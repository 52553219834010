import React, { Component } from 'react'
import Template from '../../../helper/Template'
class TemplateVariables extends Component {
    render() {
        const templateVariables = Template.variables();
        return (
            <div>
                <ul>
                    {Object.keys(templateVariables).map((item) => {
                        return (
                            <li><strong>{"{{"+item+"}}"}</strong> : {templateVariables[item]}</li>
                        )
                    })}
                </ul>
            </div>
        )
    }
}

export default TemplateVariables
