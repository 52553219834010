import { SC } from 'helper/ServerCall';

export const adminUserService = {
    login,
    logout,
    dashboard,
    dashboardMenu,
}
function login(username, password) {
    return SC.postCallLoginAdmin('auth/login', { username, password });
}

function logout() {
    return SC.getCall('logout');
}

function dashboard() {
    return SC.getCall('dashboard');
}

function dashboardMenu() {
    return SC.getCall('getMenu');
}