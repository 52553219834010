import { accountService } from "../../services/account";
// import { alertActions } from "./alert-actions";
import { adminConstants } from "../constant/admin-constant";
import { generalActions } from "./generalActions";
import { frontEndUserConstants } from "../constant/front-end-constant";

export function getBrands() {
    return dispatch => {
        return accountService.getBrands().then(
            response => {
                dispatch(generalActions.success(adminConstants.GET_ALL_BRANDS_SUCCESS, response))
                return response;
            },
            error => {
                dispatch(generalActions.failure(adminConstants.GET_ALL_BRANDS_FAILURE, error))
                return Promise.reject(error);
            }
        )
    }
}

export function getBrandsCamerasToShip() {
    return dispatch => {
        return accountService.getBrandsCamerasToShip().then(
            response => {
                return response;
            },
            error => {
                return Promise.reject(error);
            }
        )
    }
}

export function getBrandingInfoById(id) {
    return dispatch => {
        accountService.getBrandingInfoById(id).then(
            res => {

            },
            err => {

            }
        )
    }
}

export function getBrandingInfoBySlug(slug) {
    return dispatch => {
        accountService.getBrandingInfoBySlug(slug).then(
            res => {
                dispatch(generalActions.success(frontEndUserConstants.GET_BRANDING_INFO_SUCCESS, res))
            },
            err => {

            }
        )
    }
}