import React from 'react';
import { Link } from 'react-router-dom'
import {
	OverlayTrigger,
	Popover,
	Button
} from "react-bootstrap";
import { connect } from 'react-redux';
import { getSettingValue } from 'redux/actions/settings-actions';
import Select from 'react-select';
import { ThemeFunctions as TF } from 'helper/ThemeFunctions';
import { focusInput, optionsHasRole, isEmptyCheck, dateTimeWithAMPM, openModal, closeModal, dateFormatMy, sortObjectWithDefineKeys } from 'helper/helperFunctions';
import OpenModalButton from 'components/ThemeComponents/openModelButton';
import SimpleReactValidator from 'simple-react-validator';
import PricingDetail from "components/Admin/Order/PricingDetail";
import { hasPermission } from 'helper/hasPermission';
import OrderTitles from "components/Admin/Order/OrderTitles";
import { updateOrderDate, getOrder, toggleOrderEquipmentStatus, updatePricingDetail, processShipStatus } from 'redux/actions/order-actions';
import { getOrderUser, updateOrderModerator } from 'redux/actions/user-actions';
import { history } from 'helper/history';
import { Status } from '../../../constants/Status';
import { startCase, isEmpty } from 'lodash';
import { objectsConstants as OC } from 'redux/constant/objects-constant';
import { getLatestCommentByCategoryTitle } from 'redux/actions/comment-actions';
import { SC } from '../../../helper/ServerCall';
var Modal = require('react-bootstrap-modal');
const queryString = require('query-string');

class PricingDetailShowAndModalPage extends React.Component {
	constructor(props) {
		super(props);
		this.validatorPricingDetail = new SimpleReactValidator({ autoForceUpdate: this, locale: 'en' });
		// console.log('stateParams', this.props.stateParams);
		const parsed = queryString.parse(window.location.search);
		this.state = {
			shipping_details: { ...this.props.shipping_details },
			pricing_details: { ...this.props.pricing_details },
			order_details: this.props.order_details,
			editPricingModal: parsed.editPricingModal ? parsed.editPricingModal : false,
			titlesModal: false,
			orderCancelComment: {},
			...this.props.stateParams,
            dashboardMozaix:{}
		};

		// this.pricingDetailUpdate = this.pricingDetailUpdate.bind(this);
		this.handlePricingDetailSubmit = this.handlePricingDetailSubmit.bind(this);
        this.getOrderMozaixManager(this.props.orderId).then(
            response => {
                response &&
                this.setState({
                   dashboardMozaix : response.data
                })
            }
        )
	}

	handlePricingDetailSubmit(e) {
		e.preventDefault();
		if (this.validatorPricingDetail.allValid()) {
			this.setState({ submitted: true }, function () {
				const data = { pricing_details: this.ctrl_PD.returnPricingDetailData() }
				this.props.updatePricingDetailCall(data, this.props.orderId).then(
					response => {
						this.setState({
							editPricingModal: false
						})
					}
				)
			});
		} else {
			this.setState({ submitted: false });
			focusInput(this.validatorPricingDetail.getErrorMessages());
			this.validatorPricingDetail.showMessages();
			this.forceUpdate();
		}
	}
    getOrderMozaixManager(orderId) {
        return SC.getCall('getOrderMozaixManager/' + orderId);
    }
	componentDidUpdate(prevProps, prevState) {
		if (this.props.pricing_details !== this.state.pricing_details) {
			this.setState({ pricing_details: this.props.pricing_details });
		}

		if (this.props.shipping_details !== prevProps.shipping_details) {
			this.setState(
				{
					shipping_details: this.props.shipping_details,
					projected_camera_ship_date: this.props.shipping_details.order_dates.projected_camera_ship_date.value
				});
		}

		if (this.props.order_details !== prevProps.order_details) {
			this.setState({ order_details: this.props.order_details }, () => {
				if (this.state.orderCancelComment !== null) {
					if (!('id' in this.state.orderCancelComment)) {
						if (this.props.order_details.status.title === Status.CANCELLED) {
							this.props.getLatestCommentByCategoryTitle('Cancel', this.props.order_details.id).then(
								res => {
									this.setState({
										orderCancelComment: res.data.comment
									})
								}
							)
						}
					}
				}
			});
        //     if (this.props.order_details.agency.primary_address && this.props.order_details.agency.primary_address.state && this.props.order_details.agency.primary_address.state.title && this.props.order_details.agency.primary_address.state.title === 'North Carolina') {
        //         this.props.getSettingValueCall('tax', 'carolina').then( response => {

        //             if ((this.props.settings !== prevProps.settings) && !this.props.loadingSettings) {
        //                         console.log("settings changed");
        //                 if (this.props.settings.key === 'tax' || this.props.settings.key === 'carolina_tax') {

        //                     console.log("settings carolina_tax");
        //                     const taxOT = { ...this.state.pricing_details };
        //                     const order_totals = { ...taxOT.order_totals };
        //                     console.log("order_totals carolina_tax", order_totals);
        //                     var tempOT = {
        //                         code: 'tax',
        //                         value: this.props.settings.value,
        //                         id: this.props.pricing_details.order_totals.tax && this.props.pricing_details.order_totals.tax.id,
        //                         order_id: this.props.pricing_details.order_totals.tax.order_id
        //                     };
        //                     console.log("this.props.settings.value", this.props.settings.value);
        //                     order_totals['tax'] = tempOT;
        //                     this.setState({
        //                         pricing_details: {
        //                             ...taxOT,
        //                             order_totals: order_totals
        //                         }
        //                     },()=>{
        //                         console.log("state updated carolina_tax");
        //                         console.log("state",this.state.pricing_details.order_totals);

        //                     })
        //                 }
        //             }
        // console.log("pricing_details: "  ,this.state.pricing_details);
        //         });
        //     }
        }


		if (prevState.editPricingModal && !this.state.editPricingModal) {
			const parsed = queryString.parse(window.location.search);
			if (parsed.editPricingModal) {
				history.push(`/Admin/${this.props.order_details.id}/lab-production-report`);
			}
		}
	}

	toggleOrderEquipmentStatus = () => {
		const value = !this.props.production_details.no_equipment_upload_only;
		const data = { value };
		this.props.toggleOrderEquipmentStatusCall(this.props.orderId, data);
	}

	getPricingFromPD(state) {
		this.setState((prevState) => {
			return {
				...prevState,
				pricing_details: state
			}
		})
	}

	showSelect(options, accesor, accessor_id, name) {
		return (
			<div className="flexElem editAdmin" >
				<Select
					value={
						this.state.order_details[accesor] ? {
							id: this.state.order_details[accesor].id, name: this.state.order_details[accesor].name
						} : null
					}
					options={optionsHasRole(options, accesor)}
					onChange={(e) => this.handleChangeModerator(e, accessor_id, name)}
					getOptionLabel={(option) => option.name}
					getOptionValue={(option) => option.id}
					className="selectUser"
					placeholder="Select User"
					isMulti={false}
				/>

				<OverlayTrigger placement='top' overlay={<Popover id='copyLink' >Cancel</Popover>} >
					<button onClick={() => this.changeStatusModerator(name)}><i className="fa fa-times"></i></button>
				</OverlayTrigger>
			</div>
		);
	}

	showModerator = (options, accesor, accesor_id, name, title, widths, settingFinalizeAccesor) => {
		// console.log('%c the users', 'color:red', options)
		return (
			!this.state['edit' + name] ?
				TF.showValueLabel(title,
					<span>
						{
							this.state.order_details[accesor] &&
							this.state.order_details[accesor].name
						}
						{/* {
							parseInt(this.state.order_details.settings[settingFinalizeAccesor].value, 10) === 1
								?
								<OverlayTrigger placement="right" overlay={<Tooltip id="tooltip">Finalized</Tooltip>}>
									<span className="question_mark pull-right">?</span>
								</OverlayTrigger>
								:
								<button className="pull-right orderDetailsAction small_icon editIcon" onClick={() => this.changeStatusModerator(name)} >Edit</button>
						} */}
						{
							parseInt(this.state.order_details.settings[settingFinalizeAccesor].value, 10) === 0 &&
							<button className="pull-right orderDetailsAction small_icon editIcon" onClick={() => this.changeStatusModerator(name)} >Edit</button>
						}
					</span>,
					false,
					widths
				) :
				this.state['edit' + name] &&
				TF.showValueLabel(title,
					this.showSelect(options, accesor, accesor_id, name),
					false,
					widths
				)
		);
	}

	changeStatusModerator(name, callback) {
		this.setState({ ['edit' + name]: !this.state['edit' + name] }, callback);
	}
	handleChangeModerator = (e, accessor_id, name) => {
		var data = { [accessor_id]: e.id }
		this.changeStatusModerator(name, this.props.updateOrderModeratorCall(data, this.state.order_details.id));
	}

	clearProduction = () => {
		const data = this.props.order_details.order_dates.receive_date;
		data.value = null;
		this.props.updateOrderDate(this.props.orderId, { ...data, flag: 'clear_production_status' }).then(
			res => {
				this.props.getOrder(this.props.orderId)
			}
		)
	}
	updateReceivedDate = (val) => {
		const data = this.props.order_details.order_dates.receive_date;
		data.value = val;
		this.props.updateOrderDate(this.props.orderId, { ...data, flag: 'update_received_date' }).then(
			res => {
				this.props.getOrder(this.props.orderId)
			}
		)
	}
	clear_finalize_by_editor = () => {
		const data = this.props.order_details.settings.finalize_by_editor;
		data.value = null;
		this.props.updateOrderDate(this.props.orderId, { ...data, flag: 'finalize_by_editor' }).then(
			res => {
				this.props.getOrder(this.props.orderId)
			}
		)
	}
	clear_finalize_by_pm = () => {
		const data = this.props.order_details.settings.finalize_by_pm;
		data.value = null;
		this.props.updateOrderDate(this.props.orderId, { ...data, flag: 'finalize_by_pm' }).then(
			res => {
				this.props.getOrder(this.props.orderId)
			}
		)
	}
	clear_finalize_by_qa = () => {
		const data = this.props.order_details.settings.finalize_by_qa;
		data.value = null;
		this.props.updateOrderDate(this.props.orderId, { ...data, flag: 'finalize_by_qa' }).then(
			res => {
				this.props.getOrder(this.props.orderId)
			}
		)
	}
	showStatus = (order_details) => {
		var status = '';
		if (isEmptyCheck(order_details.order_dates.receive_date.value)) {
			status = 'Waiting To Be Received';
		}
		if (!isEmptyCheck(order_details.order_dates.receive_date.value)) {
			status = 'Submitted For Editing';
		}
		if (parseInt(order_details.settings.finalize_by_editor.value, 10) === 1) {
			status = 'Ready For Production';
		}
		if (parseInt(order_details.settings.finalize_by_pm.value, 10) === 1) {
			status = 'Produced';
		}
		if (parseInt(order_details.settings.finalize_by_qa.value, 10) === 0 && parseInt(order_details.settings.finalize_by_pm.value, 10) === 1) {
			status = 'Waiting Quality Control';
		}
		if (parseInt(order_details.settings.finalize_by_qa.value, 10) === 1) {
			status = 'Complete';
		}
		return (
			<React.Fragment>
				<strong >{status} </strong>
			</React.Fragment>
		);
	}

	componentDidMount() {
		this.props.getOrderUsersCall();
	}
	render() {
		const { editPricingModal, pricing_details, shipping_details, orderCancelComment,dashboardMozaix } = this.state;
		const { production_details, order_details, orderUsers, programType, csr_steps, is_ttk } = this.props;
        // console.log("shipping_details", shipping_details);
        // console.log("dashboardMozaix", dashboardMozaix);

		let calculatedTax = 0;
        calculatedTax = parseFloat(Number(
            ("sub_total" in pricing_details.order_totals ? Number(pricing_details.order_totals.sub_total.value) : 0) +
            ("additional_fees" in pricing_details.order_totals ? Number(pricing_details.order_totals.additional_fees.value) : 0) -
            ("discount" in pricing_details.order_totals ? Number(pricing_details.order_totals.discount.value) : 0)
        ) * (parseFloat("tax" in pricing_details.order_totals ? pricing_details.order_totals.tax.value : 0) / 100)).toFixed(2)

		if(calculatedTax < 0) {
			calculatedTax = Number(0).toFixed(2);
		}

		return (
			<div className="sepSections flexElem">
				<div className="orderContent hasChild">
					<div className="orderContent" >
						<div id="pricing_details">
							<div className="flexElem" style={{ "flexWrap": "wrap", "justifyContent": "space-between" }}>
								<div className="orderDetailsHeader">
									<span>Production Details</span>
									<span><Link to={`/Admin/OrderLog/${this.props.orderId}/`} target="_blank" className='blue_link'>Order History</Link></span>

									<span className="controls">

										{/* <OverlayTrigger className="" placement="bottom" trigger="focus" overlay={<Popover id="tooltip">Advance Search</Popover>}>
											<span className="orderDetailsAction refreshAction" onClick={this.openeditPricingModal} >Refresh</span>
										</OverlayTrigger> */}
									</span>
								</div>
								<div className="orderDetailsBox">
									<table className="table orderTable">
										<tbody>
                                            {
                                                !is_ttk &&
                                                <React.Fragment>
                                                    <tr>
                                                        <td className="td_heading">Customer Slate For Videos</td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <table className="resetOuterPadding block">
                                                                <tbody>
                                                                    {
                                                                        (hasPermission("role-edit") && order_details.customer_slates_for_videos_id) &&
                                                                        <React.Fragment>
                                                                            <Link target="_blank" className="blue_link target_blank" to={'/Admin/' + order_details.customer_slates_for_videos_id + '/agency-edit'} >
                                                                                {
                                                                                    order_details.customer_slates_for_videos.name
                                                                                }
                                                                            </Link>
                                                                            <div className="mt-sm">
                                                                                <Link target="_blank" to={'/Admin/' + order_details.agency_id + '/agency-resource'} className="iconOutlineBtn"><i className="fa fa-file" />
                                                                                    <span>Agency Resources</span>
                                                                                </Link>
                                                                            </div>
                                                                        </React.Fragment>
                                                                    }
                                                                    {/* TF.showValueLabel('Customer Slates For Videos',
                                                                        <React.Fragment>
                                                                            <Link target="_blank" className="blue_link target_blank" to={'/Admin/' + order_details.customer_slates_for_videos_id + '/agency-edit'} >
                                                                                {
                                                                                    order_details.customer_slates_for_videos.name
                                                                                }
                                                                            </Link>
                                                                            <div className="mt-sm">
                                                                                <Link target="_blank" to={'/Admin/' + order_details.agency_id + '/agency-resource'} className="iconOutlineBtn"><i className="fa fa-file" />
                                                                                    <span>Agency Resources</span>
                                                                                </Link>
                                                                            </div>
                                                                        </React.Fragment>,
                                                                            false,
                                                                            { td1: '50%', td2: '50%' }
                                                                        ) */}
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className='td_heading' >Titles</td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <OpenModalButton
                                                                openModal={openModal.bind(this, 'titlesModal')}
                                                                classButton={['customBtn btn btn-primary']}
                                                                buttonName='Titles Available'
                                                                tooltipText='Titles Available'
                                                                classIcon={[]} />
                                                        </td>
                                                    </tr>
                                                </React.Fragment>
                                            }
											<tr>
												<td className='td_heading'>Mozaix</td>
											</tr>
											<tr>
												<td>
                                                    <Link to={`/Admin/${this.props.orderId}/Manage-Mosaics`} className='blue_link'>Manage</Link>
													{/* {
														'20x30 PhotoMozaix' in pricing_details.order_items && pricing_details.order_items['20x30 PhotoMozaix'].quantity > 0 ?
															<Link to={`/Admin/${this.props.orderId}/Manage-Mosaics`} className='blue_link'>Manage</Link>
															:
															<span>None <Link to={`/Admin/${this.props.orderId}/Manage-Mosaics`} className='blue_link'>Create New</Link></span>
													} */}
												</td>
											</tr>
                                            {
                                                !is_ttk &&
                                                <React.Fragment>
                                                    <tr>
                                                        <td className='td_heading'>Lab Processing Record</td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <Link className="editIcon orderDetailsAction" to={`/Admin/${this.props.orderId}/lab-production-report`}>Edit Lab Production Record (LPR)</Link>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            {/* <button className="editIcon orderDetailsAction mb-xs">Edit Lab Production Report (LPR)</button> */}
                                                            <Link target="_blank" className="reprintIcon orderDetailsAction" to={`/Admin/${this.props.orderId}/print_LPR`}>Reprint Lab Production Record (LPR)</Link>
                                                        </td>
                                                    </tr>
                                                </React.Fragment>
                                            }
											{/* <tr>
												<td className='td_heading'>Edit By Date</td>
											</tr>
											<tr>

												{
													production_details.order_dates.receive_date &&
													<td>
														{getFormattedDate(production_details.order_dates.receive_date.updated_at)}
													</td>
												}
											</tr> */}
											{
												false &&
												<React.Fragment>
													<tr>
														<td className='td_heading' >Order Equipment Status</td>
													</tr>
													<tr>
														<td>&nbsp;</td>
													</tr>
													<tr>
														<td>
															<Link className="editIcon orderDetailsAction mb-xs" to={`/Admin/${this.props.orderId}/lab-production-report`}>Edit Lab Production Report (LPR)</Link>
															{/* <button className="editIcon orderDetailsAction mb-xs">Edit Lab Production Report (LPR)</button> */}
															<button className="reprintIcon orderDetailsAction mb-xs">Reprint Lab Production Report (LPR)</button>
															{
																production_details.no_equipment_upload_only ?
																	<button onClick={this.toggleOrderEquipmentStatus} className="editIcon orderDetailsAction">Equipment Not Returning-Upload Only</button>
																	:
																	<button onClick={this.toggleOrderEquipmentStatus} className="returningIcon orderDetailsAction">Equipment is Returning</button>
															}
														</td>
													</tr>
												</React.Fragment>
											}


											{
												!hasPermission("role-edit") &&
												TF.showValueLabel('Customer slates for videos', order_details.customer_slates_for_videos ? order_details.customer_slates_for_videos.name : '')
											}
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
					<div className="orderContent">
						<div className="flexElem" style={{ "flexWrap": "wrap", "justifyContent": "space-between" }}>
							<div className="orderDetailsHeader">
								<span>Production Status</span>
							</div>
							<div className="orderDetailsBox">
								<table className="table orderTable">
									<tbody>
										<tr>
											<td className="td_heading" style={{ "verticalAlign": "top" }}>
												<div className='flexElem align-items-center justify-content-between'>
													<span>
														PTM Status
													</span>
													{
														shipping_details.camera_ship_status != null &&
															!isEmpty(shipping_details.camera_ship_status.shipping_method) ?
															<a onClick={this.props.processShipStatusCall.bind(this, this.props.orderId, { status: 0, slug: 'camera', 'sourceEnd':'admin'})} className={`blue_link ${(order_details.order_dates.receive_date.value || order_details.settings.finalize_by_qa.value == 1) ? 'disabledUnprocess' : ''}`} 
															>Unprocess</a>
															// <Button className="customBtn btn btn-primary btn-sm" onClick={this.props.processShipStatusCall.bind(this, this.props.orderId, { status: 0, slug: 'camera', 'sourceEnd':'admin'})} >Unprocess</Button>
															: null
													}
												</div>
											</td>
										</tr>
										{
											'production_status' in shipping_details &&
											<tr>
												<td colSpan='2'>
													<div className='row flexElem align-items-center' >
														<div className='col-md-12' >
															<div id="showStatus">
                                                                {
																	TF.productionStatus(order_details.settings, order_details.status, csr_steps, order_details.onHoldLastComment, orderCancelComment, order_details.order_dates, shipping_details)
																}

															</div>
														</div>
													</div>
												</td>
											</tr>
										}
										<tr>
											<td className="td_heading">
												{
													!isEmptyCheck(production_details.order_dates.receive_date)
														?
														'value' in production_details.order_dates.receive_date
															?
															!isEmptyCheck(production_details.order_dates.receive_date.value)
																?
																<div className='flexElem align-items-center justify-content-between'>
																	<span>Received</span>
																	{
																		(order_details.settings.finalize_by_pm.value === '0' && order_details.order_dates.receive_date.value) &&
																		(
																			<div>
																				{/* <button onClick={this.clearProduction} className='customBtn btn btn-primary btn btn-default'>Clear Production Status</button> */}
																				{/* <button onClick={this.clearProduction} className='customBtn btn btn-primary btn-sm'>Unprocess</button> */}
																				<a onClick={this.clearProduction} className={`blue_link ${(order_details.settings.finalize_by_qa.value == 1) ? 'disabledUnprocess' : ''}`}>Unprocess</a>
																			</div>
																		)
																	}
																</div>
																:
																'Not Received'
															:
															null
														:
														null
												}
											</td>
										</tr>
										{/* <tr>
											<td >
												{
													!isEmptyCheck(production_details.order_dates.receive_date)
														?
														'value' in production_details.order_dates.receive_date
															?
															!isEmptyCheck(production_details.order_dates.receive_date.value)
																?
																new Date(production_details.order_dates.receive_date.value).toDateString()
																:
																'Not Received'
															:
															null
														:
														null
												}
											</td>
										</tr> */}

										{
											'production_status' in shipping_details &&
											<React.Fragment>
												<tr>
													<td>
														<div className='row flexElem align-items-center' >
															<div className='col-md-12' >
																{
																	(order_details.settings.finalize_by_pm.value === '0' && order_details.order_dates.receive_date.value) &&
																	<div>
																		{/* <button onClick={this.clearProduction} className='customBtn btn btn-primary btn btn-default'>Clear Receiving Status</button> */}
																	</div>
																}

																{
																	order_details.order_dates.receive_date.value &&
																	<div>
                                                                        <span className="mt-md">
                                                                            <strong>{dateTimeWithAMPM(order_details.order_dates.receive_date.value)} {order_details.order_dates.receive_date.updated_by ? ' BY ' + order_details.order_dates.receive_date.updated_by.name : null}</strong>
                                                                        </span>
																		{/* <strong className="innerLabel">RECEIVED DATE: </strong> */}
																		{/*
																			order_details.settings.finalize_by_qa.value === '1'
																				?
																				<span>{dateFormatMy(order_details.order_dates.receive_date.value)}</span>
																				:
																				<span className="mt-md">
																					<strong>{dateTimeWithAMPM(order_details.order_dates.receive_date.value)} {order_details.order_dates.receive_date.updated_by ? ' BY ' + order_details.order_dates.receive_date.updated_by.name : null}</strong>
                                                                                </span>
                                                                            }

																		*/}

																	</div>
																}
                                                                {
                                                                    !is_ttk &&
                                                                    <React.Fragment>
                                                                        <div className='resetOuterPadding'>
                                                                            <table className='w-100'>
                                                                                <tbody>
                                                                                    {this.showModerator(orderUsers, 'editor', 'editor_id', 'Editor', 'Editor', { td1: "25%", td2: "75%" }, 'finalize_by_editor')}
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                        {
                                                                            order_details.settings.finalize_by_editor.value !== '0' &&
                                                                            <div className='mt-md'>
                                                                                <strong className="innerLabel">EDITING PROCESSED: </strong>
                                                                                <span>{dateTimeWithAMPM(order_details.settings.finalize_by_editor.updated_at)} <br /> {order_details.settings.finalize_by_editor.updated_by_user ? order_details.settings.finalize_by_editor.updated_by_user.name : null}</span>
                                                                                <span> <button onClick={this.clear_finalize_by_editor} className='deleteIcon orderDetailsAction'>Unprocess</button></span>
                                                                            </div>
                                                                        }
                                                                        <div className='resetOuterPadding'>
                                                                            <table className='w-100'>
                                                                                <tbody>
                                                                                    {this.showModerator(orderUsers, 'producer', 'producer_id', 'Producer', 'Video Producer', { td1: "25%", td2: "75%" }, 'finalize_by_pm')}
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                        {
                                                                            order_details.settings.finalize_by_pm.value !== '0' &&
                                                                            <div className='mt-md'>
                                                                                <strong className="innerLabel">PRODUCTION PROCESSED: </strong>
                                                                                <span>{dateTimeWithAMPM(order_details.settings.finalize_by_pm.updated_at)} {order_details.settings.finalize_by_pm.updated_by_user ? order_details.settings.finalize_by_pm.updated_by_user.name : null}</span>
                                                                                <span> <button onClick={this.clear_finalize_by_pm} className='deleteIcon orderDetailsAction'>Unprocess</button></span>
                                                                            </div>
                                                                        }
                                                                        <div className='resetOuterPadding'>
                                                                            <table className='w-100'>
                                                                                <tbody>
                                                                                    {this.showModerator(orderUsers, 'qa', 'qa_id', 'QA', 'Quality Control', { td1: "25%", td2: "75%" }, 'finalize_by_qa')}
                                                                                </tbody>
                                                                            </table>

                                                                        </div>
                                                                        {
                                                                            order_details.settings.finalize_by_qa.value !== '0' &&
                                                                            <div className='mt-md'>
                                                                                <strong className="innerLabel">QUALITY CONTROL: </strong>
                                                                                <span>{dateTimeWithAMPM(order_details.settings.finalize_by_qa.updated_at)} <br /> {order_details.settings.finalize_by_qa.updated_by_user ? order_details.settings.finalize_by_qa.updated_by_user.name : null}</span>
                                                                                <span> <button onClick={this.clear_finalize_by_qa} className='deleteIcon orderDetailsAction'>Unprocess</button></span>
                                                                            </div>
                                                                        }
                                                                    </React.Fragment>
                                                                }

                                                                    <div className='mt-md'>
																		<strong className="innerLabel">MOZAIX: </strong>
                                                                                {
                                                                                    dashboardMozaix.is_mozaix_printed ?
                                                                                    <>
                                                                                         <span>Printed: </span> <span>{dashboardMozaix.updated_at ? dateTimeWithAMPM(dashboardMozaix.updated_at) : ''}</span> <br />
                                                                                         <span>{dashboardMozaix.mozaix_manager ? dashboardMozaix.mozaix_manager.name : '' }</span>
                                                                                    </>
                                                                                    : <span>Not Printed</span>
                                                                                }
                                                                    </div>
																{/* {
																shipping_details.production_status.editor_logged_date != null &&
																<div>
																	<strong className="innerLabel">{shipping_details.production_status.editor_logged_date.title}: </strong>
																	{
																		'value' in shipping_details.production_status.editor_logged_date ?
																			shipping_details.production_status.editor_logged_date.value ?
																				<span>{new Date(shipping_details.production_status.editor_logged_date.value).toDateString()}</span>
																				: null
																			: null
																	}
																</div>
															}
																	{
																		shipping_details.production_status.editing_processed_date != null &&
																		<div>
																			<strong className="innerLabel">{shipping_details.production_status.editing_processed_date.title}: </strong>
																			{
																				'value' in shipping_details.production_status.editing_processed_date ?
																					shipping_details.production_status.editing_processed_date.value ?
																						<span>{new Date(shipping_details.production_status.editing_processed_date.value).toDateString()}</span>
																						: null
																					: null
																			}

																		</div>
																	}
																	{
																		shipping_details.production_status.produced_date != null &&
																		<div>
																			<strong className="innerLabel">{shipping_details.production_status.produced_date.title}: </strong>
																			{
																				'value' in shipping_details.production_status.produced_date ?
																					shipping_details.production_status.produced_date.value ?
																						<span>{new Date(shipping_details.production_status.produced_date.value).toDateString()}</span>
																						: null
																					: null
																			}
																		</div>
																	}
																*/}
															</div>
														</div>
													</td>
												</tr>
												<tr>
													<td className="td_heading">Estimated Complete By Date</td>
												</tr>
												<tr>
													<td>
														{
															production_details.order_dates.finish_by_date &&
																'value' in production_details.order_dates.finish_by_date ?
																production_details.order_dates.finish_by_date.value ?
																	new Date(production_details.order_dates.finish_by_date.value).toDateString()
																	: null
																: null
														}
													</td>
												</tr>
											</React.Fragment>
										}
										{/* <tr><td colSpan="">&nbsp;</td></tr> */}
										{/* <tr>
											<td>
												<table className="resetOuterPadding">
													<tbody>
														{!Number(order_details.settings.finalize_by_editor.value) && this.showModerator(orderUsers, 'editor', 'editor_id', 'Editor', 'Editor', { td1: "25%", td2: "75%" }, 'finalize_by_editor')}
														{!Number(order_details.settings.finalize_by_pm.value) && this.showModerator(orderUsers, 'producer', 'producer_id', 'Producer', 'Production Manager', { td1: "25%", td2: "75%" }, 'finalize_by_pm')}
														{!Number(order_details.settings.finalize_by_qa.value) && this.showModerator(orderUsers, 'qa', 'qa_id', 'QA', 'Quality Control', { td1: "25%", td2: "75%" }, 'finalize_by_qa')}
													</tbody>
												</table>
											</td>
										</tr> */}
									</tbody>
								</table>
							</div>
						</div>
					</div>
					{/* <div className='orderContent' >
						<div id='orderChecklist'>
							<div className="flexElem" style={{ "flexWrap": "wrap", "justifyContent": "space-between" }}>
								<div className="orderDetailsHeader">
									<span>Order Checklist</span>

									<span className="controls">
										<OverlayTrigger className="" placement="bottom" trigger="focus" overlay={<Popover id="tooltip">Advance Search</Popover>}>
											<span className="orderDetailsAction refreshAction" onClick={this.openeditPricingModal} >Refresh</span>
										</OverlayTrigger>
									</span>
								</div>
								<div className="orderDetailsBox">
									<OrderChecklist orderId={this.props.orderId} checkList={checkList} />
								</div>
							</div>
						</div>
					</div> */}
				</div >

				<div className="orderContent">
					{
						pricing_details &&
						<div id="pricing_details">
							<div className="flexElem" style={{ "flexWrap": "wrap", "justifyContent": "space-between" }}>
								<div className="orderDetailsHeader">
									<span>Pricing Details</span>

									<span className="controls">
										{

											<OpenModalButton
												openModal={openModal.bind(this, "editPricingModal")}
												classButton={['editIcon orderDetailsAction']}
												buttonName="Edit Pricing Details"
												tooltipText="Edit Pricing Details"
												classIcon={[]}
											/>

										}
									</span>
								</div>
								<div className="orderDetailsBox">
									{
										(
											Object.keys(pricing_details.order_items).length > 0 ||
											Object.keys(pricing_details.order_totals).length > 0
										) &&
										<React.Fragment>
											<table className="table orderTable mt-none evenly table-striped">
												<thead>
													<tr>
														<th className="text-left"><strong>Item</strong></th>
														<th className="text-right"><strong>Quantity</strong></th>
														<th className="text-right"><strong>Unit Price</strong></th>
														<th className="text-right"><strong>Line Price</strong></th>
													</tr>
												</thead>
												<tbody>
													{
														Object.keys(pricing_details.order_items).map(key => {
															return (
																!OC.EXCEPTITEMS.includes(pricing_details.order_items[key].title) ||  (OC.EXCEPTITEMS.includes(pricing_details.order_items[key].title) && parseInt(pricing_details.order_items[key].quantity) > 0)
																?
                                                                    is_ttk && pricing_details.order_items[key].title === "Videos/Travelers" ?
																	<tr key={key} >
																		<td className="text-left">{pricing_details.order_items[key].title === "Videos/Travelers" && is_ttk ? "Travelers" : pricing_details.order_items[key].title}</td>
																		<td className="text-right">{pricing_details.order_items[key].quantity}</td>
																		<td className="text-right">{pricing_details.order_items[key].price ? "$" + pricing_details.order_items[key].price : '-'}</td>
																		<td className="text-right">{pricing_details.order_items[key].total ? "$" + pricing_details.order_items[key].total : '-'}</td>
																	</tr>
                                                                    :
                                                                    !is_ttk &&
																	<tr key={key} >
																		<td className="text-left">{pricing_details.order_items[key].title}</td>
																		<td className="text-right">{pricing_details.order_items[key].quantity}</td>
																		<td className="text-right">{pricing_details.order_items[key].price ? "$" + pricing_details.order_items[key].price : '-'}</td>
																		<td className="text-right">{pricing_details.order_items[key].total ? "$" + pricing_details.order_items[key].total : '-'}</td>
																	</tr>
																:
																	null
															);
														})
													}
													{/* <tr>
														<td className="text-left">Cams/Cards</td>
														<td className="text-right">{pricing_details.number_of_cameras}</td>
														<td className="text-right">--</td>
														<td className="text-right">--</td>
													</tr>
													<tr>
														<td className="text-left">Flash Drives</td>
														<td className="text-right">{pricing_details.number_of_flashdrive}</td>
														<td className="text-right">--</td>
														<td className="text-right">--</td>
													</tr>
													<tr>
														<td className="text-left">Sd Cards</td>
														<td className="text-right">{pricing_details.number_of_sdcard}</td>
														<td className="text-right">--</td>
														<td className="text-right">--</td>
													</tr> */}
													{
														Object.keys(sortObjectWithDefineKeys(pricing_details.order_totals, [
															'sub_total', 'discount', 'additional_fees', 'tax', 'shipping', 'grand_total'
														])).map(key => {
															return (
																(pricing_details.taxable !== 1 && pricing_details.order_totals[key].code === 'tax')
																	?
																	null
																	:
																	<tr key={key}  >
																		<td className="text-left"></td>
																		<td className="text-right"></td>
																		<td className="text-right" style={key === 'discount' ? { color: 'red' } : null}>{startCase(pricing_details.order_totals[key].code)}</td>
																		{
																			pricing_details.taxable !== 0 && pricing_details.order_totals[key].code === 'tax' ?
																				<td className="text-right">{parseFloat(pricing_details.order_totals[key].value).toFixed(2)} % (${calculatedTax})</td>
																			:
																				<td className="text-right" style={key === 'discount' ? { color: 'red' } : null}>{"$" + parseFloat(pricing_details.order_totals[key].value).toFixed(2)}</td>
																		}
																	</tr>
															);
														})
													}
												</tbody>
											</table>

											{
                                                !is_ttk &&
                                                <table align="right" className="table orderTable table-striped" >
                                                    <thead>
                                                        <tr>
                                                            <th className="text-right"><strong>Free Copy</strong></th>
                                                            <th className="text-right"><strong>Client</strong></th>
                                                            <th className="text-right"><strong>Agency</strong></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td className="text-right">DVD</td>
                                                            <td className="text-right">{pricing_details.free_additional_dvds_to_client}</td>
                                                            <td className="text-right">{pricing_details.free_additional_dvds_to_agency}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-right">Flash Drive</td>
                                                            <td className="text-right">{pricing_details.settings.send_gl_fd_copy.value}</td>
                                                            <td className="text-right">{pricing_details.settings.no_gl_copy.value}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            }
											{/* <div>
												<table align="right" className="table orderTable table-striped">
													<thead>
														<tr>
															<th className="text-right"><strong>Free </strong></th>
															<th className="text-right"><strong>No GL Copy</strong></th>
															<th className="text-right"><strong>send GL FD Copy</strong></th>
														</tr>
													</thead>
													<tbody>
														<tr>
															<td className="text-right">{pricing_details.settings.free_fash_drive_to_client.value}</td>
															<td className="text-right">{pricing_details.settings.no_gl_copy.value}</td>
															<td className="text-right">{pricing_details.settings.send_gl_fd_copy.value}</td>
														</tr>
													</tbody>
												</table>
											</div> */}
										</React.Fragment>
									}
								</div>
							</div>
						</div>
					}
				</div>
				<Modal  backdrop={'static'} show={editPricingModal} onHide={closeModal.bind(this, "editPricingModal")} aria-labelledby="ModalHeader" className="editOrderDetail" >
					<Modal.Header closeButton>
						<Modal.Title id='ModalHeader' className="headerTitle">Edit Pricing Details</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div className="clearfix">
							{
								'program_type' in pricing_details &&
								<PricingDetail
									provideCtrlPD={ctrlPD => this.ctrl_PD = ctrlPD}
									collect_tax={this.props.collect_tax}
									htmlElement="PricingDetail"
									pricing_details={pricing_details}
									validatorInstance={this.validatorPricingDetail}
									requiredField={{}}
									order_details={order_details}
									hasCard={true}
									programType={programType}
									formType='edit'
                                    orderId={this.props.orderId}
                                    is_ttk={is_ttk}

								/>
							}
							<button className="backButton pt-sm no_radius pb-sm primary btn btn-md btn-info ml-sm mt-sm pull-right" onClick={this.handlePricingDetailSubmit} >Edit Pricing Detail</button>
						</div>
					</Modal.Body>
				</Modal>
				<Modal backdrop={'static'} size='sm' show={this.state.titlesModal} onHide={closeModal.bind(this, 'titlesModal')} aria-labelledby="ModalHeader" className="editOrderDetail">
					<Modal.Header closeButton>
						<Modal.Title id='ModalHeader' className="headerTitle">Title Available</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<OrderTitles close={closeModal.bind(this, 'titlesModal')} orderId={this.props.orderId} titles={production_details.video_title_templates} order_details={order_details} />
					</Modal.Body>
				</Modal>
			</div>
		);
	};
}
const mapDispatchToProps = dispatch => {
	return ({
		getSettingValueCall: (key, stateName) => {return dispatch(getSettingValue(key, stateName)) },
		getOrderUsersCall: () => { dispatch(getOrderUser()) },
		updateOrderModeratorCall: (orderUserData, orderId) => { dispatch(updateOrderModerator(orderUserData, orderId)) },
		updateOrderDate: (id, data) => { return dispatch(updateOrderDate(id, data)) },
		getOrder: (id) => { dispatch(getOrder(id)) },
		toggleOrderEquipmentStatusCall: (orderId, data) => { dispatch(toggleOrderEquipmentStatus(orderId, data)) },
		updatePricingDetailCall: (data, id) => { return dispatch(updatePricingDetail(data, id)) },
		processShipStatusCall: (orderId, data) => { dispatch(processShipStatus(orderId, data)) },
		getLatestCommentByCategoryTitle: (title, model_id) => dispatch(getLatestCommentByCategoryTitle(title, model_id)),
	});
};

function mapStateToProps(state) {
	const { loadingSettings, settings } = state.settingsReducer;
	const { orderUsers } = state.userReducer;
	const { csr_steps, is_gtv } = state.ordersReducer;
	return {
		loadingSettings,
		orderUsers,
		csr_steps,
        settings,
        is_gtv
	};
}


const PricingDetailShowAndModal = connect(mapStateToProps, mapDispatchToProps)(PricingDetailShowAndModalPage);

export default PricingDetailShowAndModal;
