import { schedulerServices } from "services/scheduler";
import { alertActions } from 'redux/actions/alert-actions';

export function  getCallSchedulers() {
	return dispatch => {
        return schedulerServices.getCallSchedulers().then(
            response => {
                return response;
            },
            error => {
            	return Promise.reject(error);
            }
        )
    }
}

export function addEventtoScheduler(data) {
    return dispatch => {
        return schedulerServices.addEventtoScheduler(data).then(
            response => {
                dispatch(alertActions.success(response.message));
                return response;
            },
            error => {
                return Promise.reject(error);
            }
        )
    }   
}