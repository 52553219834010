import React, { Component } from 'react'
import { connect } from 'react-redux'
import { sendPasswordResetLink } from '../../redux/actions/front-end-action';

class ForgotPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: ''
        }
    }
    render() {
        const cardStyle = {
            border: '1px solid rgba(0,0,0,.125)'
        }
        return (
            <div className="main_login">
                <div className="login_form_container">
                    <div className="login_form text-center">
                        <h1 className="text-center">Forgot Password</h1>
                        <div className="text-center text_22 mt-md">For Tour Operator Use Only</div>
                        <div className="text-center text_14">Please enter the email address you provided to our Sales Manager when your account was created. If the email address is in our system, an email will be sent to that email address.</div>

                        <form name="form" className="mt-md">
                            <input placeholder='username' className='form-control' type="text" name="" id="" value={this.state.username} onChange={(e) => {
                                this.setState({
                                    username: e.target.value
                                })
                            }} />
                            <button className="btn btn-default btn_login mt-lg" type='button' onClick={() => { this.props.sendPasswordResetLink(this.state.username).then(res => this.setState({ username: '' })) }}>Send password link</button>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        sendPasswordResetLink: (username) => dispatch(sendPasswordResetLink(username))
    }
}

export default connect(null, mapDispatchToProps)(ForgotPassword)
