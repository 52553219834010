import { adminConstants } from '../constant/admin-constant';

const initialState = { loadingRole: false };

export function rolesReducer(state = initialState, action) {

	switch (action.type) {
		case adminConstants.GET_USER_ROLES_REQUEST:
			return { ...state, loadingRole: true };
		case adminConstants.GET_USER_ROLES_SUCCESS:
			return { ...state, loadingRole: false, roles: action.payload.userRoles };
		case adminConstants.GET_USER_ROLES_FAILURE:
			return { ...state, loadingRole: false, error: action.payload };

		case adminConstants.GET_AGENCY_ROLES_REQUEST:
			return { ...state, loadingRole: true };
		case adminConstants.GET_AGENCY_ROLES_SUCCESS:
			return { ...state, loadingRole: false, agencyRoles: action.payload.agencyRoles };
		case adminConstants.GET_AGENCY_ROLES_FAILURE:
			return { ...state, loadingRole: false, error: action.payload };

		case adminConstants.PAGINATION_ROLES_REQUEST:
			return { ...state, loadingRole: true, roles: {}, editRole: '', storedRole: '', updatedRole: '', deleteRole: false };
		case adminConstants.PAGINATION_ROLES_SUCCESS:
			return { ...state, rolesData: action.payload.data.pagination.data, pages: action.payload.data.pagination.last_page, loadingRole: false, current_page: action.payload.data.pagination.current_page };
		case adminConstants.PAGINATION_ROLES_FAILURE:
			return { ...state, loadingRole: false, error: action.payload };

		case adminConstants.STORE_ROLE_REQUEST:
			return { ...state, loadingRole: true, editRole: '', storedRole: '' }
		case adminConstants.STORE_ROLE_SUCCESS:
			return { ...state, loadingRole: false, storedRole: action.payload.role };
		case adminConstants.STORE_ROLE_FAILURE:
			return { ...state, loadingRole: false, error: action.payload };

		case adminConstants.GET_ROLE_REQUEST:
			return { ...state, loadingRole: true };
		case adminConstants.GET_ROLE_SUCCESS:
			return { ...state, loadingRole: false, editRole: action.payload.role };
		case adminConstants.GET_ROLE_FAILURE:
			return { ...state, loadingRole: false, error: action.payload };

		case adminConstants.EDIT_ROLE_REQUEST:
			return { ...state, loading: true, updatedRole: '' };
		case adminConstants.EDIT_ROLE_SUCCESS:
			return { ...state, loading: false, updatedRole: action.payload.role };
		case adminConstants.EDIT_ROLE_FAILURE:
			return { ...state, loading: false, error: action.payload };

		case adminConstants.DELETE_ROLE_REQUEST:
			return { ...state, loading: true, deleteRole: false }
		case adminConstants.DELETE_ROLE_SUCCESS:
			return { ...state, loading: false, deleteRole: true };
		case adminConstants.DELETE_ROLE_FAILURE:
			return { ...state, loading: false, error: action.payload };

		default:
			return { ...state };
	}
}