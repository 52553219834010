import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Grid, Row, Col } from 'react-bootstrap'
import Card from 'components/Card/Card';
import Validator from 'simple-react-validator'
import { inputChangeHandler } from 'helper/helperFunctions';
import { storeItem, getSingleAdditionalItem, updateAdditionalItem, getItemTypes, getItemCategories } from 'redux/actions/settings-actions';
class AdditionalServicesForm extends Component {

    constructor(props) {
        super(props)

        this.state = {
            title: '',
            price: '',
            taxable: '0',
            dvd: ''
        }
        this.validator = new Validator();
        this.formSubmitHandler = this.formSubmitHandler.bind(this);
        if (this.props.formType === 'edit' && (this.props.editableItem !== undefined || Object.keys(this.props.editableItem).length >= 1)) {
            this.props.onGetSingleAdditionalServiceCall(this.props.editableItem.id);
        }
    }

    componentDidMount() {
        this.props.getItemTypesCall();
        this.props.getItemCategoriesCall();
    }


    formSubmitHandler() {
        if (this.validator.allValid()) {
            const data = { ...this.state };
            data.item_category_id = this.props.itemTypes.find(item => {
                return item.label === 'Additional Services';
            }).value;
            data.item_type_id = this.props.itemCategories.find(item => {
                return item.label === 'Other';
            }).value;
            data.show_on_order_form = 0;
            if (this.props.formType === 'add') {
                this.props.storeAdditionalServieCall(data).then(
                    response => {
                        this.props.hide();
                        this.props.getPagination();
                    }
                );
            } else if (this.props.formType === 'edit') {
                this.props.updateAdditionalServiceCall(data, this.props.editableItem).then(
                    response => {
                        this.props.hide();
                        this.props.getPagination();
                    }
                )
            }
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.singleAdditionalService !== prevProps.singleAdditionalService) {
            this.setState({
                ...this.props.singleAdditionalService
            })
        }
    }


    render() {
        return (
            <div>
                <Grid fluid>
                    <Row>
                        <Col md={12}>
                            <Card bsClass={['innerCard']} content={
                                <React.Fragment>
                                    <div className='row'>
                                        <div className='col-md-4'>
                                            <div className='form-group' >
                                                <label>Title<span className="requiredClass">*</span></label>
                                                <input type='text' name='title' value={this.state.title} onChange={inputChangeHandler.bind(this)} className='form-control' />
                                                {this.validator.message('Title', this.state.title, 'required')}
                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            <div className='form-group' >
                                                <label>Price<span className="requiredClass">*</span></label>
                                                <input type='text' name='price' value={this.state.price} onChange={inputChangeHandler.bind(this)} className='form-control' />
                                                {this.validator.message('Price', this.state.price, 'required')}
                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            <div className='form-group' >
                                                <label>Taxable<span className="requiredClass">*</span></label>
                                                <select name='taxable' onChange={inputChangeHandler.bind(this)} className='form-control' value={this.state.taxable}>
                                                    <option value='1'>YES</option>
                                                    <option value='0'>NO</option>
                                                </select>
                                                {this.validator.message('Taxable', this.state.taxable, 'required')}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-4'>
                                            <div className='form-group' >
                                                <label>DVD<span className="requiredClass">*</span></label>
                                                <input type='text' name='dvd' value={this.state.dvd} onChange={inputChangeHandler.bind(this)} className='form-control' />
                                                {this.validator.message('DVD', this.state.dvd, 'required')}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='text-right' >
                                        <button onClick={this.formSubmitHandler} className="backButton pt-sm text-xs no_radius pb-sm primary btn btn-lg btn-info" >Save</button>
                                    </div>
                                </React.Fragment>
                            } />
                        </Col>
                    </Row>
                </Grid>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { singleAdditionalService, itemTypes, itemCategories } = state.settingsReducer;
    return {
        singleAdditionalService,
        itemTypes, itemCategories
    }
}

const mapDispatchToProps = dispatch => {
    return {
        storeAdditionalServieCall: (data) => { return dispatch(storeItem(data)) },
        onGetSingleAdditionalServiceCall: (id) => { dispatch(getSingleAdditionalItem(id)) },
        updateAdditionalServiceCall: (data, editableItem) => { return dispatch(updateAdditionalItem(data, editableItem)) },
        getItemTypesCall: () => { dispatch(getItemTypes()) },
        getItemCategoriesCall: () => { dispatch(getItemCategories()) },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdditionalServicesForm)
