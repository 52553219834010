import { SC } from '../helper/ServerCall';

export const roleService = {
    getUserRoles,
    getAgencyRoles,
    getRolesPagination,
    storeRole,
    getRole,
    editRole,
    deleteRole,
}

function getUserRoles() {
    return SC.getCall('getUserRoles');
}

function getAgencyRoles() {
    return SC.getCall('getAgencyRoles');
}
function getRolesPagination(page, filter, sort, pageSize) {
    return SC.getCall('roles?page=' + page + '&filter=' + filter + '&sort=' + sort + '&pageSize=' + pageSize);
}
function storeRole(roleData) {
    return SC.postCall('roles', roleData);
}
function getRole(roleId) {
    return SC.getCall('roles/' + roleId);
}
function editRole(roleData, roleId) {
    return SC.putCall('roles/' + roleId, roleData);
}
function deleteRole(roleId) {

    return SC.deleteCall('roles/' + roleId);
}