import React, { Component } from "react";
import InputMask from 'react-input-mask';
import { getPhoneFormat } from "../../helper/helperFunctions";
import User from "../../helper/User";

const PhoneFormat = getPhoneFormat('uk');
class phoneNumber extends Component {

	constructor(props) {
		super(props);
		this.state = {
			validatorArray : [
				{
					'regex': [/^[\d]{3}[-]?[\d]{3}[-]?[\d]{4}[-]?[\d]{0,6}$/]
				},
			],
            ukValidator : PhoneFormat[0]
		}

	}
	componentDidMount(){
		const { required } = this.props;
		if ( required ) {
			this.setState({ validatorArray: [...this.state.validatorArray, 'required'] })
			this.setState({ ukValidator: [...this.state.ukValidator, 'required'] })
		}
	}

	render(){
		const { validatorArray, ukValidator } = this.state;
		const { name, value, validationName } = this.props;

        let mask = PhoneFormat[1];
        let placeHolder = PhoneFormat[2];
		return(
			<React.Fragment>
				<InputMask
					mask={User.brand('domain_slug').includes('gm') ? mask : "999-999-9999-999999"}
					maskChar={null}
					type="text"
					name={name}
                    id={this.props.elemId}
                    onClick={this.props.handleParent}
					className="form-control phone_icon"
					placeholder={User.brand('domain_slug').includes('gm') ? placeHolder : "123-456-7890-123456"}
					value={value}
					onChange={this.props.handleParent}
				/>
				<div style={{width: '100%'}}>
				{
					this.props.validator &&
						this.props.validator.message(validationName, value, User.brand('domain_slug').includes('gm') ? ukValidator : validatorArray)
				}
				</div>
			</React.Fragment>
		);
	};
}



export default phoneNumber;
