import React, { Component,useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { getAgencyDocumentsPagination } from 'redux/actions/agency-resource-actions';
import { dateFormatMy } from 'helper/helperFunctions';
import { Button, Table } from "react-bootstrap";
import TreeView from '@mui/lab/TreeView';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import DoNotDisturbOnOutlinedIcon from '@mui/icons-material/DoNotDisturbOnOutlined';
import TreeItem from '@mui/lab/TreeItem';
import { SC } from 'helper/ServerCall';
import Select from 'react-select';
import AgencyDocuments from '../Agency/AgencyDocuments';
import { confirmAlert } from 'react-confirm-alert';
import ConfirmAlertForDelete from 'components/ThemeComponents/confirmAlertForDelete';
import Folder from '../Folder';
import AgencyFolder from '../AgencyFolder';
// const moment = window.moment;

class GlobalDocuments extends Component {
    constructor(props) {
        super(props)
        this.state = {
            agencyDocuments: [],
            order_folder_id: null,
            callData: [],
            addFolderModal: false,
            folderName: '',
            selectedFolder: 'Please select a folder',
            expanded: [],
            is_folder: true,
            folders:[],

        }
        this.getAgencyFolderResources(this.props.agency_id).then(response => {
            // console.log("responseGlobalDocs", response);
                this.setState({
                    callData: response.data.data,
                    folders: response.data.folder_array
                });
        })
    }


    getAgencyFolderResources = (id) => {
        return SC.getCall(`getAgencyFolderResources/${id}`);
    }


    componentDidMount() {
        this.props.getAgencyDocumentsPaginationCall(this.props.agency_id).then(response => {
            console.log('agency', response);
            this.setState({ agencyDocuments: response });
        });
    }
    confirmResourceDelete = (index, id) => {
        confirmAlert({
            customUI: ({ onClose }) => <ConfirmAlertForDelete typeName='Resource' onClosePro={onClose} deleteType={this.onResourceDeleteHandler.bind(this, index, id)} />
        })
    }
    storeFodlerhandler = (e) => {
        // console.log("value", e.target.value);
        // var folderName = this.state.folderName;
        // folderName = e.target.value;
        this.setState({
            folderName: e.target.value
        })
    }
    folderSubmitHandler = () => {
        if(this.state.folderName !== "") {
            const data = {name: this.state.folderName, order_id: this.props.agency_id}
            this.storeFolder(data).then(
                response => {

                    this.props.success(response.message);
                    this.setState({
                        addFolderModal: false,
                        folderName: ''
                    },()=>{
                        this.show(this.props.orderId).then(response =>{
                            this.setState({
                                callData: response.data.data,
                                folders: response.data.folder_array
                            });
                        })
                    });

                }
            );
        }
    }
    onFolderClick = (e, data, nodes, nodeId) => {
        const removeId = e.target.getAttribute('Mui-expanded');
        // console.log("removedId", e.target);
        // console.log("data", data);

        // console.log("nodes", nodes);
        const expandingNodes = nodes.filter(x => !this.state.expanded.includes(x));
        // console.log("expandingNodes", expandingNodes);
        // if ()
        // console.log(expandingNodes)
        this.setState({
            expanded:expandingNodes,
            selectedFolder: data.folder_name,
            order_folder_id: data.folder_id,
            is_folder: true
        })
    }
    storeFolder = (data) => {
        return SC.postCall('storeAgencyFolder' , data );
    }

    render() {
        let itemId = 1;
        const { header } = this.props;
        const { agencyDocuments, callData } = this.state;
        console.log(this.props.agency_id,"Agency id")
        return (
            <div>
                {
                callData ?
                <>
                <div class="secHeading">Agency Documents</div>
                <Table className="blueStyle" bordered striped size="sm">
                    <thead>
                        <tr className="border-bottom">
                            <td width="35%">File Name</td>
                            <td width="25%">Uploaded Date</td>
                            <td width="20%">Action</td>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            callData.map((item, index) => {
                                if (item.file_path) {
                                    return(
                                        <tr key={item.id}>
                                            <td><a style={{ wordBreak: 'break-all' }} className='blue_link' target="_blank" href={item.file_path} rel="noopener noreferrer">{item.original_file_name}</a></td>
                                            <td>{dateFormatMy(item.created_at)}</td>
                                            <td>
                                                <a className='ml-md' href={item.file_path}><i className='fa fa-download'></i></a>
                                            </td>
                                        </tr>
                                    )
                                }else{
                                    return null;
                                }
                            })
                        }
                    </tbody>
                </Table>
                {
                            callData &&

                            callData.map((item, key , index) => {
                                // console.log("index: " , key);
                                // console.log("item: " , item);
                                if (item.folder_id){
                                    return (
                                        <Folder key = {itemId} item = {item} updatebackend = {this.updatebackenddrag} ex = {this.state.expanded} onFolderClick = {this.onFolderClick} confirmResourceDelete = {this.confirmResourceDelete} noDelete = {true} dragable = {false}/>

                                    )
                                }
                            })

                        }
                </>
                : ''
                }
        </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getAgencyDocumentsPaginationCall: (agency_id) => { return dispatch(getAgencyDocumentsPagination(agency_id)) },
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GlobalDocuments))
