import { SC } from '../helper/ServerCall';

export const destinationService = {
    getDestinationsPagination,
    storeDestination,
    getDestination,
    editAllDestination,
    editDestination,
    deleteDestination,
    destinationOrders,
    assignOrderDestination
}

function getDestinationsPagination(page, filter, sort, pageSize, is_active) {
    return SC.getCall('destinations?page=' + page + '&filter=' + filter + '&sort=' + sort + '&pageSize=' + pageSize + '&is_active=' + is_active);
}
function storeDestination(destinationData) {
    return SC.postCall('destinations', destinationData);
}
function assignOrderDestination(destinationId, destinationData) {
    return SC.postCall('assignOrderDestination/' + destinationId, destinationData);
}
function getDestination(destinationId) {
    return SC.getCall('destinations/' + destinationId);
}
function destinationOrders(destinationId) {
    return SC.getCall('destinationOrders/' + destinationId);
}
function editDestination(destinationData, destinationId) {
    return SC.putCall('destinations/' + destinationId, destinationData);
}
function editAllDestination(destinationId, destinationData) {
    return SC.putCall('destinations/allData', destinationId, destinationData);
}
function deleteDestination(destinationId) {

    return SC.deleteCall('destinations/' + destinationId);
}

