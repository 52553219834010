import React, { Component } from 'react'
import { connect } from 'react-redux'
import { inputChangeHandler } from '../../../../helper/helperFunctions'
import { addComment, getCommentCategories, getCommentCategoryByTitle, editComment, getAllComments ,setCommentForOrder } from '../../../../redux/actions/comment-actions'
import { withRouter } from 'react-router-dom'
import User from '../../../../helper/User'
import { Roles } from '../../../../constants/Roles'

class Comment extends Component {
    constructor(props) {
        super(props)

        this.state = {
            comment: {
                comment: ""
            }
        }
        this.inputChangeHandler = inputChangeHandler.bind(this);
        this.props.getCommentCategoryByTitle(['TO/GL']);
    }

    componentDidUpdate(prevProps, prevState) {
        if ((this.props.submitComment !== prevProps.submitComment) && this.props.submitComment) {
            if (this.state.comment.comment !== "") {
                this.commentSubmitHandler();
            } else {
                if (User.hasRole(Roles.PHOTO_ADMIN)) {
                    if (this.props.orderOptions === undefined) {
                        this.props.history.push('/dashboard');
                    }
                } else {
                    if (this.props.formType === 'add') {
                        this.props.history.push('/thank-you', { barcode: this.props.createdOrderBarcode })
                    } else {
                        this.props.history.push('/order-history')
                    }
                }
            }
        }
        if (this.props.singleCommentCategory !== prevProps.singleCommentCategory) {
            if (this.props.formType === 'edit')
                this.props.getAllCommentsCall(1, '', '', 1, this.props.singleCommentCategory.comment_category_id, this.props.order_id, '', '');
        }
        // if (this.props.comments !== prevProps.comments) {
        //     this.setState(prevState => ({
        //         comment: { ...prevState.comment, ...this.props.comments.data[0] }
        //     }))
        // }
        if (this.state.comment.comment !== prevState.comment.comment){
              this.props.setCommentForOrder(this.state.comment.comment)
        }
    }

    commentSubmitHandler = () => {
        const validComment = this.state.comment.comment.trim();
        const data = {
            ...this.state.comment,
            comment_category_id: this.props.singleCommentCategory.comment_category_id
        }
        if(validComment.length > 0){
            if (this.state.comment.id) {
                this.props.editComment(data, data.id).then(
                    res => {
                        if (User.hasRole(Roles.PHOTO_ADMIN)) {
                            if (this.props.orderOptions === undefined) {
                                this.props.history.push('/dashboard');
                            }
                        } else {
                            this.props.history.push('/order-history')
                        }
                    }
                );
            } else {
                this.props.addComment(data, this.props.order_id).then(
                    res => {
                        if (User.hasRole(Roles.PHOTO_ADMIN)) {
                            if (this.props.orderOptions === undefined) {
                                this.props.history.push('/dashboard');
                            }
                        } else {
                            this.props.history.push('/order-history')
                        }
                    }
                );
            }
        } else {
            this.props.history.push('/order-history')
        }
    }

    render() {
        return (
            <textarea onChange={this.inputChangeHandler} name='comment.comment' type="text" value={this.state.comment.comment} rows="10" placeholder="Type Your Comment" className="form_tile_input tile_textarea block" />
        )
    }
}

const mapStateToProps = (state) => {
    const { commentCategories, singleCommentCategory, comments } = state.commentReducer;
    return {
        commentCategories,
        singleCommentCategory,
        comments
    };
}

const mapDispatchToProps = dispatch => {
    return {
        addComment: (data, modelId) => { return dispatch(addComment(data, modelId)) },
        getCommentCategoriesCall: () => { dispatch(getCommentCategories()) },
        getCommentCategoryByTitle: (titles) => { dispatch(getCommentCategoryByTitle(titles)) },
        editComment: (data, id, comments) => { return dispatch(editComment(data, id, comments)) },
        getAllCommentsCall: (page, filter, sort, pageSize, cat_id, modelId, from, to) => { dispatch(getAllComments(page, filter, sort, pageSize, cat_id, modelId, from, to)) },
        setCommentForOrder: (comment) => { return dispatch(setCommentForOrder(comment)) }
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Comment))
