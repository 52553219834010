import { Roles } from "../constants/Roles";
export default class User {

    static user = localStorage.getItem('user') !== null ? JSON.parse(localStorage.getItem('user')).user : undefined;
    // static shipped;

    static isAuthenticated() {
        if (this.user !== undefined) {
            return true;
        } else {
            return false;
        }
    }

    static hasRole(roles) {
        if (this.user !== undefined && this.user.role) {
            if (roles.includes(this.user.role.name)) {
                return true
            } else {
                return false
            }
        }
    }

    static roleExists(role) {
        var result = false;
        if (this.user !== undefined && this.user.roles) {
            let user_roles = this.user.roles.map(a => a.name);
            if(user_roles.includes(role)) {
                result = true;
            }
        }
        return result;
    }

    static agency(prop) {
        if (this.isAuthenticated() && this.hasRole([...Object.values(Roles)])) {
            if (prop in this.user.agency) {
                return this.user.agency[prop];
            }
        }
    }

    static orderSettingValue(prop) {
        if (this.isAuthenticated() && this.hasRole([...Object.values(Roles)])) {
            if (typeof this.user.order !== 'undefined') {
                if (prop in this.user.order.settings) {
                    return this.user.order.settings[prop].value;
                }
            }
        }
    }


    static agencyLogo(prop) {
        if (this.isAuthenticated() && this.hasRole([...Object.values(Roles)])) {
            if (typeof this.user.agency.agency_logo !== 'undefined' && this.user.agency) {
                if (typeof this.user.agency.agency_logo !== 'undefined' && this.user.agency.agency_logo) {
                    return this.user.agency.agency_logo.file_path;
                }
            }
            return null;
        }
    }


    static customerSlatesLogo(prop) {
        if (this.isAuthenticated() && this.hasRole([...Object.values(Roles)])) {
            if (typeof this.user.customerSlatesAgency !== 'undefined' && this.user.customerSlatesAgency) {
                if (typeof this.user.customerSlatesAgency.customer_slates_logo !== 'undefined' && this.user.customerSlatesAgency.customer_slates_logo) {
                    return this.user.customerSlatesAgency.customer_slates_logo.file_path;
                }
            }
                return null;
        }
    }


    static order(prop) {
        if (this.isAuthenticated() && this.hasRole([...Object.values(Roles)])) {
            if (typeof this.user.order !== 'undefined') {
                if (prop in this.user.order) {
                    return this.user.order[prop];
                }
            }
        }
    }

    static brand(prop) {
        if (this.user === undefined || this.user.brand === undefined || this.user.brand === null) {
            return null
        }
        if (prop in this.user.brand && this.hasRole([...Object.values(Roles)])) {
            return this.user.brand[prop];
        }
    }

    static getProperty(prop) {
        const param = prop.split('.');
        const parent = param[0];
        if (this.user === undefined || this.user[parent] === undefined || this.user[parent] == null) {
            return null;
        }
        if (1 in param) {
            const property = param[1];
            if (property in this.user[parent]) {
                return this.user[parent][property];
            }
        } else {
            if (parent in this.user) {
                return this.user[parent];
            }
        }

    }

    static logout() {
        localStorage.removeItem('user');
    }

}
