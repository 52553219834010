export const frontEndUserConstants = {
  LOGIN_FRONT_END_USER_REQUEST: "LOGIN_FRONT_END_USER_REQUEST",
  LOGIN_FRONT_END_USER_SUCCESS: "LOGIN_FRONT_END_USER_SUCCESS",
  LOGIN_FRONT_END_USER_FAILURE: "LOGIN_FRONT_END_USER_FAILURE",

  LOGOUT_FRONT_END_USER: "LOGOUT_FRONT_END_USER",

  GET_ADJUDICATORS_REQUEST: "GET_ADJUDICATORS_REQUEST",
  GET_ADJUDICATORS_SUCCESS: "GET_ADJUDICATORS_SUCCESS",
  GET_ADJUDICATORS_FAILURE: "GET_ADJUDICATORS_FAILURE",

  GET_ADJUDICATORS_RECORDINGS_REQUEST: "GET_ADJUDICATORS_RECORDINGS_REQUEST",
  GET_ADJUDICATORS_RECORDINGS_SUCCESS: "GET_ADJUDICATORS_RECORDINGS_SUCCESS",
  GET_ADJUDICATORS_RECORDINGS_FAILURE: "GET_ADJUDICATORS_RECORDINGS_FAILURE",

  FORGET_FRONT_END_USER_REQUEST: "FORGET_FRONT_END_USER_REQUEST",
  FORGET_FRONT_END_USER_SUCCESS: "FORGET_FRONT_END_USER_SUCCESS",
  FORGET_FRONT_END_USER_FAILURE: "FORGET_FRONT_END_USER_FAILURE",

  GET_AGENCY_ORDERS_REQUEST: "GET_AGENCY_ORDERS_REQUEST",
  GET_AGENCY_ORDERS_SUCCESS: "GET_AGENCY_ORDERS_SUCCESS",
  GET_AGENCY_ORDERS_FAILURE: "GET_AGENCY_ORDERS_FAILURE",

  GET_ORDER_UPLOAD_INFO_SUCCESS: "GET_ORDER_UPLOAD_INFO_SUCCESS",
  GET_ORDER_UPLOAD_INFO_FAILURE: "GET_ORDER_UPLOAD_INFO_FAILURE",

  GET_SINGLE_IMAGE_SUCCESS: "GET_SINGLE_IMAGE_SUCCESS",
  GET_SINGLE_IMAGE_FAILURE: "GET_SINGLE_IMAGE_FAILURE",

  ROTATE_IMAGE_SUCCESS: "ROTATE_IMAGE_SUCCESS",
  ROTATE_IMAGE_FAILURE: "ROTATE_IMAGE_FAILURE",

  GET_AGENCY_LOGO_REQUEST: "GET_AGENCY_LOGO_REQUEST",
  GET_AGENCY_LOGO_SUCCESS: "GET_AGENCY_LOGO_SUCCESS",
  GET_AGENCY_LOGO_FAILURE: "GET_AGENCY_LOGO_FAILURE",


  GET_ALL_TESTIMONIALS_SUCCESS: "GET_ALL_TESTIMONIALS_SUCCESS",
  GET_ALL_TESTIMONIALS_FAILURE: "GET_ALL_TESTIMONIALS_FAILURE",

  STORE_TESTIMONAIL_SUCCESS: "STORE_TESTIMONAIL_SUCCESS",
  STORE_TESTIMONAIL_FAILURE: "STORE_TESTIMONAIL_FAILURE",

  GET_AGENCY_RESOURCES_BY_ROLE_SUCCESS: "GET_AGENCY_RESOURCES_BY_ROLE_SUCCESS",
  GET_AGENCY_RESOURCES_BY_ROLE_FAILURE: "GET_AGENCY_RESOURCES_BY_ROLE_FAILURE",

  GET_ORDER_ITEMS_SUCCESS: "GET_ORDER_ITEMS_SUCCESS",
  GET_ORDER_ITEMS_FAILURE: "GET_ORDER_ITEMS_FAILURE",

  SHOW_PROGRESS: "SHOW_PROGRESS",
  RESET_PROGRESS: "RESET_PROGRESS",

  GET_SINGLE_COMMENT_CATEGORY_SUCCESS: "GET_SINGLE_COMMENT_CATEGORY_SUCCESS",
  GET_SINGLE_COMMENT_CATEGORY_FAILURE: "GET_SINGLE_COMMENT_CATEGORY_FAILURE",

  GET_SHIPPING_SETTINGS_SUCCESS: "GET_SHIPPING_SETTINGS_SUCCESS",
  GET_SHIPPING_SETTINGS_FAILURE: "GET_SHIPPING_SETTINGS_FAILURE",

  GET_ALL_MUSIC_SUCCESS: "GET_ALL_MUSIC_SUCCESS",
  GET_ALL_MUSIC_FAILURE: "GET_ALL_MUSIC_FAILURE",

  GET_BRANDING_INFO_SUCCESS: "GET_BRANDING_INFO_SUCCESS",
  GET_BRANDING_INFO_FAILURE: "GET_BRANDING_INFO_FAILURE",

  CLEAR_ORDER_RESOURCES_SUCCESS: "CLEAR_ORDER_RESOURCES_SUCCESS",
};
