import React, { Component } from "react";
import { connect } from 'react-redux';
import { checkOrderStatusWithBarcode } from "redux/actions/report-actions";
import SimpleReactValidator from "simple-react-validator";
import { Row, Col, Grid } from "react-bootstrap";
import { Card } from "components/Card/Card.jsx";
import 'react-table/react-table.css';
import { withRouter, Link } from 'react-router-dom';
class ScanBarcode extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator({ autoForceUpdate: this, locale: 'en' });
        this.state = {
            barcode: '',
            orderAlreadyReceived: false,
            cameraShipped: null,
            orderId: ''
        }
    }

    inputHandler = (e) => {
        const { name, value } = e.target;
        this.setState({
            [name]: value
        })
    }
    handleOnSubmit = async (e) => {
        if (this.validator.allValid()) {
            const res = await this.props.checkOrderStatusWithBarcodeCall({ barcode: this.state.barcode });
            if (res.data.order !== null) {
                if (res.data) {
                    let cameraShipped = null
                    let orderAlreadyReceived = false;
                    // if (!res.data.cameraShipped) {
                    //     cameraShipped = false;
                    // } else if (res.data.cameraShipped) {
                    //     cameraShipped = true
                    // }
                    if (res.data.status) {
                        orderAlreadyReceived = true
                    }
                    if (orderAlreadyReceived === false) {
                        this.props.history.push(`/Admin/${res.data.order.id}/lab-production-report`)
                    }
                    this.setState({
                        cameraShipped: cameraShipped,
                        orderAlreadyReceived: orderAlreadyReceived,
                        orderId: res.data.order.id
                    })
                }
            }
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }
    render() {
        const { orderAlreadyReceived/*, cameraShipped*/ } = this.state;
        return (
            <div className="content">
                <Grid fluid>
                    <Row>
                        <Col md={12}>
                            <Card
                                ctTableResponsive
                                content={
                                    <div>
                                        <Grid fluid>
                                            <div className="row">
                                                <div className="col-md-4 col-md-offset-4 mt-lg">
                                                    <div style={{ color: 'blue' }}>{this.props.orderReceiveMessage}</div>
                                                    <div>Please scan or enter barcode: </div>
                                                    <div>
                                                        <input className="form-control mt-xs" type="text" name="barcode" onChange={this.inputHandler} />
                                                        {
                                                            this.validator.message('Barcode', this.state.barcode, 'required')
                                                        }
                                                    </div>
                                                    <div className="text-center mb-xlg">
                                                        <button className='customBtn btn btn-primary block mt-md' onClick={this.handleOnSubmit}>Submit</button>
                                                    </div>
                                                    {
                                                        orderAlreadyReceived &&
                                                        <p className='mt-md'>ORDER HAS ALREADY BEEN RECEIVED <Link className='blue_link' to={`/Admin/${this.state.orderId}/lab-production-report`}>EDIT/PRINT LAB PROCESSING REPORT</Link></p>
                                                    }
                                                    {/* {
                                                        (cameraShipped !== null && cameraShipped === false) &&
                                                        <p className='mt-md'>THE CAMERAS FOR ORDER <strong>{this.state.barcode}</strong> HAVE NOT BEEN SHIPPED OUT. YOU CAN NOT RECEIVE IT YET</p>
                                                    } */}
                                                </div>
                                            </div>
                                        </Grid>
                                    </div>
                                } />
                        </Col>
                    </Row>
                </Grid>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return ({
        checkOrderStatusWithBarcodeCall: (data) => { return dispatch(checkOrderStatusWithBarcode(data)) },//example function
    });
};

function mapStateToProps(state) {
    const { orderReceivedStatus, orderReceiveMessage, order } = state.reportReducer;
    return {
        orderReceivedStatus, orderReceiveMessage, order
    };
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ScanBarcode));
