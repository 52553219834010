import { adminConstants } from '../constant/admin-constant';
import { alertActions } from './alert-actions';
import { permissionService } from '../../services/permission';

export function getAllPermissions() {
    return dispatch => {
        dispatch(request());
        permissionService.getAllPermissions()
            .then(
                response => {
                    dispatch(success(response));
                    // dispatch(alertActions.success(response.message));
                },
                error => {
                    dispatch(failure(error));
                    // dispatch(alertActions.error(error));
                }
            );
    }

    function request() { return { type: adminConstants.GET_ALL_PERMISSION_REQUEST } }
    function success(response) { return { type: adminConstants.GET_ALL_PERMISSION_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.GET_ALL_PERMISSION_FAILURE, payload: error } }
}

export function getPermissionsPagination(page, filter, sort, pageSize) {
    return dispatch => {
        dispatch(request())
        return permissionService.getPermissionsPagination(page, filter, sort, pageSize)
            .then(
                response => {
                    dispatch(success(response));
                    return response;
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request() { return { type: adminConstants.PAGINATION_PERMISSIONS_REQUEST } }
    function success(response) { return { type: adminConstants.PAGINATION_PERMISSIONS_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.PAGINATION_PERMISSIONS_FAILURE, payload: error } }
}
export function storePermission(PermissionData) {
    return dispatch => {
        dispatch(request());
        permissionService.storePermission(PermissionData)
            .then(
                response => {
                    dispatch(success(response));
                    dispatch(alertActions.success(response.message));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    }

    function request() { return { type: adminConstants.STORE_PERMISSION_REQUEST } }
    function success(response) { return { type: adminConstants.STORE_PERMISSION_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.STORE_PERMISSION_FAILURE, payload: error } }
}
export function getPermission(id) {
    return dispatch => {
        dispatch(request());
        permissionService.getPermission(id)
            .then(
                Permission => {
                    dispatch(success(Permission));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    }

    function request() { return { type: adminConstants.GET_PERMISSION_REQUEST } }
    function success(Permission) { return { type: adminConstants.GET_PERMISSION_SUCCESS, payload: Permission } }
    function failure(error) { return { type: adminConstants.GET_PERMISSION_FAILURE, payload: error } }
}
export function editPermission(PermissionData, permissionId) {
    console.log(PermissionData);

    return dispatch => {
        dispatch(request());
        permissionService.editPermission(PermissionData, permissionId)
            .then(
                response => {
                    dispatch(success(response));
                    dispatch(alertActions.success(response.message));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    }

    function request() { return { type: adminConstants.EDIT_PERMISSION_REQUEST } }
    function success(response) { return { type: adminConstants.EDIT_PERMISSION_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.EDIT_PERMISSION_FAILURE, payload: error } }
}
export function deletePermission(permissionId) {
    return dispatch => {
        dispatch(request());
        permissionService.deletePermission(permissionId)
            .then(
                response => {
                    dispatch(success(response));
                    dispatch(alertActions.success(response.message));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error.message));
                }
            );
    }

    function request() { return { type: adminConstants.DELETE_PERMISSION_REQUEST } }
    function success(response) { return { type: adminConstants.DELETE_PERMISSION_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.DELETE_PERMISSION_FAILURE, payload: error } }
}

export function getAllParentLabel() {
    return dispatch => {
        dispatch(request());
        permissionService.getAllParentLabel()
            .then(
                response => {
                    dispatch(success(response));
                    // dispatch(alertActions.success(response.message));
                },
                error => {
                    dispatch(failure(error));
                    // dispatch(alertActions.error(error));
                }
            );
    }

    function request() { return { type: adminConstants.GET_ALL_PARENT_LABEL_REQUEST } }
    function success(response) { return { type: adminConstants.GET_ALL_PARENT_LABEL_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.GET_ALL_PARENT_LABEL_FAILURE, payload: error } }
}
export function getAlreadyExistingOrders() {
    return dispatch => {
        dispatch(request());
        permissionService.getAlreadyExistingOrders()
            .then(
                Permission => {
                    dispatch(success(Permission));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    }

    function request() { return { type: adminConstants.GET_ALREADY_EXISTING_ORDER_REQUEST } }
    function success(Permission) { return { type: adminConstants.GET_ALREADY_EXISTING_ORDER_SUCCESS, payload: Permission } }
    function failure(error) { return { type: adminConstants.GET_ALREADY_EXISTING_ORDER_FAILURE, payload: error } }
}