import React, { Component } from 'react'
import { connect } from 'react-redux'
import Validator from 'simple-react-validator'
import { storeTestimonial } from '../../../redux/actions/testimonials';
import User from '../../../helper/User';

class TestimonialForm extends Component {
    constructor(props) {
        super(props)

        this.state = {
            author: '',
            description: '',
            company: ''
        }
        this.inputChangeHandler = this.inputChangeHandler.bind(this);
        this.submitHandler = this.submitHandler.bind(this);
        this.Validator = new Validator();
    }

    inputChangeHandler(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    submitHandler() {
        if (this.Validator.allValid()) {
            const data = { ...this.state, brand_id: User.brand('id') };
            this.props.storeTestimonial(data).then(
                response => {
                    const state = { ...this.state };
                    Object.keys(state).forEach((item) => { state[item] = '' });
                    console.log(state);
                    this.setState({
                        ...state
                    })
                }
            );
        } else {
            this.Validator.showMessages();
            this.forceUpdate();
        }
    }

    render() {
        return (
            <div className="col-md-8 col-md-offset-2 mt-xlg">
                <div className="liveChatBanner">
                    <div>
                        <p>Submit Your <span className="text-white">Testimonial</span></p>
                    </div>
                </div>

                <div className="contactFormContainer mt-xlg">
                    <div className="flexElem respFlex">
                        <div className="hasFieldIcon">
                            <span className="fieldIcon userIcon">&nbsp;</span>
                            <input name='author' value={this.state.author} onChange={this.inputChangeHandler} className='form-control' type='text' placeholder='Full Name' />

                            <div>{this.Validator.message('Full Name', this.state.author, 'required')}</div>
                        </div>

                        <div className="hasFieldIcon">
                            <span className="fieldIcon companyIcon">&nbsp;</span>
                            <input  name='company' value={this.state.company} onChange={this.inputChangeHandler} className='form-control' type='text' placeholder="Tour Company Name or Group Name" />
                            <div>{this.Validator.message('Company/Group  Name', this.state.company, 'required')}</div>
                        </div>
                    </div>

                    <div className="flexElem block respFlex">
                        <div>
                            <textarea value={this.state.description} name='description' onChange={this.inputChangeHandler} className='form-control' style={{minHeight: "130px"}} placeholder="Your Testimonial"></textarea>
                            <div>{this.Validator.message('Testimonial', this.state.description, 'required')}</div>
                        </div>
                    </div>
                    
                    <div className="flexElem block respFlex">
                        <div className="text-center">
                            <button type="button" onClick={this.submitHandler} className="themeBtn_14 no_radius hover_dark red_gradient">Submit Testimonial</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        storeTestimonial: (data) => { return dispatch(storeTestimonial(data)) }
    }
}

export default connect(null, mapDispatchToProps)(TestimonialForm)
