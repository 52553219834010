import React from 'react';
import {
	Button
} from "react-bootstrap";

import SimpleReactValidator from 'simple-react-validator';
import Select from 'react-select';
import { returnExtFromImage, returnLabelsWithValues } from 'helper/helperFunctions';
import FileUpload from "components/Upload/FileUpload";
import ImagePreview from "components/Upload/ImagePreview";

class UserForm extends React.Component {
	constructor(props) {
		super(props);
		this.validatorUser = new SimpleReactValidator({ autoForceUpdate: this, locale: 'en' });
		this.state = {
			user: {
				...this.props.userPro,
				password: '',
				confirm_password: ''
			},
			submitted: false,
			formType: this.props.formTypePro || '',
			allowedFormatsStringMime: 'image/jpeg, image/png',
			allowedFormatsStringExt: 'jpg, png',
		};

		this.handleUser = this.handleUser.bind(this);
		this.handleUserSubmitForm = this.handleUserSubmitForm.bind(this);
		this.handleChangeRole = this.handleChangeRole.bind(this);
	}
	handleChangeRole(selectedOption, name) {
		if (name.action === 'select-option') {
			this.setState(prevState => ({
				user: {
					...prevState.user,
					roles: [...prevState.user.roles, name.option.value]
				}
			}));
		}
		if (name.action === 'remove-value') {
			const rolesTemp = this.state.user.roles;
			rolesTemp.splice(rolesTemp.indexOf(name.removedValue.value), 1);
			this.setState(prevState => ({
				user: {
					...prevState.user,
					roles: rolesTemp
				}
			}))
		}
	}
	handleUser(e) {
		const { name, value, type } = e.target;
		const userState = { ...this.state.user };
		userState[name] = type === 'checkbox' ? e.target.checked ? true : false : value;
		this.setState({ user: userState });
	}
	handleUserSubmitForm(e) {
		e.preventDefault();
		if (this.validatorUser.allValid()) {
			this.setState({ submitted: true }, function () {
				this.props.handleUserSubmit(this.state.user, this.state.formType);
			});
		} else {
			this.setState({ submitted: false });
			this.validatorUser.showMessages();
			this.forceUpdate();
		}
	}
	extractImageData = (value) => {
		const file = {};
		file['file'] = value['file'];
		file['name'] = value['name'];
		file['size'] = value['size'];
		file['extension'] = returnExtFromImage(value['name']);
		file['preview'] = value['preview'];
		file['type'] = value['type'];
		file['lastModified'] = value['lastModified'];
		return file;
	}
	setResourceFile = (key, value) => {
		this.setState({
			user: { ...this.state.user, image: this.extractImageData(value) },
		});
	}
	removeFile = (key, index) => {
		this.setState({
			user: { ...this.state.user, image: null },
		});
	}
	render() {
		const { user, formType, allowedFormatsStringMime, allowedFormatsStringExt } = this.state;
		const { roles } = this.props;
		return (
			<form onSubmit={this.handleUserSubmitForm}>
				<div className="row">
					<div className="col-md-6">
						<div className='form-group'>
							<label htmlFor="name">Name<span className="requiredClass">*</span></label>
							<input type="text" name="name" className="form-control" value={user.name} onChange={this.handleUser} />
							{this.validatorUser.message('userName', user.name, 'required|min:5')}
						</div>
					</div>

					<div className="col-md-6">
						<div className='form-group'>
							<label htmlFor="username">User Name<span className="requiredClass">*</span></label>
							<input type="text" name="username" className="form-control" value={user.username} onChange={this.handleUser} />
							{
								/*
								this.validatorUser.message('userName', user.username, 'required|min:5')
								*/
							}
						</div>
					</div>


					<div className="col-md-6">
						<div className='form-group'>
							<label htmlFor="email">Email<span className="requiredClass">*</span></label>
							<input type="text" name="email" className="form-control email_icon" value={user.email} onChange={this.handleUser} /*readOnly={formType === 'edit'?true:false}*/ />
							{this.validatorUser.message('email', user.email, 'required|email')}
						</div>
					</div>

					<div className="col-md-6">
						<div className='form-group'>
							<label htmlFor="name">Select Access Level<span className="requiredClass">*</span></label>
							<Select
								value={returnLabelsWithValues(user.roles, roles)}
								onChange={this.handleChangeRole}
								options={roles}
								isSearchable={true}
								isMulti={true}
								placeholder={'Select Access Level'}
								isClearable={false}
							/>
							{this.validatorUser.message('roles', user.roles, 'required|min:1,array')}
						</div>
					</div>



					<div className="col-md-6">
						<div className='form-group'>
							<label htmlFor="password">Password<span className="requiredClass">*</span></label>
							<input type="password" autoComplete="off" name="password" className="form-control password_icon" value={user.password} onChange={this.handleUser} />
							{formType === 'add' &&
								this.validatorUser.message('password', user.password, 'required|min:5')
							}
						</div>
					</div>
					<div className="col-md-6">
						<div className='form-group'>
							<label htmlFor="confirm_password">Confirm Password<span className="requiredClass">*</span></label>
							<input type="password" autoComplete="off" name="confirm_password" className="form-control password_icon" value={user.confirm_password} onChange={this.handleUser} />
							{formType === 'add' &&
								this.validatorUser.message('confirm_password', user.confirm_password, 'required|min:5')
							}
						</div>
					</div>

					<div className="col-md-6">
						<div className=''>
							<label className="container-check checkbox_down"> Is Active?
	                  <input type="checkbox" name="is_active" className="checkbox" value={user.is_active ? 1 : 0} onChange={this.handleUser} checked={user.is_active ? 1 : 0} />
								<span className="checkmark"></span>
							</label>
							{/* {this.validatorPermission.message('is_active', user.is_active, 'required')} */}
						</div>
					</div>

					<div className="col-md-6 pofilePicContainer">
						{!this.state.user.image ?
							<div className='form-group dropzone_profileImage'>

								<FileUpload
									files={this.state.user.image}
									setResourceFile={this.setResourceFile}
									allowedFormatsStringMimePro={allowedFormatsStringMime}
									allowedFormatsStringExtPro={allowedFormatsStringExt}
									multiple={false}
								/>
							</div>
							:
							<ImagePreview
								filesToPreview={this.state.user.image}
								removeFile={this.removeFile}
							/>
						}
					</div>
				</div>

				<div className="row">
					<div className="col-md-12">
						<div className='text-center'>
							<Button type='submit' bsSize="large" bsStyle="info" className="backButton pt-sm no_radius pb-sm success btn btn-lg btn-info ml-sm mt-sm btn-default " >Save</Button>
							<Button bsSize="large" bsStyle="info" onClick={() => this.props.closeModel()} className="backButton pt-sm no_radius pb-sm primary btn btn-lg ml-sm mt-sm btn-info " >Cancel</Button>
						</div>
					</div>
				</div>
			</form>
		);
	}
}


export default UserForm;