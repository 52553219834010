import { adminConstants } from "../constant/admin-constant";
import { objectsConstants } from '../constant/objects-constant';
import { frontEndUserConstants } from "../constant/front-end-constant";

const initialState = {
    commentCategories: [],
    comment_request: false,
    comments: {},
    add_comment: objectsConstants.ADDCOMMENT,
    submitted: false,
    success: false,
    contactLogTypes: [],
}

export function commentReducer(state = initialState, action) {
    switch (action.type) {
        case adminConstants.ADD_COMMENT: {
            return {
                ...state,
                add_comment: action.payload
            }
        }
        case adminConstants.ADD_COMMENT_REQUEST:
			return {
				...state, comment_request: true
			}
        case adminConstants.GET_COMMENT_CATEGORIES_SUCCESS: {
            return {
                ...state,
                // commentCategories: action.payload.comment_categories
                commentCategories: action.payload && action.payload.comment_categories ? action.payload.comment_categories : initialState.commentCategories
            }
        }
        case adminConstants.GET_COMMENT_CATEGORIES_FAILURE:
            return {
                ...state,
                error: action.payload
            }
        case adminConstants.GET_ALL_COMMENTS_SUCCESS:
            return {
                ...state,
                comments: action.payload.data.pagination
            }
        case adminConstants.DELETE_COMMENT_SUCCESS:
            return {
                ...state,
                comments: {
                    ...state.comments,
                    data: action.payload
                }
            }
        case adminConstants.DELETE_COMMENT_FAILURE:
            return {
                ...state,
                error: action.payload
            }
        case adminConstants.ADD_COMMENT_SUCCESS:
            return {
                ...state,
                comments: {
                    ...state.comments,
                    data: action.payload
                },
            }
        case adminConstants.ADD_COMMENT_FAILURE: {
            return {
                ...state,
                error: action.payload,
            }
        }
        case adminConstants.EDIT_COMMENT_SUCCESS:
            return {
                ...state,
                comments: {
                    ...state.comments,
                    data: action.payload
                }
            }
        case adminConstants.EDIT_COMMENT_FAILURE:
            return {
                ...state,
                error: action.payload,
            }
        case adminConstants.TOGGLE_COMMENT_HIGHLIGHT_SUCCESS:
            return {
                ...state,
                comments: {
                    ...state.comments,
                    data: action.comments
                }
            }
        case adminConstants.TOGGLE_COMMENT_HIGHLIGHT_FAILURE:
            return {
                ...state,
                error: action.payload
            }

        case adminConstants.GET_CONTACT_LOG_TYPES_REQUEST:
            return { ...state };
        case adminConstants.GET_CONTACT_LOG_TYPES_SUCCESS:
            return { ...state, contactLogTypes: action.payload.contactLogTypes };
        case adminConstants.GET_CONTACT_LOG_TYPES_FAILURE:
            return { ...state, error: action.payload };


        case frontEndUserConstants.GET_SINGLE_COMMENT_CATEGORY_SUCCESS:
            return {
                ...state,
                singleCommentCategory: action.payload.data
            }
        default:
            return state;
    }
}
