import React from 'react';

export default function videoIcon (props) {
    return (<svg stroke={props.svgColor} className={props.iconClass} fill={props.svgColor} strokeWidth="0" viewBox="0 0 496 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
        <defs>  
            <linearGradient id={props.svgID} x1="50%" y1="0%" x2="50%" y2="100%" > 
                
                <stop offset="0%" stopColor="#7A5FFF">
                    <animate attributeName="stopColor" values="#7A5FFF; #01FF89; #7A5FFF" dur="2.5s" repeatCount="indefinite"></animate>
                </stop>

                <stop offset="100%" stopColor="#01FF89">
                    <animate attributeName="stopColor" values="#01FF89; #7A5FFF; #01FF89" dur="2.5s" repeatCount="indefinite"></animate>
                </stop>

            </linearGradient> 

        </defs>
        <path d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zM88 256H56c0-105.9 86.1-192 192-192v32c-88.2 0-160 71.8-160 160zm160 96c-53 0-96-43-96-96s43-96 96-96 96 43 96 96-43 96-96 96zm0-128c-17.7 0-32 14.3-32 32s14.3 32 32 32 32-14.3 32-32-14.3-32-32-32z"></path>
    </svg>);
}