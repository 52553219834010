import React from 'react';
import {
	Button
} from "react-bootstrap";
import { connect } from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';
import { storeFaq, editFaq } from 'redux/actions/brand-actions';
import Select from 'react-select'
import CKEditor from "react-ckeditor-component";
import { getBrands } from '../../../../redux/actions/account-action';
import { selectArrayChangeHandler } from '../../../../helper/helperFunctions';

class FaqFormPage extends React.Component {
	constructor(props) {
		super(props);
		this.validatorFaq = new SimpleReactValidator({ autoForceUpdate: this, locale: 'en' });
		this.state = {
			faq: {
				...this.props.faqPro
			},
			submitted: false,
			formType: this.props.formTypePro || '',
			content: '',
			selectedBrands: [],
		};

		this.handleFaq = this.handleFaq.bind(this);
		this.handleFaqSubmit = this.handleFaqSubmit.bind(this);
		this.selectArrayChangeHandler = selectArrayChangeHandler.bind(this);

		// this.props.getFaqsCategoriesCall();
	}

	componentDidMount() {
		this.props.getBrandsCall();
	}


	handleFaq(e) {
		const { name, value } = e.target;
		const faqState = { ...this.state.faq };
		faqState[name] = value;
		this.setState({ faq: faqState });
	}
	handleFaqSubmit(e) {
		e.preventDefault();
		if (this.validatorFaq.allValid()) {
			this.setState({ submitted: true }, function () {
				const { formType, faq, selectedBrands } = this.state;
				if (formType === 'add') {
					const data = { ...faq, selectedBrands };
					this.props.storeFaqCall(faq.account_id, data).then(
						response => {
							this.props.closeModel();
						}
					);
				}
				else if (formType === 'edit') {
					this.props.editFaqCall(faq.account_id, faq, faq.id).then(
						response => {
							this.props.closeModel();
						}
					);
				}
			});
		} else {
			this.setState({ submitted: false });
			this.validatorFaq.showMessages();
			this.forceUpdate();
		}
	}
	handleChangeLocation = (selectedOptionType, info) => {
		if (info['action'] === 'select-option') {
			this.setState({ faq: { ...this.state.faq, category_id: selectedOptionType['id'] } }, () => {
				console.log('faq state', this.state.faq)
			})
		}
	}

	onChange = (key) => (evt) => {
		console.log("1: ", key);
		console.log("onChange fired with event info: ", evt);
		var newContent = evt.editor.getData();
		this.setState({ faq: { ...this.state.faq, [key]: newContent } })
		// this.setState({
		//  	content: newContent
		// })
	}

	onBlur = (evt) => {
		console.log("onBlur event called with event info: ", evt);
	}

	afterPaste = (evt) => {
		console.log("afterPaste event called with event info: ", evt);
	}

	render() {
		const { faq, selectedBrands } = this.state;
		const { brands } = this.props
		return (
			<form >
				<div className="row">
					<div className="col-md-12">
						<div className='form-group'>
							<label htmlFor="name">Question<span className="requiredClass">*</span></label>
							<input type="text" name="question" className="form-control" value={faq.question} onChange={this.handleFaq} />
							{this.validatorFaq.message('faqName', faq.question, 'required')}
						</div>
					</div>
					<div className="col-md-12">
						<label htmlFor="">Platform<span className="requiredClass">*</span></label>
						<div className='form-group'>
							<label className='radio-inline'><input checked={Number(faq.is_web) === 1} onChange={this.handleFaq} type="radio" name="is_web" id="optionsRadios1" value='1' />Web</label>
							<label className='radio-inline'><input checked={Number(faq.is_web) === 0} onChange={this.handleFaq} type="radio" name="is_web" id="optionsRadios2" value='0' />Mobile</label>
						</div>
						{this.validatorFaq.message('faqName', faq.question, 'required')}
					</div>

					<div className="col-md-12">
						<div className='form-group'>
							<label htmlFor="name">Answer<span className="requiredClass">*</span></label>
							<CKEditor
								activeClass="p10"
								content={faq.answer}
								events={{
									// "blur": this.onBlur,
									// "afterPaste": this.afterPaste,
									"change": this.onChange('answer')
								}}
							/>
							{this.validatorFaq.message('faqName', faq.answer, 'required')}
						</div>
					</div>
					{
						this.state.formType === 'add' &&
						<div className='col-md-12'>
							<div className='form-group'>
								<Select
									isSearchable={false}
									isMulti={true}
									options={brands}
									name='selectedBrands'
									onChange={this.selectArrayChangeHandler}
									placeholder='Select Brands'
								/>
							</div>
							{this.validatorFaq.message('selectedBrands', selectedBrands, 'required')}
						</div>
					}
					{
						/*

					<div className="col-md-6">
						<div className='form-group'>
							<label htmlFor="name">Select Category<span className="requiredClass">*</span></label>
							<Select
								value={
									faq.category_id ? {
										id:HF.returnColumnValueWithColumnMatch( faqsCategories, 'id', [faq.category_id], 'id' )  , 
										name: HF.returnColumnValueWithColumnMatch(faqsCategories, 'name', [faq.category_id], 'id')
									} : null
								}
								options={ faqsCategories }
								onChange={this.handleChangeLocation}
								getOptionLabel={(option) => option.name}
								getOptionValue={(option) => option.id}
								className="selectUser"
								placeholder="Select Faq Category"
								isMulti={false}
							/>
							{this.validatorFaq.message('faqCategory', faq.category_id, 'required')}
						</div>
					</div>

					*/
					}
				</div>
				<div className="row">
					<div className="col-md-12">
						<div className='text-center'>
							<Button onClick={this.handleFaqSubmit} bsSize="large" bsStyle="info" className="backButton pt-sm no_radius pb-sm success btn btn-lg btn-info ml-sm mt-sm btn-default  btn btn-lg btn-info" >Save</Button>
							<Button bsSize="large" bsStyle="info" onClick={() => this.props.closeModel()} className="backButton pt-sm no_radius pb-sm primary btn btn-lg ml-sm mt-sm btn-info " >Cancel</Button>
						</div>
					</div>
				</div>
			</form>
		);
	}
}


const mapDispatchToProps = dispatch => {
	return ({
		storeFaqCall: (accountId, faqData) => { return dispatch(storeFaq(accountId, faqData)) },
		editFaqCall: (accountId, faqData, id) => { return dispatch(editFaq(accountId, faqData, id)) },
		getBrandsCall: () => { dispatch(getBrands()) },
		// getFaqsCategoriesCall: () => { dispatch(getFaqsCategories()) },
	});
};

function mapStateToProps(state) {
	const { loadingFaq, faqsCategories } = state.brandsReducer;
	const { brands } = state.accountReducer;
	return {
		loadingFaq, faqsCategories, brands
	};
};

const FaqForm = connect(mapStateToProps, mapDispatchToProps)(FaqFormPage);

export default FaqForm;