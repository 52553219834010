import React, { Component } from 'react';
import { connect } from 'react-redux'
import { getMessage } from 'redux/actions/front-end-action';
import AppointmentPicker from 'appointment-picker';
import '../../../node_modules/appointment-picker/dist/appointment-picker.css';

class ShowMessage extends Component {
  constructor(props) {
    super(props);

    const { slug, orderId, glName } = this.props.match.params;

    this.state = {
      content: '',
      time: {},
      slug: slug,
      orderId: orderId,
      glName: glName,
    };

    if (slug === 'showSchedular') {
      this.pickerRef = React.createRef();
      this.onTimeSelect = this.onTimeSelect.bind(this);
    }


    if (slug !== 'showSchedular') {
      this.props.getMessagCall({ slug: slug, orderId: orderId, glName: glName }).then(response => {
        this.setState({ content: response.content }, () => {
          console.log('state content', this.state.content);
        });
      });
    }
  }

  onTimeSelect(event) {
    console.log('change.appo.picker', event.time);
    this.setState({ time: event.time });
    // Or do something different with your time object
  }

  componentDidMount() {
    if (this.state.slug === 'showSchedular') {
      this.picker = new AppointmentPicker(this.pickerRef.current, {
        interval: '30',
        mode: '12h',
        startTime: '09',
        endTime: '24'
      });
      this.pickerRef.current.addEventListener('change.appo.picker', this.onTimeSelect);
    }
  }




  render() {
    const { slug } = this.state;
    return (
      <React.Fragment>
        {
          slug === 'showSchedular' &&
          <input type="text" ref={this.pickerRef}></input>
        }
      </React.Fragment>
    );
  }
}
const mapStateToProps = state => {
  return {}
}

const mapDispatchToProps = dispatch => {
  return {
    getMessagCall: (data) => { return dispatch(getMessage(data)) },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ShowMessage)