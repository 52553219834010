import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Grid,
  Row,
  Col,
  // Tooltip,
  OverlayTrigger,
  // Button,
  Popover
} from "react-bootstrap";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Card } from "components/Card/Card.jsx";
import { getAgencyUsersPagination, getAgencyUser, storeAgencyUser, editAgencyUser, deleteAgencyUser } from 'redux/actions/agency-user-actions';
import { getAgencyRoles } from 'redux/actions/role-actions';
import AgencyUserForm from "components/Admin/AgencyUsers/AgencyUserForm";
import { hasPermission } from 'helper/hasPermission';
import Confirmalertfordelete from 'components/ThemeComponents/confirmAlertForDelete';
import { adminLabels } from 'redux/constant/admin-label-constant';
import OpenModalButton from 'components/ThemeComponents/openModelButton';
import { objectsConstants as OC } from 'redux/constant/objects-constant';
import { clean, hasRole, openModal, closeModal } from 'helper/helperFunctions';
import Pagination from 'components/ThemeComponents/Pagination';
import { updateUserStatus, showPassword } from 'redux/actions/user-actions';
import ShowPassword from 'components/ThemeComponents/showPassword';
import { AiOutlineEye } from 'react-icons/ai';
var Modal = require('react-bootstrap-modal');

class AgencyUserListPage extends Component {
  constructor(props) {
    super(props);
    const { id } = this.props.match.params;
    this.state = {
      user: OC.AGENCYUSER,
      addModalAgencyUser: false,
      editModalAgencyUser: false,
      agency_id: id,
      agencyName: '',
    };

    this.openModalAgencyUserEdit = this.openModalAgencyUserEdit.bind(this);
    this.deleteAgencyUser = this.deleteAgencyUser.bind(this);
    this.deleteConfirmAgencyUser = this.deleteConfirmAgencyUser.bind(this);
    this.handleAgencyUserSubmit = this.handleAgencyUserSubmit.bind(this);
    this.clean = clean.bind(this);
    this.props.getAllRolesCall();
  };

  handleAgencyUserSubmit(userData, formType) {
    if (formType === 'add')
      this.props.storeAgencyUserCall(this.state.agency_id, userData).then(response => {
        this.setState({ addModalAgencyUser: false }, function () {
          this.pagination.dataCall();
        });
      });
    else if (formType === 'edit')
      this.props.editAgencyUserCall(userData, this.props.editAgencyUser.id, this.state.agency_id).then(response => {
        this.setState({ editModalAgencyUser: false }, function () {
          this.pagination.dataCall();
        });
      });
  }

  openModalAgencyUserEdit(id) {
    this.props.getAgencyUserCall(this.state.agency_id, id);
  }
  deleteAgencyUser(userId) {
    this.props.deleteAgencyUserCall(this.state.agency_id, userId).then(response => {
      this.pagination.dataCall();
    });
  }
  deleteConfirmAgencyUser(userId) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <Confirmalertfordelete typeName="Agency User" description="Are you sure to delete the admin agency user?" onClosePro={onClose} deleteType={() => this.deleteAgencyUser(userId)} />
        )
      }
    })
  }
  paginationCall = (data) => {
    return this.props.getAgencyUsersCall(this.state.agency_id, data.page, data.filter, data.sort, data.pageSize).then(response => {
      this.setState({ agencyName: response.data.agency });
      return response;
    });
  }
  componentDidUpdate(prevProps, nextProps) {
    if ((prevProps.editAgencyUser !== this.props.editAgencyUser) && !this.props.loadingAgencyUser) {
      this.setState({
        user: {
          name: this.props.editAgencyUser.name,
          username: this.props.editAgencyUser.username,
          fax: this.props.editAgencyUser.contact ? this.clean(this.props.editAgencyUser.contact.fax) : "",
          phone1: this.props.editAgencyUser.contact ? this.props.editAgencyUser.contact.phone1 : this.props.editAgencyUser.phone1,
          phone2: this.props.editAgencyUser.contact ? this.props.editAgencyUser.contact.phone2 : "",
          email: this.props.editAgencyUser.email,
          password: '',
          confirm_password: '',
          agencyRoles: this.props.editAgencyUser.agencyRoles,
          is_active: this.props.editAgencyUser.is_active,
        }
      }, function () {
        this.setState({ editModalAgencyUser: true });
      });
    }
  }

  handleUpdateUser = userId => (e) => {
    this.props.updateUserStatusCall(userId, { is_active: e.target.checked ? true : false }).then(
      response => {
        this.pagination.dataCall();
        // this.props.usersPaginationData();
      }
    );
  }

  showPasswordModal = (userId) => {
    this.props.showPasswordCall(userId).then(response => {

      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <ShowPassword description={`Password for ${response.user.username} : `} user={response.user} onClosePro={onClose} />
          )
        }
      })
    })
  }

  render() {
    const { user, agencyName } = this.state;
    const { editAgencyUser, agencyRoles } = this.props;
    const columns = [
      {
        Header: "Agency Users Listing",
        headerClassName: 'headerPagination',
        columns: [
          {
            Header: "Name",
            accessor: "name",
            className: "action-center",
            excExp: true
          },
          {
            Header: "Email",
            accessor: "email",
            className: "action-center",
            excExp: true
          },
          {
            Header: "Roles",
            accessor: "role_csv_value",
            Cell: ({ original }) => (
              <div>
                {
                  original.roles.map((prop, key) => {
                    return (<span className="badge badge-success" key={key}>{prop.label}</span>)
                  })
                }
              </div>
            ),
            className: "action-center",
            sortable: false,
            excExp: true
          },
          {
            Header: "Status",
            accessor: "is_active",
            Cell: ({ row }) => (
              <label className="container-check">
                &nbsp;
                <input
                  type="checkbox" name="is_active" className="checkbox" value={row.is_active ? 1 : 0} onChange={this.handleUpdateUser(row.id)} checked={row.is_active ? 1 : 0}
                /><span className="checkmark"></span>
              </label>
            ),
            className: "action-center",
            sortable: false
          },
          {
            Header: "Actions",
            accessor: "id",
            Cell: ({ value }) => (
              <div>
                <OverlayTrigger placement="bottom" overlay={<Popover id="tooltip">{hasPermission('agency-user-edit') ? 'Edit Agency User' : adminLabels.DONT_ALLOW}</Popover>}>
                  <span>
                    {
                      hasPermission('agency-user-edit') &&
                      <button type='button' className="editIcon orderDetailsAction" onClick={() => this.openModalAgencyUserEdit(value)}>&nbsp;</button>
                    }
                    {
                      !hasPermission('agency-user-edit') &&
                      <button type='button' className="editIcon orderDetailsAction button_disabled" >&nbsp;</button>
                    }
                  </span>
                </OverlayTrigger>
                <OverlayTrigger placement="bottom" overlay={<Popover id="tooltip">{hasPermission('agency-user-delete') ? 'Delete Agency User' : adminLabels.DONT_ALLOW}</Popover>}>
                  <React.Fragment>
                    <span>
                      {
                        hasPermission('agency-user-delete') &&
                        <button type='button' className="deleteIcon orderDetailsAction " onClick={() => this.deleteConfirmAgencyUser(value)}>&nbsp;</button>
                      }
                    </span>
                    <span>
                      {
                        !hasPermission('agency-user-delete') &&
                        <button type='button' className="deleteIcon orderDetailsAction button_disabled" >&nbsp;</button>
                      }
                    </span>
                    <span>
                      {
                        hasRole(['admin']) &&
                        <button onClick={() => this.showPasswordModal(value)} className={'linkBtn'} ><AiOutlineEye /></button>
                      }
                    </span>
                  </React.Fragment>
                </OverlayTrigger>
              </div>
            ),
            className: "action-center",
            sortable: false
          }
        ]
      }
    ];
    return (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                ctTableResponsive
                content={
                  <div>
                    <Grid fluid>
                      <Row>
                        <Col md={12}>
                          <div className='clearfix' >
                            <div className="secHeading pull-left">Agency Name : <strong>{agencyName}</strong></div>
                          </div>
                        </Col>
                        <Pagination
                          ref={(p) => this.pagination = p}
                          columns={columns}
                          getDataCall={this.paginationCall}
                          filterView={true}
                          filterPlaceHolder={'Agency User'}
                          downloadFileName={agencyName+'_Users'}
                          downloadData={true}
                          upperContent={
                            <Col md={8} sm={6} xs={12} >
                              <div className="flexElem flexResponsive" style={{ "alignItems": "center", "justifyContent": "space-between" }}>
                                <div className="form-group">
                                  <OpenModalButton
                                    openModal={hasPermission('agency-user-create') ? openModal.bind(this, "addModalAgencyUser") : null}
                                    classButton={['backButton pt-sm no_radius pb-sm primary text-xs btn btn-lg btn-block btn-info btn btn-lg btn-info', !hasPermission('agency-user-create') ? 'button_disabled' : '']}
                                    buttonName="Add Agency User"
                                    tooltipText={hasPermission('agency-user-create') ? 'Add Agency User' : adminLabels.DONT_ALLOW}
                                    classIcon={['fa', 'fa-plus']}
                                  />
                                </div>
                              </div>
                            </Col>
                          }
                        //getRowProps = { this.getRowProps }
                        />
                      </Row>
                    </Grid>
                    {/*Add AgencyUser Modal Start*/}
                    {agencyRoles !== null &&
                      <Modal backdrop={'static'} show={this.state.addModalAgencyUser} onHide={closeModal.bind(this, "addModalAgencyUser")} aria-labelledby="ModalHeader" >
                        <Modal.Header closeButton>
                          <Modal.Title id='ModalHeader' className="headerTitle">Add Agency User</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <div className="row">
                            <div className="col-md-12">
                              <Card bsClass={['innerCard mb-none']} content={
                                <AgencyUserForm
                                  closeModel={closeModal.bind(this, "addModalAgencyUser")}
                                  formTypePro="add"
                                  userPro={OC.AGENCYUSER}
                                  handleAgencyUserSubmit={this.handleAgencyUserSubmit}
                                  componentPro="adminAgencyUser"
                                  agencyRoles={agencyRoles}
                                />
                              } />
                            </div>
                          </div>
                        </Modal.Body>
                      </Modal>
                    }
                    {/*Add AgencyUser Modal End*/}

                    {/*Edit AgencyUser Modal start*/}
                    {editAgencyUser && agencyRoles !== null &&
                      <Modal backdrop={'static'} show={this.state.editModalAgencyUser} onHide={closeModal.bind(this, "editModalAgencyUser")} aria-labelledby="ModalHeader" >
                        <Modal.Header closeButton>
                          <Modal.Title id='ModalHeader' className="headerTitle">Edit Agency User </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <div className="row">
                            <div className="col-md-12">
                              <Card bsClass={['innerCard mb-none']} content={
                                <AgencyUserForm
                                  closeModel={closeModal.bind(this, "editModalAgencyUser")}
                                  formTypePro="edit"
                                  userPro={user}
                                  handleAgencyUserSubmit={this.handleAgencyUserSubmit}
                                  componentPro="adminAgencyUser"
                                  agencyRoles={agencyRoles}
                                />
                              }
                              />
                            </div>
                          </div>
                        </Modal.Body>
                      </Modal>
                    }
                    {/*Edit AgencyUser Modal End*/}
                  </div>
                } />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  };
}

const mapDispatchToProps = dispatch => {
  return ({
    getAgencyUsersCall: (agency_id, page, filter, sort, pageSize) => { return dispatch(getAgencyUsersPagination(agency_id, page, filter, sort, pageSize)) },
    getAgencyUserCall: (agency_id, id) => { dispatch(getAgencyUser(agency_id, id)) },
    storeAgencyUserCall: (agencyId, userData) => { return dispatch(storeAgencyUser(agencyId, userData)) },
    editAgencyUserCall: (userData, id, agency_id) => { return dispatch(editAgencyUser(userData, id, agency_id)) },
    deleteAgencyUserCall: (agency_id, id) => { return dispatch(deleteAgencyUser(agency_id, id)) },
    getAllRolesCall: () => { dispatch(getAgencyRoles()) },
    updateUserStatusCall: (id, data) => { return dispatch(updateUserStatus(id, data)) },
    showPasswordCall: (id) => { return dispatch(showPassword(id)) }

  });
};

function mapStateToProps(state) {
  const { editAgencyUser, loadingAgencyUser } = state.agencyUserReducer;
  const { agencyRoles } = state.rolesReducer;
  return {
    loadingAgencyUser, editAgencyUser, agencyRoles
  };
}
const AgencyUsers = connect(mapStateToProps, mapDispatchToProps)(AgencyUserListPage);
export default AgencyUsers;