import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { MdClose } from 'react-icons/md';
import FileUpload from 'components/Upload/FileUpload';
import ImagePreview from 'components/Upload/ImagePreview';
import { storeAgencyDocument, deleteAgencyDocument, getAgencyDocumentsPagination } from 'redux/actions/agency-resource-actions';
import { getAgencyFolderResources } from 'redux/actions/order-actions';
import { confirmAlert } from 'react-confirm-alert';
import ConfirmAlertForDelete from 'components/ThemeComponents/confirmAlertForDelete';
import Validator from 'simple-react-validator';
import DatePicker from "react-datepicker";
import { Collapse } from 'react-bootstrap';
import { dateFormatMy, s3StaticPath, helperFunctions as HF,getFormattedDate,returnLabelWithValue,closeModal} from 'helper/helperFunctions';
import { SC } from 'helper/ServerCall';
import Select from 'react-select';
import { alertActions } from '../../../redux/actions/alert-actions';
import Folder from '../../Admin/Folder';
import TOFolder from '../../Admin/TOFolder';
var Modal = require('react-bootstrap-modal');
// import MyImage from 'components/Upload/MyImage';
const moment = window.moment;

class AgencyDocuments extends Component {
    constructor(props) {
        super(props)
        this.dateValidator = new Validator();
        var date = moment(new Date()).format('YYYY-MM-DD')
        this.state = {
            schDate: date,
            files: [],
            agencyDocumentsData: [],
            documentType: '',
			collapseDocument: false,
            length: 0,
            agency_folder_id: null,
            callData: [],
            addFolderModal: false,
            folderName: '',
            selectedFolder: 'No Folder Selected',
            expanded: [],
            is_folder: true,
            folders:[]
        }
        // this.props.onOrderResourceFormatsCall(this.props.resourceTypeText).then(response => {
        //     this.setState({ resourceType: response });
        // });
        // console.log(this.props.agency_id, 'agency id');
        if (this.props.formType === 'edit') {
            this.props.getAgencyDocumentsPaginationCall(this.props.agency_id).then(response => {
                // console.log(response.length, 'responseee');
                this.setState({ agencyDocumentsData: response,
                                length: response.length
                             });
            });
        }
        this.props.getAgencyFolderResourcesCall(this.props.agency_id).then(response =>{
            console.log("response..........................", response);
            var folder = response.data.folder_array;
               var ids = [];
               Object.keys(folder).map((item) => {
                    if (!(folder[item].value == "-1")) {
                        ids.push(folder[item].value.toString());
                    }
                })
            this.setState({
                expanded: ids,
                callData: response.data.data,
                folders: response.data.folder_array
            });
        })

        // if (this.props.formType === 'add') {
        //     this.props.clearOrderResources();
        // }
        this.Validator = new Validator();

        // this.deleteAgencyFolderResources(2).then(response =>{
        //     console.log("deleteFolderResources----------", response);
        // })

    }
    deleteAgencyFolderResources = (id) => {
        return SC.deleteCall(`deleteAgencyFolderResources/${id}` );
    }
    storeAgencyFolder = (data) => {
        return SC.postCall('storeAgencyFolder' , data );
    }
    getAgencyFolderResources = (id) => {
        return SC.getCall(`getAgencyFolderResources/${id}` );
    }


    folderSubmitHandler = () => {
        if(this.state.folderName !== "") {
            const data = {name: this.state.folderName, agency_id: this.props.agency_id}
            this.storeAgencyFolder(data).then(
                response => {
                        console.log("ressss...//////////",response);
                        this.setState({
                            selectedFolder: response.data.name,
                            agency_folder_id: response.data.id,
                            is_folder: true
                        })
                    this.props.success(response.message);
                    this.setState({
                        addFolderModal: false,
                        folderName: ''
                    },()=>{
                        this.props.getAgencyFolderResourcesCall(this.props.agency_id).then(response =>{
                            this.setState({
                                callData: response.data.data,
                                folders: response.data.folder_array
                            });
                        })
                    });

                }
            );
        }
    }
    // DateChangeHandler = (value, field) => {
    //     this.setState({ [field]: value === null ? '' : value }, () => {
    //         if (this.dateValidator.allValid()) {
    //             //this.pagination.dataCall();;
    //             // console.log("dateChange", value);
    //             // console.log("schDate", this.state.schDate);
    //         } else {
    //             this.dateValidator.showMessages();
    //             this.forceUpdate();
    //         }
    //     })
    // }
    // selectedFolderHandle = (e) => {
    //     //   console.log("clicked", e.target.checked);
    //       const value = e.target.checked ? true : false;
    //       this.setState({
    //         is_folder: value
    //       })
    //     }
    handleChangeFolder = (selectedOptionType, info) => {
            // console.log("selected option type: " , selectedOptionType);
            // console.log("info " , info);
            if ( selectedOptionType.value === "-1")
            {
                this.setState({
                    agency_folder_id: null,
                    selectedFolder: selectedOptionType.label,
                })
            }else{
                this.setState({
                    selectedFolder: selectedOptionType.label,
                    agency_folder_id: selectedOptionType.value
                })
            }
        }
    onFolderClick = (e, data, nodes, nodeId) => {
        const removeId = e.target.getAttribute('Mui-expanded');
        // const expandingNodes = nodes.filter(x => !this.state.expanded.includes(x));
        const expandingNodes = nodes;
        this.setState({
            expanded:expandingNodes,
            selectedFolder: data.folder_name,
            agency_folder_id: data.folder_id,
            is_folder: true
        })}

    // dateElement(label, name, value, rules) {
    //     var selectedDate = this.state[name] !== '' ? new Date(this.state[name]) : '';
    //     return (
    //         <div className='form-group fullCalendar' id={name}>
    //             <label>{label}</label>
    //             <DatePicker
    //                 className="form-control calender_icon"
    //                 minDate={Date.now()}
    //                 selected={selectedDate}
    //                 name={name}
    //                 onChange={(event) => this.DateChangeHandler(event, name)}
    //             />

    //         </div>
    //     );
    // }
    setResourceFile = (key, value) => {
        this.setState((prevState) => {
            return {
                files: [
                    ...prevState.files,
                    value
                ]
            }
        });
    }

    documentSubmitHandler = () => {
        const fd = new FormData();
        // console.log(this.props.notEditable, this.props.formType)
        if (this.props.formType === 'edit') {
            // console.log(this.state.files)
            if (this.Validator.allValid()) {
                // fd.append('schDate', this.state.schDate);
                fd.append('file', this.state.files[0]);
                fd.append('agency_folder_id', this.state.agency_folder_id);
                // fd.append('resource_type_id', this.state.resourceType.id);
                // this.props.storeAgencyDocumentCall(this.props.agency_id, fd).then(
                    let self = this;
                this.props.storeAgencyDocumentCall(this.props.agency_id, fd).then(
                    response => {
                        self.setState({
                            files: []
                        }, () =>{
                            self.props.getAgencyFolderResourcesCall(this.props.agency_id).then(response =>{
                                self.setState({
                                    callData: response.data.data,
                                    folders: response.data.folder_array
                                });
                            })
                            this.forceUpdate();
                        });
                        this.props.getAgencyDocumentsPaginationCall(this.props.agency_id).then(response => {
                            this.setState({ agencyDocumentsData: response });
                        });
                        this.Validator.hideMessages();
                    }
                );
            } else {
                this.Validator.showMessages();
                this.forceUpdate();
            }
        } else {
            // fd.append('schDate', this.state.schDate);
            fd.append('file', this.state.files[0]);
            fd.append('agency_folder_id', this.state.agency_folder_id);
            // fd.append('resource_type_id', this.state.resourceType.id);
            // this.props.storeAgencyDocumentCall(this.props.agency_id, fd).then(
            this.props.storeAgencyDocumentCall(this.props.agency_id, fd).then(
                response => {
                    this.setState({
                        files: []
                    })
                    this.props.history.push('/dashboard')
                }
            );
        }
    }

    removeFile = (key, index) => {
        const files = [...this.state.files]
        files.splice(index, 1);

        this.setState({
            files: files,
        });
    }
    openAddFoderModal = () => {
        this.setState({
            addFolderModal: !this.state.addFolderModal
        })
    }
    storeFodlerhandler = (e) => {
        this.setState({
            folderName: e.target.value
        })
    }


    componentDidUpdate(prevProps, prevState) {
        if (this.props.formSubmitted && (this.props.formSubmitted !== prevProps.formSubmitted)) {
            if (this.state.files.length > 0 && this.props.formType === 'add') {
                this.documentSubmitHandler();
            }
            this.setState({
                submitComment: true
            }, () => {
                this.setState({
                    submitComment: false
                })
            });
        }

        if(this.state.length !== prevState.length){
            // console.log("function scucess");/\
            this.setState({
                collapseDocument: true
            })
            }
        if (this.state.folderName !== prevState.folderName) {
            this.setState({ folderName: this.state.folderName})
        }
        if (this.state.callData !== prevState.callData) {
            this.setState({ callData: this.state.callData})
        }

        if (this.state.expanded !== prevState.expanded) {

            if(this.state.expanded.length === 0) {
                this.setState({
                    selectedFolder: 'No Folder Selected',
                    agency_folder_id: null
                })
            }
        }
        if (this.state.is_folder !== prevState.is_folder) {

            if(this.state.is_folder === false) {
                this.setState({
                    selectedFolder: 'No Folder Selected',
                    agency_folder_id: null
                })
            }
        }
        if(this.state.agency_folder_id === null) {
            this.setState({
                selectedFolder: 'No Folder Selected',
                agency_folder_id: -1
            })
        }

        if(this.props.AgencyFolderDocuments !== prevProps.AgencyFolderDocuments) {
            this.setState({
                callData: this.props.AgencyFolderDocuments.data.data
            });
        }
    }

    onResourceDeleteHandler = (index, id) => {
        const agencyDocumentsData = [...this.state.agencyDocumentsData];
        agencyDocumentsData.splice(index, 1);
        this.props.deleteAgencyDocumentCall(this.props.agency_id, id).then(response => {
            this.props.getAgencyFolderResourcesCall(this.props.agency_id).then(response =>{
                console.log("1st setsstate", this.state.callData);
                this.setState({
                    callData: response.data.data,
                    folders: response.data.folder_array
                });
            })
            this.props.getAgencyDocumentsPaginationCall(this.props.agency_id).then(response => {
                this.setState({ agencyDocumentsData: response });
            });
        });
    }

    confirmResourceDelete = (index, id) => {
        confirmAlert({
            customUI: ({ onClose }) => <ConfirmAlertForDelete typeName='File' onClosePro={onClose} deleteType={this.onResourceDeleteHandler.bind(this, index, id)} />
        })
    }


    render() {
        const { header } = this.props;
        const { agencyDocumentsData , callData,folders,addFolderModal} = this.state;
        return (
            <div className="form_tile mb-2">
                <div className="form_tile_heading flexElem alignCenter spaceBetween" style={{cursor: 'pointer'}} onClick={() => {this.setState({collapseDocument: !this.state.collapseDocument})}}>
                    <div className="flexElem alignCenter">
                        <img src={HF.s3StaticPath('img/documents.png')} alt="Video Title" />
                        <span>Add Documents</span>
                    </div>

                    <div>
                        <i className="fa fa-chevron-down" style={{fontSize: 'smaller', transition: '0.2s all ease', transform: this.state.collapseDocument ? 'rotate(180deg)' : 'rotate(0deg)'}}></i>
                    </div>
                </div>

                <Collapse in={this.state.collapseDocument}>
                    <div className="form_tile_content">
                        <div className="form_tile mt-0 mb-0">
                            <div className="form_tile_content">
                                {
                                    <React.Fragment>
                                        <div className="flexElem respFlex spaceBetween form_tile_row">
                                            <div className="col-md-12">
                                                <div className="form_tile_title">Upload New File</div>
                                                <div class="text_14"><strong class="theme_color">NOTE:</strong> These will appear in every order</div>
                                            </div>
                                        </div>
                                        {
                                            folders &&
                                            <div className="flexElem respFlex spaceBetween form_tile_row">
                                                <div className="col-md-12">
                                                    <div className='form-group' id="folderId">
                                                        <label htmlFor="name">Select Folder</label>
                                                        <span className="addFolderLink">
                                                            <a style={{fontWeight: 'bold'}} onClick={this.openAddFoderModal}>Add New Folder</a>
                                                        </span>
                                                        <Select
                                                            value={returnLabelWithValue(this.state.agency_folder_id, folders)}
                                                            log={console.log("this.state.agency_folder_id",returnLabelWithValue(this.state.agency_folder_id, folders))}
                                                            onChange={this.handleChangeFolder}
                                                            options={folders}
                                                            // options={options.itemTypes}
                                                            isSearchable={false}
                                                            isMulti={false}
                                                            placeholder={'Select Type'}
                                                            isClearable={false}
                                                            name="agency_folder_id"
                                                        />
                                                        {/* {this.validatorItem.message('itemId', item.item_type_id, 'required')} */}
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        <div className="flexElem respFlex spaceBetween alignCenter form_tile_row">
                                            <div className="col-md-4">
                                                <button onClick={() => { this.uploadForm.open() }} className="themeBtn_14 hover_dark blue_theme_button" style={{borderRadius: '0px'}}>
                                                    <img src={s3StaticPath('img/upload_photo_white.png')} alt="Upload" />
                                                    <span>Choose File</span>
                                                </button>
                                            </div>
                                            <div className="col-md-8">
                                                {/* <div className='row flexElem flexResponsive align-items-center' >
                                                </div> */}
                                            </div>
                                            <div className="col-md-5">
                                                {
                                                    (this.props.notEditable || this.props.formType === 'edit') &&
                                                    <React.Fragment>
                                                        <button onClick={this.documentSubmitHandler} type="button" className="pl-xlg pr-xlg themeBtn_12 blue_theme_button hover_dark">Upload</button><br />
                                                    </React.Fragment>
                                                }
                                                <div style={{ display: 'none' }} >
                                                    <FileUpload
                                                        ref={el => { this.uploadForm = el }}
                                                        setResourceFile={this.setResourceFile}
                                                        multiple={false}
                                                        allowedFormatsStringMimePro={'image/jpeg, image/png, video/quicktime, video/mp4, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/pdf ,application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}
                                                        allowedFormatsStringExtPro={'jpg, png, docx, doc, Jpeg, pdf, xls, xlsx, mov, mp4'}
                                                    />
                                                </div>
                                                {
                                                    this.state.files.length < 1 &&
                                                    <span className="theme_color text_16 text_elipsis">No File Chosen</span>
                                                }
                                            </div>
                                        </div>
                                        {this.Validator.message('Documents', this.state.files, "required|min:1,array")}
                                    </React.Fragment>
                                }
                                <div>
                                    <ImagePreview
                                        filesToPreview={this.state.files}
                                        removeFile={this.removeFile} />
                                    {/* {this.props.progress !== 0 && <span>{this.props.progress}</span>} */}
                                </div>
                                {/* Start */}
                                {
                                    this.props.formType === 'edit' &&
                                    <>
                                        <div className="flexElem respFlex spaceBetween form_tile_row mt-lg">
                                            <div className="col-md-12">
                                                <div className="flexElem mb-md">
                                                    <span className="form_tile_title">Agency Documents</span>
                                                    <p className="smallText">Will appear on all orders.</p>
                                                </div>
                                            </div>
                                        </div>

                                        {
                                            this.props.formType === 'edit' && callData &&
                                            //WorkDND
                                            callData.map((item, key) => {
                                                // console.log("index: " , key);
                                                // console.log("item: " , item);
                                                if (item.folder_id){
                                                    return (
                                                        <TOFolder item = {item} agencyId = {this.props.agency_id} ex = {this.state.expanded} onFolderClick = {this.onFolderClick} confirmResourceDelete = {this.confirmResourceDelete} />
                                                            )
                                                }
                                            })

                                        }

                                        {
                                            this.props.formType === 'edit' &&
                                                <div className="flexElem respFlex spaceBetween form_tile_row">
                                                    <div className="col-md-12">
                                                        <div className="table-responsive">
                                                            <table className="table_tile mb-md">
                                                                <thead>
                                                                    <tr className="border-bottom">
                                                                        <th width="35%">File Name</th>
                                                                        <th width="25%">Uploaded Date</th>
                                                                        <th width="20%">Status</th>
                                                                        <th width="20%">Action</th>
                                                                    </tr>
                                                                </thead>

                                                                <tbody>
                                                                    {
                                                                        callData.map((item, index) => {
                                                                            // console.log("itemmmm", item),

                                                                            if (item.data){
                                                                            return console.log("not found");
                                                                            } else if (item.file_path){

                                                                                return(
                                                                                <tr key={item.id}>
                                                                                    <td><a style={{ wordBreak: 'break-all' }} className='blue_link' target="_blank" href={item.file_path} rel="noopener noreferrer">{item.original_file_name}</a></td>
                                                                                    <td>{getFormattedDate(item.created_at)}</td>
                                                                                    <td>{item.is_scheduled === 0 ? 'Sent' : 'Scheduled'}</td>
                                                                                    <td>
                                                                                        <button onClick={() => { this.confirmResourceDelete(index, item.id) }} type='button' className="linkBtn text_14 text-danger"><MdClose /></button>
                                                                                        <a className='ml-md' href={item.file_path}><i className='fa fa-download'></i></a>
                                                                                    </td>
                                                                                </tr>
                                                                            )
                                                                            }else{
                                                                                return null
                                                                            }
                                                                    })
                                                                    }
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                        }
                                    </>
                                }
                                {/* <div className="flexElem respFlex spaceBetween form_tile_row">
                                    <div className="col-md-12">
                                        <div className="col-md-5">
                                            <button onClick={this.openAddFoderModal} className="folderBtn mt-md mb-md">Add New Folder</button>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </Collapse>
            {/* Add Folder Model */}
            <Modal size="sm" backdrop={'static'} show={addFolderModal} id="" onHide={closeModal.bind(this, "addFolderModal")} aria-labelledby="ModalHeader" className="" >
                <Modal.Header closeButton>
                    <Modal.Title id='ModalHeader' className="headerTitle">Add New Folder</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="clearfix">
                        <input type="text" value={this.state.folderName} onChange={this.storeFodlerhandler} className="form-control" placeholder="Enter Folder Name"/>
                        <br />
                        {/* <button onClick={closeModal.bind(this, "addFolderModal")}  className="folderBtn mr-md "> Back</button> */}

                        <div className="section-frontEnd">
                            <button onClick={() => this.folderSubmitHandler()} style={{float: "right"}} className="pl-xlg pr-xlg themeBtn_12 blue_theme_button hover_dark">Submit</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { AgencyFolderDocuments } = state.ordersReducer;
    return {
        AgencyFolderDocuments
    };
}

const mapDispatchToProps = dispatch => {
    return {
        getAgencyDocumentsPaginationCall: (agency_id) => { return dispatch(getAgencyDocumentsPagination(agency_id)) },
        storeAgencyDocumentCall: (agencyId, AgencyDocumentData) => { return dispatch(storeAgencyDocument(agencyId, AgencyDocumentData))},
        deleteAgencyDocumentCall: (agency_id, id) => dispatch(deleteAgencyDocument(agency_id, id)),
        getAgencyFolderResourcesCall: (agencyId) => { return dispatch(getAgencyFolderResources(agencyId)) },
        success: (message) => { return dispatch(alertActions.success(message))},
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AgencyDocuments))
