import React, { Component } from 'react';
import { connect } from 'react-redux';
import { login } from 'redux/actions/admin-user-action';
import { s3StaticPath } from 'helper/helperFunctions';
import MessageCom from 'layouts/Dashboard/Message';
import MyImage from 'components/Upload/MyImage';

class LoginPage extends Component {

	constructor(props) {
        super(props);
        var checkLogin = localStorage.getItem('user');
        if (checkLogin){
            localStorage.removeItem('user');
            // this.props.dispatch(logout());    
        }
        
        this.state = {
            username       : '',
            password    : '',
            submitted   : false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleSubmit(e) {
        e.preventDefault();
        this.setState({ submitted: true });
        const { username, password } = this.state;
        const { dispatch } = this.props;
        if (username && password) {
            dispatch(login(username, password));
        }
    }
	render(){
		const { username, password, submitted } = this.state;
        const { loggingIn } = this.props;
		return(
            <div className="main_login">
            <MessageCom/>
                <p className="text-center logo_container"><MyImage className="img-responsive logoImageLogin" src={s3StaticPath('img/GTV_Logo_black.jpg')} alt="backGroundImage" /> </p>
                <div className="login_form_container">
                    <div className="login_form">
                        <h1 className="text-center testrrr"  >Admin Login</h1>
                        <form name="form" onSubmit={this.handleSubmit}>
                            <div className={'form-group' + (submitted && !username ? ' has-error' : '')}>
                                <input type="text" placeholder="Enter Your Username..." className="form-control email_ctrl" name="username" value={username} onChange={this.handleChange} />
                                {submitted && !username &&
                                    <div className="help-block">Username is required.</div>
                                }
                            </div>
                            <div className={'form-group' + (submitted && !password ? ' has-error' : '')}>
                                <input type="password" placeholder="Password" className="form-control password_ctrl" name="password" value={password} onChange={this.handleChange} />
                                {submitted && !password &&
                                    <div className="help-block">Password is required.</div>
                                }
                            </div>
                            <div className="form-group">
                                <button className="btn btn-default btn_login">LOGIN</button>
                                { loggingIn &&
                                    <MyImage alt="imageLogin" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                }
                            </div>                        
                        </form>
                    </div>
                </div>
            </div>
		);
	}
}

function mapStateToProps(state) {
    const { loggingIn } = state.adminReducer;
    return {
        loggingIn
    };
}

const Login = connect(mapStateToProps)(LoginPage);
export default Login;