import { SC } from '../helper/ServerCall';

export const agencyUserService = {
    storeAgencyUser,
    getAgencyUser,
    editAgencyUser,
    deleteAgencyUser,
    getCurrentAgencyUser,
    updatePermission,
    getAgencyUsersPagination,
    getAllAgenciesUsersPagination
}

function storeAgencyUser(agencyId, agencyUserData) {
    return SC.postCall('agencyUsers/' + agencyId, agencyUserData);
}

function getAgencyUser(agency_id, agencyUserId) {
    return SC.getCall('agencyUsers/' + agency_id + '/' + agencyUserId + '/show');
}

function editAgencyUser(agencyUserData, agencyUserId, agency_id) {
    return SC.putCall('agencyUsers/' + agency_id + '/' + agencyUserId + '/update', agencyUserData);
}

function deleteAgencyUser(agency_id, agencyUserId) {
    return SC.deleteCall('agencyUsers/' + agency_id + '/' + agencyUserId + '/delete');
}

function getAgencyUsersPagination(agency_id, page, filter, sort, pageSize) {
    return SC.getCall('agencyUsers/' + agency_id + '/listing?page=' + page + '&filter=' + filter + '&sort=' + sort + '&pageSize=' + pageSize);
}

function getAllAgenciesUsersPagination(page, filter, sort, pageSize, is_active) {
    return SC.getCall('allAgenciesUsers?page=' + page + '&filter=' + filter + '&sort=' + sort + '&pageSize=' + pageSize + '&is_active='+is_active);
}

function getCurrentAgencyUser() {
    return SC.getCall('getUsersData');
}

function updatePermission(agencyUserId) {
    return SC.getCall('changePrimaryContact/' + agencyUserId);
}