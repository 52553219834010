import React, { Component } from 'react'
import { connect } from 'react-redux'
import { recentlyProcessedOrder, unprocessOrders } from "../../../redux/actions/order-actions";
import { Grid, Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import ThemeModal from '../../ThemeComponents/ThemeModal';
import { closeModal } from '../../../helper/helperFunctions';
import ProcessOrder from './ProcessOrder';
import Card from '../../Card/Card';
import Pagination from '../../ThemeComponents/Pagination';

class RecentOrderProcessList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filtered: '',
            pageSize: 10,
            orders: [],
            processOrdersModal: false
        }
        this.checkChange = this.checkChange.bind(this);
        this.table = React.createRef();
    }

    checkChange(id, e) {
        const check = e.target.checked;
        const orders = [...this.state.orders];
        if (check) {
            orders.push(id);
            this.setState({
                orders: orders
            })
        } else {
            const i = orders.findIndex((el) => {
                return el === id;
            });
            orders.splice(i, 1);
            this.setState({
                orders: orders
            })
        }
    }
    unprocessOrders = () => {
        this.props.unprocessOrderCall({ 'ordersIds': this.state.orders, 'status': 0, 'slug': 'camera' }).then(response => {
            this.pagination.dataCall();
        });
    }

    paginationCall = (data) => {
        return this.props.recentlyProcessedOrdersCall(data.page, data.filter, data.sort, data.pageSize);
    }

    getRowProps = (state, rowInfo) => {
        let data = {}
        if (rowInfo !== undefined) {
            const date = rowInfo.original.order_dates.projected_camera_ship_date.date_diff
            if (date < 1) {
                data = {
                    color: 'red'
                }
            }
            if (date === 1) {
                data = {
                    color: 'green'
                }
            }
            if (date > 1) {
                data = {
                    color: 'black'
                }
            }
        }
        return {
            style: data
        };
    }

    render() {
        // const { recenltyProcessedOrders } = this.props;
        const columns = [
            {
                Header: 'Recently Processed Order List',
                headerClassName: 'headerPagination',
                columns: [
                    {
                        Header: 'Process Date',
                        accessor: 'camera_ship_status[0].process_date',
                        id: 'order_shippings.process_date',
                        className: 'action-center',
                        Cell: ({ value }) => {
                            return <span>{new Date(value).toDateString()}</span>
                        }
                    },
                    {
                        Header: 'Departure Date',
                        accessor: 'order_dates.departure_date.value',
                        className: 'action-center'
                    },
                    {
                        Header: 'Ship Time (State)',
                        accessor: 'pre_trip_material_address.shipping_duration.days',
                        className: 'action-center'
                    },
                    {
                        Header: 'Barcode',
                        accessor: 'barcode',
                        className: 'action-center',
                        width: 200,
                        Cell: ({ value, original }) => {
                            return <Link className='blue_link' target="_blank" style={{ textDecoration: 'underline' }} to={`${original.id}/order-edit`} >{value}</Link>
                        }
                    },
                    {
                        Header: 'Customer',
                        accessor: 'agency.name',
                        id: 'agencies.name',
                        className: 'action-center'
                    },
                    {
                        Header: 'Group Name',
                        accessor: 'group_name',
                        className: 'action-center'
                    },
                    {
                        Header: 'Username',
                        accessor: 'username',
                        className: 'action-center'
                    },
                    {
                        Header: 'Approved For Shipping',
                        className: 'action-center',
                        sortable: false,
                        Cell: ({ value, original }) => {
                            return (
                                parseInt(original.settings.approve_for_shipping.value, 10) === 1 ? 'Yes' : 'No'
                            )
                        }
                    },
                    {
                        Header: 'Un Process',
                        accessor: 'status.title',
                        className: 'action-center',
                        Cell: ({ value, original }) => {
                            return (
                                value === 'Pending' ?
                                    <span style={{ color: 'red' }}>ORDER NOT APPROVED</span>
                                    :
                                    <label className="container-check">
                                        <input
                                            type="checkbox"
                                            checked={this.state.orders.includes(original.id)}
                                            className="checkbox"
                                            value={original.id}
                                            onChange={this.checkChange.bind(this, original.id)}
                                            disabled={original.depatureDateDiff <= 4 && original.order_shipping_camera.tracking_number != null ? true : false}
                                        />
                                        <span className="checkmark"></span>
                                    </label>
                            )
                        },
                        sortable: false
                    },
                    {
                        Header: 'Cam Qty',
                        accessor: 'number_of_cameras',
                        className: 'action-center'
                    },
                    {
                        Header: 'PTM Shipping Comments',
                        accessor: 'comments',
                        sortable: false,
                        className: 'action-center',
                        Cell: ({ value, original }) => {
                            return (
                                <div>
                                    {
                                        value !== undefined ?
                                            value[0] !== undefined ?
                                                <div>
                                                    <p>{value[0].comment}</p>
                                                    <Link to={`${original.id}/order-edit`} className='blue_link' >View More</Link>
                                                </div>
                                                : null
                                            : null
                                    }
                                </div>
                            );
                        }
                    }
                ]
            }
        ]

        return (
            <div className='content' >
                <Grid fluid >
                    <Row>
                        <Col md={12} >
                            <Card content={
                                <React.Fragment>
                                    <h1 className='secHeading' >Recently Processed Order List</h1>
                                    <p>These are orders that have recently been processed for shipment Within the last 7 Days.</p>

                                    {/* <ReactTable
                                        columns={columns}
                                        data={recenltyProcessedOrders.data}
                                        noDataText='No Order Found'
                                        className='-striped listing'
                                        loadingText='loading'
                                        manual
                                        ref={this.table}
                                        filtered={this.state.filtered}
                                        defaultPageSize={this.state.pageSize}
                                        onFetchData={(state) => {
                                            var sort = state.sorted.length === 0 ? '' : state.sorted[0].id + ',desc:' + state.sorted[0].desc;
                                            this.props.recentlyProcessedOrdersCall(state.page + 1, state.filtered, sort, state.pageSize);
                                        }}
                                        pages={recenltyProcessedOrders.last_page}
                                        getTrProps={(state, rowInfo) => {
                                            let data = {}
                                            if (rowInfo !== undefined) {
                                                const date = rowInfo.original.order_dates.projected_camera_ship_date.date_diff
                                                if (date < 1) {
                                                    data = {
                                                        color: 'red'
                                                    }
                                                }
                                                if (date === 1) {
                                                    data = {
                                                        color: 'green'
                                                    }
                                                }
                                                if (date > 1) {
                                                    data = {
                                                        color: 'black'
                                                    }
                                                }
                                            }
                                            return {
                                                style: data
                                            };
                                        }}
                                    /> */}
                                    <Pagination
                                        ref={(p) => this.pagination = p}
                                        showPagination={false}
                                        columns={columns}
                                        pageSize={10}
                                        getDataCall={this.paginationCall}
                                        filterView={false}
                                        noDataText='No Order Found'
                                        // downloadData={true}
                                        // downloadFileName={'Orders'}
                                        // lowerContent = {null}
                                        // filterPlaceHolder = {'Orders'}
                                        getRowProps={this.getRowProps}
                                    // showAllToggle={true}
                                    />
                                    <div className='my-20 clearfix' >
                                        <div className='pull-left' >
                                            You have selected {this.state.orders.length} orders to process
                                        </div>
                                        <div className='pull-right' >
                                            <OverlayTrigger placement="left" overlay={<Tooltip id="tooltip">{this.state.orders.length < 1 ? 'Select At Least One Order' : 'UnProcess Orders'}</Tooltip>}>
                                                <button disabled={this.state.orders.length < 1} onClick={this.unprocessOrders} className='backButton my-20 mr-20 pt-sm no_radius pb-sm primary text-xs btn btn-lg btn-info btn btn-lg btn-info btn btn-default' >UnProcess Orders</button>
                                            </OverlayTrigger>


                                            {
                                                /*<OpenModalButton
                                                    openModal={openModal.bind(this, 'processOrdersModal')}
                                                    classButton={['backButton', 'my-20', 'mr-20', 'pt-sm', 'no_radius', 'pb-sm', 'primary', 'text-xs', 'btn', 'btn-lg', 'btn-info', 'btn', 'btn-lg', 'btn-info', 'btn', 'btn-default']}
                                                    buttonName='Process Orders'
                                                    tooltipText={this.state.orders.length < 1 ? 'Select At Least One Order' : 'Process Orders'}
                                                    classIcon={[]}
                                                    disabled={this.state.orders.length < 1}
                                                />
                                                */
                                            }
                                        </div>
                                    </div>
                                </React.Fragment>
                            } />
                        </Col>
                    </Row>
                </Grid>
                <ThemeModal size='lg' show={this.state.processOrdersModal} title='Process Orders' hide={closeModal.bind(this, 'processOrdersModal')} >
                    <ProcessOrder orders={this.state.orders} />
                </ThemeModal>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { recenltyProcessedOrders } = state.ordersReducer;
    return { recenltyProcessedOrders };
}

const mapDispatchToProps = dispatch => {
    return {
        recentlyProcessedOrdersCall: (page, filtered, sort, pageSize) => { return dispatch(recentlyProcessedOrder(page, filtered, sort, pageSize)) },
        unprocessOrderCall: (data) => { return dispatch(unprocessOrders(data)) },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RecentOrderProcessList)
