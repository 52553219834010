import { adminConstants } from '../constant/admin-constant';
import { alertActions } from './alert-actions';
import { history } from '../../helper/history';
import { brandService } from '../../services/brand';
// import { generalActions } from './generalActions';

export function getBrandsPagination(page, filter, sort, pageSize, status) {
    return dispatch => {
        dispatch(request())
        return brandService.getBrandsPagination(page, filter, sort, pageSize, status)
            .then(
                response => {
                    dispatch(success(response));
                    return response;
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request() { return { type: adminConstants.PAGINATION_BRANDS_REQUEST } }
    function success(response) { return { type: adminConstants.PAGINATION_BRANDS_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.PAGINATION_BRANDS_FAILURE, payload: error } }
}
export function storeBrand(BrandData) {
    return dispatch => {
        dispatch(request());
        return brandService.storeBrand(BrandData)
            .then(
                response => {
                    dispatch(success(response));
                    dispatch(alertActions.success(response.message));
                    return response;
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                    return Promise.reject(error);
                }
            );
    }

    function request() { return { type: adminConstants.STORE_BRAND_REQUEST } }
    function success(response) { return { type: adminConstants.STORE_BRAND_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.STORE_BRAND_FAILURE, payload: error } }
}
export function getBrand(id) {
    return dispatch => {
        dispatch(request());
        return brandService.getBrand(id)
            .then(
                Brand => {
                    dispatch(success(Brand));
                    return Brand;
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    }

    function request() { return { type: adminConstants.GET_BRAND_REQUEST } }
    function success(Brand) { return { type: adminConstants.GET_BRAND_SUCCESS, payload: Brand } }
    function failure(error) { return { type: adminConstants.GET_BRAND_FAILURE, payload: error } }
}
export function editBrand(BrandData, brandId) {

    return dispatch => {
        dispatch(request());
        return brandService.editBrand(BrandData, brandId)
            .then(
                response => {
                    dispatch(success(response));
                    dispatch(alertActions.success(response.message));
                    return response;
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                    return Promise.reject(error);
                }
            );
    }

    function request() { return { type: adminConstants.EDIT_BRAND_REQUEST } }
    function success(response) { return { type: adminConstants.EDIT_BRAND_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.EDIT_BRAND_FAILURE, payload: error } }
}
export function deleteBrand(brandId) {
    return dispatch => {
        dispatch(request());
        return brandService.deleteBrand(brandId)
            .then(
                response => {
                    dispatch(success(response));
                    dispatch(alertActions.success(response.message));
                    return response;
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error.message));
                }
            );
    }

    function request() { return { type: adminConstants.DELETE_BRAND_REQUEST } }
    function success(response) { return { type: adminConstants.DELETE_BRAND_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.DELETE_BRAND_FAILURE, payload: error } }
}

export function updateBrandStatus(id, data) {
    return dispatch => {
        dispatch(request());
        return brandService.updateBrandStatus(id, data)
            .then(
                Brand => {
                    dispatch(success(Brand));
                    dispatch(alertActions.success(Brand.message));
                    return Brand;
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    }

    function request() { return { type: adminConstants.UPDATE_BRAND_STATUS_REQUEST } }
    function success(Brand) { return { type: adminConstants.UPDATE_BRAND_STATUS_SUCCESS, payload: Brand } }
    function failure(error) { return { type: adminConstants.UPDATE_BRAND_STATUS_FAILURE, payload: error } }
}

export function getFaqsPagination(accountId, page, filter, sort, pageSize, is_web) {
    return dispatch => {
        dispatch(request())
        return brandService.getFaqsPagination(accountId, page, filter, sort, pageSize, is_web)
            .then(
                response => {
                    dispatch(success(response));
                    return response;
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request() { return { type: adminConstants.PAGINATION_FAQS_REQUEST } }
    function success(response) { return { type: adminConstants.PAGINATION_FAQS_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.PAGINATION_FAQS_FAILURE, payload: error } }
}
export function getFaqsCategories() {
    return dispatch => {
        dispatch(request());
        brandService.getFaqsCategories()
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    }

    function request() { return { type: adminConstants.GET_FAQS_CATEGORIES_REQUEST } }
    function success(response) { return { type: adminConstants.GET_FAQS_CATEGORIES_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.GET_FAQS_CATEGORIES_FAILURE, payload: error } }
}
export function storeFaq(accountId, FaqData) {
    return dispatch => {
        dispatch(request());
        return brandService.storeFaq(accountId, FaqData)
            .then(
                response => {
                    dispatch(success(response));
                    dispatch(alertActions.success(response.message));
                    return response;
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                    return Promise.reject(error);
                }
            );
    }

    function request() { return { type: adminConstants.STORE_FAQ_REQUEST } }
    function success(response) { return { type: adminConstants.STORE_FAQ_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.STORE_FAQ_FAILURE, payload: error } }
}
export function getFaq(accountId, id) {
    return dispatch => {
        dispatch(request());
        brandService.getFaq(accountId, id)
            .then(
                Faq => {
                    dispatch(success(Faq));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    }

    function request() { return { type: adminConstants.GET_FAQ_REQUEST } }
    function success(Faq) { return { type: adminConstants.GET_FAQ_SUCCESS, payload: Faq } }
    function failure(error) { return { type: adminConstants.GET_FAQ_FAILURE, payload: error } }
}
export function editFaq(accountId, FaqData, brandId) {

    return dispatch => {
        dispatch(request());
        return brandService.editFaq(accountId, FaqData, brandId)
            .then(
                response => {
                    dispatch(success(response));
                    dispatch(alertActions.success(response.message));
                    return response;
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                    return Promise.reject(error);
                }
            );
    }

    function request() { return { type: adminConstants.EDIT_FAQ_REQUEST } }
    function success(response) { return { type: adminConstants.EDIT_FAQ_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.EDIT_FAQ_FAILURE, payload: error } }
}
export function deleteFaq(accountId, faqId) {
    return dispatch => {
        dispatch(request());
        return brandService.deleteFaq(accountId, faqId)
            .then(
                response => {
                    dispatch(success(response));
                    dispatch(alertActions.success(response.message));
                    return response;
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error.message));
                }
            );
    }

    function request() { return { type: adminConstants.DELETE_FAQ_REQUEST } }
    function success(response) { return { type: adminConstants.DELETE_FAQ_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.DELETE_FAQ_FAILURE, payload: error } }
}


export function updateFaqStatus(id, data) {
    return dispatch => {
        dispatch(request());
        return brandService.updateFaqStatus(id, data)
            .then(
                Faq => {
                    dispatch(success(Faq));
                    dispatch(alertActions.success(Faq.message));
                    return Faq;
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    }

    function request() { return { type: adminConstants.UPDATE_FAQ_STATUS_REQUEST } }
    function success(Faq) { return { type: adminConstants.UPDATE_FAQ_STATUS_SUCCESS, payload: Faq } }
    function failure(error) { return { type: adminConstants.UPDATE_FAQ_STATUS_FAILURE, payload: error } }
}

export function getTransmittalTemplatesPagination(accountId, page, filter, sort, pageSize, status) {
    return dispatch => {
        dispatch(request())
        return brandService.getTransmittalTemplatesPagination(accountId, page, filter, sort, pageSize, status)
            .then(
                response => {
                    dispatch(success(response));
                    return response;
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request() { return { type: adminConstants.PAGINATION_TRANSMITTAL_TEMPLATES_REQUEST } }
    function success(response) { return { type: adminConstants.PAGINATION_TRANSMITTAL_TEMPLATES_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.PAGINATION_TRANSMITTAL_TEMPLATES_FAILURE, payload: error } }
}
export function storeTransmittalTemplate(accountId, TransmittalTemplateData) {
    return dispatch => {
        dispatch(request());
        return brandService.storeTransmittalTemplate(accountId, TransmittalTemplateData)
            .then(
                response => {
                    dispatch(success(response));
                    dispatch(alertActions.success(response.message));
                    return response;
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                    return Promise.reject(error);
                }
            );
    }

    function request() { return { type: adminConstants.STORE_TRANSMITTAL_TEMPLATE_REQUEST } }
    function success(response) { return { type: adminConstants.STORE_TRANSMITTAL_TEMPLATE_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.STORE_TRANSMITTAL_TEMPLATE_FAILURE, payload: error } }
}
export function getTransmittalTemplate(accountId, id) {
    return dispatch => {
        dispatch(request());
        brandService.getTransmittalTemplate(accountId, id)
            .then(
                TransmittalTemplate => {
                    dispatch(success(TransmittalTemplate));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    }

    function request() { return { type: adminConstants.GET_TRANSMITTAL_TEMPLATE_REQUEST } }
    function success(TransmittalTemplate) { return { type: adminConstants.GET_TRANSMITTAL_TEMPLATE_SUCCESS, payload: TransmittalTemplate } }
    function failure(error) { return { type: adminConstants.GET_TRANSMITTAL_TEMPLATE_FAILURE, payload: error } }
}
export function editTransmittalTemplate(accountId, TransmittalTemplateData, brandId) {

    return dispatch => {
        dispatch(request());
        return brandService.editTransmittalTemplate(accountId, TransmittalTemplateData, brandId)
            .then(
                response => {
                    dispatch(success(response));
                    dispatch(alertActions.success(response.message));
                    return response;
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                    return Promise.reject(error);
                }
            );
    }

    function request() { return { type: adminConstants.EDIT_TRANSMITTAL_TEMPLATE_REQUEST } }
    function success(response) { return { type: adminConstants.EDIT_TRANSMITTAL_TEMPLATE_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.EDIT_TRANSMITTAL_TEMPLATE_FAILURE, payload: error } }
}
export function deleteTransmittalTemplate(accountId, transmittalTemplateId) {
    return dispatch => {
        dispatch(request());
        return brandService.deleteTransmittalTemplate(accountId, transmittalTemplateId)
            .then(
                response => {
                    dispatch(success(response));
                    dispatch(alertActions.success(response.message));
                    return response;
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error.message));
                }
            );
    }

    function request() { return { type: adminConstants.DELETE_TRANSMITTAL_TEMPLATE_REQUEST } }
    function success(response) { return { type: adminConstants.DELETE_TRANSMITTAL_TEMPLATE_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.DELETE_TRANSMITTAL_TEMPLATE_FAILURE, payload: error } }
}

export function getTransmittalTemplatesTypes() {
    return dispatch => {
        dispatch(request());
        brandService.getTransmittalTemplatesTypes()
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    }

    function request() { return { type: adminConstants.GET_TRANSMITTAL_TEMPLATES_TYPES_REQUEST } }
    function success(response) { return { type: adminConstants.GET_TRANSMITTAL_TEMPLATES_TYPES_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.GET_TRANSMITTAL_TEMPLATES_TYPES_FAILURE, payload: error } }
}

export function getTransmittalTemplatesCategories(typeId) {
    return dispatch => {
        dispatch(request());
        brandService.getTransmittalTemplatesCategories(typeId)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    }

    function request() { return { type: adminConstants.GET_TRANSMITTAL_TEMPLATES_CATEGORIES_REQUEST } }
    function success(response) { return { type: adminConstants.GET_TRANSMITTAL_TEMPLATES_CATEGORIES_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.GET_TRANSMITTAL_TEMPLATES_CATEGORIES_FAILURE, payload: error } }
}
export function updateTransmittalTemplateStatus(id, data) {
    return dispatch => {
        dispatch(request());
        return brandService.updateTransmittalTemplateStatus(id, data)
            .then(
                TransmittalTemplate => {
                    dispatch(success(TransmittalTemplate));
                    dispatch(alertActions.success(TransmittalTemplate.message));
                    return TransmittalTemplate;
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    }

    function request() { return { type: adminConstants.UPDATE_TRANSMITTAL_TEMPLATE_STATUS_REQUEST } }
    function success(TransmittalTemplate) { return { type: adminConstants.UPDATE_TRANSMITTAL_TEMPLATE_STATUS_SUCCESS, payload: TransmittalTemplate } }
    function failure(error) { return { type: adminConstants.UPDATE_TRANSMITTAL_TEMPLATE_STATUS_FAILURE, payload: error } }
}

export function getBrandAppSetting(id) {
    return dispatch => {
        dispatch(request());
        brandService.getBrandAppSetting(id)
            .then(
                Brand => {
                    dispatch(success(Brand));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    }

    function request() { return { type: adminConstants.GET_BRAND_APP_SETTING_REQUEST } }
    function success(Brand) { return { type: adminConstants.GET_BRAND_APP_SETTING_SUCCESS, payload: Brand } }
    function failure(error) { return { type: adminConstants.GET_BRAND_APP_SETTING_FAILURE, payload: error } }
}
export function updateBrandAppSettings(id, data) {
    return dispatch => {
        dispatch(request());
        brandService.updateBrandAppSettings(id, data)
            .then(
                AppSettings => {
                    dispatch(success(AppSettings));
                    dispatch(alertActions.success(AppSettings.message));
                    history.push(`/Admin/brands`);
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    }

    function request() { return { type: adminConstants.UPDATE_APP_SETTINGS_REQUEST } }
    function success(AppSettings) { return { type: adminConstants.UPDATE_APP_SETTINGS_SUCCESS, payload: AppSettings } }
    function failure(error) { return { type: adminConstants.UPDATE_APP_SETTINGS_FAILURE, payload: error } }
}

export function handleBrandActiveStatus(brandId) {
    return dispatch => {
        return brandService.handleBrandActiveStatus(brandId).then(
            res => {
                dispatch(alertActions.success(res.message))
            }
        ).catch(err => {
            dispatch(alertActions.error(err))
        })
    }
}