import { adminConstants } from 'redux/constant/admin-constant';
import { alertActions } from './alert-actions';
import { proShowFileService } from 'services/proShowFile';

export function storeProShowFile(data) {
    return dispatch => {
        dispatch(request());
        return proShowFileService.storeProShowFile(data)
            .then(
                response => {
                    dispatch(success(response));
                    dispatch(alertActions.success(response.message));
                    return response;
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                    return Promise.reject(error);
                }
            );
    };

    function request() { return { type: adminConstants.STORE_PRO_SHOW_FILE_REQUEST } }
    function success(response) { return { type: adminConstants.STORE_PRO_SHOW_FILE_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.STORE_PRO_SHOW_FILE_FAILURE, payload: error } }
}

export function getProShowFile(id) {
    return dispatch => {
        dispatch(request());
        proShowFileService.getProShowFile(id)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request() { return { type: adminConstants.GET_PRO_SHOW_FILE_REQUEST } }
    function success(response) { return { type: adminConstants.GET_PRO_SHOW_FILE_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.GET_PRO_SHOW_FILE_FAILURE, payload: error } }
}

export function getProShowFilesPagination(page, filter, proShowDest, sort, pageSize, pagination = true) {
    return dispatch => {
        dispatch(request())
        return proShowFileService.getProShowFilesPagination(page, filter, proShowDest, sort, pageSize, pagination)
            .then(
                response => {
                    dispatch(success(response));
                    return response;
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request() { return { type: adminConstants.PAGINATE_PRO_SHOW_FILE_REQUEST } }
    function success(response) { return { type: adminConstants.PAGINATE_PRO_SHOW_FILE_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.PAGINATE_PRO_SHOW_FILE_FAILURE, payload: error } }
}

export function proShowFileOptions(orderId) {
    return dispatch => {
        dispatch(request())
        return proShowFileService.proShowFileOptions(orderId)
            .then(
                response => {
                    dispatch(success(response));
                    return response;
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request() { return { type: adminConstants.PRO_SHOW_FILE_OPTIONS_REQUEST } }
    function success(response) { return { type: adminConstants.PRO_SHOW_FILE_OPTIONS_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.PRO_SHOW_FILE_OPTIONS_FAILURE, payload: error } }
}
export function getProShowDestinations(data) {
    return dispatch => {
        dispatch(request())
        return proShowFileService.getProShowDestinations(data)
            .then(
                response => {
                    console.log("action response", response);
                    dispatch(success(response));
                    return response;
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request() { return { type: adminConstants.GET_PRO_SHOW_FILE_DESTINATION_REQUEST } }
    function success(response) { return { type: adminConstants.GET_PRO_SHOW_FILE_DESTINATION_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.GET_PRO_SHOW_FILE_DESTINATION_FAILURE, payload: error } }
}

export function updateProShowFileCall(data, id) {
    return dispatch => {
        dispatch(request());
        return proShowFileService.updateProShowFileCall(data, id)
            .then(
                response => {
                    dispatch(success(response));
                    dispatch(alertActions.success(response.message));
                    return response;
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                    return Promise.reject(error);
                }
            );
    };

    function request() { return { type: adminConstants.UPDATE_PRO_SHOW_FILE_REQUEST } }
    function success(response) { return { type: adminConstants.UPDATE_PRO_SHOW_FILE_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.UPDATE_PRO_SHOW_FILE_FAILURE, payload: error } }
}

export function deleteProShowFile(destinationId) {
    return dispatch => {
        dispatch(request());
        return proShowFileService.deleteProShowFile(destinationId)
            .then(
                response => {
                    dispatch(success(response));
                    dispatch(alertActions.success(response.message));
                    return response;
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error.message));
                }
            );
    }

    function request() { return { type: adminConstants.DELETE_DESTINATION_REQUEST } }
    function success(response) { return { type: adminConstants.DELETE_DESTINATION_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.DELETE_DESTINATION_FAILURE, payload: error } }
}