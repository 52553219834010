import { adminConstants } from '../constant/admin-constant';
import { frontEndUserConstants } from '../constant/front-end-constant';
import { objectsConstants } from '../constant/objects-constant';

const initialState = {
	loadingAgency: false,
	agenciesOption: [],
	publishableAgenciesOption: [],
	submittedResource: false,
	resourceFiles: [],
	filesPreview: [],
	editAgency: {
		name: '',
		publishable_agency_name: '',
		primary_address: {
			street_address_1: '', city: '', zipcode: '', state_id: '', country_id: '',
		},
		secondary_address: {
			street_address_1: '', city: '', zipcode: '', state_id: '', country_id: ''
		},
		accounting_contacts: {}
	},
	agencyOrders: {},
	invLocationStats: {
		items: [],
		totalInvLoc: []
	},
	preAwarenessAgencies: {
		allowed_agencies: [],
		not_allowed_agencies: []
	},
    is_ttk: false,
    agencySearchParams: {
		agencyAdvanceSearch: { ...objectsConstants.AGENCYADVANCESEARCH },
		agencyUserPreferences: { ...objectsConstants.AGENCYUSERPREFERENCES },
	},
};

export function agenciesReducer(state = initialState, action) {

	switch (action.type) {

		//Optimize
		// case adminConstants.PAGINATION_AGENCIES_REQUEST:
		// 	return { ...state, loadingAgency: true, agencies: {}, editAgency: '', storedAgency: '', updatedAgency: '', deleteAgency: false, changeAgencyStatus: false };
		// case adminConstants.PAGINATION_AGENCIES_SUCCESS:
		// 	return { ...state, agenciesData: action.payload.agencies.data, pages: action.payload.agencies.last_page, loadingAgency: false, current_page: action.payload.agencies.current_page };
		// case adminConstants.PAGINATION_AGENCIES_FAILURE:
		// 	return { ...state, loadingAgency: false, error: action.payload };

		// case adminConstants.STORE_AGENCY_REQUEST:
		// 	return { ...state, loadingAgency: true, editAgency: '', storedAgency: '' }
		// case adminConstants.STORE_AGENCY_SUCCESS:
		// 	return { ...state, loadingAgency: false, storedAgency: action.payload.agency };
		// case adminConstants.STORE_AGENCY_FAILURE:
		// 	return { ...state, loadingAgency: false, error: action.payload };

		case adminConstants.GELL_ALL_AGENCY_USER_REQUEST:
			return { ...state, loadingAgency: true }
		case adminConstants.GELL_ALL_AGENCY_USER_SUCCESS:
			return { ...state, loadingAgency: false, agenciesOption: action.payload && action.payload.agencies ? action.payload.agencies : initialState.agenciesOption };
		case adminConstants.GELL_ALL_AGENCY_USER_FAILURE:
			return { ...state, loadingAgency: false, error: action.payload };

            //---------------PUBLISHALE AGENCIES------------------//

        case adminConstants.GET_ALL_AGENCY_PUBLISH_ABLE_USER_REQUEST:
            return { ...state, loadingAgency: true }
		case adminConstants.GET_ALL_AGENCY_PUBLISH_ABLE_USER_SUCCESS:
			return { ...state, loadingAgency: false, publishableAgenciesOption: action.payload && action.payload.agencies ? action.payload.agencies : initialState.publishableAgenciesOption };
		case adminConstants.GET_ALL_AGENCY_PUBLISH_ABLE_USER_FAILURE:
			return { ...state, loadingAgency: false, error: action.payload };

		case adminConstants.GET_AGENCY_REQUEST:
			return { ...state, loadingAgency: true };
		case adminConstants.GET_AGENCY_SUCCESS:
			return { ...state,
                loadingAgency: false,
                editAgency: action.payload.agency,
                is_ttk: action.payload && action.payload.agency && action.payload.agency.accounts && action.payload.agency.accounts[0] && action.payload.agency.accounts[0].title === 'Travel TripKit' ? true : false
            };
		case adminConstants.GET_AGENCY_FAILURE:
			return { ...state, loadingAgency: false, error: action.payload };

		// case adminConstants.EDIT_AGENCY_REQUEST:
		// 	return { ...state, loadingAgency: true, updatedAgency: '' };
		// case adminConstants.EDIT_AGENCY_SUCCESS:
		// 	return { ...state, loadingAgency: false, updatedAgency: action.payload.agency };
		// case adminConstants.EDIT_AGENCY_FAILURE:
		// 	return { ...state, loadingAgency: false, error: action.payload };

		// case adminConstants.DELETE_AGENCY_REQUEST:
		// 	return { ...state, loadingAgency: true, deleteAgency: false }
		// case adminConstants.DELETE_AGENCY_SUCCESS:
		// 	return { ...state, loadingAgency: false, deleteAgency: true };
		// case adminConstants.DELETE_AGENCY_FAILURE:
		// 	return { ...state, loadingAgency: false, error: action.payload };

		case adminConstants.CHANGE_AGENCY_STATUS_REQUEST:
			return { ...state, loadingAgency: true, changeAgencyStatus: false }
		case adminConstants.CHANGE_AGENC_STATUS_SUCCESS:
			return { ...state, loadingAgency: false, changeAgencyStatus: true };
		case adminConstants.CHANGE_AGENC_STATUS_FAILURE:
			return { ...state, loadingAgency: false, error: action.payload };
		case frontEndUserConstants.GET_AGENCY_ORDERS_SUCCESS:
			return {
				...state,
				agencyOrders: action.payload.data
			}
		case frontEndUserConstants.GET_AGENCY_ORDERS_FAILURE:
			return {
				...state,
				error: action.payload
			}

		case adminConstants.GET_INVENTORY_LIST_REQUEST:
			return { ...state, loadingAgency: true };
		case adminConstants.GET_INVENTORY_LIST_SUCCESS:
			return { ...state, agencyInventories: action.payload.data.pagination.data, lastPageInv: action.payload.data.pagination.last_page, loadingAgency: false, currentPageInv: action.payload.data.pagination.current_page };
		case adminConstants.GET_INVENTORY_LIST_FAILURE:
			return { ...state, loadingAgency: false, error: action.payload };

		case adminConstants.STORE_INVENTORY_REQUEST:
			return { ...state, loadingAgency: true };
		case adminConstants.STORE_INVENTORY_SUCCESS:
			return { ...state, inventory: action.payload.inventory, loadingAgency: false };
		case adminConstants.STORE_INVENTORY_FAILURE:
			return { ...state, loadingAgency: false, error: action.payload };

		case adminConstants.GET_INVENTORY_REQUEST:
			return { ...state, loadingAgency: true };
		case adminConstants.GET_INVENTORY_SUCCESS:
			return { ...state, editAgencyInv: action.payload.editAgencyInv, loadingAgency: false };
		case adminConstants.GET_INVENTORY_FAILURE:
			return { ...state, loadingAgency: false, error: action.payload };

		case adminConstants.UPDATE_INVENTORY_REQUEST:
			return { ...state, loadingAgency: true };
		case adminConstants.UPDATE_INVENTORY_SUCCESS:
			return { ...state, updatedInv: action.payload.updatedInv, loadingAgency: false };
		case adminConstants.UPDATE_INVENTORY_FAILURE:
			return { ...state, loadingAgency: false, error: action.payload };

		case adminConstants.DELETE_INVENTORY_REQUEST:
			return { ...state, loadingAgency: true };
		case adminConstants.DELETE_INVENTORY_SUCCESS:
			return { ...state, deleteInv: action.payload.deleteInv, loadingAgency: false };
		case adminConstants.DELETE_INVENTORY_FAILURE:
			return { ...state, loadingAgency: false, error: action.payload };

		case adminConstants.GELL_INVENTORY_LOCATION_STATS_REQUEST:
			return { ...state, loadingAgency: true }
		case adminConstants.GELL_INVENTORY_LOCATION_STATS_SUCCESS:
			return { ...state, loadingAgency: false, invLocationStats: action.payload.data };
		case adminConstants.GELL_INVENTORY_LOCATION_STATS_FAILURE:
			return { ...state, loadingAgency: false, error: action.payload };

		case adminConstants.UPDATE_INVENTORY_LOCATION_REQUEST:
			return { ...state, loadingAgency: true };
		case adminConstants.UPDATE_INVENTORY_LOCATION_SUCCESS:
			return { ...state, [action.payload.data.key]: action.payload.data[action.payload.data.key], loadingAgency: false };
		case adminConstants.UPDATE_INVENTORY_LOCATION_FAILURE:
			return { ...state, loadingAgency: false, error: action.payload };
		case adminConstants.GET_PREAWARENESS_AGENCIES_SUCCESS:
			return {
				...state,
				preAwarenessAgencies: action.payload.data
			}
			case adminConstants.UPLOAD_FILE:
				return {
					...state,
					resourceFiles: action.files,
					filesPreview: action.files,
					resource: action.resource
				}
			case adminConstants.UPLOAD_FILE_REQUEST:
				return {
					...state,
					submittedResource: true
				}
			case adminConstants.UPLOAD_FILE_SUCCESS:
				return {
					...state,
					resourceFiles: [],
					filesPreview: [],
					submittedResource: false,
					// order_details: {
					// 	...state.order_details,
					// 	order_resources_count: action.payload
					// }
				}
            case adminConstants.SAVE_AGENCY_SEARCH_PARAMS:
                return {
                    ...state,
                    agencySearchParams: action.payload.data
                }
		default:
			return { ...state };
	}
}
