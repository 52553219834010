import React, { Component } from 'react';
import { helperFunctions as HF} from 'helper/helperFunctions';
// import MyImage from 'components/Upload/MyImage';

class MainSlider extends Component{

  render(){

    return(
        <section id="main_slider" className="slider_main flexElem respFlex">
          <div className="container">
            <div className="col-md-6">
              <div className="banner_text_big">Storytelling Video</div>
              
              <div className="banner_text_small">
                Made From Tour Travel Pics!
              </div>

              <div className="downloadBtns flexElem respFlex">
                <a target="_blank" href="https://itunes.apple.com/us/app/group-travel-videos/id581218198?mt=8&uo=4"><img src={HF.s3StaticPath('img/appStore.png')} alt="Download from App Store"/></a>
                <a target="_blank" href="https://play.google.com/store/apps/details?id=com.PhotoVisionInc.GTV"><img src={HF.s3StaticPath('img/playStore.png')} alt="Download from Play Store"/></a>
              </div>
              <p>
                Download Free App
              </p>
            </div>
        
            
          </div>
          <div className="clearfix"></div>
        </section>
    );
  }
}


export default MainSlider;