import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Grid, Row, Col } from 'react-bootstrap'
import Card from '../../Card/Card'
import { inputChangeHandler, columnFilters, createSortString, getFormattedDate } from '../../../helper/helperFunctions'
import { Link } from 'react-router-dom'
import { getOrderCheckInList } from '../../../redux/actions/order-actions'
import Pagination from '../../ThemeComponents/Pagination'

class OrderCheckInList extends Component {
    constructor(props) {
        super(props);
        this.inputChangeHandler = inputChangeHandler.bind(this);
        this.state = {
            filtered: '',
            pageSize: 100
        }
    }

    paginationCall = (data) => {
        let matchFilterWith;
        if (this.pagination) {
            if (this.pagination.reactTable) {
                matchFilterWith = columnFilters(this.pagination.reactTable.state.columns[0].columns)
            }
        }
        let sorted = data.sort.split(',');
        let { sort, orderType } = createSortString(sorted)
        return this.props.getOrderCheckInList(data.pageSize, sort, orderType, data.filter, matchFilterWith);
    }

    render() {
        const columns = [
            {
                Header: 'Program Types',
                headerClassName: 'headerPagination',
                columns: [
                    {
                        Header: 'Barcode',
                        accessor: 'barcode',
                        width: 200,
                        className: 'action-center',
                        sortable: true,
                        Cell: ({ value, original }) => {
                            return <Link target='_blank' to={`/Admin/${original.id}/order-edit`} className='blue_link'>{value}</Link>
                        }
                    },
                    {
                        Header: 'Group',
                        accessor: 'group_name',
                        className: 'action-center',
                        sortable: true,
                    },
                    {
                        Header: 'Destiantion',
                        id: 'destinations',
                        accessor: 'destinations',
                        className: 'action-center',
                        sortable: false,
                    },
                    {
                        Header: 'DVD\'s',
                        id: 'numberOfVideos',
                        accessor: 'numberOfVideos',
                        className: 'action-center',
                        sortable: true,
                    },
                    {
                        Header: 'Departure Date',
                        id: 'orderDateSingle.departure_date.value',
                        accessor: 'order_dates.departure_date.value',
                        className: 'action-center',
                        sortable: true,
                        Cell: ({ value }) => {
                            return getFormattedDate(value)
                        }
                    },
                    {
                        Header: 'Return Date',
                        accessor: 'order_dates.return_date.value',
                        className: 'action-center',
                        id: 'orderDateSingle.return_date.value',
                        sortable: true,
                        Cell: ({ value }) => {
                            return getFormattedDate(value)
                        }
                    },
                    {
                        Header: 'Upload Cut Off Date',
                        accessor: 'order_dates.upload_cut_off_date.value',
                        className: 'action-center',
                        id: 'orderDateSingle.upload_cut_off_date.value',
                        sortable: true,
                        width: 150,
                        Cell: ({ value }) => {
                            return getFormattedDate(value)
                        }
                    },
                    {
                        Header: 'Photo Count',
                        accessor: 'photoCount',
                        className: 'action-center',
                        sortable: true,
                    },
                    {
                        Header: 'Status',
                        accessor: 'status.title',
                        className: 'action-center',
                        sortable: false,
                    },
                    {
                        Header: 'Contact Log',
                        className: 'action-center',
                        sortable: false,
                        Cell: ({ value, original }) => {
                            return <Link target='_blank' to={`/Admin/${original.id}/order-edit?commentCategoryCode=ContactLog#commentTabs`} className='blue_link'>Contact Log</Link>
                        }
                    }
                ]
            }
        ]
        const { /*orderCheckInList*/ } = this.props;
        return (
            <div className='content'>
                <Grid fluid>
                    <Row>
                        <Col md={12}>
                            <Card content={
                                <React.Fragment>
                                    <div className="secHeading">Order 3 Day Checkin List</div>
                                    {/* <div className='flexElem' style={{ justifyContent: 'space-between' }}>
                                        <div className='my-20 col-md-4'>
                                            <input onChange={this.inputChangeHandler} type='text' name='filtered' className='form-control' placeholder='Search...' />
                                        </div>
                                    </div> */}
                                    <div>
                                        {/* <p>Orders departure date by {getFormattedDate(days_from_now.date)} ({days_from_now.days} days from now)</p> */}
                                    </div>
                                    {/* <ReactTable
                                        noDataText='No Email Found'
                                        manual
                                        columns={columns}
                                        data={orderCheckInList.data}
                                        defaultPageSize={100}
                                        onFetchData={(state) => {
                                            let matchFilterWith = columnFilters(state.columns[0].columns);
                                            let filter = state.filtered;
                                            let { sort, orderType } = createSortString(state.sorted);
                                            this.props.getOrderCheckInList(state.pageSize, sort, orderType, filter, matchFilterWith);
                                        }}
                                    /> */}
                                    <div className='row'>
                                        <Pagination
                                            ref={(p) => this.pagination = p}
                                            showPagination={false}
                                            columns={columns}
                                            pageSize={100}
                                            noDataText='No Email Found'
                                            getDataCall={this.paginationCall}
                                            filterView={false}
                                            // downloadData={true}
                                            // downloadFileName={'Orders'}
                                            // lowerContent = {null}
                                            // filterPlaceHolder = {'Orders'}
                                            getRowProps={this.getRowProps}
                                        // showAllToggle={true}
                                        />
                                    </div>
                                </React.Fragment>
                            } />
                        </Col>
                    </Row>
                </Grid>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { orderCheckInList } = state.ordersReducer;
    return {
        orderCheckInList
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getOrderCheckInList: (pageSize, sort, orderType, filter, matchFilterWith) => { return dispatch(getOrderCheckInList(pageSize, sort, orderType, filter, matchFilterWith)) }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderCheckInList)
