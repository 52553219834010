import React, { Component } from "react";
import { NavItem, Nav } from "react-bootstrap";
import { connect } from 'react-redux';

import { logout } from 'redux/actions/admin-user-action';
import Pusher from "pusher-js";
import { authHeader } from 'helper/authHeader';
import { baseUrl } from 'helper/ApiCall';
import { checkLoggedInUserById, hasRole, s3StaticPath } from 'helper/helperFunctions';
import { alertActions } from 'redux/actions/alert-actions';
import { history } from 'helper/history';
import MyImage from 'components/Upload/MyImage';

class HeaderLinksPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: null,
      login: false,
    }
  }
  componentDidMount() {

    Pusher.logToConsole = true;
    const pusher = new Pusher('817d7356d8726f318e88', {
      cluster: 'ap2',
      forceTLS: true,
      authEndpoint: baseUrl + 'pusher/auth',
      auth: {
        headers: authHeader()
      }
    });

    const channel = pusher.subscribe('private-notification');
    channel.bind('pusher:subscription_succeeded', data => {
    //   console.log('successfully subscribed!', data);
    });
    channel.bind('broadCast-notification', data => {
    //   console.log('type', data.notification_type);
      if (data.notification_type === "notification") {
        if (!checkLoggedInUserById(data.created_by) && hasRole(data.role))
        // console.log('type', data.notification_type);
          this.props.messageCall(data);
      } else if (data.notification_type === "logout") {
        localStorage.removeItem('user');
        history.push('/Admin/admin-login');
      }


    });
    if ( window.location.href.indexOf("Admin/dashboard") > -1)  {
        this.setState({
        login: true
      })
    }

  }
  render() {
    const { loggedIn, userAdmin } = this.props;
    const { login } = this.state;
    const user = JSON.parse(localStorage.getItem('user'));
    // console.log('login', login);
    // console.log('userAdmin', userAdmin);
    let nameCapitalized;
    if (user) {
      nameCapitalized = user.user.name.charAt(0).toUpperCase() + user.user.name.slice(1);
    } else {
      nameCapitalized = null;
    }
    return (
      <div>
          {(login || loggedIn ) &&
            <Nav pullRight>
                <React.Fragment>
                <div className="dropdown headerLogin">
                    <div style={{ color: '#fff' }} className='dropdown-toggle' data-toggle="dropdown">
                    <MyImage src={s3StaticPath('img/user.svg')} alt="user icon" className="headerUserIcon" />
                    <span style={{ marginLeft: '5px' }}>{nameCapitalized}</span>
                    <span className="caret"></span>
                    </div>
                    <ul className="dropdown-menu text-center topNav" style={{ backgroundColor: '#2f4576' }}>
                    <NavItem onClick={() => { this.props.onLogoutClick() }}>Logout <MyImage src={s3StaticPath('img/logout.svg')} alt="logoutIcon" /> </NavItem>
                    </ul>
                </div>
                </React.Fragment>
            </Nav>
          }
      </div>
    );
  }
};


function mapStateToProps(state) {
  const { userAdmin, loggedIn } = state.adminReducer;
  return {
    userAdmin, loggedIn
  };
};
function mapDispatchToProps(dispatch) {
  return dispatch => {
    return {
      onLogoutClick: () => { dispatch(logout()) },
      messageCall: (message) => { dispatch(alertActions.success(message, 'notification')) }

    }
  }
}
const HeaderLinks = connect(mapStateToProps, mapDispatchToProps)(HeaderLinksPage);
export default HeaderLinks;
