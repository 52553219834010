import React, { Component } from "react";
import { adminLabels } from '../../redux/constant/admin-label-constant';

class ConfirmEditTracking extends Component {

	render(){
        let description = '';
        if (this.props.notChangeable) {
            description = "This order has already been recieved, you cannot un-process PTM"
        } else {
            description = this.props.description + (this.props.name ? (this.props.name + '?') : '')
        }
		return(
			<div className='custom-ui'>
	            <div className="react-confirm-alert-body text-center">
	              	<div><i className="pe-7s-attention" style={{"fontSize":"40px", "color":"red"}}></i></div>
	              	<h3 style={{"color":"#4f7bea", "fontSize":"20px"}}>{ this.props.typeName }</h3>
	              	<p className="marginFromTopPopUp" >{ description }</p>
	              	{
                        this.props.notChangeable 
                        ? 
                            <div log= {console.log("if condition")}  className="react-confirm-alert-button-group alignFlexButtons">
                                <button className="btn btn-success" onClick={() => {
                                    this.props.onClosePro()
                                }}>
                                <i className="pe-7s-check"></i> OK</button>
                            </div>
                        :
                        !this.props.mozaix 
                        ?
                            <div log= {console.log("else if condition", this.props.notChangeable)} className="react-confirm-alert-button-group alignFlexButtons">
                                <button onClick={this.props.onClosePro} className="btn btn-danger"><i className="pe-7s-close-circle"></i> No</button>
                                <button className="btn btn-success" onClick={() => {
                                    this.props.editModel()
                                    this.props.onClosePro()
                                }}>
                                <i className="pe-7s-check"></i> Yes</button>
                            </div>
                        :
                        <div log= {console.log("else condition")}  className="react-confirm-alert-button-group alignFlexButtons">
                            <button className="btn btn-success" onClick={() => {
                                this.props.submit()
                                this.props.onClosePro()
                            }}>
                            <i className="pe-7s-check"></i> OK</button>
                        </div>
                    }
	            </div>
	        </div>
		);
	};
}



export default ConfirmEditTracking;
