import React, { Component } from "react";
import  CustomCheckbox  from "components/CustomCheckbox/CustomCheckbox.jsx";
import {Card} from 'components/Card/Card.jsx';

class AdditionalSearch extends Component {
	render(){
		const { additionalFilters, isOpen } = this.props;
		return(
			<Card bsClass={["innerCard relativeCard"]} lazyRender title="Additional Filters" collapseTrigger={isOpen} collapsable={true} collapsHandler={()=>this.props.collapseHandle('isOpenAdvanceFilters')}
				content={
				<React.Fragment>
					<div className="clearfix mt-md fullCalendar">
						<div className="flexElem mt-md mb-sm flexResponsive" style={{ "alignItems": "center",}}>
							<div style={{marginRight:'100px'}}>

								<CustomCheckbox 
									tooltipText="Only Show Orders That Have NOT Been Cancelled" 
									label="Only Show Orders That Have NOT Been Cancelled" 
									value={additionalFilters.not_cancelled}
									indexArray={['not_cancelled']} 
									name="not_cancelled"
									parentState="additionalFilters"
									handleChandgeCheckBox = { this.props.handleSearchCheckBoxPro } 
								/>
							
								<CustomCheckbox 
									tooltipText="Only Show Orders That Have Been Cancelled" 
									label="Only Show Orders That Have Been Cancelled" 
									value={additionalFilters.cancelled}
									indexArray={['cancelled']} 
									name="cancelled"
									parentState="additionalFilters"
									handleChandgeCheckBox = { this.props.handleSearchCheckBoxPro } 
								/>

								<CustomCheckbox 
									tooltipText="Only Show Produced" 
									label="Only Show Produced" 
									value={additionalFilters.produced}
									indexArray={['produced']} 
									name="produced"
									parentState="additionalFilters"
									handleChandgeCheckBox = { this.props.handleSearchCheckBoxPro } 
								/>

								<CustomCheckbox 
									tooltipText="Only Show Not Produced" 
									label="Only Show Not Produced" 
									value={additionalFilters.not_produced}
									indexArray={['not_produced']} 
									name="not_produced"
									parentState="additionalFilters"
									handleChandgeCheckBox = { this.props.handleSearchCheckBoxPro } 
								/>

								<CustomCheckbox 
									tooltipText="Only show Not Demos" 
									label="Only show Not Demos" 
									value={additionalFilters.not_programType}
									indexArray={['not_programType']} 
									name="not_programType"
									parentState="additionalFilters"
									handleChandgeCheckBox = { this.props.handleSearchCheckBoxPro } 
								/>
								{
									/*
									<CustomCheckbox 
										tooltipText="Only Show Reorders" 
										label="Only Show Reorders" 
										value={additionalFilters.reorders}
										indexArray={['reorders']} 
										name="reorders"
										parentState="additionalFilters"
										handleChandgeCheckBox = { this.props.handleSearchCheckBoxPro } 
									/>
									*/
								}
							</div>
							<div>
								
								<CustomCheckbox 
									tooltipText="Only Show Orders On Hold" 
									label="Only Show Orders On Hold" 
									value={additionalFilters.on_hold}
									indexArray={['on_hold']} 
									name="on_hold"
									parentState="additionalFilters"
									handleChandgeCheckBox = { this.props.handleSearchCheckBoxPro } 
								/>

								<CustomCheckbox 
									tooltipText="Only Show Orders That are UnResolved" 
									label="Only Show Orders That are UnResolved" 
									value={additionalFilters.un_resolved}
									indexArray={['un_resolved']} 
									name="un_resolved"
									parentState="additionalFilters"
									handleChandgeCheckBox = { this.props.handleSearchCheckBoxPro } 
								/>

								<CustomCheckbox 
									tooltipText="Only Show Orders with Photos Not Auto-Approved for Posting" 
									label="Only Show Orders with Photos Not Auto-Approved for Posting" 
									value={additionalFilters.not_auto_approve_photos}
									indexArray={['not_auto_approve_photos']} 
									name="not_auto_approve_photos"
									parentState="additionalFilters"
									handleChandgeCheckBox = { this.props.handleSearchCheckBoxPro } 
								/>

								<CustomCheckbox 
									tooltipText="Only Show Orders With Digital Assets Assigned To It" 
									label="Only Show Orders With Digital Assets Assigned To It" 
									value={additionalFilters.digital_assets_assigned}
									indexArray={['digital_assets_assigned']} 
									name="digital_assets_assigned"
									parentState="additionalFilters"
									handleChandgeCheckBox = { this.props.handleSearchCheckBoxPro } 
								/>

								<CustomCheckbox 
									tooltipText="Only Show Multiple Attempts Made- Approve for Shipping" 
									label="Only Show Multiple Attempts Made- Approve for Shipping" 
									value={additionalFilters.auto_approve_photos}
									indexArray={['auto_approve_photos']} 
									name="auto_approve_photos"
									parentState="additionalFilters"
									handleChandgeCheckBox = { this.props.handleSearchCheckBoxPro } 
								/>
							</div>
						</div>
						
						
					
						
						<div className="col-md-12 text-right">
							<button className="backButton pt-sm no_radius pb-sm success btn btn-info ml-sm mt-sm" onClick={()=>this.props.changeStatusForSearch(true, 'additionalFilters') }>Select All</button>
							<button className="backButton pt-sm no_radius pb-sm success btn btn-info ml-sm mt-sm" onClick={()=>this.props.changeStatusForSearch(false, 'additionalFilters') }>Unselect All</button>

							<button onClick={ ()=>this.props.performSearch() } className="backButton pt-sm no_radius pb-sm primary btn btn-md btn-info ml-sm mt-sm">Perform Search</button>
						</div>
					</div>
				</React.Fragment>
			}
			/>
		);
	};
}



export default AdditionalSearch;
