export default class Template {
    static variables() {
        return {
            CONTACTNAME: 'GROUP CONTACT NAME',
            CONTACTPHONE: 'GROUP CONTACT PHONE',
            GROUPNAME: 'TOUR GROUP',
            BARCODE: 'Order Barcode',
            APPNAME: 'BRAND NAME',
            GTVAPPDEMOLINK: 'App Demo Link',
            EXT: 'user phone extension',
            YOURNAME: 'Logged in username',
            CREATEDAT: 'Order Created Date',
            AGENCYNAME: 'TOUR AGENCY',
            SHIPPINGADDRESS: 'Pre-Trip Material Address',
            UPSTRACKING: 'Video Tracking Number',
            SENDERNAME: 'Sender name for manually sent emails',
            CAMERATYPE: 'Order Camera Type',
            CAMERACOUNT: 'Order Camera Count',
            CAMERASHIPDATE: 'Order Camera Ship Date',
            CAMERASHIPADDRESS: 'Order Camera Ship Address',
            SENDEREMAIL: 'Sender email for manually sent emails',
            RETURNDATEPLUS3: 'Three Days after tour return',
            PHOTOADMINLINK: 'Link that automaticallly logs user in as photo admin',
            DESTINATION: 'Trip Destination',
            APPINFO1: 'Each traveler will receive their very own DVD souveneir copy, and will be able to view photos and the video on their smartphones, tablets and computers.',
            APPINFO2: 'While you are there, take a look at our app which allows your group to upload photos as you travel allowing family back home to virtually go on the trip with you! It is very simple, fun an free to use.',
            NUMBER_OF_PHOTOS_UPLOADED: '# of Uploaded Photos',
            UPLOAD_CUTOFF_DATE: 'Upload Cut Off Date',
            UPLOADCUTOFFDATE: 'Upload Cut Off Date',
            TRAVELER_USERNAME: 'Traveler Username',
            TRAVELER_PASSWORD: 'Traveler Password',
            USERNAME: 'Traveler Username',
            PASSWORD: 'Traveler Password',
            PHOTOADMIN_USERNAME: 'Photo Admin Username',
            PHOTOADMIN_PASSWORD: 'Photo Admin Password',
            ADMIN_USERNAME: 'Admin Username',
            ADMIN_PASSWORD: 'Admin Password',
            PERSONALIZED_EMAIL_SIGNATURE: 'Full Personalized email signature to make all signatures consistent',
            VIDEO_SHIPPING_ADDRESS: 'Video Shipping Address',
            TOUR_CODE: 'Client Tour Code',
        }
    }
}