import { frontEndUserConstants } from "../constant/front-end-constant";

const initialState = {
    testimonails: {
        data: []
    }
}

export default (state = initialState, action) => {
    switch (action.type) {

        case frontEndUserConstants.GET_ALL_TESTIMONIALS_SUCCESS:
            return {
                ...state,
                testimonails: action.payload.data, pages: action.payload.data.last_page, current_page: action.payload.data.current_page, perPage: action.payload.data.per_page
            }
        case frontEndUserConstants.GET_ALL_TESTIMONIALS_FAILURE:
            return {
                ...state,
                error: action.payload
            }
        case frontEndUserConstants.STORE_TESTIMONAIL_FAILURE:
            return {
                ...state,
                error: action.payload
            }
        default:
            return state
    }
}
