import { SC } from "../helper/ServerCall";

export const reportService = {
    getOrdersWithStatus,
    getVideoShipments,
    getTodaysShipments,
    getWebLog,
    getOrderLog,
    getAccessLog,
    getOrderBooking,
    getForcastReport,
    getReceivingReports,
    getUCDReached,
    getContactLog,
    checkOrderStatusWithBarcode,
    createLabelWithBarcode,
    getEditingReports,
    getProductionReports,
    getRecetlyReceivedOrders,
    getOrderOnHoldList
};

function getOrdersWithStatus(status, value, fromDate, toDate, page, pageSize, sorted) {
    return SC.getCall(`getOrdersWithStatus/${status}/${value}?from=${fromDate}&to=${toDate}&page=${page}&pageSize=${pageSize}&sort=${sorted}`);
}

function getVideoShipments(value, fromDate, toDate, page, pageSize, sorted) {
    return SC.getCall(`videoShipmentsReport?from=${fromDate}&to=${toDate}&page=${page}&pageSize=${pageSize}&sort=${sorted}`);
}

function getTodaysShipments(value, fromDate, toDate, page, pageSize, sorted) {
    return SC.getCall(`todayShipmentsReport?from=${fromDate}&to=${toDate}&page=${page}&pageSize=${pageSize}&sort=${sorted}`);
}

function getWebLog(value, fromDate, toDate, page, pageSize, sorted) {
    return SC.getCall(`webLogReport?from=${fromDate}&to=${toDate}&page=${page}&pageSize=${pageSize}&sort=${sorted}&order_id=${value}`);
}

function getContactLog(fromDate, toDate, page, pageSize, sorted) {
    return SC.getCall(`contactLogReport?from=${fromDate}&to=${toDate}&page=${page}&pageSize=${pageSize}&sort=${sorted}`);
}

function getAccessLog(value, fromDate, toDate, page, pageSize, sorted) {
    return SC.getCall(`accessLogReport?from=${fromDate}&to=${toDate}&page=${page}&pageSize=${pageSize}&sort=${sorted}`);
}

function getOrderBooking(value, fromDate, toDate, page, pageSize, sorted) {
    return SC.getCall(`ordersBookingReport?from=${fromDate}&to=${toDate}&page=${page}&pageSize=${pageSize}&sort=${sorted}`);
}

function getForcastReport(value, fromDate, toDate, page, pageSize, sorted) {
    return SC.getCall(`ordersForcastReport?from=${fromDate}&to=${toDate}&page=${page}&pageSize=${pageSize}&sort=${sorted}`);
}

function getUCDReached(numOfDays, fromDate, toDate, page, pageSize, sorted) {
    return SC.getCall(`ucdReachedButNotProduced?numOfDays=${numOfDays}&from=${fromDate}&to=${toDate}&page=${page}&pageSize=${pageSize}&sort=${sorted}`);
}

//
function getReceivingReports(data) {
    return SC.postCall(`ordersUploadPhotosVideoProduction`, data);
}

function getOrderOnHoldList(data) {
    return SC.postCall(`getOrderOnHoldList`, data);
}
function getRecetlyReceivedOrders(data) {
    return SC.postCall(`getRecetlyReceivedOrders`, data);
}

function getEditingReports(data) {
    return SC.postCall(`getEditingReports`, data);
}

function getProductionReports(data) {
    return SC.postCall(`getProductionReports`, data);
}

function checkOrderStatusWithBarcode(data) {
    return SC.postCall('receiveOrderByBarcode', data);
}

function createLabelWithBarcode(data) {
    return SC.postCall('orderFoundByBarcode', data);
}


function getOrderLog(value, fromDate, toDate, page, pageSize, sorted) {
    return SC.getCall(`getOrderActivityLog?from=${fromDate}&to=${toDate}&page=${page}&pageSize=${pageSize}&sort=${sorted}&order_id=${value}`);
}
