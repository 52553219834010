import React, { Component } from 'react'
import OrderForm from './OrderForm/OrderForm';

class EditOrder extends Component {
    render() {
        return (
            <div className='container'>
                <h2 className="panel_heading userLogin_heading">Edit Order</h2>
                <OrderForm formType='edit' id={this.props.match.params.id} />
            </div>
        )
    }
}

export default EditOrder
