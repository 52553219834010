import React, { Component } from 'react'
import { s3StaticPath } from 'helper/helperFunctions';

class FooterLogo extends Component {

   

    render() {
        
        return (
            <React.Fragment>
                <div>
                    <img src={ s3StaticPath('img/maintaince.png') } />
                </div>
            </React.Fragment>
        )
    }
}



export default FooterLogo
