import { adminConstants } from "./../constant/admin-constant";

const initialState = {
    holidays: []
}

export function holidayReducer(state = initialState, action) {

    switch (action.type) {
        case adminConstants.GET_ALL_HOLIDAYS_SUCCESS:
            return {
                ...state,
                holidays: action.payload.data
            }
        case adminConstants.GET_ALL_HOLIDAYS_FAILURE:
            return {
                ...state,
                error: action.payload
            }
        default:
            return state;
    }

}