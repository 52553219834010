import React from 'react';
// import Collapsible from 'react-collapsible';
import { connect } from "react-redux";
import { Button } from "react-bootstrap";
import { getAllModules } from '../../../redux/actions/module-actions';
// import arrow from "assets/img/arrow_down.png";
import ToggleButton from "react-toggle-button";
import { themeCSSConstants } from 'redux/constant/themeCSS';
import { editRole } from "./../../../redux/actions/role-actions";
import { Card } from '../../Card/Card.jsx';
import SearchInput, { createFilter } from 'react-search-input';
const KEYS_TO_FILTERS = ["permissions.name"];


class ModulePermissions extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            permissions: this.props.permissions,
            searchTerm: '',
        }

        this.searchUpdated = this.searchUpdated.bind(this);
        this.props.getModulesCall(parseInt(this.props.match.params.id, 10));
    }

    searchUpdated(term) {
        this.setState({ searchTerm: term });
    }

    componentDidUpdate(prevProps) {
        if (this.props.modules !== prevProps.modules) {
            this.setState({
                permissions: this.props.permissions,
            })
        }
    }

    extractModulePermissions = (module, Mindex) => {
        let change = true;
        return module.permissions.map((permission, index) => {
            if (index % 4 !== 0) {
                change = !change;
            }
            return (
                <div key={permission.id} className={change ? 'odd col-md-3 col-sm-6' : 'even col-md-3 col-sm-6'} >
                    <div className='row permissionCell'>
                        <div className='col-md-9'>
                            <span className='permissionName' >{permission.name}</span>
                        </div>
                        <div className='col-md-3 permissionSwitch'>
                            <ToggleButton
                                inactiveLabel={''}
                                activeLabel={''}
                                colors={themeCSSConstants.THUMBICONSTYLE}
                                trackStyle={{ height: '10px', width: '30px' }}
                                thumbStyle={themeCSSConstants.THUMBSTYLE}
                                thumbAnimateRange={[0, 15]}
                                value={permission.checked}
                                onToggle={this.permissionChange.bind(this, index, Mindex)}
                            />
                        </div>
                    </div>
                </div>
            );
        });
    }

    permissionChange = (Pindex, Mindex) => {

        const modules = this.props.modules;
        const permissions = this.state.permissions;
        const permission = modules[Mindex]['permissions'][Pindex];

        permission['checked'] = !permission['checked'];
        const checked = permission['checked'];

        if (checked) {
            permissions.push(permission);
        } else {
            const index = permissions.findIndex(function (element) {
                return element.id === permission.id;
            });
            permissions.splice(index, 1);
        }
        this.setState({
            permissions: permissions
        })
    }

    permissionFormChangeSubmit = (e) => {
        console.log('sdfsdf');
        e.preventDefault();
        const permissions = this.state.permissions;
        const role = this.props.role;
        const label = this.props.label;
        const id = this.props.match.params.id;
        const updatedPermissions = permissions.map(p => {
            return p.id
        });
        const roleData = {
            'name': role,
            'label': label,
            'permission': updatedPermissions
        };
        console.log('roleData', roleData);
        this.props.editRoleCall(roleData, id);
    }

    render() {
        const { modules } = this.props;
        const filteredResources = modules.filter(createFilter(this.state.searchTerm, KEYS_TO_FILTERS));
        let moduleContent;
        if (filteredResources.length > 0) {
            moduleContent = filteredResources.map((module, index) => {
                return (
                    <Card bsClass={["innerCard"]} key={index} title={module.name} content={
                        <div className="row" style={{ 'margin': '0' }}>
                            <span></span>
                            {
                                module.permissions ?
                                    this.extractModulePermissions(module, index)
                                    : null
                            }
                        </div>
                    } />
                )
            });
        } else {
            moduleContent = <div className="text-center well font-weight-bold">Permission not found!</div>;
        }
        return (
            <div className="container-fluid">
                <div className="col-md-12">
                    <div className='content'>
                        <div style={{ padding: '0 20px 15px', overflow: 'auto' }} className='card'>
                            <div className="flexElem mt-md mb-sm flexResponsive" style={{ "alignItems": "center", "justifyContent": "space-between" }}>
                                <div className="secHeading" style={{ textTransform: 'capitalize' }}>{this.props.role}</div>

                                <div className="custom-search-input">
                                    <SearchInput fuzzy={true} className="search-input form-control" placeholder="Search Permission" onChange={this.searchUpdated} />
                                    {/*<input type="text" className="form-control" placeholder="Search Permission" /> */}
                                </div>
                            </div>
                            <form onSubmit={this.permissionFormChangeSubmit} >
                                {moduleContent}
                                <div className="text-center">
                                    <Button type='submit' bsSize="large" className="backButton success pt-sm no_radius pb-sm btn btn-lg btn-default" >Save</Button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    const { modules, role, permissions, loadingModules, label } = state.moduleReducer;
    return { modules, role, permissions, loadingModules, label };
}

const mapDispatchToProps = dispatch => {
    return {
        getModulesCall: (id) => { dispatch(getAllModules(id)) },
        editRoleCall: (roleData, id) => { dispatch(editRole(roleData, id)) },
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(ModulePermissions);