import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getResourcesByRole } from 'redux/actions/agency-resource-actions';
import User from 'helper/User';
import { helperFunctions as HF} from 'helper/helperFunctions';


class MarketingFlyers extends Component {
    constructor(props) {
        super(props)
        this.props.getResourcesByRole(User.agency('id'));
    }

    render() {
        const { marketingFlyers } = this.props;
        return (
            <React.Fragment>
                <div className="form_tile">
                    <div className="form_tile_heading">
                        <img src={HF.s3StaticPath('img/marketing_flyers.png')} alt="Marketing Flyers" />
                        <span>Marketing Flyers</span>
                    </div>

                    <div className="form_tile_content">
                        <div className="flyer_links">
                            {
                                Object.keys(marketingFlyers).length > 0 ?
                                    <React.Fragment>
                                        {
                                            marketingFlyers.traveler_flyer_version1 &&
                                            <a rel="noopener noreferrer" target='_blank' href={marketingFlyers.traveler_flyer_version1.file_path} className="text-primary text_14 link_hover">Traveler Flyer (pdf)</a>
                                        }
                                        {
                                            marketingFlyers.traveler_flyer_version2 &&
                                            <a rel="noopener noreferrer" target='_blank' href={marketingFlyers.traveler_flyer_version2.file_path} className="text-primary text_14 link_hover">Traveler Flyer #2 (pdf)</a>
                                        }
                                        {
                                            marketingFlyers.before_departure_flyer &&
                                            <a rel="noopener noreferrer" target='_blank' href={marketingFlyers.before_departure_flyer.file_path} className="text-primary text_14 link_hover">Before Departure Flyer (pdf)</a>
                                        }

                                        {
                                            marketingFlyers.before_departure_flyer &&
                                            <a rel="noopener noreferrer" target='_blank' href={'https://grouptravelvideos.s3.amazonaws.com/edrivedata/company/00201/resources/GTV_beforeTravelFlier.pdf'} className="text-primary text_14 link_hover">Before Departure Flyer (pdf)</a>
                                        }

                                    </React.Fragment>
                                    : null
                            }
                        </div>
                    </div>
                </div>

            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    const { marketingFlyers } = state.frontEndUserReducer;
    return {
        marketingFlyers
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getResourcesByRole: (agency_id) => { dispatch(getResourcesByRole(agency_id)) }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MarketingFlyers)
