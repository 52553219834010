import React from 'react';
import {
	Button
} from "react-bootstrap";
import SimpleReactValidator from 'simple-react-validator';
import Select from 'react-select';
import { returnColumnValueWithColumnMatch, returnLabelWithValue, returnColumnValueWithColumnMatchSA } from 'helper/helperFunctions';
import { objectsConstants as OC } from 'redux/constant/objects-constant';

class InventoryForm extends React.Component {
	constructor(props) {
		super(props);
		this.validatorInventory = new SimpleReactValidator({ autoForceUpdate: this, locale: 'en' });
		this.state = {
			tempQuantity: this.props.inventoryPro.quantity,
			inventory: {
				...this.props.inventoryPro, quantity: 0
			},
			submitted: false,
			formType: this.props.formTypePro || '',
			inventoryType: this.props.inventoryType || '',
		};

		// this.handleInventory 			= this.handleInventory.bind(this);
		this.handleInventorySubmitForm = this.handleInventorySubmitForm.bind(this);
		// this.handleChangeInventory 		= this.handleChangeInventory.bind(this);
		// this.handleChangeAgency = this.handleChangeAgency.bind(this);
	}

	// handleChangeAgency(selectedOption, name) {
	// 	if (name.action === 'select-option'){
	// 		this.setState(prevState => ({
	// 		    inventory: {    
	// 		        ...prevState.inventory,  
	// 		        agency_id:  selectedOption.value
	// 		    }
	// 		}));
	// 	}
	// }
	handleChangeInventory = (selectedOption, info) => {
		if (info.action === 'select-option') {
			this.setState(prevState => ({
				inventory: {
					...prevState.inventory,
					[info.name]: selectedOption.value
				}
			}));
		}
		// if (name.action === 'remove-value'){
		// 	const rolesTemp = this.state.user.roles;
		// 	rolesTemp.splice(rolesTemp.indexOf(name.removedValue.value), 1);
		// 	this.setState(prevState => ({
		// 		user: {    
		// 	        ...prevState.user,
		// 	        roles: rolesTemp
		// 	    }
		// 	}))
		// }
	}
	handleInventory = (e) => {
		const { name, value, type } = e.target;
		const inventoryState = { ...this.state.inventory };
		inventoryState[name] = type === 'checkbox' ? e.target.checked ? true : false : value;
		this.setState({ inventory: inventoryState });
	}
	handleInventorySubmitForm(e) {
		e.preventDefault();
		if (this.validatorInventory.allValid()) {
			this.setState({ submitted: true }, function () {
				this.props.handleInventorySubmit(this.state.inventory, this.state.formType);
			});
		} else {
			this.setState({ submitted: false });
			this.validatorInventory.showMessages();
			this.forceUpdate();
		}
	}
	componentDidMount() {
		const { defaultItemById, defaultItemByName, defaultAgencyByName } = this.props;
		if (defaultItemById || defaultItemByName || defaultAgencyByName) {
			const { options, defaultItemId, defaultItemName } = this.props;
			// console.log('defaultAgencyByName', defaultAgencyByName);
			this.setState({
				inventory: {
					...this.state.inventory,
					item_id: defaultItemByName ? returnColumnValueWithColumnMatch(options.items, 'value', [defaultItemName], 'label') : defaultItemById ? parseInt(defaultItemId, 10) : null,
					agency_id: defaultAgencyByName ? returnColumnValueWithColumnMatch(options.agencies, 'value', [OC.DEFAULTAGENCY], 'label') : null,

				}
			});
		}
	}
	componentDidUpdate(prevProps, prevState) {
		if (prevState.inventory.badgeInventory && !this.state.inventory.badgeInventory) {
			this.setState({ inventory: { ...this.state.inventory, isSerialNumber: false } })
		}
	}
	render() {
		this.validatorInventory.purgeFields();
		const { inventory, formType, inventoryType, tempQuantity } = this.state;
		// console.log('inventory', inventory);
		const { options } = this.props;
		// var test = returnColumnValueWithColumnMatchSA( returnColumnValueWithColumnMatch(options.items, 'item_type', [inventory.item_id], 'value') , 'title');
		return (
			<form onSubmit={this.handleInventorySubmitForm} id="inventory-form">
				<div className="row">
					<span></span>
					<div className="col-md-6">
						<div className='form-group' id="itemId">
							<label htmlFor="name">Select Item<span className="requiredClass">*</span></label>
							<Select
								value={returnLabelWithValue(inventory.item_id, options.items)}
								onChange={this.handleChangeInventory}
								options={options.items}
								isSearchable={true}
								isMulti={false}
								placeholder={'Select Item'}
								isClearable={false}
								isDisabled={formType === 'edit' ? true : false}
								name="item_id"
							/>
							{this.validatorInventory.message('itemId', inventory.item_id, 'required')}
						</div>
					</div>

					<div className="col-md-6">
						<div className='form-group' id="agencyId">
							<label htmlFor="name">Select Agency<span className="requiredClass">*</span></label>
							<Select
								value={returnLabelWithValue(inventory.agency_id, options.agencies)}
								onChange={this.handleChangeInventory}
								options={options.agencies}
								isSearchable={true}
								isMulti={false}
								placeholder={'Select Agency'}
								isClearable={false}
								isDisabled={formType === 'edit' ? true : false}
								name="agency_id"
							/>
							{this.validatorInventory.message('agencyId', inventory.agency_id, 'required')}
						</div>
					</div>
					<span></span>

				</div>
				<div className="row">
					<span></span>
					<div className="col-md-6">
						<div className='form-group' id="quantity">
							<label htmlFor="quantity">Quantity<span className="requiredClass">*</span></label>
							<input type="number" autoComplete="off" name="quantity" className="form-control threshold_icon" value={inventory.quantity} onChange={this.handleInventory} max={returnColumnValueWithColumnMatchSA(returnColumnValueWithColumnMatch(options.items, 'item_type', [inventory.item_id], 'value'), 'title') === 'Physical Products' && !inventory.badgeInventory ? 1 : null} />
							{
								inventoryType === 'Add' || inventoryType === 'Create' ?
									this.validatorInventory.message('quantity', inventory.quantity, 'required|min:0,num') :
									this.validatorInventory.message('quantity', inventory.quantity, `required|min:0,num|max:${tempQuantity},num`)
							}
						</div>
					</div>
					<div className="col-md-6">
						<div className='form-group' id="threshold">
							<label htmlFor="threshold">Threshold<span className="requiredClass">*</span></label>
							<input type="number" autoComplete="off" name="threshold" className="form-control threshold_icon" value={inventory.threshold} onChange={this.handleInventory} />
							{this.validatorInventory.message('threshold', inventory.threshold, 'required|min:0,num')}
						</div>
					</div>
					<div className="col-md-6">
						<div className='form-group' id="purchasedPrice">
							<label htmlFor="purchased_price">Purchased Price<span className="requiredClass">*</span></label>
							<input type="number" autoComplete="off" name="purchased_price" className="form-control threshold_icon" value={inventory.purchased_price} onChange={this.handleInventory} step="any" />
							{this.validatorInventory.message('purchased_price', inventory.purchased_price, 'required|min:0,num')}
						</div>
					</div>

					<div className="col-md-6">
						<div className='form-group' id="barcode">
							<label htmlFor="barcode">Barcode
				        	{
									((formType === 'create') || (formType === 'edit' && (inventory.item.title !== 'Disc' && inventory.item.title !== 'Sleeve'))) &&
									<span className="requiredClass">*</span>
								}
							</label>
							<input type="text" autoComplete="off" name="barcode" className="form-control" value={inventory.barcode} onChange={this.handleInventory} />
							{
								((formType === 'create') || (formType === 'edit' && (inventory.item.title !== 'Disc' && inventory.item.title !== 'Sleeve'))) &&
								this.validatorInventory.message('barcode', inventory.barcode, 'required')

							}

						</div>
					</div>
					{
						!inventory.badgeInventory ?
							<div className="col-md-6">
								<div className='form-group' id="serialNumber">
									<label htmlFor="serial_number">Serial Number</label>
									<input type="text" autoComplete="off" name="serial_number" className="form-control" value={inventory.serial_number} onChange={this.handleInventory} />
									{
										/*
										!inventory.badgeInventory ?
											this.validatorInventory.message('serialNumber', inventory.serial_number, 'required')
										:
											null
										*/
									}
								</div>
							</div>
							: null
					}
					{
						formType === 'create' &&
						<div className="col-md-6" >
							<div className='form-group' id="badgeInventory">
								<label className="container-check checkbox_down">Badge Inventory
			              			<input type="checkbox" name="badgeInventory" className="checkbox" value={inventory.badgeInventory} onChange={this.handleInventory} checked={inventory.badgeInventory ? true : false} />
									<span className="checkmark"></span>
								</label>
							</div>
						</div>
					}
					{
						inventory.badgeInventory ?
							<div className="col-md-6" >
								<div className='form-group' id="isSerialNumber">
									<label className="container-check checkbox_down">Is Serial Number
			              			<input type="checkbox" name="isSerialNumber" className="checkbox" value={inventory.isSerialNumber} onChange={this.handleInventory} checked={inventory.isSerialNumber ? true : false} />
										<span className="checkmark"></span>
									</label>
								</div>
							</div>
							: null
					}
					<span></span>

				</div>


				<div className="row">
					<div className="col-md-12">
						<div className='text-center'>
							<Button type='submit' form="inventory-form" bsSize="large" bsStyle="info" className="backButton pt-sm no_radius pb-sm success btn btn-lg btn-info ml-sm mt-sm btn-default " >Save</Button>
							<Button bsSize="large" bsStyle="info" onClick={() => this.props.closeModel()} className="backButton pt-sm no_radius pb-sm primary btn btn-lg ml-sm mt-sm btn-info " >Cancel</Button>
						</div>
					</div>
				</div>
			</form>
		);
	}
}

export default InventoryForm;