import React, { Component } from "react";
import { connect } from 'react-redux';
import { getInvoicingOrders, submitInvoicingOrders, getPrintInvoices } from "redux/actions/invoicing-actions";
import ReactValidator from "simple-react-validator";
import DatePicker from "react-datepicker";
import {
    Grid,
    Row,
    Col,
    OverlayTrigger,
	Tooltip,
    Popover,
    Button
} from "react-bootstrap";
import { Card } from "components/Card/Card.jsx";
import 'react-table/react-table.css';
import { Link } from 'react-router-dom';
import { appendValues, dateFormatMy } from 'helper/helperFunctions';
import ReactToPrint from "react-to-print";
import PrintInvoices from "./PrintInvoices";
import Pagination from "../../ThemeComponents/Pagination";
import ReactDatePicker from "react-datepicker";

const moment = window.moment;
// var date = moment(new Date()).format("YYYY-MM-DD");
var today = new Date(),
date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();

class InvoicingOrdersPage extends Component {
    constructor(props) {
        super(props);
        this.dateValidator = new ReactValidator();
        this.state = {
            fromDate: '',
            to: '',
            pageSize: 10,
            page: 0,
            sorted: '',
            selected: {},
            selectAll: 0,
            newdata: [],
            checked:0,
            invoiceDate:date,
            selectedInvoiced: [],
            use_global_buffer: false
        };
        this.toggleRow = this.toggleRow.bind(this);
        this.renderEditable = this.renderEditable.bind(this);

    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.reportsData !== this.props.reportsData) {
            const temp = appendValues(this.props.reportsData, [{ column: 'comments', value: '' }, { column: 'checklist', value: false }]);
            this.setState({
                newdata: temp
            })
            console.log('updated');
        }
        if (prevState.use_global_buffer !== this.state.use_global_buffer) {
            this.pagination.dataCall();
        }
    }



    toggleRow(id) {
        let newdataTemp = [...this.state.newdata];
        newdataTemp[id]['checklist'] = !newdataTemp[id]['checklist']
        // console.log(id,'rowtoggle')
        // console.log("ToggleRow",this.state.checked)
        this.setState({
            newdata: newdataTemp,
            checked:0
        });

        this.checkedcalculator()

    }
    checkedcalculator(){
        this.setState({checked:0})
        var positive = 0
        var negative = 0
        this.state.newdata.map(x => x.checklist ? positive = positive + 1: negative +1)
        this.setState({checked : positive})
    }
    toggleSelectAll() {
        // let newSelected = {};
        let newdataTemp = [...this.state.newdata];
        if (this.state.selectAll === 0) {

            newdataTemp.forEach(x => {
                x['checklist'] = true;
            });
        } else {
            newdataTemp.forEach(x => {
                x['checklist'] = false;
            });
        }

        this.setState({
            newdata: newdataTemp,
            selectAll: this.state.selectAll === 0 ? 1 : 0
        });
        this.checkedcalculator()
    }
    useGlobalBufferCheck = (e) => {
        const value = e.target.checked ? true : false
        console.log("valueee", value);
        this.setState({
            use_global_buffer: value
        });
        
    }

    DateChangeHandler = (value, field) => {
        this.setState({ [field]: value === null ? '' : moment(value).format("YYYY-MM-DD") }, () => {
            if (this.dateValidator.allValid()) {
                const { fromDate, to, page, pageSize, sorted } = this.state;
                // this.props.getReportsCall(1, fromDate, to, page, pageSize, sorted);
            } else {
                this.dateValidator.showMessages();
                // this.forceUpdate();
            }
        })
    }
    dateElement(label, name, value, rules) {
        var selectedDate = this.state[name] !== '' ? new Date(this.state[name]) : '';
        return (
            <div className='form-group fullCalendar' id={name}>
                <label>{label}</label>
                <DatePicker className="form-control calender_icon" selected={selectedDate} name={name} onChange={(event) => this.DateChangeHandler(event, name)} />

            </div>
        );
    }
    reportsPaginationData = (page = this.state.page, sorted = this.state.sorted, pageSize = this.state.pageSize) => {
        const { fromDate, to } = this.state;
        this.props.getReportsCall(1, fromDate, to, page, pageSize, sorted);
    }
   changedDate = (x) => {
        this.setState({invoiceDate:x})
   }
    sendOrdersTOInvoice = (page = this.state.page, sorted = this.state.sorted, pageSize = this.state.pageSize) => {
        let newdataTemp = [...this.state.newdata];
        const { fromDate, to } = this.state;
        let newArray = {};
        newdataTemp.forEach(x => {
            if (x.checklist === true)
                newArray[x.id] = { "order_id": x.id, 'customer_note': x.comments,};
        });
        console.log("invoice", newArray);
        if (Object.keys(newArray).length === 0) {
            alert("Please select the orders to process")
            return
        }
        this.props.submitInvoicingOrdersCall(newArray,this.state.invoiceDate).then(
            response => {
                this.pagination.dataCall();
                // this.props.getReportsCall(1, fromDate, to, page, pageSize, sorted);
            },
            error => {

            }
        );
        this.checkedcalculator()

    }

    selectedInvoiced = () => {
        let newdataTemp = [...this.state.newdata];
        let newArray = [];
        newdataTemp.forEach(x => {
            if (x.checklist === true)
                newArray.push(x.id);
        });
        if (Object.keys(newArray).length === 0) {
            this.setState({
                selectedInvoiced: []
            })
            alert("Please select the orders to print")
            return
        } else {
            this.setState({
                selectedInvoiced: newArray
            })
        }
    }

    handleInputChange = (cellInfo, event) => {
        let newdata = [...this.state.newdata];
        newdata[cellInfo.index][cellInfo.column.id] = event.target.value;

        this.setState({ newdata });
    };

    getRowProps = (rowInfo) => {
        let data = {}
        if (rowInfo.qbo_last_error !== undefined && rowInfo.qbo_last_error != null) {
            data = {
                color: 'red'
            }
        }
        return data;
    }

    renderEditable = cellInfo => {
        const cellValue = this.state.newdata[cellInfo.index] !== undefined ? this.state.newdata[cellInfo.index][cellInfo.column.id] : null;
        if (cellValue !== null) {
            return (
                <textarea
                    placeholder="-"
                    name="input"
                    onChange={this.handleInputChange.bind(null, cellInfo)}
                    value={cellValue}
                />
            );
        } else {
            return (<span></span>);
        }
    };

    paginationCall = (data) => {
        const { fromDate, to, use_global_buffer } = this.state;
        return this.props.getReportsCall(1, fromDate, to, use_global_buffer, data.page, data.pageSize, data.sort)
    }

    render() {
        // console.log(this.state.selectAll,"SelectedState")
        console.log(this.state.invoiceDate)
        const columns = [
            {
                Header: "Report Testing",
                headerClassName: 'headerPagination',
                columns: [
                    {
                        Header: "Bill Cycle",
                        accessor: "order_shipping_items",
                        Cell: ({ value }) => (
                            <div>
                                IMMEDIATE (SHIP)
                            </div>
                        ),
                        className: "action-center",
                        sortable: false,
                    },
                    {
                        Header: "Barcode",
                        accessor: "barcode",
                        width: 200,
                        Cell: ({ value, original }) => {
                            return <Link target="_blank" to={'/Admin/' + original.id + '/order-edit#pricing_details'} className="blue_link">{value}</Link>
                        },
                        className: "action-center",
                        sortable: false,
                    },
                    {
                        Header: "Agency",
                        accessor: "agency_name",
                        className: "action-center",
                        sortable: false,
                    },
                    {
                        Header: "Client",
                        accessor: "group_name",
                        className: "action-center",
                        sortable: false,
                    },
                    {
                        Header: "Invoice File Date",
                        accessor: "quickbooks_synced_at",
                        Cell: ({ value }) => (
                            <div>
                                {dateFormatMy(value)}
                            </div>
                        ),
                        className: "action-center",
                        sortable: false,
                    },
                    {
                        Header: "Departure Date",
                        accessor: "departuredate",
                        Cell: ({ value }) => (
                            <div>
                                {dateFormatMy(value)}
                            </div>
                        ),
                        className: "action-center",
                        sortable: false,
                    },
                    {
                        Header: "Updated",
                        accessor: "modified_at",
                        Cell: ({ value }) => (
                            <div>
                                {dateFormatMy(value)}
                            </div>
                        ),
                        className: "action-center",
                        sortable: false,
                    },
                    {
                        Header: "Total",
                        accessor: "grand_total",
                        className: "action-center",
                        sortable: false,
                    },
                    {
                        Header: "View",
                        accessor: "id",
                        Cell: ({ value, original }) => {
                            return (
                                <>
                                    <Link target="_blank" to={'/Admin/' + original.id + '/order-edit#pricing_details'} className="blue_link">View</Link>
                                    {
                                    original.qbo_last_error && original.qbo_last_error.description &&
                                    <OverlayTrigger placement="right" overlay={<Tooltip id="tooltip">{original.qbo_last_error.description}</Tooltip>}>
                                        <span className="question_mark">?</span>
                                    </OverlayTrigger>
                                    }
                                </>
                            )
                        },
                        className: "action-center",
                        sortable: false,
                    },
                    {
                        Header: "Comments",
                        accessor: "adminComment",
                        Cell: this.renderEditable,
                        sortable: false,
                        width: 250
                    },
                    {
                        accessor: "checklist",
                        Cell: ({ original, row }) => {
                            return (
                                <input
                                    style={{ width: "30", height: "30"}}
                                    type="checkbox"
                                    className="checkbox"
                                    onChange={() => this.toggleRow(row._index)}
                                    checked={original.checklist}
                                />
                            );
                        },
                        Header: x => {
                            return (
                                <input
                                    style={{ width: "30", height: "30"}}
                                    type="checkbox"
                                    className="checkbox"
                                    checked={this.state.selectAll === 1}
                                    ref={input => {
                                        if (input) {
                                            input.indeterminate = this.state.selectAll === 2;
                                        }
                                    }}
                                    onChange={() => this.toggleSelectAll()}
                                />
                            );
                        },
                        sortable: false,
                        width: 45
                    },
                ]
            }
        ];
        return (
            <div className="content">


                <Grid fluid>
                    <Row>
                        <Col md={12}>
                            <Card
                                ctTableResponsive
                                content={
                                    <div>
                                        <Grid fluid>
                                            <div className="row"  >
                                                <Col md={12} >
                                                    <div className="secHeading spaceBetween">
                                                        Orders To Invoice -
                                                        {/* Global Buffer (30) */}
                                                        <Link to='/Admin/invoiceSettings' className='backButton my-20 ml-md pt-sm no_radius pb-sm primary text-xs btn btn-lg btn-info btn btn-lg btn-info  btn btn-default'>Global Buffer ({this.props.invoice_period_global.value})</Link>
                                                        {/* <div> */}
                                                            <label style={{ marginLeft: "20px", fontSize: '20px', paddingLeft: '35px'}} className="container-check">Use Global Buffer For All Agencies
                                                                <input
                                                                    type="checkbox"
                                                                    className=""
                                                                    checked={this.state.use_global_buffer }
                                                                    onChange={this.useGlobalBufferCheck}
                                                                />
                                                                <span className="checkmark"></span>
                                                            </label>
                                                        {/* </div> */}
                                                    </div>
                                                </Col>

                                                <Col md={6} style={{lineHeight:"38px"}}>
                                                <div><strong> Original Invoices: </strong>({this.props.total_invoices}):
                                                    ${this.props.total_amount.toFixed(2)}
                                                        <strong> Orders Listed: </strong> {this.props.total_orders}
                                                        <strong> Orders Selected: </strong> {this.state.checked}
                                                        </div>
                                                    <div>
                                                        <span style={{color: "red", fontWeight:"bold"}}>Red</span> color shows Quickbooks error
                                                    </div>

                                                    <div >
                                                        <Link to='/Admin/recentlyInvoicedOrders' className='backButton my-20 pt-sm no_radius pb-sm primary text-xs btn btn-lg btn-info btn btn-lg btn-info  btn btn-default'>Recently Processed Invoices List</Link>
                                                    </div>
                                                </Col>
                                                <Col md={6}  >

                                                    <div className='row flexElem flexResponsive align-items-center' >
                                                        <div className="col-md-4" >
                                                        {
                                                            this.dateElement('Invoice Date', 'invoiceDate', this.state.invoiceDate !== '' && moment(this.state.invoiceDate, 'YYYY-MM-DD'), [{ before_or_equal: moment(this.state.invoiceDate) }])
                                                        }
                                                        </div>


                                                            <div className="col-md-4" >
                                                                <ReactToPrint
                                                                    trigger={
                                                                        () =>
                                                                            <OverlayTrigger placement="bottom" trigger={['hover']} overlay={<Popover id="tooltip">Print Invoices</Popover>}>
                                                                                <Button type='button' onClick={this.selectedInvoiced} className="backButton pt-sm no_radius btn-block pb-sm primary mt-none btn btn btn-info "  >Print Invoices</Button>
                                                                            </OverlayTrigger>
                                                                    }
                                                                    content={() => this.componentRef}
                                                                    // content={() => this.state.selectedInvoiced}
                                                                />
                                                                {/* {
                                                                    this.state.invoicePrinted && */}
                                                                    <div style={{ display: "none" }}><PrintInvoices data = { this.state.selectedInvoiced} ref={el => (this.componentRef = el)} /></div>
                                                                {/* } */}

                                                            </div>
                                                        <div className="col-md-4" >
                                                            <div >
                                                                <OverlayTrigger placement="bottom" trigger={['hover']} overlay={<Popover id="tooltip">Send Orders to Invoices</Popover>}>
                                                                    <Button type='button' className="backButton pt-sm no_radius btn-block pb-sm primary mt-none btn btn btn-info " onClick={this.sendOrdersTOInvoice} ><i className="fa fa-plus" />Send Invoices</Button>
                                                                </OverlayTrigger>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </Col>
                                                {/* <Col md={12} className="mt-md">
                                                    <ReactTable
                                                        noDataText='No order found'
                                                        data={newdata}
                                                        showPagination={false}
                                                        pages={REPORTSpages}
                                                        columns={columns}
                                                        defaultPageSize={10}
                                                        loading={loadingOrder}
                                                        className="-striped listing"
                                                        loadingText={'Loading...'}
                                                        pageData={this.reportsPaginationData}
                                                        manual
                                                        onFetchData={(state, instance) => {

                                                            var sort = state.sorted.length === 0 ? '' : state.sorted[0].id + ',desc:' + state.sorted[0].desc;
                                                            state.pageData(state.page + 1, sort, state.pageSize);
                                                        }}
                                                    />
                                                </Col> */}
                                                <Pagination
                                                    ref={(p) => this.pagination = p}
                                                    showPagination={false}
                                                    columns={columns}
                                                    noDataText='No order found'
                                                    pageSize={10}
                                                    page={1}
                                                    getDataCall={this.paginationCall}
                                                    filterView={false}
                                                    // downloadData={true}
                                                    // downloadFileName={'Orders'}
                                                    // lowerContent = {null}
                                                    // filterPlaceHolder = {'Orders'}
                                                    getRowProps={this.getRowProps}
                                                // showAllToggle={true}
                                                />
                                            </div>
                                        </Grid>
                                    </div>
                                } />
                        </Col>
                    </Row>
                </Grid>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    // console.log(invoiceDate,"invooic date send in dispatch")
    return ({
        getReportsCall: (value, fromDate, toDate, use_global_buffer, page, pageSize, sorted) => { return dispatch(getInvoicingOrders(value, fromDate, toDate, use_global_buffer, page, pageSize, sorted)) },//example function
        submitInvoicingOrdersCall: (ordersData, invoiceDate) => { return dispatch(submitInvoicingOrders(ordersData, invoiceDate)) },
        getgetPrintInvoicesCall: (data) => { return dispatch(getPrintInvoices(data)) },

    });
};

function mapStateToProps(state) {
    const { reportsData, total_orders, invoice_period_global, total_invoices, total_amount, REPORTSpages, REPORTScurrent_page, REPORTStotal_orders, loadingOrder, printData } = state.invoicingReducer;
    return {
        reportsData, total_orders, invoice_period_global, total_invoices, total_amount, REPORTSpages, REPORTScurrent_page, REPORTStotal_orders, loadingOrder, printData
    };
}


const InvoicingOrders = connect(mapStateToProps, mapDispatchToProps)(InvoicingOrdersPage);
export default InvoicingOrders;
