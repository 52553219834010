import { itemService } from "services/item";
import { adminConstants } from "redux/constant/admin-constant";
import { alertActions } from './alert-actions';

export function getItems(type) {

    return dispatch => {
        dispatch(request());
        itemService.getItems(type).then(
            response => {
                dispatch(success(response))
            },
            error => {
                dispatch(failure(error))
            }
        )
    }

    function request() { return { type: adminConstants.GET_ITEMS_REQUEST } }
    function success(response) { return { type: adminConstants.GET_ITEMS_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.GET_ITEMS_FAILURE, payload: error } }
}

export function getItemsByCategory(type) {

    return dispatch => {
        dispatch(request());
        itemService.getItemsByCategory(type).then(
            response => {
                dispatch(success(response))
            },
            error => {
                dispatch(failure(error))
            }
        )
    }

    function request() { return { type: adminConstants.GET_ITEMS_REQUEST } }
    function success(response) { return { type: adminConstants.GET_ITEMS_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.GET_ITEMS_FAILURE, payload: error } }
}

export function createOrderAssignedItemsWithType(data, type, shippingItemId) {
    return dispatch => {
        dispatch(request());
        return itemService.createOrderAssignedItemsWithType(data, type, shippingItemId).then(
            response => {
                dispatch(success(response))
                dispatch(alertActions.success(response.message))
                return response;
            },
            error => {
                dispatch(failure(error));
                dispatch(alertActions.error(error));
                return Promise.reject(error);
            }
        )
    }

    function request() { return { type: adminConstants.STORE_ORDER_ASSIGNED_ITEMS_REQUEST } }
    function success(response) { return { type: adminConstants.STORE_ORDER_ASSIGNED_ITEMS_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.STORE_ORDER_ASSIGNED_ITEMS_FAILURE, payload: error } }
}

export function getItemPagination(page, filter, sort, pageSize) {
    return dispatch => {
        dispatch(request())
        return itemService.getItemPagination(page, filter, sort, pageSize)
            .then(
                response => {
                    dispatch(success(response));
                    return response;
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                    return Promise.reject(error);
                }
            );
    };

    function request() { return { type: adminConstants.GET_ITEM_LIST_REQUEST } }
    function success(response) { return { type: adminConstants.GET_ITEM_LIST_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.GET_ITEM_LIST_FAILURE, payload: error } }
}

export function storeItem(ItemData) {
    return dispatch => {
        dispatch(request());
        return itemService.storeItem(ItemData)
            .then(
                response => {
                    dispatch(success(response));
                    dispatch(alertActions.success(response.message));
                    return response;
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                    return Promise.reject(error);
                }
            );
    }

    function request() { return { type: adminConstants.STORE_ITEM_REQUEST } }
    function success(response) { return { type: adminConstants.STORE_ITEM_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.STORE_ITEM_FAILURE, payload: error } }
}

export function editItem(invId) {
    return dispatch => {
        dispatch(request());
        itemService.editItem(invId)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    }

    function request() { return { type: adminConstants.GET_ITEM_REQUEST } }
    function success(response) { return { type: adminConstants.GET_ITEM_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.GET_ITEM_FAILURE, payload: error } }
}

export function updateItem(data, id) {
    return dispatch => {
        dispatch(request());
        return itemService.updateItem(data, id)
            .then(
                response => {
                    dispatch(success(response));
                    dispatch(alertActions.success(response.message));
                    return response;
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                    return Promise.reject(error);
                }
            );
    }

    function request() { return { type: adminConstants.UPDATE_ITEM_REQUEST } }
    function success(response) { return { type: adminConstants.UPDATE_ITEM_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.UPDATE_ITEM_FAILURE, payload: error } }
}

export function deleteItem(ItemId) {
    return dispatch => {
        dispatch(request());
        return itemService.deleteItem(ItemId)
            .then(
                response => {
                    dispatch(success(response));
                    dispatch(alertActions.success(response.message));
                    return response;
                },
                error => {
                    dispatch(failure(error));
                    return dispatch(alertActions.error(error));
                }
            );
    }

    function request() { return { type: adminConstants.DELETE_ITEM_REQUEST } }
    function success(response) { return { type: adminConstants.DELETE_ITEM_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.DELETE_ITEM_FAILURE, payload: error } }
}

export function getItemsByTypeByLoction(type, location) {
    return dispatch => {
        return itemService.getItemsByTypeByLoction(type, location)
            .then(
                response => {
                    return response;
                },
                error => {
                    return Promise.reject(error);
                }
            );
    }
}

export function unassignOrderShippingItem(ordershippingItemId, type) {
    return dispatch => {
        dispatch(request());
        return itemService.unassignOrderShippingItem(ordershippingItemId, type).then(
            response => {
                dispatch(success(response))
                dispatch(alertActions.success(response.message))
                // return response;
            },
            error => {
                dispatch(failure(error))
            }
        )
    }

    function request() { return { type: adminConstants.STORE_ORDER_ASSIGNED_ITEMS_REQUEST } }
    function success(response) { return { type: adminConstants.STORE_ORDER_ASSIGNED_ITEMS_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.STORE_ORDER_ASSIGNED_ITEMS_FAILURE, payload: error } }
}

export function getReorderEntryItems() {
    return dispatch => {
        return itemService.getReorderEntryItems();
    }
}