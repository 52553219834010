import { SC } from "helper/ServerCall";

const fetchOrderByBarCode = data => {
    return SC.postCall("endicia/getOrderInfo", data);
};

const fetchShippingRates = data => {
    return SC.postCall("endicia/ViewAllShippingRates", data);
    // return SC.postCall("endicia/ViewShippingRates", data);
};

const createLabelPreview = data => {
    return SC.postCall("endicia/sera/createLabelPreview", data);
    // return SC.postCall("endicia/createLabelPreview", data);
};

const createLabel = data => {
    return SC.postCall("endicia/sera/createLabel", data);
    // return SC.postCall("endicia/createLabel", data);
};

const fetchFromAddresses = () => {
    return SC.getCall("endicia/getShipFromAddresses");
};

const fetchReprintLabel = (labelId) => {
    return SC.getCall(`endicia/sera/reprintLabel/${labelId}`);
};

const validateAddresses = (data) => {
    return SC.postCall("endicia/sera/addressValidate", data);
};

const refundLabel = (data) => {
    return SC.postCall("endicia/sera/voidLabel", data);
};

function updateDetailsFromShipping(orderId, Data) {
    return SC.putCall("updateDetailsFromShipping/" + orderId + "/update", Data);
}

export default {
    fetchOrderByBarCode,
    fetchShippingRates,
    createLabelPreview,
    createLabel,
    fetchFromAddresses,
    validateAddresses,
    refundLabel,
    fetchReprintLabel,
    updateDetailsFromShipping
};
