import React, { Component } from "react";
import {BsExclamationTriangle} from 'react-icons/bs';

class ConfirmAlertForShipping extends Component {
	render(){
		console.log("okBtn", this.props.okBtn);
		return(
			<div className='custom-ui'>
	            <div className="react-confirm-alert-body react-confirm-alert-body-ship text-center">
					<div className="row">
						<BsExclamationTriangle style={{"fontSize":"33px", "color":"orange"}}/>
					</div>
					<p className="marginFromTopPopUp" >{  this.props.description }</p>
				<div className="row">
					<div className="col-md-6">
						<h4 className="font-weight-bold">Current Address</h4>
						{/* <p className="font14">{this.props.currentAddress.street_address_1 + '\n' + this.props.currentAddress.city + ' ' + this.props.currentAddress.state.code + ' ' +this.props.currentAddress.zipcode}</p> */}
						<p>
							{this.props.currentAddress.street_address_1}
							<br />
							{this.props.currentAddress.city} {this.props.currentAddress.state.code} {this.props.currentAddress.zipcode}
						</p>
						
					</div>
					<div className="col-md-6">
						<h4 className="font-weight-bold">New Address</h4>
						{/* <p className="font14">{this.props.changeableAddress.street_address_1 + '\n' + this.props.changeableAddress.city + ' ' + this.props.changeableAddress.state.code + ' ' +this.props.changeableAddress.zipcode}</p> */}
						<p>
							{this.props.changeableAddress.street_address_1}
							<br />
							{this.props.changeableAddress.city} {this.props.changeableAddress.state.code} {this.props.changeableAddress.zipcode}
						</p>
						
					</div>
				</div>
                    {
						this.props.okBtn ?
						<div className="react-confirm-alert-button-group alignFlexButtons">
							<button onClick={this.props.onClosePro} className="btn btn-danger flexElem alignCenter flexGap5">
								<i className="pe-7s-close-circle"></i> <span>Close</span></button>
	              		</div> 
					:
						<div className="react-confirm-alert-button-group alignFlexButtons">
							<button onClick={this.props.onClosePro} className="btn btn-danger flexElem alignCenter flexGap5">
								<i className="pe-7s-close-circle"></i>
								<span>No</span>
							</button>
							<button onClick={() => {
									this.props.onYesPro()
									this.props.onClosePro()
								}} className='btn btn-success flexElem alignCenter flexGap5'>
								<i className="pe-7s-check"></i>
								<span>Yes</span>
							</button>
						</div>
					}       
	            </div>
	        </div>
		);
	};
}



export default ConfirmAlertForShipping;
