import { adminConstants } from "../constant/admin-constant";

const initialState = {
    shippingMethods: []
}

export function ShippingReducer(state = initialState, action) {
    switch (action.type) {
        case adminConstants.GET_ALL_SHIPPING_METHODS_SUCCESS:
            return {
                ...state,
                // shippingMethods: action.payload.data
                shippingMethods: action.payload && action.payload.data ? action.payload.data : initialState.shippingMethods
            }
        case adminConstants.GET_ALL_SHIPPING_METHODS_FAILURE:
            return {
                ...state,
                error: action.payload
            }
        default:
            return state;
    }
}