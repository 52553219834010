import { SC } from "../helper/ServerCall";

export const accountService = {
    getBrands,
    getBrandingInfoById,
    getBrandingInfoBySlug,
    getBrandsCamerasToShip,
}

function getBrands() {
    return SC.getCall(`getBrands`);
}


function getBrandsCamerasToShip() {
    return SC.getCall(`getBrandsCameraToShip`);
}

function getBrandingInfoById(id) {
    return SC.getCall(`getBrandingInfo/${id}`);
}

function getBrandingInfoBySlug(slug) {
    return SC.getCall(`getBrandingInfoBySlug/${slug}`);
}