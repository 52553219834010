import { agencyTypeService } from "../../services/agency-type";
import { adminConstants } from "../constant/admin-constant";
import { alertActions } from '../actions/alert-actions'

export function getAgencyTypePagination(page, filter, sort, pageSize) {
    return dispatch => {
        return agencyTypeService.getAgencyTypePagination(page, filter, sort, pageSize).then(
            response => {
                dispatch(success(response))
                return response;
            },
            error => {
                return error
            }
        )
    }
    function success(response) { return { type: adminConstants.GET_AGENCY_TYPES_PAGINATION_SUCCESS, payload: response } }
    // function failure(error) { return { type: adminConstants.GET_AGENCY_TYPES_PAGINATION_FAILURE, payload: error } }
}

export function getAgencyType(id) {
    return dispatch => {
        agencyTypeService.getAgencyType(id).then(
            response => {
                dispatch(success(response));
            },
            error => {
                dispatch(failure(error))
            }
        )
    }
    function success(response) { return { type: adminConstants.GET_AGENCY_TYPES_SINGLE_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.GET_AGENCY_TYPES_SINGLE_FAILURE, payload: error } }
}

export function storeAgencyType(data) {
    return dispatch => {
        return agencyTypeService.storeAgencyType(data).then(
            response => {
                dispatch(getAgencyTypePagination(1, '', '', 10))
                dispatch(alertActions.success(response.message))
                return response
            },
            error => {
                dispatch(failure(error))
                dispatch(alertActions.error(error))
                return Promise.reject(error)
            }
        )
    }
    // function success(response) { return { type: adminConstants.STORE_AGENCY_TYPES_SINGLE_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.STORE_AGENCY_TYPES_SINGLE_FAILURE, payload: error } }
}

export function updateAgencyType(agencyType, data) {
    return dispatch => {
        return agencyTypeService.updateAgencyType(agencyType.id, data).then(
            response => {
                dispatch(success(data, agencyType.index))
                dispatch(alertActions.success(response.message))
                return response
            },
            error => {
                dispatch(failure(error))
                dispatch(alertActions.error(error))
                return Promise.reject(error)
            }
        )
    }
    function success(data, index) { return { type: adminConstants.UPDATE_AGENCY_TYPES_SINGLE_SUCCESS, payload: { data: data, index: index } } }
    function failure(error) { return { type: adminConstants.UPDATE_AGENCY_TYPES_SINGLE_FAILURE, payload: error } }
}

export function deleteAgencyType(id) {
    return dispatch => {
        return agencyTypeService.deleteAgencyType(id).then(
            response => {
                dispatch(alertActions.success(response.message))
            },
            error => {
                dispatch(failure(error))
                dispatch(alertActions.error(error))
            }
        )
    }
    // function success(data) { return { type: adminConstants.DELETE_AGENCY_TYPES_SINGLE_SUCCESS, payload: data } }
    function failure(error) { return { type: adminConstants.DELETE_AGENCY_TYPES_SINGLE_FAILURE, payload: error } }
}