import React from 'react';
import OrderForm from './OrderForm';
import { connect } from 'react-redux';
import {
  Grid,
  Row,
  Col
} from "react-bootstrap";
import { Card } from "components/Card/Card.jsx";

class AddOrderPage extends React.Component {
	constructor(props){
		super(props);
		this.state = {
			agency : {},
			formType:'add',
			selected:{}
		};
	};
	render(){
		return(
			<div className="content">
	        	<Grid fluid>
	          		<Row>
	            		<Col md={12}>
	              			<Card
				                content={
		                            <Grid fluid>
	                                	<Col md={12}>
	                                		<OrderForm handleActionParent={this.handleAction} agencyData = {this.state.agency} formType={this.state.formType} agencySelectedOptions={this.state.selected}  />
	                                	</Col>
		                            </Grid>
				                }
				            />
	             		</Col>
	            	</Row>
	          	</Grid>
	        </div>
		);
	};
}

function mapStateToProps(state) {
    const { loadingAgency } = state.agenciesReducer;
    
    return {
        loadingAgency
    };
}

const AddOrder = connect(mapStateToProps)(AddOrderPage);
export default AddOrder;