import React, {Component} from "react";
import {CSVLink} from "react-csv";
import _get from 'lodash.get'
import {returnDynamicURL} from "../../helper/helperFunctions";
var { isEqual } = require('lodash');

class reactDataToCSV extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataToDownload: []
        }
        this.download = this.download.bind(this);
    }

    download(event) {
        //OLD CODE
        const {currentRecordsRef, columnsData} = this.props;
        let currentRecordsData = currentRecordsRef.resolvedData;

        //NEW CODE
        const DataToDownload = [];
        const columnsToDownload = {};

        columnsData.forEach(column => {
            if (column.excExp === true) {
                let accessor = column.accessor;
                const splitAccessor = accessor.split('.');
                const accessorLength = splitAccessor.length;
                if (accessorLength > 2 && !(column['Header'] === 'Pre Trip Material Address State' || column['Header'] === 'Video Shipping Address State' || column['Header'] === 'Agency Primary State')) {
                    console.log(" line 31", column['Header']);
                    if (splitAccessor[1] in columnsToDownload) {
                        console.log(" line 33", column['Header']);
                        columnsToDownload[splitAccessor[1]].push(column);
                    } else {
                        console.log(" line 36", column['Header']);
                        columnsToDownload[splitAccessor[1]] = [column]
                    }
                } else if (accessorLength > 1) {
                    console.log(" line 40", column['Header']);
                    if (splitAccessor[0] in columnsToDownload) {
                        console.log(" line 43", column['Header']);
                        columnsToDownload[splitAccessor[0]].push(column);
                    } else {
                        console.log(" line 45", column['Header']);
                        columnsToDownload[splitAccessor[0]] = [column]
                    }
                } else {
                    console.log(" line 49", column['Header'], accessorLength);
                    columnsToDownload[splitAccessor[0]] = column;
                }
            }
        });
        let i = 0;
        let record = currentRecordsData[i];
        while (record !== undefined) {
            let currentRecord = {}
            Object.keys(columnsToDownload).forEach(column => {
                if (columnsToDownload[column] instanceof Array) {
                    columnsToDownload[column].forEach(nestedItem => {
                        this.makeColumn(nestedItem, currentRecord, record)
                    })
                } else {
                    this.makeColumn(columnsToDownload[column], currentRecord, record)
                }
            })
            DataToDownload.push(currentRecord);
            i += 1;
            record = currentRecordsData[i]
        }
        this.setState({dataToDownload: DataToDownload}, () => {
            this.csvLink.link.click()
        })
    }

    makeColumn(item, currentRecord, record) {
        if (item.csvMod) {
            if (item instanceof Array) {
                let arr = item;
                let output = '';
                arr.forEach(item => {
                    if (item.csv_value)
                        output += item.csv_value + '\n';
                })
                currentRecord[item.Header] = output;
            } else {
                if (item.Header === 'Traveler Quick Login') {
                    let match, result;
                    match = document.domain.match(/^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\/\n\?\=]+)/im);
                    if (match) {
                        result = match[1]
                        let domainSlugs = ['gtv', 'brt', 'mci', 'eti', 'itk', 'act', 'ttk', 'tech', 'aom', 'gm'];
                        if (domainSlugs.includes(result.split('.')[0])) {
                            result = result.split('.').slice(1).join('.');
                        }
                    }
                    let link = returnDynamicURL(record.accounts[0].domain_slug, record.username, record.password)
                    currentRecord[item.Header] = link
                } else {
                    currentRecord[item.Header] = item.csv_value;
                }
            }
        } else {
            // console.log("record", record);
            let value = _get(record, item.accessor);
            if (item.accessor === 'pre_trip_material_address.city' || item.accessor === 'video_shipping_address.city') {
                // agency csv_value
                let agency_csv_value = this.returnAddressObjectForComparison(record.agency.primary_address);

                // order video address csv_value
                let order_video_csv_value = this.returnAddressObjectForComparison(record.video_shipping_address);

                // order ptm address csv_value
                let order_ptm_csv_value = this.returnAddressObjectForComparison(record.pre_trip_material_address);

                if (item.accessor === 'pre_trip_material_address.city') {
                    if (isEqual(agency_csv_value, order_ptm_csv_value)) {
                        value = "***" + " " + value
                    }
                }
                if (item.accessor === 'video_shipping_address.city') {
                    if (isEqual(agency_csv_value, order_video_csv_value)) {
                        value = "***" + " " + value
                    }
                }
            }
            // if (item.accessor.match(/[\w\W]+\.[\w\W]+\.[\w\W]+/)) {
            //     let newAcc = item.accessor.split('.');
            //     if (record[newAcc[0]][newAcc[1]].value === '0') {
            //         value = ''
            //     }
            // } else {
            //     if (item.accessor === 'barcode') {
            //         // value = `=""${value}""`
            //         value = `=HYPERLINK(""https://www.grouptravelvideos.com/Admin/${record.id}/order-edit"",""${value}"")`
            //     }
            //     if (item.accessor === 'orderShippingCameraShippedDate') {
            //         value = record.orderShippingCameraFlag ? "**" + value + "**" : value
            //     }
            // }
            if (item.Header === "Agency All User Email")
            {
                let text = "Click here";
                value = `=HYPERLINK(""https://www.grouptravelvideos.com/Admin/${record.id}/agency-users"",""${text}"")`
            }
            if (item.Header === "Agency All User Name")
            {
                let text = "Click here";
                value = `=HYPERLINK(""https://www.grouptravelvideos.com/Admin/${record.id}/agency-users"",""${text}"")`
            }
            if (item.accessor === 'barcode') {
                // value = `=""${value}""`
                value = `=HYPERLINK(""https://www.grouptravelvideos.com/Admin/${record.id}/order-edit"",""${value}"")`
            }
            if (item.accessor === 'orderShippingCameraShippedDate') {
                value = record.orderShippingCameraFlag ? "**" + value + "**" : value
            }
            currentRecord[item.Header] = value;
        }
    }
    returnAddressObjectForComparison = (obj) => {
		const newObj = Object.assign({},
			{
				street_address_2: obj.street_address_2,
				city: obj.city,
				state_id: obj.state_id,
				country_id: obj.country_id,
				zipcode: obj.zipcode,
				country: obj.country,
				state: obj.state,
                street_address_1: obj.street_address_1,
			})
		return newObj;
	}

    render() {
        const {fileName} = this.props;
        const {dataToDownload} = this.state;
        return (
            <React.Fragment>
                <button onClick={this.download}>
                    Download
                </button>
                <CSVLink
                    data={dataToDownload}
                    filename={fileName + ".csv"}
                    className="hidden"
                    ref={(r) => this.csvLink = r}
                    target="_blank"
                />
            </React.Fragment>
        );
    }
}

export default reactDataToCSV;
