import React from 'react';
import { connect } from 'react-redux';
import { Card } from "components/Card/Card.jsx";
import { createOrderAssignedItemsWithType, getItemsByTypeByLoction, unassignOrderShippingItem } from "redux/actions/item-actions";
import { focusInput, returnColumnValueWithColumnMatch } from 'helper/helperFunctions';
import SimpleReactValidator from 'simple-react-validator';
import { Link } from 'react-router-dom';
import { getOrder, getOrderLatestComment } from 'redux/actions/order-actions';
var cloneDeep = require('lodash.clonedeep');

class AssignedItemsGridPage extends React.Component {
	constructor(props) {
		super(props);
		this.validatorAssignedItems = new SimpleReactValidator({
			messages: {
				required: 'The :attribute is required',
				min: 'The quantity must be at least 1.',
			},
		});

		this.state = {
			items: [],
			assignedItemsAdd: [{ quantity: 1, barcode: '' }],
			itemType: 'Physical Products',
			comments: [],
		}

		// this.props.getItemsCall(this.state.itemType);
		// this.props.getItemsByTypeByLoctionCall(this.state.itemType, 'warehouse').then(response=>{
		// 	this.setState({items : appendValues( appendCount( response.items), [{column:'disable', value:false}] )});
		// });


	};

	componentDidMount() {
		this.props.getOrderLatestComment({ orderId: this.props.order_details.id, categories: ['Admin', 'PreTripMaterialsShip', 'TO_GL'] }).then(response => {
			this.setState({ comments: response.data }, () => {
				console.log('comments', this.state.comments);
			});
		});
	}
	addRow = () => {
		this.setState({ assignedItemsAdd: [...this.state.assignedItemsAdd, { quantity: 1, barcode: '' }] });
	}
	deleteRow = (key, barcode) => {
		const assignedItemsAddState = cloneDeep(this.state.assignedItemsAdd);
		assignedItemsAddState.splice(key, 1);
		this.setState({ assignedItemsAdd: assignedItemsAddState }, () => {
			if (barcode) {
				// var indexItem = returnKeyWithColumnMatch('value', this.state.items, barcode);
				const itemsState = cloneDeep(this.state.items);
				// itemsState[indexItem]['disable'] = false;
				this.setState({ items: itemsState })
			}
		});
	}
	handleChangeItem = key => (selectedOptionType, info) => {
		if (info['action'] === 'select-option') {
			const assignedItemsAddState = cloneDeep(this.state.assignedItemsAdd);
			// var lengthOfAssignedItems = this.state.assignedItemsAdd.length;
			assignedItemsAddState[key][info['name']] = selectedOptionType['value'];
			this.setState({ assignedItemsAdd: assignedItemsAddState }, function () {
				if (info['name'] === 'barcode') {
					const itemsState = cloneDeep(this.state.items);
					itemsState[selectedOptionType['count']]['disable'] = true;
					this.setState({ items: itemsState });
				}
			});
		}
	}
	handleChange = key => (e) => {
		const { name, value } = e.target;
		const assignedItemsAddState = cloneDeep(this.state.assignedItemsAdd);
		assignedItemsAddState[key][name] = value;
		this.setState({ assignedItemsAdd: assignedItemsAddState });
	}
	submitForm = (e) => {
		e.preventDefault();
		if (this.validatorAssignedItems.allValid()) {
			const data = { assignedBarcode: this.state.assignedItemsAdd };
			this.props.createOrderAssignedItemsWithTypeCall(data, this.state.itemType, this.props.camera_ship_status.id).then(
				response => {
					this.props.getOrderCall(this.props.order_details.id).then(response => {
						this.setState({ assignedItemsAdd: [{ quantity: 1, barcode: '' }] });
					})
					// this.props.onHide()
				}
			);
		} else {
			focusInput(this.validatorAssignedItems.getErrorMessages());
			this.validatorAssignedItems.showMessages();
			this.forceUpdate();
		}
	}
	componentDidUpdate(prevProps, prevState) {
		// if ( this.state.items !== prevState.items && this.state.assignedItemsAdd.length === 0 && prevState.items.length === 0 ) {
		// 	if ( this.props.assignedItems ) {
		// 		// const uniqueAssignedItemCatId =   remove_duplicates(this.props.assignedItems.map(x => x.item.item_category_id ));
		// 		var tempAssignedItems = [];
		// 		// if ( !uniqueAssignedItemCatId.includes(this.props.order_details.program_type.item_category.id) ) {

		// 		// 	var availableIdProgType = returnColumnValueWithColumnMatch(this.state.items, 'id', [ this.props.order_details.program_type.item_category.id ] , 'item_category_id')
		// 		// 	if (availableIdProgType){
		// 		// 		tempAssignedItems.push({ quantity:  this.props.order_details.number_of_cameras, barcode: availableIdProgType});
		// 		// 	}
		// 		// }
		// 		// if ( parseInt(this.props.pricing_details.settings.free_fash_drive_to_client.value,10) === 1) {
		// 		// 	var usbFlashDriveCatId = returnColumnValueWithColumnMatch(this.state.items, 'item_category_id', [ 'USB Flash Drive' ] , 'itemCategoryTitle');
		// 		// 	if ( !uniqueAssignedItemCatId.includes(usbFlashDriveCatId) ) {
		// 		// 		var availableIdUsb = returnColumnValueWithColumnMatch(this.state.items, 'id', [ 'USB Flash Drive' ] , 'itemCategoryTitle');
		// 		// 		if (availableIdUsb){
		// 		// 			tempAssignedItems.push({ quantity:  1, barcode: availableIdUsb});
		// 		// 		}
		// 		// 	}
		// 		// }
		// 		if ( tempAssignedItems.length === 0 ) {
		// 			tempAssignedItems.push({ quantity:  1, barcode: null});
		// 		}
		// 		this.setState({assignedItemsAdd:tempAssignedItems})
		// 	}
		// }
	}

	inventoryOptionDisable = (option) => {
		const { assignedItemsAdd } = this.state;
		return returnColumnValueWithColumnMatch(assignedItemsAdd, 'inventory_id', [option.id], 'inventory_id') === option.id ? true : false;
	}


	itemOptionDisable = (option) => {
		const { assignedItemsAdd } = this.state;
		return returnColumnValueWithColumnMatch(assignedItemsAdd, 'barcode', [option.id], 'barcode') === option.id ? true : false;
	}
	unassignOrderShippingItem = (orderShippingId) => {
		this.props.unassignOrderShippingItemCall(orderShippingId, this.state.itemType).then(response => {
			this.props.getOrderCall(this.props.order_details.id).then(response => {
				this.setState({ assignedItemsAdd: [{ quantity: 1, barcode: '' }] });
			})
		});
	}
	render() {
		this.validatorAssignedItems.purgeFields();
		const { /*items,*/ assignedItemsAdd, comments } = this.state;
		const { /* loadingItem, shipping_details ,*/ order_details, assignedItems, pricing_details } = this.props;
		console.log('assignedItems', assignedItems);
		return (
			<div>
				<div className={!this.props.hasCard ? 'clearfix' : 'row customHalf lessSpace'}>
					<Card bsClass={!this.props.hasCard ? ['notCard'] : ['innerCard mb-none']} content={
						<React.Fragment>
							{
								<div>
									<div className="row">
										<span></span>
										<div className={"col-md-7"}>
											<table className="table table-responsive table-striped">
												<tbody>
													<tr>
														<td><span>Program Type</span></td>
														<td>{`${order_details.program_type.title} @ ${order_details.program_type.price}`}</td>
													</tr>
													<tr>
														<td colSpan="2">
															<span  >{`This order requires ${pricing_details.number_of_cameras + pricing_details.number_of_flashdrive + pricing_details.number_of_sdcard} assets (${pricing_details.number_of_cameras} Cams/Cards + ${pricing_details.number_of_flashdrive} Flash Drives + ${pricing_details.number_of_sdcard} Sd Cards)`}</span>
														</td>
													</tr>
													{
														/*
														<tr>
															<td colSpan="2">
																<span style={{fontWeight:'bold'}} >{ `You have already added ${shipping_details.camerasCount.camerasCount} cameras and ${shipping_details.cardsCount.cardsCount} cards.` }</span>
															</td>
														</tr>
														*/
													}

												</tbody>
											</table>

											<div style={{ marginTop: '60px' }} >
												<table className="table table-responsive table-striped">
													<tbody>
														<tr>
															<td><span style={{ 'fontWeight': 'bold', 'fontSize': '18px' }}>Group Name</span> </td>
															<td style={{ 'fontSize': '18px' }}>{`${order_details.group_name}`}</td>
														</tr>
													</tbody>
												</table>
											</div>

											<table className="table table-responsive table-striped mt-md" >
												<thead>
													<tr>
														<th width="40%">Inventory Barcode</th>
														{/*<th width="20%">INVENTORY</th>*/}
														{/*<th width="20%" className="action-center">QUANTITY</th>*/}
														<th width="20%" className="action-center">Delete</th>
													</tr>
												</thead>
												<tbody>
													{
														Object.keys(assignedItemsAdd).length > 0 &&
														Object.keys(assignedItemsAdd).map(key => (
															<tr id={'asset' + key} key={key} >
																<td id={'barcode' + key} className="action-center">
																	<input className="form-control" type="text" name="barcode" onChange={this.handleChange(key)} value={assignedItemsAdd[key].barcode} />
																	{this.validatorAssignedItems.message('barcode' + key, assignedItemsAdd[key].barcode, 'required')}
																</td>
																{
																	/*
																		<td className="action-center" id={'asset-'+ (parseInt(key,10)+1) }>
																			<Select
																				value={returnLabelWithValue(assignedItemsAdd[key].barcode , items)}
																				onChange={this.handleChangeItem(key)}
																				options={items}
																				isSearchable={true}
																				isMulti={false}
																				name='barcode'
																				placeholder={'Select Asset'}
																				isOptionDisabled={ (option) => this.itemOptionDisable(option) }
																				// isOptionDisabled={ (option) => option.disable === true }
																				// isDisabled = {assignedItemsAdd[key].barcode?true:false}
																			/>
																			{ this.validatorAssignedItems.message('asset-'+ (parseInt(key,10)+1) , assignedItemsAdd[key].barcode, 'required') }
																		</td>
																	*/
																}
																{/*
																	<td className="action-center" id={'inventory-'+ (parseInt(key,10)+1) }>
																		<Select
																			value={returnLabelWithValue(assignedItemsAdd[key].inventory_id , assignedItemsAdd[key].barcode ? returnColumnValueWithColumnMatch(items, 'inventories', [ assignedItemsAdd[key].barcode ] , 'id') : []  )}
																			onChange={this.handleChangeItem(key)}
																			options={assignedItemsAdd[key].barcode ? returnColumnValueWithColumnMatch(items, 'inventories', [ assignedItemsAdd[key].barcode ] , 'id') : [] }
																			isSearchable={true}
																			isMulti={false}
																			name='inventory_id'
																			placeholder={'Select Inventory'}
																			isOptionDisabled={ (option) => this.inventoryOptionDisable(option) }
																			
																		/>
																		{ this.validatorAssignedItems.message('inventory-'+ (parseInt(key,10)+1), assignedItemsAdd[key].inventory_id, 'required') }
																	</td>
																	*/}
																{

																	/*<td id={'qty'+ key} className="action-center">
																		<input className="form-control" type="number" name="quantity" onChange={this.handleChange(key)} value={assignedItemsAdd[key].quantity} min={1} />
																		{ this.validatorAssignedItems.message('qty'+ key, assignedItemsAdd[key].quantity, 'integer|min:1,num') }
																	</td>
																	*/
																}
																<td className="action-center"><button className="backButton pt-sm no_radius pb-sm primary btn btn-md btn-info ml-sm mt-sm" onClick={() => this.deleteRow(key, assignedItemsAdd[key].barcode)} >Delete</button></td>
															</tr>
														))
													}
												</tbody>
											</table>


											{
												/*items.length > assignedItemsAdd.length && returnKeyWithColumnMatch('disable', items, false) && */
												<button onClick={this.addRow} className="backButton pt-sm no_radius pb-sm primary btn btn-md btn-info ml-sm mt-sm pull-right" >Add More</button>
											}
											{
												assignedItemsAdd.length > 0 &&
												<button type="click" onClick={this.submitForm} className="backButton pt-sm no_radius pb-sm success btn ml-sm mt-sm btn btn-default pull-right" >Submit</button>
											}

											<table className="table orderTable mt-none evenly table-striped">
												<thead>
													<tr>
														<th className="text-center" colSpan="3" ><strong>Items currently assigned to this order</strong></th>

													</tr>
												</thead>
												<tbody>
													{

														Object.keys(assignedItems).map(key => {
															return (
																(assignedItems[key].inventory && assignedItems[key].item) &&
																<tr key={key} >
																	<td className="text-center">{assignedItems[key].inventory.barcode}</td>
																	<td className="text-left">{assignedItems[key].item.title}</td>
																	<td className="text-left" ><button className='customBtn btn ml-sm btn-primary btn btn-default' onClick={() => this.unassignOrderShippingItem(assignedItems[key].id)} >Un Assign</button></td>
																	{/* 
																		<td className="text-center">
																			<ChangeInvLoc 
																				handleChangeLocation={this.handleChangeLocation(assignedItems[key].inventory.id, assignedItems[key].order_shippings_id, "Physical Products")}
																				inventory_location_stats_object={assignedItems[key].inventory.inventory_location_stats}
																			/>
																		</td>
																		 */}
																</tr>
															);
														})
													}
												</tbody>
											</table>
										</div>
										<div className="col-md-5">
											{
												Object.keys(comments).map(key => {
													return (
														<div key={key}>
															<span style={{ fontWeight: 'bold' }} > {`${key} Comments`} </span>
															<table className="table orderTable mt-none evenly table-striped" >
																<tbody>
																	{
																		Object.keys(comments[key]).map(key1 => {
																			return (
																				<tr key={key1} >
																					<td>
																						{comments[key][key1].comment}
																					</td>
																				</tr>
																			)
																		})
																	}
																</tbody>
															</table>
														</div>
													)
												})
											}
											{
												/*
												<Link target='_blank' to={`/Admin/${order_details.id}/order-edit?commentCategoryCode=Admin#commentTabs`} className='blue_link assignAssetsLink'>Admin Comments</Link> |&nbsp;
												<Link target='_blank' to={`/Admin/${order_details.id}/order-edit?commentCategoryCode=PreTripMaterialsShip#commentTabs`} className='blue_link assignAssetsLink'>PreTrip Materials Ship Comments</Link> |&nbsp;
												<Link target='_blank' to={`/Admin/${order_details.id}/order-edit?commentCategoryCode=TO_GL#commentTabs`} className='blue_link assignAssetsLink'>TO/GL Comments</Link> |&nbsp;
												*/
											}

										</div>
										<span></span>
									</div>
								</div>

							}
							<hr />
							<Link target='_blank' to={`/Admin/${order_details.id}/order-edit?commentCategoryCode=ContactLog#commentTabs`} className='blue_link  pull-right' style={{ 'marginRight': '50px' }} >Contact Log</Link>
						</React.Fragment>
					}
					/>
				</div>
			</div>


		);
	};
}

const mapDispatchToProps = (dispatch) => {
	return {
		createOrderAssignedItemsWithTypeCall: (data, type, orderShippingId) => { return dispatch(createOrderAssignedItemsWithType(data, type, orderShippingId)) },
		// getItemsCall: (type) => { dispatch( getItems(type) ) },
		getItemsByTypeByLoctionCall: (type, location) => { return dispatch(getItemsByTypeByLoction(type, location)) },
		unassignOrderShippingItemCall: (orderShippingId, type) => { return dispatch(unassignOrderShippingItem(orderShippingId, type)) },
		getOrderCall: (orderId) => { return dispatch(getOrder(orderId)) },
		getOrderLatestComment: (data) => { return dispatch(getOrderLatestComment(data)) }
	}
}

function mapStateToProps(state) {
	const {
		loadingItem,
		// items 
	} = state.itemReducer;

	const { order_details, pricing_details } = state.ordersReducer;
	return {
		loadingItem,
		order_details,
		pricing_details
		// items
	};
}

const AssignedItemsGrid = connect(mapStateToProps, mapDispatchToProps)(AssignedItemsGridPage);
export default AssignedItemsGrid;