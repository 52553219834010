import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Grid, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Card from 'components/Card/Card'
import DatePicker from "react-datepicker";
import { getOrder, updatePricingDetail, updateOrderDetail, updateOrderSettings, updateOrderDate, updateShippingDetails, holdOrderAddComment, toggleInventoryStatus, getOrderResources } from 'redux/actions/order-actions';
import { clean, inputChangeHandler, dateChangeHandler, toggleModal, selectChangeHandler, selectArrayChangeHandler, compareDates, returnColumnValueWithColumnMatch, returnKeyWithColumnMatch, s3StaticPath, returnLabelsWithValues, dateTimeMy, openModal, getFormattedDate, dateFormatMy, closeModal, dateTimeWithAMPM, focusInput } from 'helper/helperFunctions';
import { objectsConstants } from 'redux/constant/objects-constant';
import { getCountries, getDestinations } from 'redux/actions/settings-actions';
import SimpleReactValidator from 'simple-react-validator';
import ThemeModal from 'components/ThemeComponents/ThemeModal';
import OrderTitles from 'components/Admin/Order/OrderTitles';
import OrderComments from 'components/Admin/Order/OrderComments';
import { getItems } from 'redux/actions/item-actions';
import Select from 'react-select'
import { getCustomMusic } from 'redux/actions/customMusicActions'
import { objectsConstants as OC } from 'redux/constant/objects-constant';
import MyImage from 'components/Upload/MyImage';
import Documents from 'components/FrontEnd/Order/OrderForm/Documents';
import $ from 'jquery';
import OpenModalButton from 'components/ThemeComponents/openModelButton';
import { addComment } from "redux/actions/comment-actions";
import { settingsService } from 'services/settings';
import AsyncCreatableSelect from 'react-select/async-creatable';
import {
    OverlayTrigger,
    Popover,
} from "react-bootstrap";
import { orderService } from '../../../services/order'
const moment = window.moment;
var Modal = require('react-bootstrap-modal');
var dateFormat = require('dateformat');

const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
        console.log('inputValue', inputValue);
        setTimeout(() => {
            resolve(settingsService.getDestinationsAsync({ inputValue: inputValue }));
        }, 1000);
    }
    );

class LabProduction extends Component {
    constructor(props) {
        super(props);
        this.validatorComment = new SimpleReactValidator({ autoForceUpdate: this, locale: 'en' });
        this.state = {
            order_details: { ...objectsConstants.ORDERDETAIL, order_musics: [] },
            shipping_details: objectsConstants.SHIPPINGDETAIL,
            pricing_details: objectsConstants.PRICINGDETAIL,
            production_details: this.props.production_details,
            showTitlesModal: false,
            additionalServiceModal: false,
            item: OC.ITEMADDITIONALSERVICE,
            productionModal: false,
            inv_barcode: '',
            submitOrderDocuments: false,
            editOrderModal: false,
            scheduledOrderModal: false,
            commentOnHold: {
                comment: '',
                comment_category_id: '',
                slug: '',
                uuid: '',
            },
            productionMessage: '',
            estimatedData: '',
        }
        this.clean = clean.bind(this);
        this.inputChangeHandler = inputChangeHandler.bind(this);
        this.validatorShippingDetail = new SimpleReactValidator({ autoForceUpdate: this, locale: 'en' });
        this.Validator = new SimpleReactValidator({ autoForceUpdate: this, locale: 'en' });
        this.ValidatorRec = new SimpleReactValidator({ autoForceUpdate: this, locale: 'en' });
        this.ValidatorAS = new SimpleReactValidator({ autoForceUpdate: this, locale: 'en' });
        this.dateChangeHandler = dateChangeHandler.bind(this);
        this.toggleModal = toggleModal.bind(this);
        this.selectChangeHandler = selectChangeHandler.bind(this);
        this.selectArrayChangeHandler = selectArrayChangeHandler.bind(this)
        this.props.getOrder(this.props.match.params.id, true);
        this.props.getCountriesCall();
        this.props.getCustomMusic();
        // this.props.getDestinationsCall();
        this.props.getDestinationsCall({ inputValue: null, defaultOptions: [] }).then(result => {
            console.log("response: ", result);
        });
        this.props.onGetOrderResourcesCall(this.props.match.params.id, 'audio_video');
    }

    componentDidUpdate(prevProps, prevState) {

        if (this.props.order_details.order_resources_count !== prevProps.order_details.order_resources_count) {
            this.setState({ editOrderModal: false }, () => {
                this.props.getOrder(this.props.match.params.id, true);
            });
        }
        if (this.props.order_details !== prevProps.order_details) {
            this.setState({
                order_details: {
                    ...this.props.order_details,
                    settings: { ...prevState.order_details.settings, ...this.props.order_details.settings },
                    order_dates: {
                        ...this.props.order_details.order_dates,
                        receive_date: { ...this.props.order_details.order_dates.receive_date, value: this.props.order_details.order_dates.receive_date.value ? this.props.order_details.order_dates.receive_date.value : moment(new Date(), 'YYYY-MM-DD') },
                        finish_by_date: { ...this.props.order_details.order_dates.finish_by_date, value: this.props.order_details.order_dates.finish_by_date.value ? this.props.order_details.order_dates.finish_by_date.value : moment().add(2, 'w') }
                    }
                }
            }, () => {
                // console.log('order detail one', this.state.order_details);
            });
        }
        if (this.props.pricing_details !== prevProps.pricing_details) {
            this.setState({
                pricing_details: this.props.pricing_details
            })
        }
        if (this.props.shipping_details !== prevProps.shipping_details) {
            this.setState({
                shipping_details: this.props.shipping_details
            })
        }
        if (this.props.production_details !== prevProps.production_details) {
            this.setState({
                production_details: this.props.production_details
            })
        }
        if (this.state.item.item_id !== prevState.item.item_id) {
            const item = this.props.items.find(item => {
                return item.value === this.state.item.item_id
            })
            if (item !== undefined) {
                this.setState(prevState => ({
                    item: {
                        ...prevState.item,
                        title: item.label,
                        price: parseFloat(item.price),
                        item: {
                            title: item.label,
                            id: item.value,
                            price: parseFloat(item.price)
                        }
                    }
                }));
            }
        }
        if (this.state.item.quantity !== prevState.item.quantity && this.state.item.item !== undefined) {
            this.setState(prevState => ({
                item: {
                    ...prevState.item,
                    // price: parseFloat(this.state.item.quantity) * parseFloat(this.state.item.item.price)
                    price: parseFloat(this.state.item.item.price)
                }
            }))
        }

        // if (this.state.order_details !== prevState.order_details && this.state.order_details.order_dates.finish_by_date.value !== prevState.order_details.order_dates.finish_by_date.value) {
        //     orderService.scheduledEstimatedOrder({ selectedDate: this.state.order_details.order_dates.finish_by_date.value }).then(result => {
        //         this.setState({ estimatedData: result.data })
        //     });
        // }

        if (this.state.order_details.settings.approved_for_upload !== prevState.order_details.settings.approved_for_upload) {
            const { order_details } = this.state
            const { settings } = order_details
            if (+this.state.order_details.settings.approved_for_upload.value) {
                // console.log('one');
                const photos_submitted = +settings.photos_submitted_for_video_production.value
                const upload_cut_off_date = order_details.order_dates.upload_cut_off_date.value
                // const uploadDatePassed = compareDates(new Date(), upload_cut_off_date, '<=')
                //feedback 5 comment this
                //photo is not submitted for video production and upload cut off date is not reached and photo submitted for video prodction current and previous value is not same
                // if (!photos_submitted && uploadDatePassed && (photos_submitted !== parseInt(prevState.order_details.settings.photos_submitted_for_video_production.value, 10))) {
                //     this.props.holdOrderAddComment(this.props.match.params.id).then(
                //         res => {
                //             this.setState({
                //                 holdMessage: res.holdMessage
                //             })
                //         }
                //     );
                // }
                if (!photos_submitted && compareDates(new Date(), upload_cut_off_date, '>')) {
                    if (order_details.originalImages > 0) {
                        if (Number(order_details.settings.photos_submitted_for_video_production.value) !== 0 && order_details.settings.photos_submitted_for_video_production.value !== "") {
                            this.props.updateOrderSettingsCall(this.props.match.params.id, 1, 'photos_submitted_for_video_production')
                        }
                        this.setState({
                            holdMessage: `Uploaded Photos have NOT been submitted but upload cut off date HAS been reached.${order_details.originalImages} number of photos have been uploaded. Submit Uploaded Photos For Video Production.`
                        })
                    } else {
                        this.setState({
                            holdMessage: `No Uploaded Photos. Continue Production. If no images were received on equipment, contact GL.`
                        })
                    }
                }
                if (photos_submitted !== parseInt(prevState.order_details.settings.photos_submitted_for_video_production.value, 10)) {
                    // console.log('five', photos_submitted);
                    // console.log('six',  prevState.order_details.settings.photos_submitted_for_video_production.value);

                    if (photos_submitted && order_details.order_shipping_items_count > 0) {
                        this.props.holdOrderAddComment(this.props.match.params.id)
                        this.setState({
                            holdMessage: `${order_details.originalImages} photos have been submitted for production. Order still has equipment assigned to order. Order placed on Hold. Place order aside until equipment is returned.`
                        })
                    }
                    else {
                        this.setState({
                            holdMessage: `No Uploaded Photos.`
                        })
                    }
                }
            }
            if ((settings.finalize_by_pm.value !== prevState.order_details.settings.finalize_by_pm.value) && settings.finalize_by_pm.value === '1') {
                this.setState({
                    productionMessage: 'This order has been produced.'
                }, () => {
                    this.setState({
                        productionModal: true
                    })
                })
            } else if ((settings.finalize_by_editor.value !== prevState.order_details.settings.finalize_by_editor.value) && settings.finalize_by_editor.value === '1') {
                this.setState({
                    productionMessage: 'This order has been edited.'
                }, () => {
                    this.setState({
                        productionModal: true
                    })
                })
            } else if ((settings !== prevState.order_details.settings) && settings.print_lpr && settings.print_lpr.value === '1') {
                if (this.state.productionMessage === '') {
                    this.setState({
                        productionMessage: 'LPR has already been printed.'
                    }, () => {
                        this.setState({
                            productionModal: true
                        })
                    })
                }
            } else if ((settings.finalize_by_editor.value !== prevState.order_details.settings.finalize_by_editor.value) && settings.finalize_by_editor.value !== '1' && order_details.total_approved_photos < 1) {
                this.setState({
                    productionMessage: 'Please contact GL, all photos are Not Approved.'
                }, () => {
                    this.setState({
                        productionModal: true
                    })
                })
            }
        }

        if ('onHoldLastComment' in this.props.order_details) {
            if (this.props.order_details.onHoldLastComment !== prevProps.order_details.onHoldLastComment && this.props.order_details.onHoldLastComment !== null) {
                this.setState({ commentOnHold: this.props.order_details.onHoldLastComment });
            }
        }

        if (prevProps.commentCategories !== this.props.commentCategories) {
            this.setState((state) => {
                return {
                    commentOnHold: {
                        ...state.comment,
                        comment_category_id: returnColumnValueWithColumnMatch(this.props.commentCategories, 'id', "Hold", 'commentCategoryCode')
                    }
                }
            });
        }
    }

    updateFinishByDate = (e) => {
		e.preventDefault();
        console.log("clicked");
        if (this.Validator.allValid()) {
            console.log("if//////");
            const name = e.target.name;
            const url = `/Admin/${this.props.match.params.id}/print_LPR`;
            const data = {
                pricing_details: this.state.pricing_details,
                order_details: this.state.order_details,
            }
            const oldPricingDetails = JSON.stringify(this.props.pricing_details);
            const newPricingDetails = JSON.stringify(this.state.pricing_details);
            const orderData = {
                finish_by_date: this.state.order_details && this.state.order_details.order_dates.finish_by_date && { value: this.state.order_details.order_dates.finish_by_date.value ? this.state.order_details.order_dates.finish_by_date.value : '' },

                video_special_need_date: this.state.order_details && this.state.order_details.order_dates.video_special_need_date && { value: this.state.order_details.order_dates.video_special_need_date.value ? this.state.order_details.order_dates.video_special_need_date.value : '' },

                receive_date: this.state.order_details && this.state.order_details.order_dates.receive_date && { value: this.state.order_details.order_dates.receive_date.value ? this.state.order_details.order_dates.receive_date.value : '' },
                group_name: this.state.order_details && this.state.order_details.group_name ? this.state.order_details.group_name : '',
                destinations: this.state.order_details && this.state.order_details.destinations ? this.state.order_details.destinations : [],
                music_selection_criteria: this.state.order_details && this.state.order_details.music_selection_criteria ? this.state.order_details.music_selection_criteria : ''
            }
            if (oldPricingDetails !== newPricingDetails) {
                this.props.updatePricingDetails({ pricing_details: data.pricing_details, LPR: true }, this.props.match.params.id).then(() => {
                    orderService.updateFinishByDate({ orderData }, this.props.match.params.id).then(() => {
                        window.open(url);
                        this.props.history.push(`/Admin/${this.props.match.params.id}/order-edit`);
                    });
                })
            } else {
                orderService.updateFinishByDate({ orderData }, this.props.match.params.id).then(() => {
                    window.open(url);
                    this.props.history.push(`/Admin/${this.props.match.params.id}/order-edit`);
                });
            }
        } else {
			focusInput(this.Validator.getErrorMessages());
            this.Validator.showMessages();
            this.forceUpdate();
        }
    }

    formSubmitHandler = (e) => {
        if (this.Validator.allValid()) {
            const { name } = e.target;
            const data = {
                pricing_details: this.state.pricing_details,
                order_details: this.state.order_details,
                // shipping_details: {
                //     ...this.state.shipping_details,
                //     video_shipping_address: this.ctrl_VSA.returnAddressData()
                // }
            }
            console.log('data order Details', data.order_details);
            // return false;
            if (name === 'save') {
                const receive_date = data.order_details.order_dates.receive_date;
                if (receive_date.value === null) {
                    receive_date.value = new Date();
                    this.props.updateOrderDate(this.props.match.params.id, receive_date)
                }
                this.props.updatePricingDetails({ pricing_details: data.pricing_details, LPR: true }, this.props.match.params.id);
                this.props.updateOrderCall({ order_details: data.order_details }, this.props.match.params.id);
                // this.props.updateShippingDetailsCall(this.props.match.params.id, { shipping_details: data.shipping_details })
                // this.props.updateOrderSettingsCall(this.props.match.params.id, !parseFloat(data.order_details.settings.on_hold.value), data.order_details.settings.on_hold.key);
            }

        } else {
			focusInput(this.Validator.getErrorMessages());
            this.Validator.showMessages();
            this.forceUpdate();
        }
    }

    openAdditionalServiceModal = () => {
        // if (this.props.items.length === 0)
        this.props.getItems('Additional Services');

        this.setState({ item: OC.ITEMADDITIONALSERVICE }, () => {
            this.toggleModal('additionalServiceModal');
        });
    }
    commentHoldAddSubmitHandler = () => {

        if (this.validatorComment.allValid()) {
            const data = {
                ...this.state.commentOnHold,
            }

            this.props.addCommentCall(data, this.props.order_details.id).then(
                response => {
                    this.setState(state => {
                        this.props.updateOrderSettingsCall(this.props.order_details.id, 0, 'on_hold').then(
                            res => {
                                this.props.getOrder(this.props.order_details.id)
                            }
                        );

                        return {
                            commentHoldAdd: false,
                            commentOnHold: {
                                ...state.commentOnHold,
                                comment: response.data.comment.comment,
                            }
                        }


                    })
                }
            );

        } else {
            this.validatorComment.showMessages();
            this.forceUpdate();
        }
    }
    addAdditionalService = () => {
        if (this.ValidatorAS.allValid()) {
            const item = { ...this.state.item };
            this.setState(prevState => ({
                ...prevState,
                pricing_details: {
                    ...prevState.pricing_details,
                    order_items: {
                        ...prevState.pricing_details.order_items,
                        [item.title]: item
                    }
                }
            }), () => {
                const order_totals = this.state.pricing_details.order_totals;
                const order_items = this.state.pricing_details.order_items;
                const total = Object.keys(order_items).reduce((total, current) => {
                    return total = total + parseFloat(order_items[current].price)
                }, 0)
                this.setState(prevState => ({
                    ...prevState,
                    pricing_details: {
                        ...prevState.pricing_details,
                        order_totals: {
                            ...order_totals,
                            grand_total: {
                                ...order_totals.grand_total,
                                value: total
                            },
                            sub_total: {
                                ...order_totals.sub_total,
                                value: total
                            }
                        }
                    }
                }))
                this.toggleModal('additionalServiceModal');
            })
        } else {
            this.forceUpdate();
            this.ValidatorAS.showMessages();
        }
    }

    toggleInventoryStatus = () => {
        if (this.ValidatorRec.fieldValid('inventory barcode')) {
            const { order_details } = this.state;
            const inv_barcode = this.state.inv_barcode
            const data = {};
            data.non_uploaded_photos_received = order_details.settings.non_uploaded_photos_received.value;
            data.received_client_equipment = parseInt(order_details.settings.received_client_equipment.value, 10) === 1 ? true : false;
            data.return_package_not_received = parseInt(order_details.settings.return_package_not_received.value, 10) === 1 ? true : false;
            data.client_equipment_comments = order_details.settings.client_equipment_comments.value;
            data.non_received_return_package_comments = order_details.settings.non_received_return_package_comments.value;

            this.props.toggleInventoryStatus(this.props.match.params.id, inv_barcode, data).then(
                res => {
                    this.props.getOrder(this.props.match.params.id);
                }
            );
        } else {
            this.forceUpdate();
            this.ValidatorRec.showMessageFor('inventory barcode');
        }
    }

    onChangeOrderDate(value, field) {
        const order_details = { ...this.state.order_details };
        const order_dates = { ...order_details.order_dates };
        const date_type = { ...order_dates[field] };
        date_type.value = value === null ? '' : value;
        this.setState(
            {
                order_details: {
                    ...order_details,
                    order_dates: {
                        ...order_dates,
                        [field]: { ...date_type }
                    }
                }
            }, () => {
                console.log('order date change', this.state.order_details);
            }
        );
    }


    commentOnHoldChangeHandler = (e) => {
        const value = e.target.value;
        this.setState((state) => {
            return {
                commentOnHold: {
                    ...state.commentOnHold,
                    comment: value
                }
            }
        })
    }
    dateElement = (label, name, value, rules, defaultDate) => {
        var selectedDate = this.state.order_details.order_dates[name]['value'] !== '' ? new Date(this.state.order_details.order_dates[name]['value']) : '';
        if (defaultDate && selectedDate === '') {
            selectedDate = new Date(defaultDate);
        }

        return (
            <div className='form-group fullCalendar' id={name}>
                <label>{label}</label>
                <DatePicker
                    className="form-control calender_icon"
                    selected={selectedDate}
                    name={name}
                    onChange={(event) => this.onChangeOrderDate(event, name)}
                    autoComplete="off"
                />
                {
                    value &&
                    this.Validator.message(name, value, rules)
                }
            </div>
        );
    }

    handleChangeDestination = (selectedOption, name) => {
        if (name.action === 'select-option') {
            this.setState(prevState => ({
                order_details: {
                    ...prevState.order_details,
                    destinations: [...prevState.order_details.destinations, name.option.value]
                }
            }));
        }
        if (name.action === 'remove-value') {
            const destinationsTemp = this.state.order_details.destinations;
            destinationsTemp.splice(destinationsTemp.indexOf(name.removedValue.value), 1);
            this.setState(prevState => ({
                order_details: {
                    ...prevState.order_details,
                    destinations: destinationsTemp
                }
            }))
        }
        if (name.action === 'clear') {
            this.setState(prevState => ({
                order_details: {
                    ...prevState.order_details,
                    destinations: []
                }
            }));
        }
        /*Modal for destination code*/
        if (name.action === 'create-option') {
            var key = returnKeyWithColumnMatch('__isNew__', selectedOption, true)
            this.setState(prevState => ({
                newDestination: {
                    ...prevState.newDestination,
                    title: selectedOption[key]['label']
                }
            }), () => {
                this.openModal('addModalDestination');
            })
        }
        /*Modal for destination code*/
    }



    toggleUploader = (e, value) => {
        // let that = this;
        // e.preventDefault();
        $('.uploader_box_contains').stop(true, true).slideToggle("slow", () => {
            this.setState({
                toggleUploader: !this.state.toggleUploader
            });

            let hash = window.location.hash;
            if (hash && this.state.toggleUploader) {
                let topOffset = $(hash).offset().top;
                $("html,body").animate({ scrollTop: topOffset }, 100);
            }
        });
    }

    updateOrderSettings = (e) => {
        var value = e.target.value;
        var key = e.target.name;
        this.props.updateOrderSettingsCall(this.props.order_details.id, value, key).then(
            res => {
                this.props.getOrder(this.props.order_details.id)
            }
        );
    }
    estimateTable = (estimatedData) => {
        let tempObj = {};
        estimatedData.orders.map(ord => {
            if (!tempObj[ord.finish_by_date]) {
                tempObj[ord.finish_by_date] = {
                    orders: 1,
                    photos: ord.originalImages,
                }
            } else {
                tempObj = {
                    ...tempObj,
                    [ord.finish_by_date]: {
                        orders: tempObj[ord.finish_by_date].orders + 1,
                        photos: tempObj[ord.finish_by_date].photos + ord.originalImages,
                    }
                }
            }

        })
        estimatedData.estimatd_dates.map(date => {
            if(!tempObj[date]) {
                tempObj[date] = {
                    orders: 0,
                    photos: 0
                }
            }
        })
        return (
            <table className='table customTable table-bordered'>
                <thead>
                    <tr>
                        <th>Estimated Completed By Date</th>
                        <th># of Orders</th>
                        <th># of Photos</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        Object.keys(estimatedData.estimatd_dates).length > 0 && Object.keys(tempObj).length > 0 &&
                        estimatedData.estimatd_dates.map(date => {
                            if (tempObj[date]) {

                                return (
                                    <tr key={tempObj[date]} className={dateFormat(this.state.order_details.order_dates.finish_by_date.value, "yyyy-mm-dd") === date ? "text-danger" : ""} >
                                        <td>{date}</td>
                                        <td>
                                            {
                                                tempObj[date].orders
                                            }
                                        </td>
                                        <td>
                                            {
                                                tempObj[date].photos
                                            }
                                        </td>
                                    </tr>

                                    //    }
                                )
                            } else
                                return ''

                        })
                    }
                </tbody>
            </table>
        )
    }
    updateOrderSettingsModal = () => {
        this.setState({ commentHoldAdd: true });
    }
    scheduledOrderModal = () => {
        orderService.scheduledEstimatedOrder({ selectedDate: this.state.order_details.order_dates.finish_by_date.value }).then(result => {
            this.setState({ estimatedData: result.data })
        });
        this.setState({ scheduledOrderModal: true });
    }
    render() {
        this.ValidatorAS.purgeFields();
        const { order_details, shipping_details, pricing_details, production_details, item, editOrderModal, commentHoldAdd, commentOnHold, scheduledOrderModal, estimatedData } = this.state;
        const { /*countries, customMusic, */destinations, orderResourcesDocuments } = this.props;
        const { video_shipping_address } = shipping_details;
        const OrderId = this.props.match.params.id;
        return (
            <div className='content'>
                <Grid fluid>
                    <Row>
                        <Col md={12}>
                            <Card content={
                                <React.Fragment>

                                    <div className="secHeading">Lab Processing Record</div>
                                    {
                                        ('on_hold' in order_details.settings && order_details.settings.on_hold.value === '1') &&
                                        <div className="text-center"><strong className='text-danger ' style={{ 'fontSize': '35px' }} >{`ORDER ON HOLD`}</strong></div>


                                    }
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <div className='orderContent'>
                                                <div className="orderDetailsHeader">
                                                    <span className='text-uppercase'>Order Details</span>
                                                </div>
                                                <div className="orderDetailsBox border-x-0">
                                                    <table className="table orderTable">
                                                        <tbody>
                                                            <tr className='form-group'>
                                                                <td>Barcode</td>
                                                                <td><Link to={'/Admin/' + order_details.id + '/order-edit'} className="blue_link" target='_blank' > {this.clean(order_details.barcode)} </Link></td>
                                                                <td vAlign='center' rowSpan='2' ><React.Fragment>
                                                                    {
                                                                        order_details.agency.agency_logo !== undefined && order_details.agency.agency_logo !== null
                                                                            ?
                                                                            <MyImage src={order_details.agency.agency_logo.file_path} alt="primary logo" className="agencyLogo" width="50px" height="50px" />
                                                                            :
                                                                            <MyImage src={s3StaticPath('img/placeholder.png')} className="agencyLogo" alt="primary agency logo placeholder" width="50px" height="50px" />
                                                                    }

                                                                    {
                                                                        order_details.agency_id !== order_details.customer_slates_for_videos_id
                                                                            ?
                                                                            order_details.customer_slates_for_videos.agency_logo !== undefined && order_details.customer_slates_for_videos.agency_logo !== null
                                                                                ?
                                                                                <MyImage src={order_details.customer_slates_for_videos.agency_logo.file_path} alt="secondary logo" className="agencyLogo secondaryLogo" width="50px" height="50px" />
                                                                                :
                                                                                <MyImage src={s3StaticPath('img/placeholder.png')} className="agencyLogo secondaryLogo" alt="secondary agency logo placeholder" width="50px" height="50px" />
                                                                            :
                                                                            null
                                                                    }
                                                                </React.Fragment></td>

                                                            </tr>
                                                            <tr className='form-group'>
                                                                <td>Agency Name</td>
                                                                <td colSpan='2'>

                                                                    {
                                                                        this.clean(order_details.agency.name)
                                                                    }

                                                                </td>
                                                            </tr>
                                                            <tr className='form-group' id="GroupName">
                                                                <td>Group Name<span className="requiredClass">*</span></td>
                                                                <td colSpan='2'>
                                                                    <input className='form-control' type="text" name="order_details.group_name" value={this.clean(order_details.group_name)} onChange={this.inputChangeHandler} />
                                                                {
                                                                    this.Validator.message('GroupName', order_details.group_name, 'required')
                                                                }
                                                                </td>
                                                            </tr>
                                                            {/* <tr className='form-group'>
                                                                <td colSpan='3'>
                                                                    <div className='flexElem'>
                                                                        <div style={{ 'flexBasis': '38%' }}>
                                                                            <div className='form-group'>Destination</div>
                                                                            <div>
                                                                                <Select
                                                                                    isMulti={true}
                                                                                    value={returnLabelsWithValues(order_details.destinations, destinations)}
                                                                                    options={destinations}
                                                                                    onChange={this.handleChangeDestination}
                                                                                    className="selectUser"
                                                                                    name='order_details.destinations'
                                                                                    placeholder="Select Destination"

                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div style={{ 'flexBasis': '60%', 'marginLeft': '5px' }}>
                                                                            <div className='form-group'>Title Status</div>
                                                                            <div>
                                                                                <span style={{fontSize: 'smaller'}}>TITLES ENTERED</span><br /><button style={{ fontSize: 'smaller' }} name='showTitlesModal' onClick={() => { this.toggleModal('showTitlesModal') }} className='customBtn mt-xs btn btn-primary btn-default'>Change Titles</button>
                                                                            </div>
                                                                        </div>

                                                                    </div>

                                                                </td>

                                                            </tr> */}
                                                            {/*----------------- Start Destinations and Titles ------------------*/}
                                                            <tr className='form-group' id="desitnation">
                                                                <td>
                                                                    <div className='form-group'>Destination<span className="requiredClass">*</span></div>

                                                                </td>
                                                                <td colSpan='2'>
                                                                    <div>
                                                                        {/* <Select
                                                                            isMulti={true}
                                                                            value={returnLabelsWithValues(order_details.destinations, destinations)}
                                                                            options={destinations}
                                                                            onChange={this.handleChangeDestination}
                                                                            className="selectUser"
                                                                            name='order_details.destinations'
                                                                            placeholder="Select Destination"

                                                                        /> */}
                                                                        {
                                                                            order_details.destinationDefault &&
                                                                            <AsyncCreatableSelect
                                                                                isMulti={true}
                                                                                defaultOptions
                                                                                defaultValue={order_details.destinationDefault}
                                                                                loadOptions={promiseOptions}
                                                                                onChange={this.handleChangeDestination}
                                                                                className="selectUser"
                                                                                placeholder="Select Destination"
                                                                            />
                                                                        }
                                                                        {
                                                                            this.Validator.message('desitnation', order_details.destinations, 'required|min:1,array')
                                                                        }
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <div className=''>Titles</div>
                                                                </td>
                                                                {/* <td>

                                                                </td> */}
                                                                <td colSpan='2'>
                                                                    <div>
                                                                        <button style={{ fontSize: 'smaller' }} name='showTitlesModal' onClick={() => { this.toggleModal('showTitlesModal') }} className='customBtn mt-xs btn btn-primary btn-default'>Change Titles</button>
                                                                    </div>
                                                                </td>

                                                            </tr>

                                                            {/*----------------- End Destinations and Titles ------------------*/}

                                                            <tr>
                                                                <td >Music selection</td>
                                                                <td colSpan='2'>
                                                                    <label className="container-radio">
                                                                        Destination
                                                                        <input onChange={this.inputChangeHandler} value='destination_based' checked={order_details.music_selection_criteria === 'destination_based'} type='radio' name='order_details.music_selection_criteria' className='checkbox' />
                                                                        <span className="checkmark"></span>
                                                                    </label>
                                                                    <label className="container-radio ml-md">
                                                                        Age Based
                                                                        <input onChange={this.inputChangeHandler} value='age_based' checked={order_details.music_selection_criteria === 'age_based'} type='radio' name='order_details.music_selection_criteria' className='checkbox' />
                                                                        <span className="checkmark"></span>
                                                                    </label>

                                                                    <label className="container-radio ml-md">
                                                                        Custom
                                                                        <input onChange={this.inputChangeHandler} value='custom_music_based' checked={order_details.music_selection_criteria === 'custom_music_based'} type='radio' name='order_details.music_selection_criteria' className='checkbox' />
                                                                        <span className="checkmark"></span>
                                                                    </label>
                                                                </td>
                                                            </tr>
                                                            {
                                                            /*
                                                            <tr>
                                                                <td >Custom music</td>
                                                                <td colSpan='2'>
                                                                    {
                                                                        customMusic.map(music => {

                                                                            return (
                                                                                <label key={music.id} className="container-check mr-md">
                                                                                    {music.name}
                                                                                    <input onChange={this.inputChangeHandler} name='order_details.order_musics[]' checked={
                                                                                        order_details.order_musics.includes(music.id)
                                                                                    } value={music.id} type='checkbox' className='checkbox' />
                                                                                    <span className="checkmark"></span>
                                                                                </label>
                                                                            )
                                                                        })
                                                                    }
                                                                </td>
                                                            </tr>
                                                           */}
                                                            <tr className='form-group'>
                                                                <td>Video Shipping Address</td>
                                                                <td>
                                                                    {/* {
                                                                        shipping_details && shipping_details.video_shipping_address && shipping_details.video_shipping_address.country && shipping_details.video_shipping_address.country.title && shipping_details.video_shipping_address.country.title == 'France'
                                                                            ?
                                                                            <>
                                                                                {
                                                                                    `${shipping_details && shipping_details.video_shipping_address && shipping_details.video_shipping_address.street_address_1 && shipping_details.video_shipping_address.street_address_1} ${shipping_details.video_shipping_address.street_address_2 ? shipping_details.video_shipping_address.street_address_2 : ''}`
                                                                                }
                                                                                <br />
                                                                                {
                                                                                    `${shipping_details.video_shipping_address.zipcode} ${shipping_details.video_shipping_address.city}`
                                                                                }
                                                                                <br />
                                                                                {
                                                                                    `${shipping_details && shipping_details.video_shipping_address && shipping_details.video_shipping_address.country && shipping_details.video_shipping_address.country.title}`
                                                                                }
                                                                            </>
                                                                            :
                                                                            <>
                                                                                {
                                                                                    `${shipping_details && shipping_details.video_shipping_address && shipping_details.video_shipping_address.street_address_1 && shipping_details.video_shipping_address.street_address_1} ${shipping_details.video_shipping_address.street_address_2 ? shipping_details.video_shipping_address.street_address_2 : ''}`
                                                                                }
                                                                                <br />
                                                                                {
                                                                                    `${shipping_details && shipping_details.video_shipping_address && shipping_details.video_shipping_address.city && shipping_details.video_shipping_address.city}, ${shipping_details && shipping_details.video_shipping_address && shipping_details.video_shipping_address.state && shipping_details.video_shipping_address.state.title} ${shipping_details && shipping_details.video_shipping_address && shipping_details.video_shipping_address.zipcode && shipping_details.video_shipping_address.zipcode}  ${shipping_details && shipping_details.video_shipping_address && shipping_details.video_shipping_address.country && shipping_details.video_shipping_address.country.title}`
                                                                                }
                                                                            </>
                                                                        } */}
                                                                    {
                                                                        video_shipping_address && video_shipping_address.country && video_shipping_address.country.title && video_shipping_address.country.title === 'France'
                                                                            ?
                                                                            <>
                                                                                {
                                                                                    `${video_shipping_address && video_shipping_address.street_address_1 && video_shipping_address.street_address_1}${video_shipping_address.street_address_2 ? ', ' + video_shipping_address.street_address_2 : ''}`
                                                                                }
                                                                                <br />
                                                                                {
                                                                                    `${video_shipping_address && video_shipping_address.zipcode ? video_shipping_address.zipcode : ''} ${video_shipping_address && video_shipping_address.city && video_shipping_address.city}${video_shipping_address && video_shipping_address.country && video_shipping_address.country.title ? ', ' + video_shipping_address.country.title : ''}`
                                                                                }
                                                                                {/* <br />
                                                                                {
                                                                                    `${video_shipping_address && video_shipping_address.country && video_shipping_address.country.title}`
                                                                                } */}
                                                                            </>
                                                                            :
                                                                            <>
                                                                                {
                                                                                    `${video_shipping_address && video_shipping_address.street_address_1 && video_shipping_address.street_address_1}${video_shipping_address.street_address_2 ? ', ' + video_shipping_address.street_address_2 : ''}`
                                                                                }
                                                                                <br />
                                                                                {
                                                                                    `${video_shipping_address && video_shipping_address.city && video_shipping_address.city + ', '}${video_shipping_address && video_shipping_address.state && video_shipping_address.state.title} ${video_shipping_address && video_shipping_address.zipcode ? video_shipping_address.zipcode : ''} ${video_shipping_address && video_shipping_address.country && video_shipping_address.country.title}`
                                                                                }
                                                                            </>
                                                                    }
                                                                    {
                                                                        /*
                                                                            <AddressForm
                                                                                provideCtrl={ctrl => this.ctrl_VSA = ctrl}
                                                                                htmlElement="VideoShippingAddress"
                                                                                clearState={this.clearState}
                                                                                type="video_shipping_address"
                                                                                options={
                                                                                    {
                                                                                        'countries': countries
                                                                                    }
                                                                                }
                                                                                adressObject={shipping_details.video_shipping_address}
                                                                                title="Video Shipping Address"
                                                                                validatorInstance={this.validatorShippingDetail}
                                                                                requiredField={
                                                                                    {
                                                                                        'street_address_1': 'required',
                                                                                        'country_id': 'required',
                                                                                        'city': 'required',
                                                                                        'state_id': 'required',
                                                                                        'zipcode': 'integer'
                                                                                    }
                                                                                }
                                                                                isClearableCountryPro={false}
                                                                                readOnly={true}
                                                                            />
                                                                        */
                                                                    }
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className='orderContent'>
                                                <div className="orderDetailsHeader">
                                                    <span className='text-uppercase'>Uploading Information</span>
                                                </div>
                                                <div className="orderDetailsBox border-x-0">
                                                    <div className='flexElem'>
                                                        <div style={{ 'flexBasis': '50%' }}>
                                                            <table className='table orderTable'>
                                                                <tbody>
                                                                    <tr>
                                                                        <td>Current # of Photos Uploaded</td>
                                                                        <td>{order_details.originalImages}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Upload cut off date</td>
                                                                        <td>{getFormattedDate(this.clean(order_details.order_dates.upload_cut_off_date.value))}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Submission Status</td>
                                                                        <td>
                                                                            {
                                                                                Number(order_details.settings.photos_submitted_for_video_production.value) === 1 ?
                                                                                    `${dateTimeWithAMPM(order_details.settings.photos_submitted_for_video_production.updated_at)} by ${order_details.settings.photos_submitted_for_video_production.updated_by_user ? order_details.settings.photos_submitted_for_video_production.updated_by_user.name : ''}`
                                                                                    :
                                                                                    'NOT SUBMITTED'
                                                                            }
                                                                        </td>
                                                                    </tr>

                                                                    {
                                                                        /*order_details.settings.approved_for_upload.value === '1' &&
                                                                        (<tr>
                                                                            <td>Highlight (Flash) Pic Selected</td>
                                                                            <td>{order_details.HighlightPicsCount}</td>
                                                                        </tr>)
                                                                    */}

                                                                    {
                                                                        <React.Fragment>
                                                                        <tr>
                                                                            <td>Randomization</td>
                                                                            <td>{order_details.sortOrder === 'Randomization' ? order_details.sortOrderCount : 'No images were randomized'}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Positioning</td>
                                                                            <td>{order_details.sortOrder === 'Positioning' ? order_details.sortOrderCount : 'No images were positioned'}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Not Approved Photos</td>
                                                                            <td>{order_details.originalImages  ? (order_details.originalImages - order_details.total_approved_photos)  : '' }</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td colSpan='2'><a target='_blank' href={`/Admin/${order_details.id}/order-edit?orderDetailCode=production`} className='blue_link' rel="noopener noreferrer" >ADD Photos</a></td>
                                                                        </tr>
                                                                        </React.Fragment>
                                                                    }

                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        <div style={{ 'flexBasis': '50%' }}>
                                                            <table className='table orderTable'>
                                                                <tbody>
                                                                    <tr>
                                                                        <td>Current # of Video/Audio Files Uploaded</td>
                                                                        <td>{orderResourcesDocuments.length}</td>
                                                                        {/* <td>{order_details.order_resources_audio_video_count}</td> */}
                                                                    </tr>
                                                                    <tr>
                                                                        <td colSpan='2'>
                                                                            <OpenModalButton
                                                                                openModal={openModal.bind(this, "editOrderModal")}
                                                                                classButton={['customBtn btn btn-primary btn-default']}
                                                                                buttonName="ADD Video/Audio"
                                                                                tooltipText="ADD Video/Audio Files"
                                                                                classIcon={[]}
                                                                            />
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td colSpan='2'>
                                                                            {
                                                                                'on_hold' in order_details.settings &&
                                                                                <React.Fragment>
                                                                                    {
                                                                                        order_details.settings.on_hold.value === '1'
                                                                                            ?
                                                                                            <span>
                                                                                                <button value={order_details.settings.on_hold.value} name='on_hold' onClick={this.updateOrderSettings} className='customBtn btn btn-primary btn-default'>Release From Hold</button>
                                                                                                {

                                                                                                    <OverlayTrigger overlay={
                                                                                                        <Popover id="onHoldStatus">
                                                                                                            This order was placed on hold on <strong>{getFormattedDate(order_details.settings.on_hold.updated_at)} {` by ${order_details.settings.on_hold.updated_by_user.name}`}</strong>
                                                                                                            <br />
                                                                                                            {
                                                                                                                order_details.onHoldLastComment &&
                                                                                                                `Comment : ${order_details.onHoldLastComment.comment}`
                                                                                                            }
                                                                                                        </Popover>
                                                                                                    }>


                                                                                                        <span className="question_mark ml-xs">?</span>
                                                                                                    </OverlayTrigger>

                                                                                                }
                                                                                            </span>
                                                                                            :
                                                                                            <button value={order_details.settings.on_hold.value} name='on_hold' onClick={this.updateOrderSettingsModal} className='customBtn btn btn-primary btn-default'>Place on Hold</button>
                                                                                    }
                                                                                </React.Fragment>

                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>

                                                    </div>
                                                    <Modal backdrop={'static'} show={editOrderModal} onHide={closeModal.bind(this, "editOrderModal")} aria-labelledby="ModalHeader" className="editOrderDetail" >
                                                        <Modal.Header closeButton>
                                                            <Modal.Title id='ModalHeader' className="headerTitle">Edit Order Information</Modal.Title>
                                                        </Modal.Header>
                                                        <Modal.Body>
                                                            <Documents
                                                                notEditable
                                                                formSubmitted={this.state.submitOrderDocuments}
                                                                id={order_details.id}
                                                                formType={'edit'}
                                                                isSchedule={false}
                                                                header={'Audio Video'}
                                                                resourceTypeText={'audio_video'}
                                                            />
                                                        </Modal.Body>
                                                    </Modal>
                                                </div>
                                            </div>
                                            {
                                                shipping_details.assignedItems.length > 1 &&
                                                <div className='orderContent'>
                                                    <div className="orderDetailsHeader">
                                                        <span className='text-uppercase'>Digital Assets</span>
                                                    </div>
                                                    <div className="orderDetailsBox border-x-0">
                                                        <div className={'flexElem'}>
                                                            <div style={{ 'flexBasis': '50%' }}>
                                                                <div className={'p-md pb-none'}>Assets Assigned To This Order</div>
                                                                <table className='table orderTable'>
                                                                    <tbody>
                                                                        {
                                                                            order_details.program_type.item_category.title !== 'Single Use' &&
                                                                            order_details.program_type.item_category.title !== 'GL\'s Own Equipment'
                                                                            &&
                                                                            (
                                                                                <React.Fragment>
                                                                                    <tr className='form-group'>
                                                                                        <td style={{ 'fontWeight': 'bold' }}>Barcode</td>
                                                                                        <td style={{ 'fontWeight': 'bold' }}>Item</td>
                                                                                    </tr>

                                                                                    {
                                                                                        /*
                                                                                            <td>
                                                                                            Scan item to return
                                                                                            <input onChange={this.inputChangeHandler} name='inv_barcode' value={this.state.inv_barcode} className='form-control' type="text" />
                                                                                            <button onClick={this.toggleInventoryStatus} className='customBtn btn mt-sm btn-primary btn btn-default'>Receive</button>
                                                                                            {this.Validator.message('inventory barcode', this.state.inv_barcode, 'required')}
                                                                                        </td>

                                                                                        */
                                                                                    }

                                                                                    {
                                                                                        order_details.order_shipping_items.map(item => {

                                                                                            return (item.inventory.barcode && item.item.title)
                                                                                                ?
                                                                                                <tr key={item.id}>
                                                                                                    <td>
                                                                                                        {item.inventory.barcode}
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        {item.item.title}
                                                                                                    </td>
                                                                                                </tr>
                                                                                                :
                                                                                                null
                                                                                        })
                                                                                    }
                                                                                    {
                                                                                        /*

                                                                                        <td>

                                                                                            <label htmlFor="">
                                                                                                PHOTO NUMBERS (Format Ex. 100+200+300)
                                                                                                <input
                                                                                                    value={order_details.settings.non_uploaded_photos_received.value}
                                                                                                    onChange={this.inputChangeHandler}
                                                                                                    className='form-control'
                                                                                                    type="text"
                                                                                                    name='order_details.settings.non_uploaded_photos_received.value'
                                                                                                />
                                                                                            </label>
                                                                                        </td>

                                                                                        */
                                                                                    }

                                                                                </React.Fragment>
                                                                            )
                                                                        }
                                                                        <tr>

                                                                            <td>
                                                                                Scan item to return
                                                                                <input onChange={this.inputChangeHandler} name='inv_barcode' value={this.state.inv_barcode} className='form-control' type="text" />
                                                                                <button onClick={this.toggleInventoryStatus} className='customBtn btn mt-sm btn-primary btn btn-default'>Receive</button>
                                                                                {this.ValidatorRec.message('inventory barcode', this.state.inv_barcode, 'required')}
                                                                            </td>
                                                                        </tr>

                                                                        {
                                                                            /*
                                                                                //feedback 5 client says not need this for time being

                                                                                <tr className='form-group'>
                                                                                    <td>
                                                                                        <label className="container-check">
                                                                                            Received Client's Personal Equipment? (flash drive,sd card etc.)
                                                                                            <input
                                                                                                onChange={this.inputChangeHandler}
                                                                                                name='order_details.settings.received_client_equipment.value'
                                                                                                checked={parseFloat(order_details.settings.received_client_equipment.value)}
                                                                                                value={parseFloat(order_details.settings.received_client_equipment.value) === 0 ? 1 : 0}
                                                                                                type='checkbox'
                                                                                                className='checkbox' />
                                                                                            <span className="checkmark"></span>
                                                                                        </label>
                                                                                    </td>
                                                                                    <td>
                                                                                        <label className="container-check">
                                                                                            No PhotoVision Return Package Received
                                                                                            <input
                                                                                                onChange={this.inputChangeHandler}
                                                                                                name='order_details.settings.return_package_not_received.value'
                                                                                                checked={parseFloat(order_details.settings.return_package_not_received.value)}
                                                                                                value={parseFloat(order_details.settings.return_package_not_received.value) === 0 ? 1 : 0}
                                                                                                type='checkbox'
                                                                                                className='checkbox' />
                                                                                            <span className="checkmark"></span>
                                                                                        </label>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr className='form-group'>
                                                                                    <td>
                                                                                        (add comments about what we received)
                                                                                        <textarea
                                                                                            onChange={this.inputChangeHandler}
                                                                                            value={this.clean(order_details.settings.client_equipment_comments.value)}
                                                                                            className='form-control'
                                                                                            name="order_details.settings.client_equipment_comments.value"
                                                                                            id=""
                                                                                            rows="5" />
                                                                                    </td>
                                                                                    <td>
                                                                                        PhotoVision Equipment Received
                                                                                        <textarea
                                                                                            onChange={this.inputChangeHandler}
                                                                                            // value={this.clean(order_details.settings.non_received_return_package_comments.value)}
                                                                                            className='form-control'
                                                                                            name="order_details.settings.non_received_return_package_comments.value"
                                                                                            id=""
                                                                                            rows="5" />
                                                                                    </td>
                                                                                </tr>
                                                                            */
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                            </div>

                                                            <div style={{ 'flexBasis': '50%' }}>
                                                                <div className={'p-md pb-none'}>Number of photos mailed</div>
                                                                <label htmlFor="" className={'p-md'}>

                                                                    <input
                                                                        value={order_details.settings.non_uploaded_photos_received.value}
                                                                        onChange={this.inputChangeHandler}
                                                                        className='form-control'
                                                                        type="text"
                                                                        maxLength={4}
                                                                        name='order_details.settings.non_uploaded_photos_received.value'
                                                                    />
                                                                    PHOTO NUMBERS (Format Ex. 100+200+300)
                                                                </label>
                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>

                                            }


                                            <div className='mt-md'>
                                                <button onClick={this.updateFinishByDate} name='print' className='customBtn btn ml-sm btn-primary btn btn-default text-light' >Print Lab Processing Record</button>
                                                <button onClick={this.formSubmitHandler} name='save' className='customBtn btn ml-sm btn-primary btn btn-default'>Save</button>
                                                <button onClick={() => { this.props.history.push(`/Admin/${OrderId}/order-edit`) }} className='customBtn btn ml-sm btn-primary btn btn-default'>Cancel</button>
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className='orderContent'>
                                                <div className="orderDetailsHeader">
                                                    <span className='text-uppercase'>Target Dates</span>
                                                </div>
                                                <div className="orderDetailsBox border-x-0">
                                                    <table className='table orderTable'>
                                                        <tbody>
                                                            <tr className='form-group'>
                                                                <td>
                                                                    {
                                                                        /*
                                                                        <DatePicker
                                                                            selected={this.clean(order_details.order_dates.receive_date.updated_at, new Date(), true)}
                                                                            onChange={(event) => this.dateChangeHandler(event, 'order_details.order_dates.departure_date.value')}
                                                                            className="form-control calender_icon"
                                                                            name="departure_date" />

                                                                        */
                                                                    }
                                                                    {
                                                                        this.dateElement(
                                                                            'Date Received',
                                                                            'receive_date',
                                                                            order_details.order_dates.receive_date['value'] !== '' && moment(order_details.order_dates.receive_date['value'], 'YYYY-MM-DD'),
                                                                            order_details.order_dates.video_special_need_date['value']
                                                                                ?
                                                                                ['required', 'date', { before: moment(order_details.order_dates.video_special_need_date['value'], 'YYYY-MM-DD') }]
                                                                                :
                                                                                ['required', 'date']
                                                                            , false)
                                                                    }
                                                                </td>
                                                                <td colSpan='2'>
                                                                    {
                                                                        this.dateElement(
                                                                            'Estimated Complete By Date',
                                                                            'finish_by_date',
                                                                            order_details.order_dates.finish_by_date['value'] !== '' && moment(order_details.order_dates.finish_by_date['value'], 'YYYY-MM-DD'),
                                                                            order_details.order_dates.video_special_need_date['value']
                                                                                ?
                                                                                ['required', 'date', { before: moment(order_details.order_dates.video_special_need_date['value'], 'YYYY-MM-DD') }]
                                                                                :
                                                                                ['required', 'date']
                                                                            , false)
                                                                    }
                                                                    <button name='scheduled_order' onClick={this.scheduledOrderModal} className='customBtn btn btn-primary btn-default'>Scheduled Orders</button>

                                                                    {
                                                                        /*
                                                                        <DatePicker
                                                                            selected={this.clean(order_details.order_dates.finish_by_date.value, '', true)}
                                                                            onChange={(event) => { this.dateChangeHandler(event, 'order_details.order_dates.finish_by_date.value') }}
                                                                            className="form-control calender_icon"
                                                                            name="finish_by_date"
                                                                        />
                                                                        */
                                                                    }
                                                                </td>
                                                            </tr>
                                                            <tr className='form-group'>
                                                                <td>
                                                                    {
                                                                        /*

                                                                        <DatePicker
                                                                            onChange={(event) => this.dateChangeHandler(event, 'order_details.order_dates.video_special_need_date.value')}
                                                                            selected={this.clean(order_details.order_dates.video_special_need_date.value, new Date(), true)}
                                                                            className="form-control calender_icon"
                                                                            name="video_special_need_date"
                                                                        />
                                                                        */
                                                                    }

                                                                    {
                                                                        this.dateElement(
                                                                            'Special Need Date',
                                                                            'video_special_need_date',
                                                                            order_details.order_dates.video_special_need_date['value'] !== '' && moment(order_details.order_dates.video_special_need_date['value'], 'YYYY-MM-DD'),
                                                                            ['date'],
                                                                            false
                                                                        )
                                                                    }

                                                                </td>
                                                                <td>

                                                                    <div className='form-group fullCalendar'>
                                                                        <label>Last Day Of School</label>
                                                                        <br />
                                                                        {
                                                                            dateFormatMy(order_details.order_dates.last_day_of_school['value'])
                                                                        }
                                                                    </div>
                                                                    {
                                                                        /*
                                                                            this.dateElement('Last Day Of School', 'last_day_of_school', order_details.order_dates.last_day_of_school['value']!=='' && moment(order_details.order_dates.last_day_of_school['value'], 'YYYY-MM-DD'), ['date', {after: moment(order_details.order_dates.return_date['value'], 'YYYY-MM-DD') }],  false)

                                                                        */
                                                                    }

                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className='orderContent'>
                                                <div className="orderDetailsHeader">
                                                    <span className='text-uppercase'>Number of copies </span>
                                                    {/*<span className='text-muted'>(* denotes free copies)</span>*/}
                                                </div>
                                                <div className="orderDetailsBox border-x-0">
                                                    <table className='table orderTable'>
                                                        <tbody>
                                                            <tr>
                                                                <td className='td_heading' rowSpan='2'>Videos/Travelers</td>
                                                                <td className='td_heading'>TOTAL</td>
                                                                <td className='td_heading'>CLIENT</td>
                                                                <td className='td_heading'>AGENCY</td>
                                                            </tr>
                                                            <tr>
                                                                <td>{pricing_details.order_items['Videos/Travelers'] && pricing_details.order_items['Videos/Travelers'].quantity} <br /> <Link className='blue_link' to={`/Admin/${order_details.id}/order-edit?editPricingModal=true`} > Change </Link> </td>
                                                                <td>{pricing_details.free_additional_dvds_to_client} <br /><span>&nbsp;</span></td>
                                                                <td>{pricing_details.free_additional_dvds_to_agency} <br /><span>&nbsp;</span></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <span style={{ color: 'red' }}>
                                                        {
                                                            parseInt(order_details.settings.no_gl_copy.value, 10) === 1 &&
                                                            <span>NO DVDs for travelers, No GL Copy</span>
                                                        }
                                                        {
                                                            parseInt(order_details.settings.send_gl_fd_copy.value, 10) === 1 &&
                                                            <span>NO DVDs for travelers, send GL FD Copy</span>
                                                        }
                                                        {
                                                            (Number(order_details.settings.no_gl_copy.value) === 0 && Number(order_details.settings.send_gl_fd_copy.value) === 0) &&
                                                            <span>DVDs Will Be Shipped</span>
                                                        }
                                                    </span>
                                                </div>
                                            </div>
                                            <div className='orderContent'>
                                                <div className="orderDetailsHeader">
                                                    <span className='text-uppercase'>Additional Services</span>
                                                </div>
                                                <div className="orderDetailsBox border-x-0">
                                                    <table className='table orderTable'>
                                                        <thead>
                                                            <tr>
                                                                <td className='td_heading'>Description</td>
                                                                <td className='td_heading'>Quantity</td>
                                                                <td className='td_heading'>Item Cost</td>
                                                                <td className='td_heading'>Line Total</td>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                Object.keys(pricing_details.order_items).map(item => {
                                                                    if (OC.EXCEPTITEMSLPR.includes(pricing_details.order_items[item].title) && parseInt(pricing_details.order_items[item].quantity) > 0) {
                                                                        return (
                                                                            <tr key={item} >
                                                                                <td>{pricing_details.order_items[item].title}</td>
                                                                                <td>{pricing_details.order_items[item].quantity}</td>
                                                                                <td>{pricing_details.order_items[item].price}</td>
                                                                                <td>{parseFloat(pricing_details.order_items[item].price * pricing_details.order_items[item].quantity).toFixed(2)}</td>
                                                                            </tr>
                                                                        )
                                                                    }
                                                                    else {
                                                                        return null;
                                                                    }
                                                                })
                                                            }
                                                        </tbody>
                                                    </table>
                                                    <button onClick={this.openAdditionalServiceModal} className='customBtn btn ml-sm btn-primary btn btn-default mb-md'>Add Additional Service</button>
                                                </div>
                                            </div>

                                            {
                                                /* Music select change place remove in future
                                                <div className='orderContent'>
                                                    <div className="orderDetailsHeader">
                                                        <span className='text-uppercase'>music</span>
                                                    </div>
                                                    <div className="orderDetailsBox border-x-0">
                                                        <table className='table orderTable'>
                                                            <tbody>
                                                                <tr>
                                                                    <td className='td_heading'>Music selection</td>
                                                                    <td>
                                                                        <label className="container-radio">
                                                                            Destination
                                                                            <input onChange={this.inputChangeHandler} value='destination_based' checked={order_details.music_selection_criteria === 'destination_based'} type='radio' name='order_details.music_selection_criteria' className='checkbox' />
                                                                            <span className="checkmark"></span>
                                                                        </label>
                                                                        <label className="container-radio ml-md">
                                                                            Age Based
                                                                            <input onChange={this.inputChangeHandler} value='age_based' checked={order_details.music_selection_criteria === 'age_based'} type='radio' name='order_details.music_selection_criteria' className='checkbox' />
                                                                            <span className="checkmark"></span>
                                                                        </label>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className='td_heading'>Custom music</td>
                                                                    <td>
                                                                        {
                                                                            customMusic.map(music => {

                                                                                return (
                                                                                    <label key={music.id} className="container-check mr-md">
                                                                                        {music.name}
                                                                                        <input onChange={this.inputChangeHandler} name='order_details.order_musics[]' checked={
                                                                                            order_details.order_musics.includes(music.id)
                                                                                        } value={music.id} type='checkbox' className='checkbox' />
                                                                                        <span className="checkmark"></span>
                                                                                    </label>
                                                                                )
                                                                            })
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>

                                                */
                                            }

                                            <div className='orderTabs'>
                                                <div className='orderContent'>
                                                    <div className="orderDetailsHeader">
                                                        <span className='text-uppercase'>Comments</span>
                                                    </div>
                                                    <div className='orderDetailsBox border-x-0 p-md pt-none'>
                                                        <OrderComments showSteps={false} hideTitle={true} showOnly={['Production', 'TO_GL', 'DVD_MozaixShip', 'ContactLog', 'All']} addExceptTab={['TO_GL', 'All']} modelId={OrderId} order_details={order_details} />
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                /*
                                                    <div className='orderContent'>
                                                        <div className="orderDetailsHeader">
                                                            <span className='text-uppercase'>hold order</span>
                                                        </div>
                                                        <div className="orderDetailsBox border-x-0">
                                                            <div className='p-md'>
                                                                <label className="container-check" >
                                                                    Place Order on Hold? (add comments below...)
                                                                    {
                                                                        ( parseInt(order_details.settings.approved_for_upload.value, 10) === 1 &&
                                                                        parseInt(order_details.settings.photos_submitted_for_video_production.value, 10) === 0 &&
                                                                        new Date()  < new Date(order_details.order_dates.upload_cut_off_date.value) )
                                                                        ?
                                                                        <label className="container-check" >
                                                                            <input checked={parseFloat(order_details.settings.on_hold.value)} onChange={this.inputChangeHandler} name='order_details.settings.on_hold.value' value={order_details.settings.on_hold.value === '0' ? 1 : 0} type='checkbox' className='checkbox'/>
                                                                        </label>
                                                                        :

                                                                            <OverlayTrigger  placement="bottom" overlay={
                                                                                <Popover id="tooltip">
                                                                                    <span>Place order on hold only when following conditions meet</span>
                                                                                        <ol>
                                                                                            <li>Photo Uploading Allowed</li>
                                                                                            <li>Photos Not Submitted For Video Production</li>
                                                                                            <li>Upload Cut Off Date has not been reached yet</li>
                                                                                        </ol>
                                                                                </Popover>
                                                                            }>
                                                                                <label className="container-check" >
                                                                                    <input checked={parseFloat(order_details.settings.on_hold.value)} value={order_details.settings.on_hold.value === '0' ? 1 : 0} type='checkbox' className='checkbox disabled' />
                                                                                </label>
                                                                            </OverlayTrigger>


                                                                    }

                                                                    <span className="checkmark"></span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                */
                                            }
                                            {/* <div className='mt-md'>
                                                <button className='customBtn btn ml-sm btn-primary btn btn-default'>Print Lab Processing Record</button>
                                                <button className='customBtn btn ml-sm btn-primary btn btn-default'>Save</button>
                                                <button className='customBtn btn ml-sm btn-primary btn btn-default'>Cancel</button>
                                            </div> */}
                                        </div>
                                    </div>
                                    <ThemeModal size='lg' hide={() => { this.toggleModal('showTitlesModal') }} title='Change Video Titles' show={this.state.showTitlesModal}>
                                        <OrderTitles close={() => { this.toggleModal('showTitlesModal') }} orderId={OrderId} titles={production_details.video_title_templates} order_details={order_details} />
                                    </ThemeModal>
                                    <ThemeModal size='lg' hide={() => { this.toggleModal('additionalServiceModal') }} title='Add Additional Service' show={this.state.additionalServiceModal}>
                                        <div className='row'>
                                            <div className='col-md-4'>
                                                <div className='form-group'>
                                                    <label htmlFor="">Select Item</label>
                                                    <Select
                                                        options={this.props.items}
                                                        placeholder='Select Item'
                                                        onChange={this.selectChangeHandler}
                                                        name='item.item_id'
                                                    />
                                                    {this.ValidatorAS.message('Item', this.state.item.item_id, 'required')}
                                                </div>
                                            </div>
                                            <div className='col-md-4'>
                                                <div className='form-group'>
                                                    <label htmlFor="">Quantity</label>
                                                    <input value={item.quantity} onChange={(e) => { this.inputChangeHandler(e, /[\d]$/) }} type="number" min={1} name="item.quantity" id="" className='form-control' />
                                                    {this.ValidatorAS.message('quantity', this.state.item.quantity, 'min:1,num')}
                                                </div>
                                            </div>
                                            <div className='col-md-4'>
                                                <div className='form-group'>
                                                    <label htmlFor="">Price</label>
                                                    <input className='form-control' type="text" readOnly value={item.price} />
                                                </div>
                                            </div>
                                        </div>
                                        <button onClick={this.addAdditionalService} className='customBtn btn ml-sm btn-primary btn btn-default mb-md'>Add</button>
                                    </ThemeModal>
                                    <ThemeModal bsize='large' hide={() => { this.toggleModal('productionModal') }} title='Production Status' show={this.state.productionModal}>
                                        <p>{this.state.productionMessage}</p>
                                    </ThemeModal>



                                </React.Fragment>
                            } />
                        </Col>
                    </Row>
                </Grid>

                <Modal backdrop={'static'} show={commentHoldAdd} onHide={closeModal.bind(this, 'commentHoldAdd')} aria-labelledby="ModalHeader" >
                    <Modal.Header closeButton>
                        <Modal.Title id='ModalHeader' className="headerTitle">Add Hold Comment</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Card bsClass={["innerCard"]} content={
                            <div className='clearfix' >
                                <div className="text-center">

                                    <textarea name='comment' onChange={this.commentOnHoldChangeHandler} className='form-control' value={commentOnHold.comment} />
                                    {
                                        this.validatorComment.message('comment', commentOnHold.comment, 'required')
                                    }
                                    {
                                        this.validatorComment.message('commentCategory', commentOnHold.comment_category_id, 'required')
                                    }

                                    <button name='add' type="button" className='backButton pt-sm no_radius pb-sm primary btn btn-md btn-info ml-sm mt-sm' onClick={this.commentHoldAddSubmitHandler} >save</button>
                                </div>
                            </div>
                        } />
                    </Modal.Body>
                </Modal>
                <Modal backdrop={'static'} show={scheduledOrderModal} onHide={closeModal.bind(this, 'scheduledOrderModal')} aria-labelledby="ModalHeader" >
                    <Modal.Header closeButton>
                        <Modal.Title id='ModalHeader' className="headerTitle">Scheduled Orders Modal</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Card bsClass={["innerCard"]} content={
                            <div className='clearfix' >
                                {
                                    estimatedData &&
                                    this.estimateTable(estimatedData)
                                }
                            </div>
                        } />
                    </Modal.Body>
                </Modal>

            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { order_details, shipping_details, pricing_details, production_details, orderResourcesDocuments } = state.ordersReducer;
    const { countries, destinations } = state.settingsReducer;
    const { items } = state.itemReducer;
    const { customMusic } = state.customMusicReducer;
    const { commentCategories } = state.commentReducer;
    // console.log('order_details haha', order_details);
    return {
        order_details,
        shipping_details,
        pricing_details,
        production_details,
        countries,
        items,
        customMusic,
        destinations,
        commentCategories,
        orderResourcesDocuments
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getOrder: (orderId, LPR) => { dispatch(getOrder(orderId, LPR)) },
        getCountriesCall: () => { dispatch(getCountries()) },
        getItems: (type) => { dispatch(getItems(type)) },
        updatePricingDetails: (data, id) => { return dispatch(updatePricingDetail(data, id)) },
        updateOrderCall: (data, id) => { return dispatch(updateOrderDetail(data, id)) },
        updateShippingDetailsCall: (orderId, Data) => { dispatch(updateShippingDetails(orderId, Data)) },
        updateOrderSettingsCall: (orderId, value, key) => { return dispatch(updateOrderSettings(orderId, value, key)) },
        getCustomMusic: () => { dispatch(getCustomMusic()) },
        updateOrderDate: (id, data) => { dispatch(updateOrderDate(id, data)) },
        holdOrderAddComment: (id) => { return dispatch(holdOrderAddComment(id)) },
        toggleInventoryStatus: (id, barcode, data) => { return dispatch(toggleInventoryStatus(id, barcode, data)) },
        // getDestinationsCall: () => { dispatch(getDestinations()) },
        getDestinationsCall: (filter) => { return dispatch(getDestinations(filter)) },
        addCommentCall: (data, modelId) => { return dispatch(addComment(data, modelId)) },
        onGetOrderResourcesCall: (orderId, type) => { return dispatch(getOrderResources(orderId, type)) },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LabProduction)
