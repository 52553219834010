import { SC } from "../helper/ServerCall";

export const invoicingService = {
    getInvoicingOrders,
    submitInvoicingOrders,
    getRecentlyInvoicedOrders,
    submitRecentlyInvoicedOrders,
    getInvoicingSettings,
    submitInvoicingSettings,
    getPrintInvoices,
    getInvoicingImport,
    submitInvoicingImport,
  };

  function getInvoicingOrders(value, fromDate, toDate, use_global_buffer, page, pageSize, sorted) {
    return SC.getCall(`invoicingReport?from=${fromDate}&to=${toDate}&use_global_buffer=${use_global_buffer}&page=${page}&pageSize=${pageSize}&sort=${sorted}`);
  }

  function submitInvoicingOrders(orders, invoiceDate) {
    return SC.postCall(`processOrdersToInvoice`, {orders:orders, invoiceDate: invoiceDate});
  }

  function getRecentlyInvoicedOrders(value, fromDate, toDate, page, pageSize, sorted) {
    return SC.getCall(`getRecentlyInvoicedOrders?from=${fromDate}&to=${toDate}&page=${page}&pageSize=${pageSize}&sort=${sorted}`);
  }

  function submitRecentlyInvoicedOrders(orders, invoiceDate) {
    return SC.postCall(`submitRecentlyInvoicedOrders`, {orders:orders, invoiceDate: invoiceDate});
  }

  function getInvoicingSettings() {
    return SC.getCall(`getInvoicesSettings`);
  }

  function submitInvoicingSettings(orders) {
    return SC.postCall(`saveInvoicesSettings`,orders);
  }
  function getPrintInvoices() {
    return SC.getCall(`printInvoice`);
  }

  function getInvoicingImport() {
    return SC.getCall(`getInvoicesSettings`);
  }

  function submitInvoicingImport(orders) {
    return SC.postCall(`importCSVOrders`,orders);
  }




