import { SC } from '../helper/ServerCall';

export const moduleService = {
    getAllModules,
    storeModule
}

function getAllModules(id) {
    if (id !== undefined) {
        return SC.getCall('getAllModules/' + id);
    } else {
        return SC.getCall('getAllModules');
    }
}

function storeModule(module) {
    return SC.postCall('modules', module);
}
