import React, { Component } from 'react'
import { Switch, Route } from 'react-router-dom'
import NewOrder from '../NewOrder';
import { Dashboard } from '../../../../layouts/Frontend/Dashboard/Dashboard';
import EditOrder from '../EditOrder';
import  KeepSake  from '../KeepSake';
class Order extends Component {
    render() {
        return (
            <React.Fragment>
                <Switch>
                    <Route path='/new-order' component={NewOrder} />
                    <Route path='/keep_sake' component={KeepSake} />
                    <Route path={`/edit_order/:id`} component={EditOrder} />
                </Switch>
            </React.Fragment>
        )
    }
}

export default Dashboard(Order);
