import React, { Component } from "react";
import { Switch, Redirect } from "react-router-dom";
import Header from "components/Admin/Header/Header";
import Footer from "components/Admin/Footer/Footer";
import Sidebar from "components/Admin/Sidebar/Sidebar";
import { s3StaticPath } from 'helper/helperFunctions';
import dashboardRoutes from "routes/dashboard.jsx";
import { AdminAuthRoute } from 'helper/PrivateRouteAdmin';
import { alertActions } from 'redux/actions/alert-actions';
import { showLoader } from 'redux/actions/settings-actions';
import { connect } from 'react-redux';
import MessageCom from "layouts/Dashboard/Message";
import { history } from 'helper/history';
import { loadProgressBar } from 'axios-progress-bar';
import ErrorBoundary from "layouts/Dashboard/ErrorBoundary"
import 'axios-progress-bar/dist/nprogress.css';
import "bootstrap/dist/css/bootstrap.min.css";
import "assets/css/animate.min.css";
import "assets/css/gen_classes.css";
import "assets/sass/light-bootstrap-dashboard.css?v=1.2.0";
import "assets/css/demo.css";
import "assets/css/pe-icon-7-stroke.css";
import "assets/fonts/robotoFont.css";
import $ from 'jquery';
// import MyImage from 'components/Upload/MyImage';

class DashboardPage extends Component {
  constructor(props) {
    super(props);
    // const { dispatch } = this.props;
    this.props.showLoaderCall(true);
    this.state = {
      canvasMenuToggle: false
    }
    // dispatch(showLoader(true));
    history.listen((location, action) => {
      this.props.alertActionCall();
    });
    this.closeSideBarMenu = this.closeSideBarMenu.bind(this);
    this.showCanvasMenu = this.showCanvasMenu.bind(this);
  }
  closeSideBarMenu() {
    this.setState({canvasMenuToggle:false}, function(){
      $('.sidebar').removeClass('showSideNav');
      // var sub_height = $(".sidebar").outerWidth();
      $(".main-panel").css({'width':'calc(100% - 50px)'});
    });
  }
  showCanvasMenu(){
    this.setState({canvasMenuToggle:true}, function(){
      $('.sidebar').addClass('showSideNav');
      setTimeout(function () {
        var sub_height = $(".sidebar").outerWidth();
        $(".main-panel").css({'width':'calc(100% - '+sub_height+'px)'});
      }, 100);
    });
  }

  render() {
    const { showLoader } = this.props;
    // console.log('showLoader', showLoader);
    return (
      <div className="wrapper">
        <Sidebar {...this.props} hideSideNav={this.closeSideBarMenu} showSideNav={this.showCanvasMenu} />
        <div id="main-panel" className="main-panel" ref="mainPanel">
          <Header {...this.props} ref="child" />
          <div>

            <MessageCom  />
            {
              loadProgressBar({showSpinner:showLoader})
            }
          </div>
          {
            this.props.location.pathname !== '/Admin/dashboard' &&
            <div className='text-left backbutton'>
              <button onClick={() => {
                if(this.props.location.hash){
                  this.props.history.replace(`/Admin/${this.props.location.hash.replace('#','')}/order-edit`)
                }else{
                 this.props.history.goBack()
                }
                 }} className='backButton pt-sm no_radius pb-sm primary text-xs btn btn-sm btn-info' >
                <img src={s3StaticPath('img/arrow_down.png')} alt="Arrow" width="15" />
              </button>
            </div>
          }
          <ErrorBoundary>
            <Switch>
              {
                dashboardRoutes.map((prop, key) => {
                  if (prop.redirect) {
                    return <Redirect from={prop.path} to={prop.to} key={key}/>;
                  }
                  return (
                    <AdminAuthRoute path={prop.path} component={prop.component} key={key} />
                  );
                })
              }
            </Switch>
          </ErrorBoundary>
          <Footer />
        </div>
      </div>

    );
  }
}


function mapStateToProps(state) {
  const { alert } = state;
  const { showLoader } = state.settingsReducer;
  const typeOfAlert = typeof alert.message;
  // console.log('showLoader', showLoader);
  return {
    alert,
    typeOfAlert,
    showLoader
  };
}

const mapDispatchToProps = dispatch => {
  return ({
    showLoaderCall: (value) => { dispatch(showLoader(value)) },
    alertActionCall: () => { dispatch( alertActions.clear() ) },

  });
};

const Dashboard = connect(mapStateToProps, mapDispatchToProps)(DashboardPage);

export default Dashboard;
