import { adminConstants } from "../constant/admin-constant";
import { shippingService } from "../../services/shippingMethod";

export function getAllShippingMethods() {
    return dispatch => {
        return shippingService.getAllShippingMethods().then(
            response => {
                dispatch(success(response))
                return response;
            }, error => {
                dispatch(failure(error))
            }
        )
    }
    function success(response) { return { type: adminConstants.GET_ALL_SHIPPING_METHODS_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.GET_ALL_SHIPPING_METHODS_FAILURE, payload: error } }
}