import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Grid,
  Row,
  Col,
  // Tooltip,
  OverlayTrigger,
  ButtonToolbar,
  Popover,
  // Button,
  Table,
} from "react-bootstrap";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Card } from "components/Card/Card.jsx";
import { getAgencyResourcesPagination, getAgencyResourcesTypes, storeAgencyResource, editAgencyResource, deleteAgencyResource } from 'redux/actions/agency-resource-actions';
import { changePermission } from 'redux/actions/settings-actions';
// import { roleActions } from '../../../redux/actions/role-actions';
import AgencyResourceForm from "./AgencyResourceForm";
// import { FormInputs } from "components/FormInputs/FormInputs.jsx";
import { hasPermission } from 'helper/hasPermission';
import { helperFunctions as HF } from 'helper/helperFunctions';
import Confirmalertfordelete from 'components/ThemeComponents/confirmAlertForDelete';
import { adminLabels } from 'redux/constant/admin-label-constant';
import SearchInput, { createFilter } from 'react-search-input';
import renderHTML from 'react-render-html';
import OpenModalButton from 'components/ThemeComponents/openModelButton';
import DownloadFile from 'components/ThemeComponents/downloadFile';
// import { Link } from 'react-router-dom';
var moment = require("moment");
var Modal = require('react-bootstrap-modal');
var isEqual = require('lodash.isequal');
const KEYS_TO_FILTERS = ["title", "resource_type_templates", "agency_resources.file_title", "guidelines", "agency_resources.updated_at"];


const initialState = {
  addModalAgencyResource: false,
  editModalAgencyResource: false,
  resource: {
    agency_id: null,
    resource_type_id: null,
    file: null,
    file_name: null,
    file_size: null,
    file_extension: null,
  },
  resourceTypeSelectionOption: null, //selected resource type option for edit form
  resourceInfo: null,//resource info for selected resource type for edit form
  resourcesTypesYetToAddOptions: [], //resource type options yet to be add for add form
  resourceAllDataYetToAdd: [],//resource type options data to be shown in add form
  agency_id: null,
  searchTerm: '',
  currentAction: null,
  allowedFormatsStringMime: null,
  allowedFormatsStringExt: null
};

class AgencyResourceListPage extends Component {
  constructor(props) {
    super(props);
    const { id } = this.props.match.params;
    this.state = {
      ...initialState,
      agencyResourcesData: this.props.agencyResourcesData,
      resource: {
        ...initialState.resource, agency_id: id
      },
      agency_id: id
    };

    this.clearField = this.clearField.bind(this);

    this.updateStatusAgencyResourceModal = this.updateStatusAgencyResourceModal.bind(this);

    this.openModalAgencyResource = this.openModalAgencyResource.bind(this);
    this.openModalAgencyResourceEdit = this.openModalAgencyResourceEdit.bind(this);

    this.handleAgencyResourceSubmitPar = this.handleAgencyResourceSubmitPar.bind(this);

    this.deleteAgencyResource = this.deleteAgencyResource.bind(this);
    this.deleteConfirmAgencyResource = this.deleteConfirmAgencyResource.bind(this);

    this.searchUpdated = this.searchUpdated.bind(this);

    this.props.getAgencyResourcesCall(this.state.agency_id);
    this.props.getAgencyResourcesTypesCall(this.state.agency_id);
  };

  clearField() {
    this.setState({
      ...initialState,
      resource: {
        ...initialState.resource, agency_id: this.state.agency_id
      },
      agency_id: this.state.agency_id,
      agencyResourcesData: this.state.agencyResourcesData
    });
  }
  updateStatusAgencyResourceModal(type, status) {
    if (!status) {
      this.clearField();
    }
    var obj = {}
    obj[type + 'ModalAgencyResource'] = status;
    this.setState(obj);
  }
  openModalAgencyResource() {
    this.setState({ currentAction: 'add' }, function () {
      this.props.getAgencyResourcesTypesCall(this.state.agency_id);
    });
  }
  openModalAgencyResourceEdit(slug) {
    this.setState({ currentAction: 'edit' }, function () {

      var resourceKey = HF.returnKeyWithColumnMatch('slug', this.props.agencyResourcesData.resource_types, slug);
      var selectedResource = this.props.agencyResourcesData.resource_types[parseInt(resourceKey, 10)];

      var formatArrayMime = HF.returnFormats(selectedResource.resource_formats, 'mime_type');
      var formatArrayExt = HF.returnFormats(selectedResource.resource_formats, 'extension');
      console.log('selected resource', selectedResource)
      this.setState({
        resource:
        {
          agency_id: this.state.agency_id,
          resource_type_id: selectedResource['id'],
          file: null,
          name: selectedResource.agency_resources['file_title'],
          size: null,
          extension: null,
          type: selectedResource.agency_resources['mime_type'],
          preview: selectedResource.agency_resources['file_path'],
        },
        resourceInfo: selectedResource,
        allowedFormatsStringMime: formatArrayMime.join(", "),
        allowedFormatsStringExt: formatArrayExt.join(", "),
      }, function () {
        this.setState({ resourceTypeSelectionOption: { value: selectedResource['id'], label: selectedResource['title'] } }, function () {
          this.setState({ editModalAgencyResource: true });
        });
      });

    });
  }
  handleAgencyResourceSubmitPar(resourceData, type) {
    if (type === 'add')
      this.props.storeAgencyResourceCall(this.state.agency_id, resourceData);
    else if (type === 'edit')
      this.props.editAgencyResourceCall(this.state.agency_id, resourceData);
  }
  deleteAgencyResource(resourceId) {
    this.props.deleteAgencyResourceCall(this.state.agency_id, resourceId);
  }
  deleteConfirmAgencyResource(resourceId) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <Confirmalertfordelete typeName="Agency Resource" description="Are you sure to delete the admin agency resource?" onClosePro={onClose} deleteType={() => this.deleteAgencyResource(resourceId)} />
        )
      }
    })
  }
  searchUpdated(term) {
    this.setState({ searchTerm: term });
  }

  componentDidUpdate(prevProps, nextProps) {
    if ((prevProps.storedAgencyResource !== this.props.storedAgencyResource) && !this.props.loadingAgencyResource) {
      this.props.getAgencyResourcesCall(this.state.agency_id);
    }

    if ((prevProps.updatedAgencyResource !== this.props.updatedAgencyResource) && !this.props.loadingAgencyResource) {
      console.log('update close');
      this.props.getAgencyResourcesCall(this.state.agency_id);
    }

    if ((prevProps.agencyResourcesData !== this.props.agencyResourcesData) && !this.props.loadingAgencyResource && !isEqual(this.state.agencyResourcesData, this.props.agencyResourcesData)) {
      this.setState({ agencyResourcesData: this.props.agencyResourcesData }, function () {
        this.updateStatusAgencyResourceModal(this.state.currentAction, false);
      });

    }

    if ((prevProps.agencyResourceTypes !== this.props.agencyResourceTypes) && !this.props.loadingAgencyResource) {
      this.setState({ resourcesTypesYetToAddOptions: this.props.agencyResourceTypes.options }, function () {
        this.setState({ resourceAllDataYetToAdd: this.props.agencyResourceTypes.data }, function () {
          this.setState({ resourceTypeSelectionOption: null }, function () {
            this.updateStatusAgencyResourceModal(this.state.currentAction, true);
          });
        })
      });
    }

    if ((prevProps.deleteAgencyResource !== this.props.deleteAgencyResource) && !this.props.loadingAgencyUser) {
      this.props.getAgencyResourcesCall(this.state.agency_id);
    }
  }

  changePermission = () => {
    this.props.changePermissionCall({permissionType:'agencyResourceUpdate', agency_id:this.state.resource.agency_id})
  }

  render() {
    const { agencyResourcesData, resource, resourceTypeSelectionOption, resourcesTypesYetToAddOptions, resourceAllDataYetToAdd, addModalAgencyResource, editModalAgencyResource, resourceInfo, allowedFormatsStringMime, allowedFormatsStringExt, currentAction } = this.state;
    const { loadingAgencyResource } = this.props;
    const filteredResources = agencyResourcesData.resource_types.filter(createFilter(this.state.searchTerm, KEYS_TO_FILTERS));
    return (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                ctTableResponsive
                content={
                  <div>
                    <Grid fluid>
                      <Row>
                        <Col md={12}>
                          <div>


                            <div className="secHeading pull-left">{
                              agencyResourcesData.agency.name
                            }</div>
                          </div>
                          <span className='clearfix' ></span>
                        </Col>
                        <Col md={12}>
                          <div className="flexElem flexResponsive" style={{ "alignItems": "center", "justifyContent": "space-between" }}>
                            <div>
                              <OpenModalButton
                                openModal={hasPermission('agency-resource-create') ? this.openModalAgencyResource : null}
                                classButton={['backButton pt-sm no_radius btn-block pb-sm primary text-xs btn btn-lg btn-info btn btn-lg btn-info  btn btn-default', !hasPermission('agency-resource-create') ? 'button_disabled' : '']}
                                buttonName="Add Agency Resource"
                                tooltipText={hasPermission('agency-resource-create') ? 'Add Agency Resource' : adminLabels.DONT_ALLOW}
                                classIcon={['fa', 'fa-plus']}
                              />
                              {
                                /*
                              <OverlayTrigger placement="bottom" overlay={<Popover id="tooltip">Make Agency Resources Public</Popover>} >
                                <button type='button' className="backButton pt-sm no_radius btn-block pb-sm primary text-xs btn btn-lg btn-info btn btn-lg btn-info  btn btn-default" onClick={()=>this.changePermission()} >Public Resources</button>
                              </OverlayTrigger>
                              */
                              }
                            </div>
                            <div className="custom-search-input">
                              <SearchInput fuzzy={true} className="search-input form-control" placeholder="Search Agency Resources" onChange={this.searchUpdated} />
                            </div>
                          </div>
                        </Col>

                        <Col md={12} className="mt-lg">
                          <Table striped hover responsive className="customTable tableLayoutFix">
                            <thead>
                              <tr>
                                <th width="10%">File Type</th>
                                <th width="25%">Template File</th>
                                <th width="25%">File Name</th>
                                <th width="25%">Guideline</th>
                                <th width="10%">Date Modified</th>
                                <th width="5%">Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                filteredResources.map((prop, key) => {
                                  return (
                                    <tr key={prop.id} >
                                      <td>{prop.title}</td>
                                      <td>
                                        {
                                          prop.resource_type_templates != null &&
                                          prop.resource_type_templates.map((innerProp, key) => {
                                            return (
                                              <div key={'resource_file_type_' + innerProp.id} >
                                                <a className="customLink text-primary" href={innerProp.file_path} download>
                                                  {innerProp.file_title}
                                                </a>
                                              </div>
                                            );
                                          })
                                        }
                                      </td>
                                      <td>
                                        {/* {prop.agency_resources && prop.agency_resources.file_title} */}
                                        <DownloadFile multiple={false} title={prop.agency_resources.file_title} files={{
                                          'file_title': prop.agency_resources.file_title,
                                          'file_path': prop.agency_resources.file_path
                                        }} />
                                      </td>
                                      <td style={{ "lineHeight": "1.5" }}>{renderHTML(prop.guidelines)}</td>
                                      <td>{prop.agency_resources && moment(moment.utc(prop.agency_resources.updated_at).toDate()).local().format('MMM D YYYY LT')}</td>
                                      <td>
                                        <ButtonToolbar>
                                          <OverlayTrigger trigger="focus" placement="bottom" overlay={
                                            <Popover id="tooltip">
                                              <table className="table text-left actions_table">
                                                <tbody>
                                                  <tr>
                                                    <td>
                                                      {
                                                        hasPermission('agency-resource-edit') &&
                                                        <button type='button' className="btn_link action-button-size" onClick={() => this.openModalAgencyResourceEdit(prop.slug)}><i className="fa fa-pencil" />Edit</button>
                                                      }

                                                      {
                                                        !hasPermission('agency-resource-edit') &&
                                                        <button type='button' className="btn_link button_disabled" ><i className="fa fa-pencil" />Edit</button>
                                                      }
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td>
                                                      {
                                                        hasPermission('agency-resource-delete') &&
                                                        <button type='button' className="btn_link action-button-size" onClick={() => this.deleteConfirmAgencyResource(prop.id)}><i className="fa fa-trash text-danger" />Delete</button>
                                                      }

                                                      {
                                                        !hasPermission('agency-resource-delete') &&
                                                        <button type='button' className="btn_link button_disabled" ><i className="fa fa-trash text-danger" />Delete</button>
                                                      }
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </Popover>
                                          }>
                                            <span>
                                              <button type='button' className="action-button-size" ><i className="fa fa-bars" /></button>
                                            </span>
                                          </OverlayTrigger>
                                        </ButtonToolbar>
                                      </td>
                                    </tr>
                                  );
                                })
                              }
                            </tbody>
                          </Table>
                        </Col>
                      </Row>
                    </Grid>

                    {/*Add AgencyResource Modal Start*/}
                    <Modal backdrop={'static'} show={addModalAgencyResource} onHide={() => this.updateStatusAgencyResourceModal('add', false)} aria-labelledby="ModalHeader" >
                      <Modal.Header closeButton>
                        <Modal.Title id='ModalHeader' className="headerTitle">Add Agency Resource</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <div className="row">
                          <div className="col-md-12" >
                            <Card bsClass={['innerCard mb-none']} content={
                              <AgencyResourceForm
                                handleAgencyResourceSubmitParPro={this.handleAgencyResourceSubmitPar}
                                formTypePro={currentAction}
                                resourcesTypesYetToAddOptionsPro={resourcesTypesYetToAddOptions}
                                resourceAllDataYetToAddPro={resourceAllDataYetToAdd}
                                updateStatusAgencyResourceModalPro={this.updateStatusAgencyResourceModal}
                                loadingAgencyResource={loadingAgencyResource}
                              />
                            } />
                          </div>
                        </div>
                      </Modal.Body>
                    </Modal>
                    {/*Add AgencyResource Modal End*/}

                    {/*Edit AgencyResource Modal start*/}
                    <Modal backdrop={'static'} show={editModalAgencyResource} onHide={() => this.updateStatusAgencyResourceModal('edit', false)} aria-labelledby="ModalHeader" >
                      <Modal.Header closeButton>
                        <Modal.Title id='ModalHeader' className="headerTitle">Edit Agency Resource</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <div className="row">
                          <div className="col-md-12">
                            <Card bsClass={['innerCard mb-none']} content={
                              <AgencyResourceForm
                                resourcePro={resource}
                                handleAgencyResourceSubmitParPro={this.handleAgencyResourceSubmitPar}
                                formTypePro={currentAction}
                                resourcesTypesYetToAddOptionsPro={resourcesTypesYetToAddOptions}
                                resourceTypeSelectionOptionPro={resourceTypeSelectionOption}
                                resourceAllDataYetToAddPro={resourceAllDataYetToAdd}
                                updateStatusAgencyResourceModalPro={this.updateStatusAgencyResourceModal}
                                resourceInfoPro={resourceInfo}
                                allowedFormatsStringMimePro={allowedFormatsStringMime}
                                allowedFormatsStringExtPro={allowedFormatsStringExt}
                                loadingAgencyResource={loadingAgencyResource}
                              />
                            } />
                          </div>
                        </div>
                      </Modal.Body>
                    </Modal>
                    {/*Edit AgencyResource Modal End*/}
                  </div>
                } />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  };
}

const mapDispatchToProps = dispatch => {
  return ({
    getAgencyResourcesCall: (agency_id) => { dispatch(getAgencyResourcesPagination(agency_id)) },
    getAgencyResourcesTypesCall: (agency_id) => { dispatch(getAgencyResourcesTypes(agency_id)) },
    // getAgencyResourceCall   : (agency_id, id) => { dispatch(getAgencyResource(agency_id, id)) },
    storeAgencyResourceCall: (agencyId, resourceData) => { dispatch(storeAgencyResource(agencyId, resourceData)) },
    editAgencyResourceCall: (agencyId, resourceData) => { dispatch(editAgencyResource(agencyId, resourceData)) },
    deleteAgencyResourceCall: (agency_id, id) => { dispatch(deleteAgencyResource(agency_id, id)) },
    changePermissionCall:(data) => { dispatch(changePermission(data)) }
    // getAllRolesCall : ()=> { dispatch(roleActions.getAgencyRoles()) },
  });
};

function mapStateToProps(state) {
  const { editAgencyResource, loadingAgencyResource, agencyResourcesData, storedAgencyResource, updatedAgencyResource, deleteAgencyResource, agencyResourceTypes } = state.agencyResourceReducer;
  const { agencyRoles, loadingRole } = state.rolesReducer;
  return {
    editAgencyResource, loadingAgencyResource, agencyResourcesData, storedAgencyResource, updatedAgencyResource, deleteAgencyResource, agencyResourceTypes, agencyRoles, loadingRole
  };
}
const AgencyResources = connect(mapStateToProps, mapDispatchToProps)(AgencyResourceListPage);
export default AgencyResources;
