import { adminConstants } from '../constant/admin-constant';

const initialState = {loadingProShowFile: false, proShowFileData:[], proShowFileOptions:[], proShowFileDestination: []};
export function proShowFileReducer(state = initialState, action){

	switch(action.type){


		case adminConstants.PAGINATE_PRO_SHOW_FILE_REQUEST:
			return { ...state, loadingProShowFile:true}
		case adminConstants.PAGINATE_PRO_SHOW_FILE_SUCCESS:
			// return { ...state, loadingProShowFile:false, proShowFileData: action.payload.data.pagination.data, pages:action.payload.data.pagination.last_page, current_page:action.payload.data.pagination.current_page };
			return {
                ...state,
                loadingProShowFile:false,
                proShowFileData: action.payload && action.payload.data && action.payload.data.pagination && action.payload.data.pagination.data ? action.payload.data.pagination.data : initialState.proShowFileData,
                pages: action.payload && action.payload.data && action.payload.data.pagination && action.payload.data.pagination.last_page && action.payload.data.pagination.last_page,
                current_page: action.payload && action.payload.data && action.payload.data.pagination && action.payload.data.pagination.current_page && action.payload.data.pagination.current_page
            };
		case adminConstants.PAGINATE_PRO_SHOW_FILE_FAILURE:
			return { ...state, loadingProShowFile:false, error:action.payload };

		case adminConstants.PRO_SHOW_FILE_OPTIONS_REQUEST:
			return { ...state, loadingProShowFile:true}
		case adminConstants.PRO_SHOW_FILE_OPTIONS_SUCCESS:
			// return { ...state, loadingProShowFile:false, proShowFileData: action.payload.data.pagination.data, pages:action.payload.data.pagination.last_page, current_page:action.payload.data.pagination.current_page };
			return {
                ...state,
                loadingProShowFile:false,
                proShowFileOptions: action.payload && action.payload.data && action.payload.data.pagination ? action.payload.data.pagination : initialState.proShowFileData,
                };
		case adminConstants.PRO_SHOW_FILE_OPTIONS_FAILURE:
			return { ...state, loadingProShowFile:false, error:action.payload };

		case adminConstants.GET_PRO_SHOW_FILE_DESTINATION_REQUEST:
			return { ...state, loadingProShowFile:true}
		case adminConstants.GET_PRO_SHOW_FILE_DESTINATION_SUCCESS:
			console.log("resucer payload", action.payload);
			return {
                ...state,
                proShowFileDestination: action.payload && action.payload.getDestinations ? action.payload.getDestinations : initialState.proShowFileDestination
                // proShowFileDestination: action.payload && action.payload.data && action.payload.data.pagination && action.payload.data.pagination.data ? action.payload.data.pagination.data : initialState.proShowFileDestination
            };
		case adminConstants.GET_PRO_SHOW_FILE_DESTINATION_FAILURE:
			return { ...state, loadingProShowFile:false, error:action.payload };

		case adminConstants.STORE_PRO_SHOW_FILE_REQUEST:
			return { ...state, loadingProShowFile:true}
		case adminConstants.STORE_PRO_SHOW_FILE_SUCCESS:
			return { ...state, loadingProShowFile:false, proShowFileStored: action.payload.proShowFileStored};
		case adminConstants.STORE_PRO_SHOW_FILE_FAILURE:
			return { ...state, loadingProShowFile:false, error:action.payload };

		case adminConstants.GET_PRO_SHOW_FILE_REQUEST:
			return { ...state, loadingProShowFile:true};
		case adminConstants.GET_PRO_SHOW_FILE_SUCCESS:
			return { ...state, loadingProShowFile:false, proShowFileShow : action.payload.proShowFile };
		case adminConstants.GET_PRO_SHOW_FILE_FAILURE:
			return { ...state, loadingProShowFile:false, error : action.payload };

		case adminConstants.UPDATE_PRO_SHOW_FILE_REQUEST:
			return { ...state, loadingProShowFile:true}
		case adminConstants.UPDATE_PRO_SHOW_FILE_SUCCESS:
			return { ...state, loadingProShowFile:false, proShowFileUpdated: action.payload.proShowFileUpdated};
		case adminConstants.UPDATE_PRO_SHOW_FILE_FAILURE:
			return { ...state, loadingProShowFile:false, error:action.payload };

		case adminConstants.DELETE_PRO_SHOW_FILE_REQUEST:
			return { ...state, loading:true}
		case adminConstants.DELETE_PRO_SHOW_FILE_SUCCESS:
			return { ...state, loading:false};
		case adminConstants.DELETE_PRO_SHOW_FILE_FAILURE:
			return { ...state, loading:false, error:action.payload };

		default:
			return { ...state };
	}
}
