import React, { Component } from 'react';
import { SC } from '../../helper/ServerCall';
import LazyLoad from 'react-lazyload'
class Clients extends Component {
    constructor(props) {
        super(props);
        this.state = {
            clients: []
        };
    }

    componentDidMount() {
        let that = this;
        let clients = SC.getCall('getClientLogos').then(res => {
            that.setState({
                clients: res.clients
            });
        });

    }

    render() {
        const { clients } = this.state;
        return (
            <React.Fragment>
                <section id="memberAffliate" className="section clients">
                    <div className="container mt-xlg">
                        <div className="row mb-xlg">
                            <div className="col-md-12 mb-xlg">
                                <h2 className="panel_heading">Our Clients</h2>
                            </div>

                            <div className="clearfix"></div>

                            <div className="gridElem grid_4 gridGap_25">
                                {
                                    clients.map(client => {
                                        return (
                                            <LazyLoad classNamePrefix='member' height={20}>
                                                <div className='text-center'>
                                                    <div className="memberLogo">
                                                        <img src={client.file_path} alt="Student Youth Travel Association" />
                                                    </div>
                                                    <p className="memberName mt-sm text_18">{client.agency_name.name}</p>
                                                    <div className="mt-xs">
                                                        <a style={{ wordWrap: 'break-word', wordBreak: 'break-all' }} href={client.agency_name.website} className="linkColor" target="_blank" rel="noopener noreferrer">{client.agency_name.website}</a>
                                                    </div>
                                                </div>
                                            </LazyLoad>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

export default Clients;