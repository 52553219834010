import React, { Component } from 'react';
import $ from 'jquery';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class Privacy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isHovered: false,
    };
    this.handleHover = this.handleHover.bind(this);
  }
  handleHover() {
    // this.setState(prevState => ({
    //     isHovered: !prevState.isHovered
    // }));

    $("#clients div.client").hover(function () {
      $("#clients div.client").removeClass("pink_gradient")
      $(this).addClass("pink_gradient")
    }, function () {
      $("#clients div.client").removeClass("pink_gradient")
    })
  }
  render() {
    // const hoverClass = this.state.isHovered ? "client pink_gradient" : "client";
    // let sliderSettings = {
    //   dots: true,
    //   autoplay: true,
    //   infinite: true,
    //   speed: 500,
    //   slidesToShow: 1,
    //   slidesToScroll: 1,
    //   accessibility: false,
    //   className: 'benefits',
    //   prevArrow: <div><MdArrowBack /></div>,
    //   nextArrow: <div><MdArrowForward /></div>,
    // };

    return (
        <section id="privacy" className="section">
            <div className="container mt-xlg">
                <div className="row mb-xlg">
                    <div className="col-md-12 mb-xlg">
                        <h2 className="panel_heading">Privacy Policy</h2>

                        <p className="text_18 text-muted dark mt-md">PhotoVision, Inc. is committed to protecting your privacy. The following privacy policy discloses what, how, and why the information we require in doing a transaction with you through our website for our services. By using our website or our services, you agree to be bound by the terms of this policy.</p>

                        <p className="text_22 mt-xxlg text-primary">
                            <strong className="semibold">Personal Information</strong>
                        </p>

                        <p className="text_16 mt-sm">
                            Personal information is information that is unique to you (such as your name, address, e-mail address, telephone number). We do not collect or retain your credit card information. We only collect personal information from you to offer you our service. Because protecting your information is important to us, we do not sell any personal information to third parties. This information is only available to certain employees who have a need to it in the execution of their job.
                        </p>

                        <p className="text_16 mt-md">
                            We may use independent contractors to provide services on our behalf. For example, we may use an e-commerce provider to handle your order. Such third parties may have access to your personal information in the course of providing services on our behalf. Any personal information that we provide to such third parties is protected under a confidentiality agreement. Such third parties will take commercially reasonable measures to keep your personal information safe, private and secure. Independent contractors who service our technical hardware and software requirements have no possible access to this information.
                        </p>

                        <p className="text_22 mt-xxlg text-primary">
                            <strong className="semibold">Information We Automatically Collect</strong>
                        </p>

                        <p className="text_16 mt-sm">
                            We automatically collect and store: the name of the domain and host from which you access the Internet; the Internet protocol (IP) address of the computer you are using; the browser software you use and your operating system; the date and time you access our sites; and the Internet address of the site from which you linked directly to our sites.
                        </p>

                        <p className="text_16 mt-md">
                            We use this information only as anonymous aggregate data to determine the number of visitors to different sections of our sites, to ensure the sites are working properly, and to help us make our sites more useful. We do not use it to track or record information about individuals. Generally, we delete this information after one year.
                        </p>

                        <p className="text_22 mt-xxlg text-primary">
                            <strong className="semibold">Cookies</strong>
                        </p>

                        <p className="text_16 mt-sm">
                            When you use our services as a registered user, we utilize cookies to store information about your visits to make your revisits more efficient for you and us. It is necessary to place the cookie on your computer's hard drive in order for us to do this. This is a common practice used by most websites. We do not sell or give this information to any outside parties. A "cookie" is a small text file that a Web site can place on your computer's hard drive in order, for example, to collect information about your activities on the site or to make it possible for you to use an online "shopping cart" to keep track of items you wish to purchase. The cookie transmits this information back to the Web site's computer which, generally speaking, is the only computer that can read it. Many consumers do not know that "cookies" are being placed on their computers when they visit Web sites. If you want to know when this happens, or to prevent it from happening, you can set your browser to warn you when a Web site attempts to place a "cookie" on your computer.
                        </p>

                        <p className="text_22 mt-xxlg text-primary">
                            <strong className="semibold">Security</strong>
                        </p>

                        <p className="text_16 mt-sm">
                            Protecting your information is very important to us, and we use commercially reasonable measures to provide secure transmission of your personal information to us. You should be aware that there is a level of risk involved in transmitting information over the Internet. As a result, we cannot ensure or warrant the security of the information that is transmitted over the Internet, and that you do so at your own risk.
                        </p>

                        <p className="text_22 mt-xxlg text-primary">
                            <strong className="semibold">Third Party Advertising</strong>
                        </p>

                        <p className="text_16 mt-sm">
                            Currently, PhotoVision does not work with third parties that provide advertisements to our site. This could change in the future, and if it does, a revised privacy policy will be issued.
                        </p>

                        <p className="text_22 mt-xxlg text-primary">
                            <strong className="semibold">External Links</strong>
                        </p>

                        <p className="text_16 mt-sm">
                            Our website may contain links to other sites for your convenience. In most cases, those sites are not under our control, and they have their own policies regarding privacy, which you should review before using them. We bear no responsibility for linked websites and provide these links solely for your convenience and information.
                        </p>

                        <p className="text_22 mt-xxlg text-primary">
                            <strong className="semibold">Use of Website by Children</strong>
                        </p>

                        <p className="text_16 mt-sm">
                            Our website is intended for the general audience and is not directed to children under the age of 13. Children under the age of 13 may not use our website. We respect the privacy of parents and children and are committed to complying with the Children's Online Privacy Protection Act (COPPA). If a parent believes that his or her child has submitted personal information to us, he or she can contact us via e-mail or postal mail as listed below. We will promptly delete the information upon learning that it relates to a child under the age of 13.
                        </p>

                        <p className="text_22 mt-xxlg text-primary">
                            <strong className="semibold">Contacting PhotoVision, Inc.</strong>
                        </p>

                        <p className="text_16 mt-sm">
                            If, for any reason, you are concerned with the way that we may be using your personal information, you would like to request that we cease using your personal information altogether, or you wish to file a complaint, you may contact PhotoVision, Inc. at 8300 Fairview Road Charlotte, NC 28227, <strong className="semibold">1-888-533-7637 x205</strong>. Our hours of operation are <strong className="semibold">9am to 5pm, Monday-Friday</strong>.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
  }
}

export default Privacy;