// import { alertActions } from "./alert-actions";
import { musicServices } from "../../services/music";
import { frontEndUserConstants } from "../constant/front-end-constant";

export function getAllMusic() {
    return dispatch => {
        musicServices.getAllMusic().then(
            res => {
                dispatch(success(res));
            },
            err => {
                dispatch(failure(err))
            }
        )
    }
    function success(response) { return { type: frontEndUserConstants.GET_ALL_MUSIC_SUCCESS, payload: response } }
    function failure(error) { return { type: frontEndUserConstants.GET_ALL_MUSIC_FAILURE, payload: error } }
}
