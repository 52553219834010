import React, { Component } from 'react';
// import $ from 'jquery';
import { Link } from 'react-router-dom';
import { s3StaticPath } from '../../helper/helperFunctions';
import ScrollAnimation from 'react-animate-on-scroll';
// import { Parallax, Background } from 'react-parallax';
import "./../../assets/css/animate.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";




class TourOperator extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isHovered: false,
        };
    }

    render() {
        return (
            <main id="tour_operator">
                <section className="banner">
                    <div className="text-center">
                        <div className="inlineFlexElem justifyCenter alignCenter phoneScreen">
                            <div className="">
                                <h1>Stand Out with a Custom Branded App</h1>
                            </div>
                            <div>
                                <img src={s3StaticPath('img/operator/phoneBanner.png')} alt="Phone Banner" style={{marginLeft: '42px'}}/>
                            </div>
                        </div>
                    </div>

                    <div className="bgVideo">
                        <video width={window.screen.availWidth} src={s3StaticPath('img/operator/bgVideo.mp4')} autoPlay muted loop type="video/mp4" className="video"></video>
                    </div>
                </section>

                <section className="moreThan">
                    <h2 className="wordpressHeading text-center">We're more than a Keepsake Video.</h2>
                    <div className="wordpressHeading subHeading text-center">Explore the benefits...</div>

                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xs-3">
                                <div>
                                    <div className="text-center"><img src={s3StaticPath('img/operator/marketing.png')} alt="Marketing" /></div>
                                    <div className="blockHeading text_left_center_767">Marketing</div>
                                    <p>Increase your website traffic, promote contests on your social media, and enjoy an almost unlimited supply of digital assets. Use images and video for content creation and so much more.</p>
                                </div>
                            </div>

                            <div className="col-xs-3">
                                <div>
                                    <div className="text-center"><img src={s3StaticPath('img/operator/recruiting.png')} alt="Recruiting" /></div>
                                    <div className="blockHeading text_left_center_767">Recruiting</div>
                                    <p>Many group leaders will show last year's keepsake video at this year's pre trip meetings, resulting in larger participation for the upcoming trip. Proven to work for 20+ years.</p>
                                </div>
                            </div>

                            <div className="col-xs-3">
                                <div>
                                    <div className="text-center"><img src={s3StaticPath('img/operator/organized.png')} alt="Organization" /></div>
                                    <div className="blockHeading text_left_center_767">Organization</div>
                                    <p>Organization is a key element when taking a group on a trip. The Group Memories app helps you keep everything in one place, and makes sharing information with your travelers' easy.</p>
                                </div>
                            </div>

                            <div className="col-xs-3">
                                <div>
                                    <div className="text-center"><img src={s3StaticPath('img/operator/communication.png')} alt="Communication" /></div>
                                    <div className="blockHeading text_left_center_767">Communication</div>
                                    <p>Share important documents such as itineraries, emergency contact lists, and rules to follow. All without requiring email addresses or phone numbers from anyone in the group.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xs-3">
                                <div>
                                    <div className="text-center"><img src={s3StaticPath('img/operator/brand.png')} alt="Branding" /></div>
                                    <div className="blockHeading text_left_center_767">Branding</div>
                                    <p>Your brand is on everything that your travelers will see with our program including the app, the keepsake video, and everything in between.</p>
                                </div>
                            </div>

                            <div className="col-xs-3">
                                <div>
                                    <div className="text-center"><img src={s3StaticPath('img/operator/education.png')} alt="Education" /></div>
                                    <div className="blockHeading text_left_center_767">Education</div>
                                    <p>Share educational material by including clickable links on documents shared from inside of the app. The opportunity to enrich your travelers in their experience is endless.</p>
                                </div>
                            </div>

                            <div className="col-xs-3">
                                <div>
                                    <div className="text-center"><img src={s3StaticPath('img/operator/safety.png')} alt="Safety" /></div>
                                    <div className="blockHeading text_left_center_767">Safety</div>
                                    <p>A safety tool with management features designed to deliver peace of mind for group leaders, parents, and you with our mapping feature that works only while your group travels.</p>
                                </div>
                            </div>

                            <div className="col-xs-3">
                                <div>
                                    <div className="text-center"><img src={s3StaticPath('img/operator/added_value.png')} alt="Added Value" /></div>
                                    <div className="blockHeading text_left_center_767">Added Value</div>
                                    <p>The app brings huge added value to your customers experience even after  the trip is finished. Helping your brand deliver a positive emotional experience that will bring your customers back to you for years to come.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="subFooter contact">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="flexElem text-center alignCenter">
                                    <div>
                                        Questions?
                                </div>

                                    <div>
                                        <Link to="/Contact_Us" target="_blank" className="wordpressButton">
                                            Give Us a Call
                                    </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="postTrip flexElem flexResponsive justifyCenter alignCenter">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-10 col-md-offset-1">
                                <ScrollAnimation animateIn="fadeInLeft" animateOnce={true}>
                                    <div className="block">
                                        <h3>Post Trip Gratification</h3>
                                        <p>With the keepsake video, families can relive the traveler's experience together... sparking enriched conversation, curiosity, and re-enforcing the magical memories of their trip.</p>
                                    </div>
                                </ScrollAnimation>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="inspire">
                    <div className="">
                        <div className="container">
                            <div className="row flexElem alignCenter">
                                <div className="col-xs-5 text_left_center_991 mt-xlg">
                                    <img src={s3StaticPath('img/operator/presentation.jpeg')} alt="Increase Your Group Sizes" />
                                </div>
                                <div className="col-xs-7 mt-xlg pb-xlg pt-xlg">
                                    <div className="wordpressHeading">Increase Your Group Sizes</div>
                                    <p>
                                        Show a previous video at pre-trip meetings and watch how it generates excitement for future trips.
                                </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="inspire stregthen">
                    <div className="">
                        <div className="container">
                            <div className="row flexElem alignCenter">
                                <div className="col-xs-5 text_left_center_991 mt-xlg">
                                    <img src={s3StaticPath('img/operator/relationship.jpeg')} alt="Strengthen Your Relationships" />
                                </div>
                                <div className="col-xs-7 mt-xlg">
                                    <div className="wordpressHeading">Strengthen Your Relationships</div>
                                    <p>
                                        Relationships with teachers, administrators, parents, and your travelers are all important. Our program helps strengthen these relationships today and for the future.
                                </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="inspire marketing" style={{ backgroundColor: '#f9f9f9' }}>
                    <div className="">
                        <div className="container">
                            <div className="row flexElem alignCenter">
                                <div className="col-xs-5 text_left_center_991 mt-xlg">
                                    <img src={s3StaticPath('img/operator/marketing.jpeg')} alt="Marketing Resources" />
                                </div>
                                <div className="col-xs-7 mt-xlg">
                                    <div className="wordpressHeading market">Marketing Resources</div>
                                    <p>
                                        Did we make a video for your group last year? If so, show the video at your pre-trip meetings to generate excitement & participation. When they see people they know in the video, it will encourage trip enrollment. The video also helps reinforce the educational reason for the trip.
                                </p>

                                    <div className="mt-lg">
                                        <a href="#!" className="wordpressButton">Download Free Guide</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="yourBrand">
                    <h4 className="wordpressHeading text-center">
                        <div><span>We Include Your Brand</span> On Everything</div>
                    </h4>

                    <div className="brandingImages">
                        <img src={s3StaticPath('img/operator/to_branding_info_packet.png')} alt="Info Packet Branding" />
                        <img src={s3StaticPath('img/operator/to_branding_app_card.png')} alt="App Card Branding" />
                        <img src={s3StaticPath('img/operator/to_branding_app.png')} alt="App Branding" />
                        <br />&nbsp;
                    <img src={s3StaticPath('img/operator/to_branding_dvd_beginning.png')} alt="DVD Begin Branding" />
                        <img src={s3StaticPath('img/operator/to_branding_DVD_End.png')} alt="DVD End Branding" />
                        <img src={s3StaticPath('img/operator/to_branding_pw_slate.png')} alt="PW Slate Branding" />
                        <img src={s3StaticPath('img/operator/to_branding_watermark.png')} alt="Watermark Branding" />
                    </div>
                </section>

                <section className="subFooter pt-md pb-md mt-xlg" style={{ backgroundColor: '#f9f9f9' }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="flexElem text-center flexResponsiveSM alignCenter" style={{ minHeight: '20px' }}>
                                    <div>
                                        See For Yourself
                                </div>

                                    <div>
                                        <Link to="https://calendly.com/grouptravelvideos" target="_blank" className="wordpressButton">
                                            Schedule a Live Demo
                                    </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="inspire">
                    <div className="">
                        <div className="container">
                            <div className="row flexElem alignCenter">
                                <div className="col-xs-5 mt-xlg">
                                    <img style={{ transform: 'rotateY(180deg)' }} src={s3StaticPath('img/operator/music_director.jpeg')} alt="Include Performance Music" />
                                </div>
                                <div className="col-xs-7 mt-xlg pt-lg pb-lg">
                                    <div className="includePerformance">
                                        <div className="wordpressHeading">Include Performance Music</div>
                                        <p>
                                            Add any groups pre-recorded audio tracks or the actual footage from their performance into your keepsake video for no additional charge.
                                    </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="inspire leaderGift">
                    <div className="">
                        <div className="container">
                            <div className="row flexElem alignCenter">
                                <div className="col-xs-6 text_left_center_991 mt-xlg">
                                    <img src={s3StaticPath('img/operator/mozaic_image.png')} alt="Give the Perfect Gift to Group Leaders" />
                                </div>
                                <div className="col-xs-6 mt-xlg">
                                    <div className="row">
                                        <div className="col-md-11">
                                            <div className="wordpressHeading">
                                                Give the Perfect Gift <span className="lighter">to Group Leaders</span>
                                            </div>
                                        </div>
                                    </div>
                                    <p>
                                        PhotoMozaix are a custom made work of art created from your group's pictures. They can be added to any of your orders which makes it an easy, affordable, and truly unique way of saying thanks.
                                </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="ourClients">
                    <div className="text-center">
                        <a href="#" className="link">We work with over 200 Tour Operators</a>
                    </div>
                    <div className="text-center">
                        and are proud members of
                </div>

                    <div className="container">
                        <div className="row">
                            <div className="col-md-8 col-md-offset-2">
                                <div className="flexElem">
                                    <div><img className="mt-lg" src={s3StaticPath('img/home/nta-transparent.png')} alt="NTA" /></div>
                                    <div><img className="mt-lg" src={s3StaticPath('img/home/SYTA-Transparent.png')} alt="SYTA" /></div>
                                    <div><img className="mt-lg" src={s3StaticPath('img/home/ABA-Transparent.png')} alt="ABA" /></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        );
    }
}

export default TourOperator;
