import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Grid,
  Row,
  Col,
  OverlayTrigger,
  Popover
} from "react-bootstrap";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Card } from "components/Card/Card.jsx";
import { hasPermission } from 'helper/hasPermission';
import { getPermissionsPagination, getPermission, storePermission, editPermission, deletePermission, getAllParentLabel, getAlreadyExistingOrders } from 'redux/actions/permission-action';
import { getAllModules } from "redux/actions/module-actions";
import 'react-table/react-table.css';
import PermissionForm from "components/Admin/Permissions/PermissionForm";
import Confirmalertfordelete from 'components/ThemeComponents/confirmAlertForDelete';
import { adminLabels } from 'redux/constant/admin-label-constant';
import OpenModalButton from 'components/ThemeComponents/openModelButton';
import { openModal, closeModal } from 'helper/helperFunctions';
import { objectsConstants as OC } from 'redux/constant/objects-constant';
import Pagination from '../../ThemeComponents/Pagination';
var Modal = require('react-bootstrap-modal');

class PermissionsListPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addModalPermission: this.props.addModalPermission,
      editModalPermission: this.props.editModalPermission,
      permission: OC.PERMISSION,
      sorted: '',
      filtered: '',
      pageSize: 10,
      optionsParent: null,
      optionsOrder: null,
      optionsType: [{ value: 'url', label: 'URL' }, { value: 'label', label: 'LABEL' }],
    };
    this.openModalPermissionEdit = this.openModalPermissionEdit.bind(this);

    this.handlePermissionSubmit = this.handlePermissionSubmit.bind(this);

    this.deletePermission = this.deletePermission.bind(this);
    this.deleteConfirmPermission = this.deleteConfirmPermission.bind(this);

    this.permissionsPaginationData = this.permissionsPaginationData.bind(this);
    this.filterSearchHandle = this.filterSearchHandle.bind(this);
    this.defaultOptions();
  };
  handlePermissionSubmit(permissionData, formType) {
    if (formType === 'add')
      this.props.storePermissionCall(permissionData);
    else if (formType === 'edit')
      this.props.editPermissionCall(permissionData, this.props.editPermission.permission.id);
  }
  defaultOptions() {
    this.props.getAllParentLabelCall();
    this.props.getAlreadyExistingOrdersCall();
    this.props.getAllModulesCall();
  }
  filterSearchHandle(e) {
    this.setState({
      'filtered': e.target.value
    });
  }
  openModalPermissionEdit(id) {
    this.props.getPermissionCall(id);
  }
  deletePermission(userId) {
    this.props.deletePermissionCall(userId);
  }
  deleteConfirmPermission(userId) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <Confirmalertfordelete typeName="Permission" description="Are you sure to delete the permission?" onClosePro={onClose} deleteType={() => this.deletePermission(userId)} />
        )
      }
    })
  }
  permissionsPaginationData(page, filter, sort, pageSize) {
    this.setState({ pageSize: pageSize }, function () {
      this.props.getPermissionsPaginationCall(page, filter, sort, pageSize);
    })
  }
  componentDidUpdate(prevProps, nextProps) {
    if (this.props.submittedModule !== prevProps.submittedModule) {
      this.props.getAllModulesCall();
    }
    if ((prevProps.editPermission !== this.props.editPermission) && !this.props.loadingPermission) {
      this.setState({
        permission: {
          name: this.props.editPermission.permission.name,
          permission_type: this.props.editPermission.permission.permission_type,
          is_menu: this.props.editPermission.permission.is_menu,
          label: this.props.editPermission.permission.label,
          icon: this.props.editPermission.permission.icon,
          description: this.props.editPermission.permission.description,
          parent_label: this.props.editPermission.permission.parent_label,
          order: this.props.editPermission.permission.order,
          path: this.props.editPermission.permission.path,
          module_id: this.props.editPermission.permission.module_id,
          is_new_tab: this.props.editPermission.permission.is_new_tab,
        }
      }, function () {
        this.setState({ editModalPermission: true });
      });
    }
    if ((prevProps.storedPermission !== this.props.storedPermission) && !this.props.loadingPermission) {
      this.setState({ addModalPermission: false }, function () {
        // this.props.getPermissionsPaginationCall(this.props.current_page, '', '', this.state.pageSize);
        this.pagination.dataCall();
      });
    }
    if ((prevProps.updatedPermission !== this.props.updatedPermission) && !this.props.loadingPermission) {
      this.setState({ editModalPermission: false }, function () {
        // this.props.getPermissionsPaginationCall(this.props.current_page, '', '', this.state.pageSize);
        this.pagination.dataCall();
      });
    }
    if ((prevProps.deletePermission !== this.props.deletePermission) && !this.props.loadingPermission) {
      // this.props.getPermissionsPaginationCall(this.props.current_page, '', '', this.state.pageSize);
      this.pagination.dataCall();
    }
    if (this.props.permissionsData !== prevProps.permissionsData) {
      this.defaultOptions();
    }
  }

  paginationCall = (data) => {
    return this.props.getPermissionsPaginationCall(data.page, data.filter, data.sort, data.pageSize);
  }

  render() {
    const { permission/*, filtered*/, optionsType } = this.state;
    const { editPermission, parent_labels, existingOrder, modules, /*permissionsData, pages, loadingPermission*/ } = this.props;
    const columns = [
      {
        Header: "Permissions Listing",
        headerClassName: 'headerPagination',
        columns: [
          {
            Header: "Permission Name",
            accessor: "name",
            className: "action-center"
          },
          {
            Header: "Label",
            accessor: "label",
            className: "action-center"
          }, {
            Header: 'Module',
            accessor: 'module.name',
            className: 'action-center'
          },
          {
            Header: "Is Menu?",
            accessor: "is_menu",
            Cell: ({ value }) => (
              <div>
                {
                  value === 1 &&
                  <span className="badge badge-success" >Yes</span>
                }
                {
                  (value === 0 || value === null) &&
                  <span className="badge badge-success" >No</span>
                }

              </div>
            ),
            className: "action-center",
            sortable: false
          },
          {
            Header: "Actions",
            accessor: "id",
            Cell: ({ value }) => (
              <div>
                <OverlayTrigger placement="bottom" overlay={<Popover id="tooltip">{hasPermission('permission-edit') ? 'Edit Permission' : adminLabels.DONT_ALLOW}</Popover>}>
                  <span>
                    {hasPermission('permission-edit') &&
                      <button type='button' className="editIcon orderDetailsAction" onClick={() => this.openModalPermissionEdit(value)}>&nbsp;</button>
                    }
                    {!hasPermission('permission-edit') &&
                      <button type='button' className="editIcon orderDetailsAction button_disabled" >&nbsp;</button>
                    }
                  </span>
                </OverlayTrigger>

                <OverlayTrigger placement="bottom" overlay={<Popover id="tooltip">{hasPermission('permission-delete') ? 'Delete Permission' : adminLabels.DONT_ALLOW}</Popover>}>
                  <span>
                    {hasPermission('permission-delete') &&
                      <button type='button' className="deleteIcon orderDetailsAction" onClick={() => this.deleteConfirmPermission(value)}>&nbsp;</button>
                    }
                    {!hasPermission('permission-delete') &&
                      <button type='button' className="deleteIcon orderDetailsAction button_disabled">&nbsp;</button>
                    }
                  </span>
                </OverlayTrigger>
              </div>
            ),
            className: "action-center",
            sortable: false
          }
        ]
      }
    ];

    return (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                ctTableResponsive
                content={
                  <div>
                    <Grid fluid>
                      <Row>
                        <Col md={12}>
                          <div className="secHeading">Permissions List</div>
                        </Col>

                        <Col md={12}>
                          <div className="flexElem flexResponsive" style={{ "alignItems": "flex-start", "justifyContent": "space-between" }}>
                            <div className="form-group">
                              <OpenModalButton
                                openModal={hasPermission('permission-create') ? openModal.bind(this, "addModalPermission") : null}
                                classButton={['backButton pt-sm no_radius pb-sm primary mt-none btn-block btn-info btn ', !hasPermission('permission-create') ? 'button_disabled' : '']}
                                buttonName="Add Permission"
                                tooltipText={hasPermission('permission-create') ? 'Add Permission' : adminLabels.DONT_ALLOW}
                                classIcon={['fa', 'fa-plus']}
                              />
                            </div>

                            {/* <div className="custom-search-input">
                              <FormInputs
                                ncols={["col-md-12"]}
                                onChange={this.filterSearchHandle}
                                proprieties={[
                                  {
                                    type: "text",
                                    bsClass: "form-control",
                                    placeholder: "Search Permissions",
                                    onChange: this.filterSearchHandle,
                                    name: "filter"
                                  }
                                ]}
                              />
                            </div> */}
                          </div>
                        </Col>

                        {/* <Col md={12} className="mt-sm">
                          <ReactTable
                            noDataText= 'No permission found'
                            data={permissionsData}
                            pages={pages}
                            loading={loadingPermission}
                            columns={columns}
                            filtered={filtered}
                            defaultPageSize={10}
                            className="-striped listing"
                            pageData={this.permissionsPaginationData}
                            manual
                            onFetchData={(state, instance) => {
                              var sort = state.sorted.length === 0 ? '' : state.sorted[0].id + ',desc:' + state.sorted[0].desc;
                              state.pageData(state.page + 1, state.filtered, sort, state.pageSize);
                            }}
                          />
                        </Col> */}
                        <Pagination
                          ref={(p) => this.pagination = p}
                          showPagination={false}
                          columns={columns}
                          pageSize={10}
                          getDataCall={this.paginationCall}
                          filterView={true}
                          noDataText='No permission found'
                          // downloadData={true}
                          // downloadFileName={'Orders'}
                          // lowerContent = {null}
                          filterPlaceHolder={'Permissions'}
                          getRowProps={this.getRowProps}
                        // showAllToggle={true}
                        />
                      </Row>
                    </Grid>

                    {/*Add Permission Modal Start*/}
                    {parent_labels !== null &&
                      <Modal backdrop={'static'} show={this.state.addModalPermission} onHide={closeModal.bind(this, "addModalPermission")} aria-labelledby="ModalHeader" >
                        <Modal.Header closeButton>
                          <Modal.Title id='ModalHeader' className="headerTitle">Add Permission</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <div className="row">
                            <div className="col-md-12">
                              <Card bsClass={['innerCard mb-none']} content={
                                <PermissionForm
                                  closeModel={closeModal.bind(this, "addModalPermission")}
                                  formTypePro="add"
                                  permissionPro={OC.PERMISSION}
                                  handlePermissionSubmit={this.handlePermissionSubmit}
                                  componentPro="adminPermission"
                                  optionsParent={parent_labels}
                                  optionsOrder={existingOrder}
                                  optionsType={optionsType}
                                  moduleOptions={modules} />
                              } />
                            </div>
                          </div>
                        </Modal.Body>
                      </Modal>
                    }

                    {/*Add Permission Modal End*/}

                    {/*Edit Permission Modal start*/}
                    {editPermission &&
                      <Modal backdrop={'static'} show={this.state.editModalPermission} onHide={closeModal.bind(this, "editModalPermission")} aria-labelledby="ModalHeader" >
                        <Modal.Header closeButton>
                          <Modal.Title id='ModalHeader' className="headerTitle">Edit Permission</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <div className="row">
                            <div className="col-md-12">

                              <Card
                                bsClass={["innerCard mb-none"]}
                                content={
                                  <PermissionForm
                                    closeModel={closeModal.bind(this, "editModalPermission")}
                                    formTypePro="edit"
                                    permissionPro={permission}
                                    handlePermissionSubmit={this.handlePermissionSubmit}
                                    componentPro="adminPermission"
                                    optionsParent={parent_labels}
                                    optionsOrder={existingOrder}
                                    optionsType={optionsType}
                                    moduleOptions={modules}
                                  />
                                }
                              />
                            </div>
                          </div>
                        </Modal.Body>
                      </Modal>
                    }
                    {/*Edit Permission Modal End*/}
                  </div>
                } />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  };
}

const mapDispatchToProps = dispatch => {
  return ({
    getPermissionsPaginationCall: (page, filter, sort, pageSize) => { return dispatch(getPermissionsPagination(page, filter, sort, pageSize)) },
    getPermissionCall: (id) => { dispatch(getPermission(id)) },
    storePermissionCall: (permissionData) => { dispatch(storePermission(permissionData)) },
    editPermissionCall: (permissionData, id) => { dispatch(editPermission(permissionData, id)) },
    deletePermissionCall: (id) => { dispatch(deletePermission(id)) },
    getAllParentLabelCall: () => { dispatch(getAllParentLabel()) },
    getAlreadyExistingOrdersCall: () => { dispatch(getAlreadyExistingOrders()) },
    getAllModulesCall: () => { dispatch(getAllModules()) }
  });
};

function mapStateToProps(state) {
  // const { userAdmin } = state.adminReducer;
  const { modules, loadingModule, submittedModule } = state.moduleReducer;
  const { parent_labels, editPermission, loadingPermission, permissionsData, pages, storedPermission, updatedPermission, deletePermission, current_page, existingOrder } = state.permissionsReducer;
  return {
    permissionsData, loadingModule, loadingPermission, pages, storedPermission, updatedPermission, editPermission, deletePermission, current_page, parent_labels, existingOrder, modules, submittedModule
  };
};

const Permissions = connect(mapStateToProps, mapDispatchToProps)(PermissionsListPage);
export default Permissions;