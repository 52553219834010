import React, { Component } from 'react';
import { Modal } from "react-bootstrap";
import {connect} from 'react-redux';
import { Dashboard } from 'layouts/Frontend/Dashboard/Dashboard';
import { updateOrderUploadCutOffDateByEmail, getOrderUploadInfo, } from 'redux/actions/order-actions';
import User from 'helper/User';
import { alertActions } from '../../../redux/actions/alert-actions';
import { orderService } from '../../../services/order';
import { returnChecksForPhotoSubmit } from '../../../helper/helperFunctions';
const queryString = require('query-string');

class IncreaseUploadCutOffDate extends Component {

    constructor(props) {
        super(props);
        const parsed = queryString.parse(window.location.search);
        this.state = {
            photoSubmitedModal: false,
            days: parsed.days ? parsed.days : null,
            photos_submitted_for_video_production: parsed.photos_submitted_for_video_production ? parsed.photos_submitted_for_video_production : null,
        };
        console.log("parsed", parsed);
    }
    componentDidMount() {
        //
        this.props.getOrderUploadInfoCall(User.getProperty('order.id')).then(
            res => {
                let errorMsg = returnChecksForPhotoSubmit(res.data);
                if ( Number(res.data.settings.photos_submitted_for_video_production.value) === 0 ) {
                    if (this.state.days) {
                        // if ( new Date(res.data.upload_cut_off_date_formatted) < new Date() ) {
                            this.props.updateOrderUploadCutOffDateByEmailCall(User.getProperty('order.id'), {
                                value: this.props.orderUploadInfo.upload_cut_off_date_original,
                                days: this.state.days
                            }).then(
                                response => {
                                    this.props.getOrderUploadInfoCall(User.getProperty('order.id'));
                                    if ( response ) {
                                        const url = `/uploader`;
                                        this.props.history.push(url);
                                    }
                                }
                            )
                        // } else {
                        //     this.props.error(`Upload cut off date has not ended yet, you can increase the date after ${this.props.orderUploadInfo.upload_cut_off_date_formatted} `)
                        // }

                    } else if (this.state.photos_submitted_for_video_production) {
                        if (res.data.images_count <= 100) {
                            this.setState({photoSubmitedModal: true})
                        } else
                         if (errorMsg) {
                            this.props.error(errorMsg);
                        } else {
                            orderService.submitVideoProductionFromEmail(User.getProperty('order.id'), this.props.orderUploadInfo.photos_submitted_for_video_production.value, 'photos_submitted_for_video_production').then(
                                response => {
                                    this.props.success(response.message);
                                    this.props.getOrderUploadInfoCall(User.getProperty('order.id'));
                                    if ( response ) {
                                        const url = `/uploader`;
                                        this.props.history.push(url);
                                    }
                                }
                            );
                        } 
                    }
                } else {
                    this.props.error('Your photos have already been submitted into production, if you need more time to upload please call 888-533-7637 x213.')
                }
            }
        )

    }
    closeModal = () => {
        this.setState({
            photoSubmitedModal: false
        }, () => {
            const url = `/uploader`;
            this.props.history.push(url);
        })
    }
    // increase_ucd?days=2
    render() {
    const { photoSubmitedModal } = this.state;
        return (
            <div className="container pb-xlg text-center">
                <div className="row">
                    <div className="col-md-12">
                        <h2 className="panel_heading userLogin_heading">Increasing Your Upload Cut Off Date...</h2>
                    </div>
                </div>

                <Modal show={photoSubmitedModal}
                    onHide={this.closeModal}
                    aria-labelledby="ModalHeader"
                    backdrop={false}>
                <Modal.Header closeButton>
                    <Modal.Title id='ModalHeader' className="headerTitle">
                        Make Your Keepsake Video Even Better
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="" style={{background: '#efefef', padding: 'inherit'}}>
                        We hope you had a great trip! We are excited to start production on your keepsake video. 
                        Normally we like to have at least 100 photos, so if you could reach back out to your 
                        group just one more time and see if we could collect a few more photos, this will 
                        make your keepsake video even better. <br /> <br />
                        Don't forget to extend your upload time.
                    </div>


                </Modal.Body>
            </Modal>
            </div>
            
        )
    }

}
const mapStateToProps = state => {
    const {orderUploadInfo} = state.frontEndUserReducer;
    return {
        orderUploadInfo
    }
}
const mapDispatchToProps = dispatch => {
    return {
        updateOrderUploadCutOffDateByEmailCall: (orderId, value) => dispatch(updateOrderUploadCutOffDateByEmail(orderId, value)),
        getOrderUploadInfoCall: (orderId) => {return dispatch(getOrderUploadInfo(orderId))},
        error: (message) => { return dispatch(alertActions.error(message))},
        success: (message) => { return dispatch(alertActions.success(message))},
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard(IncreaseUploadCutOffDate))
