import React, { Component } from 'react';

class Dummy extends Component{
 	render(){
  	return(
      <React.Fragment>
        <section id="since" className="section bg_alter">
          <div className="container mt-xxxlg mb-xxxlg" style={{backgroundColor: '#eaeef6'}}>
            <div className="row">
                <div className="col-md-12">
                  <div className="p-md pb-xlg pt-xlg">
                    <table className="infoPage table blank">
                      <tbody>
                        <tr>
                          <td><span className="text_20 font-weight-medium">Shipped Date:</span></td>
                          <td><span className="text_20 font-weight-medium blue_color">01/13/2021</span></td>
                        </tr>
                        <tr>
                          <td><span className="text_20 font-weight-medium">Tracking Link:</span></td>
                          <td><span className="text_20 font-weight-medium blue_color">https://grouptravelvideos.com/</span></td>
                        </tr>
                        <tr>
                          <td colspan="2"><span className="text_18 font-weight-medium green_color">Still have questions about your shipment or your order?</span></td>
                        </tr>
                        <tr>
                          <td><span className="text_20 font-weight-medium">Please call us at:</span></td>
                          <td><span className="text_20 font-weight-medium blue_color">1-888-533-7637</span></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
            </div>
          </div>
        </section>
      </React.Fragment>
  	);
  }
}
export default Dummy;
