import React from "react";
import { openModal, getInitials, getFormattedDate, isEmptyCheck, returnColumnValueWithColumnMatch } from './helperFunctions';
import {
	OverlayTrigger,
	Popover,
} from "react-bootstrap";
// import Mailto from 'reactv16-mailto';
// import MyImage from 'components/Upload/MyImage';
import { objectsConstants as OC } from 'redux/constant/objects-constant';
import { Status } from "../constants/Status";
const moment = require('moment');

export const ThemeFunctions = {
	showValueLabel,
	openModelButton,
	initialsLogo,
	errorMsg,
	showValueLabelForEmailLink,
	mailToEmailsSubject,
	productionStatus,
	productionStatusFrontEnd,
	onHold,
    showValueLabelForTitles,
    productionStatusTTK
}


function showValueLabel(label, value, isDate = false, widths) {
	return (
		<tr className="">
			<td width={widths ? widths.td1 : null}>{label}:</td>
			{
				label === 'Barcode' ?
					<td width={widths ? widths.td2 : null}><span className="td_barcode">{value}</span></td>
					: (label === 'Order ID' ? <td width={widths ? widths.td2 : null}><span className="td_orderId">{value}</span></td>
						: (isDate ? <td width={widths ? widths.td2 : null}>{value}</td> : <td width={widths ? widths.td2 : null}>{value}</td>))
			}
		</tr>
	);
}
function showValueLabelForTitles(label, value, isChanged = false, isDate = false, widths) {
	return (
		<tr className={isChanged ? "text-danger" : ""}>
			<td width={widths ? widths.td1 : null}>{label}:</td>
			{
				label === 'Barcode' ?
					<td width={widths ? widths.td2 : null}><span className="td_barcode">{value}</span></td>
					: (label === 'Order ID' ? <td width={widths ? widths.td2 : null}><span className="td_orderId">{value}</span></td>
						: (isDate ? <td width={widths ? widths.td2 : null}>{value}</td> : <td width={widths ? widths.td2 : null}>{value}</td>))
			}
		</tr>
	);
}


function showValueLabelForEmailLink(label, value, isDate = false, widths = null, extraTest = null) {
	return (
		<tr className="form-group">
			<td width={widths ? widths.td1 : null}>{label}:</td>
			<td>
				<a rel="noopener noreferrer" href={`mailto:${value},hahaha@gmail.com?subject=your title&body=TThe message`} target="_blank" style={{ backgroundColor: '#ffffa0' }} >{value}</a> {extraTest}
			</td>
		</tr>
	);
}

function mailToEmailsSubject(label, emails, subject) {
	return (
		<a rel="noopener noreferrer" href={`mailto:${emails}?subject=${subject}`} target="_blank" style={{ backgroundColor: '#ffffa0' }} >{label}</a>
	);
}


function openModelButton(classButton, name, tooltipText, buttonName) {
	return (
		<OverlayTrigger placement="bottom" trigger={['hover']} overlay={<Popover id="tooltip"> {tooltipText} </Popover>}>
			<button className={classButton} name={name} onClick={openModal.bind(this)}> {buttonName} </button>
		</OverlayTrigger>
	);
}

function initialsLogo(color, name) {
	var randomColor = OC.RANDOMCOLORS;
	var rand = color ? color : randomColor[Math.floor(Math.random() * randomColor.length)];
	return (
		<span className="initialsLogo" style={{ background: rand }}>{getInitials(name, " ")}</span>
	);
}

function errorMsg(text, type, icon, extraClass = "") {
	return (
		<div className={"flexElem text-" + type + extraClass} role="alert">
			<img src={icon} alt="Icon" />
			<span style={{ marginLeft: "10px" }}>{text}</span>
		</div>
	);
}


function onHold(settings, onHoldLastComment) {
	var html = '';
	html = <span><strong style={{ 'color': 'red' }}>ORDER ON HOLD</strong>
		{

			<OverlayTrigger placement="left" overlay={
				<Popover id="onHoldStatus">

					On Hold {getFormattedDate(settings.on_hold.updated_at)} {` by ${settings.on_hold.updated_by_user ? settings.on_hold.updated_by_user.name : ''}`}
					<br />
					{
						onHoldLastComment &&
						<span style={{ 'fontSize': '17px' }}>Comment : <strong>{onHoldLastComment.comment}</strong></span>
					}
				</Popover>
			}>


				<span className="question_mark ml-xs">?</span>
			</OverlayTrigger>
		}
	</span>

	return (
		<React.Fragment>
			<strong > {html}</strong>
		</React.Fragment>
	);
}
function productionStatus(settings, status, csr_steps, onHoldLastComment, orderCancelComment, orderDates, shipping_details = false, placement = 'right') {
	var html = '';
	if (!isEmptyCheck(status.title) && status.title === 'Pending') {
		html = <strong>Pending</strong>;
	}
	if (settings &&
		returnColumnValueWithColumnMatch(csr_steps, 'value', ['step2'], 'code') === 1 &&
		parseInt(settings.photos_renamed.value) != 1 &&
		parseInt(settings.photos_submitted_for_video_production.value) === 1 &&
		orderDates &&
		orderDates.receive_date.value === ''
	) {
		html = <strong>Waiting To Be Received</strong>;
	}
	if (settings && settings.photos_submitted_for_video_production &&
		parseInt(settings.photos_submitted_for_video_production.value, 10) !== 1 &&
		parseInt(settings.finalize_by_qa.value, 10) !== 1 &&
		shipping_details.camera_ship_status
	) {
		html = <strong>Waiting To Be Submitted</strong>;
	}
	if (settings &&
		parseInt(settings.photos_renamed.value) === 1 &&
		orderDates &&
		orderDates.receive_date.value === '') {
		html = <strong>Ready for Receiving</strong>;
	}

	if (settings &&
		orderDates &&
		orderDates.receive_date.value &&
		parseInt(settings.photos_renamed.value) === 1 &&
		parseInt(settings.finalize_by_editor.value, 10) === 0 &&
		parseInt(settings.finalize_by_pm.value, 10) === 0 &&
		parseInt(settings.finalize_by_qa.value, 10) === 0
	) {
		html = <strong>Ready For Editing</strong>;

	}
	if (settings &&
		parseInt(settings.finalize_by_editor.value, 10) === 1) {
		html = <strong>Ready For Production</strong>;
	}
	if (settings &&
		parseInt(settings.finalize_by_pm.value, 10) === 1) {
		html = <strong>Produced</strong>;
	}
	if (settings &&
		parseInt(settings.finalize_by_qa.value, 10) === 0 && parseInt(settings.finalize_by_pm.value, 10) === 1) {
		html = <strong>Waiting Quality Control</strong>;
	}
	if (settings &&
		parseInt(settings.finalize_by_qa.value, 10) === 1) {
		html = <strong>Complete</strong>;
	}
	if (settings &&
		!returnColumnValueWithColumnMatch(csr_steps, 'value', ['step2'], 'code') && parseInt(settings.finalize_by_qa.value, 10) !== 1 ) {
		html = <strong>Waiting for PTM to Ship</strong>;
	}
	if (settings &&
		parseInt(settings.on_hold.value, 10) === 1) {

		html = <span><strong style={{ 'color': 'red' }}>ORDER ON HOLD</strong>
			{

				<OverlayTrigger placement="left" overlay={
					<Popover id="onHoldStatus">

						On Hold {getFormattedDate(settings.on_hold.updated_at)} {` by ${settings.on_hold.updated_by_user ? settings.on_hold.updated_by_user.name : ''}`}
						<br />
						{
							onHoldLastComment &&
							<span style={{ 'fontSize': '17px' }}>Comment : <strong>{onHoldLastComment.comment}</strong></span>
						}
					</Popover>
				}>


					<span className="question_mark ml-xs">?</span>
				</OverlayTrigger>
			}
		</span>
	}
	if (status.title === Status.CANCELLED) {
		html = <span style={{'color':'red'}}><strong>Cancelled</strong>
			{
				<OverlayTrigger placement={placement} overlay={
					<Popover>
						{
							(orderCancelComment !== undefined && orderCancelComment) &&
							<span style={{ 'fontSize': '17px' }}>Comment : <strong>{orderCancelComment.comment} cancelled at {moment(orderCancelComment.created_at).format("dddd, MMMM Do YYYY, hh:mm:ss a")}</strong></span>
						}
					</Popover>
				}>
					<span className="question_mark ml-xs">?</span>
				</OverlayTrigger>
			}
		</span>
	}

	return (
		<React.Fragment>
			<strong > {html}</strong>
		</React.Fragment>
	);
}


function productionStatusTTK(settings, status, csr_steps, onHoldLastComment, orderCancelComment, orderDates, shipping_details = false) {
	var html = '';
    if ( parseInt(returnColumnValueWithColumnMatch(csr_steps, 'value', ['step1'], 'code'), 10) === 0 )
    {
        html = <strong>Waiting for PTM to Send</strong>;
    }
    if (settings && settings.photos_submitted_for_video_production &&
        parseInt(settings.photos_submitted_for_video_production.value, 10) !== 1 && parseInt(returnColumnValueWithColumnMatch(csr_steps, 'value', ['step1'], 'code'), 10) === 1 )
    {
        html = <strong>Waiting To Be Submitted</strong>;
    }
    if (settings && settings.photos_submitted_for_video_production &&
        parseInt(settings.photos_submitted_for_video_production.value, 10) === 1 && parseInt(returnColumnValueWithColumnMatch(csr_steps, 'value', ['step1'], 'code'), 10) === 1 )
    {
        html = <strong>Order Complete</strong>;
    }

	if (settings &&
		parseInt(settings.on_hold.value, 10) === 1) {

		html = <span><strong style={{ 'color': 'red' }}>ORDER ON HOLD</strong>
			{

				<OverlayTrigger placement="left" overlay={
					<Popover id="onHoldStatus">

						On Hold {getFormattedDate(settings.on_hold.updated_at)} {` by ${settings.on_hold.updated_by_user ? settings.on_hold.updated_by_user.name : ''}`}
						<br />
						{
							onHoldLastComment &&
							<span style={{ 'fontSize': '17px' }}>Comment : <strong>{onHoldLastComment.comment}</strong></span>
						}
					</Popover>
				}>


					<span className="question_mark ml-xs">?</span>
				</OverlayTrigger>
			}
		</span>
	}
	if (status.title === Status.CANCELLED) {
		html = <span style={{'color':'red'}}><strong>Cancelled</strong>
			{
				<OverlayTrigger placement="right" overlay={
					<Popover>
						{
							(orderCancelComment !== undefined && orderCancelComment) &&
							<span style={{ 'fontSize': '17px' }}>Comment : <strong>{orderCancelComment.comment} cancelled at {moment(orderCancelComment.created_at).format("dddd, MMMM Do YYYY, hh:mm:ss a")}</strong></span>
						}
					</Popover>
				}>
					<span className="question_mark ml-xs">?</span>
				</OverlayTrigger>
			}
		</span>
	}

	return (
		<React.Fragment>
			<strong > {html}</strong>
		</React.Fragment>
	);
}


function productionStatusFrontEnd(settings, status){
	var productionStatus = '--';

	if (!Number(settings.photos_submitted_for_video_production.value) && status !== 'Cancelled') {
		productionStatus = 'Active';
	}
	if (Number(settings.photos_submitted_for_video_production.value) && status !== 'Cancelled') {
		productionStatus = 'In production';
	}
	if (Number(settings.finalize_by_qa.value) && status !== 'Cancelled') {
		productionStatus = 'Complete';
	}
	if (status === 'Cancelled') {
		productionStatus = 'Cancelled';
	}
	return productionStatus;
}
