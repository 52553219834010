import React, { Component, Fragment } from "react";
import { s3StaticPath } from 'helper/helperFunctions';
import ReactPlayer from 'react-player';
// import screenfull from 'screenfull';
import { findDOMNode } from 'react-dom';
import { settingsService } from 'services/settings';
import { Card } from "components/Card/Card.jsx";


class VideoPreview extends Component {
	constructor(props) {
		super(props);
		// console.log(this.props.match);
		// const { fileId } = this.props.match?this.props.match.params:null;
		// console.log('fileId', ti;l)
		this.state = {
			url: null,
			pip: false,
			playing: true,
			controls: true,
			light: false,
			volume: 0.8,
			muted: false,
			played: 0,
			loaded: 0,
			duration: 0,
			playbackRate: 1.0,
			loop: false,
			src : this.props.src ? this.props.src :null,
			fileId : this.props.match?this.props.match.params.fileId:null,
			name:this.props.name?this.props.name:null,
			id:this.props.id?this.props.id:null,
			controlsCollaps : false,
		}
	}

	componentDidMount() {
		if (this.state.fileId) {
			settingsService.getFile(this.state.fileId).then(response=>{
				this.setState({src:response.data.file_path,name:response.data.name, id:response.data.id});
			});
		}
	}

	handleSetPlaybackRate = e => {
		this.setState({ playbackRate: parseFloat(e.target.value) })
	}

	handleClickFullscreen = () => {
		console.log('e',findDOMNode(this.player))
		// screenfull.request()
	}
	ref = player => {
		this.player = player
	}

	render() {
		const { controls, playbackRate, src, name, fileId, id, controlsCollaps, light } = this.state;
		return (
			<div className="text-center" style={{height: (!fileId?'auto':'100vh')}}>
				<div key={src} className=" display_ib" style={{width: (!fileId?'500px':'100%'), height: (!fileId?'auto':'80%')}}>
					<React.Fragment>
						<ReactPlayer
							ref={this.ref}
							key={src}
							url={src}
							className='react-player'
			              	width='100%'
			              	height={!fileId?'260px':'100%'}
			              	controls={controls}
			              	playbackRate={playbackRate}
			              	light={light}
						/>
                        {
                            !this.props.hideVideoControls &&
                            <Card bsClass={["innerCard mt-sm text-left"]} title="Video Player Controls" collapseTrigger={controlsCollaps} collapsHandler={() => this.setState({ controlsCollaps: !controlsCollaps })} collapsable={true}
							content={
								<div className="displayInline">
								<button className="customBtn btn btn-primary text-white m-sm" onClick={this.handleSetPlaybackRate} value={1}>1x</button>
			                  	<button className="customBtn btn btn-primary text-white m-sm" onClick={this.handleSetPlaybackRate} value={2.5}>2x</button>
			                  	<button className="customBtn btn btn-primary text-white m-sm" onClick={this.handleSetPlaybackRate} value={3.5}>3x</button>
			                  	<button className="customBtn btn btn-primary text-white m-sm" onClick={this.handleSetPlaybackRate} value={4}>4x</button>
			                  	{
			                  		!fileId &&
			                  			<a style={{display: "inline-flex",background: "#2F4576",padding: "12px"}} className={'customBtn btn btn-primary text-white m-sm'} href={`/show-video/${id}`} target="_blank" rel="noopener noreferrer">
			                  				<img src="https://grouptravelvideos.s3.amazonaws.com/static/scale-screen.svg" title="Fullscreen" height="18" alt="Fullscreen" style={{background: 'none'}}/>
			                  			</a>
			                  	}
			                  	</div>
							}
						/>
                        }


					</React.Fragment>
					{
						/*
					<video key={src} id="playVid" controls width="300" >
						<source src={src} type="video/mp4" />
					</video>
					*/
					}
					<div className="prevName">
						{name}
					</div>

				</div>
			</div>
		);
	};
}

export default VideoPreview;
