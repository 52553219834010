/***********************/
//Read Me
// import Pagination from 'components/ThemeComponents/Pagination';

// getRowProps = (rowData) =>{
// 	let data = {};
// 	if( rowData.customer_slates.id !== rowData.agency.id){
// 	  data = {backgroundColor:'yellow'}
// 	}
// 	return data;
// }


// paginationCall = (data) =>{
// 	return this.props.paginationDataCall({...data, exceptResource:['logo_file']});
// }

// <Pagination
// 	ref={(p) => this.pagination = p}
// 	columns = { columns }
// 	getDataCall= { this.paginationCall }
// 	filterView={true}
// 	//getRowProps = { this.getRowProps }
// />
/***********************/
import React, { Component } from "react";
// import { connect } from 'react-redux';
import ReactTable from "react-table";
import 'react-table/react-table.css';
// import { orderActions } from "redux/actions/order-actions";
import { Col } from "react-bootstrap";
import { FormInputs } from "components/FormInputs/FormInputs.jsx";
import ReactDataToCSV from 'components/ThemeComponents/reactDataToCSV';

const ShowCols = (props) => {
	return props.props.noCols ?
	<div className="clearfix pull-left" style={{width : '100%'}}>
		{props.children}
	</div>
	:
	<Col md={12} className="mt-md">
		{props.children}
	</Col>
}

const ShowRow = (props) => {
	return props.props.noCols ?
	<div className="row">
		{props.children}
	</div>
	:
	<React.Fragment>
		{props.children}
	</React.Fragment>
}

class Pagination extends Component {
	constructor(props) {
		super(props);
		this.state = {
			sorted: '',
			filtered: '',
			pageSize: this.props.pageSize || 10,
			total: 0,
			page: 0,
			data: [],
			pages: 0,
			showAll: false,
            length:false
		};

	}
	dataCall = (page = this.state.page, filtered = this.state.filtered, sorted = this.state.sorted, pageSize = this.state.pageSize) => {
		var data = {
			filter: filtered,
			pageSize: this.state.showAll ? this.state.total : pageSize,
			sort: sorted,
			page: this.state.showAll ? 0 : page
		};
		this.props.getDataCall(data).then(response => {
            response &&
			this.setState({
				data: response.data.pagination.data,
				pages: response.data.pagination.last_page,
				page: response.data.pagination.current_page,
				total: response.data.pagination.total,
			}, ()=>{
				// this.reactTable.reload();

                this.setState({
                    length: response.data.pagination.data.length
                }, () => {
                    if(this.props.parentCallback){
                        // console.log(response.data.pagination.data.length,'paginationdata');
                     this.props.parentCallback(this.state.length)
                    }
                })
			})
		});
	}

	paginationStateData = () => {
		return {...this.state}
	}
	filterSearchHandle = (e) => {
		this.setState({
			'filtered': e.target.value
		});
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.showAll !== this.state.showAll) {
			this.dataCall();
		}
		if (this.state.filtered !== prevState.filtered) {
			this.dataCall();
		}
		// if (prevProps.columns !== this.props.columns) {
		// 	this.forceUpdate();
		// }
	}

	render() {
		const { showAll, data, pages, filtered, pageSize, page } = this.state;
		const { showAllToggle, columns, loadingOrder, filterView, upperContent, lowerContent, filterPlaceHolder, downloadData, minRows, downloadFileName, style, showTotal } = this.props;
		// console.log('columns', columns);
		return (
			<React.Fragment>
				<ShowCols props={this.props}>
					{
						showTotal &&
                        <p>Your query resulted in a total of {this.state.total}.</p>

					}
					{
						upperContent &&
						upperContent
					}
					<ShowRow props={this.props}>
						{
							this.props.addNewBtn &&
							<Col md={8} sm={8} xs={12} >
								{this.props.addNewBtn}
							</Col>
						}
						{
							filterView &&
							<Col className='pull-right' md={4} sm={6} xs={12} >
								<FormInputs
									ncols={["col-md-12"]}
									onChange={this.filterSearchHandle}
									proprieties={[
										{
											type: "text",
											bsClass: "form-control",
											placeholder: `Search ${filterPlaceHolder ? filterPlaceHolder : ''}`,
											onChange: this.filterSearchHandle,
											name: "filter"
										}
									]}
								/>
							</Col>
						}

						{
							this.props.toggleBtns &&
							<Col md={12} className="mb-md">
								{this.props.toggleBtns}
							</Col>
						}
					</ShowRow>

					{
						lowerContent &&
						lowerContent
					}

					{
						showAllToggle &&
						<React.Fragment>
							<Col md={1} className='pull-right text-right pl-xs'>
								<label className="container-check mb-none" style={{ marginTop: "10px", paddingLeft: "28px" }}> Show All <input type="checkbox" name="is_default" className="checkbox" onChange={() => {
									this.setState({ showAll: !showAll })
								}} /><span className="checkmark"></span></label>
							</Col>
						</React.Fragment>
					}
					{
						downloadData &&
						<React.Fragment>
							<Col md={2} className="mt-sm downloadCsv text-right pull-right">
								<ReactDataToCSV
									className="downloadbtn"
									fileName={downloadFileName}
									currentRecordsRef={this.reactTable}
									columnsData={columns[0].columns}
								/>
							</Col>
						</React.Fragment>
					}
					<ShowCols props={this.props}>
						<ReactTable
							minRows={minRows ? minRows : 5}
							showPagination={showAll ? false : true}
							ref={(r) => this.reactTable = r}
							trProps={this.props.getRowProps}
							noDataText={this.props.noDataText || "No records found"}
							data={data}
							pages={pages}
							columns={columns}
							filtered={filtered}
							pageSizeOptions={this.props.pageSizeOptions}
							defaultPageSize={pageSize}
							pageSize={pageSize}
							loading={loadingOrder}
							defaultSorted={this.props.defaultSorted || []}
							onPageSizeChange={(pageSize) => {
								this.setState({
									pageSize: pageSize
								})
							}}
							style = {style}
							className="-striped listing"
							loadingText={'Loading...'}
							pageData={this.dataCall}
							manual
							// page={page}
							// autoResetPage={true}
							onFetchData={(state, instance) => {
								var sort = state.sorted.length === 0 ? '' : state.sorted[0].id + ',desc:' + state.sorted[0].desc;
								state.pageData(state.page + 1, state.filtered, sort, state.pageSize);
							}}
							getTrProps={(state, rowInfo, columns, instance) => {
								let data = {};
								if (instance.props.trProps) {
									if (rowInfo !== undefined) {
										const rowData = rowInfo.original;
										// console.log('rowData', rowData);
										data = instance.props.trProps(rowData);
									}
								}
								return {
									style: data
								};
							}}
						/>
					</ShowCols>
				</ShowCols>
			</React.Fragment>
		);
	}
}


export default Pagination;



