import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Grid, Row, Col, Button } from 'react-bootstrap'
import DatePicker from "react-datepicker";
import Card from '../../Card/Card'
import { storeMoreButton, getMoreButton, updateMoreButton } from '../../../redux/actions/more-buttons-action';
import ImagePreview from "components/Upload/ImagePreview";
import ToggleButton from 'react-toggle-button';
import { themeCSSConstants } from 'redux/constant/themeCSS';
import Validator from 'simple-react-validator';
import FileUpload from "components/Upload/FileUpload";
import {objectsConstants as OC} from 'redux/constant/objects-constant';
import { SC } from 'helper/ServerCall';

const moment = window.moment;

var cloneDeep = require('lodash.clonedeep');


class MoreButtonForm extends Component {
    constructor(props) {

        super(props);
        this.dateValidator = new Validator();
        var date = '';//moment(new Date()).format('YYYY-MM-DD');
        // console.log(date, 'dateee');
        this.state = {
            moreButtons: OC.MOREBUTTONS,
            expiration_date: date,
            uploadLink: false,
            uploadFile: false,
            maxAllowedSortOrder: 1
        }
        this.Validator = new Validator();

        if (this.props.formType === 'add') {
            let response = this.getAgencyMoreButtonNextSortOrder().then( (response) =>  {
                this.setState(prevState => ({
                    moreButtons: {
                        ...prevState.moreButtons,
                        sort_order: response.data.nextSortOrder
                    },
                    maxAllowedSortOrder: response.data.nextSortOrder
                }))
            })
        }

    }
    getAgencyMoreButtonNextSortOrder = () => {
        return SC.getCall('getMoreButtonNextSortOrder/' + this.props.agency_id + '/Agency' );
    }
    DateChangeHandler = (value, field) => {
        // this.setState({ [field]: value === null ? '' : moment(new Date(value)).format('YYYY-MM-DD') }

        var valueDate = moment(value).startOf('day');
        value = value === null ? '' : valueDate;
        this.setState({ [field]: value});
        // var tempDate = cloneDeep(this.state.order_details);
        // tempDate.order_dates[field].value = value === null ? '' : valueDate;

        // , () => {
        //     if (this.dateValidator.allValid()) {
        //     } else {
        //         this.dateValidator.showMessages();
        //         this.forceUpdate();
        //     }
        // }
        // )
    }
    dateElement(label, name, value,) {
        var selectedDate = this.state[name] !== '' ? new Date(this.state[name]) : '';
        // moment(selectedDate).startOf('day');
        return (
            <div className='form-group fullCalendar' id={name}>
                <label>{label}</label>
                <DatePicker
                    placeholderText='MM/DD/YYYY'
                    autoComplete='off'
                    className="form-control calender_icon"
                    minDate={Date.now()}
                    selected={selectedDate}
                    name={name}
                    // dateFormat="mm/dd/yyyy"
                    onChange={(event) => this.DateChangeHandler(event, name)}
                />

            </div>
        );
    }

    sortOrderInputChangeHandler = (e) => {
        // console.log(this.state.moreButtons);
        const moreButtons = { ...this.state.moreButtons };
        moreButtons[e.target.name] = e.target.value && (e.target.value > this.state.maxAllowedSortOrder) || (e.target.value < 0) ? this.state.maxAllowedSortOrder : e.target.value;
        this.setState({
            moreButtons: moreButtons,
        })
    }

    inputChangeHandler = (e) => {
        // console.log(this.state.moreButtons);
        const moreButtons = { ...this.state.moreButtons };
        moreButtons[e.target.name] = e.target.value;

        this.setState({
            moreButtons: moreButtons,
        })
    }

    inputChangeHandlerLink = (e) => {
        const moreButtons = { ...this.state.moreButtons };
        moreButtons[e.target.name] = e.target.value;
        var length = e.target.value.length
        // console.log(e.target.value.length, 'value input');

        this.setState({
            moreButtons: moreButtons,

        }, () => {
            if(length > 0){
                this.setState({
                    uploadLink: false,
                    uploadFile: true
                })

            } else{
                this.setState({
                    uploadLink: false,
                    uploadFile: false
                })
            }
        })
    }

    formSubmitHandler = () => {

        const data = new FormData();

        Object.keys(this.state.moreButtons).forEach(item => {
            if(item == 'expiration_date' || (item == 'model_id' && this.props.agency_id)){
                return;
            }
            data.append(item,this.state.moreButtons[item]);
        })
        if(this.props.agency_id) {
            data.append('model_id', this.props.agency_id);
        }
        if(this.state.expiration_date != '') {
            // data.append('resource_file', this.state.moreButtons.file_path);
            data.append('expiration_date', moment(new Date(this.state.expiration_date)).format('YYYY-MM-DD'));
        }

            // const data = { ...this.state.moreButtons, model_id : this.props.agency_id};
            if (this.props.formType === 'add') {
                this.props.storeMoreButtonCall(data).then(
                    response => {
                        if ('hide' in this.props) {
                            this.props.hide();
                            this.props.getPagination();
                            // console.log('function called successfully');
                        }
                    }
                );
            } else if (this.props.formType === 'edit') {
                this.props.updateMoreButtonCall(data, this.props.data.id).then(
                    response => {
                        if ('hide' in this.props) {
                            this.props.hide();
                            this.props.getPagination();
                            // console.log('function called successfully');
                        }
                    }
                );
            }
    }

    // componentDidMount() {
    //     let response = this.getAgencyWeTravelButton().then( (response) =>  {
    //         // console.log(response, 'response#21');
    //         this.setState({
    //             weTravelButton: response.data.weTravel
    //         })
    //     })
    // }

    setResourceFile = (key, value) => {
		this.setState({ moreButtons: { ...this.state.moreButtons, file_path: value },
                        uploadLink: true,
                        uploadFile: false }, function () {
			// console.log('pro show file', this.state.moreButtons);
		})
	}
	removeFile = (key, value) => {
		this.setState(prevState => ({
			moreButtons: {
				...prevState.moreButtons,
				file_path: null,
                file_name: null,
                mime_type: null,
                extension: null,
                file_size: null,
			},
            uploadLink: false,
            uploadFile: false
		}), () => {
			// console.log('pro show file', this.state.moreButtons);
		});
	}

    handleOptionAtOrderPlacement = (name, value) => {
		let tempValue = !value;
        tempValue = tempValue ? 1 : 0;
		this.setState(prevState => ({
					moreButtons: {
                        ...prevState.moreButtons,
                        [name]: tempValue
                    }
		})
        	,
			() => {
				if (name === 'show_on_all_orders' || name === 'option_at_order_placement') {
					var WtOrder = '';
					if (name === 'show_on_all_orders')
						WtOrder = 'option_at_order_placement';
					else
						WtOrder = 'show_on_all_orders';
					this.setState(prevState => ({
						moreButtons: {
                            ...prevState.moreButtons,
                            [WtOrder]:0
                        }
					}));
				}
            });
	}


    componentDidUpdate(prevProps, prevState) {
        if (prevProps.moreButtons !== this.props.moreButtons) {
            // console.log(this.props.moreButtons,'componentDidUpdate log');
            this.setState({
                moreButtons: this.props.moreButtons
            })
        }
    }


componentDidMount(){
    let data = this.props.data;
    if (data){
        this.setState({
            moreButtons:{...data},
            expiration_date: (this.props.data && this.props.data.expiration_date != '' && this.props.data.expiration_date != null && this.props.data.expiration_date != 'null') ? moment(new Date(this.props.data.expiration_date)).startOf('day') : ''
        }, ()=>{
            // console.log(data.file_path, 'file_path update');

            if(data.link && data.link.length > 0){
                this.setState({
                    uploadLink: false,
                    uploadFile: true
                })

            }else if(data.file_path && data.file_path.name && data.file_path.name.length > 0){
                this.setState({
                    uploadLink: true,
                    uploadFile: false
                })
            }else{
                this.setState({
                    uploadLink: false,
                    uploadFile: false
                })
            }

            // if(data.file_path && data.file_path.name.length > 0){
            //     this.setState({
            //         uploadLink: true,
            //         uploadFile: false
            //     })
            // }
            // else{
            //     this.setState({
            //         uploadLink: false,
            //         uploadFile: false
            //     })
            // }


        })

    }
    if (this.props.formType == 'add'){
        this.setState({
            moreButtons: {...this.state.moreButtons,option_at_order_placement:1}
        })
    }
}

    render() {
        const { moreButtons , expiration_date } = this.state;
        // console.log(moreButtons)
        const title = this.props.formType === 'add' ? 'Create' : 'Edit';
        return (
            <div>
                <Grid fluid>
                    <Row>
                        <Col md={12} >
                            <Card title={title + ' More Button'} bsClass={['innerCard']} content={
                                <React.Fragment>
                                    <form id="moreButtons-form" encType='multipart/form-data'>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className='form-group' id="title">
                                                    <label htmlFor="title">Title<span className="requiredClass">*</span></label>
                                                    <input type="text" name="title" className="form-control" value={moreButtons.title} onChange={this.inputChangeHandler} />

                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className='form-group' id="sort_order">
                                                    <label htmlFor="sort_order">Sort Order</label>
                                                    <input type="number" min="1" max={this.state.maxAllowedSortOrder} name="sort_order" className="form-control " value={moreButtons.sort_order} onChange={this.sortOrderInputChangeHandler} />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-6">
                                                {
                                                    this.dateElement('Expiration Date','expiration_date', expiration_date !== '' && moment(expiration_date, 'YYYY-MM-DD'), ['date'], 'date')
                                                }
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="togglePadding form-group">
                                                    <label className="container-check flexElem alignCenter">
                                                    <ToggleButton
                                                        inactiveLabel={''}
                                                        activeLabel={''}
                                                        height={'40px'}
                                                        colors={themeCSSConstants.THUMBICONSTYLE}
                                                        trackStyle={{ height: '10px', width: '30px' }}
                                                        thumbStyle={themeCSSConstants.THUMBSTYLE}
                                                        thumbAnimateRange={[0, 15]}
                                                        value={moreButtons.show_on_all_orders}
                                                        onToggle={() => this.handleOptionAtOrderPlacement("show_on_all_orders", moreButtons.show_on_all_orders)} />
                                                        <div style={{fontSize: '16px'}}>Show on all orders</div>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="togglePadding form-group">
                                                    <label className="container-check flexElem alignCenter">
                                                    <ToggleButton
                                                        inactiveLabel={''}
                                                        activeLabel={''}
                                                        height={'40px'}
                                                        colors={themeCSSConstants.THUMBICONSTYLE}
                                                        trackStyle={{ height: '10px', width: '30px' }}
                                                        thumbStyle={themeCSSConstants.THUMBSTYLE}
                                                        thumbAnimateRange={[0, 15]}
                                                        // value={this.props.formType == 'add' ? !moreButtons.option_at_order_placement : moreButtons.option_at_order_placement}
                                                        value={moreButtons.option_at_order_placement}
                                                        onToggle={() => this.handleOptionAtOrderPlacement("option_at_order_placement", moreButtons.option_at_order_placement)} />
                                                        <div style={{fontSize: '16px'}}>Option at order placement</div>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className='form-group' id="description">
                                                    <label htmlFor="description">Description<span className="requiredClass">*</span></label>
                                                    <textarea type="text" style={{height: '130px'}} name="description" className="form-control " value={moreButtons.description} onChange={this.inputChangeHandler} />

                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className='form-group' id="link">
                                                    <label htmlFor="link">Upload Link</label>
                                                    <input type="text" name="link" className="form-control " disabled = {(this.state.uploadLink)} value={moreButtons.link} onChange={this.inputChangeHandlerLink} />
                                                </div>
                                            </div>
                                        </div>
                                        {/* {
                                          this.state.uploadFile == false ? */}
                                            <div className="row">
                                                <div className="col-md-12" id="File">
                                                    <label htmlFor="name">Upload File</label>

                                                    <div className={"pofilePicContainer " + (this.state.uploadFile ? 'disableDropzone' : '')}>
                                                        {
                                                            !moreButtons.file_path || !moreButtons.file_path.name ?
                                                                <div className='form-group dropzone_profileImage'>
                                                                    <FileUpload
                                                                        files={moreButtons.file_path}
                                                                        setResourceFile={this.setResourceFile}
                                                                        allowedFormatsStringMimePro={'image/jpeg, image/png, video/quicktime, video/mp4, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/pdf ,application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}
                                                                        allowedFormatsStringExtPro={'jpg, png, docx, doc, Jpeg, pdf, xls, xlsx, mov, mp4'}
                                                                        multiple={false}
                                                                        askToUpdate={true}
                                                                        updateWithoutAsking={false}
                                                                    />
                                                                </div>
                                                                :
                                                                    <ImagePreview
                                                                        hideVideoControls
                                                                        // log={console.log('moreButtons', moreButtons)}
                                                                        filesToPreview={moreButtons.file_path}
                                                                        removeFile={this.removeFile}
                                                                        resetImage={true}
                                                                    />
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            {/* :''
                                        } */}

                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className='text-center'>
                                                    <Button type='button' onClick={this.formSubmitHandler} form="moreButtons-form" bsSize="large" bsStyle="info" className="backButton pt-sm no_radius pb-sm success btn btn-lg btn-info ml-sm mt-sm btn-default " >{this.props.formType =="add" ? "Save" : "Update"}</Button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </React.Fragment>
                            } />
                        </Col>
                    </Row>
                </Grid>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { moreButtons } = state.moreButtonReducer;
    return { moreButtons }
}

const mapDispatchToProps = dispatch => {
    return {
        storeMoreButtonCall: (data) => { return dispatch(storeMoreButton(data)) },
        getMoreButtonCall: (id) => { dispatch(getMoreButton(id)) },
        updateMoreButtonCall: (data, id) => { return dispatch(updateMoreButton(data, id)) }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(MoreButtonForm);
