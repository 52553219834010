import React, { Component } from 'react'
import OrderForm from './OrderForm/OrderForm';
import User from 'helper/User';

class NewOrder extends Component {
    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <h2 className="panel_heading userLogin_heading">New Order</h2>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12">
                        <OrderForm formType='add' agency_id={User.agency('id')} />
                    </div>
                </div>
            </div>
        )
    }
}

export default NewOrder;
