import React, { Component } from 'react'
import { Grid, Row, Col } from 'react-bootstrap'
import Card from '../../Card/Card'
import Select from 'react-select'
import { connect } from 'react-redux'
import { getBrands } from '../../../redux/actions/account-action';
import { getItemCategories } from '../../../redux/actions/item-category-actions';
import { returnLabelWithValue } from '../../../helper/helperFunctions'
import ReactValidator from 'simple-react-validator'
import { storeProgramType, getProgramType, updateProgramType } from '../../../redux/actions/ProgramTypeActions';

class ProgramTypeForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            programType: {
                title: '',
                accounts: [],
                item_quantity: 0,
                item_category_id: '',
                video_quantity: 0,
                price: '',
                public_price: '',
                // sort_order: 1
            }
        }
        this.inputChangeHandler = this.inputChangeHandler.bind(this);
        this.selectChangeHandler = this.selectChangeHandler.bind(this);
        this.formSubmitHandler = this.formSubmitHandler.bind(this);
        this.validator = new ReactValidator();
        if (this.props.formType === 'edit' && (this.props.id !== undefined || null)) {
            this.props.getProgramTypeCall(this.props.id);
        }
        this.props.getBrandsCall();
        this.props.getItemCategoriesCall();
    }

    inputChangeHandler(e) {
        const programType = { ...this.state.programType };
        programType[e.target.name] = e.target.value;
        this.setState({
            programType: programType
        })
    }

    selectChangeHandler(selectedOption, action) {
        const programType = { ...this.state.programType };
        if (Array.isArray(selectedOption)) {
            if (action.action === 'select-option') {
                selectedOption.forEach(option => {
                    if (!programType.accounts.includes(option)) {
                        programType[action.name].push(option);
                    }
                });
            } else if (action.action === 'remove-value') {
                programType[action.name] = programType[action.name].filter(item => {
                    return item.value !== action.removedValue.value
                })
            }
        } else {
            if (action.action === 'remove-value') {
                programType[action.name] = programType[action.name].filter(item => {
                    return item.value !== action.removedValue.value
                })
            } else {
                programType[action.name] = selectedOption.value;
            }
        }
        this.setState({
            programType: programType
        })
    }

    formSubmitHandler() {
        if (this.validator.allValid()) {
            const data = { ...this.state.programType };
            data.accounts = data.accounts.map(item => item.value);
            if (this.props.formType === 'add') {
                this.props.storeProgramTypeCall(data).then(
                    response => {
                        if ('hide' in this.props) {
                            this.props.hide();
                            this.props.getPagination();
                            console.log('function called successfully');
                        }
                    }
                );
            } else if (this.props.formType === 'edit') {
                this.props.updateProgramTypeCall(data, this.props.id).then(
                    response => {
                        if ('hide' in this.props) {
                            this.props.hide();
                            this.props.getPagination();
                            console.log('function called successfully');
                        }
                    }
                );
            }
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.programType !== this.props.programType) {
            this.setState({
                programType: this.props.programType
            })
        }
    }


    render() {
        const { brands, itemCategories } = this.props;
        const { programType } = this.state;
        console.log(programType)
        const title = this.props.formType === 'add' ? 'Create' : 'Edit';
        return (
            <div>
                <Grid fluid>
                    <Row>
                        <Col md={12} >
                            <Card title={title + ' Program Type'} bsClass={['innerCard']} content={
                                <React.Fragment>
                                    <div className='row' >
                                        <div className='col-md-4' >
                                            <div className='form-group' >
                                                <label>Title<span className="requiredClass">*</span></label>
                                                <input type='text' name='title' value={programType.title} onChange={this.inputChangeHandler} className='form-control' />
                                                {this.validator.message('Title', programType.title, 'required')}
                                            </div>
                                        </div>
                                        <div className='col-md-4' >
                                            <div className='form-group' >
                                                <label>Public Price<span className="requiredClass">*</span></label>
                                                <input type='text' onChange={this.inputChangeHandler} value={programType.public_price} name='public_price' className='form-control' />
                                                {this.validator.message('Public Price', programType.public_price, 'required')}
                                            </div>
                                        </div>
                                        <div className='col-md-4' >
                                            <div className='form-group' >
                                                <label>Equipment Quantity<span className="requiredClass">*</span></label>
                                                <input type='number' min={1} onChange={this.inputChangeHandler} value={programType.item_quantity} name='item_quantity' className='form-control' />
                                                {this.validator.message('Item Quantity', programType.item_quantity, 'required|min:1,num')}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row' >
                                        <div className='col-md-4' >
                                            <div className='form-group' >
                                                <label>Equipment Type<span className="requiredClass">*</span></label>
                                                <Select
                                                    value={returnLabelWithValue(programType.item_category_id, itemCategories)}
                                                    onChange={this.selectChangeHandler}
                                                    options={itemCategories}
                                                    isSearchable={true}
                                                    isMulti={false}
                                                    name='item_category_id'
                                                    placeholder={'Select Item Category'}
                                                />
                                                {this.validator.message('Item Category', programType.item_category_id, 'required')}
                                            </div>
                                        </div>
                                        <div className='col-md-4' >
                                            <div className='form-group' >
                                                <label>Video Quantity<span className="requiredClass">*</span></label>
                                                <input type='number' min={1} onChange={this.inputChangeHandler} value={programType.video_quantity} name='video_quantity' className='form-control' />
                                                {this.validator.message('Video Quantity', programType.video_quantity, 'required|min:1,num')}
                                            </div>
                                        </div>
                                        <div className='col-md-4' >
                                            <div className='form-group' >
                                                <label>Price<span className="requiredClass">*</span></label>
                                                <input type='text' onChange={this.inputChangeHandler} value={programType.price} name='price' className='form-control' />
                                                {this.validator.message('Price', programType.price, 'required')}
                                            </div>
                                        </div>

                                        {/* <div className='col-md-3' >
                                            <div className='form-group' >
                                                <label>Sort Order<span className="requiredClass">*</span></label>
                                                <input type='number' min={1} onChange={this.inputChangeHandler} value={programType.sort_order} name='sort_order' className='form-control' />
                                                {this.validator.message('Sort Order', programType.sort_order, 'required|min:1')}
                                            </div>
                                        </div> */}
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-4' >
                                            <div className='form-group' >
                                                <label>Brand<span className="requiredClass">*</span></label>
                                                <Select
                                                    value={this.state.programType.accounts.map(item => item)}
                                                    onChange={this.selectChangeHandler}
                                                    options={brands}
                                                    isSearchable={true}
                                                    isMulti={true}
                                                    name='accounts'
                                                    placeholder={'Select Brand'}
                                                />
                                                {this.validator.message('Brand', programType.accounts, 'min:1,array')}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='text-right my-20' >
                                        <button onClick={this.formSubmitHandler} className="backButton pt-sm text-xs no_radius pb-sm primary btn btn-lg btn-info" >Save</button>
                                    </div>
                                </React.Fragment>
                            } />
                        </Col>
                    </Row>
                </Grid>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { brands } = state.accountReducer;
    const { itemCategories } = state.itemCategoryReducer;
    const { programType } = state.programTypeReducer;
    return { brands, itemCategories, programType }
}

const mapDispatchToProps = dispatch => {
    return {
        getBrandsCall: () => { dispatch(getBrands()) },
        getItemCategoriesCall: () => { dispatch(getItemCategories()) },
        storeProgramTypeCall: (data) => { return dispatch(storeProgramType(data)) },
        getProgramTypeCall: (id) => { dispatch(getProgramType(id)) },
        updateProgramTypeCall: (data, id) => { return dispatch(updateProgramType(data, id)) }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(ProgramTypeForm);
