import React, { Component } from 'react';
import $ from 'jquery';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Validator from 'simple-react-validator'
import { s3StaticPath, getDemoVideoLink } from '../../helper/helperFunctions';
import { MdPhotoSizeSelectActual, MdLocalMovies } from 'react-icons/md';
import { IoMdPlay } from 'react-icons/io';
import { FaHandHoldingHeart, FaQuestion } from 'react-icons/fa';



import VideoIcon from './VideoIcon'
import MainClients from './MainClients';
class KeepsakeVideo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isHovered: false,
      full_name: '',
      phone: '',
      email: '',
      message: '',
      company: '',
      activeTabNo: 1,
      activeVideo: 1,
      bucket: 'https://grouptravelvideos.s3.amazonaws.com/',
      videoUrl: 'global/2016_DC_demo.mp4',
      location: 'Washington DC'
    };
    this.handleHover = this.handleHover.bind(this);
    this.activeTabFunction = this.activeTabFunction.bind(this);
    this.contactValidator = new Validator();
  }
  activeTabFunction(tab, name, url = null, location = null) {
    if (name === 'activeVideo') {
      if (this.state.activeVideo !== tab) {
        this.setState({ [name]: tab, videoUrl: url, location: location },);
      }
    } else {
      if (this.state.activeTabNo !== tab) {
        this.setState({ [name]: tab });
      }
    }
    return null;
  }

  handleVideo() {
    let demoVideo = document.getElementById("demoVideo");
    demoVideo.play();
  }
  inputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  submitContactForm = () => {
    if (this.contactValidator.allValid()) {
      const data = {
        full_name: this.state.full_name,
        phone: this.state.phone,
        email: this.state.email,
        message: this.state.message,
        company: this.state.company
      }
      this.props.sendContactEmail(data).then(
        res => {
          const reset = {
            full_name: '',
            phone: '',
            email: '',
            message: '',
            company: ''
          }
          this.setState(prevState => ({
            ...prevState,
            ...reset
          }), () => {
            this.contactValidator.hideMessages();
            this.contactValidator.purgeFields();
            this.forceUpdate();
          })
        },
        err => {

        }
      );
    } else {
      this.contactValidator.showMessages();
      this.forceUpdate();
    }
  }
  handleHover() {
    // this.setState(prevState => ({
    //     isHovered: !prevState.isHovered
    // }));

    $("#clients div.client").hover(function () {
      $("#clients div.client").removeClass("pink_gradient")
      $(this).addClass("pink_gradient")
    }, function () {
      $("#clients div.client").removeClass("pink_gradient")
    })
  }
  render() {
    const logos = [
      {
        logo: s3StaticPath('img/gtv_logos/group-travel-videos-wide-small.jpg'),
        name: 'Small Wide'
      },
      {
        logo: s3StaticPath('img/gtv_logos/group-travel-videos-wide-medium.jpg'),
        name: 'Medium Wide'
      },
      {
        logo: s3StaticPath('img/gtv_logos/group-travel-videos-wide-large.jpg'),
        name: 'Large Wide'
      },
      {
        logo: s3StaticPath('img/gtv_logos/group-travel-videos-wide-small.png'),
        name: 'Small Wide - Transparent Background'
      },
      {
        logo: s3StaticPath('img/gtv_logos/group-travel-videos-wide-medium.png'),
        name: 'Medium Wide - Transparent Background'
      },
      {
        logo: s3StaticPath('img/gtv_logos/group-travel-videos-wide-large.png'),
        name: 'Large Wide - Transparent Background'
      },
      {
        logo: s3StaticPath('img/gtv_logos/group-travel-videos-wide-white-small.png'),
        name: 'Small Wide - Transparent Background - White Text'
      },
      {
        logo: s3StaticPath('img/gtv_logos/group-travel-videos-wide-white-medium.png'),
        name: 'Medium Wide - Transparent Background - White Text'
      },
      {
        logo: s3StaticPath('img/gtv_logos/group-travel-videos-wide-white-large.png'),
        name: 'Large Wide - Transparent Background - White Text'
      },
      {
        logo: s3StaticPath('img/gtv_logos/group-travel-videos-tall-small.png'),
        name: 'Small Tall - Transparent Background'
      },
      {
        logo: s3StaticPath('img/gtv_logos/group-travel-videos-tall-medium.png'),
        name: 'Medium Tall - Transparent Background'
      },
      {
        logo: s3StaticPath('img/gtv_logos/group-travel-videos-tall-large.png'),
        name: 'Large Tall - Transparent Background'
      },
      {
        logo: s3StaticPath('img/gtv_logos/group-travel-videos-tall-white-small.png'),
        name: 'Small Tall - Transparent Background - White Text'
      },
      {
        logo: s3StaticPath('img/gtv_logos/group-travel-videos-tall-white-medium.png'),
        name: 'Medium Tall - Transparent Background - White Text'
      },
      {
        logo: s3StaticPath('img/gtv_logos/group-travel-videos-tall-white-medium.png'),
        name: 'Large Tall - Transparent Background - White Text'
      },
      // {
      //   logo: s3StaticPath('img/gtv_logos/group-travel-videos-storytelling-videos-small.jpg'),
      //   name: 'Small - Info Graphic for White Background'
      // },
      // {
      //   logo: s3StaticPath('img/gtv_logos/group-travel-videos_DVD.png'),
      //   name: 'DVD - Transparent Background'
      // },
      // {
      //   logo: s3StaticPath('img/gtv_logos/group-travel-videos_Sleeve_DVD.png'),
      //   name: 'DVD & Sleeve - Transparent Background'
      // },
      // {
      //   logo: s3StaticPath('img/gtv_logos/group-travel-videos-storytelling-videos.jpg'),
      //   name: 'Full Graphic - Storytelling Videos'
      // },
      // {
      //   logo: s3StaticPath('img/gtv_logos/group-travel-videos-after-your-trip.jpg'),
      //   name: 'Full Graphic - After Your Trip'
      // },
    ]
    const { loggingIn } = this.props;
    // const hoverClass = this.state.isHovered ? "client pink_gradient" : "client";
    // let sliderSettings = {
    //   dots: true,
    //   autoplay: true,
    //   infinite: true,
    //   speed: 500,
    //   slidesToShow: 1,
    //   slidesToScroll: 1,
    //   accessibility: false,
    //   className: 'benefits',
    //   prevArrow: <div><MdArrowBack /></div>,
    //   nextArrow: <div><MdArrowForward /></div>,
    // };
    const posters = {
      'Washington DC': 'washington-dc.jpg',
      'Chicago': 'Chicago.jpg',
      'New York City': 'New-York-City.jpg',
      'Orlando': 'Orlando.jpg',
      'Italy': 'Italy.jpg',
    }

    return (
      <React.Fragment>

        <section id="keepsake_video" className="section">
          <h2 className="panel_heading">Advertise Group Memories on Your Website</h2>

          <div className="container">
            <div className="row">
              <div className="col-md-12 mt-xlg">
                <div className="text-muted dark">
                  <p className="text_18 text-center">If you are not using our logo below on your website or in print materials, please replace it with a logo from this page. We also have a new demo video player, if you are currently using our old demo player, please replace it with the new one found on this page.</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="uploaderTabs" className="section bg_alter sampleVideos">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center">
                <div className="text_22">Generic Demo Player</div>
                <div className="text_16">(No Tour Operator Branding)</div>
              </div>
            </div>
            <div className="row mt-md">
              <div className="col-md-12">
                <div className="tabsContainer">
                  <div className="tabsHeader">
                    <div className="grid">
                      <div>
                        <a href="#/" type='button'
                          onClick={() => this.activeTabFunction(1, 'activeTabNo')}

                          className={"tabBtn text_16 " + (this.state.activeTabNo === 1 ? "activeTab red_gradient" : "medium_blue_gradient")}><MdLocalMovies className="mr-sm" />Sample Videos</a>
                      </div>
                      <div>
                        <a href="#/" type='button'
                          onClick={() => this.activeTabFunction(2, 'activeTabNo')}
                          className={"tabBtn text_16 " + (this.state.activeTabNo === 2 ? "activeTab red_gradient" : "medium_blue_gradient")}><FaQuestion className="mr-sm" />How it works</a>
                      </div>
                      <div>
                        <a href="#/" type='button'
                          onClick={() => this.activeTabFunction(3, 'activeTabNo')}
                          className={"tabBtn text_16 " + (this.state.activeTabNo === 3 ? "activeTab red_gradient" : "medium_blue_gradient")} ><FaHandHoldingHeart className="mr-sm" />Benefits</a>
                      </div>
                      <div>
                        <a href="#/" type='button'
                          onClick={() => this.activeTabFunction(4, 'activeTabNo')}
                          className={"tabBtn text_16 " + (this.state.activeTabNo === 4 ? "activeTab red_gradient" : "medium_blue_gradient")}><IoMdPlay className="mr-sm" />App Demo</a>
                      </div>
                      <div>
                        <a href="#/" type='button'
                          onClick={() => this.activeTabFunction(5, 'activeTabNo')}
                          className={"tabBtn text_16 " + (this.state.activeTabNo === 5 ? "activeTab red_gradient" : "medium_blue_gradient")}><MdPhotoSizeSelectActual className="mr-sm" />View Photos</a>
                      </div>
                    </div>
                  </div>

                  <div className="tabsContent">
                    <div className="mb-lg logos">
                      <img src={s3StaticPath('img/gtv_logos/group-travel-videos-wide-small.jpg')} alt="Group Memories Logo" />
                    </div>
                    {this.state.activeTabNo === 1 &&
                      <div className="contentContainer">
                        <div className="flexElem respFlex">
                          <div>
                            <div className="text_18 text-left">Click Below to View Sample Videos</div>

                            <ul className="sampleVideosList">
                              <li onClick={() => this.activeTabFunction(1, 'activeVideo', 'global/2016_DC_demo.mp4', 'Washington DC')}>
                                <VideoIcon iconClass={this.state.activeVideo === 1 ? "fa-spin" : ""} svgColor={this.state.activeVideo === 1 ? "url('#video-gradient')" : "currentColor"} svgID="video-gradient" />
                                <span> Washington DC</span>
                              </li>
                              <li onClick={() => this.activeTabFunction(2, 'activeVideo', 'global/2016_NY_demo.mp4', 'New York City')}>
                                <VideoIcon iconClass={this.state.activeVideo === 2 ? "fa-spin" : ""} svgColor={this.state.activeVideo === 2 ? "url('#video-gradient')" : "currentColor"} svgID="video-gradient" />
                                <span> New York City</span>
                              </li>
                              <li onClick={() => this.activeTabFunction(3, 'activeVideo', 'global/2016_orlando_demo.mp4', 'Orlando')}>
                                <VideoIcon iconClass={this.state.activeVideo === 3 ? "fa-spin" : ""} svgColor={this.state.activeVideo === 3 ? "url('#video-gradient')" : "currentColor"} svgID="video-gradient" />
                                <span> Orlando</span>
                              </li>
                              <li onClick={() => this.activeTabFunction(4, 'activeVideo', 'global/2016_chicago_demo.mp4', 'Chicago')}>
                                <VideoIcon iconClass={this.state.activeVideo === 4 ? "fa-spin" : ""} svgColor={this.state.activeVideo === 4 ? "url('#video-gradient')" : "currentColor"} svgID="video-gradient" />
                                <span> Chicago</span>
                              </li>
                            </ul>

                            <div className="text_18 mt-xlg text-left">Senior Travel</div>

                            <ul className="sampleVideosList">
                              <li onClick={() => this.activeTabFunction(5, 'activeVideo', 'global/2016_senior_italy_demo.mp4', 'Italy')}>
                                <VideoIcon iconClass={this.state.activeVideo === 5 ? "fa-spin" : ""} svgColor={this.state.activeVideo === 5 ? "url('#video-gradient')" : "currentColor"} svgID="video-gradient" />
                                <span> Italy</span>
                              </li>
                            </ul>
                          </div>

                          <div id="video" style={{ border: "none" }}>
                            <div className="vidContainer">
                              <div className="section_heading mt-none text-center">{this.state.location}</div>

                              <div className="video_placeholder mb-none">
                                <video onPlay={() => { $(".video_placeholder .video_play").hide() }} onPause={() => { $(".video_placeholder .video_play").show() }} id="demoVideo" controls src={this.state.bucket + this.state.videoUrl} type="video/mp4" poster={this.state.bucket + `static/img/${posters[this.state.location]}`}></video>
                                <div className="video_play blue_gradient hover_dark" onClick={this.handleVideo}></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    }

                    {this.state.activeTabNo === 2 &&
                      <div className="contentContainer">
                        <div className="flexElem">
                          <div>
                            <span className="howItWorksNumber purple_gradient">1</span>
                          </div>
                          <div>
                            <div className="text_16 howItWorksPoints">
                              We provide your group with a username &amp; password to login to our app and website to upload trip photos.
                                    </div>
                            <div className="text_14 mt-xs">
                              {/* We can also send you a camera or memory card. */}
                                    </div>
                          </div>
                        </div>

                        <div className="flexElem mt-xlg">
                          <div>
                            <span className="howItWorksNumber purple_gradient">2</span>
                          </div>
                          <div>
                            <div className="text_16 howItWorksPoints">
                              Your group uploads photos with our app or website while and/or after you travel.
                                    </div>
                            <div className="text_14 mt-xs">
                              {/* You return any of our cameras or cards back in the prepaid mailer we provide. */}
                                    </div>
                          </div>
                        </div>

                        <div className="flexElem mt-xlg">
                          <div>
                            <span className="howItWorksNumber purple_gradient">3</span>
                          </div>
                          <div>
                            <div className="text_16 howItWorksPoints">
                              We edit your photos and repost them to the app and website. Then we create your video from the edited photos.
                                    </div>
                            <div className="text_14 mt-xs">
                              Your photos can only be accessed with your group’s login.
                                    </div>
                          </div>
                        </div>

                        <div className="flexElem mt-xlg">
                          <div>
                            <span className="howItWorksNumber purple_gradient">4</span>
                          </div>
                          <div>
                            <div className="text_16 howItWorksPoints">
                             {/* We ship your DVDs to you and make your video available to stream on our app &amp; website. */}
                             We make your keepsake video available on our app and website for you to stream, download and save!
                                    </div>
                          </div>
                        </div>
                      </div>
                    }

                    {this.state.activeTabNo === 3 &&
                      <div className="contentContainer">
                        <div className="flexElem">
                          <div>
                            <span className="howItWorksNumber purple_blue_gradient"></span>
                          </div>
                          <div>
                            <div className="text_16 howItWorksPoints">
                            Private FREE Mobile App:
                                  </div>
                            <div className="text_14 mt-xs">
                              Allows you to upload and share photos while you travel.
                                  </div>
                          </div>
                        </div>

                        <div className="flexElem mt-xlg">
                          <div>
                            <span className="howItWorksNumber purple_blue_gradient"></span>
                          </div>
                          <div>
                            <div className="text_16 howItWorksPoints">
                              Live Video Clips &amp; Performance Music:
                                  </div>
                            <div className="text_14 mt-xs">
                                Photo Admins can include these in your videos for Free.
                                  </div>
                          </div>
                        </div>

                        <div className="flexElem mt-xlg">
                          <div>
                            <span className="howItWorksNumber purple_blue_gradient"></span>
                          </div>
                          <div>
                            <div className="text_16 howItWorksPoints">
                              Facebook Sharing:
                                  </div>
                            <div className="text_14 mt-xs">
                              You can easily share photos &amp; your highlight video on Facebook from the app or GroupMemories.co.uk photo gallery.
                                  </div>
                          </div>
                        </div>

                        <div className="flexElem mt-xlg">
                          <div>
                            <span className="howItWorksNumber purple_blue_gradient"></span>
                          </div>
                          <div>
                            <div className="text_16 howItWorksPoints">
                                Keepsake Video Download:
                                  </div>
                            <div className="text_14 mt-xs">
                              Videos can be downloaded from the app or GroupMemories.co.uk gallery for offline viewing.
                                  </div>
                          </div>
                        </div>

                        <div className="flexElem mt-xlg">
                          <div>
                            <span className="howItWorksNumber purple_blue_gradient"></span>
                          </div>
                          <div>
                            <div className="text_16 howItWorksPoints">
                              Photo Editing:
                                  </div>
                            <div className="text_14 mt-xs">
                              Our editors go through each photo to remove not needed or duplicate photos, do color correction, &amp; cropping.
                                  </div>
                          </div>
                        </div>

                        <div className="flexElem mt-xlg">
                          <div>
                            <span className="howItWorksNumber purple_blue_gradient"></span>
                          </div>
                          <div>
                            <div className="text_16 howItWorksPoints">
                            Keepsake Video:
                                  </div>
                            <div className="text_14 mt-xs">
                            Each traveler is able to stream, cast, share and download the video to preserve their memories for years to come!
                                  </div>
                          </div>
                        </div>
                      </div>
                    }

                    {this.state.activeTabNo === 4 &&
                      <div className="contentContainer">
                        <div className="video_placeholder mb-none">
                          {/* <img src={s3StaticPath('img/video_placeholder.jpg')} className="block" alt="Video placeholder" /> */}
                          {/* <video onPlay={() => { $(".video_placeholder .video_play").hide() }} onPause={() => { $(".video_placeholder .video_play").show() }} id="demoVideo" controls src={s3StaticPath('gtv_appDemo_apple.mp4')} type="video/mp4" poster={s3StaticPath('img/gtv_app_video.jpg')}></video> */}
                          <video onPlay={() => { $(".video_placeholder .video_play").hide() }} onPause={() => { $(".video_placeholder .video_play").show() }} id="demoVideo" controls src={getDemoVideoLink(this.props.location.search)} type="video/mp4" poster={s3StaticPath('img/gtv_app_video.jpg')}></video>
                          <div className="video_play blue_gradient hover_dark" onClick={this.handleVideo}></div>
                        </div>
                      </div>
                    }

                    {this.state.activeTabNo === 5 &&
                      <div className="contentContainer">
                        <div className="login_form">
                          <div className="text_16">Enter Your Group's Username and Password to view your photos on GroupMemories.co.uk:</div>
                          <form name="form">
                              <div className="form-group mt-md">
                                  <input type="text" placeholder="Email or Username" className="form-control email_ctrl" name="email" />
                              </div>
                              <div className="form-group">
                                  <input type="password" placeholder="Password" className="form-control password_ctrl" name="password" />
                              </div>
                              <div className="form-group">
                                  <button style={{width:'100%'}} className="btn btn-default btn_login">LOGIN</button>
                                  {
                                      loggingIn &&
                                      <img alt="imageLogin" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                  }
                              </div>
                          </form>
                      </div>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container mt-md">
            <div className="row">
              <div className="col-md-12">
                <p className="text_16 text-center">
                  To include this generic demo player (No Tour Operator Branding) on your website, copy the below code and paste into your website. Please do not alter the below code or it may cause the demo player to not work or to display incorrectly.
                </p>

                <textarea onFocus={(e) => {e.target.select()}} defaultValue={`<iframe src="https://www.grouptravelvideos.com/demo_container.php?dc=true&amp;orlando=true&amp;nyc=true&amp;chicago=true" style="border: none; margin:0 auto; display: block" name="GTV Demo Player" scrolling="yes" frameborder="1" marginheight="0px" marginwidth="0px" height="680" width="1000"></iframe>`} className="form-control mt-md"></textarea>
              </div>
            </div>
          </div>
        </section>

        <section id="uploaderTabs" className="section sampleVideos">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center">
                <div className="text_22">customised Demo Player</div>
                <div className="text_16">(With Your Tour Operator Branding and/Or Custom Demos)</div>
              </div>
            </div>
            <div className="row mt-md">
              <div className="col-md-12">
                <div className="tabsContainer">
                  <div className="tabsHeader">
                    <div className="grid">
                      <div>
                        <a href="#/" type='button'
                          onClick={() => this.activeTabFunction(1, 'activeTabNo')}

                          className={"tabBtn text_16 " + (this.state.activeTabNo === 1 ? "activeTab red_gradient" : "medium_blue_gradient")}><MdLocalMovies className="mr-sm" />Sample Videos</a>
                      </div>
                      <div>
                        <a href="#/" type='button'
                          onClick={() => this.activeTabFunction(2, 'activeTabNo')}
                          className={"tabBtn text_16 " + (this.state.activeTabNo === 2 ? "activeTab red_gradient" : "medium_blue_gradient")}><FaQuestion className="mr-sm" />How it works</a>
                      </div>
                      <div>
                        <a href="#/" type='button'
                          onClick={() => this.activeTabFunction(3, 'activeTabNo')}
                          className={"tabBtn text_16 " + (this.state.activeTabNo === 3 ? "activeTab red_gradient" : "medium_blue_gradient")} ><FaHandHoldingHeart className="mr-sm" />Benefits</a>
                      </div>
                      <div>
                        <a href="#/" type='button'
                          onClick={() => this.activeTabFunction(4, 'activeTabNo')}
                          className={"tabBtn text_16 " + (this.state.activeTabNo === 4 ? "activeTab red_gradient" : "medium_blue_gradient")}><IoMdPlay className="mr-sm" />App Demo</a>
                      </div>
                      <div>
                        <a href="#/" type='button'
                          onClick={() => this.activeTabFunction(5, 'activeTabNo')}
                          className={"tabBtn text_16 " + (this.state.activeTabNo === 5 ? "activeTab red_gradient" : "medium_blue_gradient")}><MdPhotoSizeSelectActual className="mr-sm" />View Photos</a>
                      </div>
                    </div>
                  </div>

                  <div className="tabsContent">
                    <div className="mb-lg logos">
                      <img src={s3StaticPath('img/gtv_logos/group-travel-videos-wide-small.jpg')} alt="Group Memories Logo" />
                      <img src={s3StaticPath('img/gtv_logos/your_logo.png')} height="66" alt="Your Logo" />
                    </div>
                    {this.state.activeTabNo === 1 &&
                      <div className="contentContainer">
                        <div className="flexElem respFlex">
                          <div>
                            <div className="text_18 text-left">Click Below to View Sample Videos</div>

                            <ul className="sampleVideosList">
                              <li onClick={() => this.activeTabFunction(1, 'activeVideo', 'global/2016_DC_demo.mp4', 'Washington DC')}>
                                <VideoIcon iconClass={this.state.activeVideo === 1 ? "fa-spin" : ""} svgColor={this.state.activeVideo === 1 ? "url('#video-gradient')" : "currentColor"} svgID="video-gradient" />
                                <span> Washington DC</span>
                              </li>
                              <li onClick={() => this.activeTabFunction(2, 'activeVideo', 'global/2016_NY_demo.mp4', 'New York City')}>
                                <VideoIcon iconClass={this.state.activeVideo === 2 ? "fa-spin" : ""} svgColor={this.state.activeVideo === 2 ? "url('#video-gradient')" : "currentColor"} svgID="video-gradient" />
                                <span> New York City</span>
                              </li>
                              <li onClick={() => this.activeTabFunction(3, 'activeVideo', 'global/2016_orlando_demo.mp4', 'Orlando')}>
                                <VideoIcon iconClass={this.state.activeVideo === 3 ? "fa-spin" : ""} svgColor={this.state.activeVideo === 3 ? "url('#video-gradient')" : "currentColor"} svgID="video-gradient" />
                                <span> Orlando</span>
                              </li>
                              <li onClick={() => this.activeTabFunction(4, 'activeVideo', 'global/2016_chicago_demo.mp4', 'Chicago')}>
                                <VideoIcon iconClass={this.state.activeVideo === 4 ? "fa-spin" : ""} svgColor={this.state.activeVideo === 4 ? "url('#video-gradient')" : "currentColor"} svgID="video-gradient" />
                                <span> Chicago</span>
                              </li>
                            </ul>

                            <div className="text_18 mt-xlg text-left">Senior Travel</div>

                            <ul className="sampleVideosList">
                              <li onClick={() => this.activeTabFunction(5, 'activeVideo', 'global/2016_senior_italy_demo.mp4', 'Italy')}>
                                <VideoIcon iconClass={this.state.activeVideo === 5 ? "fa-spin" : ""} svgColor={this.state.activeVideo === 5 ? "url('#video-gradient')" : "currentColor"} svgID="video-gradient" />
                                <span> Italy</span>
                              </li>
                            </ul>
                          </div>

                          <div id="video" style={{ border: "none" }}>
                            <div className="vidContainer">
                              <div className="section_heading mt-none text-center">{this.state.location}</div>

                              <div className="video_placeholder mb-none">
                                <video onPlay={() => { $(".video_placeholder .video_play").hide() }} onPause={() => { $(".video_placeholder .video_play").show() }} id="demoVideo" controls src={this.state.bucket + this.state.videoUrl} type="video/mp4" poster={this.state.bucket + `static/img/${posters[this.state.location]}`}></video>
                                <div className="video_play blue_gradient hover_dark" onClick={this.handleVideo}></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    }

                    {this.state.activeTabNo === 2 &&
                      <div className="contentContainer">
                        <div className="flexElem">
                          <div>
                            <span className="howItWorksNumber purple_gradient">1</span>
                          </div>
                          <div>
                            <div className="text_16 howItWorksPoints">
                              We provide your group with a username &amp; password to login to our app and website to upload trip photos.
                                    </div>
                            <div className="text_14 mt-xs">
                              {/* We can also send you a camera or memory card. */}
                                    </div>
                          </div>
                        </div>

                        <div className="flexElem mt-xlg">
                          <div>
                            <span className="howItWorksNumber purple_gradient">2</span>
                          </div>
                          <div>
                            <div className="text_16 howItWorksPoints">
                              Your group uploads photos with our app or website while and/or after you travel.
                                    </div>
                            <div className="text_14 mt-xs">
                              {/* You return any of our cameras or cards back in the prepaid mailer we provide. */}
                                    </div>
                          </div>
                        </div>

                        <div className="flexElem mt-xlg">
                          <div>
                            <span className="howItWorksNumber purple_gradient">3</span>
                          </div>
                          <div>
                            <div className="text_16 howItWorksPoints">
                              We edit your photos and repost them to the app and website. Then we create your video from the edited photos.
                                    </div>
                            <div className="text_14 mt-xs">
                              Your photos can only be accessed with your group’s login.
                                    </div>
                          </div>
                        </div>

                        <div className="flexElem mt-xlg">
                          <div>
                            <span className="howItWorksNumber purple_gradient">4</span>
                          </div>
                          <div>
                            <div className="text_16 howItWorksPoints">
                             {/* We ship your DVDs to you and make your video available to stream on our app &amp; website. */}
                             We make your keepsake video available on our app and website for you to stream, download and save!
                                    </div>
                          </div>
                        </div>
                      </div>
                    }

                    {this.state.activeTabNo === 3 &&
                      <div className="contentContainer">
                        <div className="flexElem">
                          <div>
                            <span className="howItWorksNumber purple_blue_gradient"></span>
                          </div>
                          <div>
                            <div className="text_16 howItWorksPoints">
                                Private FREE Mobile App:
                                  </div>
                            <div className="text_14 mt-xs">
                              Allows you to upload and share photos while you travel.
                                  </div>
                          </div>
                        </div>

                        <div className="flexElem mt-xlg">
                          <div>
                            <span className="howItWorksNumber purple_blue_gradient"></span>
                          </div>
                          <div>
                            <div className="text_16 howItWorksPoints">
                              Live Video Clips &amp; Performance Music:
                                  </div>
                            <div className="text_14 mt-xs">
                                Photo Admins can include these in your videos for Free.
                                  </div>
                          </div>
                        </div>

                        <div className="flexElem mt-xlg">
                          <div>
                            <span className="howItWorksNumber purple_blue_gradient"></span>
                          </div>
                          <div>
                            <div className="text_16 howItWorksPoints">
                              Facebook Sharing:
                                  </div>
                            <div className="text_14 mt-xs">
                              You can easily share photos &amp; your highlight video on Facebook from the app or GroupMemories.co.uk photo gallery.
                                  </div>
                          </div>
                        </div>

                        <div className="flexElem mt-xlg">
                          <div>
                            <span className="howItWorksNumber purple_blue_gradient"></span>
                          </div>
                          <div>
                            <div className="text_16 howItWorksPoints">
                                Keepsake Video Download:
                                  </div>
                            <div className="text_14 mt-xs">
                              Videos can be downloaded from the app or GroupMemories.co.uk gallery for offline viewing.
                                  </div>
                          </div>
                        </div>

                        <div className="flexElem mt-xlg">
                          <div>
                            <span className="howItWorksNumber purple_blue_gradient"></span>
                          </div>
                          <div>
                            <div className="text_16 howItWorksPoints">
                              Photo Editing:
                                  </div>
                            <div className="text_14 mt-xs">
                              Our editors go through each photo to remove not needed or duplicate photos, do color correction, &amp; cropping.
                                  </div>
                          </div>
                        </div>

                        <div className="flexElem mt-xlg">
                          <div>
                            <span className="howItWorksNumber purple_blue_gradient"></span>
                          </div>
                          <div>
                            <div className="text_16 howItWorksPoints">
                            Keepsake Video:
                                  </div>
                            <div className="text_14 mt-xs">
                            Each traveler is able to stream, cast, share and download the video to preserve their memories for years to come!
                                  </div>
                          </div>
                        </div>
                      </div>
                    }

                    {this.state.activeTabNo === 4 &&
                      <div className="contentContainer">
                        <div className="video_placeholder mb-none">
                          {/* <img src={s3StaticPath('img/video_placeholder.jpg')} className="block" alt="Video placeholder" /> */}
                          {/* <video onPlay={() => { $(".video_placeholder .video_play").hide() }} onPause={() => { $(".video_placeholder .video_play").show() }} id="demoVideo" controls src={s3StaticPath('gtv_appDemo_apple.mp4')} type="video/mp4" poster={s3StaticPath('img/gtv_app_video.jpg')}></video> */}
                          <video onPlay={() => { $(".video_placeholder .video_play").hide() }} onPause={() => { $(".video_placeholder .video_play").show() }} id="demoVideo" controls src={getDemoVideoLink(this.props.location.search)} type="video/mp4" poster={s3StaticPath('img/gtv_app_video.jpg')}></video>
                          <div className="video_play blue_gradient hover_dark" onClick={this.handleVideo}></div>
                        </div>
                      </div>
                    }

                    {this.state.activeTabNo === 5 &&
                      <div className="contentContainer">
                        <div className="login_form">
                          <div className="text_16">Enter Your Group's Username and Password to view your photos on GroupMemories.co.uk:</div>
                          <form name="form">
                              <div className="form-group mt-md">
                                  <input type="text" placeholder="Email or Username" className="form-control email_ctrl" name="email" />
                              </div>
                              <div className="form-group">
                                  <input type="password" placeholder="Password" className="form-control password_ctrl" name="password" />
                              </div>
                              <div className="form-group">
                                  <button style={{width:'100%'}} className="btn btn-default btn_login">LOGIN</button>
                                  {
                                      loggingIn &&
                                      <img alt="imageLogin" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                  }
                              </div>
                          </form>
                      </div>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container mt-md">
            <div className="row">
              <div className="col-md-12">
                <p className="text_16 text-center">
                  To include a customised demo player (With Your Tour Operator Branding and/or Custom Demos) on your website, contact Dave for the custom code.
                </p>
              </div>
            </div>

            <div className="row" id="contactUs">
              <div className="col-md-8 text-center col-md-offset-2 mt-xlg">
                <p className="text_18">Dave Martinson</p>
                <p className="text_16">Dave@grouptravelvideos.com</p>
                <p className="text_16">1-888-533-7637 ext. 205</p>
              </div>
              <div className="col-md-8 col-md-offset-2 mt-xlg contactFormContainer">
                  <div className="flexElem respFlex">
                    <div className="hasFieldIcon">
                      <span className="fieldIcon userIcon">&nbsp;</span>
                      <input onChange={this.inputChange} value={this.state.full_name} type="text" name='full_name' className="form-control" placeholder="Full Name" />
                      {this.contactValidator.message('full_name', this.state.full_name, 'required')}
                    </div>
                    <div className="hasFieldIcon">
                      <span className="fieldIcon companyIcon">&nbsp;</span>
                      <input onChange={this.inputChange} value={this.state.company} name='company' type="text" className="form-control" placeholder="Company" />
                      {this.contactValidator.message('company', this.state.company, 'required')}
                    </div>
                  </div>

                  <div className="flexElem respFlex">
                    <div className="hasFieldIcon">
                      <span className="fieldIcon emailIcon">&nbsp;</span>
                      <input onChange={this.inputChange} value={this.state.email} name='email' type="email" className="form-control" placeholder="Email" />
                      {this.contactValidator.message('email', this.state.email, 'required|email')}
                    </div>
                    <div className="hasFieldIcon">
                      <span className="fieldIcon userIcon">&nbsp;</span>
                      <input onChange={this.inputChange} value={this.state.phone} name='phone' type="text" className="form-control" placeholder="Phone" />
                      {this.contactValidator.message('phone', this.state.phone, 'required|phone')}
                    </div>
                  </div>

                  <div className="flexElem block respFlex">
                    <div><textarea name='message' value={this.state.message} onChange={this.inputChange} className="form-control" placeholder="Message"></textarea></div>
                  </div>
                  {this.contactValidator.message('message', this.state.message, 'required')}

                  <div className="flexElem block respFlex">
                    <div className="text-center">
                      <button onClick={this.submitContactForm} type="button" className="themeBtn_14 no_radius hover_dark red_gradient">Submit Contact Form</button>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </section>

        <section className="section" style={{backgroundColor: '#2481BC', color: '#fff'}}>
            <div className="container">
              <div className="row">
                <div className="col-md-12 text-center">
                  <div style={{fontSize: '2em', fontFamily: '"Poppins", sans-serif'}}>Logos Terms and Conditions:</div>

                  <p>You may choose any one of these logos to include on your company website only for use as a link to www.groupmemories.co.uk, to inform your travelers that you offer Group Memories with your tours, or to explain the benefits of Group Memories™. Only resellers of Group Memories are permitted to use the logo without express permission, or others who acquire the express consent of PhotoVision, Inc.</p>
                </div>

                <div className="col-md-12 mt-lg">
                  <p className="text_18">To Download a logo, right click the logo and go to "Save Image As..." (Firefox) or "Save Picture As..." (Internet Explorer).</p>
                </div>

                {
                  logos.map((item, index) => (
                    <div className="col-md-12 text-center" style={{marginTop:'4em'}} key={index}>
                      <div className="text_22">
                        {item.name}
                      </div>
                      <div className="mt-sm">
                        <img src={item.logo} alt={item.name} />
                      </div>
                    </div>
                  ))
                }
              </div>
            </div>
        </section>
      </React.Fragment>
    );
  }
}

export default KeepsakeVideo;
