import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Grid,
  Row,
  Col,
  OverlayTrigger,
  Popover
} from "react-bootstrap";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Card } from "components/Card/Card.jsx";
import { getDestinationsPagination, getDestination, deleteDestination,  editDestination, editAllDestination  } from 'redux/actions/destination-actions';
// import { permissionActions } from 'redux/actions/permission-action';
import 'react-table/react-table.css';
import DestinationForm from "./DestinationForm";
import { SC } from 'helper/ServerCall';
// import { FormInputs } from "components/FormInputs/FormInputs.jsx";
import { hasPermission } from 'helper/hasPermission';
import Confirmalertfordelete from 'components/ThemeComponents/confirmAlertForDelete';
import { adminLabels } from 'redux/constant/admin-label-constant';
import OpenModalButton from 'components/ThemeComponents/openModelButton';
import { helperFunctions as HF } from 'helper/helperFunctions';
// import { Link } from 'react-router-dom';
import { objectsConstants as OC } from 'redux/constant/objects-constant';
import ThemeModal from 'components/ThemeComponents/ThemeModal';
import Pagination from '../../ThemeComponents/Pagination';
// var Modal = require('react-bootstrap-modal');

class DestinationsListPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addModalDestination: this.props.addModalDestination,
      editModalDestination: this.props.editModalDestination,
      onCloseAfterApi: false,
      sorted: '',
      filtered: '',
      pageSize: 10,
      page: 0,
      destinations: [],
      updateDestination: [],
      is_active: '1',
      in_active_order_assign_desination: false
    };
    this.toggleModal = HF.toggleModal.bind(this);
    this.checkAll = this.checkAll.bind(this);
    this.submitHandler = this.submitHandler.bind(this);
    this.unCheckAll = this.unCheckAll.bind(this);
  };
    checkChange = (event, id ) => {
    const check = event.target.checked;
    let newDestinations = [...this.state.destinations];
    let active = this.state.updateDestination.map(item=>{
      if(item.id===id){
        item.is_active = check;
        return item;
      }
      return item;
    })
    this.setState({
      updateDestination: [...active]
    })
    if (check){
      newDestinations.push(id);
      this.setState({
        destinations: newDestinations
      })
    }else{
      const i = newDestinations.findIndex((el) => {
        return el === id;
    });
        newDestinations.splice(i, 1);
        this.setState({
          destinations: newDestinations
        })
    }
}
checkAll = () => {

  const destinations = [];
  this.props.destinationsData.forEach(item => {
    destinations.push(item.id );
  })
  const new_arr =  this.props.destinationsData.map (item => {
    return {
      ...item,
      is_active: true
    }
  })
  this.setState({
    destinations,
    updateDestination: new_arr,
  })
}
unCheckAll =() => {
  const new_arr =  this.props.destinationsData.map (item => {
    return {
      ...item,
      is_active: false
    }
  })
   this.setState({
    destinations: [],
    updateDestination: new_arr,
  })
}
submitHandler = () => {
  if (this.state.updateDestination.length > 0){
     let data ={destinations:this.state.updateDestination }
      this.props.editAllDestinationCall(data);
  }
  else{
    this.props.editAllDestinationCall(this.props.destinationsData);
  }
};
  filterSearchHandle = (e) => {
    this.setState({
      'filtered': e.target.value
    });
  }
  deleteDestination = (userId) => {
    this.props.deleteDestinationCall(userId).then(response => {
      this.pagination.dataCall();
    });
  }
  deleteConfirmDestination = (userId) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <Confirmalertfordelete typeName="Destination" description="Are you sure to delete the destination?" onClosePro={onClose} deleteType={() => this.deleteDestination(userId)} />
        )
      }
    })
  }
  destinationsPaginationData = (page = this.state.page, filtered = this.state.filtered, sorted = this.state.sorted, pageSize = this.state.pageSize, is_active = this.state.is_active) => {
    this.props.getDestinationsPaginationCall(page, filtered, sorted, pageSize, is_active);
  }
  componentDidUpdate(prevProps, prevState) {
    if ((prevProps.editDestination !== this.props.editDestination) && this.props.editDestination) {
      this.setState({ editModalDestination: true });
    }
    if (prevProps.current_page !== this.props.current_page) {
      this.setState({ page: this.props.current_page })
    }
    if ((!prevState.addModalDestination && this.state.addModalDestination) || (!prevState.editModalDestination && this.state.editModalDestination)) {
      this.setState({ onCloseAfterApi: false })
    }
    if ((prevState.addModalDestination && !this.state.addModalDestination && this.state.onCloseAfterApi) || (prevState.editModalDestination && !this.state.editModalDestination && this.state.onCloseAfterApi)) {
      this.pagination.dataCall();
    }
    if (prevProps.destinationsData !== this.props.destinationsData){
        const destinations = [];
        this.props.destinationsData.forEach(item => {
         if(item.is_active){
           destinations.push(item.id);
        }
        })
        this.setState({
          destinations,
          updateDestination:[...this.props.destinationsData]
        })
      }

      if (this.state.is_active !== prevState.is_active) {
        this.pagination.dataCall();
    }
  }

  paginationCall = (data) => {
    return this.props.getDestinationsPaginationCall(data.page, data.filter, data.sort, data.pageSize, this.state.is_active);
  }

  allDestCall = () => {
    this.pagination.dataCall(this.state.is_active='-1');
    this.setState({
        in_active_order_assign_desination: false
    })
  }

  InActiveOrderAssignDesination = () => {
    this.pagination.dataCall(this.state.is_active='-2');
    this.setState({
        in_active_order_assign_desination: true
    })
  }

  DeletedOrderAssignDesination = () => {
    this.pagination.dataCall(this.state.is_active='-3');
    this.setState({
        in_active_order_assign_desination: true
    })
  }

  updateDestinationStatus = (id, data) => {
    return SC.postCall('updateDestinationStatus/' + id, data );
}


  handleActiveDestination = (e) => {
      this.pagination.dataCall(this.state.is_active='1');
      this.setState({
          in_active_order_assign_desination: false
      })
  }
  handleInActiveDestination = (e) => {
      this.pagination.dataCall(this.state.is_active='0');
      this.setState({
          in_active_order_assign_desination: false
      })
  }

  handleUpdateDestinationStatus = destinationId => (e) => {
    this.updateDestinationStatus(destinationId, { is_active: e.target.checked ? true : false }).then(
      response => {
        this.pagination.dataCall();
      }
    );
  }

  render() {
    const { /*filtered*/ } = this.state;
    const { editDestination,/* destinationsData, pages, loadingDestination*/ } = this.props;
    const columns = [
      {
        Header: "Destinations Listing",
        headerClassName: 'headerPagination',
        columns: [
          {
            Header: "Destination Name",
            accessor: "title",
            className: "acer"
          },
        {
          Header: "Active",
          accessor: "id",
          show: this.state.in_active_order_assign_desination ? false : true,
          Cell: ({ row, original}) => (
            //   console.log(row, 'rowwww'),
            <label className="container-check">
              &nbsp;
              <input type="checkbox" name="is_active" className="checkbox" value={original.is_active ? true : false} onChange={this.handleUpdateDestinationStatus(original.id)} checked={original.is_active ? true : false}
                readOnly={true} /><span className="checkmark"></span>
            </label>
          ),
        //   Cell: ({ original }) => (
        //     <label className="container-check">
        //       &nbsp;
        //       <input
        //         type="checkbox"
        //         name="is_active"
        //         className="checkbox"
        //         checked={this.state.destinations.includes(original.id)}
        //         onChange={(event)=>{this.checkChange(event,original.id)}}
        //       /><span className="checkmark"></span>
        //     </label>
        //   ),
          className: "action-center",
          sortable: false
        },
          {
            Header: "Actions",
            accessor: "id",
            show: this.state.in_active_order_assign_desination ? false : true,
            Cell: ({ value }) => (
              <div>
                <OverlayTrigger placement="bottom" overlay={<Popover id="tooltip">{hasPermission('destination-edit') ? 'Edit Destination' : adminLabels.DONT_ALLOW}</Popover>}>
                  <span>
                    {
                      hasPermission('destination-edit') &&
                      <button type='button' className="editIcon orderDetailsAction" onClick={() => this.props.getDestinationCall(value)}>&nbsp;</button>
                    }
                    {
                      !hasPermission('destination-edit') &&
                      <button type='button' className="editIcon orderDetailsAction button_disabled" >&nbsp;</button>
                    }
                  </span>
                </OverlayTrigger>
                <OverlayTrigger placement="bottom" overlay={<Popover id="tooltip">{hasPermission('destination-delete') ? 'Delete Destination' : adminLabels.DONT_ALLOW}</Popover>}>
                  <span>
                    {
                      hasPermission('destination-delete') &&
                      <button type='button' className="deleteIcon ml-xs orderDetailsAction" onClick={() => this.deleteConfirmDestination(value)}>&nbsp;</button>
                    }
                    {
                      !hasPermission('destination-delete') &&
                      <button type='button' className="deleteIcon ml-xs orderDetailsAction button_disabled" >&nbsp;</button>
                    }
                  </span>
                </OverlayTrigger>
              </div>
            ),
            className: "action-center",
            sortable: false
          },
          {
            Header: "Orders",
            accessor: "barcode",
            width: 200,
            className: "action-center",
            show: this.state.in_active_order_assign_desination ? true : false,
            Cell: ({ value, original }) => (
              /*add true in below conditions just for time to ignore condition */
              <OverlayTrigger placement="bottom" overlay={<Popover id="tooltip">Assign Destinations to Orders</Popover>}>
                {
				// history.push(`/Admin/' + original.id + '/assign-order-destinations`),
                    <Link to={'/Admin/' + original.id + '/assign-order-destinations'} className="blue_link barcode" target="_blank" >
                      <span>
                        View Orders({original.orders_count})
                      </span>
                    </Link>
                }
              </OverlayTrigger>
            ),
          },
        ]
      }
    ];
    return (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                ctTableResponsive
                content={
                  <div>
                    <Grid fluid>
                      <Row>
                        <Col md={12}>
                          <div className="secHeading">
                            Destinations List
                          </div>
                        </Col>
                        <Col md={12}>
                          <div className="flexElem flexResponsive" style={{ "alignItems": "flex-start", "justifyContent": "space-between" }}>
                            <div className="">
                              <OpenModalButton
                                openModal={hasPermission('destination-create') ? HF.openModal.bind(this, "addModalDestination") : null}
                                classButton={['backButton pt-sm no_radius pb-sm primary mt-none btn-block btn-info btn', !hasPermission('destination-create') ? 'button_disabled' : '']}
                                buttonName="Add Destination"
                                tooltipText={hasPermission('destination-create') ? 'Add Destination' : adminLabels.DONT_ALLOW}
                                classIcon={['fa', 'fa-plus']}
                              />
                            </div>
                            <div className="custom-search-input">
                              {/* <FormInputs
                                ncols={["col-md-12"]}
                                onChange={this.filterSearchHandle}
                                proprieties={[
                                  {
                                    type: "text",
                                    bsClass: "form-control",
                                    placeholder: "Search Destinations",
                                    onChange: this.filterSearchHandle,
                                    name: "filter"
                                  }
                                ]}
                              /> */}
                            </div>
                          </div>
                        </Col>

                        <Col md={12}>
                                {/* <div className='pull-left' >
                                    <button style={{ marginRight: '10px' }} className='backButton my-20 pt-sm no_radius pb-sm primary text-xs btn btn-lg btn-info btn btn-lg btn-info  btn btn-default' onClick={this.checkAll}  >Check All</button>
                                    <button style={{ marginRight: '10px' }} className='backButton my-20 pt-sm no_radius pb-sm primary text-xs btn btn-lg btn-info btn btn-lg btn-info  btn btn-default' onClick={this.unCheckAll}  >Un-Check All</button>
                                    <button style={{ marginRight: '10px' }} className='backButton my-20 pt-sm no_radius pb-sm primary text-xs btn btn-lg btn-info btn btn-lg btn-info  btn btn-default' onClick={this.submitHandler} >Submit</button>
                                </div> */}
                                <div className='d-inline-block mt-md' >
                                    <div className="radio radio-primary radio-inline m-0">
                                        <input onChange={(e) => this.handleActiveDestination()} type="radio" id="inlineRadio1" value="1" name="orders_type" defaultChecked />
                                        <label htmlFor="inlineRadio1"><strong>Active</strong></label>
                                    </div>
                                    <div className="radio radio-primary radio-inline m-0">
                                        <input onChange={(e) => this.handleInActiveDestination()} type="radio" id="inlineRadio2" value="0" name="orders_type" />
                                        <label htmlFor="inlineRadio2"><strong>In-Active</strong></label>
                                    </div>
                                    <div className="radio radio-primary radio-inline m-0">
                                        <input onChange={(e) => this.allDestCall()} type="radio" id="inlineRadio3" value="2" name="orders_type" />
                                        <label htmlFor="inlineRadio3"><strong>All</strong></label>
                                    </div>
                                    <div className="radio radio-primary radio-inline m-0">
                                        <input onChange={(e) => this.InActiveOrderAssignDesination()} type="radio" id="inlineRadio4" value="3" name="orders_type" />
                                        <label htmlFor="inlineRadio4"><strong>In-Active Still Assigned To Orders</strong></label>
                                    </div>
                                    <div className="radio radio-primary radio-inline m-0">
                                        <input onChange={(e) => this.DeletedOrderAssignDesination()} type="radio" id="inlineRadio5" value="4" name="orders_type" />
                                        <label htmlFor="inlineRadio5"><strong>Deleted Still Assigned To Orders</strong></label>
                                    </div>
                                </div>
                        </Col>
                        {/* <Col md={12} className="mt-md">
                          <ReactTable
                            noDataText= 'No destination found'
                            data={destinationsData}
                            pages={pages}
                            columns={columns}
                            filtered={filtered}
                            defaultPageSize={10}
                            loading={loadingDestination}
                            className="-striped listing"
                            loadingText={'Loading...'}
                            pageData={this.destinationsPaginationData}
                            manual
                            onFetchData={(state, instance) => {
                              var sort = state.sorted.length === 0 ? '' : state.sorted[0].id + ',desc:' + state.sorted[0].desc;
                              state.pageData(state.page + 1, state.filtered, sort, state.pageSize);
                            }}
                          />
                        </Col> */}
                        <Pagination
                          ref={(p) => this.pagination = p}
                          showPagination={false}
                          columns={columns}
                          pageSize={10}
                          getDataCall={this.paginationCall}
                          filterView={true}
                          noDataText='No destination found'
                          // downloadData={true}
                          // downloadFileName={'Orders'}
                          // lowerContent = {null}
                          filterPlaceHolder={'Destinations'}
                          getRowProps={this.getRowProps}
                        // showAllToggle={true}
                        />
                      </Row>
                    </Grid>

                    {/*Add Destination Modal Start*/}
                    <ThemeModal modalClass="" title='Add Destination' show={this.state.addModalDestination} hide={() => { this.toggleModal('addModalDestination') }} >
                      <DestinationForm
                        formTypePro="add"
                        destinationPro={OC.DESTINATION}
                        closeModel={HF.closeModalAfterApi.bind(this, "addModalDestination", "onCloseAfterApi")}
                      />
                    </ThemeModal>
                    {/*Add Destination Modal End*/}

                    {/*Edit Destination Modal start*/}
                    {
                      editDestination &&
                      <ThemeModal modalClass="" title='Edit Destination' show={this.state.editModalDestination} hide={() => { this.toggleModal('editModalDestination') }} >
                        <DestinationForm
                          formTypePro="edit"
                          destinationPro={editDestination}
                          closeModel={HF.closeModalAfterApi.bind(this, "editModalDestination", "onCloseAfterApi")}
                        />
                      </ThemeModal>
                    }
                    {/*Edit Destination Modal End*/}
                  </div>
                } />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  };
};

const mapDispatchToProps = dispatch => {
  return ({
    getDestinationsPaginationCall: (page, filter, sort, pageSize, is_active) => { return dispatch(getDestinationsPagination(page, filter, sort, pageSize, is_active)) },
    getDestinationCall: (id) => { dispatch(getDestination(id)) },
    deleteDestinationCall: (id) => { return dispatch(deleteDestination(id)) },
    editAllDestinationCall: (destinations) =>{return dispatch(editAllDestination(destinations)) },
  });
};

function mapStateToProps(state) {
  const { editDestination, loadingDestination, destinationsData, pages, current_page, editAllDestination, } = state.destinationsReducer;
  return {
    editDestination, loadingDestination, destinationsData, pages, current_page , editAllDestination,
  };
};

const Destinations = connect(mapStateToProps, mapDispatchToProps)(DestinationsListPage);
export default Destinations;
