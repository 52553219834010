import React from "react";
import Slider from "react-slick";
import { connect } from 'react-redux'
import { getTestimonials } from "redux/actions/testimonials";
import User from "helper/User";
import { s3StaticPath } from 'helper/helperFunctions';

class TestiCarousel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            nav1: null,
            nav2: null
        }
        this.props.getTestimonials(User.brand('id'), 4, '', '')
    }

    componentDidMount() {
        this.setState({
            nav1: this.slider1,
            nav2: this.slider2
        })
    }

    render() {
        const SlickButtonFix = ({ currentSlide, slideCount, children, ...props }) => (
            <div {...props}>{children}</div>
        );

        //These images should be dynamic *Recomended by Gulzar*
        const images = [
            { src: s3StaticPath('img/packing_1.png'), title: 'Grant Doyle Educational Tours-FL', date: 'Sep 28 2012' },
            { src: s3StaticPath('img/packing_2.png'), title: 'Grant Doyle Educational Tours-FL', date: 'Sep 28 2012' },
            { src: s3StaticPath('img/packing_3.png'), title: 'Grant Doyle Educational Tours-FL', date: 'Sep 28 2012' },
            { src: s3StaticPath('img/packing_4.png'), title: 'Grant Doyle Educational Tours-FL', date: 'Sep 28 2012' }
        ];

        let settings = {
            dots: true,
            customPaging: function (i) {
                return (
                    <span className="thumb">
                        <img alt={images[i].title} src={images[i].src} />
                    </span>
                );
            },
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            prevArrow: <SlickButtonFix><img src={s3StaticPath('img/arrow_prev.png')} alt="" /></SlickButtonFix>,
            nextArrow: <SlickButtonFix><img src={s3StaticPath('img/arrow_next.png')} alt="" /></SlickButtonFix>,
        };

        let settings2 = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            accessibility: false,
            className: 'testi_info'
        };
        const testimonialsContent = this.props.testimonails.data.map(testimonial => {
            return (
                <div key={testimonial.id} className="testi_container">
                    <p><strong>{testimonial.id}</strong> We received our videos today. They are excellent and the kids love watching it. You folks are so easy to work with. We appreciate everything you do for us and the good work you do with our pictures. Thanks again and we look forward to working with you next year.</p>
                </div>
            );
        });

        const testmionialsAuthor = this.props.testimonails.data.map(testimonial => {
            return (
                <div key={testimonial.id} className='info'>
                    <div>{testimonial.author}, {testimonial.company}</div>
                    <div>{testimonial.created_date}</div>
                </div>
            )
        })
        return (
            <React.Fragment>
                <Slider {...settings} asNavFor={this.state.nav2} ref={slider => (this.slider1 = slider)}>
                    {testimonialsContent}
                </Slider>

                <Slider {...settings2} asNavFor={this.state.nav1} ref={slider => (this.slider2 = slider)}>
                    {testmionialsAuthor}
                </Slider>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    const { testimonails, pages, perPage, current_page } = state.testimonialReducer;
    return {
        testimonails, pages, perPage, current_page
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getTestimonials: (brand_id, pageSize, sort, filter) => { dispatch(getTestimonials(brand_id, pageSize, sort, filter)) }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TestiCarousel);