
import React, { Component } from "react";
import DashboardSuperAdmin from './DashboardSuperAdmin';


class Dashboard extends Component {
	render(){
		return(
			<div className="content">
				{
					<DashboardSuperAdmin />
				}
				
			</div>
		);
	};
}



export default Dashboard;
