import React, { Component } from "react";
import { connect } from 'react-redux';
import OpenModalButton from 'components/ThemeComponents/openModelButton';
import AssignedItemsGrid from './AssignedItemsGrid';
import { openModal, closeModal } from 'helper/helperFunctions';
import { updateInventoryLocation } from 'redux/actions/agency-actions';
import { unassignOrderShippingItem } from "redux/actions/item-actions";
var Modal = require('react-bootstrap-modal');

class AssignedItemShowAndModalPage extends Component {
	constructor(props) {
		super(props);
		this.state = {

			assignedItemModal: false,
			itemType: 'Physical Products',
		}

	}
	handleChangeLocation = (key, orderShippingId, type) => (selectedOptionType, info) => {

		if (info['action'] === 'select-option') {
			this.props.updateInventoryLocationCall(key, { inventory_location_id: selectedOptionType['id'] }, 'assignedItems', orderShippingId, type);
		}
	}

	unassignOrderShippingItem = (orderShippingId) => {
		this.props.unassignOrderShippingItemCall(orderShippingId, this.state.itemType).then(response => {
			console.log('response', response);
		});
	}
	render() {
		const { assignedItemModal } = this.state;
		const { camera_ship_status, assignedItems, orderId, order_details, shipping_details } = this.props;
		return (
			<div>
				<hr />
				<div className="orderContent" style={{ border: "none", marginTop: "0" }}>
					<div id="pricing_details">
						<div className="flexElem" style={{ "flexWrap": "wrap", "justifyContent": "space-between" }}>
							<div className="orderDetailsHeader">
								<span>Assigned Assets</span>
								<span className="controls">
									{
										<OpenModalButton
											openModal={openModal.bind(this, "assignedItemModal")}
											classButton={['editIcon orderDetailsAction']}
											buttonName="Add Assets"
											tooltipText="Add Assets"
											classIcon={[]}
										/>
									}
								</span>
							</div>
							<div style={{ width: "100%" }}>
								{
									(
										assignedItems && Object.keys(assignedItems).length > 0
									) &&
									<React.Fragment>
										<table className="table orderTable mt-none evenly table-striped">
											<thead>
												<tr>
													<th className="text-center"><strong>Barcode</strong></th>
													<th className="text-left"><strong>Asset</strong></th>
													{/*<th className="text-left"><strong>Unassign</strong></th>*/}
													{/* <th className="text-center"><strong>Action</strong></th> */}
												</tr>
											</thead>
											<tbody>
												{

													Object.keys(assignedItems).map(key => {
														return (
															(assignedItems[key].inventory && assignedItems[key].item) &&
															<tr key={key} >
																<td className="text-center">{assignedItems[key].inventory.barcode}</td>
																<td className="text-left">{assignedItems[key].item.title}</td>
																{/*<td className="text-left" ><button className='customBtn btn ml-sm btn-primary btn btn-default' onClick={()=>this.unassignOrderShippingItem(assignedItems[key].id)} >Un Assign</button></td>*/}
																{/* 
																<td className="text-center">
																	<ChangeInvLoc 
																		handleChangeLocation={this.handleChangeLocation(assignedItems[key].inventory.id, assignedItems[key].order_shippings_id, "Physical Products")}
																		inventory_location_stats_object={assignedItems[key].inventory.inventory_location_stats}
																	/>
																</td>
																 */}
															</tr>
														);
													})
												}
											</tbody>
										</table>
									</React.Fragment>
								}
							</div>
						</div>
					</div>
				</div>
				<Modal backdrop={'static'} show={assignedItemModal} id="editOrderAssets" onHide={closeModal.bind(this, "assignedItemModal")} aria-labelledby="ModalHeader" className="editOrderDetail" >
					<Modal.Header closeButton>
						<Modal.Title id='ModalHeader' className="headerTitle">Add Assets</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div className="clearfix">
							<AssignedItemsGrid onHide={closeModal.bind(this, "assignedItemModal")} camera_ship_status={camera_ship_status} assignedItems={assignedItems} orderId={orderId} order_details={order_details} shipping_details={shipping_details} />
						</div>
					</Modal.Body>
				</Modal>
			</div>
		)
	}
}

const mapDispatchToProps = dispatch => {
	return ({
		updateInventoryLocationCall: (id, data, keyName, orderShippingId, type) => { return dispatch(updateInventoryLocation(id, data, keyName, orderShippingId, type)) },
		unassignOrderShippingItemCall: (orderShippingId, type) => { return dispatch(unassignOrderShippingItem(orderShippingId, type)) },
	});
};

function mapStateToProps(state) {
	return {};
};

const AssignedItemShowAndModal = connect(mapStateToProps, mapDispatchToProps)(AssignedItemShowAndModalPage);

export default AssignedItemShowAndModal;