import React, {Component} from "react";
import {connect} from 'react-redux';
import {updateOrCreateSettingsData} from 'redux/actions/settings-actions';
import {orderService} from 'services/order';
import {SC} from "helper/ServerCall";

const queryString = require('query-string');

const moment = window.moment;

class firstEmailNotReceivedPage extends Component {
    constructor(props) {
        super(props);
        const parsed = queryString.parse(window.location.search);
        this.state = {
            order_id: parsed.order_id,
            group_name: ''
        };
    }

    componentDidMount() {
        const {order_id} = this.state;
        SC.getCall(`order/${order_id}`).then(res => {
            this.setState({
                group_name: res.order.order_details.group_name
            })
        });
        if (order_id) {
            orderService.firstEmailNotReceived(order_id)
        }
    }

    render() {
        const {order_id, group_name} = this.state;
        
        return (
            <div>
                {
                    (order_id) &&
                    <React.Fragment>
                        <section id="since" className="section bg_alter">
                            <div className="flexElem alignCenter justifyCenter">
                                <div className="flexElem alignCenter justifyCenter mt-xxxlg mb-xxxlg" style={{backgroundColor: '#eaeef6', minHeight: '400px', width: '100%', maxWidth: '550px'}}>
                                    <div>
                                        <div className="col-md-12">
                                            <h1 className="blue_color mb-lg pl-lg pr-lg font-weight-medium" style={{fontSize: '40px'}}>Thank You! We have received your request.</h1>
                                        </div>

                                        <div className="col-md-12">
                                            <p className="text_24 mt-md smaller pl-lg pr-lg">In the next few days we will send you an
                                                email that summarizes your group’s app information for easy
                                                access while traveling.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </React.Fragment>
                }

            </div>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return ({
        // updateOrCreateSettingsDataCall: (model_id, key, settingsData) => {
        //     dispatch(updateOrCreateSettingsData(model_id, key, settingsData))
        // },//example function
    });
};

function mapStateToProps(state) {
    return {
        // exampleConstant
    };
}


const firstEmailNotReceived = connect(mapStateToProps, mapDispatchToProps)(firstEmailNotReceivedPage);
export default firstEmailNotReceived;
