import { SC } from "../helper/ServerCall"

export const mosaicService = {
    getAllMosaicStyles,
    updateOrderMosaic
}

function getAllMosaicStyles(data) {
    if (data) {
        return SC.getCall(`mosaicStyles?selectable=${data.selectable}`);
    } else {
        return SC.getCall(`mosaicStyles`);
    }
}

function updateOrderMosaic(id, data) {
    return SC.putCall(`updateOrderMosaic/${id}`, data)
}