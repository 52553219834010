import React from 'react';

import SimpleReactValidator from 'simple-react-validator';
import Select from 'react-select';
// import { adminLabels } from '../../../redux/constant/admin-label-constant';
import FileUpload from "../../Upload/FileUpload";
import ImagePreview from "../../Upload/ImagePreview";
import renderHTML from 'react-render-html';
// import ImageUpload from "../../Upload/ImageUpload";
import { returnFormats, returnExtFromImage } from '../../../helper/helperFunctions';
import TopBarProgress from "react-topbar-progress-indicator"
TopBarProgress.config({
	barColors: {
		"0": "green",
		"1.0": "#fff",
	},
	shadowBlur: 5,
	barThickness: 10,
})

class AgencyResourceForm extends React.Component {
	constructor(props) {
		super(props);
		this.validatorAgencyResource = new SimpleReactValidator({ autoForceUpdate: this, locale: 'en' });
		this.state = {
			resource: this.props.resourcePro || {},
			allowedFormatsStringMime: this.props.allowedFormatsStringMimePro || null,
			allowedFormatsStringExt: this.props.allowedFormatsStringExtPro || null,
			resourceInfo: this.props.resourceInfoPro || null,
			resourcesTypesYetToAddOptions: this.props.resourcesTypesYetToAddOptionsPro || {},
			selectedResourceTypeOption: this.props.resourceTypeSelectionOptionPro || null,
			submitted: false,
			resourceAllDataYetToAdd: this.props.resourceAllDataYetToAddPro || {},
			formType: this.props.formTypePro
		};

		this.handleAgencyResource = this.handleAgencyResource.bind(this);
		this.handleAgencyResourceSubmit = this.handleAgencyResourceSubmit.bind(this);
		this.handleChangeResource = this.handleChangeResource.bind(this);
		this.setResourceFile = this.setResourceFile.bind(this);
	}
	handleAgencyResource(e) {
		const { name, value } = e.target;
		const userState = { ...this.state.user };
		userState[name] = value;
		this.setState({ user: userState });
	}
	handleAgencyResourceSubmit(e) {
		e.preventDefault();
		if (this.validatorAgencyResource.allValid()) {
			this.setState({ submitted: true }, function () {
				if (this.state.formType === 'edit')
					this.props.handleAgencyResourceSubmitParPro(this.state.resource, 'edit');
				else if (this.state.formType === 'add')
					this.props.handleAgencyResourceSubmitParPro(this.state.resource, 'add');
			});
		} else {
			this.setState({ submitted: false });
			this.validatorAgencyResource.showMessages();
			this.forceUpdate();
		}
	}
	handleChangeResource(selectedOption, name) {
		const resourceState = { ...this.state.resource };
		resourceState['resource_type_id'] = selectedOption.value;
		resourceState['file'] = null;
		this.setState({ resource: resourceState }, function () {
			this.setState({ resourceInfo: this.state.resourceAllDataYetToAdd[selectedOption.slug] }, function () {
				var formatArrayMime = returnFormats(this.state.resourceAllDataYetToAdd[selectedOption.slug].resource_formats, 'mime_type');
				var formatArrayExt = returnFormats(this.state.resourceAllDataYetToAdd[selectedOption.slug].resource_formats, 'extension');
				this.setState({ allowedFormatsStringMime: formatArrayMime.join(", ") }, function () {
					this.setState({ allowedFormatsStringExt: formatArrayExt.join(", ") }, function () {
						this.setState({ selectedResourceTypeOption: selectedOption });
					});
				});
			})
		});
	}
	setResourceFile = (key, value) => {
		const resourceState = { ...this.state.resource };
		resourceState['file'] = value.file;
		resourceState['name'] = value.name;
		resourceState['size'] = value.size;
		resourceState['extension'] = returnExtFromImage(value.name);
		resourceState['preview'] = value.preview;
		resourceState['type'] = value.type;
		resourceState['lastModified'] = value.lastModified;
		this.setState({ resource: resourceState });
	}
	render() {
		this.validatorAgencyResource.purgeFields();
		const { resourceInfo, resource, resourcesTypesYetToAddOptions, selectedResourceTypeOption, formType, allowedFormatsStringMime, allowedFormatsStringExt } = this.state;
		const { loadingAgencyResource } = this.props;
		return (
			<form onSubmit={this.handleAgencyResourceSubmit} encType="multipart/form-data">
				<div className="row">
					<div className="col-md-12">
						<div className='form-group'>
							<label htmlFor="name">Select Resource Type<span className="requiredClass">*</span></label>
							<Select
								value={selectedResourceTypeOption}
								onChange={this.handleChangeResource}
								options={resourcesTypesYetToAddOptions}
								isSearchable={true}
								isMulti={false}
								placeholder={'Select Resource Type'}
								isClearable={false}
								isDisabled={formType === 'edit' ? true : false}
							/>
							{this.validatorAgencyResource.message('ResourceType', resource.resource_type_id, 'required')}
						</div>
					</div>
					{
						resourceInfo !== null &&
						<div>
							<div className="flexStyling">
								{
									resourceInfo.resource_formats.length > 0 &&
									<div className="flexStylingChild">
										<div className='form-group'>
											<label htmlFor="allowedFormat">Allowed Formats</label>
											<div>
												<ol className="pl-xlg">
													{
														resourceInfo.resource_formats.map((format, index) => (
															<li key={index}>
																<span  >{format.extension}</span>
															</li>
														)
														)
													}
												</ol>
											</div>
										</div>
									</div>
								}

								{
									resourceInfo.resource_type_templates.length > 0 &&
									<div className="flexStylingChild">
										<div className='form-group'>
											<label htmlFor="templateFile">Template Files</label>
											<div>
												<ol>
													{
														resourceInfo.resource_type_templates.map((templates, index) => (
															<li key={templates.id}>
																<a rel="noopener noreferrer" target="_blank" href={templates.file_path} download>
																	{templates.file_title}
																</a>
															</li>

														)
														)
													}
												</ol>
											</div>
										</div>
									</div>
								}

								<div className="flexStylingChild">
									<div className='form-group'>
										<label htmlFor="Guideline">Guideline</label>
                                        {
                                            resourceInfo.guidelines &&
										    <div className="pl-lg">{renderHTML(resourceInfo.guidelines)}</div>
                                        }
									</div>
								</div>
							</div>


							<div className="clearfix">
								<div className="col-md-12">
									<FileUpload
										files={this.state.resource}
										setResourceFile={this.setResourceFile}
										allowedFormatsStringMimePro={allowedFormatsStringMime}
										allowedFormatsStringExtPro={allowedFormatsStringExt}
										multiple={false}
									/>
									<ImagePreview filesToPreview={this.state.resource} />
									{
										this.validatorAgencyResource.message('File', resource.file, 'required')
									}
								</div>
								<div >
									<div className="col-md-12 text-center">
										<div className='btn-toolbar' style={{ "display": "inline-flex" }}>
											<button type='submit' disabled={(resource.file === null || loadingAgencyResource) ? true : false} className="backButton pt-sm no_radius pb-sm success btn btn-lg btn-info ml-sm mt-sm btn btn-default" >Save</button>
											{
												formType === 'edit' &&
												<button type="button" onClick={() => this.props.updateStatusAgencyResourceModalPro('edit', false)} className="backButton pt-sm no_radius pb-sm primary btn btn-lg btn-info ml-sm mt-sm" >Cancel</button>
											}
											{
												formType === 'add' &&
												<button type="button" onClick={() => this.props.updateStatusAgencyResourceModalPro('add', false)} className="backButton pt-sm no_radius pb-sm primary btn btn-lg btn-info ml-sm mt-sm" >Cancel</button>
											}
										</div>
									</div>
								</div>
							</div>
						</div>
					}
				</div>
				<small className='text-muted'>PLEASE NOTE: Maximum file size for upload is 60 MBs</small>
			</form>
		);
	}
}


export default AgencyResourceForm;
