import { moreButtonService } from "../../services/moreButton";
import { adminConstants } from "../constant/admin-constant";
import { alertActions } from "./alert-actions";


export function storeMoreButton(data) {
    return dispatch => {
        return moreButtonService.storeMoreButton(data).then(
            response => {
                // dispatch(getMoreButtonsPagination(1, '', '', 10))
                dispatch(alertActions.success(response.message))
                return response;
            },
            error => {
                dispatch(alertActions.error(error))
                dispatch(failure(error))
                return Promise.reject(error)
            }
        )
    }
    function failure(error) { return { type: adminConstants.STORE_MORE_BUTTON_FAILURE, payload: error } }
}

export function updateMoreButtonStatus(moreButtonId, data) {
    return dispatch => {
        dispatch(request());

        return moreButtonService.updateMoreButtonStatus(moreButtonId, data)
            .then(
                response => {
                    dispatch(success(response));
                    dispatch(alertActions.success(response.message));
                    return response;
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );

    }

    function request() { return { type: adminConstants.UPDATE_MORE_BUTTON_STATUS_REQUEST } }
    function success(response) { return { type: adminConstants.UPDATE_MORE_BUTTON_STATUS_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.UPDATE_MORE_BUTTON_STATUS_FAILURE, payload: error } }
}

export function getMoreButton(id) {
    return dispatch => {
        moreButtonService.getMoreButton(id).then(
            response => {
                dispatch(success(response))
            },
            error => {
                dispatch(failure(error))
            }
        )
    }
    function success(response) { return { type: adminConstants.GET_SINGLE_MORE_BUTTON_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.GET_SINGLE_MORE_BUTTON_FAILURE, payload: error } }
}

export function updateMoreButton(data, id) {
    return dispatch => {
        return moreButtonService.updateMoreButton(data, id).then(
            response => {
                dispatch(success(id, data))
                dispatch(alertActions.success(response.message))
                return response
            },
            error => {
                dispatch(failure(error))
                dispatch(alertActions.error(error))
                return Promise.reject(error)
            }
        )
    }
    function success(id, data) { return { type: adminConstants.UPDATE_MORE_BUTTON_SUCCESS, payload: { id: id, data: data } } }
    function failure(error) { return { type: adminConstants.UPDATE_MORE_BUTTON_FAILURE, payload: error } }
}

export function updateAccountMoreButton(data, id) {
    return dispatch => {
        return moreButtonService.updateAccountMoreButton(data, id).then(
            response => {
                dispatch(success(id, data))
                dispatch(alertActions.success(response.message))
                return response
            },
            error => {
                dispatch(failure(error))
                dispatch(alertActions.error(error))
                return Promise.reject(error)
            }
        )
    }
    function success(id, data) { return { type: adminConstants.UPDATE_ACCOUNT_MORE_BUTTON_SUCCESS, payload: { id: id, data: data } } }
    function failure(error) { return { type: adminConstants.UPDATE_ACCOUNT_MORE_BUTTON_FAILURE, payload: error } }
}

export function updateMoreButtonForOrder(data, id) {
    return dispatch => {
        return moreButtonService.updateMoreButtonForOrder(data, id).then(
            response => {
                dispatch(success(id, data))
                dispatch(alertActions.success(response.message))
                return response
            },
            error => {
                dispatch(failure(error))
                dispatch(alertActions.error(error))
                return Promise.reject(error)
            }
        )
    }
    function success(id, data) { return { type: adminConstants.UPDATE_MORE_BUTTON_FOR_ORDER_SUCCESS, payload: { id: id, data: data } } }
    function failure(error) { return { type: adminConstants.UPDATE_MORE_BUTTON_FAILURE, payload: error } }
}
export function getMoreButtonsPagination(page, filter, sort, pageSize, orderType, agency_id) {
    return dispatch => {
        return moreButtonService.getMoreButtonsPagination(page, filter, sort, pageSize, orderType, agency_id).then(
            response => {
                dispatch(success(response))
                return response
            },
            error => {
                return error
            }
        )
    }
    function success(response) { return { type: adminConstants.GET_MORE_BUTTONS_PAGINATION_SUCCESS, payload: response } }
}

export function getAccountMoreButtonsPagination(page, filter, sort, pageSize, orderType, id) {
    return dispatch => {
        return moreButtonService.getAccountMoreButtonsPagination(page, filter, sort, pageSize, orderType, id).then(
            response => {
                dispatch(success(response))
                return response
            },
            error => {
                return error
            }
        )
    }
    function success(response) { return { type: adminConstants.GET_ACCOUNT_MORE_BUTTONS_PAGINATION_SUCCESS, payload: response } }
}

export function getMoreButtonsForOrder(page, filter, sort, pageSize, orderType, order_id,agency_id = null, uuid = null) {
    return dispatch => {
        return moreButtonService.getMoreButtonsForOrder(page, filter, sort, pageSize, orderType, order_id,agency_id, uuid).then(
            response => {
                dispatch(success(response))
                return response
            },
            error => {
                return error
            }
        )
    }
    function success(response) { return { type: adminConstants.GET_MORE_BUTTON_FOR_ORDER_SUCCESS, payload: response } }
}

export function deleteMoreButton(id) {
    return dispatch => {
        return moreButtonService.deleteMoreButton(id).then(
            response => {
                dispatch(alertActions.success(response.message))
            },
            error => {

            }
        )
    }
    // function success(data) { return { type: adminConstants.DELETE_MORE_BUTTON_SUCCESS, payload: data } }
    // function failure(error) { return { type: adminConstants.DELETE_MORE_BUTTON_FAILURE, payload: error } }
}
