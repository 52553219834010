import React, { Component } from "react";
import {
  Grid,
  Row,
  Col
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { StatsCard } from "components/StatsCard/StatsCard.jsx";
import "react-confirm-alert/src/react-confirm-alert.css";
import { hasRole, s3StaticPath } from '../../helper/helperFunctions';
import OrderListingForModerator from './Dashboard/OrderListingForModerator';
import CustomerServices from './Dashboard/CustomerServices';
import OrderList from "./Order/OrderList";
import { Roles } from "../../constants/Roles";
import { getOrderStatuses } from "../../redux/actions/settings-actions";
import AgencyList from "./Agency/AgencyList";
import ContactLogPage from "./Reports/ContactLog";
import MyImage from 'components/Upload/MyImage';

class DashboardSuperAdminPage extends Component {
  constructor(props) {
    super(props)
    this.props.getOrderStatuses()
  }

  render() {
    const { orderStatuses } = this.props
    orderStatuses.find(item => {
      return item.label === 'Pending'
    })
    return (
      <div className="content">
        {
          hasRole(['admin']) &&
          <Grid fluid>
            <Row>
              <Col lg={6} sm={12}>
                <StatsCard
                  bigIcon={
                    <MyImage height="70" src={s3StaticPath('img/company_orange.svg')} alt="companyIconImage" />
                  }
                  statsText="Orders waiting to be approved"
                  statsValue={10}
                  statsIcon={
                    <Link to={"/Admin/users"}>
                      {
                        <MyImage
                          height="15"
                          src={s3StaticPath('img/company_orange.svg')}
                          alt="companyIconImage"
                        />
                      }
                    </Link>
                  }
                  statsIconText="Orders waiting to be approved"
                />
              </Col>

              <Col lg={6} sm={12}>
                <StatsCard
                  bigIcon={
                    <MyImage
                      height="70"
                      src={s3StaticPath('img/festivals.svg')}
                      alt="festivalsIconImage"
                    />
                  }
                  statsText="Orders in system"
                  statsValue={12}
                  statsIcon={
                    <Link to={"/Admin/users"}>
                      {
                        <MyImage
                          height="15"
                          src={s3StaticPath('img/festivals.svg')}
                          alt="festivalsIconImage"
                        />
                      }
                    </Link>
                  }
                  statsIconText="Orders in system"
                />
              </Col>
            </Row>
          </Grid>
        }
        {
          hasRole([Roles.ADMIN,'Remote Editor/Producer']) &&
          <OrderList Dashboard orderStatus={orderStatuses.find(item => {
            return item.label === 'Pending'
          })} />
        }
        {
          hasRole([Roles.ADMIN]) &&
          <AgencyList
            Dashboard
            collapseCard={false}
          />
        }
        {
          hasRole([Roles.ADMIN]) &&
          <ContactLogPage
            collapseCard={false}
          />
        }
        {
          hasRole(['editor', 'producer', 'qa']) &&
          <div>
            <OrderListingForModerator userType="editor" />
          </div>
        }
        {
          hasRole(['Customer Services']) &&
          <div>
            <CustomerServices />
          </div>
        }
      </div>
    );
  }
}


function mapStateToProps(state) {
  const { loading } = state.adminReducer;
  const { orderStatuses } = state.settingsReducer
  return {
    loading,
    orderStatuses
  };
}

const mapDispatchToProps = dispatch => {
  return {
    getOrderStatuses: () => { dispatch(getOrderStatuses()) }
  }
}

const DashboardSuperAdmin = connect(
  mapStateToProps, mapDispatchToProps
)(DashboardSuperAdminPage);

export default DashboardSuperAdmin;
