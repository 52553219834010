import React, { Component } from "react";
import CustomCheckbox from "components/CustomCheckbox/CustomCheckbox.jsx";
import { Card } from '../../Card/Card.jsx';

class AgencyUserPreferences extends Component {
	render() {
		const { agencyUserPreferences, isOpen } = this.props;
		return (
			<Card bsClass={["innerCard relativeCard"]} lazyRender collapseTrigger={isOpen} collapsable={true} collapsHandler={() => this.props.collapseHandle('isOpenAgencyUserPreferences')} title="User Preferences"
				content={
					<React.Fragment>

						<div className="clearfix mt-md fullCalendar">
							<div className="col-md-4">

                                <CustomCheckbox
									tooltipText="Agency Name"
									label="Agency Name"
									value={agencyUserPreferences.name}
									indexArray={['name']}
									name="name"
									parentState="agencyUserPreferences"
									handleChandgeCheckBox={this.props.handleSearchCheckBoxPro}
								/>

                                <CustomCheckbox
									tooltipText="Publishable Name"
									label="Agency Publishable Name"
									value={agencyUserPreferences.publishable_agency_name}
									indexArray={['publishable_agency_name']}
									name="publishable_agency_name"
									parentState="agencyUserPreferences"
									handleChandgeCheckBox={this.props.handleSearchCheckBoxPro}
								/>

                                <CustomCheckbox
									tooltipText="Agency website"
									label="Agency website"
									value={agencyUserPreferences.website}
									indexArray={['website']}
									name="website"
									parentState="agencyUserPreferences"
									handleChandgeCheckBox={this.props.handleSearchCheckBoxPro}
								/>

                                <CustomCheckbox
									tooltipText="Agency Accounting Contact"
									label="Accounting Contact"
									value={agencyUserPreferences.accounting_contact}
									indexArray={['accounting_contact']}
									name="accounting_contact"
									parentState="agencyUserPreferences"
									handleChandgeCheckBox={this.props.handleSearchCheckBoxPro}
								/>

								<CustomCheckbox
									tooltipText="Primary Contact Name"
									label="Primary Contact Name"
									value={agencyUserPreferences.primary_contacts.name}
									indexArray={['primary_contacts', 'name']}
									name="primary_contacts_name"
									parentState="agencyUserPreferences"
									handleChandgeCheckBox={this.props.handleSearchCheckBoxPro}
								/>

                                <CustomCheckbox
									tooltipText="Primary Contact Number"
									label="Primary Contact Number"
									value={agencyUserPreferences.primary_contacts.phone1}
									indexArray={['primary_contacts', 'phone1']}
									name="primary_contacts_phone1"
									parentState="agencyUserPreferences"
									handleChandgeCheckBox={this.props.handleSearchCheckBoxPro}
								/>

								<CustomCheckbox
									tooltipText="Primary Contact Email"
									label="Primary Contact Email"
									value={agencyUserPreferences.primary_contacts.email}
									indexArray={['primary_contacts', 'email']}
									name="primary_contacts_email"
									parentState="agencyUserPreferences"
									handleChandgeCheckBox={this.props.handleSearchCheckBoxPro}
								/>

                                <CustomCheckbox
									tooltipText="Program Type"
									label="Program Type"
									value={agencyUserPreferences.program_type.title}
									indexArray={['program_type', 'title']}
									name="program_type"
									parentState="agencyUserPreferences"
									handleChandgeCheckBox={this.props.handleSearchCheckBoxPro}
								/>

                                <CustomCheckbox
									tooltipText="Quick Link to agency profile"
									label="Quick Link to agency profile"
									value={agencyUserPreferences.quick_link_to_agency_profile}
									indexArray={['quick_link_to_agency_profile']}
									name="quick_link_to_agency_profile"
									parentState="agencyUserPreferences"
									handleChandgeCheckBox={this.props.handleSearchCheckBoxPro}
								/>

                                <CustomCheckbox
									tooltipText="Agency Brand"
									label="Brand"
									value={agencyUserPreferences.brand}
									indexArray={['brand']}
									name="brand"
									parentState="agencyUserPreferences"
									handleChandgeCheckBox={this.props.handleSearchCheckBoxPro}
								/>

								<CustomCheckbox
									tooltipText="GTV Sales Rep"
									label="GTV Sales Rep"
									value={agencyUserPreferences.gtv_sales_rep}
									indexArray={['gtv_sales_rep']}
									name="gtv_sales_rep"
									parentState="agencyUserPreferences"
									handleChandgeCheckBox={this.props.handleSearchCheckBoxPro}
								/>

							</div>

							<div className="col-md-4">
								<CustomCheckbox
									tooltipText="Agency Primary Street"
									label="Agency Primary Street"
									value={agencyUserPreferences.primary_address.street_address_1}
									indexArray={['primary_address', 'street_address_1']}
									name="primary_address_street_address_1"
									parentState="agencyUserPreferences"
									handleChandgeCheckBox={this.props.handleSearchCheckBoxPro}
								/>

								<CustomCheckbox
									tooltipText="Agency Primary City"
									label="Agency Primary City"
									value={agencyUserPreferences.primary_address.city}
									indexArray={['primary_address', 'city']}
									name="primary_address_street_city"
									parentState="agencyUserPreferences"
									handleChandgeCheckBox={this.props.handleSearchCheckBoxPro}
								/>
								<CustomCheckbox
									tooltipText="Agency Primary State"
									label="Agency Primary State"
									value={agencyUserPreferences.primary_address.state}
									indexArray={['primary_address', 'state']}
									name="primary_address_street_state"
									parentState="agencyUserPreferences"
									handleChandgeCheckBox={this.props.handleSearchCheckBoxPro}
								/>
								<CustomCheckbox
									tooltipText="Agency Primary Zipcode"
									label="Agency Primary Zipcode"
									value={agencyUserPreferences.primary_address.zipcode}
									indexArray={['primary_address', 'zipcode']}
									name="primary_address_street_zipcode"
									parentState="agencyUserPreferences"
									handleChandgeCheckBox={this.props.handleSearchCheckBoxPro}
								/>

                                <CustomCheckbox
									tooltipText="Keep High Resolution Photos"
									label="Keep High Resolution Photos"
									value={agencyUserPreferences.keep_high_resolution_photos}
									indexArray={['keep_high_resolution_photos']}
									name="keep_high_resolution_photos"
									parentState="agencyUserPreferences"
									handleChandgeCheckBox={this.props.handleSearchCheckBoxPro}
								/>

							</div>

							<div className="col-md-4">


								<CustomCheckbox
									tooltipText="Agency Group Type"
									label="Agency Type"
									value={agencyUserPreferences.agency_type.title}
									indexArray={['agency_type', 'title']}
									name="agency_type"
									parentState="agencyUserPreferences"
									handleChandgeCheckBox={this.props.handleSearchCheckBoxPro}
								/>



								<CustomCheckbox
									tooltipText="Agency Created Date"
									label="Create Date"
									value={agencyUserPreferences.created_at}
									indexArray={['created_at']}
									name="created_at"
									parentState="agencyUserPreferences"
									handleChandgeCheckBox={this.props.handleSearchCheckBoxPro}
								/>

                                <CustomCheckbox
									tooltipText="Agency All User Name/Email"
									label="Agency All User Name/Email"
									value={agencyUserPreferences.agency_all_users_name}
									indexArray={['agency_all_users_name']}
									name="agency_all_users_name"
									parentState="agencyUserPreferences"
									handleChandgeCheckBox={this.props.handleSearchCheckBoxPro}
								/>
                                {/* <CustomCheckbox
									tooltipText="Agency All User Email"
									label="Agency All User Email"
									value={agencyUserPreferences.agency_all_users_email}
									indexArray={['agency_all_users_email']}
									name="agency_all_users_email"
									parentState="agencyUserPreferences"
									handleChandgeCheckBox={this.props.handleSearchCheckBoxPro}
								/> */}
							</div>

							<div className="col-md-12 text-right">
								{/*<button onClick={() => this.props.hide()} className="backButton pt-sm no_radius pb-sm primary btn btn-md btn-info ml-sm mt-sm">Perform Search</button>*/}
								<button onClick={ ()=>this.props.performSearch() } className="backButton pt-sm no_radius pb-sm primary btn btn-md btn-info ml-sm mt-sm">Perform Search</button>
								<button className="backButton pt-sm no_radius pb-sm success btn btn-info ml-sm mt-sm" onClick={() => this.props.changeStatusForSearch(true, 'agencyUserPreferences')}>Select All</button>
								<button className="backButton pt-sm no_radius pb-sm success btn btn-info ml-sm mt-sm" onClick={() => this.props.changeStatusForSearch(false, 'agencyUserPreferences')}>Unselect All</button>
								{/* <button className="backButton pt-sm no_radius pb-sm success btn btn-info ml-sm mt-sm" onClick={() => this.props.changeToDefault()}>Default</button> */}


							</div>
						</div>
					</React.Fragment>
				}
			/>
		);
	};
}



export default AgencyUserPreferences;
