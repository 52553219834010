import React, { Component } from "react";
import { connect } from 'react-redux';
import { getAgencyInventoryPagination, storeAgencyInventory, editAgencyInventory, updateAgencyInventory, deleteAgencyInventory, getAllAgencies, updateInventoryLocation } from 'redux/actions/agency-actions';
import 'react-table/react-table.css';
// import { FormInputs } from "components/FormInputs/FormInputs.jsx";
// import Select from 'react-select';
import { openModal, closeModal } from 'helper/helperFunctions';
import { Grid, Row, Col, OverlayTrigger, Popover } from "react-bootstrap";
import { Card } from "components/Card/Card.jsx";
import OpenModalButton from 'components/ThemeComponents/openModelButton';
import { hasPermission } from 'helper/hasPermission';
import { adminLabels } from 'redux/constant/admin-label-constant';
import InventoryForm from "./InventoryForm";
import { objectsConstants as OC } from 'redux/constant/objects-constant';
import { getItems } from 'redux/actions/item-actions';
import Confirmalertfordelete from 'components/ThemeComponents/confirmAlertForDelete';
import { confirmAlert } from 'react-confirm-alert';
import qs from 'qs';
import { Link } from 'react-router-dom';
// import { settingsActions } from 'redux/actions/settings-actions';
// import Select from 'react-select';
import ChangeInvLoc from 'components/Admin/Inventory/ChangeInvLoc';
import Pagination from "../../ThemeComponents/Pagination";
var Modal = require('react-bootstrap-modal');

class InventoryListPage extends Component {
	constructor(props) {
		super(props);
		const { search } = this.props.location;
		var parsedQSData = qs.parse(search.slice(1));

		this.state = {
			createAgencyInvModal: false,
			editAgencyInvModal: false,
			editInvFormType: null,
			sorted: '',
			filtered: '',
			pageSize: 10,
			page: 0,
			where: search ? parsedQSData : [],
		};

		this.agencyInventoryPaginationData = this.agencyInventoryPaginationData.bind(this);
		this.filterSearchHandle = this.filterSearchHandle.bind(this);
		this.handleInventorySubmit = this.handleInventorySubmit.bind(this);
		this.setEditFormType = this.setEditFormType.bind(this);
		this.deleteInventory = this.deleteInventory.bind(this);
		this.deleteConfirmInventory = this.deleteConfirmInventory.bind(this);

		this.props.getItemsCall();
		this.props.getAgenciesCall();
		// this.props.getInvLocationsCall();
	};
	handleChangeLocation = key => (selectedOptionType, info) => {
		// console.log('key', key);
		// console.log('selectedOptionType', selectedOptionType);
		// console.log('info', info);

		if (info['action'] === 'select-option') {
			this.props.updateInventoryLocationCall(key, { inventory_location_id: selectedOptionType['id'] }, 'updatedInvLocation').then(
				response => {
					this.agencyInventoryPaginationData();
				}
			);
		}
		// if (info['action'] === 'clear') {
		//   this.setState({
		//     ...this.state,
		//     selectedOptions_agency_type_id: null
		//   }, function () {
		//     this.getAgencyDataWithStateValue();
		//   });
		// }
	}
	agencyInventoryPaginationData(page = this.state.page, filtered = this.state.filtered, sorted = this.state.sorted, pageSize = this.state.pageSize) {
		this.props.getAgencyInventoriesPaginationCall(page, filtered, sorted, pageSize, { where: this.state.where });
	}
	filterSearchHandle(e) {
		this.setState({
			'filtered': e.target.value
		});
	}
	handleInventorySubmit(InvData, formType) {
		// const { page, pageSize } = this.state;
		if (formType === 'create') {
			this.props.storeAgencyInventoryCall(InvData).then(
				response => {
					this.setState({
						createAgencyInvModal: false
					}, function () {
						this.agencyInventoryPaginationData();
					});
				}
			);
		}
		else if (formType === 'edit') {
			const { editInvFormType } = this.state;
			this.props.updateAgencyInventoryCall(InvData, this.props.editAgencyInv.id, editInvFormType).then(
				response => {
					this.setState({
						editAgencyInvModal: false
					}, function () {
						this.agencyInventoryPaginationData();
					});
				}
			);
		}
	}
	setEditFormType(value, editFormType) {
		this.setState({ editInvFormType: editFormType }, function () {
			this.props.editInvCall(value);
		});
	}
	deleteInventory(inventoryId) {
		this.props.deleteAgencyInventoryCall(inventoryId).then(
			response => {
				const { page, pageSize } = this.state;
				this.agencyInventoryPaginationData(page, '', '', pageSize);
			}
		);
	}
	deleteConfirmInventory(inventoryId) {
		confirmAlert({
			customUI: ({ onClose }) => {
				return (
					<Confirmalertfordelete typeName="Inventory" description="Are you sure to delete the admin inventory?" onClosePro={onClose} deleteType={() => this.deleteInventory(inventoryId)} />
				)
			}
		})
	}

	componentDidUpdate(prevProps, nextProps) {
		if ((prevProps.editAgencyInv !== this.props.editAgencyInv) && this.props.editAgencyInv) {
			this.setState({ editAgencyInvModal: true });
		}
		if (prevProps.currentPageInv !== this.props.currentPageInv) {
			this.setState({ page: this.props.currentPageInv })
		}
	}

	paginationCall = (data) => {
		return this.props.getAgencyInventoriesPaginationCall(data.page, data.filter, data.sort, data.pageSize, this.state.where);
	}

	render() {
		const { /*filtered, */editInvFormType, where } = this.state;
		const { editAgencyInv/*, agencyInventories, lastPageInv, loadingAgency*/, items, agenciesOption } = this.props;
		const columns = [
			{
				Header: "Inventor Listing",
				headerClassName: 'headerPagination',
				columns: [
					{
						Header: "Item Name",
						accessor: "item.title",
						className: "action-center",
					},
					{
						Header: "Quantity",
						accessor: "quantity",
						className: "action-center",
					},
					{
						Header: "Threshold",
						accessor: "threshold",
						className: "action-center",
					},
					{
						Header: "Purchased Price",
						accessor: "purchased_price",
						className: "action-center",
					},
					{
						Header: "Agency",
						accessor: "agency.name",
						className: "action-center",
					},
					{
						Header: "Barcode",
						accessor: "barcode",
                        width: 200,
						className: "action-center",
					},
					{
						Header: "Serial Number",
						accessor: "serial_number",
						className: "action-center",
					},
					// {
					// 	Header: "Orders",
					// 	accessor : "item",
					// 	Cell: ({row}) => (
					// 		<ul>

					// 			{
					//           row._original.item.order_shippings.map((prop, key)=>{
					//           	return(
					//           		prop.order &&
					//           		<li className="badge badge-green" key={key}><Link to={'/Admin/' + prop.order_id + '/order-edit'} >{prop.order.barcode}</Link> </li>
					//           	)
					//           })
					//       }
					// 		</ul>

					// 	),
					// 	className:"action-center"
					// },

					{
						Header: "Orders",
						accessor: "inventory_log",
						Cell: ({ row }) => (
							<ul>

								{
									row._original.inventory_log.map((prop, key) => {
										return (
											prop.order &&
											<li className="badge badge-green" key={key}><Link to={'/Admin/' + prop.order_id + '/order-edit'} >{prop.order.barcode}</Link> </li>
										)
									})
								}
							</ul>

						),
						className: "action-center"
					},
					{
						Header: "Location",
						accessor: "id",
						Cell: ({ row, original }) => (
							<div style={{
								width: '100%',
							}}>
								{
									original.item.item_type.title === 'Physical Products'
										?
										<ChangeInvLoc
											handleChangeLocation={this.handleChangeLocation(original.id)}
											inventory_location_stats_object={original.inventory_location_stats}
										/>



										:
										original.item.item_type.title
								}
							</div>
						),
						className: "action-center minWidthSelect showSelect"
					},
					{
						Header: "Actions",
						accessor: "id",
						Cell: ({ row }) => (
							<div>
								<OverlayTrigger placement="bottom" overlay={<Popover id="tooltip">{hasPermission('user-edit') ? 'Edit Inventory' : adminLabels.DONT_ALLOW}</Popover>}>
									<span>
										{
											hasPermission('user-edit') &&
											<button type='button' className="addIcon orderDetailsAction" onClick={() => this.setEditFormType(row._original.id, 'Add')}>&nbsp;</button>
										}
										{
											!hasPermission('user-edit') &&
											<button type='button' className="addIcon orderDetailsAction button_disabled" >&nbsp;</button>
										}

										{
											hasPermission('user-edit') &&
											<button type='button' className="adjustIcon orderDetailsAction" disabled={row._original.quantity > 0 ? false : true} onClick={() => this.setEditFormType(row._original.id, 'Adjust')}>&nbsp;</button>
										}
										{
											!hasPermission('user-edit') &&
											<button type='button' className="adjustIcon orderDetailsAction button_disabled" >&nbsp;</button>
										}
									</span>
								</OverlayTrigger>
								{

									<OverlayTrigger placement="bottom" overlay={<Popover id="tooltip">{hasPermission('user-delete') ? 'Delete Inventory' : adminLabels.DONT_ALLOW}</Popover>}>
										<span>
											{
												hasPermission('user-delete') &&
												<button type='button' className="deleteIcon orderDetailsAction" onClick={() => this.deleteConfirmInventory(row._original.id)}>&nbsp;</button>
											}
											{
												!hasPermission('user-delete') &&
												<button type='button' className="deleteIcon orderDetailsAction button_disabled" >&nbsp;</button>
											}
										</span>
									</OverlayTrigger>

								}
							</div>
						),
						className: "action-center",
						sortable: false
					}
				]
			}
		];
		if (this.pagination) {
			console.log(this.pagination.state);
		}
		return (
			<div className="content">
				<Grid fluid>
					<Row>
						<Col md={12}>
							<Card
								ctTableResponsive
								content={
									<div>
										<Grid fluid>
											<Row>
												<Col md={12}>
													<div className="secHeading">Inventory List</div>
												</Col>

												<Col md={12}>
													<div className="flexElem flexResponsive" style={{ "alignItems": "flex-start", "justifyContent": "space-between" }}>
														<div className="">
															<OpenModalButton
																openModal={hasPermission('user-create') ? openModal.bind(this, "createAgencyInvModal") : null}
																classButton={['backButton pt-sm no_radius pb-sm primary mt-none btn-block btn-info btn', !hasPermission('user-create') ? 'button_disabled' : '']}
																buttonName="Add Inventory"
																tooltipText={hasPermission('user-create') ? 'Add Inventory' : adminLabels.DONT_ALLOW}
																classIcon={['fa', 'fa-plus']}
															/>
														</div>

														<div className="custom-search-input">
															{/* <FormInputs
																ncols={["col-md-12"]}
																onChange={this.filterSearchHandle}
																proprieties={[
																	{
																		type: "text",
																		bsClass: "form-control",
																		placeholder: "Search Inventory",
																		onChange: this.filterSearchHandle,
																		name: "filter"
																	}
																]}
															/> */}
														</div>
													</div>
												</Col>

												{/* <Col md={12} className="mt-md">
		                            <ReactTable
		                              noDataText= 'No Inventory found'
		                              data={agencyInventories}
		                              pages={lastPageInv}
		                              loading = {loadingAgency}
		                              columns={columns}
		                              filtered={filtered}
		                              defaultPageSize={10}
		                              className="-striped listing responsive"
		                              pageData = {this.agencyInventoryPaginationData}
		                              manual
		                              onFetchData={(state, instance) => {
		                                  var sort = state.sorted.length === 0 ? '' : state.sorted[0].id+',desc:'+state.sorted[0].desc;
		                                  state.pageData(state.page+1, state.filtered, sort, state.pageSize);
		                              }}
		                            />
		                          </Col> */}
												<Pagination
													ref={(p) => this.pagination = p}
													showPagination={false}
													noDataText='No Inventory found'
													columns={columns}
													pageSize={10}
													getDataCall={this.paginationCall}
													filterView={true}
													// downloadData={true}
													// downloadFileName={'Orders'}
													// lowerContent = {null}
													filterPlaceHolder={'Inventory'}
													getRowProps={this.getRowProps}
												// showAllToggle={true}
												/>
											</Row>
										</Grid>

										{/*Add Inventory Modal Start*/}
										{
											<Modal backdrop={'static'} show={this.state.createAgencyInvModal} onHide={closeModal.bind(this, "createAgencyInvModal")} aria-labelledby="ModalHeader" >
												<Modal.Header closeButton>
													<Modal.Title id='ModalHeader' className="headerTitle">Create Inventory</Modal.Title>
												</Modal.Header>
												<Modal.Body>
													<div className="row">
														<div className="col-md-12">
															<Card bsClass={["innerCard mb-none"]} content={
																<InventoryForm
																	inventoryPro={OC.INVENTORY}
																	closeModel={closeModal.bind(this, "createAgencyInvModal")}
																	formTypePro="create"
																	componentPro="Inventory"
																	handleInventorySubmit={this.handleInventorySubmit}
																	options={
																		{
																			'items': items,
																			'agencies': agenciesOption,
																		}
																	}
																	inventoryType={"Create"}
																	defaultItemById={'item_id' in where ? true : false}
																	defaultItemId={'item_id' in where ? where.item_id : null}
																	defaultItemByName={false}
																	defaultItemName={'Sleeve'}
																	defaultAgencyByName={true}
																/>
															} />
														</div>
													</div>
												</Modal.Body>
											</Modal>
										}

										{/*Add Inventory Modal End*/}

										{/*Edit Inventory Modal start*/}
										{editAgencyInv &&
											<Modal backdrop={'static'} show={this.state.editAgencyInvModal} onHide={closeModal.bind(this, "editAgencyInvModal")} aria-labelledby="ModalHeader" >
												<Modal.Header closeButton>
													<Modal.Title id='ModalHeader' className="headerTitle">{editInvFormType} Inventory</Modal.Title>
												</Modal.Header>
												<Modal.Body>
													<div className="row">
														<div className="col-md-12">
															<Card bsClass={["innerCard mb-none"]} content={
																<InventoryForm
																	inventoryPro={editAgencyInv}
																	closeModel={closeModal.bind(this, "editAgencyInvModal")}
																	formTypePro="edit"
																	componentPro="Inventory"
																	handleInventorySubmit={this.handleInventorySubmit}
																	options={
																		{
																			'items': items,
																			'agencies': agenciesOption,
																		}
																	}
																	inventoryType={editInvFormType}
																	defaultAgencyByName={false}
																/>
															} />
														</div>
													</div>
												</Modal.Body>
											</Modal>
										}
										{/*Edit Inventory Modal End*/}
									</div>
								} />
						</Col>
					</Row>
				</Grid>
			</div>
		);
	};
}

const mapDispatchToProps = dispatch => {
	return ({
		getAgencyInventoriesPaginationCall: (page, filter, sort, pageSize, where) => { return dispatch(getAgencyInventoryPagination(page, filter, sort, pageSize, where)) },
		storeAgencyInventoryCall: (InvData) => { return dispatch(storeAgencyInventory(InvData)) },
		editInvCall: (value) => { dispatch(editAgencyInventory(value)) },
		updateAgencyInventoryCall: (data, id, type) => { return dispatch(updateAgencyInventory(data, id, type)) },
		deleteAgencyInventoryCall: (id) => { return dispatch(deleteAgencyInventory(id)) },
		getItemsCall: (type = null) => { dispatch(getItems(type = null)) },
		getAgenciesCall: () => { dispatch(getAllAgencies()) },
		// getInvLocationsCall: () => { dispatch(getInvLocations())},
		updateInventoryLocationCall: (id, data, keyName) => { return dispatch(updateInventoryLocation(id, data, keyName)) },
	});
};

function mapStateToProps(state) {
	const { loadingAgency, agencyInventories, lastPageInv, currentPageInv, editAgencyInv, agenciesOption } = state.agenciesReducer;
	const { loadingItem, items } = state.itemReducer;
	// const { loadingSettings, invLocations } = state.settingsReducer;
	// console.log('updatedInvLocation', updatedInvLocation);
	return {
		loadingAgency, agencyInventories, lastPageInv, currentPageInv, editAgencyInv, agenciesOption,
		loadingItem, items,
		// loadingSettings, invLocations,
	};
};

const InventoryList = connect(mapStateToProps, mapDispatchToProps)(InventoryListPage);
export default InventoryList;
