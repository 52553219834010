import React, { Component } from 'react'
import { connect } from 'react-redux'

import { getSettingValue, getProgramItem } from 'redux/actions/settings-actions';
import User from 'helper/User';
import { getReorderEntryItems } from 'redux/actions/item-actions';
import { cloneDeep, reduce } from 'lodash';
import { s3StaticPath, sortObjectWithDefineKeys } from 'helper/helperFunctions';
import { objectsConstants as OC } from 'redux/constant/objects-constant';
import { startCase } from 'lodash';
import { Link } from 'react-router-dom';
// import MyImage from 'components/Upload/MyImage';

class Pricing extends Component {

    constructor(props) {
        super(props)
        this.state = {
            pricing_details: {
                ...this.props.pricing_details,
                // program_type_id: User.getProperty('programType.id'),
                program_type_id: this.props.agency.program_type_id ? this.props.agency.program_type_id : User.getProperty('programType.id'),
                order_totals: {
                    ...this.props.pricing_details.order_totals,
                    // tax: { code: 'tax', value: 0 },
                    grand_total: { code: 'grand_total', value: 0 },
                    sub_total: { code: 'sub_total', value: 0 }
                }
            },
            items_total: 0,
            singleMosaicShippingAdded: false,
            // fullDiscountPrice: 23.40
            fullDiscountPrice: 26.40
        }
        this.val = this.props.validatorInstance;
        // this.props.getProgramItemCall(User.getProperty('programType.id'));
        // this.props.getSettingValueCall('tax');
    }

    componentDidMount() {
        if (this.props.reorder) {
            this.props.getReorderEntryItems().then(
                res => {
                    const pricing_details = cloneDeep(this.state.pricing_details);
                    const reOrderItems = cloneDeep(res.reOrderItems);
                    reOrderItems.forEach(item => {
                        if (item.title === 'PhotoMozaix™') {
                            this.mosaicPrice = Number(item.price);
                        }
                        pricing_details.order_items[item.title] = {
                            item_id: item.id,
                            price: Number(item.price),
                            quantity: 0,
                            title: item.title,
                            total: 0
                        }
                    })
                    this.setState({
                        pricing_details: pricing_details
                    }, () => {
                        this.updatePrice();
                    })
                }
            );
        }
    }


    inputChangeHandler = (e, index) => {
        const items_total = Object.values(this.state.pricing_details.order_items).reduce((total, current) => {
            return total + parseFloat(current.total)
        }, 0);
        const value = e.target.value;
        if (value.match(/^\d{0,3}$/) === null) {
            return;
        }
        const order_items = cloneDeep(this.state.pricing_details.order_items);
        if (this.props.reorder && index === 'PhotoMozaix™') {
            let itemPrice = parseFloat(this.mosaicPrice);
            let quantity = value;
            if (quantity > 1 && quantity < 76) {
                // const slabs = ["2,5,35.10", "6,10,33.15", "11,20,31.20", "21,30,29.25", "31,50,27.30", "51,75,25.35"];
                const slabs = ["2,5,39.60", "6,10,37.40", "11,20,35.20", "21,30,33.00", "31,50,30.80", "51,75,28.60"];
                slabs.forEach(slab => {
                    slab = slab.split(',');
                    if (quantity >= parseInt(slab[0], 10) && quantity <= parseInt(slab[1], 10)) {
                        itemPrice = parseFloat(slab[2]);
                    }
                })
            } else if (quantity >= 76) {
                itemPrice = this.state.fullDiscountPrice;
            }
            order_items[index].quantity = quantity;
            order_items[index].total = quantity * itemPrice;
        } else {
            order_items[index].quantity = value
            order_items[index].total = order_items[index].quantity * order_items[index].price;
        }
        this.setState(prevState => ({
            pricing_details: {
                ...prevState.pricing_details,
                order_items: order_items
            },
            items_total: parseFloat(items_total)
        }), () => {
            this.updatePrice()
        });
    }
    percentage = (num, per) =>{
		return (per / 100) * num;
	}
    updatePrice = () => {
        const order_items = cloneDeep(this.state.pricing_details.order_items);
        let subtotal = reduce(order_items, (subtotal, current) => {
            return subtotal + (Number(current.quantity) * Number(current.price))
        }, 0);
        const pricing_details = cloneDeep(this.state.pricing_details);
        // let grand_total = subtotal;
        let grand_total = 0;
        let shipping = 0;
        var discount = 0;
		var previous_discount = pricing_details.discount_value;
        for (let key in order_items) {
            if (order_items.hasOwnProperty(key)) {
                let item = order_items[key];
                if (Number(item.quantity) > 0) {
                    shipping = 4;
                    // One item has quantity > 0, so no need to check further
                    break;
                } 
            }
        }
        if (this.props.reorder) {
            let mozaixQty = order_items['PhotoMozaix™'] ? Number(order_items['PhotoMozaix™'].quantity) : 0
            //Add $7.00 shipping if only one mozaix ordered
            if (mozaixQty === 1) {
                shipping = Number(this.props.mosaicShipping.value)
                grand_total += shipping;
            }
            //Calculate Mozaix discount
            else if (mozaixQty > 1 && mozaixQty < 76) {
                // const slabs = ["2,5,35.10", "6,10,33.15", "11,20,31.20", "21,30,29.25", "31,50,27.30", "51,75,25.35"];
                const slabs = ["2,5,39.60", "6,10,37.40", "11,20,35.20", "21,30,33.00", "31,50,30.80", "51,75,28.60"];
                slabs.forEach(slab => {
                    slab = slab.split(',');
                    if (mozaixQty >= parseInt(slab[0], 10) && mozaixQty <= parseInt(slab[1], 10)) {
                        discount = (this.mosaicPrice - Number(slab[2])) * mozaixQty;
                    }
                })
            }
            else if (mozaixQty >= 76) {
                discount = (this.mosaicPrice - this.state.fullDiscountPrice) * mozaixQty;
            }
            subtotal = subtotal - Number(discount).toFixed(2);
        }

        const order_totals = cloneDeep(this.state.pricing_details.order_totals);
        if(!this.props.reorder) {
            //Calculate discount
            if (pricing_details.discount_type === "%") {
                discount = this.percentage(subtotal, previous_discount);
            } else if (pricing_details.discount_type === "£") {
                discount = previous_discount;
            }
            //Calculate and add tax in grand total
            if (this.state.pricing_details.taxable) {
                let taxP = order_totals.tax.value;
                let sub_total_additional_fee = ("additional_fees" in pricing_details.order_totals ? Number(pricing_details.order_totals.additional_fees.value) : 0) + subtotal - discount;
                let tax = parseFloat((sub_total_additional_fee / 100) * taxP);
                if( tax <= 0 ){
                    tax = 0 ;
                }
                grand_total += tax;
            }
            //Add shipping in grand total
            if("shipping" in order_totals){
                // shipping = Number(order_totals.shipping.value);
                shipping = Number(shipping);
                grand_total += shipping
            }
        }
        //Add additional fees in grand total
        if("additional_fees" in order_totals){
            grand_total += Number(order_totals.additional_fees.value);
        }
        if(this.props.reorder) {
            //Add production fees in grand total
            if ('production' in order_totals) {
                grand_total += Number(order_totals.production.value)
                shipping += Number(order_totals.production.value)
            }
            //Add overnight shipping in grand total
            if ('overnight' in order_totals) {
                grand_total += Number(order_totals.overnight.value)
                shipping += Number(order_totals.overnight.value)
            }
            grand_total += subtotal;
        }else {
            grand_total += subtotal - Number(discount).toFixed(2);
        }

        order_totals.sub_total.value = subtotal;
        order_totals.grand_total.value = grand_total;
        order_totals.shipping = {
            ...order_totals.shipping,
            code: 'shipping',
            value: shipping
        }
        order_totals.discount = {
            ...order_totals.discount,
            code: 'discount',
            value: discount
        }
        this.setState(prevState => ({
            pricing_details: {
                ...prevState.pricing_details,
                order_items: order_items,
                order_totals: order_totals
            }
        }), () => {
            this.props.returnState(this.state.pricing_details, 'pricing_details');
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.pricing_details !== prevProps.pricing_details) {
            this.setState({
                pricing_details: this.props.pricing_details,
            }, () => {
                if (this.props.programItems !== prevProps.programItems) {
                    this.setState({
                        pricing_details: {
                            ...prevState.pricing_details,
                            order_items: this.props.programItems.reduce((total, current) => {
                                if (current.title === '20x30 PhotoMozaix') {
                                    this.mosaicPrice = parseFloat(current.item_program_price.pivot.price)
                                }
                                return {
                                    ...total,
                                    [current.title]: {
                                        item_id: current.item_program_price.pivot.item_id,
                                        price: parseFloat(current.item_program_price.pivot.price),
                                        quantity: current.quantity ? parseInt(current.quantity, 10) : 0,
                                        title: current.title,
                                        total: current.quantity ? parseInt(current.quantity, 10) * parseFloat(current.item_program_price.pivot.price) : 0
                                    }
                                }
                            }, {})
                        }
                    }, () => {
                        this.updatePrice();
                    })
                }
            })
        }
        // console.log('state', this.props.state);
        // if (prevProps != this.props.state && this.props.state ) {
        //     this.props.getSettingValueCall('tax', 'carolina');
        // }
        if (this.props.settings !== prevProps.settings) {
            this.setState(prevState => ({
                pricing_details: {
                    ...prevState.pricing_details,
                    order_totals: {
                        ...prevState.pricing_details.order_totals,
                        tax: this.props.settings
                    }
                }
            }))
        }
        if (this.props.programItems !== prevProps.programItems && this.props.formType === 'add' && !this.props.reorder) {
            this.setState({
                pricing_details: {
                    ...prevState.pricing_details,
                    order_items: this.props.programItems.reduce((total, current) => {
                        if (current.title === '20x30 PhotoMozaix') {
                            this.mosaicPrice = parseFloat(current.item_program_price.pivot.price)
                        }
                        return {
                            ...total,
                            [current.title]: {
                                item_id: current.item_program_price.pivot.item_id,
                                price: parseFloat(current.item_program_price.pivot.price),
                                quantity: current.quantity ? parseInt(current.quantity, 10) : 0,
                                title: current.title,
                                total: current.quantity ? parseInt(current.quantity, 10) * parseFloat(current.item_program_price.pivot.price) : 0
                            }
                        }
                    }, {})
                }
            }, () => {
                this.updatePrice();
            })
        }
        if (this.props.showErrorMessages && this.props.showErrorMessages !== prevProps.showErrorMessages) {
            this.props.validatorInstance.showMessages();
        }
        if (this.props.agency !== prevProps.agency) {
            this.setState(prevState => ({
                pricing_details: {
                    ...prevState.pricing_details,
                    program_type_id: this.props.agency.program_type_id
                }
            }))
            if (this.props.formType === "add") {
                this.props.getProgramItemCall(this.props.agency.program_type_id);
            } else {
                this.props.getProgramItemCall(User.getProperty('programType.id'));
            }
        }
    }


    render() {
        const { pricing_details } = this.state;
        const { notEditable, agency, formType, is_ttk } = this.props;
        let calculatedTax = 0;
        calculatedTax = parseFloat(Number(
            ("sub_total" in pricing_details.order_totals ? Number(pricing_details.order_totals.sub_total.value) : 0) +
            ("additional_fees" in pricing_details.order_totals ? Number(pricing_details.order_totals.additional_fees.value) : 0) -
            ("discount" in pricing_details.order_totals ? Number(pricing_details.order_totals.discount.value) : 0)
        ) * (parseFloat("tax" in pricing_details.order_totals ? pricing_details.order_totals.tax.value : 0) / 100)).toFixed(2);
        if(calculatedTax < 0) {
			calculatedTax = Number(0).toFixed(2);
        }
        const maozaixPath = process.env.NODE_ENV !== 'production' ? "groupmemories.dev.techliance.com" : "groupmemories.co.uk";
        return (
            <div className="form_tile">
                <div className="form_tile_heading">
                    <img src={s3StaticPath('img/product_pricing.png')} alt="Products &amp; Pricing" />
                    <span>Products &amp; Pricing</span>
                </div>
                <div className="form_tile_content">
                    <div className="reponsive-table form_tile_row">
                        <table className="table_tile">
                            <thead>
                                <tr>
                                    <th width="22%" className="text-center">Qty</th>
                                    <th width="40%">Product</th>
                                    {
                                        notEditable === undefined &&
                                        <React.Fragment>
                                            <th width="19%">Price</th>
                                            <th width="19%" className="text-center">Line</th>
                                        </React.Fragment>
                                    }
                                </tr>
                            </thead>

                            <tbody>
                                {
                                    Object.keys(pricing_details.order_items).map((item, i) => (
                                        <tr key={i}>
                                            {
                                                notEditable === undefined ?

                                                    (
                                                        (pricing_details.order_items[item].title !== 'Group Photo' &&
                                                        pricing_details.order_items[item].title !== 'Flash Drive with Gallery Photos' &&
                                                    pricing_details.order_items[item].title !== '11x14 MOSAICS' &&
                                                    pricing_details.order_items[item].title !== 'Individual Shipping of DVDs' &&
                                                    pricing_details.order_items[item].title !== 'Custom Editing Hrly Charge' &&
                                                    pricing_details.order_items[item].title !== 'Individual Shipping Teacher Special' &&
                                                    pricing_details.order_items[item].title !== 'CD of Images' &&
                                                    pricing_details.order_items[item].title !== 'ADDITIONAL CAMS/CARDS') &&

                                                    <React.Fragment>
                                                            <td><input onChange={(e) => this.inputChangeHandler(e, pricing_details.order_items[item].title)} value={pricing_details.order_items[item].quantity} type="number" placeholder="000" className="text-center form_tile_input block" /></td>
                                                            <td>
                                                                {
                                                                    pricing_details.order_items[item].title  === 'Videos/Travelers' ? "No. of travelers" : pricing_details.order_items[item].title
                                                                } {pricing_details.order_items[item].title === 'Videos/Travelers' ? '*' : ''} <br />
                                                                {
                                                                    pricing_details.order_items[item].title === '20x30 PhotoMozaix' ? <Link to='/mozaix' target='_blank' >Learn More About PhotoMozaix</Link> : null
                                                                }
                                                            </td>
                                                            <td>£{parseFloat(pricing_details.order_items[item].price).toFixed(2)}</td>
                                                            <td className="text-center">£{isNaN(parseFloat(pricing_details.order_items[item].total)) ? (0).toFixed('2') : parseFloat(pricing_details.order_items[item].total).toFixed(2)}</td>
                                                    </React.Fragment>)
                                                    :
                                                    ((((pricing_details.order_items[item].title === 'Group Photo' ||
                                                    pricing_details.order_items[item].title === '11x14 MOSAICS' ||
                                                    pricing_details.order_items[item].title === 'ADDITIONAL CAMS/CARDS') && pricing_details.order_items[item].quantity > 0) ||
                                                    (pricing_details.order_items[item].title !== 'Group Photo' &&
                                                    pricing_details.order_items[item].title !== '11x14 MOSAICS' &&
                                                    pricing_details.order_items[item].title !== 'Flash Drive with Gallery Photos' &&
                                                    pricing_details.order_items[item].title !== 'Individual Shipping of DVDs' &&
                                                    pricing_details.order_items[item].title !== 'Custom Editing Hrly Charge' &&
                                                    pricing_details.order_items[item].title !== 'Individual Shipping Teacher Special' &&
                                                    pricing_details.order_items[item].title !== 'CD of Images' &&
                                                    pricing_details.order_items[item].title !== 'ADDITIONAL CAMS/CARDS')) &&
                                                        <React.Fragment>
                                                            <td className='text-center'>{pricing_details.order_items[item].quantity}</td>
                                                            <td>{pricing_details.order_items[item].title  === 'Videos/Travelers' ? "No. of travelers" : pricing_details.order_items[item].title} *</td>
                                                        </React.Fragment>
                                                    )
                                            }
                                        </tr>
                                    ))
                                }
                                {
                                    notEditable === undefined &&
                                    <React.Fragment>
                                        {
                                            Object.keys(sortObjectWithDefineKeys(pricing_details.order_totals, [
                                                'sub_total', 'discount', 'additional_fees', 'tax', 'shipping', 'grand_total'
                                            ])).map(key => {
                                                return (
                                                        (
                                                            !(key === 'sub_total' || key === 'grand_total' || key === 'tax' || key === 'shipping')
                                                        )
                                                        ?
                                                        null
                                                        :
                                                        <tr key={key}  >
                                                            <td ></td>
                                                            <td ></td>
                                                            <td className={key === 'discount' ?  "text-danger" : "text-primary"}>{
                                                            key === 'tax' && pricing_details.order_totals[key].key ==='gm_tax' ?

                                                            'Vat (Tax)'
                                                            :
                                                            startCase(pricing_details.order_totals[key].code)

                                                            }</td>
                                                            {
                                                                key === 'tax' ?
                                                                    <td className="text-primary text-center">{parseFloat(pricing_details.order_totals[key].value).toFixed(2)} % (£{calculatedTax})</td>
                                                                    :
                                                                    <td className={key === 'discount' ?  "text-danger text-center" : "text-primary text-center"}>{"£" + parseFloat(pricing_details.order_totals[key].value).toFixed(2)}</td>
                                                            }
                                                        </tr>
                                                );
                                            })
                                        }
                                    </React.Fragment>
                                }
                            </tbody>
                        </table>
                        {
                            this.props.reorder &&
                            <div>
                                <strong>* Mosaic Quantity Discounts</strong>
                                <ul>
                                    <li>2-5 £39.60 each</li>
                                    <li>6-10 £37.40 each</li>
                                    <li>11-20 £35.20 each</li>
                                    <li>21-30 £33.00 each</li>
                                    <li>31-50 £30.80 each</li>
                                    <li>51-75 £28.60 each</li>
                                    <li>76+ £{this.state.fullDiscountPrice} each</li>
                                    <li>£7.00 shipping will be added for single mosaic delivery</li>
                                </ul>
                            </div>
                        }
                        {/* {
                            this.val.message('ssssss', Object.values(this.state.pricing_details.order_items).reduce((total, current) => {
                                return total + parseInt(current.quantity, 10)
                            }, 0), 'min:1,num')
                        } */}
                        {
                            pricing_details && pricing_details.order_items && 'Videos/Travelers' in pricing_details.order_items &&
                            this.val.message('No. of travelers', pricing_details.order_items['Videos/Travelers'].quantity, 'min:5,num')
                        }

                        {/* {

                            'settings' in agency &&
                            this.val.message('subTotal', parseFloat("sub_total" in pricing_details.order_totals ? pricing_details.order_totals.sub_total.value : 0), `numeric|min:${!Number(agency.settings.disable_minimum_price.value) && !this.props.reorder ? OC.MINORDER : '0'},num`)

                        } */}
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { programItems, settings } = state.settingsReducer;
    const { is_ttk } = state.agenciesReducer;
    return {
        programItems,
        settings,
        is_ttk
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getProgramItemCall: (id) => { dispatch(getProgramItem(id)) },
        getSettingValueCall: (key, stateName) => { dispatch(getSettingValue(key, stateName)) },
        getReorderEntryItems: () => dispatch(getReorderEntryItems()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Pricing)
