import { adminConstants } from '../constant/admin-constant';
import { alertActions } from './alert-actions';
import { roleService } from '../../services/roles';

export function getAgencyRoles() {
    return dispatch => {
        dispatch(request());
        roleService.getAgencyRoles()
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    }

    function request() { return { type: adminConstants.GET_AGENCY_ROLES_REQUEST } }
    function success(response) { return { type: adminConstants.GET_AGENCY_ROLES_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.GET_AGENCY_ROLES_FAILURE, payload: error } }
}

export function getUserRoles() {
    return dispatch => {
        dispatch(request());
        roleService.getUserRoles()
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    }

    function request() { return { type: adminConstants.GET_USER_ROLES_REQUEST } }
    function success(response) { return { type: adminConstants.GET_USER_ROLES_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.GET_USER_ROLES_FAILURE, payload: error } }
}

export function getRolesPagination(page, filter, sort, pageSize) {
    return dispatch => {
        dispatch(request())
        return roleService.getRolesPagination(page, filter, sort, pageSize)
            .then(
                response => {
                    dispatch(success(response));
                    return response;
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request() { return { type: adminConstants.PAGINATION_ROLES_REQUEST } }
    function success(response) { return { type: adminConstants.PAGINATION_ROLES_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.PAGINATION_ROLES_FAILURE, payload: error } }
}

export function storeRole(RoleData) {
    return dispatch => {
        dispatch(request());
        roleService.storeRole(RoleData)
            .then(
                response => {
                    dispatch(success(response));
                    dispatch(alertActions.success(response.message));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    }

    function request() { return { type: adminConstants.STORE_ROLE_REQUEST } }
    function success(response) { return { type: adminConstants.STORE_ROLE_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.STORE_ROLE_FAILURE, payload: error } }
}

export function getRole(id) {
    return dispatch => {
        dispatch(request());
        roleService.getRole(id)
            .then(
                Role => {
                    dispatch(success(Role));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    }

    function request() { return { type: adminConstants.GET_ROLE_REQUEST } }
    function success(Role) { return { type: adminConstants.GET_ROLE_SUCCESS, payload: Role } }
    function failure(error) { return { type: adminConstants.GET_ROLE_FAILURE, payload: error } }
}

export function editRole(RoleData, roleId) {

    return dispatch => {
        dispatch(request());
        roleService.editRole(RoleData, roleId)
            .then(
                response => {
                    dispatch(success(response));
                    dispatch(alertActions.success(response.message));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    }

    function request() { return { type: adminConstants.EDIT_ROLE_REQUEST } }
    function success(response) { return { type: adminConstants.EDIT_ROLE_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.EDIT_ROLE_FAILURE, payload: error } }
}

export function deleteRole(roleId) {
    return dispatch => {
        dispatch(request());
        roleService.deleteRole(roleId)
            .then(
                response => {
                    dispatch(success(response));
                    dispatch(alertActions.success(response.message));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error.message));
                }
            );
    }

    function request() { return { type: adminConstants.DELETE_ROLE_REQUEST } }
    function success(response) { return { type: adminConstants.DELETE_ROLE_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.DELETE_ROLE_FAILURE, payload: error } }
}