import React, { Component } from 'react';
import $ from 'jquery';
import { s3StaticPath, getDemoVideoLink } from '../../helper/helperFunctions';
import { MdPhotoSizeSelectActual, MdLocalMovies } from 'react-icons/md';
import { IoMdPlay } from 'react-icons/io';
import { FaHandHoldingHeart, FaQuestion } from 'react-icons/fa';
import { login, logout } from 'redux/actions/front-end-action';
import { connect } from 'react-redux';
import {SC} from '../../helper/ServerCall'



import VideoIcon from './VideoIcon'
class Demovideo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isHovered: false,
      full_name: '',
      phone: '',
      email: '',
      password: '',
      message: '',
      company: '',
      activeTabNo: 1,
      activeVideo: 1,
      bucket: 'https://grouptravelvideos.s3.amazonaws.com/',
      videoUrl: 'global/2016_DC_demo.mp4',
      location: 'Washington DC',
      showVideos: {
        dc:"true",
        orlando:"true",
        nyc:"true",
        chicago:"true"
      },
      agency: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleHover = this.handleHover.bind(this);
    this.activeTabFunction = this.activeTabFunction.bind(this);
    if (Object.keys(this.props.match.params).length > 1) {
      const params = { ...this.props.match.params };
      if (params.username !== undefined && params.password !== undefined) {
        this.props.loginCall(params.username, params.password)
      }
    }
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleSubmit(e) {
    e.preventDefault();
    // document.querySelector('.loginIcon').classList.add('open');
    this.setState({ submitted: true });
    const { email, password } = this.state;
    if (email && password) {
      this.props.loginCall(email, password);
    }
  }


componentDidMount(){
    let params = Object.fromEntries(new URLSearchParams(this.props.location.search));
    let vals = Object.values(params);

    if(vals && vals[0] && vals[0].length){
        this.setState({
            ...this.state,
            showVideos: params
        })
    }

    if(params && params.customerID){
      SC.getCall(`getAgencyLogo/${params.customerID}`).then(response =>{
        this.setState({
          ...this.state,
          agency: response
        })
      })
    }
}

  activeTabFunction(tab, name, url = null, location = null) {
    if (name === 'activeVideo') {
      if (this.state.activeVideo !== tab) {
        this.setState({ [name]: tab, videoUrl: url, location: location },);
      }
    } else {
      if (this.state.activeTabNo !== tab) {
        this.setState({ [name]: tab });
      }
    }
    return null;
  }

  handleVideo() {
    let demoVideo = document.getElementById("demoVideo");
    demoVideo.play();
  }

  inputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  handleHover() {
    // this.setState(prevState => ({
    //     isHovered: !prevState.isHovered
    // }));

    $("#clients div.client").hover(function () {
      $("#clients div.client").removeClass("pink_gradient")
      $(this).addClass("pink_gradient")
    }, function () {
      $("#clients div.client").removeClass("pink_gradient")
    })
  }
  render() {
    const { loggingIn } = this.props;
    // const hoverClass = this.state.isHovered ? "client pink_gradient" : "client";
    // let sliderSettings = {
    //   dots: true,
    //   autoplay: true,
    //   infinite: true,
    //   speed: 500,
    //   slidesToShow: 1,
    //   slidesToScroll: 1,
    //   accessibility: false,
    //   className: 'benefits',
    //   prevArrow: <div><MdArrowBack /></div>,
    //   nextArrow: <div><MdArrowForward /></div>,
    // };
    const posters = {
      'Washington DC': 'washington-dc.jpg',
      'Chicago': 'Chicago.jpg',
      'New York City': 'New-York-City.jpg',
      'Orlando': 'Orlando.jpg',
      'Italy': 'Italy.jpg',
    }
    const { email, password, submitted } = this.state

    return (
      <React.Fragment>
        <section id="uploaderTabs" className="section sampleVideos resetPadding">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12" style={{padding: '0'}}>
                <div className="tabsContainer">
                  <div className="tabsHeader">
                    <div className="grid">
                      <div>
                        <a href="#/" type='button'
                          onClick={() => this.activeTabFunction(1, 'activeTabNo')}

                          className={"tabBtn text_16 " + (this.state.activeTabNo === 1 ? "activeTab red_gradient" : "medium_blue_gradient")}><MdLocalMovies />&nbsp;Sample Videos</a>
                      </div>
                      <div>
                        <a href="#/" type='button'
                          onClick={() => this.activeTabFunction(2, 'activeTabNo')}
                          className={"tabBtn text_16 " + (this.state.activeTabNo === 2 ? "activeTab red_gradient" : "medium_blue_gradient")}><FaQuestion />&nbsp;How it works</a>
                      </div>
                      <div>
                        <a href="#/" type='button'
                          onClick={() => this.activeTabFunction(3, 'activeTabNo')}
                          className={"tabBtn text_16 " + (this.state.activeTabNo === 3 ? "activeTab red_gradient" : "medium_blue_gradient")} ><FaHandHoldingHeart />&nbsp;Benefits</a>
                      </div>
                      <div>
                        <a href="#/" type='button'
                          onClick={() => this.activeTabFunction(4, 'activeTabNo')}
                          className={"tabBtn text_16 " + (this.state.activeTabNo === 4 ? "activeTab red_gradient" : "medium_blue_gradient")}><IoMdPlay />&nbsp;App Demo</a>
                      </div>
                      <div>
                        <a href="#/" type='button'
                          onClick={() => this.activeTabFunction(5, 'activeTabNo')}
                          className={"tabBtn text_16 " + (this.state.activeTabNo === 5 ? "activeTab red_gradient" : "medium_blue_gradient")}><MdPhotoSizeSelectActual />&nbsp;View Photos</a>
                      </div>
                    </div>
                  </div>

                  <div className="tabsContent" style={{minHeight: 'calc(100vh - 44px)'}}>
                    <div className="mb-lg logos">
                      <img src={s3StaticPath('img/gtv_logos/group-travel-videos-wide-small.jpg')} alt="Group Memories Logo" />
                      {
                        this.state.agency &&
                        <img src={this.state.agency.data.file_path} log={console.log(this.state.agency.data)} height="66" alt={this.state.agency.data.file_title} />
                      }
                    </div>
                    {this.state.activeTabNo === 1 &&
                      <div className="contentContainer">
                        <div className="flexElem respFlex">
                          <div>
                            <div className="text_18 text-left">Click Below to View Sample Videos</div>

                            <ul className="sampleVideosList">
                                {
                                    this.state.showVideos.dc == "true" &&
                                    <li onClick={() => this.activeTabFunction(1, 'activeVideo', 'global/2016_DC_demo.mp4', 'Washington DC')}>
                                        <VideoIcon iconClass={this.state.activeVideo === 1 ? "fa-spin" : ""} svgColor={this.state.activeVideo === 1 ? "url('#video-gradient')" : "currentColor"} svgID="video-gradient" />
                                        <span> Washington DC</span>
                                    </li>
                                }
                                {
                                    this.state.showVideos.nyc == "true" &&
                                    <li onClick={() => this.activeTabFunction(2, 'activeVideo', 'global/2016_NY_demo.mp4', 'New York City')}>
                                        <VideoIcon iconClass={this.state.activeVideo === 2 ? "fa-spin" : ""} svgColor={this.state.activeVideo === 2 ? "url('#video-gradient')" : "currentColor"} svgID="video-gradient" />
                                        <span> New York City</span>
                                    </li>
                                }
                                {
                                    this.state.showVideos.orlando == "true" &&
                                    <li onClick={() => this.activeTabFunction(3, 'activeVideo', 'global/2016_orlando_demo.mp4', 'Orlando')}>
                                        <VideoIcon iconClass={this.state.activeVideo === 3 ? "fa-spin" : ""} svgColor={this.state.activeVideo === 3 ? "url('#video-gradient')" : "currentColor"} svgID="video-gradient" />
                                        <span> Orlando</span>
                                    </li>
                                }
                                {
                                    this.state.showVideos.chicago == "true" &&
                                    <li onClick={() => this.activeTabFunction(4, 'activeVideo', 'global/2016_chicago_demo.mp4', 'Chicago')}>
                                        <VideoIcon iconClass={this.state.activeVideo === 4 ? "fa-spin" : ""} svgColor={this.state.activeVideo === 4 ? "url('#video-gradient')" : "currentColor"} svgID="video-gradient" />
                                        <span> Chicago</span>
                                    </li>
                                }
                            </ul>

                            <div className="text_18 mt-xlg text-left">Senior Travel</div>

                            <ul className="sampleVideosList">
                              <li onClick={() => this.activeTabFunction(5, 'activeVideo', 'global/2016_senior_italy_demo.mp4', 'Italy')}>
                                <VideoIcon iconClass={this.state.activeVideo === 5 ? "fa-spin" : ""} svgColor={this.state.activeVideo === 5 ? "url('#video-gradient')" : "currentColor"} svgID="video-gradient" />
                                <span> Italy</span>
                              </li>
                            </ul>
                          </div>

                          <div id="video" style={{ border: "none" }}>
                            <div className="vidContainer">
                              <div className="section_heading mt-none text-center">{this.state.location}</div>

                              <div className="video_placeholder mb-none">
                                <video onPlay={() => { $(".video_placeholder .video_play").hide() }} onPause={() => { $(".video_placeholder .video_play").show() }} id="demoVideo" controls src={this.state.bucket + this.state.videoUrl} type="video/mp4" poster={this.state.bucket + `static/img/${posters[this.state.location]}`}></video>
                                <div className="video_play blue_gradient hover_dark" onClick={this.handleVideo}></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    }

                    {this.state.activeTabNo === 2 &&
                      <div className="contentContainer">
                        <div className="flexElem">
                          <div>
                            <span className="howItWorksNumber purple_gradient">1</span>
                          </div>
                          <div>
                            <div className="text_16 howItWorksPoints">
                              We provide your group with a username &amp; password to login to our app and website to upload trip photos.
                                    </div>
                            <div className="text_14 mt-xs">
                              We can also send you a camera or memory card.
                                    </div>
                          </div>
                        </div>

                        <div className="flexElem mt-xlg">
                          <div>
                            <span className="howItWorksNumber purple_gradient">2</span>
                          </div>
                          <div>
                            <div className="text_16 howItWorksPoints">
                              Your group uploads photos with our app or website while and/or after you travel.
                                    </div>
                            <div className="text_14 mt-xs">
                              You return any of our cameras or cards back in the prepaid mailer we provide.
                                    </div>
                          </div>
                        </div>

                        <div className="flexElem mt-xlg">
                          <div>
                            <span className="howItWorksNumber purple_gradient">3</span>
                          </div>
                          <div>
                            <div className="text_16 howItWorksPoints">
                              We edit your photos and repost them to the app and website. Then we create your video from the edited photos.
                                    </div>
                            <div className="text_14 mt-xs">
                              Your photos can only be accessed with your group’s login.
                                    </div>
                          </div>
                        </div>

                        <div className="flexElem mt-xlg">
                          <div>
                            <span className="howItWorksNumber purple_gradient">4</span>
                          </div>
                          <div>
                            <div className="text_16 howItWorksPoints">
                              {/* We ship your DVDs to you and make your video available to stream on our app &amp; website. */}
                             We make your keepsake video available to stream on our app and download &amp; save on our website.
                                    </div>
                          </div>
                        </div>
                      </div>
                    }

                    {this.state.activeTabNo === 3 &&
                      <div className="contentContainer">
                        <div className="flexElem">
                          <div>
                            <span className="howItWorksNumber purple_blue_gradient"></span>
                          </div>
                          <div>
                            <div className="text_16 howItWorksPoints">
                              New FREE Mobile App:
                                  </div>
                            <div className="text_14 mt-xs">
                              Allows you to upload and share photos while you travel.
                                  </div>
                          </div>
                        </div>

                        <div className="flexElem mt-xlg">
                          <div>
                            <span className="howItWorksNumber purple_blue_gradient"></span>
                          </div>
                          <div>
                            <div className="text_16 howItWorksPoints">
                              Live Video Clips &amp; Performance Music:
                                  </div>
                            <div className="text_14 mt-xs">
                              You can include these in your videos for FREE.
                                  </div>
                          </div>
                        </div>

                        <div className="flexElem mt-xlg">
                          <div>
                            <span className="howItWorksNumber purple_blue_gradient"></span>
                          </div>
                          <div>
                            <div className="text_16 howItWorksPoints">
                              Facebook Sharing:
                                  </div>
                            <div className="text_14 mt-xs">
                              You can easily share photos &amp; your highlight video on Facebook from the app or GroupMemories.co.uk photo gallery.
                                  </div>
                          </div>
                        </div>

                        <div className="flexElem mt-xlg">
                          <div>
                            <span className="howItWorksNumber purple_blue_gradient"></span>
                          </div>
                          <div>
                            <div className="text_16 howItWorksPoints">
                              Video Downloads:
                                  </div>
                            <div className="text_14 mt-xs">
                              Videos can be downloaded from the app or GroupMemories.co.uk gallery for offline viewing.
                                  </div>
                          </div>
                        </div>

                        <div className="flexElem mt-xlg">
                          <div>
                            <span className="howItWorksNumber purple_blue_gradient"></span>
                          </div>
                          <div>
                            <div className="text_16 howItWorksPoints">
                              Photo Editing:
                                  </div>
                            <div className="text_14 mt-xs">
                              Our editors go through each photo to remove unusable photos, do color correction, &amp; cropping.
                                  </div>
                          </div>
                        </div>

                        <div className="flexElem mt-xlg">
                          <div>
                            <span className="howItWorksNumber purple_blue_gradient"></span>
                          </div>
                          <div>
                            <div className="text_16 howItWorksPoints">
                            Keepsake Video:
                                  </div>
                            <div className="text_14 mt-xs">
                            Each traveler is able to stream, cast, share and download the video to preserve their memories for years to come!
                                  </div>
                          </div>
                        </div>
                      </div>
                    }

                    {this.state.activeTabNo === 4 &&
                      <div className="contentContainer">
                        <div className="video_placeholder mb-none">
                          {/* <img src={s3StaticPath('img/video_placeholder.jpg')} className="block" alt="Video placeholder" /> */}
                          {/* <video onPlay={() => { $(".video_placeholder .video_play").hide() }} onPause={() => { $(".video_placeholder .video_play").show() }} id="demoVideo" controls src={s3StaticPath('gtv_appDemo_apple.mp4')} type="video/mp4" poster={s3StaticPath('img/gtv_app_video.jpg')}></video> */}
                          <video onPlay={() => { $(".video_placeholder .video_play").hide() }} onPause={() => { $(".video_placeholder .video_play").show() }} id="demoVideo" controls src={getDemoVideoLink(this.props.location.search)} type="video/mp4" poster={s3StaticPath('img/gtv_app_video.jpg')}></video>
                          <div className="video_play blue_gradient hover_dark" onClick={this.handleVideo}></div>
                        </div>
                      </div>
                    }

                    {this.state.activeTabNo === 5 &&
                      <div className="contentContainer">
                        <div className="login_form">
                          <div className="text_16">Enter Your Group's Username and Password to view your photos on GroupMemories.co.uk:</div>
                          <form name="form">
                              <div className="form-group mt-md">
                                <input type="text" placeholder="Email or Username" onChange={this.handleChange} className={"form-control" + (submitted && !email ? ' has-error' : '')} name="email" value={email} />
                                {submitted && !email &&
                                <span style={{ marginTop: '0' }} className="help-block">Email is required.</span>
                                }
                              </div>
                              <div className="form-group">
                                <input type="password" onKeyUp={(e) => {
                                if (e.key === 'Enter') {
                                    this.button.click();
                                }
                                }}  placeholder="Password" onChange={this.handleChange} className={"form-control" + (submitted && !password ? ' has-error' : '')} name="password" value={password} />
                                {(submitted && !password) &&
                                    <span style={{ marginTop: '0' }} className="help-block">Password is required.</span>
                                }
                              </div>
                              <div className="form-group">
                                  <button style={{width:'100%'}} onClick={this.handleSubmit} className="btn btn-default btn_login">LOGIN</button>
                                  {
                                      loggingIn &&
                                      <img alt="imageLogin" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                  }
                              </div>
                          </form>
                      </div>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
    </React.Fragment>
    );
  }
}

// export default Demovideo;

const mapDispatchToProps = (dispatch) => {
  return {
    loginCall: (email, password) => { dispatch(login(email, password)) },
    logoutCall: () => { dispatch(logout()) }
  }
}

function mapStateToProps(state) {
  const { loading, User, loggedIn } = state.frontEndUserReducer;
  return {
    loading, User, loggedIn
  };
}

const Samplevideo = connect(mapStateToProps, mapDispatchToProps)(Demovideo);
export default Samplevideo;
