import { adminConstants } from "../constant/admin-constant";

const initialState = {
    programType: {},
    programTypesPagination: {}
}

export const programTypeReducer = (state = initialState, action) => {
    switch (action.type) {

        case adminConstants.STORE_PROGRAM_TYPE_SUCCESS:
            return {
                ...state,
            }
        case adminConstants.STORE_PROGRAM_TYPE_FAILURE:
            return {
                ...state,
                error: action.payload.error
            }
        case adminConstants.GET_SINGLE_PROGRAM_TYPE_SUCCESS:
            return {
                ...state,
                programType: action.payload.data
            }
        case adminConstants.GET_SINGLE_PROGRAM_TYPE_FAILURE:
            return {
                ...state,
                error: action.payload.error
            }
        case adminConstants.GET_PROGRAM_TYPES_PAGINATION_SUCCESS:
            return {
                ...state,
                programTypesPagination: action.payload.data
            }
        case adminConstants.DELETE_PROGRAM_TYPE_SUCCESS:
            return {
                ...state,
                programTypesPagination: {
                    ...state.programTypesPagination,
                    data: action.payload
                }
            }
        case adminConstants.UPDATE_PROGRAM_TYPE_SUCCESS:
            const data = [...state.programTypesPagination.pagination.data];
            const index = data.findIndex(function (item) {
                return item.id === action.payload.id;
            });
            data[index] = { ...data[index],...action.payload.data }
            return {
                ...state,
                programTypesPagination: {
                    ...state.programTypesPagination,
                    data: [...data]
                }
            }
        default:
            return state
    }
}
