import { programTypeService } from "../../services/ProgramType";
import { alertActions } from "./alert-actions";
import { adminConstants } from "../constant/admin-constant";

export function storeProgramType(data) {
    return dispatch => {
        return programTypeService.storeProgramType(data).then(
            response => {
                dispatch(getProgramTypesPagination(1, '', '', 10))
                dispatch(alertActions.success(response.message))
                return response;
            },
            error => {
                dispatch(alertActions.error(error))
                dispatch(failure(error))
                return Promise.reject(error)
            }
        )
    }
    // function success(response) { return { type: adminConstants.STORE_PROGRAM_TYPE_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.STORE_PROGRAM_TYPE_FAILURE, payload: error } }
}

export function getProgramType(id) {
    return dispatch => {
        programTypeService.getProgramType(id).then(
            response => {
                dispatch(success(response))
            },
            error => {
                dispatch(failure(error))
            }
        )
    }
    function success(response) { return { type: adminConstants.GET_SINGLE_PROGRAM_TYPE_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.GET_SINGLE_PROGRAM_TYPE_FAILURE, payload: error } }
}

export function updateProgramType(data, id) {
    return dispatch => {
        return programTypeService.updateProgramType(data, id).then(
            response => {
                dispatch(success(id, data))
                dispatch(alertActions.success(response.message))
                return response
            },
            error => {
                dispatch(failure(error))
                dispatch(alertActions.error(error))
                return Promise.reject(error)
            }
        )
    }
    function success(id, data) { return { type: adminConstants.UPDATE_PROGRAM_TYPE_SUCCESS, payload: { id: id, data: data } } }
    function failure(error) { return { type: adminConstants.UPDATE_PROGRAM_TYPE_FAILURE, payload: error } }
}

export function getProgramTypesPagination(page, filter, sort, pageSize, orderType) {
    return dispatch => {
        return programTypeService.getProgramTypesPagination(page, filter, sort, pageSize, orderType).then(
            response => {
                dispatch(success(response))
                return response
            },
            error => {
                return error
            }
        )
    }
    function success(response) { return { type: adminConstants.GET_PROGRAM_TYPES_PAGINATION_SUCCESS, payload: response } }
    // function failure(error) { return { type: adminConstants.GET_SINGLE_PROGRAM_TYPE_FAILURE, payload: error } }
}

export function deleteProgramType(id) {
    return dispatch => {
        return programTypeService.deleteProgramType(id).then(
            response => {
                dispatch(alertActions.success(response.message))
            },
            error => {

            }
        )
    }
    // function success(data) { return { type: adminConstants.DELETE_PROGRAM_TYPE_SUCCESS, payload: data } }
    // function failure(error) { return { type: adminConstants.DELETE_PROGRAM_TYPE_FAILURE, payload: error } }
}

