import React, { Component } from 'react';
import {
    Col,
    // OverlayTrigger,
    // ButtonToolbar,
    // Popover
} from "react-bootstrap";
import ThemeModal from '../../ThemeComponents/ThemeModal';
import ToggleButton from 'react-toggle-button';
import { themeCSSConstants } from 'redux/constant/themeCSS';
// import MoreButtonForm from "../MoreButton/MoreButtonForm";
import OrderMoreButtonsWeTravel from "../MoreButton/OrderMoreButtonsWeTravel";
import { connect } from 'react-redux'
import { getMoreButtonsPagination, deleteMoreButton, updateMoreButtonStatus, getMoreButtonsForOrder, updateMoreButtonForOrder } from '../../../redux/actions/more-buttons-action';
import Confirmalertfordelete from 'components/ThemeComponents/confirmAlertForDelete';
import { createSortString, closeModal } from '../../../helper/helperFunctions'
import { objectsConstants as OC } from 'redux/constant/objects-constant';
import 'react-table/react-table.css';
import { confirmAlert } from 'react-confirm-alert';
import Pagination from '../../ThemeComponents/Pagination'
import OrderMoreButtonForm from './OrderMoreButtonForm';
import { SC } from 'helper/ServerCall';
// import { getAgency } from 'redux/actions/agency-actions';
class OrderMoreButtonsList extends Component {

    constructor(props) {
        super(props);
        this.table = React.createRef();
        this.state = {
            pageSize: 10,
            filtered: [],
            moreButtonEditModal: false,
            moreButtonCreateModal: false,
            moreButtonEditData: '',
            is_active: '1',
            agency: false,
            order_details: { ...this.props.order_details }
        }
        this.showModal = this.showModal.bind(this);
    }


    editMoreOrderBtn = (moreBtnData, id, check, e) => {
        const data = new FormData();
        Object.keys(moreBtnData).forEach(item => {
            if(item == 'is_active'){
                return;
            }
            data.append(item, moreBtnData[item]);
        })

        if (this.props.order_id) {
            data.append('order_id', this.props.order_id);
        }

        let tempValue = !check;
        tempValue = tempValue ? 1 : 0;
        data.append('is_active', tempValue);
        // console.log(tempValue,check, e, 'tempValue check e');

        if (!moreBtnData.file_name && !moreBtnData.link) {

            this.showModal(moreBtnData, e, 'moreButtonEditModal')
            this.setState({
                moreButtonEditModal: true
            });

        } else {
            // check
            //     ?
            //     this.deleteMoreButtonHandler(id)
            //     :
                this.props.updateMoreButtonForOrderCall(data, moreBtnData.id, this.props.order_id).then(
                    response => {
                        this.pagination.dataCall();
                    }
                );
        }
    }

    showModal(data = '', e, target_name = null) {
        let newData = { ...data };
        newData.file_path = {
            preview: data.file_path,
            name: data.file_name,
            type: data.mime_type,
            id: data.id
        }
        target_name = target_name ? target_name : e.target.name;
        // console.log(newData, 'newData');
        this.setState({
            [target_name]: true,
            moreButtonEditData: newData,
        });

    }
    deleteConfirmMoreButton = (id) => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <Confirmalertfordelete typeName="Button" description="Are you sure you want to delete this button" onClosePro={onClose} deleteType={() => this.deleteMoreButtonHandler(id)} />
                )
            }
        })
    }

    deleteMoreButtonHandler(id) {
        this.props.deleteMoreButtonCall(id).then(res => {
            this.pagination.dataCall();
        });
    }

    paginationCall = (data) => {
        let sorted = data.sort.split(',');
        let { sort, orderType } = createSortString(sorted);
        return this.props.getMoreButtonsForOrderCall(data.page, data.filter, sort, data.pageSize, orderType, this.props.order_id);
        // return this.props.getMoreButtonsPaginationCall(data.page, data.filter, sort, data.pageSize, orderType, this.props.agency_id);

    }
    handleInactiveStatusUpdate = (moreButtonId, e) => {
        this.props.updateMoreButtonStatusCall(moreButtonId, { is_active: e.target.value ? true : false }).then(
            response => {
                this.pagination.dataCall();
            }
        );
    }
    handleActiveStatusUpdate = (moreButtonId, e) => {
        this.props.updateMoreButtonStatusCall(moreButtonId, { is_active: e.target.value ? false : true }).then(
            response => {
                this.pagination.dataCall();
            }
        );
    }
    static getDerivedStateFromProps(props, state) {
        // if(this.props.agency_id){
        //     this.props.getAgencyCall(this.props.agency_id)
        // }
        // self.props.getAgencyCall(props.order_details.agency_id);
        return {
            order_details: { ...props.order_details }
        };
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.props.order_details != prevProps.order_details) {
            this.setState({
                order_details: { ...this.props.order_details }
            });
        }
    }
    componentDidMount(prevProps, prevState) {
            // console.log('calling agency');
    }

    editOrderWeTravelSetting(data) {
        return SC.postCall('editOrderWeTravelSetting', data);
    }


    render() {
        // console.log(this.props.editAgency, 'agencyyyyyy');
        const { moreButtons, is_active } = this.state;

        const { editAgency, order_details } = this.props;
        const agency = editAgency
        // console.log(order_details, 'order_details');
        const columns = [
            {
                Header: 'Activate For Mobile App',
                sortable: false,
                excExp: false,
                accessor: 'id',
                width: 150,
                height: 100,
                className: "action-center",
                Cell: ({ original }) => {
                    return (
                        <div className="togglePadding">
                            <ToggleButton
                                inactiveLabel={''}
                                activeLabel={''}
                                colors={themeCSSConstants.THUMBICONSTYLE}
                                trackStyle={{ height: '10px', width: '30px' }}
                                thumbStyle={themeCSSConstants.THUMBSTYLE}
                                name={'moreButtonEditModal'}
                                thumbAnimateRange={[0, 15]}
                                value={original.model_type == 'App\\Order' ? (original.is_active == '1' ? 1 : 0) : (original.show_on_all_orders == '1' ? 1 : 0)}
                                disabled={false}
                                readOnly={true}
                                onToggle={() => { this.editMoreOrderBtn(original, original.id, original.model_type == 'App\\Order' ? (original.is_active == '1' ? 1 : 0) : (original.show_on_all_orders == '1' ? 1 : 0)) }}
                            />
                        </div>
                    )
                }
            },

            {
                Header: 'Title',
                accessor: 'title',
                className: "action-center",
                // width: 120,
                height: 100,

            },
            {
                Header: 'Description',
                accessor: 'description',
                className: "action-center",
                // width: 150,
                height: 100,

            },
            // {
            //     Header: 'Show On All Orders',
            //     accessor: 'show_on_all_orders',
            //     className: "action-center",
            //     Cell: ({ value }) => {
            //         return (
            //             parseInt(value, 10) == 1 ? 'Yes' : 'No'
            //         )
            //     },
            //     // width: 120,
            //     height: 100,

            // },
            // {
            //     Header: 'Option At Order Placement',
            //     accessor: 'option_at_order_placement',
            //     className: "action-center",
            //     Cell: ({ value }) => {
            //         return (
            //             parseInt(value, 10) == 1 ? 'Yes' : 'No'
            //         )
            //     },
            //     // width: 120,
            //     height: 100,

            // },

            {
                Header: 'File Name/Link',

                accessor: 'id',
                className: "action-center",
                Cell: ({ original }) => {
                    //     console.log(original, "data");
                    return <span>{

                        original.file_name ? original.file_name :original.link

                        }</span>
                }
            },

            // {
            //     Header: 'Link',
            //     accessor: 'link',
            //     className: "action-center",
            // },

            {
                Header: "Actions",
                accessor: "id",
                className: "action-center",
                Cell: ({ original, value }) => {
                    // console.log(original, "valueees")
                    return (
                        <button name='moreButtonEditModal' onClick={(e) => { this.showModal(original, e) }} type='button' className="orderDetailsAction editIcon" >&nbsp;</button>
                    )
                },

                className: "action-center",
                sortable: false,
                excExp: false,
                width: 100,
            }
        ]
        return (
            <div>
                <Col md={12} className="mt-md">
                    <React.Fragment>
                        <div className='row container-fluid'>
                            <OrderMoreButtonsWeTravel
                              order_id={this.props.order_id}
                             />
                            <Pagination
                                noCols={true}
                                ref={(p) => this.pagination = p}
                                showPagination={false}
                                columns={columns}
                                pageSize={10}
                                noDataText='No Item found'
                                getDataCall={this.paginationCall}
                                // filterView={true}
                                // filterPlaceHolder={'Button'}
                                getRowProps={this.getRowProps}
                            />
                        </div>
                    </React.Fragment>
                </Col>
                <ThemeModal size='lg' show={this.state.moreButtonEditModal} hide={closeModal.bind(this, 'moreButtonEditModal')} title='Edit More Button'>
                    <OrderMoreButtonForm getPagination={this.pagination ? this.pagination.dataCall : null} formType='edit' hide={closeModal.bind(this, 'moreButtonEditModal')} data={this.state.moreButtonEditData} order_id={this.props.order_id} />
                </ThemeModal>
                {/*
                <ThemeModal size='lg' show={this.state.moreButtonCreateModal} hide={closeModal.bind(this, 'moreButtonCreateModal')} title='Create More Button'>
                    <MoreButtonForm getPagination={this.pagination ? this.pagination.dataCall : null} hide={closeModal.bind(this, 'moreButtonCreateModal')} formType='add' agency_id={this.props.agency_id} />
                </ThemeModal> */}
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    const { editAgency } = state.agenciesReducer;
    const { moreButtonsPagination, moreButtonsForOrder } = state.moreButtonReducer;
    const { order_details} = state.ordersReducer;
    return { moreButtonsPagination, moreButtonsForOrder, editAgency,order_details }
}

const mapDispatchToProps = dispatch => {
    return {
        // getAgencyCall: (id) => {
        //     dispatch(getAgency(id))
        // },
        getMoreButtonsPaginationCall: (page, filter, sort, pageSize, orderType, agency_id) => { return dispatch(getMoreButtonsPagination(page, filter, sort, pageSize, orderType, agency_id)) },
        getMoreButtonsForOrderCall: (page, filter, sort, pageSize, orderType, order_id) => { return dispatch(getMoreButtonsForOrder(page, filter, sort, pageSize, orderType, order_id)) },
        updateMoreButtonStatusCall: (moreButtonId, data) => { return dispatch(updateMoreButtonStatus(moreButtonId, data)) },
        deleteMoreButtonCall: (id) => { return dispatch(deleteMoreButton(id)) },
        updateMoreButtonForOrderCall: (data, id) => { return dispatch(updateMoreButtonForOrder(data, id)) }

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(OrderMoreButtonsList)
