import React from "react";
import { updateOrderVideoTitles, videoTitlesByAgencyType } from "redux/actions/order-actions";
import ReactValidator from "simple-react-validator";
import { connect } from "react-redux";
import { getAgencyType } from 'redux/actions/settings-actions';
import { returnColumnValueWithColumnMatch } from 'helper/helperFunctions';
import Select from 'react-select';
var cloneDeep = require('lodash.clonedeep');

/*suggestion by hassan : This module should be free of pivot  it will create issues for bigger change*/
class OrderTitle extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            ...this.props.titles,
            vt_agency_type_id: this.props.order_details.vt_agency_type_id || null
        }
        this.titlesValidator = new ReactValidator();
        this.handleChangeAgencyDropDown = this.handleChangeAgencyDropDown.bind(this);
        this.props.getAgencyTypeCall();
    }

    handleChangeAgencyDropDown(selectedOptionType, info) {
        this.props.videoTitlesByAgencyTypeCall(selectedOptionType['value'], this.props.orderId).then(

            response => {

                var titleData = cloneDeep(this.state);
                titleData.ot1.pivot.value = response.data.ot1.value;
                titleData.ot1.value = response.data.ot1.value;

                titleData.ct1.pivot.value = response.data.ct1.value;
                titleData.ct1.value = response.data.ct1.value;

                titleData.ct2.pivot.value = response.data.ct2.value;
                titleData.ct2.value = response.data.ct2.value;


                titleData.ct3.pivot.value = response.data.ct3.value;
                titleData.ct3.value = response.data.ct3.value;

                this.setState({ ...titleData }, () => {
                    this.setState({ vt_agency_type_id: selectedOptionType['value'] })
                });
            },
            error => {

            }
        );

    }


    videoTitleChangeHandler = (e) => {
        const title = e.target.name;
        const value = e.target.value;
        this.setState((state) => {
            return {
                [title]: {
                    ...state[title],
                    'pivot': {
                        ...state[title].pivot,
                        value: value
                    }
                }
            }
        })
    }

    videoTitleSubmitHandler = () => {
        if (this.titlesValidator.allValid()) {
            const data = {
                ot1: {
                    ...this.state.ot1
                },
                ct1: {
                    ...this.state.ct1
                },
                ct2: {
                    ...this.state.ct2
                },
                ct3: {
                    ...this.state.ct3
                }
            }

            this.props.updateOrderVideoTitlesCall(this.props.orderId, { videoTitleData: data, vt_agency_type_id: this.state.vt_agency_type_id }).then(
                response => {
                    if ('close' in this.props) {
                        this.props.close()
                    }
                }
            );
        } else {
            this.titlesValidator.showMessages();
            this.forceUpdate();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.submit !== prevProps.submit && (this.props.submit === true)) {
            this.videoTitleSubmitHandler();
        }
        if (this.props.titles !== prevProps.titles) {
            this.setState({
                ...this.props.titles
            })
        }
        if ((this.props.getState !== prevProps.getState) && this.props.getState === true) {
            this.props.getStateMethod(this.state);
        }
    }

    render() {
        const { printOrderOptions, agency_type_id } = this.props;
        const { vt_agency_type_id } = this.state;
        return (
            <div className='clearfix' >
                {
                    !('hideDropDown' in this.props) &&
                    <React.Fragment>
                        <div className="col-md-12" id="agency_type_id">
                            <div className='form-group'>
                                <div className="clearfix">
                                    <div className="col-md-12">
                                        {
                                            agency_type_id.length > 0 &&
                                            <Select
                                                value={
                                                    vt_agency_type_id ? {
                                                        value: returnColumnValueWithColumnMatch(agency_type_id, 'value', [vt_agency_type_id], 'value'),
                                                        label: returnColumnValueWithColumnMatch(agency_type_id, 'label', [vt_agency_type_id], 'value')
                                                    } : null
                                                }
                                                options={agency_type_id}
                                                onChange={this.handleChangeAgencyDropDown}
                                                getOptionLabel={(option) => option.label}
                                                getOptionValue={(option) => option.value}
                                                placeholder="Select Agency Type"
                                                isMulti={false}
                                                isSearchable={true}
                                                name='agency_type_id'
                                            />
                                        }
                                    </div>
                                    <span></span>
                                </div>
                            </div>
                        </div>
                        <p>( <strong className='text-danger' >RED=</strong> titles have changed from default titles )</p>
                    </React.Fragment>
                }
                <div className='p-10' >
                    <div className='row'>
                        <div className='col-md-6'>
                            <label className={Number(this.state.ot1.pivot.is_changed) ? 'text-danger' : null} >Opening Title</label>
                            <textarea onChange={this.videoTitleChangeHandler} name='ot1' className={Number(this.state.ot1.pivot.is_changed) ? 'form-control text-danger' : 'form-control'} rows='10' value={this.state.ot1.pivot.value} disabled={printOrderOptions ? true : false} />
                            {this.titlesValidator.message('Opening Title', this.state.ot1.pivot.value, 'required')}
                        </div>
                        <div className='col-md-6' >
                            <label className={Number(this.state.ct1.pivot.is_changed) ? 'text-danger' : null} >Closing Title 1</label>
                            <textarea onChange={this.videoTitleChangeHandler} name='ct1' className={Number(this.state.ct1.pivot.is_changed) ? 'form-control text-danger' : 'form-control'} rows='10' value={this.state.ct1.pivot.value} disabled={printOrderOptions ? true : false} />
                            {this.titlesValidator.message('Closing Title 1', this.state.ct1.pivot.value, 'required')}
                        </div>
                    </div>
                </div>
                <div className='p-10' >
                    <div className='row' >
                        <div className='col-md-6' >
                            <label className={Number(this.state.ct2.pivot.is_changed) ? 'text-danger' : null} >Closing Title 2</label>
                            <textarea onChange={this.videoTitleChangeHandler} name='ct2' className={Number(this.state.ct2.pivot.is_changed) ? 'form-control text-danger' : 'form-control'} rows='10' value={this.state.ct2.pivot.value} disabled={printOrderOptions ? true : false} />
                            {this.titlesValidator.message('Closing Title 2', this.state.ct2.pivot.value, 'required')}
                        </div>
                        <div className='col-md-6' >
                            <label className={Number(this.state.ct3.pivot.is_changed) ? 'text-danger' : null} >Closing Title 3</label>
                            <textarea onChange={this.videoTitleChangeHandler} name='ct3' className={Number(this.state.ct3.pivot.is_changed) ? 'form-control text-danger' : 'form-control'} rows='10' value={this.state.ct3.pivot.value} disabled={printOrderOptions ? true : false} />
                            {this.titlesValidator.message('Closing Title 3', this.state.ct3.pivot.value, 'required')}
                        </div>
                    </div>
                </div>
                {
                    !('submit' in this.props) ?
                        <button onClick={this.videoTitleSubmitHandler} className="backButton pt-sm no_radius pb-sm primary btn btn-md btn-info ml-sm mt-sm pull-right" >Save</button>
                        : null
                }
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return ({
        updateOrderVideoTitlesCall: (orderId, Data) => { return dispatch(updateOrderVideoTitles(orderId, Data)) },
        getAgencyTypeCall: () => { dispatch(getAgencyType()) },
        videoTitlesByAgencyTypeCall: (agency_type_id, orderId) => { return dispatch(videoTitlesByAgencyType(agency_type_id, orderId)) },

    });
};
function mapStateToProps(state) {
    const { loadingSettings, agency_type_id } = state.settingsReducer;
    return {
        loadingSettings, agency_type_id,
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(OrderTitle);