import React, { Component } from "react";
import { connect } from 'react-redux';
import {
    getTtkPendingOrders,
    submitPendingOrders
} from "redux/actions/order-actions";
import ReactValidator from "simple-react-validator";
import DatePicker from "react-datepicker";
import {
    Grid,
    Row,
    Col,
} from "react-bootstrap";
import { Card } from "components/Card/Card.jsx";
import 'react-table/react-table.css';
import { Link } from 'react-router-dom';
import { appendValues, dateFormatMy } from 'helper/helperFunctions';
import Pagination from "../../ThemeComponents/Pagination";

const moment = window.moment;

class TtkPendingOrdersPage extends Component {
    constructor(props) {
        super(props);
        this.dateValidator = new ReactValidator();
        this.state = {
            fromDate: '',
            toDate: '',
            pageSize: 10,
            page: 0,
            sorted: '',
            selected: {},
            selectAll: 0,
            newdata: [],
        };
        this.toggleRow = this.toggleRow.bind(this);
        this.table = React.createRef();

    }

    componentDidUpdate(prevProps, prevState) {
        console.log(this.props.ttkPendingOrdersData);
        if (prevProps.ttkPendingOrdersData !== this.props.ttkPendingOrdersData) {
            const temp = appendValues(this.props.ttkPendingOrdersData, [{ column: 'checklist', value: false }]);
            this.setState({
                newdata: temp
            })
            console.log('updated');
        }
    }

    toggleRow(id) {
        let newdataTemp = [...this.state.newdata];
        newdataTemp[id]['checklist'] = !newdataTemp[id]['checklist']
        this.setState({
            newdata: newdataTemp
        });

    }

    paginationCall = (data) => {
        const { fromDate, toDate } = this.state;
        return this.props.getOrdersCall(1, fromDate, toDate, data.page, data.pageSize, data.sort);
    }


    render() {
        const { /*newdata, toDate, fromDate*/ } = this.state;
        const { /*ttkPendingOrderspages, loadingOrder*/ } = this.props;
        const columns = [
            {
                Header: "Pending Orders",
                headerClassName: 'headerPagination',
                columns: [

                    {
                        Header: "Barcode",
                        accessor: "barcode",
                        width: 200,
                        Cell: ({ value, original }) => {
                            return <Link target="_blank" to={'/Admin/' + original.id + '/order-edit'} className="blue_link">{value}</Link>
                        },
                        className: "action-center",
                        sortable: false,
                    },
                    {
                        Header: "Agency",
                        accessor: "agency",
                        Cell: ({ value }) => (
                            <div>
                                {value.name}
                            </div>
                        ),
                        className: "action-center",
                        sortable: true,
                    },
                    {
                        Header: "Group Name",
                        accessor: "group_name",
                        className: "action-center",
                        sortable: false,
                    },
                    {
                        Header: "Departure Date",
                        accessor: "departure_date",
                        Cell: ({ value }) => (
                            <div>
                                {dateFormatMy(value)}
                            </div>
                        ),
                        className: "action-center",
                        sortable: true,
                    },
                    {
                        Header: "Travelers",
                        accessor: "numberOfVideos",
                        className: "action-center",
                        sortable: false,
                    },
                ]
            }
        ];
        return (
            <div className="content">


                <Grid fluid>
                    <Row>
                        <Col md={12}>
                            <Card
                                ctTableResponsive
                                content={
                                    <div>
                                        <Grid fluid>
                                            <div className="row">
                                                <Col md={12}>
                                                    <div className="secHeading">TTK Approve Orders</div>
                                                </Col>

                                                <Pagination
                                                    ref={(p) => this.pagination = p}
                                                    showPagination={false}
                                                    noDataText='No order found'
                                                    columns={columns}
                                                    pageSize={50}
                                                    getDataCall={this.paginationCall}
                                                    filterView={false}
                                                    defaultSorted={[
                                                        { id: 'departure_date', desc: false }
                                                    ]}
                                                    // downloadData={true}
                                                    // downloadFileName={'Orders'}
                                                    // lowerContent = {null}
                                                    // filterPlaceHolder = {'Orders'}
                                                    getRowProps={this.getRowProps}
                                                // showAllToggle={true}
                                                />
                                            </div>
                                        </Grid>
                                    </div>
                                } />
                        </Col>
                    </Row>
                </Grid>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return ({
        getOrdersCall: (value, fromDate, toDate, page, pageSize, sorted) => { return dispatch(getTtkPendingOrders(value, fromDate, toDate, page, pageSize, sorted)) },
        submitPendingOrdersCall: (ordersData) => { return dispatch(submitPendingOrders(ordersData)) },

    });
};

function mapStateToProps(state) {
    const { ttkPendingOrdersData, ttkPendingOrderspages, ttkPendingOrderscurrent_page, ttkPendingOrderstotal_orders, loadingOrder } = state.ordersReducer;
    return {
        ttkPendingOrdersData, ttkPendingOrderspages, ttkPendingOrderscurrent_page, ttkPendingOrderstotal_orders, loadingOrder
    };
}


const TtkPendingOrders = connect(mapStateToProps, mapDispatchToProps)(TtkPendingOrdersPage);
export default TtkPendingOrders;
