import { testimonialService } from "../../services/testimonials";
import { frontEndUserConstants } from "../constant/front-end-constant";
import { alertActions } from "./alert-actions";

export function getTestimonials(brand_id, pageSize, sort, filter) {
    return dispatch => {
        testimonialService.getTestimonials(brand_id, pageSize, sort, filter).then(
            response => {
                dispatch(success(response));
            },
            error => {
                dispatch(failure(error))
            }
        )
    }
    function success(response) { return { type: frontEndUserConstants.GET_ALL_TESTIMONIALS_SUCCESS, payload: response } }
    function failure(error) { return { type: frontEndUserConstants.GET_ALL_TESTIMONIALS_FAILURE, payload: error } }
}

export function storeTestimonial(data) {
    return dispatch => {
        return testimonialService.storeTestimonial(data).then(
            response => {
                dispatch(alertActions.success(response.message));
                return response
            },
            error => {
                dispatch(failure(error))
                return Promise.reject(error)
            }
        )
    }
    // function success(response) { return { type: frontEndUserConstants.STORE_TESTIMONAIL_SUCCESS, payload: response } }
    function failure(error) { return { type: frontEndUserConstants.STORE_TESTIMONAIL_FAILURE, payload: error } }
}

export function deleteTestimonial(id) {
    return dispatch => {
        testimonialService.deleteTestimonial(id).then(
            response => {
                dispatch(alertActions.success(response.message));
            },
            error => {
                dispatch(alertActions.error(error));
            }
        )
    }
}