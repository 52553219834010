import React, {Children} from "react";
import {connect} from 'react-redux';
import {
    dateContainInObject,
    focusInput,
    dateContainInObjectStart,
    closeModal,
    dateTimeWithAMPM,
    hasRole,
    returnLabelWithValue,
    returnTimeZoneNames,
    returnOriginalTZ
} from 'helper/helperFunctions';
import { Link } from 'react-router-dom';
import {Calendar, momentLocalizer} from 'react-big-calendar';
import moment from 'moment'
import 'react-big-calendar/lib/css/react-big-calendar.css';
import SimpleReactValidator from 'simple-react-validator';
import {Button} from "react-bootstrap";
import {getCallSchedulers, addEventtoScheduler} from 'redux/actions/call-scheduler-actions';
import {objectsConstants as OC} from 'redux/constant/objects-constant';
import Select from 'react-select';
import LoadingOverlay from "react-loading-overlay";
import 'react-image-lightbox/style.css';
// import moment_timezone from 'moment-timezone';
// moment_timezone.tz.setDefault('America/New_York');
// var june = moment;
// var time = june.tz('America/New_York');
// console.log("time: " , moment_timezone.tz.setDefault('America/New_York'));
const queryString = require('query-string');
const localizer = momentLocalizer(moment);
var Modal = require('react-bootstrap-modal');
const today = new Date();

const style = {
    fontSize: '16px'
}
const border = {
    padding: '10px',
    border: '1px solid #ccc'
}
class Scheduler extends React.Component {
    constructor(props) {
        super(props);
        this.validatorScheduler = new SimpleReactValidator({autoForceUpdate: this, locale: 'en'});
        const parsed = queryString.parse(window.location.search);
        const date = moment();

        const estDate = new Date();
        var offset = -540; //Timezone offset for EST in minutes.
        const minTime = new Date(estDate.getTime() + offset*60*1000);
        minTime.setHours(9, 0, 0);

        const estMaxDate = new Date();
        // console.log("max....", estMaxDate);
        var offset = -540; //Timezone offset for EST in minutes.
        const maxTime = new Date(estMaxDate.getTime() + offset*60*1000);
        // console.log("maxTime before....", maxTime);

        maxTime.setHours(17, 30, 0);
        // console.log("maxTime after....", maxTime);

        // const minTime = new Date();
        // minTime.setHours(7, 0, 0);
        // const maxTime = new Date();
        // maxTime.setHours(18, 30, 0);

        this.state = {
            addAppointmentModal: false,
            showAppointmentModal: false,
            events: [],
            newEvent: OC.NEWEVENT,
            showEvent: {},
            slotInfo: {},
            minTime: minTime,
            maxTime: maxTime,
            BrowserTimeZone: '',
            canView: parsed.admin ? parsed.admin : false,
            // canView: hasRole('admin') ? true : false,
            barcode: parsed.barcode ? parsed.barcode : false,
            client: "Group Memories Client",
            userRandom: moment().valueOf(),
            selectedTimeZone: 2,
            selectedTimeZoneName: 'EST',
            is_changed: false,
            timeZones: '',
            requestEndTime: '',
            requestStartTime: '',
            requestEndTimeWithoutFormat: '',
            requestStartTimeWithoutFormat: '',
            start: '',
            end: '',
            client_time_zone_name: '',
            is_selected: parsed.admin ? true : false
        };
        this.getCallSchedulersCall();
    }

    componentDidMount() {
        // const timeZone = Intl.supportedValuesOf('timeZone');;
        const timeZone = moment.tz.names();
        let tempObj = {};
        let tempObjTime = [];
        let index = 1;
        let requiredTimeZone = [ 'EST', 'MST','CST6CDT', 'PST8PDT'];
        timeZone.map(zone => {
            if(requiredTimeZone.includes(zone)) {
                tempObj = {
                    label: returnTimeZoneNames(zone),
                    value: index
                }
                index++;
                tempObjTime.push(tempObj);
            }
        })
      this.setState({
        timeZones: tempObjTime
        }, () => {
            let temp = moment.tz.guess();
            //   let tempbrowser= '';
            //   Object.keys(this.state.timeZones).map((item) => {
            //    if ((this.state.timeZones[item].label).match(temp)) {
            //         tempbrowser = this.state.timeZones[item].value;
            //    }
            //   });
            this.setState({
                //   BrowserTimeZone: tempbrowser,
                client_time_zone_name: temp
            });
        })
    }

    getCallSchedulersCall = () => {
        this.props.getCallSchedulers().then(response => {
            var data = response.data;
            var len = data.length - 1;
            for (var i in data) {
                data[i]['start'] = new Date(data[i]['start']);
                data[i]['end'] = new Date(data[i]['end']);
                if (parseInt(i, 10) === len) {
                    this.setState({events: data})
                }
            }
        });
    }
    slotSelect = (slotInfo) => {
        console.log("slotInfoo", slotInfo);
        var startDate = moment(slotInfo.start).format("YYYY-MM-DD HH:mm:ss");
        var endDate = moment(slotInfo.end).format("YYYY-MM-DD HH:mm:ss");
        var EST_Time_Start = moment.tz(startDate, returnOriginalTZ(this.state.selectedTimeZoneName));
        var EST_Time_End = moment.tz(endDate, returnOriginalTZ(this.state.selectedTimeZoneName));
        // EST
        const convertedSelectedTimzoneToEstStart = EST_Time_Start.clone().tz("America/New_York").format("YYYY-MM-DD HH:mm:ss");
        const convertedSelectedTimzoneToEstEnd = EST_Time_End.clone().tz("America/New_York").format("YYYY-MM-DD HH:mm:ss");

        // Client's Selected Time without Format
        const convertedSelectedTimzoneStartWithoutFormat = moment(slotInfo.start).format();
        const convertedSelectedTimzoneEndWithoutFormat = moment(slotInfo.end).format();
        // Client's Selected Time with Format
        const convertedSelectedTimzoneStartWithFormat = moment(slotInfo.start).format("MMM DD YYYY h:mm A");
        const convertedSelectedTimzoneEndWithFormat = moment(slotInfo.end).format("MMM DD YYYY h:mm A");

        var startHour = moment(slotInfo.start).hour();
        var weekend_day = moment(slotInfo.start).day();
        var endHour = moment(slotInfo.end).hour();
        const {minTime, maxTime} = this.state;
        var minTimeTemp = moment(minTime).hour();
        var maxTimeTemp = moment(maxTime).hour();
        if ((startHour >= minTimeTemp && endHour <= maxTimeTemp)) {
            this.setState({
                slotInfo: slotInfo,
                newEvent: {...this.state.newEvent, start: convertedSelectedTimzoneToEstStart, end: convertedSelectedTimzoneToEstEnd},
                requestEndTimeWithoutFormat: convertedSelectedTimzoneEndWithoutFormat,
                requestStartTimeWithoutFormat: convertedSelectedTimzoneStartWithoutFormat,
                requestStartTime: convertedSelectedTimzoneStartWithFormat,
                requestEndTime: convertedSelectedTimzoneEndWithFormat,
                start: startDate,
                end: endDate
            }, () => {
                // This one also addressed "On the scheduler, it is still allowing people to pick a time or date that has passed and Saturday and Sunday are still able to be selected."
                var estcurrentHour = new Date();
                estcurrentHour.setTime(estcurrentHour.getTime()+estcurrentHour.getTimezoneOffset()*60*1000);
                var offset = -300; //Timezone offset for EST in minutes.
                var currentHour = moment(new Date(estcurrentHour.getTime() + offset*60*1000));
                if ((slotInfo.start >= this.state.minTime && slotInfo.start > currentHour) && (weekend_day >= 1 && weekend_day <= 5)) {
                    this.setState({addAppointmentModal: true});
                }
            });
        }
    }

    handleSchedulerInput = (e) => {
        const {name, value} = e.target;
        const newEventState = {...this.state.newEvent};
        newEventState[name] = value;
        this.setState({newEvent: newEventState});
    }

    handleAgencySubmit = (e) => {
        e.preventDefault();
        if (this.validatorScheduler.allValid()) {
            if (this.state.barcode) {
                console.log("if statement")
                const {newEvent, userRandom, barcode, client, selectedTimeZone, BrowserTimeZone, is_changed, requestStartTimeWithoutFormat, requestEndTimeWithoutFormat, selectedTimeZoneName, client_time_zone_name} = this.state;
                this.props.addEventtoScheduler({...newEvent, userRandom: userRandom, barcode: barcode, client: client, request_time_zone: selectedTimeZone, request_time_zone_name: selectedTimeZoneName, client_time_zone: BrowserTimeZone, client_time_zone_name: client_time_zone_name, is_time_zone_changed: is_changed, client_time_start: requestStartTimeWithoutFormat, client_time_end: requestEndTimeWithoutFormat}).then(response => {
                    this.setState({addAppointmentModal: false, newEvent: OC.NEWEVENT, selectedTimeZone: 2, selectedTimeZoneName: 'EST', is_changed: false}, () => {
                        this.getCallSchedulersCall();
                    })
                });
            } else {
                console.log("else statement")
                const {newEvent, userRandom, barcode, client, selectedTimeZone, BrowserTimeZone, is_changed, requestStartTimeWithoutFormat, requestEndTimeWithoutFormat, selectedTimeZoneName, client_time_zone_name} = this.state;
                this.props.addEventtoScheduler({...newEvent, userRandom: userRandom, client: client, request_time_zone: selectedTimeZone, request_time_zone_name: selectedTimeZoneName, client_time_zone: BrowserTimeZone, client_time_zone_name: client_time_zone_name, is_time_zone_changed: is_changed, client_time_end: requestEndTimeWithoutFormat, client_time_start: requestStartTimeWithoutFormat}).then(response => {
                    this.setState({addAppointmentModal: false, newEvent: OC.NEWEVENT, selectedTimeZone: 2, selectedTimeZoneName: 'EST', is_changed: false}, () => {
                        this.getCallSchedulersCall();
                    })
                });
            }
        } else {
            // focusInput(this.validatorScheduler.getErrorMessages());
            this.validatorScheduler.showMessages();
            this.forceUpdate();
        }
    }

    eventSelect = (event) => {
        console.log("eventSelect", event);
        var startHour = moment(event.start).hour();
        var endHour = moment(event.end).hour();
        const {minTime, maxTime, canView} = this.state;
        var minTimeTemp = moment(minTime).hour();
        var maxTimeTemp = moment(maxTime).hour();
        if ((startHour >= minTimeTemp && endHour <= maxTimeTemp) && canView) {
            this.setState({showEvent: event, showAppointmentModal: true});
        }

    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.showAppointmentModal && !this.state.showAppointmentModal) {
            this.setState({showEvent: {}});
        }
        // if (prevState.addAppointmentModal && !this.state.addAppointmentModal) {
        //     this.setState({selectedTimeZone: 2, is_changed: false});
        // }
        if (prevState.is_changed !== this.state.is_changed) {
            this.setState({is_changed: this.state.is_changed});
        }
        // if (prevState.newEvent  !== this.state.newEvent) {
        //     this.setState({newEvent: this.state.newEvent});
        // }
        // if (prevState.requestEndTime && !this.state.requestEndTime) {
        //     this.setState({requestEndTime: this.state.requestEndTime});
        // }
        // console.log("jjjjjjj", this.state.newEvent)
        // if (this.state.barcode && this.state.newEvent.length > 0 && !this.state.newEvent.includes('barcode')) {
        //     console.log("dddd", this.state.newEvent.includes('barcode'))
        //     const newEventState = {...this.state.newEvent};
        //     newEventState['barcode'] = this.state.barcode;
        //     this.setState({newEvent: newEventState}, () => { console.log("state updated" ,this.state.newEvent)});
        // }
    }

    handleTimeZoneChange = (selectedOptionType, info) => {
        // const {start, end} = this.state;
        if (info['action'] === 'select-option') {
            // var EST_Time_Start = moment.tz(start, "America/New_York");
            // var EST_Time_End = moment.tz(end, "America/New_York");
            // EST_Time_Start.clone().tz(selectedOptionType.label).format("MMM D YYYY h:mm A");
            // EST_Time_End.clone().tz(selectedOptionType.label).format("MMM D YYYY h:mm A");
            var hours = this.changeHours(selectedOptionType.label);
            // const convertedSelectedTimzoneStartWithoutFormat =EST_Time_Start.clone().tz(selectedOptionType.label).format();
            // const convertedSelectedTimzoneEndWithoutFormat = EST_Time_End.clone().tz(selectedOptionType.label).format();
            this.setState({
                ...this.state,
                selectedTimeZone: selectedOptionType.value,
                selectedTimeZoneName: selectedOptionType.label,
                // requestEndTimeWithoutFormat: convertedSelectedTimzoneEndWithoutFormat,
                // requestStartTimeWithoutFormat: convertedSelectedTimzoneStartWithoutFormat,
                is_changed: true,
                minTime: hours[0],
                maxTime: hours[1]
                // is_selected: true
            });
            if (selectedOptionType.value !== 2) {
                this.setState({
                    is_changed: true
                });

            } else if (selectedOptionType.value === 2) {
                this.setState({is_changed: false});
            }
        }
        if (info['action'] === 'clear') {
            this.setState({
                ...this.state,
                selectedTimeZone: null
            });
        }
    }
    changeHours = (zone) => {
        let minHours = 0;
        let maxHours = 0;
        // if (zone === 'CST (Central Standard Time)') {
        if (zone.includes('CST')) {
            minHours = 8;
            maxHours = 16;
        } else if (zone.includes('MST')) {
            minHours = 7;
            maxHours = 15;
        } else if (zone.includes('PST')) {
            minHours = 6;
            maxHours = 14;
        } else {
            minHours = 9;
            maxHours = 17;
        }
        // return minHours;
        const estDate = new Date();
        estDate.setTime(estDate.getTime()+estDate.getTimezoneOffset()*60*1000);
        var offset = -300; //Timezone offset for EST from UTC in minutes.
        const minTime = new Date(estDate.getTime() + offset*60*1000);
        minTime.setHours(minHours, 0, 0);

        const estMaxDate = new Date();
        estMaxDate.setTime(estMaxDate.getTime()+estMaxDate.getTimezoneOffset()*60*1000);
        // console.log("max....", estMaxDate);
        var offset = -300; //Timezone offset for EST from UTC in minutes.
        const maxTime = new Date(estMaxDate.getTime() + offset*60*1000);
        // console.log("maxTime before....", maxTime);

        maxTime.setHours(maxHours, 30, 0);
        return [minTime, maxTime];
    }

    calculateTimeSlots = () => {
    const currentTime = moment().tz(this.state.selectedTimeZone);
    const timeSlots = [];
    for (let i = 0; i < 24; i++) {
        timeSlots.push(currentTime.clone().add(i, 'hours').format('hh:mm A'));
    }
    return timeSlots;
    }

    TzSelect = () => {
        return (
            <React.Fragment>
                <div className="col-md-12 selectTimeZone">
                    <div className='form-group' style={style}>
                        <label className="mb-sm" htmlFor="glemail">Please Select Your Time Zone</label>
                            <Select
                                value={returnLabelWithValue(this.state.selectedTimeZone, this.state.timeZones)}
                                onChange={this.handleTimeZoneChange}
                                options={this.state.timeZones}
                                isSearchable={true}
                                isMulti={false}
                                // isDisabled={true}
                                name='time_zone'
                                placeholder={'Select Your Time Zone'}
                                isClearable={true}
                            />
                    </div>
                <button className="pl-xlg pb-sm pt-sm pr-xlg primary backButton "onClick={this.cancelButton}>OK</button>
                </div>
            </React.Fragment>
        )
    }
    cancelButton = () => {
        this.setState({
            is_selected: true
        })
    }
    loader = () => {
        const button = <button>Cancel</button>;
        return (
            <LoadingOverlay
                active={!this.state.is_selected}
                className="overlayModify"
                // spinner={<PulseLoader />}
                extraHtml={
                    <div>
                        <button type="button" className="">Canlce</button>
                    </div>}
                text={this.TzSelect()}
                styles={{
                    spinner: (base) => ({
                        ...base,
                        size: '600px',
                        '& svg circle': {
                            stroke: 'rgba(192,192,192,0.8)'
                        }
                    }),
                    overlay: (base) => ({
                        ...base,
                        background: 'rgba(0,0,0,0.85)'
                    }),
                }}
            ></LoadingOverlay>
        )
    }


    render() {
        this.validatorScheduler.purgeFields();
        const {
            addAppointmentModal,
            events,
            newEvent,
            showAppointmentModal,
            showEvent,
            minTime,
            maxTime,
            canView,
            userRandom, requestEndTime, requestStartTime,
            is_changed,
            is_selected
        } = this.state;

        return (
            <React.Fragment>
                {
                    (!is_selected) &&
                    this.loader()
                }
                <Calendar
                    selectable
                    localizer={localizer}
                    className="mb-md"
                    events={events}
                    startAccessor="start"
                    endAccessor="end"
                    dayLayoutAlgorithm="no-overlap"
                    onSelectSlot={(slotInfo) => this.slotSelect(slotInfo)}
                    onSelectEvent={canView ?  (event) => {
                        this.eventSelect(event)
                    }: (slotInfo) => this.slotSelect(slotInfo)}
                    views={['week']}
                    defaultView='week'
                    components={{
                        timeSlotWrapper: ({children, value}) =>
                            React.cloneElement(
                                Children.only(children), {
                                    style: {
                                        ...children.style,
                                        backgroundColor: dateContainInObjectStart(events, value) ? '#ccc' : '',
                                    },

                                }
                            ),
                        event: ({event}) => {
                            return (
                                <React.Fragment>
                                    {
                                        (canView || event.userRandom == userRandom)
                                            ?
                                            <span style={{fontSize: '12px', fontWeight: 'bold'}}>
                                                {`${event.name}`}
                                            </span>
                                            :
                                            <span></span>
                                    }
                                </React.Fragment>
                            )
                        }
                    }}
                    min={minTime}
                    max={maxTime}
                />
                <Modal backdrop={'static'} show={showAppointmentModal}
                       onHide={closeModal.bind(this, "showAppointmentModal")} aria-labelledby="ModalHeader">
                    <Modal.Header closeButton>
                        <Modal.Title id='ModalHeader' className="headerTitle twenty22">{showEvent.client ? showEvent.client : 'Schedule'}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">

                            <div className="col-md-6">

                                <div className='form-group'>
                                    <div className="clearfix">
                                        <h5 style={{fontWeight: 'bold', color: '#fd5656'}}>EST Time Zone</h5>
                                        <div className="col-md-12 lbl_container">
                                            <label htmlFor="name">Start Time</label>
                                        </div>

                                        <div style={style} className="">
                                            {
                                                dateTimeWithAMPM(showEvent.start)
                                            }
                                        </div>
                                        <span></span>
                                    </div>
                                </div>

                                <div className='form-group'>
                                    <div className="clearfix">
                                        <span></span>
                                        <div className="col-md-12 lbl_container">
                                            <label htmlFor="name">End Time</label>
                                        </div>

                                        <div style={style} className="">
                                            {
                                                dateTimeWithAMPM(showEvent.end)
                                            }
                                        </div>
                                        <span></span>
                                    </div>
                                </div>
                            </div>
                            {/* ...........Time zone .............*/}
                            <div className="col-md-6" >
                                <div style={border}>
                                    <div className='form-group' style={style}>
                                        <label htmlFor="glemail">Client's Selected Time Zone</label>
                                            <Select
                                                value={returnLabelWithValue(showEvent.request_time_zone, this.state.timeZones)}
                                                onChange={this.handleTimeZoneChange}
                                                options={this.state.timeZones}
                                                isSearchable={true}
                                                isMulti={false}
                                                isDisabled={true}
                                                name='time_zone'
                                                placeholder={'Select Your Time Zone'}
                                                isClearable={true}
                                            />
                                    </div>
                                    {/* <div className='form-group' style={style}>
                                        <label htmlFor="glemail">Client Time Zone</label>
                                            <Select
                                                value={returnLabelWithValue(showEvent.client_time_zone, this.state.timeZones)}
                                                onChange={this.handleTimeZoneChange}
                                                options={this.state.timeZones}
                                                isSearchable={true}
                                                isMulti={false}
                                                isDisabled={true}
                                                name='time_zone'
                                                placeholder={'Select Time Zone'}
                                                isClearable={true}
                                            />
                                    </div> */}

                                    {
                                        Number(showEvent.is_time_zone_changed) === 1 &&
                                        <React.Fragment>
                                            <div className='form-group'>
                                                <div className="clearfix">
                                                    <span></span>
                                                    <div className="col-md-12 lbl_container">
                                                        <label htmlFor="name">Start Time</label>
                                                    </div>
                                                    <div className="">
                                                        {
                                                            // <span style={style}>{showEvent.start}</span>
                                                            <span style={style}>{dateTimeWithAMPM(showEvent.client_time_start)}</span>
                                                        }
                                                    </div>
                                                    <span></span>
                                                </div>
                                            </div>

                                            <div className='form-group'>
                                                <div className="clearfix">
                                                    <span></span>
                                                    <div className="col-md-12 lbl_container">
                                                        <label htmlFor="name">End Time</label>
                                                    </div>
                                                    <div className="">
                                                        {
                                                            // <span style={style}>{showEvent.end}</span>
                                                            <span style={style}>{dateTimeWithAMPM(showEvent.client_time_end)}</span>
                                                        }
                                                    </div>
                                                    <span></span>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    }
                                </div>
                            </div>
                            {
                                showEvent.barcode &&
                                <div className='form-group' id="desc">
                                    <div className="clearfix">
                                        <span></span>
                                        <div className="col-md-12 lbl_container">
                                            <label htmlFor="name">barcode</label>
                                        </div>

                                        <div style={style} className="col-md-12">
                                            <Link to={'/Admin/' + showEvent.order_id + '/order-edit'} className="blue_link barcode" target="_blank">
                                                 {showEvent.barcode}
                                            </Link>
                                        </div>
                                        <span></span>
                                    </div>
                                </div>
                            }
                            <div className='form-group' id="desc">
                                <div className="clearfix">
                                    <span></span>
                                    <div className="col-md-12 lbl_container">
                                        <label htmlFor="name">name</label>
                                    </div>

                                    <div style={style} className="col-md-12">
                                    {
                                        showEvent.name
                                    }
                                    </div>
                                    <span></span>
                                </div>
                            </div>
                            <div className='form-group' id="desc">
                                <div className="clearfix">
                                    <span></span>
                                    <div className="col-md-12 lbl_container">
                                        <label htmlFor="name">email</label>
                                    </div>

                                    <div style={style} className="col-md-12">
                                        {showEvent.email}
                                    </div>
                                    <span></span>
                                </div>
                            </div>
                            <div className='form-group' id="desc">
                                <div className="clearfix">
                                    <span></span>
                                    <div className="col-md-12 lbl_container">
                                        <label htmlFor="name">phone</label>
                                    </div>

                                    <div style={style} className="col-md-12">
                                        {showEvent.phone}
                                    </div>
                                    <span></span>
                                </div>
                            </div>


                        </div>
                    </Modal.Body>
                </Modal>
                <Modal backdrop={'static'} show={addAppointmentModal}
                       onHide={closeModal.bind(this, "addAppointmentModal")} aria-labelledby="ModalHeader">
                    <Modal.Header closeButton>
                        <Modal.Title id='ModalHeader' className="headerTitle twenty22">Group Memories Client</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <form>
                                <div className="col-md-6">
                                    <div className='form-group'>
                                        <div className="clearfix">
                                            {/* <h5 style={{fontWeight: 'bold', color: '#fd5656'}}>EST Time Zone</h5> */}
                                            <div className="col-md-12 lbl_container">
                                                <label htmlFor="name">Start Time</label>
                                            </div>

                                            <div className="">
                                                {
                                                    // <span style={style}>{dateTimeWithAMPM(newEvent.start)}</span>
                                                    <span style={style}>{requestStartTime}</span>
                                                }
                                            </div>
                                            <span></span>
                                        </div>

                                    </div>

                                    <div className='form-group'>
                                        <div className="clearfix">
                                            <span></span>
                                            <div className="col-md-12 lbl_container">
                                                <label htmlFor="name">End Time</label>
                                            </div>

                                            <div className="">
                                                {
                                                    // <span style={style}>{dateTimeWithAMPM(newEvent.end)}</span>
                                                    <span style={style}>{requestEndTime}</span>
                                                }
                                            </div>
                                            <span></span>
                                        </div>
                                    </div>
                                </div>
                                    {/* ...........Time zone .............*/}
                                {/* <div className="col-md-6" >
                                    <div style={border}>
                                        <div className='form-group' style={style}>
                                            <label htmlFor="glemail">Select Your Time Zone</label>
                                                <Select
                                                    value={returnLabelWithValue(this.state.selectedTimeZone, this.state.timeZones)}
                                                    onChange={this.handleTimeZoneChange}
                                                    options={this.state.timeZones}
                                                    isSearchable={true}
                                                    isMulti={false}
                                                    name='time_zone'
                                                    placeholder={'Select Your Time Zone'}
                                                    isClearable={true}
                                                />
                                        </div>
                                        {
                                            is_changed &&
                                            <React.Fragment>
                                                <div className='form-group'>
                                                    <div className="clearfix">
                                                        <span></span>
                                                        <div className="col-md-12 lbl_container">
                                                            <label htmlFor="name">Start Time</label>
                                                        </div>
                                                        <div className="">
                                                            {
                                                                <span style={style}>{requestStartTime}</span>
                                                                // <span style={style}>{dateTimeWithAMPM(newEvent.start)}</span>
                                                            }
                                                        </div>
                                                        <span></span>
                                                    </div>
                                                </div>

                                                <div className='form-group'>
                                                    <div className="clearfix">
                                                        <span></span>
                                                        <div className="col-md-12 lbl_container">
                                                            <label htmlFor="name">End Time</label>
                                                        </div>
                                                        <div className="">
                                                            {
                                                                <span style={style}>{requestEndTime}</span>
                                                                // <span style={style}>{dateTimeWithAMPM(newEvent.end)}</span>
                                                            }
                                                        </div>
                                                        <span></span>
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        }
                                    </div>
                                </div> */}

                                <div className='form-group'>
                                    <div className="clearfix" id="name">
                                        <span></span>
                                        <div className="col-md-12 lbl_container">
                                            <label htmlFor="name">Name</label>
                                        </div>

                                        <div className="col-md-12">
                                            <input autoFocus type="text" name="name" className="form-control"
                                                   value={newEvent.name} onChange={this.handleSchedulerInput}/>
                                            {
                                                this.validatorScheduler.message('name', newEvent.name, 'required')
                                            }
                                        </div>
                                        <span></span>
                                    </div>
                                </div>

                                <div className='form-group' id="email">
                                    <div className="clearfix">
                                        <span></span>
                                        <div className="col-md-12 lbl_container">
                                            <label htmlFor="email">Email</label>
                                        </div>

                                        <div className="col-md-12">
                                            <input autoFocus type="text" name="email" className="form-control"
                                                   value={newEvent.email} onChange={this.handleSchedulerInput}/>
                                            {
                                                this.validatorScheduler.message('email', newEvent.email, 'required|email')
                                            }
                                        </div>
                                        <span></span>
                                    </div>
                                </div>
                                <div className='form-group' id="title">
                                    <div className="clearfix">
                                        <span></span>
                                        <div className="col-md-12 lbl_container">
                                            <label htmlFor="name">Phone</label>
                                        </div>

                                        <div className="col-md-12">
                                            <input autoFocus type="text" name="phone" className="form-control"
                                                   value={newEvent.phone} onChange={this.handleSchedulerInput}/>
                                            {
                                                this.validatorScheduler.message('phone', newEvent.phone, 'required')
                                            }
                                        </div>
                                        <span></span>
                                    </div>
                                </div>
                                <div className="text-center">
                                    <Button type='button' onClick={this.handleAgencySubmit} form="agency-form"
                                            className="backButton pt-sm no_radius pb-sm success btn btn-lg btn-info ml-sm mt-sm">Save</Button>
                                </div>
                            </form>
                        </div>
                    </Modal.Body>
                </Modal>
            </React.Fragment>
        );
    }
}


const mapDispatchToProps = dispatch => {
    return ({
        getCallSchedulers: () => {
            return dispatch(getCallSchedulers())
        },
        addEventtoScheduler: (data) => {
            return dispatch(addEventtoScheduler(data))
        },

    });
};

function mapStateToProps(state) {
    return {};
}

export default Scheduler = connect(mapStateToProps, mapDispatchToProps)(Scheduler);
