import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Grid, Row, Col } from 'react-bootstrap'
import Card from '../../Card/Card'
import { getProgramTypesPagination, deleteProgramType } from '../../../redux/actions/ProgramTypeActions';
import ThemeModal from '../../ThemeComponents/ThemeModal'
import { createSortString, closeModal } from '../../../helper/helperFunctions'
import ProgramTypeForm from './ProgramTypeForm';
import { confirmAlert } from 'react-confirm-alert';
import ConfirmAlertForDelete from '../../ThemeComponents/confirmAlertForDelete';
import Pagination from '../../ThemeComponents/Pagination'

class ProgramTypeList extends Component {

    constructor(props) {
        super(props)
        this.state = {
            pageSize: 10,
            filtered: [],
            programTypeEditModal: false,
            programTypeCreateModal: false,
            programTypeEditId: ''
        }
        this.inputChangeHandler = this.inputChangeHandler.bind(this);
        this.showModal = this.showModal.bind(this);
        this.deleteProgramTypeHandler = this.deleteProgramTypeHandler.bind(this);
        this.confirmDeleteHandler = this.confirmDeleteHandler.bind(this);
    }

    inputChangeHandler(e) {
        this.setState({
            [e.target.name]: [e.target.value]
        })
    }

    showModal(id = '', e) {
        this.setState({
            [e.target.name]: true,
            programTypeEditId: id
        });
    }

    deleteProgramTypeHandler(id) {
        this.props.deleteProgramTypeCall(id).then(res => {
            this.pagination.dataCall();
        });
    }

    confirmDeleteHandler(id) {
        confirmAlert({
            customUI: ({ onClose }) => <ConfirmAlertForDelete typeName='Program Type' onClosePro={onClose} deleteType={() => this.deleteProgramTypeHandler(id)} />
        })
    }

    paginationCall = (data) => {
        let sorted = data.sort.split(',');
        let { sort, orderType } = createSortString(sorted);
        return this.props.getProgramTypesPaginationCall(data.page, data.filter, sort, data.pageSize, orderType);
    }

    render() {
        
        const columns = [
            {
                Header: 'Program Types',
                headerClassName: 'headerPagination',
                columns: [
                    {
                        Header: 'Title',
                        accessor: 'title',
                        className: 'action-center'
                    },
                    // {
                    //     Header: 'Brand',
                    //     accessor: 'account_id',
                    //     id: 'accounts.title',
                    //     className: 'action-center',
                    //     Cell: ({ original }) => {
                    //         return <span>{original.account.title}</span>
                    //     }
                    // },
                    {
                        Header: 'Equipment Quantity',
                        accessor: 'item_quantity',
                        className: 'action-center'
                    },
                    {
                        Header: 'Equipment Type',
                        accessor: 'item_category_id',
                        id: 'itemCategory.title',
                        className: 'action-center',
                        Cell: ({ original }) => {
                            console.log(original, "data");
                            return <span>{original.item_category.title}</span>
                        }
                    },
                    {
                        Header: 'Video Quantity',
                        accessor: 'video_quantity',
                        className: 'action-center'
                    },
                    {
                        Header: 'Price',
                        accessor: 'price',
                        className: 'action-center'
                    },
                    {
                        Header: 'Public Price',
                        accessor: 'public_price',
                        className: 'action-center'
                    },
                    // {
                    //     Header: 'Sort Order',
                    //     accessor: 'sort_order',
                    //     className: 'action-center'
                    // },
                    {
                        Header: 'Actions',
                        className: 'action-center',
                        sortable: false,
                        Cell: ({ original }) => {
                            return (
                                <React.Fragment>
                                    <button name='programTypeEditModal' onClick={(e) => { this.showModal(original.id, e) }} type='button' className="orderDetailsAction editIcon" >&nbsp;</button>
                                    <button onClick={() => this.confirmDeleteHandler(original.id)} type='button' className="orderDetailsAction ml-md deleteIcon" >&nbsp;</button>
                                </React.Fragment>
                            )
                        }
                    }
                ]
            }
        ]

        return (
            <div className='content' >
                <Grid fluid>
                    <Row>
                        <Col md={12}>
                            <Card content={
                                <React.Fragment>
                                    <div className="secHeading">Program Types List</div>
                                    <div className='flexElem' style={{ justifyContent: 'space-between' }}>
                                        <button onClick={(e) => this.showModal('', e)} name='programTypeCreateModal' className='backButton my-20 pt-sm no_radius pb-sm primary text-xs btn btn-lg btn-info btn btn-lg btn-info  btn btn-default' ><i className="fa fa-plus" />Create Program Type</button>
                                        <div className='my-20 col-md-4'>
                                            {/* <input onChange={this.inputChangeHandler} type='text' name='filtered' className='form-control' placeholder='Search...' /> */}
                                        </div>
                                    </div>
                                    {/* <ReactTable
                                        columns={columns}
                                        manual
                                        filtered={this.state.filtered}
                                        ref={table => this.table = table}
                                        defaultPageSize={this.state.pageSize}
                                        pages={programTypesPagination.last_page}
                                        data={programTypesPagination.data}
                                        onFetchData={(state) => {
                                            var sort = state.sorted.length === 0 ? '' : state.sorted[0].id + ',desc:' + state.sorted[0].desc;
                                            this.props.getProgramTypesPaginationCall(state.page + 1, state.filtered, sort, state.pageSize);
                                        }}
                                    /> */}
                                    <div className='row'>
                                        <Pagination
                                            ref={(p) => this.pagination = p}
                                            showPagination={false}
                                            columns={columns}
                                            pageSize={10}
                                            getDataCall={this.paginationCall}
                                            filterView={true}
                                            noDataText='No Program Type'
                                            // downloadData={true}
                                            // downloadFileName={'Orders'}
                                            // lowerContent = {null}
                                            filterPlaceHolder={'Program Type'}
                                            getRowProps={this.getRowProps}
                                        // showAllToggle={true}
                                        />
                                    </div>
                                </React.Fragment>
                            } />
                        </Col>
                    </Row>
                </Grid>

                <ThemeModal size='lg' show={this.state.programTypeEditModal} hide={closeModal.bind(this, 'programTypeEditModal')} title='Edit Program Type'>
                    <ProgramTypeForm getPagination={this.pagination ? this.pagination.dataCall : null} formType='edit' hide={closeModal.bind(this, 'programTypeEditModal')} id={this.state.programTypeEditId} />
                </ThemeModal>

                <ThemeModal size='lg' show={this.state.programTypeCreateModal} hide={closeModal.bind(this, 'programTypeCreateModal')} title='Create Program Type'>
                    <ProgramTypeForm getPagination={this.pagination ? this.pagination.dataCall : null} hide={closeModal.bind(this, 'programTypeCreateModal')} formType='add' />
                </ThemeModal>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { programTypesPagination } = state.programTypeReducer;
    return { programTypesPagination }
}

const mapDispatchToProps = dispatch => {
    return {
        getProgramTypesPaginationCall: (page, filter, sort, pageSize, orderType) => { return dispatch(getProgramTypesPagination(page, filter, sort, pageSize, orderType)) },
        deleteProgramTypeCall: (id) => { return dispatch(deleteProgramType(id)) }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProgramTypeList)
