import React, { useState, useEffect } from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import _ from "lodash";
import { connect } from 'react-redux'
import TreeView from '@mui/lab/TreeView';
import TreeItem from '@mui/lab/TreeItem';
import { clearOrderResources, getOrderResourceFormats, getOrderResources, storeOrderResource, orderResourceDelete, getFolderOrderResources, getAgencyFolderResources} from 'redux/actions/order-actions';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import DoNotDisturbOnOutlinedIcon from '@mui/icons-material/DoNotDisturbOnOutlined';
import { Table } from "react-bootstrap";
import DeleteIcon from '@mui/icons-material/Delete';
import { confirmAlert } from 'react-confirm-alert';
import ConfirmAlertForDelete from 'components/ThemeComponents/confirmAlertForDelete';
import { alertActions } from '../../redux/actions/alert-actions';
import { SC } from 'helper/ServerCall';
import { withRouter } from 'react-router-dom'
import "./Folder.css"
const Folder = (props) => {
    const [FilesArray, setFilesArray] = useState(props.item.data);

    useEffect(()=>{
        setFilesArray(props.item.data)
        },
        [props.item.data]
    )

    let itemId = props.item.folder_id
    var DeleteButtonsStyle = {
        position: "absolute",
        right: "20px",
        top: "12px",
        zIndex: "5",
        cursor: "pointer",
    }

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
      };

      function deleteOrderFolderResources (id) {
        return SC.deleteCall(`deleteOrderFolderResources/${id}` );
    }
      function deleteAgencyFolderResources (id) {
        return SC.deleteCall(`deleteAgencyFolderResources/${id}` );
    }
      function setResourceSortOrder (resources) {
        return SC.postCall('setResourceSortOrder',{ resources: resources } );
    }
      function onResourceDeleteHandler(index, id){
        // For Agency Folder Deletion
        if (props.agencyId){
        deleteAgencyFolderResources(id).then(response => {
            props.success(response.message);
            props.getAgencyFolderResourcesCall(props.agencyId)
        });
        }
        // For Order Folder Deletion
        if (props.orderId) {
            deleteOrderFolderResources(id).then(response => {
            props.success(response.message);
            props.onGetOrderFolderResourcesCall(props.orderId)
        });
        }
    }

    function confirmResourceDelete(index , id){
        confirmAlert({
            customUI: ({ onClose }) => <ConfirmAlertForDelete typeName='Folder' onClosePro={onClose} deleteType={onResourceDeleteHandler.bind(this, index, id)} />
        })
    }
    function DeleteClicked(){
        console.log("I was clicked", props.orderId)
        confirmResourceDelete(props.key, props.item.folder_id)
        //Backend Api call
    }
    function handleOnDragEnd(result) {
        //console.log("I was called")
        // console.log(result,"I am initial result")
        // console.log(result.source.index,"I am the result source index ")
        // console.log(result.destination.index,"I am the result destination index ")
        if (props.dragable) {

            if (!result.destination) return;


            const items = reorder(
                FilesArray,
                result.source.index,
                result.destination.index
              );

            console.log(items,"before setFilesArray items")
            setFilesArray(items);
            // console.log(sortedArray,"after setFilesArray items")
            //Backendupdate
            let changeddata = _.cloneDeep(props.item)
            const updatearray = (changeddata) =>{
                changeddata.data = FilesArray
            }
            var resources = items.map((item) => item.id);
            //Has not been passed yet
            //console.log(result,"I am end result")
            setResourceSortOrder(resources).then(response=>{
                props.success(response.message)
            });
        }
    }
    return (
        <>
            <div style={{position: "relative"}}>
                {
                    props.noDelete ?
                    ''
                    :
                    <DeleteIcon style={ DeleteButtonsStyle}  clickable color='white' onClick={DeleteClicked} />
                }
                <TreeView
                    aria-label="multi-select"
                    className='parent parentAdmin'
                    expanded={props.ex}
                    onNodeToggle={(e, nodeId) => {props.onFolderClick(e, props.item, nodeId)}}
                    defaultCollapseIcon={<DoNotDisturbOnOutlinedIcon />}
                    defaultExpandIcon={<AddBoxOutlinedIcon />}
                    >
                    <TreeItem key={itemId.toString()}  nodeId={itemId.toString()} alt={itemId++} label={props.item.folder_name}>

                        <Table className="blueStyle" bordered striped size="sm">
                            <thead>
                                <tr>
                                    <th style={{color:"#fff"}}>File Name</th>
                                    <th style={{color:"#fff"}}>Status</th>
                                    <th className="text-center" style={{color:"#fff"}}>Action</th>
                                </tr>
                            </thead>
                            {
                                props.dragable ?
                                <DragDropContext onDragEnd={handleOnDragEnd}>
                                    <Droppable droppableId="droppable" >
                                        {(provided) => (
                                            <tbody {...provided.droppableProps} ref={provided.innerRef}>
                                                {
                                                    FilesArray ?
                                                    FilesArray.map((files,key,index) => {

                                                    // console.log(key.toString(),"This is my key")
                                                        return (
                                                            <Draggable key={key} draggableId={key.toString()} index={key}  >
                                                                {(provided) => (
                                                                    <tr key={key}  ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                    <td><a style={{ wordBreak: 'break-all' }} className='blue_link' target="_blank" href={files.file_path} rel="noopener noreferrer">{files.original_file_name }</a></td>

                                                                    <td>{files.is_scheduled === 0 ? 'Sent' : 'Scheduled'}</td>
                                                                    <td className="text-center">
                                                                        <a className='mr-md' href={files.file_path}><i className='fa fa-download'></i></a>
                                                                        {
                                                                            props.noDelete ?
                                                                            ''
                                                                            :
                                                                            <button className="orderDetailsAction deleteIcon" type="button" onClick={props.confirmResourceDelete.bind(this, key, files.id)} >&nbsp;</button>
                                                                        }
                                                                    </td>

                                                                    </tr>
                                                                )}
                                                            </Draggable>
                                                    )})
                                                    :
                                                    ''
                                                }
                                                {provided.placeholder}
                                            </tbody>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                                :
                                <tbody>
                                    {
                                        FilesArray ?
                                        FilesArray.map((files,key,index) => {

                                        // console.log(key.toString(),"This is my key")
                                            return (
                                                        <tr key={key}>
                                                        <td><a style={{ wordBreak: 'break-all' }} className='blue_link' target="_blank" href={files.file_path} rel="noopener noreferrer">{files.original_file_name }</a></td>

                                                        <td>{files.is_scheduled === 0 ? 'Sent' : 'Scheduled'}</td>
                                                        <td className="text-center">
                                                            <a className='mr-md' href={files.file_path}><i className='fa fa-download'></i></a>
                                                            {
                                                                props.noDelete ?
                                                                ''
                                                                :
                                                                <button className="orderDetailsAction deleteIcon" type="button" onClick={props.confirmResourceDelete.bind(this, key, files.id)} >&nbsp;</button>
                                                            }
                                                        </td>

                                                        </tr>
                                        )})
                                        :
                                        ''
                                    }
                                </tbody>
                            }
                        </Table>

                    </TreeItem>
                </TreeView>
            </div>
        </>

    )
}
const mapStateToProps = (state) => {
    const { resourceType, orderResourcesDocuments, orderFolderDocuments } = state.ordersReducer;
    return {
        resourceType, orderResourcesDocuments, orderFolderDocuments
    }
}

const mapDispatchToProps = dispatch => {
    return {
        clearOrderResources: () => { dispatch(clearOrderResources()) },
        onOrderResourceFormatsCall: (type) => { return dispatch(getOrderResourceFormats(type)) },
        onGetOrderResourcesCall: (orderId, type) => { return dispatch(getOrderResources(orderId, type)) },
        onGetOrderFolderResourcesCall: (orderId) => { return dispatch(getFolderOrderResources(orderId)) },
        onGetAgencyFolderResourcesCall: (agencyId) => { return dispatch(getAgencyFolderResources(agencyId)) },
        onStoreOrderResourceCall: (resource, id) => dispatch(storeOrderResource(resource, id)),
        onResourceDeleteCall: (resources, id) => { return dispatch(orderResourceDelete(resources, id)) },
        success: (message) => { return dispatch(alertActions.success(message))},
        getAgencyFolderResourcesCall: (agencyId) => { return dispatch(getAgencyFolderResources(agencyId)) }
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Folder))
