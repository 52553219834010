import axios from 'axios';
import { alertActions } from '../redux/actions/alert-actions';
export const int = {
  setupInterceptors
}

function setupInterceptors(store, history, props) {
  const { dispatch } = store;
  axios.interceptors.response.use(response => {
    return response.data;
  },
    (error) => {
      if (axios.isCancel(error)) {

      } else if (error.response){
        if (error.response && error.response.status === 401) {
          // store.dispatch(logoutUser());
          localStorage.removeItem('user');
          // dispatch(logout());
          // dispatch(adminUserActions.logout(false));
        }

        // if (error.response.status === 404) {
        //   // console.log('404');
        //    //history.push('/not-found');
        // }
        if (error.response.data){
            if ('errors' in error.response.data) {
              return Promise.reject(error.response.data.errors);
            } else if ('error' in error.response.data) {
              return Promise.reject(error.response.data.error);
            } 
            else {
              return Promise.reject(error.response.data);
            }
        } 
        else {
          return Promise.reject(error.response);
        }
      }
    });

  axios.interceptors.request.use((config) => {
    // const { dispatch } = props;
    dispatch(alertActions.clear());

    return config;
  },
    (error) => {
      console.log('error 2', error);
      return Promise.reject(error);
    });
}
