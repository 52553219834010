import React, { Component } from 'react';
import $ from 'jquery';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { s3StaticPath } from 'helper/helperFunctions'

class MemberAffiliations extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isHovered: false,
        };
        this.handleHover = this.handleHover.bind(this);
    }
    handleHover() {
        // this.setState(prevState => ({
        //     isHovered: !prevState.isHovered
        // }));

        $("#clients div.client").hover(function () {
            $("#clients div.client").removeClass("pink_gradient")
            $(this).addClass("pink_gradient")
        }, function () {
            $("#clients div.client").removeClass("pink_gradient")
        })
    }
    render() {
        // const hoverClass = this.state.isHovered ? "client pink_gradient" : "client";
        // let sliderSettings = {
        //   dots: true,
        //   autoplay: true,
        //   infinite: true,
        //   speed: 500,
        //   slidesToShow: 1,
        //   slidesToScroll: 1,
        //   accessibility: false,
        //   className: 'benefits',
        //   prevArrow: <div><MdArrowBack /></div>,
        //   nextArrow: <div><MdArrowForward /></div>,
        // };

        return (
            <React.Fragment>
                <section id="memberAffliate" className="section">
                    <div className="container mt-xlg">
                        <div className="row mb-xlg">
                            <div className="col-md-12 mb-xlg">
                                <h2 className="panel_heading">Member Affiliations</h2>

                                <p className="text_22 text-center mt-md">We have been members in good standing for over 12 years with each of the following prestigious tour and travel associations.</p>
                            </div>

                            <div className="col-md-4 col-sm-6 col-xs-12">
                                <div className="member text-center">
                                    <div className="memberLogo">
                                        <img src={s3StaticPath('img/syta_logo.png')} alt="Student Youth Travel Association" />
                                    </div>
                                    <p className="memberName mt-sm text_18">Student Youth Travel Association</p>
                                    <div className="mt-xs">
                                        <a href="http://www.syta.org" className="linkColor" target="_blank" rel="noopener noreferrer">www.syta.org</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-6 col-xs-12">
                                <div className="member text-center">
                                    <div className="memberLogo">
                                        <img src={s3StaticPath('img/aba_logo.jpg')} alt="American Bus Association" />
                                    </div>
                                    <p className="memberName mt-sm text_18">American Bus Association</p>
                                    <div className="mt-xs">
                                        <a href="http://www.buses.org" className="linkColor" target="_blank" rel="noopener noreferrer">www.buses.org</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-6 col-xs-12">
                                <div className="member text-center">
                                    <div className="memberLogo">
                                        <img src={s3StaticPath('img/NTA_logo.jpg')} alt="National Tour Association" />
                                    </div>
                                    <p className="memberName mt-sm text_18">National Tour Association</p>
                                    <div className="mt-xs">
                                        <a href="http://www.ntaonline.com" className="linkColor" target="_blank" rel="noopener noreferrer">www.ntaonline.com</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-6 col-xs-12">
                                <div className="member text-center">
                                    <div className="memberLogo">
                                        <img src={s3StaticPath('img/tap_logo.png')} alt="Travel Alliance Partners" />
                                    </div>
                                    <p className="memberName mt-sm text_18">Travel Alliance Partners</p>
                                    <div className="mt-xs">
                                        <a href="http://www.travelalliancepartners.com" className="linkColor" target="_blank" rel="noopener noreferrer">www.travelalliancepartners.com</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-6 col-xs-12">
                                <div className="member text-center">
                                    <div className="memberLogo">
                                        <img src={s3StaticPath('img/vc_logo.jpg')} alt="Visit Charlotte" />
                                    </div>
                                    <p className="memberName mt-sm text_18">Visit Charlotte</p>
                                    <div className="mt-xs">
                                        <a href="http://www.charlottesgotalot.com" className="linkColor" target="_blank" rel="noopener noreferrer">www.charlottesgotalot.com</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-6 col-xs-12">
                                <div className="member text-center">
                                    <div className="memberLogo">
                                        <img src={s3StaticPath('img/beta.jpg')} alt="British Educational Travel Association" />
                                    </div>
                                    <p className="memberName mt-sm text_18">British Educational Travel Association</p>
                                    <div className="mt-xs">
                                        <a href="https://www.betauk.com/" className="linkColor" target="_blank" rel="noopener noreferrer">www.betauk.com</a>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

export default MemberAffiliations;