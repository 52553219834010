import { mosaicService } from "../../services/mosaic"
import { generalActions } from "./generalActions"
import { adminConstants } from "../constant/admin-constant"
import { alertActions } from "./alert-actions"

export function getAllMosaicStyles(data) {
    return dispatch => {
        mosaicService.getAllMosaicStyles(data).then(
            res => {
                dispatch(generalActions.success(adminConstants.GET_ALL_MOSAIC_STYLES_SUCCESS, res))
            },
            err => {
                dispatch(generalActions.failure(adminConstants.GET_ALL_MOSAIC_STYLES_FAILURE, err))
            }
        )
    }
}

export function updateOrderMosaic(id, data) {
    return dispatch => {
        return mosaicService.updateOrderMosaic(id, data).then(
            res => {
                dispatch(alertActions.success(res.message))
            },
            err => {
                console.log(err);
                dispatch(alertActions.error(err))
            }
        )
    }
}