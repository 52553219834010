import React, { Component } from 'react'

class GeneratePreAwarenessFlyer extends Component {
    render() {
        return (
                <a
                    rel="noopener noreferrer"
                    target='_blank'
                    href={`${this.props.base_url}/generatePreawarenessFlyer/${this.props.orders}`}
                    className={this.props.className} >
                    {this.props.btnIcon}
                    <span>Pre-Awareness Flyer</span>
                </a>
        )
    }
}

export default GeneratePreAwarenessFlyer
