import { SC } from "../helper/ServerCall";

export const testimonialService = {
    getTestimonials,
    storeTestimonial,
    deleteTestimonial
}

function getTestimonials(brand_id, pageSize, sort, filter) {
    return SC.getCall(`testimonial?brand_id=${brand_id}&pageSize=${pageSize}&sort=${sort}&filter=${filter}`);
}

function storeTestimonial(data) {
    return SC.postCallWithoutAuth('testimonial', data);
}

function deleteTestimonial(id) {
    return SC.deleteCall(`deleteTestimonial/${id}`)
}