import React, { Component } from "react";
import { connect } from 'react-redux';
import { Grid, Row, Col } from "react-bootstrap";
import { Card } from "components/Card/Card.jsx";
import { contactList } from "redux/actions/order-actions";
import ContactListPagination from './ContactListPagination';
import {dateFormatMy, returnLabelWithValue} from 'helper/helperFunctions';
import { Link } from 'react-router-dom';
import { getUiSettings } from 'redux/actions/settings-actions';
import { objectsConstants as OC } from 'redux/constant/objects-constant';
import Select from 'react-select';
import "react-tabs/style/react-tabs.css";
// import MyImage from "../../Upload/MyImage";
// import {Tab} from "react-tabs";

class ContactListCSPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapseUiSettings: false,
      index: 0,
      numOfDays: 7
    }
    this.props.getUiSettingsCall();
  }

  handleChangeContactLogDays = (selectedOptionType, info) => {
    if (info['action'] === 'select-option') {
      // const temp = cloneDeep(this.state);
      // temp[info['name']] = selectedOptionType['value'];
      // if (info['name'] === 'program_type_id') {
      // this.props.getProgramItemCall(selectedOptionType['value']);
      this.setState({ numOfDays: selectedOptionType['value'] });
      // }
    }
  }
  render() {

    const CScolumns = [
      {
        Header: "Camera Ship",
        headerClassName: 'headerPagination',
        columns: [
          {
            Header: "Barcode",
            accessor: "barcode",
            width: 200,
            className: "action-center"
          },
          {
            Header: "Group Name",
            accessor: "group_name",
            Cell: ({ row }) => (
              <div >
                {
                  row._original.groupNameStars + ' ' + row._original.group_name
                }
              </div>
            ),
            className: "action-center",
          },
          {
            Header: "Contact",
            accessor: "group_leader_contact.email",
            Cell: ({ row }) => (
              <div >
                {
                  row._original.glEmailStars + ' ' + row._original.group_leader_contact.email
                }
              </div>
            ),
            className: "action-center"
          },
          {
            Header: "Departure Date",
            accessor: "order_dates.departure_date",
            Cell: ({ value }) => (
              <div>
                {dateFormatMy(value.value)}
              </div>
            ),
            className: "action-center",
            id: "order_dates.departure_date.value"
          },
          {
            Header: "Projected Camera Ship Date",
            accessor: "order_dates.projected_camera_ship_date",
            Cell: ({ value }) => (
              <div>
                {dateFormatMy(value.value)}
              </div>
            ),
            className: "action-center",
            id: "order_dates.projected_camera_ship_date.value"
          },
          {
            Header: "Number Of Videos",
            accessor: "numberOfVideos",
            className: "action-center",
            sortable: false,
          },
          {
            id: 'contactLogLastComment',
            Header: "Contact Log Last Entry",
            accessor: "contactLogLastComment",
            Cell: ({ value }) => (
              <div>
                {value ? dateFormatMy(value) : 'N/A'}
              </div>
            ),
            className: "action-center",
          },

          {
            Header: "Contact Log",
            accessor: "id",
            Cell: ({ value }) => (
              <div>
                <Link className="orderDetailsAction editIcon" to={'/Admin/' + value + '/order-edit'}>&nbsp;</Link>
              </div>
            ),
            className: "action-center",
            sortable: false,
          },
        ]
      }
    ];


    const {
      loadingOrder,
      CSContactList_data, CSContactList_pages, CSContactList_current_page,
      uiSettings
    } = this.props;

    const { collapseUiSettings, numOfDays } = this.state;
    return (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                ctTableResponsive
                content={
                  <div>
                    <Grid fluid>
                      <Row>
                        <Card bsClass={["innerUiSettingsCard"]} title="Contact List" collapseTrigger={collapseUiSettings} collapsHandler={() => this.setState({ collapseUiSettings: !this.state.collapseUiSettings })} collapsable={true}
                          content={
                            <div className="clearfix">
                              <Col md={4} className="mt-md">
                                <h6>Global Settings</h6>
                                <ul>
                                  {
                                    uiSettings.globalSetting.map((ui2, index) => (
                                      <li key={index}>
                                        <span style={{ color: ui2.value }}>{ui2.description + ':' + ui2.value}</span>
                                      </li>
                                    )
                                    )
                                  }
                                </ul>
                              </Col>
                              <Col md={4} className="mt-md">
                                <h6>Item Categories Settings</h6>
                                <ul>
                                  {
                                    uiSettings.itemCategory.map((ui1, index) => (
                                      <li key={index}>
                                        <span style={{ color: ui1.contact_list_text_color }}>{ui1.title + ':' + ui1.contact_list_text_color}</span>
                                      </li>
                                    )
                                    )
                                  }
                                </ul>
                              </Col>
                              <Col md={4} className="mt-md">
                                <h6>Account Settings</h6>
                                <ul>
                                  {
                                    uiSettings.accountSetting.map((ui3, index) => (
                                      <li key={index}>
                                        <span style={{ backgroundColor: ui3.value }}>{ui3.model.title + ':' + ui3.value}</span>
                                      </li>
                                    )
                                    )
                                  }
                                </ul>
                              </Col>
                            </div>
                          }
                        />
                        <Col md={12} className="mt-md">
                          <div id="tabs" className="orderTabs colored">


                            <ContactListPagination
                              data={CSContactList_data}
                              pages={CSContactList_pages}
                              page={CSContactList_current_page}
                              loading={loadingOrder}
                              columns={CScolumns}
                              getContactListCall={this.props.getContactListCall}
                              callback={'CSContactList'}
                              case={1}
                              searchPlaceHolder={'Search Camera ship contact list'}
                              numOfDays={numOfDays}
                              haveBackgroundColor={true}
                              extraHtml={
                                <div>
                                  <Select
                                    value={returnLabelWithValue(numOfDays, OC.CONTACTLOGDAYS)}
                                    onChange={this.handleChangeContactLogDays}
                                    options={OC.CONTACTLOGDAYS}
                                    isSearchable={true}
                                    isMulti={false}
                                    name='numOfDays'
                                    placeholder={'Select Days'}
                                  />
                                    <Link style={{position:'relative'}} target="_blank" className='customBtn btn btn-primary text-white mt-lg ml-lg' to={`/scheduler?admin=true`}><span style={{right:'0',position:'absolute',background: 'red',top: '-15px',borderRadius:'999px',padding: '2px 10px'}}>{this.props.scheduledCalls.length}</span>See Call Scheduler</Link>
                                </div>
                              }
                            />
                          </div>
                        </Col>
                      </Row>
                    </Grid>
                  </div>
                } />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return ({
    getContactListCall: (data) => { return dispatch(contactList(data)) },
    getUiSettingsCall: () => { dispatch(getUiSettings()) },
  });
};

function mapStateToProps(state) {
  const {
    loadingOrder,
    CSContactList_data, CSContactList_pages, CSContactList_current_page,
    VSContactList_data, VSContactList_pages, VSContactList_current_page,scheduledCalls
  } = state.ordersReducer;

  const { loadingSettings, uiSettings } = state.settingsReducer;
  return {
    loadingOrder,
    CSContactList_data, CSContactList_pages, CSContactList_current_page,
    VSContactList_data, VSContactList_pages, VSContactList_current_page,
    loadingSettings, uiSettings,
      scheduledCalls
  };
}


const ContactListCS = connect(mapStateToProps, mapDispatchToProps)(ContactListCSPage);
export default ContactListCS;
