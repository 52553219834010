import React, { Component } from "react";
import 'react-table/react-table.css';
import { Col } from "react-bootstrap";
import Pagination from "../../ThemeComponents/Pagination";

class ContactListPagination extends Component {
	constructor(props) {
		super(props);
		this.state = {
			sorted: '',
			filtered: '',
			pageSize: 10,
			page: this.props.page || 0,
			caseNum: this.props.case,
			callBack: this.props.callback,
			numOfDays: this.props.numOfDays,

			haveBackgroundColor: this.props.haveBackgroundColor
		};
	}
	contactListPaginationData = (page = this.state.page, filtered = this.state.filtered, sorted = this.state.sorted, pageSize = this.state.pageSize) => {
		var data = {
			filter: filtered,
			pageSize: pageSize,
			sort: sorted,
			page: page,
			caseNum: this.props.case,
			callBack: this.props.callback,
			numOfDays: this.props.numOfDays,
		};
		this.props.getContactListCall(data);
	}
	filterSearchHandle = (e) => {
		this.setState({
			'filtered': e.target.value
		});
	}
	componentDidUpdate(prevProps, prevState) {
		if (prevProps.page !== this.props.page) {
			this.setState({ page: this.props.page })
		}

		if (prevProps.numOfDays !== this.props.numOfDays) {
			var data = {
				filter: this.state.filtered,
				pageSize: this.state.pageSize,
				sort: this.state.sorted,
				page: this.state.page,
				caseNum: this.props.case,
				callBack: this.props.callback,
				numOfDays: this.props.numOfDays,
			};
			this.props.getContactListCall(data);
		}
	}

	getTrProps = (rowData) => {
		console.log('data one', rowData);
		let data = {}
		if (rowData !== undefined) {
			// const rowData = rowInfo.original;
			// console.log('data');
			if (this.props.haveBackgroundColor) {
				data = {
					color: 'textColor' in rowData ? rowData.textColor : null,
					backgroundColor: rowData.backgroundColor ? rowData.backgroundColor : rowData.order_account.account.backgroundColor,
				}
				// console.log('data', data);
			} else {
				data = {
					color: 'textColor' in rowData ? rowData.textColor : null,
				}
			}


		}
		return data;
	}

	paginationCall = (data) => {
		var newData = {
			filter: data.filter,
			pageSize: data.pageSize,
			sort: data.sort,
			page: data.page,
			caseNum: this.props.case,
			callBack: this.props.callback,
			numOfDays: this.props.numOfDays,
		};
        return this.props.getContactListCall(newData);
    }

	render() {
		const { columns /*, data, pages, loading, searchPlaceHolder, haveBackgroundColor*/ } = this.props;
		// const { filtered } = this.state;
		return (
			<React.Fragment>
				{/* <Col md={12}>
					<div className="flexElem flexResponsive" style={{ "alignItems": "flex-start", "justifyContent": "space-between" }}>
						<div className="custom-search-input">
							<FormInputs
								ncols={["col-md-12"]}
								onChange={this.filterSearchHandle}
								proprieties={[
									{
										type: "text",
										bsClass: "form-control",
										placeholder: searchPlaceHolder,
										onChange: this.filterSearchHandle,
										name: "filter"
									}
								]}
							/>
						</div>
					</div>
				</Col> */}
				<Col md={12} className="mt-md">
					{
						this.props.extraHtml
					}
					{/* <ReactTable
						noDataText='No Contact List found'
						data={data}
						pages={pages}
						columns={columns}
						filtered={filtered}
						defaultPageSize={10}
						loading={loading}
						className="-striped listing"
						loadingText={'Loading...'}
						pageData={this.contactListPaginationData}
						manual
						onFetchData={(state, instance) => {
							var sort = state.sorted.length === 0 ? '' : state.sorted[0].id + ',desc:' + state.sorted[0].desc;
							state.pageData(state.page + 1, state.filtered, sort, state.pageSize);
						}}

						getTrProps={(state, rowInfo) => {
							let data = {}
							if (rowInfo !== undefined) {
								const rowData = rowInfo.original
								if (haveBackgroundColor) {
									data = {
										color: 'textColor' in rowData ? rowData.textColor : null,
										backgroundColor: rowData.order_account.account.backgroundColor,
									}
								} else {
									data = {
										color: 'textColor' in rowData ? rowData.textColor : null,
									}
								}


							}
							return {
								style: data
							};
						}}
					/> */}
					<Pagination
						ref={(p) => this.pagination = p}
						showPagination={false}
						noDataText='No Contact List found'
						columns={columns}
						pageSize={10}
						getDataCall={this.paginationCall}
						filterView={true}
						filterPlaceHolder='Search Camera ship contact list'
						// downloadData={true}
						// downloadFileName={'Orders'}
						// lowerContent = {null}
						// filterPlaceHolder = {'Orders'}
						getRowProps={this.getTrProps}
					// showAllToggle={true}
					/>
				</Col>
			</React.Fragment>
		);
	}
}

// const mapDispatchToProps = dispatch => {
// return ({
// 	exampleFunctionCall: () => { dispatch(exampleAction.exampleFunction()) },//example function
// });
// };

// function mapStateToProps(state) {
// const { exampleConstant } = state.exampleReducer;

// return {
// 	exampleConstant
// };
// }


// const ContactListPagination = connect(mapStateToProps, mapDispatchToProps)(TestPage);
export default ContactListPagination;