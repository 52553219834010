import React, { Component } from "react";
import { connect } from 'react-redux';
import { getAccessLog } from "redux/actions/report-actions";
import ReactValidator from "simple-react-validator";
import DatePicker from "react-datepicker";
import { Row, Col, Grid } from "react-bootstrap";
import { Card } from "components/Card/Card.jsx";
import 'react-table/react-table.css';
import Pagination from "../../ThemeComponents/Pagination";
const moment = window.moment;

class AccessLogPage extends Component {
	constructor(props) {
		super(props);
		this.dateValidator = new ReactValidator();
		var today = new Date(),
			date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();

		this.state = {
			fromDate: date,
			toDate: date,
			pageSize: 10,
			page: 0,
			sorted: '',
			REPORTStotal_orders: 0
		}
		this.table = React.createRef();
	}
	DateChangeHandler = (value, field) => {
		this.setState({ [field]: value === null ? '' : moment(value).format("YYYY-MM-DD") }, () => {
			if (this.dateValidator.allValid()) {
				this.pagination.dataCall();;
			} else {
				this.dateValidator.showMessages();
				this.forceUpdate();
			}
		})
	}
	dateElement(label, name, value, rules) {
		var selectedDate = this.state[name] !== '' ? new Date(this.state[name]) : '';
		const { fromDate, toDate } = this.state;
		return (
			<div className='form-group fullCalendar' id={name}>
				<label>{label}</label>
				<DatePicker className="form-control calender_icon" selected={selectedDate} name={name} onChange={(event) => this.DateChangeHandler(event, name)} />
				{
					(fromDate !== '' && toDate !== '') &&
					this.dateValidator.message(name, value, rules)
				}
			</div>
		);
	}
	reportsPaginationData = (page = this.state.page, sorted = this.state.sorted, pageSize = this.state.pageSize) => {
		console.log(pageSize);
		const { fromDate, toDate } = this.state;
		this.props.getReportsCall(1, fromDate, toDate, page, pageSize, sorted);
	}

	paginationCall = (data) => {
		const { fromDate, toDate } = this.state;
		return this.props.getReportsCall(1, fromDate, toDate, data.page, data.pageSize, data.sort).then(res => {
			this.setState({
				REPORTStotal_orders: res.data.pagination.total
			})
			return res;
		});
	}

	render() {
		const { toDate, fromDate, REPORTStotal_orders } = this.state;
		// const { accesslogData, REPORTSpages, loadingOrder } = this.props;
		const columns = [
			{
				Header: "Access Log Report",
				headerClassName: 'headerPagination',
				columns: [
					{
						Header: "User",
						accessor: "user.name",
						className: "action-center",
						sortable: false,
					},
					{
						Header: "IP Address",
						accessor: "ipAddress",
						className: "action-center",
						sortable: false,
					},
					{
						Header: "Link",
						accessor: "route",
						className: "action-center",
						sortable: false,
					},
					{
						Header: "Referrer Link",
						accessor: "referer",
						className: "action-center",
						sortable: false,
					},
					{
						Header: "Browser",
						accessor: "userAgent",
						className: "action-center",
						sortable: false,
					},

					{
						Header: "Description",
						accessor: "description",
						className: "action-center",
						sortable: false,
					},
					{
						Header: "Date",
						accessor: "created_at",
						Cell: ({ value }) => (
							<div>
								{moment(value).format('MMMM D Y')}
							</div>
						),
						className: "action-center",
						sortable: false,
					},

				]
			}
		];
		return (
			<div className="content">
				<Grid fluid>
					<Row>
						<Col md={12}>
							<Card
								ctTableResponsive
								content={
									<div>
										<Grid fluid>
											<div className="row">
												<Col md={12}>
													<div className="secHeading">Website Access Log</div>
												</Col>

												<Col md={4} >
													<div className='row flexElem flexResponsive align-items-center' >
														<div className="col-md-6" >
															{
																this.dateElement('Date From', 'fromDate', fromDate !== '' && moment(fromDate, 'YYYY-MM-DD'), [{ before_or_equal: moment(this.state.toDate) }])
															}
														</div>
													</div>
												</Col>
												<Col md={4} >
													<div className='row flexElem flexResponsive align-items-center' >
														<div className="col-md-6" >
															{
																this.dateElement('Date To', 'toDate', toDate !== '' && moment(toDate, 'YYYY-MM-DD'), [{ after_or_equal: moment(this.state.fromDate) }])
															}
														</div>

													</div>
												</Col>
												<Col md={12}>
													<div>Total Records: {REPORTStotal_orders ? REPORTStotal_orders : 0}</div>
												</Col>

												{/* <Col md={12} className="mt-md">
					                        	<ReactTable
													ref={this.table}
						                            noDataText= 'No access log report found'
						                            data={accesslogData}
						                            pages={REPORTSpages}
						                            columns={columns}
						                            defaultPageSize={10}
						                            loading={loadingOrder}
						                            className="-striped listing"
						                            loadingText={'Loading...'}
						                            pageData={this.reportsPaginationData}
						                            manual
						                            onFetchData={(state, instance) => {
						                             
						                            	var sort = state.sorted.length === 0 ? '' : state.sorted[0].id + ',desc:' + state.sorted[0].desc;
														console.log('state page size', state.pageSize);  
														state.pageData(state.page + 1, sort, state.pageSize);
						                            }}
					                          	/>
					                        </Col> */}
												<Pagination
													ref={(p) => this.pagination = p}
													showPagination={false}
													columns={columns}
													pageSize={10}
													getDataCall={this.paginationCall}
													filterView={false}
													noDataText= 'No access log report found'
													// downloadData={true}
													// downloadFileName={'Orders'}
													// lowerContent = {null}
													// filterPlaceHolder = {'Orders'}
													getRowProps={this.getRowProps}
												// showAllToggle={true}
												/>
											</div>
										</Grid>
									</div>
								} />
						</Col>
					</Row>
				</Grid>
			</div>
		);
	}
}

const mapDispatchToProps = dispatch => {
	return ({
		getReportsCall: (value, fromDate, toDate, page, pageSize, sorted) => { return dispatch(getAccessLog(value, fromDate, toDate, page, pageSize, sorted)) },//example function
	});
};

function mapStateToProps(state) {
	const { accesslogData, REPORTSpages, REPORTScurrent_page, REPORTStotal_orders, loadingOrder } = state.reportReducer;
	return {
		accesslogData, REPORTSpages, REPORTScurrent_page, REPORTStotal_orders, loadingOrder
	};
}


const AccessLog = connect(mapStateToProps, mapDispatchToProps)(AccessLogPage);
export default AccessLog;