import React, { Component } from 'react'
import OrdersTOMissingResource from './Reports/OrdersTOMissingResource';
import UploadedImages from "./Order/ImagesTab/UploadedImages";
import TreeView from '@mui/lab/TreeView';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import DoNotDisturbOnOutlinedIcon from '@mui/icons-material/DoNotDisturbOnOutlined';
import TreeItem from '@mui/lab/TreeItem';
import 'react-sortable-tree/style.css'; // This only needs to be imported once in your app
import { SC } from 'helper/ServerCall';


export default class Test extends Component {
    constructor(props) {
        super(props)

        this.state = {
            data:  {resource_id: 6, order_folder_id: 2},
			collapseImageUploaderAndViewer: false,
            treeData: [
                { title: '', children: [{ title: '' }] },
                { title: 'Fish', children: [{ title: 'fingerline' }] },
                // { title: 'Dog', children: [{ title: 'fingerline' }] },
              ],
            callData: ''

        }
        // this.test(this.state.data);
         this.show(30089190).then(response =>{
             this.setState({
                 callData: response.data
             });
         })
    }
    test = (data) => {
        return SC.postCall('orderFolder' , data );
    }
    show = (id) => {
        return SC.getCall(`getOrderFolder/${id}` );
    }
    testEmail = () => {
        return SC.getCall('notify2test' );
    }
    render() {
        const {callData} = this.state;
        let itemId = 1;
        return (
            <div className="text-center mb-md">
                <button type="button" onClick={this.testEmail}>Email Test</button>

            </div>
        )
    }
}
