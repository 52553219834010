import React, { Component } from "react";
import { connect } from 'react-redux';
import {Grid, Row, Col, OverlayTrigger, Popover} from "react-bootstrap";
import { Card } from "components/Card/Card.jsx";
import AsyncCreatableSelect from 'react-select/async-creatable';
import { getDestinations } from 'redux/actions/settings-actions';
import { Link } from 'react-router-dom';
import { settingsService } from 'services/settings';
import Pagination from '../../ThemeComponents/Pagination';
import {alertActions} from 'redux/actions/alert-actions';
import { destinationService } from "../../../services/destination";
import { returnKeyWithColumnMatch } from 'helper/helperFunctions';
import { storeDestination } from 'redux/actions/destination-actions';
import { objectsConstants as OC } from 'redux/constant/objects-constant';


const promiseOptions = (inputValue) =>
	new Promise((resolve) => {
		console.log('inputValue', inputValue);
		setTimeout(() => {
			resolve(settingsService.getDestinationsAsync({ inputValue: inputValue }));
		}, 1000);
	}
	);
class AssignOrderDestinationPage extends Component {
  constructor(props) {
        super(props);
        this.state = {
            destinations: [],
            ordersData: [],
            ordersArray: [],
            updateDestination: [],
            destinationsArray: [],
            destinationId: this.props.match.params.id ? this.props.match.params.id : null,
            destinationsPaginationData: [],
            isChecked: false,
            updateOrders:[],
			newDestination: OC.DESTINATION,/*Modal for new destination code*/
        }
        this.props.getDestinationsCall({ inputValue: null, defaultOptions: [] }).then(result => {
            // console.log("response: " , result);
            this.setState({ destinations: result})
        });
    }

    handleChangeDestination = (selectedOption, name) => {
        if (name.action === 'select-option') {

                this.setState(prevState => ({
                    destinationsArray: [ ...prevState.destinationsArray, name.option.value]
                }));

        }else if (name.action === 'remove-value') {

            const destinationsTemp = [...this.state.destinationsArray];
            destinationsTemp.splice(destinationsTemp.indexOf(name.removedValue.value), 1);
            console.log("after remove-value",destinationsTemp.length);
            this.setState(({
                destinationsArray:  destinationsTemp
            }))

        } else if (name.action === 'create-option') {

            var lastSelectedOption = selectedOption.slice(-1).pop()
            console.log("lastSelectedOption",lastSelectedOption);
            // var key = returnKeyWithColumnMatch('__isNew__', lastSelectedOption, true)
            // console.log("keyyy",key);
            this.props.storeDestinationCall({ title: lastSelectedOption['label'] });

        } else {
            this.setState({
                destinationsArray:  []
            })
        }
        /*Modal for destination code*/
        // if (name.action === 'create-option') {
        //     var lastSelectedOption = selectedOption.slice(-1).pop()
        //     console.log("lastSelectedOption",lastSelectedOption);
        //     var key = returnKeyWithColumnMatch('__isNew__', lastSelectedOption, true)
        //     console.log("keyyy",key);
        //     this.props.storeDestinationCall({ title: lastSelectedOption['label'] });
        // }

        /*Modal for destination code*/
    }

    handleSubmit = () => {
        destinationService.assignOrderDestination(this.state.destinationId, {destinations: this.state.destinationsArray, orders: this.state.ordersArray, deleted_destination_id: this.state.destinationId}).then(response =>{
            this.props.destinationSubmitMessage(`Destination(s) Updated Successfully`)
                this.pagination.dataCall();
        })
    }
    paginationCall = (data) => {
        // const { queryString, queryValue } = this.state;
        return destinationService.destinationOrders(this.state.destinationId).then(response => {
            // console.log("destinationId", response);
            this.setState({
                destinationsPaginationData: response.data.destination,
                ordersData: response.data.pagination.data
            })
            return response;
        });
    }

    componentDidUpdate = (prevProps, prevState) => {
        if(this.state.destinationsPaginationData !== prevState.destinationsPaginationData){
            this.setState({destinationsPaginationData: this.state.destinationsPaginationData})
        }

        if (prevState.ordersData !== this.state.ordersData){
            const ordersArray = [];
            this.state.ordersData.forEach(item => {
             if(this.state.isChecked){
               ordersArray.push(item.id);
            }
            })
            this.setState({
              ordersArray,
              updateOrders:[...this.state.ordersData]
            })
            // console.log("updateOrders", this.state.updateOrders);
          }
          if (prevProps.storedDestination !== this.props.storedDestination) {
			this.setState(prevState => ({
				destinationsArray: [ ...prevState.destinationsArray, this.props.storedDestination.id],
				newDestination: OC.newDestination
			}));
		}
    }

    checkChange = (event, id ) => {
        const check = event.target.checked;
        console.log("checkchange", check);
        let newOrdes = [...this.state.ordersArray];
        // let active = this.state.updateDestination.map(item=>{
        //   if(item.id===id){
        //     item.is_active = check;
        //     return item;
        //   }
        //   return item;
        // })
        // this.setState({
        //   updateDestination: [...active]
        // })
        if (check){
          newOrdes.push(id);
          this.setState({
            ordersArray: newOrdes
          })
        }else{
          const i = newOrdes.findIndex((el) => {
            return el === id;
        });
            newOrdes.splice(i, 1);
            this.setState({
              ordersArray: newOrdes
            })
        }
    }

    checkAll = (e) => {
        const check = e.target.checked;
        console.log("check", check);
        const ordersArray = [];
        const data = this.state.ordersData
        if (check){
            data.forEach(item => {
                ordersArray.push(item.id );
            })
            this.setState({
                ordersArray,
                isChecked: true,
            })
            // console.log("ordersArray", ordersArray);
            }else{
                this.setState({
                    ordersArray: [],
                    isChecked: false
                })
            }
    }

render() {
    const {destinations, destinationId, destinationsPaginationData, ordersArray} = this.state;
    // console.log("render function",ordersArray);
    // console.log("destinationsPaginationData", this.state.destinationsPaginationData);
    const destinationName = destinationsPaginationData && destinationsPaginationData.title;
    const columns = [
        {
            Header: "Destinations Listing",
            headerClassName: 'headerPagination',
          columns: [
            {
                Header: <label className="container-check text-white">
                            &nbsp; Select All
                            <input type="checkbox" name="is_active" className="checkbox"  onChange={(e) =>{this.checkAll(e)}} checked={this.state.isChecked}
                            readOnly={true} /><span className="checkmark"></span>
                        </label>,
                accessor: "id",
                // Cell: ({ row, original}) => (
                //   //   console.log(row, 'rowwww'),
                //   <label className="container-check">
                //     &nbsp;
                //     <input type="checkbox" name="is_active" className="checkbox" value={original.is_active ? true : false} onChange={this.handleUpdateDestinationStatus(original.id)} checked={original.is_active ? true : false}
                //       readOnly={true} /><span className="checkmark"></span>
                //   </label>
                // ),
                Cell: ({ original }) => (
                  <label className="container-check">
                    &nbsp;
                    <input
                      type="checkbox"
                      name="is_active"
                      className="checkbox"
                      checked={this.state.ordersArray.includes(original.id)}
                      onChange={(event)=>{this.checkChange(event,original.id)}}
                    /><span className="checkmark"></span>
                  </label>
                ),
                className: "action-center",
                sortable: false
              },

            {
                Header: "Barcode",
                accessor: "barcode",
                width: 200,
                Cell: ({ original,value }) => (
                    // console.log("original", value),
                    /*add true in below conditions just for time to ignore condition */
                    <OverlayTrigger placement="bottom" overlay={<Popover id="tooltip">Edit Order</Popover>}>
                      {
                          <Link to={'/Admin/' + original.id + '/order-edit'} className="blue_link barcode" target="_blank" >
                            <span>
                              {original.barcode}
                            </span>
                          </Link>
                      }
                    </OverlayTrigger>
                  ),
            },
            {
                Header: "Group Name",
                accessor: "group_name",
                // width: 150,
                className: "action-center",
                Cell: ({ original }) => (
                  /*add true in below conditions just for time to ignore condition */
                  <span>{original.group_name}</span>
                ),
              },
          ]
        }
      ];

return (
    <div className="content">
        <Grid fluid>
            <Row>
                <Col md={12}>
                  <Card
                    ctTableResponsive
                    content={
                        <div>
                            <Grid fluid>
                                <Row>
                                    <Col md={12}>
                                        <div className="mb-md">
                                            <div className="secHeading">
                                                Assign Order Destinations
                                            </div>
                                            <div className="mb-md">
                                                 <strong><span style={{color:'red'}}>{destinationName}</span></strong><br />
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={12}>
                                        <div>
                                            <div className="col-md-5" id="desitnation">
                                                <label htmlFor="glemail">Select Active Destinations To Re-Assign<span className="requiredClass">*</span></label>
                                                    <AsyncCreatableSelect
                                                        isMulti={true}
                                                        defaultOptions
                                                        defaultValue={this.state.destinationsPaginationData}
                                                        // defaultInputValue
                                                        loadOptions={promiseOptions}
                                                        onChange={this.handleChangeDestination}
                                                        className="selectUser"
                                                        placeholder="Select Destinations"
                                                    />
                                                    {/* {
                                                        validatorInstance.message('desitnation', order_details.destinations, 'required|min:1,array')
                                                    } */}
                                            </div>
                                            <div className="col-md-3" style={{marginTop: "25px"}}>
                                                <div className="flexElem">
                                                    <button type="submit" onClick={this.handleSubmit} className="backButton pt-sm no_radius pb-sm primary mt-none btn-info btn mr-md ">Submit</button>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>



                                    <Pagination
                                        ref={(p) => this.pagination = p}
                                        showPagination={false}
                                        columns={columns}
                                        pageSize={10}
                                        getDataCall={this.paginationCall}
                                        filterView={true}
                                        noDataText='No destination found'
                                        // downloadData={true}
                                        // downloadFileName={'Orders'}
                                        // lowerContent = {null}
                                        filterPlaceHolder={'Destinations'}
                                        getRowProps={this.getRowProps}
                                        // showAllToggle={true}
                                    />
                                </Row>
                            </Grid>
                        </div>
                    } />
                </Col>
            </Row>
        </Grid>
    </div>
    );
  }
}


function mapStateToProps(state) {
	const { storedDestination } = state.destinationsReducer;

  return {
    storedDestination
  };
}

const mapDispatchToProps = dispatch => {
  return ({
    getDestinationsCall: (filter) => { return dispatch(getDestinations(filter)) },
    destinationSubmitMessage: (message) => { return dispatch(alertActions.success(message))},
    storeDestinationCall: (destinationData) => { return dispatch(storeDestination(destinationData)) },
  });
};

const AssignOrderDestination = connect(mapStateToProps, mapDispatchToProps)(AssignOrderDestinationPage);

export default AssignOrderDestination;
