import { SC } from "../helper/ServerCall";

export const proShowFileService = {
    storeProShowFile,
    getProShowFile,
    getProShowFilesPagination,
    updateProShowFileCall,
    deleteProShowFile,
    getProShowDestinations,
    getProShowDestinationsAsync,
    storeProshowDestination,
    updateProshowDestination,
    proShowFileOptions
}

function storeProShowFile(data) {
    return SC.postCall(`proShowFile`, data);
}

function storeProshowDestination(data) {
    return SC.postCall(`storeProshowDestination`, data);
}

function updateProshowDestination(data) {
    return SC.postCall(`updateProshowDestination`, data);
}

function getProShowFile(id) {
    return SC.getCall(`proShowFile/${id}`);
}

function proShowFileOptions(id) {
    return SC.getCall(`proShowFileOptions/${id}`);
}

function getProShowFilesPagination(page, filter, proShowDest, sort, pageSize, pagination) {
    if (pagination)
	    return SC.getCall(`proShowFileGet/${pagination}?page=${page}&filter=${filter}&proShowDest=${proShowDest}&sort=${sort}&pageSize=${pageSize}`);
    else
        return SC.getCall(`proShowFileGet/${pagination}`);
}

function updateProShowFileCall(data, id) {
	return SC.postCall(`proShowFile/${id}`, data);
}

function deleteProShowFile(destinationId) {

    return SC.deleteCall('proShowFile/' + destinationId);
}

function getProShowDestinations(data) {
    return SC.postCall(`getProShowDestinationsAsync`, data);
}

function getProShowDestinationsAsync(data) {

    return SC.postCall(`getProShowDestinationsAsync`, data).then(response=>{
        return response.getDestinations;
    });
}

