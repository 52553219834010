import React from 'react';
import {
	Button
} from "react-bootstrap";
import SimpleReactValidator from 'simple-react-validator';
import Select from 'react-select';
import { returnLabelWithValue } from 'helper/helperFunctions';
class ItemForm extends React.Component {
	constructor(props) {
		super(props);
		this.validatorItem = new SimpleReactValidator({ autoForceUpdate: this, locale: 'en' });
		this.state = {
			item: {
				...this.props.itemPro
			},
			submitted: false,
			formType: this.props.formTypePro || '',
			testObj: [
				{ value: 1, label: 'Test One' },
				{ value: 2, label: 'Test Two' },
				{ value: 3, label: 'Test Three' }
			],
		};
		this.handleItem = this.handleItem.bind(this);
		this.handleItemSubmitForm = this.handleItemSubmitForm.bind(this);
		this.handleChangeItem = this.handleChangeItem.bind(this);
	}
	handleChangeItem(selectedOption, info) {
		if (info.action === 'select-option') {
			this.setState(prevState => ({
				item: {
					...prevState.item,
					[info.name]: selectedOption.value
				}
			}));
		}
		// if (info.action === 'remove-value'){
		// 	const rolesTemp = this.state.user.roles;
		// 	rolesTemp.splice(rolesTemp.indexOf(info.removedValue.value), 1);
		// 	this.setState(prevState => ({
		// 		user: {    
		// 	        ...prevState.user,
		// 	        roles: rolesTemp
		// 	    }
		// 	}))
		// }
	}
	handleItem(e) {
		const { name, value, type } = e.target;
		const itemState = { ...this.state.item };
		itemState[name] = type === 'checkbox' ? e.target.checked ? 1 : 0 : value;
		this.setState({ item: itemState });
	}
	handleItemSubmitForm(e) {
		e.preventDefault();
		if (this.validatorItem.allValid()) {
			this.setState({ submitted: true }, function () {
				this.props.handleItemSubmit(this.state.item, this.state.formType);
			});
		} else {
			this.setState({ submitted: false });
			this.validatorItem.showMessages();
			this.forceUpdate();
		}
	}
	// componentDidMount(){
	// 	const { defaultItemById, defaultItemByName, defaultAgencyByName } = this.props;
	// 	if (defaultItemById ||  defaultItemByName || defaultAgencyByName) {	
	// 		const { options, defaultItemId, defaultItemName } = this.props;
	// 		this.setState({
	// 			item: { 
	// 				...this.state.item, 
	// 				item_id: defaultItemByName?returnColumnValueWithColumnMatch(options.items, 'value', [ defaultItemName ], 'label'):defaultItemById ? parseInt(defaultItemId, 10) : null,
	// 				agency_id: defaultAgencyByName?returnColumnValueWithColumnMatch(options.agencies, 'value', [ OC.DEFAULTAGENCY ], 'label'):null,

	// 			}
	// 		});
	// 	}
	// }
	render() {
		const { item } = this.state;
		const { options } = this.props;
		return (
			<form onSubmit={this.handleItemSubmitForm} id="item-form">
				<div className="row">
					<span></span>
					<div className="col-md-6">
						<div className='form-group' id="itemId">
							<label htmlFor="name">Select Category<span className="requiredClass">*</span></label>
							<Select
								// value={returnLabelWithValue(item.item_category_id, options.items)}
								value={returnLabelWithValue(item.item_category_id, options.itemCategories)}
								onChange={this.handleChangeItem}
								options={options.itemCategories}
								isSearchable={true}
								isMulti={false}
								placeholder={'Select Category'}
								isClearable={false}
								name="item_category_id"
							/>
							{this.validatorItem.message('itemId', item.item_category_id, 'required')}
						</div>
					</div>

					<div className="col-md-6">
						<div className='form-group' id="itemId">
							<label htmlFor="name">Select Type<span className="requiredClass">*</span></label>
							<Select
								value={returnLabelWithValue(item.item_type_id, options.itemTypes)}
								// value={returnLabelWithValue(item.item_type_id, options.items)}
								onChange={this.handleChangeItem}
								// options={options.items}
								options={options.itemTypes}
								isSearchable={true}
								isMulti={false}
								placeholder={'Select Type'}
								isClearable={false}
								name="item_type_id"
							/>
							{this.validatorItem.message('itemId', item.item_type_id, 'required')}
						</div>
					</div>
					<span></span>
				</div>
				<div className="row">
					<span></span>
					<div className="col-md-6">
						<div className='form-group' id="price">
							<label htmlFor="price">Price<span className="requiredClass">*</span></label>
							<input type="number" autoComplete="off" name="price" className="form-control" value={item.price} onChange={this.handleItem} />
							{this.validatorItem.message('price', item.price, 'required|min:0,num')}
						</div>
					</div>
					<div className="col-md-6">
						<div className='form-group' id="dvd">
							<label htmlFor="dvd">DVD<span className="requiredClass">*</span></label>
							<input type="number" autoComplete="off" name="dvd" className="form-control" value={item.dvd} onChange={this.handleItem} />
							{this.validatorItem.message('dvd', item.dvd, 'required|min:0,num')}
						</div>
					</div>
					<div className="col-md-6">
						<div className='form-group' id="title">
							<label htmlFor="title">Title<span className="requiredClass">*</span></label>
							<input type="text" autoComplete="off" name="title" className="form-control" value={item.title} onChange={this.handleItem} step="any" />
							{this.validatorItem.message('title', item.title, 'required')}
						</div>
					</div>
					<div className="col-md-6" id="show_on_order_form">
						<div className='form-group'>
							<label className="container-check checkbox_down">Show On Order Form
		              			<input type="checkbox" name="show_on_order_form" className="checkbox" value={item.show_on_order_form} onChange={this.handleItem} checked={item.show_on_order_form ? true : false} />
								<span className="checkmark"></span>
							</label>
						</div>
					</div>
					<div className="col-md-6" id="taxable">
						<div className='form-group'>
							<label className="container-check checkbox_down">Taxable
		              			<input type="checkbox" name="taxable" className="checkbox" value={item.taxable} onChange={this.handleItem} checked={item.taxable ? true : false} />
								<span className="checkmark"></span>
							</label>
						</div>
					</div>
					<span></span>

				</div>


				<div className="row">
					<div className="col-md-12">
						<div className='text-center'>
							<Button type='submit' form="item-form" bsSize="large" bsStyle="info" className="backButton pt-sm no_radius pb-sm success btn btn-lg btn-info ml-sm mt-sm btn-default " >Save</Button>
							<Button bsSize="large" bsStyle="info" onClick={() => this.props.closeModel()} className="backButton pt-sm no_radius pb-sm primary btn btn-lg ml-sm mt-sm btn-info " >Cancel</Button>
						</div>
					</div>
				</div>
			</form>
		);
	}
}

export default ItemForm;