import React, { Component } from "react";
import { connect } from 'react-redux';
import { getReceivingReports } from "redux/actions/report-actions";
import ReactValidator from "simple-react-validator";
import { Row, Col, Grid, OverlayTrigger, Popover } from "react-bootstrap";
import { Card } from "components/Card/Card.jsx";
import { dateFormatMy, hasRole } from 'helper/helperFunctions';
import Pagination from 'components/ThemeComponents/Pagination';
import { hasPermission } from 'helper/hasPermission';
import { colorForBardcodeWithDate } from 'helper/helperFunctions';
import { Link } from 'react-router-dom';
import MyImage from 'components/Upload/MyImage';
import { reportService } from "../../../services/report";
import { ThemeFunctions as TF } from 'helper/ThemeFunctions';
import { orderService } from 'services/order';
import { adminLabels } from 'redux/constant/admin-label-constant';

const queryString = require('query-string');
const moment = window.moment;

class EditedOrderByUser extends Component {
	constructor(props) {
        super(props);
        const parsed = queryString.parse(window.location.search);
        const roleParam = this.props.match ? this.props.match.params.roleParam : null;
        const idParam = this.props.match ? this.props.match.params.id : null;
		this.validator = new ReactValidator();
		this.state = {
			pageSizeTotal: 0,
            idParam: idParam,
            sorted: '',
            filtered: '',
            orderPage: 0,
            roleParam: roleParam,
            order_ids: parsed.order_ids,
			collapseUiSettings: true,

            admin_user_id: ((this.props.userAdmin && this.props.userAdmin.user) ? this.props.userAdmin.user.id : null)
		}
		this.getOrderMissingAgencyResourceCallWithNumDays = this.getOrderMissingAgencyResourceCallWithNumDays.bind(this);
		// this.getRowProps = this.getRowProps.bind(this);
	}
	getOrderMissingAgencyResourceCallWithNumDays(data) {
		data = { ...data, numOfDays: this.state.numOfDays, date_start: this.state.advancedSearchFilters.date_start, date_stop: this.state.advancedSearchFilters.date_stop};
		// return this.props.getReceivingReportsCall(data);
		return reportService.getEditingReports(data);
	}
	// getRowProps(rowData) {
	// 	let data = {};
	// 	if (parseInt(rowData.settings.finalize_by_editor.value, 10) === 1) {
	// 		data = { color: 'red' }
	// 	}
	// 	if (rowData.order_dates.receive_date.value === "" && rowData.shippingItemsCase > 0) {
	// 		data = { color: 'green' }
	// 	}
	// 	if (rowData.no_equipment_upload_only === 1) {
	// 		data = { color: 'pink' }
	// 	}
	// 	if ((rowData.order_dates.receive_date.value !== "") && (parseInt(rowData.settings.finalize_by_editor.value, 10) === 0) && (parseInt(rowData.settings.on_hold.value, 10) === 1)) {
	// 		if (moment(rowData.settings.photos_submitted_for_video_production.updated_at) < moment(rowData.order_dates.upload_cut_off_date.value)) {
	// 			data = { color: 'blue' }

	// 		}
	// 	}
	// 	return data;
	// }


	componentDidUpdate(prevProps, prevState) {
		// if (prevState.numOfDays !== this.state.numOfDays) {
        //     // this.setState(prevState => ({
        //     //     advancedSearchFilters: {
        //     //         ...prevState.advancedSearchFilters,
        //     //         date_start: '',
        //     //         date_stop: '',
        //     //     }
        //     // }))
		// 	this.pagination.dataCall();
		// }
        // // if (prevState.advancedSearchFilters.date_start !== this.state.advancedSearchFilters.date_start || prevState.advancedSearchFilters.date_stop !== this.state.advancedSearchFilters.date_stop) {
        // //     this.setState({
        // //         numOfDays: 0
        // //     })
        // // }
	}
	handleChangeContactLogDays = (selectedOptionType, info) => {
        console.log("selected",selectedOptionType);
		if (info['action'] === 'select-option') {
			this.setState({
                numOfDays: selectedOptionType['value']
            });
            this.setState(prevState => ({
                advancedSearchFilters: {
                    ...prevState.advancedSearchFilters,
                    date_start: '',
                    date_stop: '',
                }
            }),()=>{

            this.pagination.dataCall();
            })
		}
	}
    dateChangeHandler(dateType, date) {
		console.log("dateType: " , dateType);
		console.log("date: " , date);
        const input = dateType.split('.');
		console.log("input: " , input);
        const state = this.state[input[0]];
		console.log("state: " , state);
        date = date !== null ? date : '';
        state[input[1]] = date;
        this.setState({
            [input[0]]: state
        });
        this.setState({
            numOfDays: 0
        },()=>{

            this.pagination.dataCall();
        })
    }
    paginationCall = (data) => {
        var searchData = {};
        return orderService.getEditedOrderByUser(data.page, this.state.filtered, data.sort, data.pageSize, this.state.idParam, this.state.order_ids ).then(response => {
            // console.log(response.data.pagination.data.length, 'responseee');
            this.setState({
                orderData: response.data.pagination.data
            })
          return response;
        });
    }
	render() {
		//const { collapseUiSettings, filtered, numOfDays } = this.state;
		const { collapseUiSettings, numOfDays,roleParam, admin_user_id, idParam } = this.state;
        console.log("role: " , this.props.location.search);
        console.log("parsed " , this.state.order_ids);
        console.log("idParam " , idParam);
		const columns = [
			{
                Header: "Orders Listing",
                headerClassName: 'headerPagination',
                columns: [
                  {
                    Header: "Editor",
                    accessor: "created_at",
                    Cell: ({ value, original }) => (
                      <div >
                        {
                          original.settings.on_hold.value !== '1'
                            ?
                            original.editor_id
                              ?
                              original.editor.image
                                ?
                                <button><MyImage alt={original.editor.image.title} className="initialsLogo" src={original.editor.image.preview} /></button>
                                :
                                <button> {TF.initialsLogo(original.editor.bgColor, original.editor.name)}</button>
                              :
                              <OverlayTrigger placement="bottom" overlay={<Popover id="tooltip">{'Order assign me for editing process'}</Popover>}>
                                <button className="orderDetailsAction assignIcon" onClick={() => this.assignEditorToOrder(original.id)} >&nbsp;</button>
                              </OverlayTrigger>
                            :
                            'On Hold'
                        }
                      </div>
                    ),
                    className: "action-center",
                    show:
                      hasRole(['Remote Editor','Remote Editor/Producer'])
                        ?
                        true
                        :
                        false
                  },

                  {
                    Header: "Barcode",
                    accessor: "barcode",
                    width: 200,
                    className: "action-center",
                    show: roleParam !== 'mozaix' ? true : false,
                    Cell: ({ value, original }) => (
                      /*add true in below conditions just for time to ignore condition */
                      <OverlayTrigger placement="bottom" overlay={<Popover id="tooltip">{(hasPermission('order-edit') && original.editor_id === admin_user_id) || true ? 'Edit Order' : adminLabels.DONT_ALLOW}</Popover>}>
                        {

                          (hasPermission('order-edit') && original.editor_id === admin_user_id) || true ?
                            <Link to={'/Admin/' + original.id + '/order-edit'} className="blue_link barcode" target="_blank" >
                              <span className={
                                colorForBardcodeWithDate(original.order_dates.last_day_of_school.value, 'lastDayOfSchool') + " " +
                                colorForBardcodeWithDate(original.order_dates.video_special_need_date.value, 'videoSpecialNeedDate')
                              }
                              >
                                {value}
                              </span>
                            </Link>
                            :
                            <span className="barcode">{value}</span>
                        }
                      </OverlayTrigger>
                    ),
                  },
                  {
                    Header: "Agency Name",
                    accessor: "agency",
                    Cell: ({ value }) => (
                      <div>
                        {value.name}
                      </div>
                    ),
                    className: "action-center",
                    show: roleParam !== 'mozaix' ? true : false
                  },
                  {
                    Header: "Group Type",
                    accessor: `agency_type`,
                    Cell: ({ value, original }) => (
                      <div className={`${original.settings.on_hold.value === '1' ? 'yellowBackgroud' : null}`}>
                        {value.title}
                        {
                          original.settings.on_hold.value === '1' &&
                          TF.onHold(original.settings, original.on_hold_last_comment)
                        }
                      </div>
                    ),
                    className: "action-center",
                    width: 250,
                    backgroundColor: 'pink',
                    show: roleParam !== 'mozaix' ? true : false
                  },
                  {
                    Header: "Group Name",
                    accessor: "group_name",
                    className: "action-center",
                    sortable: false,
                    show: roleParam !== 'mozaix' ? true : false
                  },
                  {
                    Header: "Destinations",
                    accessor: "destinations",
                    className: "action-center",
                    show: roleParam !== 'mozaix' ? true : false
                  },
                  {
                    id: "settings.finalize_by_editor.value",
                    Header: "Editing Finalized Date",
                    accessor: "settings",
                    sortable: false,
                    Cell: ({ value }) => (
                          console.log("value", value),
                      <div>
                        {
                            value.finalize_by_editor.value ?
                            dateFormatMy(value.finalize_by_editor.updated_at)
                            :
                            ''
                        }
                      </div>
                    ),
                    className: "action-center",
                    show: roleParam !== 'mozaix' ? true : false
                  },
                  {
                    Header: "Total number of photos",
                    accessor: "originalImages",
                    className: "action-center",
                    sortable: false,
                    show: roleParam ?
                      roleParam === 'editor'
                        ?
                        true
                        :
                        false
                      :
                      hasRole(['editor'])
                        ?
                        true
                        :
                        false
                  },


                  {
                    id: "order_dates.video_special_need_date.value",
                    Header: "Special Need Date",
                    accessor: "order_dates",
                    Cell: ({ value }) => (
                      <div>
                        {dateFormatMy(value.video_special_need_date.value)}
                      </div>
                    ),
                    className: "action-center",
                    show: roleParam !== 'mozaix' ? true : false
                  },
                  {
                      Header: "Edited Photo Count ",
                      accessor: "workingImages",
                      Cell: ({ original }) => (
                        <div>
                            {
                            // console.log(original, 'Total # of Mozaix ordered'),
                               <span>{original.workingImages}</span>
                            }
                        </div>
                      ),
                      show: true
                  },
                ]
              }
		];
		return (
			<div className="content">
				<Grid fluid>
					<Row>
						<Col md={12}>
							<Card
								ctTableResponsive
								content={
									<div>
										<Grid fluid>
											<div className="row">
												<Col md={12}>
                                                    <div className="secHeading">
                                                         Edited Orders By User
                                                    </div>
												</Col>
												<Col md={12} className="mt-md">
													<Pagination
														ref={(p) => this.pagination = p}
														columns={columns}
														getDataCall={this.paginationCall}
														filterView={false}
														// getRowProps={this.getRowProps}
														pageSize={100}
													/>
												</Col>

											</div>
										</Grid>
									</div>
								} />
						</Col>
					</Row>
				</Grid>
			</div>
		);
	}
}

const mapDispatchToProps = dispatch => {
	return ({
		getReceivingReportsCall: (data) => { return dispatch(getReceivingReports(data)) },
	});
};

function mapStateToProps(state) {
	return {
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(EditedOrderByUser);
