import React, { Component } from "react";
import { connect } from 'react-redux';
import { getOrderLog } from "redux/actions/report-actions";
import ReactValidator from "simple-react-validator";
import DatePicker from "react-datepicker";
import { Row, Col, Grid } from "react-bootstrap";
import { Card } from "components/Card/Card.jsx";
import 'react-table/react-table.css';
import { dateTimeWithAMPM } from 'helper/helperFunctions';
import Highlighter from "react-highlight-words";
import Pagination from "../../ThemeComponents/Pagination";
const moment = window.moment;

class OrderLogPage extends Component {
	constructor(props) {
		super(props);
		this.dateValidator = new ReactValidator();
		var today = new Date(),
			date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
		date = '';
		const { id } = this.props.match.params;
		this.state = {
			fromDate: date,
			toDate: date,
			pageSize: 10,
			page: 0,
			sorted: '',
			order_id: id,
		}
		this.table = React.createRef();
	}
	DateChangeHandler = (value, field) => {
		this.setState({ [field]: value === null ? '' : moment(value).format("YYYY-MM-DD") }, () => {
			if (this.dateValidator.allValid()) {
				// this.pagination.dataCall();;
				this.pagination.dataCall();
			} else {
				this.dateValidator.showMessages();
				this.forceUpdate();
			}
		})
	}
	dateElement(label, name, value, rules) {
		var selectedDate = this.state[name] !== '' ? new Date(this.state[name]) : '';
		const { fromDate, toDate } = this.state;
		return (
			<div className='form-group fullCalendar' id={name}>
				<label>{label}</label>
				<DatePicker className="form-control calender_icon" selected={selectedDate} name={name} onChange={(event) => this.DateChangeHandler(event, name)} />
				{
					(fromDate !== '' && toDate !== '') &&
					this.dateValidator.message(name, value, rules)
				}
			</div>
		);
	}
	reportsPaginationData = (page = this.state.page, sorted = this.state.sorted, pageSize = this.state.pageSize) => {
		const { fromDate, toDate, order_id } = this.state;
		this.props.getReportsCall(order_id, fromDate, toDate, page, pageSize, sorted);
	}

	paginationCall = (data) => {
		const { fromDate, toDate, order_id } = this.state;
		return this.props.getReportsCall(order_id, fromDate, toDate, data.page, data.pageSize, data.sort);
	}

	isDate = (date) => {
		var matches = /^(\d{4})[-\/](\d{1,2})[-\/](\d{1,2}) (\d{2})[:](\d{2})[:](\d{2})$/.exec(date);
		if (matches == null) return false;
		return true;
	}

	render() {
		const { toDate, fromDate } = this.state;
		const columns = [
			{
				Header: "Report Testing",
				headerClassName: 'headerPagination',
				columns: [
					{
						Header: "Date",
						accessor: "created_at",
						Cell: ({ value }) => (
							<div>
								{dateTimeWithAMPM(value)}
							</div>
						),
						className: "action-center",
						sortable: false,
					},
					{
						Header: "User",
						accessor: "user",
						Cell: ({ value }) => (
							<div>{value ? value.name : 'System'}</div>
						),
						className: "action-center",
						sortable: false,
					},
					{
						Header: "IP",
						accessor: "ip",
						className: "action-center",
						sortable: false,
						show: false
					},
					{
						Header: "Description",
						accessor: "change",
						Cell: ({ value }) => (
							<div>
								<Highlighter
									highlightClassName={'YourHighlightClass'}
									searchWords={[/^[^:-]*[:-]\s*/g]}
									autoEscape={false}
									textToHighlight={value.toString()}
								/>
							</div>
						),
						className: "action-center",
						sortable: false,
					},
					{
						Header: "Properties",
						accessor: "properties",
						className: "action-center",
						sortable: false,
						Cell: ({ value }) => {
							let newValue = JSON.parse(value);
							let allowPrint = ['id', 'title', 'value', 'status_id', 'status', 'username', 'password'];
							if (newValue.old) {
								let old = newValue.old;
								let attributes = newValue.attributes;
                                allowPrint.push('customer_slates_for_videos_id');
								return (<React.Fragment>
									<div className='row'>
										<div className='col-md-6 text-left'>
											<strong style={{ textDecoration: 'underline' }}>Old</strong>
											<br />
											{
												Object.keys(old).map(item => {
													if (allowPrint.includes(item)) {
														return <React.Fragment>
															{/* <span>{item}:&nbsp; */}
                                                            <span>{item === 'customer_slates_for_videos_id' ? 'Secondary Agency' : item}:&nbsp;
                                                                {
                                                                    (this.isDate(old[item])) ? dateTimeWithAMPM(old[item])
                                                                    :
                                                                    old[item]
                                                                }
															</span> <br />
														</React.Fragment>
													}
												})
											}
										</div>
										<div className='col-md-6 text-left'>
											<strong style={{ textDecoration: 'underline' }}>New</strong>
											<br />
											{
												Object.keys(attributes).map(item => {
													if (allowPrint.includes(item)) {
														return <React.Fragment>
															{/* <span>{item}:&nbsp; */}
                                                            <span>{item === 'customer_slates_for_videos_id' ? 'Secondary Agency' : item}:&nbsp;
                                                                {
                                                                    (this.isDate(attributes[item])) ? dateTimeWithAMPM(attributes[item])
                                                                    :
                                                                    attributes[item]
                                                                }
															</span> <br />
														</React.Fragment>
													}
												})
											}
										</div>
									</div>
								</React.Fragment>)
							} else {
								allowPrint.push('created_at', 'process_date', 'ship_status_id', 'shipping_method_id');
								return <div className='text-left'>
									{Object.keys(newValue.attributes).map(item => {
										if (allowPrint.includes(item)) {
											return (<React.Fragment>
												<span>{item}:&nbsp;
												{
													(item == 'created_at' || item == 'process_date') ? dateTimeWithAMPM(newValue.attributes[item])
													:
													newValue.attributes[item]
												}
												</span> <br />
											</React.Fragment>)
										}
									})}
								</div>
							}
						}
					}
				]
			}
		];
		return (
			<div className="content">
				<Grid fluid>
					<Row>
						<Col md={12}>
							<Card
								ctTableResponsive
								content={
									<div>
										<Grid fluid>
											<div className="row">
												<Col md={12}>
													<div className="secHeading">Order History</div>
												</Col>

												<Col md={4} >
													<div className='row flexElem flexResponsive align-items-center' >
														<div className="col-md-6" >
															{
																this.dateElement('Date From', 'fromDate', fromDate !== '' && moment(fromDate, 'YYYY-MM-DD'), [{ before_or_equal: moment(this.state.toDate) }])
															}
														</div>
													</div>
												</Col>
												<Col md={4} >
													<div className='row flexElem flexResponsive align-items-center' >
														<div className="col-md-6" >
															{
																this.dateElement('Date To', 'toDate', toDate !== '' && moment(toDate, 'YYYY-MM-DD'), [{ after_or_equal: moment(this.state.fromDate) }])
															}
														</div>

													</div>
												</Col>
												{/* <Col md={12} className="mt-md">

													<ReactTable
														ref={this.table}
														showPagination={false}
														noDataText='No web log report found'
														data={OrderLogData}
														pages={REPORTSpages}
														columns={columns}
														defaultPageSize={10}
														loading={loadingOrder}
														className="-striped listing"
														loadingText={'Loading...'}
														pageData={this.reportsPaginationData}
														manual
														onFetchData={(state, instance) => {

															var sort = state.sorted.length === 0 ? '' : state.sorted[0].id + ',desc:' + state.sorted[0].desc;
															state.pageData(state.page + 1, sort, state.pageSize);
														}}
													/>

												</Col> */}
												<Pagination
													ref={(p) => this.pagination = p}
													showPagination={false}
													columns={columns}
													pageSize={10}
													noDataText='No web log report found'
													getDataCall={this.paginationCall}
													filterView={false}
													// downloadData={true}
													// downloadFileName={'Orders'}
													// lowerContent = {null}
													// filterPlaceHolder = {'Orders'}
													getRowProps={this.getRowProps}
												// showAllToggle={true}
												/>
											</div>
										</Grid>
									</div>
								} />
						</Col>
					</Row>
				</Grid>
			</div>
		);
	}
}

const mapDispatchToProps = dispatch => {
	return ({
		getReportsCall: (value, fromDate, toDate, page, pageSize, sorted) => { return dispatch(getOrderLog(value, fromDate, toDate, page, pageSize, sorted)) },//example function
	});
};

function mapStateToProps(state) {
	const { OrderLogData, REPORTSpages, REPORTScurrent_page, loadingOrder } = state.reportReducer;
	return {
		OrderLogData, REPORTSpages, REPORTScurrent_page, loadingOrder
	};
}


const OrderLog = connect(mapStateToProps, mapDispatchToProps)(OrderLogPage);
export default OrderLog;
