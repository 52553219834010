import { SC } from "helper/ServerCall";

export const itemService = {
    getItems,
    getItemsByCategory,
    createOrderAssignedItemsWithType,
    getItemPagination,
    storeItem,
    editItem,
    updateItem,
    deleteItem,
    getItemsByTypeByLoction,
    unassignOrderShippingItem,
    getReorderEntryItems
}

function getItems(type) {
    if (type)
        return SC.getCall(`getItemsByType/${type}`);
    else
        return SC.getCall(`getItemsByType`);
}

function getItemsByCategory(cat) {
    return SC.getCall(`getItemsByCategory/${cat}`);
}

function createOrderAssignedItemsWithType(data, type, shippingItemId) {
    return SC.postCall('createOrderAssignedItemsWithTypeWithBarcode/' + shippingItemId + '/' + type, data);
}

function getItemPagination(page, filter, sort, pageSize) {
    return SC.getCall('item?page=' + page + '&filter=' + filter + '&sort=' + sort + '&pageSize=' + pageSize);
}
function storeItem(ItemData) {
    return SC.postCall('items', ItemData);
}
function editItem(itemId) {
    return SC.getCall(`items/${itemId}`);
}
function updateItem(data, id) {
    return SC.putCall(`items/${id}`, data);
}
function deleteItem(ItemId) {
    return SC.deleteCall('items/' + ItemId);
}
function getItemsByTypeByLoction(type, location) {
    return SC.getCall(`getItemsByTypeByLoction/${type}/${location}`);
}
function unassignOrderShippingItem(orderShippingId, type) {
    return SC.deleteCall(`deleteOrderAssignedItem/${orderShippingId}/${type}`);
}

function getReorderEntryItems() {
    return SC.getCall(`getReorderEntryItems`);
}