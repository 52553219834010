export function hasPermission(permission) {
    // return authorization header with jwt token
    let AdminUser = JSON.parse(localStorage.getItem('user'));

    if (AdminUser && AdminUser.token) {
    	if ( Array.isArray(permission) ) {
			for (var i in permission) {
				var temp = permission[i];
				if (AdminUser.user.role_permissions.includes(temp)) {
					return true;
				}
			}
    	} else {
	    	if (AdminUser.user.role_permissions.includes(permission)) {
	        	return true;
	    	}

    	}
    } else {
        return false;
    }
}
