import { adminConstants } from '../constant/admin-constant';
import { alertActions } from './alert-actions';
import { history } from '../../helper/history';
import { agenciesService } from '../../services/agency';
import { frontEndUserConstants } from '../constant/front-end-constant';
import { generalActions } from './generalActions';

export function getAgenciesPagination(page, filter, sort, pageSize, filterCompanyType, filterProgramType, searchData) {
    return dispatch => {
        // dispatch(request())
        return agenciesService.getAgenciesPagination(page, filter, sort, pageSize, filterCompanyType, filterProgramType, searchData)
            .then(
                response => {
                    return response;
                },
                err => {
                    return Promise.reject(err);
                }
            );
    };
}
export function getAllAgencies() {
    return dispatch => {
        dispatch(request())
        agenciesService.getAllAgencies()
            .then(
                response => {
                    dispatch(generalActions.success(adminConstants.GELL_ALL_AGENCY_USER_SUCCESS, response));
                },
                err => {
                    dispatch(generalActions.failure(adminConstants.GELL_ALL_AGENCY_USER_FAILURE, err));
                    dispatch(alertActions.error(err));
                }
            );
    };

    function request() { return { type: adminConstants.GELL_ALL_AGENCY_USER_REQUEST } }
    // function success(response) { return { type: adminConstants.GELL_ALL_AGENCY_USER_SUCCESS, payload: response } }
    // function failure(error) { return { type: adminConstants.GELL_ALL_AGENCY_USER_FAILURE, payload: error } }
}
export function getAllAgenciesPublishable() {
    return dispatch => {
        dispatch(request())
        agenciesService.getAllAgenciesPublishable()
            .then(
                response => {
                    dispatch(generalActions.success(adminConstants.GET_ALL_AGENCY_PUBLISH_ABLE_USER_SUCCESS, response));
                },
                err => {
                    dispatch(generalActions.failure(adminConstants.GET_ALL_AGENCY_PUBLISH_ABLE_USER_FAILURE, err));
                    dispatch(alertActions.error(err));
                }
            );
    };

    function request() { return { type: adminConstants.GET_ALL_AGENCY_PUBLISH_ABLE_USER_REQUEST } }
    // function success(response) { return { type: adminConstants.GET_ALL_AGENCY_USER_SUCCESS, payload: response } }
    // function failure(error) { return { type: adminConstants.GET_ALL_AGENCY_USER_FAILURE, payload: error } }
}
export function storeAgency(AgencyData) {
    return dispatch => {
        // dispatch(request());
        agenciesService.storeAgency(AgencyData)
            .then(
                response => {
                    history.push('/Admin/agencies');
                    dispatch(alertActions.success(response.message));
                },
                err => {
                    dispatch(alertActions.error(err));
                }
            );
    }
}


export function getAgency(id) {
    return dispatch => {
        dispatch(request());
        agenciesService.getAgency(id)
            .then(
                Agency => {
                    dispatch(generalActions.success(adminConstants.GET_AGENCY_SUCCESS, Agency));
                },
                err => {
                    dispatch(generalActions.failure(adminConstants.GET_AGENCY_FAILURE, err));
                    dispatch(alertActions.error(err));
                }
            );
    }

    function request() { return { type: adminConstants.GET_AGENCY_REQUEST } }
    // function success(Agency) { return { type: adminConstants.GET_AGENCY_SUCCESS, payload: Agency } }
    // function failure(error) { return { type: adminConstants.GET_AGENCY_FAILURE, payload: error } }
}

export function editAgency(AgencyData, agencyId) {

    return dispatch => {
        return agenciesService.editAgency(AgencyData, agencyId)
            .then(
                response => {
                    dispatch(alertActions.success(response.message));
                    return response;
                },
                err => {
                    dispatch(alertActions.error(err));
                }
            );
    }
}

export function deleteAgency(agencyId) {
    return dispatch => {
        return agenciesService.deleteAgency(agencyId)
            .then(
                response => {
                    dispatch(alertActions.success(response.message));
                    return response;

                },
                err => {
                    dispatch(alertActions.error(err.message));
                    return Promise.reject(err);
                }
            );
    }

    // function request() { return { type: adminConstants.DELETE_AGENCY_REQUEST } }
    // function success(response) { return { type: adminConstants.DELETE_AGENCY_SUCCESS, payload: response } }
    // function failure(error) { return { type: adminConstants.DELETE_AGENCY_FAILURE, payload: error } }
}
export function changeAgencyStatus(agencyId, settingData) {
    return dispatch => {
        dispatch(request());
        agenciesService.changeAgencyStatus(agencyId, settingData)
            .then(
                response => {
                    dispatch(generalActions.success(adminConstants.CHANGE_AGENC_STATUS_SUCCESS, response));
                    dispatch(alertActions.success(response.message));
                },
                err => {
                    dispatch(generalActions.failure(adminConstants.CHANGE_AGENC_STATUS_FAILURE, err));
                    dispatch(alertActions.error(err.message));
                }
            );
    }

    function request() { return { type: adminConstants.CHANGE_AGENCY_STATUS_REQUEST } }
    // function success(response) { return { type: adminConstants.CHANGE_AGENC_STATUS_SUCCESS, payload: response } }
    // function failure(error) { return { type: adminConstants.CHANGE_AGENC_STATUS_FAILURE, payload: error } }
}

export function getAgencyOrders(page, filter, sort, pageSize, agencyId, advancedFilters) {
    return dispatch => {
        return agenciesService.getAgencyOrders(page, filter, sort, pageSize, agencyId, advancedFilters)
            .then(
                response => {
                    return response;
                    // dispatch(success(response))
                },
                err => {
                    return Promise.reject(err);
                    // dispatch(generalActions.failure(error))
                }
            );
    }

    // function success(response) { return { type: frontEndUserConstants.GET_AGENCY_ORDERS_SUCCESS, payload: response } }
    // function failure(error) { return { type: frontEndUserConstants.GET_AGENCY_ORDERS_FAILURE, payload: error } }
}
export function getAgencyInventoryPagination(page, filter, sort, pageSize, where) {
    return dispatch => {
        dispatch(request())
        return agenciesService.getAgencyInventoryPagination(page, filter, sort, pageSize, where)
            .then(
                response => {
                    dispatch(generalActions.success(response));
                    return response;
                },
                err => {
                    dispatch(generalActions.failure(adminConstants.GET_INVENTORY_LIST_FAILURE, err));
                    dispatch(alertActions.error(err));
                }
            );
    };

    function request() { return { type: adminConstants.GET_INVENTORY_LIST_REQUEST } }
    // function success(response) { return { type: adminConstants.GET_INVENTORY_LIST_SUCCESS, payload: response } }
    // function failure(error) { return { type: adminConstants.GET_INVENTORY_LIST_FAILURE, payload: error } }
}

export function storeAgencyInventory(InvData) {
    return dispatch => {
        dispatch(request());
        return agenciesService.storeAgencyInventory(InvData)
            .then(
                response => {
                    dispatch(generalActions.success(adminConstants.STORE_INVENTORY_SUCCESS, response));
                    dispatch(alertActions.success(response.message));
                    return response;
                },
                err => {
                    dispatch(generalActions.failure(adminConstants.STORE_INVENTORY_FAILURE, err));
                    dispatch(alertActions.error(err));
                    return Promise.reject(err);
                }
            );
    }

    function request() { return { type: adminConstants.STORE_INVENTORY_REQUEST } }
    // function success(response) { return { type: adminConstants.STORE_INVENTORY_SUCCESS, payload: response } }
    // function failure(error) { return { type: adminConstants.STORE_INVENTORY_FAILURE, payload: error } }
}
export function editAgencyInventory(invId) {
    return dispatch => {
        dispatch(request());
        agenciesService.editAgencyInventory(invId)
            .then(
                response => {
                    dispatch(generalActions.success(adminConstants.GET_INVENTORY_SUCCESS, response));
                },
                err => {
                    dispatch(generalActions.failure(adminConstants.GET_INVENTORY_FAILURE, err));
                    dispatch(alertActions.error(err));
                }
            );
    }

    function request() { return { type: adminConstants.GET_INVENTORY_REQUEST } }
    // function success(response) { return { type: adminConstants.GET_INVENTORY_SUCCESS, payload: response } }
    // function failure(error) { return { type: adminConstants.GET_INVENTORY_FAILURE, payload: error } }
}
export function updateAgencyInventory(data, id, type) {
    return dispatch => {
        dispatch(request());
        return agenciesService.updateAgencyInventory(data, id, type)
            .then(
                response => {
                    dispatch(generalActions.success(adminConstants.UPDATE_INVENTORY_SUCCESS, response));
                    dispatch(alertActions.success(response.message));
                    return response;
                },
                err => {
                    dispatch(generalActions.failure(adminConstants.UPDATE_INVENTORY_FAILURE, err));
                    dispatch(alertActions.error(err));
                    return Promise.reject(err);
                }
            );
    }

    function request() { return { type: adminConstants.UPDATE_INVENTORY_REQUEST } }
    // function success(response) { return { type: adminConstants.UPDATE_INVENTORY_SUCCESS, payload: response } }
    // function failure(error) { return { type: adminConstants.UPDATE_INVENTORY_FAILURE, payload: error } }
}
export function deleteAgencyInventory(InvId) {
    return dispatch => {
        dispatch(request());
        return agenciesService.deleteAgencyInventory(InvId)
            .then(
                response => {
                    dispatch(generalActions.success(adminConstants.DELETE_INVENTORY_SUCCESS, response));
                    dispatch(alertActions.success(response.message));
                    return response;
                },
                err => {
                    dispatch(generalActions.failure(adminConstants.DELETE_INVENTORY_FAILURE, err));
                    return dispatch(alertActions.error(err));
                }
            );
    }

    function request() { return { type: adminConstants.DELETE_INVENTORY_REQUEST } }
    // function success(response) { return { type: adminConstants.DELETE_INVENTORY_SUCCESS, payload: response } }
    // function failure(error) { return { type: adminConstants.DELETE_INVENTORY_FAILURE, payload: error } }
}
export function getAgencyLogo(agencyId) {

    return dispatch => {
        return agenciesService.getAgencyLogo(agencyId)
            .then(
                response => {
                    dispatch(generalActions.success(frontEndUserConstants.GET_AGENCY_LOGO_SUCCESS, response));
                    // console.log('response', response);
                    return response;
                },
                err => {
                    dispatch(generalActions.failure(frontEndUserConstants.GET_AGENCY_LOGO_FAILURE, err));
                    dispatch(alertActions.error(err));
                }
            );
    }
    // function success(response) { return { type: frontEndUserConstants.GET_AGENCY_LOGO_SUCCESS, payload: response } }
    // function failure(error) { return { type: frontEndUserConstants.GET_AGENCY_LOGO_FAILURE, payload: error } }
}
export function getInventoryLocationStats() {
    return dispatch => {
        dispatch(request())
        agenciesService.getInventoryLocationStats()
            .then(
                response => {
                    dispatch(generalActions.success(adminConstants.GELL_INVENTORY_LOCATION_STATS_SUCCESS, response));
                },
                err => {
                    dispatch(generalActions.failure(adminConstants.GELL_INVENTORY_LOCATION_STATS_FAILURE, err));
                    dispatch(alertActions.error(err));
                }
            );
    };

    function request() { return { type: adminConstants.GELL_INVENTORY_LOCATION_STATS_REQUEST } }
    // function success(response) { return { type: adminConstants.GELL_INVENTORY_LOCATION_STATS_SUCCESS, payload: response } }
    // function failure(error) { return { type: adminConstants.GELL_INVENTORY_LOCATION_STATS_FAILURE, payload: error } }
}
export function updateInventoryLocation(id, data, keyName, orderShippingId = null, type = null) {
    return dispatch => {
        dispatch(request());
        return agenciesService.updateInventoryLocation(id, data, keyName, orderShippingId, type)
            .then(
                response => {
                    console.log('response', response.data.key);
                    if (response.data.key === 'assignedItems') {
                        dispatch(generalActions.success(adminConstants.STORE_ORDER_ASSIGNED_ITEMS_SUCCESS, response.data));
                    } else {
                        dispatch(generalActions.successadminConstants.UPDATE_INVENTORY_LOCATION_SUCCESS, (response));
                    }
                    dispatch(alertActions.success(response.message));
                    return response;
                },
                err => {
                    dispatch(generalActions.failure(adminConstants.UPDATE_INVENTORY_LOCATION_FAILURE, err));
                    dispatch(alertActions.error(err));
                    return Promise.reject(err);
                }
            );
    }

    function request() { return { type: adminConstants.UPDATE_INVENTORY_LOCATION_REQUEST } }
    // function success(response) { return { type: adminConstants.UPDATE_INVENTORY_LOCATION_SUCCESS, payload: response } }
    // function successAssignedItems(response) { return { type: adminConstants.STORE_ORDER_ASSIGNED_ITEMS_SUCCESS, payload: response } }
    // function failure(error) { return { type: adminConstants.UPDATE_INVENTORY_LOCATION_FAILURE, payload: error } }
}
export function flyersAllowedAgencies() {
    return dispatch => {
        return agenciesService.flyersAllowedAgencies().then(
            res => {
                dispatch(generalActions.success(adminConstants.GET_PREAWARENESS_AGENCIES_SUCCESS, res))
                return res;
            },
            err => {
                return err
            }
        )
    }
}
export function flyersNotAllowedAgencies() {
    return dispatch => {
        return agenciesService.flyersNotAllowedAgencies().then(
            res => {
                dispatch(generalActions.success(adminConstants.GET_PREAWARENESS_AGENCIES_SUCCESS, res))
                return res;
            },
            err => {
                return err
            }
        )
    }
}
export function toggleAllowStatus(data) {
    return dispatch => {
        return agenciesService.toggleAllowStatus(data).then(
            res => {
                return res
            }
        )
    }
}
export function saveAgencySearchParams(data) {
    return dispatch => {
        dispatch(generalActions.success(adminConstants.SAVE_AGENCY_SEARCH_PARAMS, { data: data }))
    }
}
