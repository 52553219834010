import React, { Component } from "react";

class showPassword extends Component {
	render(){
		console.log(this.props.user);
		return(
			<div className='custom-ui'>
	            <div className="react-confirm-alert-body text-center">
	              	<p className="marginFromTopPopUp" >{ this.props.description } <strong>{ this.props.user.enc_password }</strong> </p>
	              	<div className="react-confirm-alert-button-group alignFlexButtons">
	                	<button onClick={this.props.onClosePro} className="btn btn-danger"><i className="pe-7s-close-circle"></i> Close</button>
	                	
	              </div>
	            </div>
	        </div>
		);
	};
}



export default showPassword;
