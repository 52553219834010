import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getAllAddtionalItems, deleteAdditionalService } from 'redux/actions/settings-actions';
import { Grid, Row, Col } from 'react-bootstrap'
import Card from 'components/Card/Card';
import { toggleModal } from 'helper/helperFunctions'
import AdditionalServicesForm from './AdditionalServicesForm';
import ThemeModal from 'components/ThemeComponents/ThemeModal';
import { confirmAlert } from 'react-confirm-alert';
import ConfirmAlertForDelete from 'components/ThemeComponents/confirmAlertForDelete';
import Pagination from 'components/ThemeComponents/Pagination';
class AdditionalServices extends Component {

    constructor(props) {
        super(props)

        this.state = {
            filtered: '',
            pageSize: 10,
            createAdditionalServicesModal: false,
            editAdditionalServicesModal: false,
            editableItem: {}
        }
        this.inputChangeHandler = this.inputChangeHandler.bind(this);
        this.deleteAdditionalService = this.deleteAdditionalService.bind(this);
        // this.props.onGetAllAddtionalItemsCall();
    }

    inputChangeHandler(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    deleteAdditionalService(id) {
        this.props.deleteAdditionalServiceCall(id).then(res => {
            this.pagination.dataCall();
        });
    }

    confirmDeleteHandler(id) {
        confirmAlert({
            customUI: ({ onClose }) => <ConfirmAlertForDelete typeName='Additional Service' onClosePro={onClose} deleteType={() => this.deleteAdditionalService(id)} />
        })
    }

    paginationCall = (data) => {
        return this.props.onGetAllAddtionalItemsCall(data.page, data.filter, data.sort, data.pageSize);
    }

    render() {
        // const { addtionalServcies } = this.props;
        const columns = [
            {
                Header: 'Additional Services',
                headerClassName: 'headerPagination',
                columns: [
                    {
                        Header: 'Title',
                        accessor: 'title'
                    },
                    {
                        Header: 'Price',
                        accessor: 'price'
                    },
                    {
                        Header: 'Taxable',
                        accessor: 'taxable',
                        Cell: ({ value }) => {
                            return value === 1 ? 'YES' : 'NO'
                        }
                    },
                    {
                        Header: 'DVD',
                        accessor: 'dvd',
                        // Cell: ({ value }) => {
                        //     return value === 1 ? 'YES' : 'NO'
                        // }
                    },
                    {
                        Header: 'Actions',
                        className: 'action-center',
                        sortable: false,
                        Cell: ({ original, index }) => {
                            return (
                                <React.Fragment>
                                    <button
                                        name='editAdditionalServicesModal'
                                        onClick={() => {
                                            this.setState({
                                                editableItem: {
                                                    id: original.id,
                                                    index: index
                                                }
                                            }, toggleModal.bind(this, 'editAdditionalServicesModal'))
                                        }}
                                        type='button'
                                        className="orderDetailsAction editIcon" >&nbsp;</button>
                                    <button onClick={() => this.confirmDeleteHandler(original.id)} type='button' className="orderDetailsAction ml-md deleteIcon" >&nbsp;</button>
                                </React.Fragment>
                            )
                        }
                    }
                ]
            }
        ]
        return (
            <div className='content'>
                <Grid fluid>
                    <Row>
                        <Col md={12} >
                            <Card title='Additional Services' content={
                                <React.Fragment>
                                    <button onClick={toggleModal.bind(this, 'createAdditionalServicesModal')} name='createAdditionalServicesModal' className='backButton my-20 pt-sm no_radius pb-sm primary text-xs btn btn-lg btn-info btn btn-lg btn-info  btn btn-default' >Create Additional Service</button>
                                    <div className='w-25 my-20' >
                                        {/* <input onChange={this.inputChangeHandler} name='filtered' type='text' className='form-control' placeholder='Search...' /> */}
                                    </div>
                                    {/* <ReactTable
                                        manual
                                        defaultPageSize={this.state.pageSize}
                                        columns={columns}
                                        filtered={this.state.filtered}
                                        data={addtionalServcies.data}
                                        onFetchData={(state) => {
                                            var sort = state.sorted.length === 0 ? '' : state.sorted[0].id + ',desc:' + state.sorted[0].desc;
                                            this.props.onGetAllAddtionalItemsCall(state.page + 1, state.filtered, sort, state.pageSize);
                                        }}
                                    /> */}
                                    <div className='row'>
                                        <Pagination
                                            ref={(p) => this.pagination = p}
                                            showPagination={false}
                                            columns={columns}
                                            pageSize={10}
                                            getDataCall={this.paginationCall}
                                            noDataText='No Additional Services'
                                            filterView={true}
                                            // downloadData={true}
                                            // downloadFileName={'Orders'}
                                            // lowerContent = {null}
                                            filterPlaceHolder={'Additional Service'}
                                            getRowProps={this.getRowProps}
                                        // showAllToggle={true}
                                        />
                                    </div>
                                </React.Fragment>
                            } />
                        </Col>
                    </Row>
                </Grid>
                <ThemeModal size='lg' title='Add Additional Service' show={this.state.createAdditionalServicesModal} hide={toggleModal.bind(this, 'createAdditionalServicesModal')} >
                    <AdditionalServicesForm getPagination={this.pagination ? this.pagination.dataCall : null} formType='add' hide={toggleModal.bind(this, 'createAdditionalServicesModal')} />
                </ThemeModal>
                <ThemeModal size='lg' title='Edit Additional Service' show={this.state.editAdditionalServicesModal} hide={toggleModal.bind(this, 'editAdditionalServicesModal')}>
                    <AdditionalServicesForm getPagination={this.pagination ? this.pagination.dataCall : null} hide={toggleModal.bind(this, 'editAdditionalServicesModal')} formType='edit' editableItem={this.state.editableItem} />
                </ThemeModal>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { addtionalServcies } = state.settingsReducer;
    return {
        addtionalServcies
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onGetAllAddtionalItemsCall: (page, filter, sort, pageSize) => { return dispatch(getAllAddtionalItems(page, filter, sort, pageSize)) },
        deleteAdditionalServiceCall: (id) => { return dispatch(deleteAdditionalService(id)) }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdditionalServices)