import React, { useState, useEffect } from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import _ from "lodash";
import TreeView from '@mui/lab/TreeView';
import TreeItem from '@mui/lab/TreeItem';
import { MdClose } from 'react-icons/md';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import DoNotDisturbOnOutlinedIcon from '@mui/icons-material/DoNotDisturbOnOutlined';
import { Table } from "react-bootstrap";
import { dateFormatMyDocuments } from 'helper/helperFunctions';
const AgencyFolder = (props) => {
    const [FilesArray, setFilesArray] = useState(props.item.data);

    useEffect(()=>{
        setFilesArray(props.item.data)
        },
        [props.item.data]
    )

    let itemId = props.item.folder_id
    var DeleteButtonsStyle = {
        position: "relative",
        left: props.Small ? "90" : "93%",
        top: props.Small ? "35px" : "33px",
        zIndex: "1000",
        cursor: "pointer",
    }

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
      };
    function handleOnDragEnd(result) {

        if (!result.destination) return;


        const items = reorder(
            FilesArray,
            result.source.index,
            result.destination.index
          );

        setFilesArray(items);
        //Backendupdate
        let changeddata = _.cloneDeep(props.item)
        const updatearray = (changeddata) =>{
            changeddata.data = FilesArray
        }
      }


    return (
        <>
            <div>
            <TreeView
                aria-label="multi-select"
                className='parent parentAdmin agencyFolder'
                expanded={props.ex}
                onNodeToggle={(e, nodeId) => {props.onFolderClick(e, props.item, nodeId)}}
                defaultCollapseIcon={<DoNotDisturbOnOutlinedIcon />}
                defaultExpandIcon={<AddBoxOutlinedIcon />}
                >
                <TreeItem key={itemId.toString()}  nodeId={itemId.toString()} alt={itemId++} label={props.item.folder_name}>

                    <div className="flexElem respFlex spaceBetween form_tile_row">
                            <div className="col-md-12">
                                <div className="table-responsive">
                                    <table className="table_tile mb-md">
                                        <thead>
                                            <tr className="border-bottom">
                                                <th width="35%">File Name</th>
                                                <th width="25%">Uploaded Date</th>
                                                <th width="20%">Status</th>
                                                <th width="20%">Action</th>
                                            </tr>
                                        </thead>
                                        {
                                            props.dragable ?
                                            <DragDropContext onDragEnd={handleOnDragEnd}>
                                                <Droppable droppableId="droppable" >
                                                    {(provided) => (
                                                        <tbody {...provided.droppableProps} ref={provided.innerRef}>
                                                            {
                                                                FilesArray ?
                                                                FilesArray.map((files,key,index) => {

                                                                // console.log(key.toString(),"This is my key")
                                                                    return (
                                                                        <Draggable key={key} draggableId={key.toString()} index={key}  >
                                                                            {(provided) => (
                                                                                <tr key={key}  ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                                <td><a style={{ wordBreak: 'break-all' }} className='blue_link' target="_blank" href={files.file_path} rel="noopener noreferrer">{files.original_file_name }</a></td>
                                                                                <td>{dateFormatMyDocuments(files.created_at)}</td>
                                                                                <td>{files.is_scheduled === 0 ? 'Sent' : 'Scheduled'}</td>
                                                                                <td>
                                                                                    {
                                                                                        props.noDelete ?
                                                                                        <button onClick= {props.confirmResourceDelete.bind(this, key, files.id)} type='button' className="linkBtn text_14 text-danger"><MdClose /></button>
                                                                                        :''
                                                                                    }
                                                                                    <a className='ml-md' href={files.file_path}><i className='fa fa-download'></i></a>
                                                                                    {/* <button className="orderDetailsAction deleteIcon" onClick={props.confirmResourceDelete.bind(this, key, files.id)} >&nbsp;</button> */}
                                                                                </td>

                                                                                </tr>
                                                                            )}
                                                                        </Draggable>
                                                                )})
                                                                :
                                                                ''
                                                            }
                                                            {provided.placeholder}
                                                        </tbody>
                                                    )}
                                                </Droppable>
                                            </DragDropContext>
                                            :
                                            <tbody>
                                                {
                                                    FilesArray ?
                                                    FilesArray.map((files,key,index) => {

                                                    // console.log(key.toString(),"This is my key")
                                                        return (
                                                                    <tr key={key} >
                                                                    <td><a style={{ wordBreak: 'break-all' }} className='blue_link' target="_blank" href={files.file_path} rel="noopener noreferrer">{files.original_file_name }</a></td>
                                                                    <td>{dateFormatMyDocuments(files.created_at)}</td>
                                                                    <td>{files.is_scheduled === 0 ? 'Sent' : 'Scheduled'}</td>
                                                                    <td>
                                                                        {
                                                                            props.noDelete ?
                                                                            <button onClick= {props.confirmResourceDelete.bind(this, key, files.id)} type='button' className="linkBtn text_14 text-danger"><MdClose /></button>
                                                                            :''
                                                                        }
                                                                        <a className='ml-md' href={files.file_path}><i className='fa fa-download'></i></a>
                                                                        {/* <button className="orderDetailsAction deleteIcon" onClick={props.confirmResourceDelete.bind(this, key, files.id)} >&nbsp;</button> */}
                                                                    </td>

                                                                    </tr>
                                                    )})
                                                    :
                                                    ''
                                                }
                                            </tbody>
                                        }
                                    </table>
                                </div>
                            </div>
                        </div>
                </TreeItem>
            </TreeView>

            </div>
        </>

    )
}

export default AgencyFolder;
