import React, { Component } from "react";
import { connect } from 'react-redux';
import { getReceivingReports } from "redux/actions/report-actions";
import ReactValidator from "simple-react-validator";
import { Row, Col, Grid } from "react-bootstrap";
import { Card } from "components/Card/Card.jsx";
import { dateFormatMy, getFormattedDate } from 'helper/helperFunctions';
import Pagination from 'components/ThemeComponents/Pagination';
import { Link } from 'react-router-dom';
import { reportService } from "../../../services/report";
const moment = window.moment;

class OnHoldList extends Component {
	constructor(props) {
		super(props);
		this.validator = new ReactValidator();
		this.state = {

			numOfDays: 30,
			collapseUiSettings: true,
		}
		this.getOrderMissingAgencyResourceCallWithNumDays = this.getOrderMissingAgencyResourceCallWithNumDays.bind(this);
		this.getRowProps = this.getRowProps.bind(this);
	}
	getOrderMissingAgencyResourceCallWithNumDays(data) {
		data = { ...data, numOfDays: this.state.numOfDays };
		return reportService.getOrderOnHoldList(data);
	}
	getRowProps(rowData) {
		let data = {};
		if (parseInt(rowData.settings.finalize_by_editor.value, 10) === 1) {
			data = { color: 'red' }
		}
		if (rowData.order_dates.receive_date.value === "" && rowData.shippingItemsCase > 0) {
			data = { color: 'green' }
		}
		if (rowData.no_equipment_upload_only === 1) {
			data = { color: 'pink' }
		}
		if ((rowData.order_dates.receive_date.value !== "") && (parseInt(rowData.settings.finalize_by_editor.value, 10) === 0) && (parseInt(rowData.settings.on_hold.value, 10) === 1)) {
			if (moment(rowData.settings.photos_submitted_for_video_production.updated_at) < moment(rowData.order_dates.upload_cut_off_date.value)) {
				data = { color: 'blue' }

			}
		}
		return data;
	}

	render() {
		const columns = [
			{
				Header: "Report Testing",
				headerClassName: 'headerPagination',
				columns: [
					{
						Header: "Barcode",
						accessor: "barcode",
						className: "action-center",
						Cell: ({ value, original }) => {
							return <Link target='_blank' to={'/Admin/' + (original.reorder ? original.reorder : original.id) + '/order-edit'} className="blue_link">{value}</Link>
						},
						width: 200,
					},
                    {
						Header: "Agency",
						accessor: "agency.name",
						className: "action-center",
						sortable: false,
					},
                    {
						Header: "Put on Hold Date",
						accessor: "settings.on_hold",
						Cell: ({ value }) => (
							<div>
								{dateFormatMy(value.updated_at)}
							</div>
						),
						className: "action-center",
					},
					{
						Header: "Group Name",
						accessor: "group_name",
						className: "action-center",
						sortable: false,
					},
					{
						Header: "Contact Name",
						accessor: "group_leader_contact.first_name",
						className: "action-center",
						sortable: false,
					},
					{
						Header: "Departure Date",
						accessor: "order_dates.departure_date",
						Cell: ({ value }) => (
							<div>
								{dateFormatMy(value.value)}
							</div>
						),
						className: "action-center",
					},
					{
						Header: "Contact Log Last Entry",
                        accessor: 'comments',
                        className: 'action-center',
                        sortable: false,
                        Cell: ({ original }) => {
                            return(
                                <span>
                                    {
                                        original.comments.length > 0 && original.comments[0] && original.comments[0].updated_at ?
                                            getFormattedDate(original.comments[0].updated_at)
                                                :
                                                original.comments.length > 0 && original.comments[0] && original.comments[0].created_at ?
                                                getFormattedDate(original.comments[0].created_at)
                                                : ''

                                    }
                                </span>
                            )
                        }
					},
					{
						Header: "Contact log link",
						accessor: "id",
                        width: 120,
                        Cell: ({ value }) => (
                            <div>
                              <Link target="_blank" className="orderDetailsAction editIcon" to={'/Admin/' + value + '/order-edit?commentCategoryCode=ContactLog#commentTabs'}>&nbsp;</Link>
                            </div>
                          ),
						className: "action-center redText",
						sortable: false,
					},

					{
						Header: "Hold Comment",
						accessor: "holdComment",
						Cell: ({ value }) => (
							<div>
								{/* <span> */}
                                    {value}
                                {/* </span> */}
							</div>
						),
						className: "action-center justifyStart",
						sortable: false,
                        width: 180,
					},
					// {
					// 	Header: "Run Rename",
					// 	accessor: "rename",
					// 	className: "action-center",
					// 	sortable: false,
					// },
					// {
					// 	Header: "Number of Photos",
					// 	accessor: "originalImages",
					// 	className: "action-center",
					// 	sortable: false,
					// },
					// {
					// 	Header: "Submitted By",
					// 	accessor: "settings.photos_submitted_for_video_production.updated_by_user",
					// 	className: "action-center",
					// 	sortable: false,
                    //     Cell: ({ original, value }) => (
                    //         console.log("original", value),
					// 		<div>
					// 			{
                    //                 value.id === 91
                    //                     ?
                    //                     <span>System</span>
                    //                      :
                    //                       value.roles[0].label === "Photo Admin"
                    //                        ?
                    //                        <span>PA</span>
                    //                         :
                    //                          <span>GTV rep</span>

                    //             }
					// 		</div>
					// 	),
					// }
				]
			}
		];
		return (
			<div className="content">
				<Grid fluid>
					<Row>
						<Col md={12}>
							<Card
								ctTableResponsive
								content={
									<div>
										<Grid fluid>
											<div className="row">
                                                <Col md={12}>
                                                    <div className="secHeading">
                                                        ORDERS ON HOLD
                                                    </div>
												</Col>

												<Col md={12} className="mt-md">
													<Pagination
														ref={(p) => this.pagination = p}
														columns={columns}
														getDataCall={this.getOrderMissingAgencyResourceCallWithNumDays}
														filterView={false}
														getRowProps={this.getRowProps}
														pageSize={100}
													/>
												</Col>

											</div>
										</Grid>
									</div>
								} />
						</Col>
					</Row>
				</Grid>
			</div>
		);
	}
}

const mapDispatchToProps = dispatch => {
	return ({
		getReceivingReportsCall: (data) => { return dispatch(getReceivingReports(data)) },
	});
};

function mapStateToProps(state) {
	return {
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(OnHoldList);
