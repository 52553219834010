import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Grid,
  Row,
  Col,
  OverlayTrigger,
  Popover
} from "react-bootstrap";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Card } from "components/Card/Card.jsx";
import { getBrandsPagination, getBrand, deleteBrand, updateBrandStatus, handleBrandActiveStatus } from 'redux/actions/brand-actions';
// import { permissionActions } from 'redux/actions/permission-action';
import 'react-table/react-table.css';
import BrandForm from "./BrandForm";
// import { FormInputs } from "components/FormInputs/FormInputs.jsx";
import { hasPermission } from 'helper/hasPermission';
import Confirmalertfordelete from 'components/ThemeComponents/confirmAlertForDelete';
import { adminLabels } from 'redux/constant/admin-label-constant';
import OpenModalButton from 'components/ThemeComponents/openModelButton';
import { toggleModal, selectChangeHandler, returnTimeStamp, openModal, closeModalAfterApi } from 'helper/helperFunctions';
import { Link } from 'react-router-dom';
import { objectsConstants as OC } from 'redux/constant/objects-constant';
import ThemeModal from 'components/ThemeComponents/ThemeModal';
import MyImage from 'components/Upload/MyImage';
import Select from 'react-select'
import Pagination from '../../ThemeComponents/Pagination';
import { calendar } from '../../../assets/media/icons';
// var Modal = require('react-bootstrap-modal');
// import DownloadFile from 'components/ThemeComponents/downloadFile';

class BrandsListPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addModalBrand: this.props.addModalBrand,
      editModalBrand: this.props.editModalBrand,
      onCloseAfterApi: false,
      sorted: '',
      filtered: '',
      pageSize: 10,
      page: 0,
      brand_status: '1',
    };
    this.toggleModal = toggleModal.bind(this);
    this.filterSearchHandle = this.filterSearchHandle.bind(this);
    this.selectChangeHandler = selectChangeHandler.bind(this);
  };
  filterSearchHandle(e) {
    this.setState({
      'filtered': e.target.value
    });
  }
  deleteBrand = (userId) => {
    this.props.deleteBrandCall(userId).then(response => {
      this.pagination.dataCall();
    });
  }
  deleteConfirmBrand = (userId) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <Confirmalertfordelete typeName="Brand" description="Are you sure to delete the brand?" onClosePro={onClose} deleteType={() => this.deleteBrand(userId)} />
        )
      }
    })
  }
  handleBrandStatus = brandId => (e) => {
    this.props.handleBrandStatusCall(brandId, { is_master: e.target.checked ? true : false }).then(
      response => {
        this.pagination.dataCall();
      }
    );
  }

  handleBrandActiveStatus = (brandId) => {
    this.props.handleBrandActiveStatus(brandId).then(
      res => {
        this.pagination.dataCall();
      }
    );
  }
  brandsPaginationData = (page = this.state.page, filtered = this.state.filtered, sorted = this.state.sorted, pageSize = this.state.pageSize, status = this.state.brand_status) => {
    this.props.getBrandsPaginationCall(page, filtered, sorted, pageSize, status);
  }
  componentDidUpdate(prevProps, prevState) {
    if ((prevProps.editBrand !== this.props.editBrand) && this.props.editBrand) {
      this.setState({ editModalBrand: true });
    }
    if (prevProps.current_page !== this.props.current_page) {
      this.setState({ page: this.props.current_page })
    }
    if ((!prevState.addModalBrand && this.state.addModalBrand) || (!prevState.editModalBrand && this.state.editModalBrand)) {
      this.setState({ onCloseAfterApi: false })
    }
    if ((prevState.addModalBrand && !this.state.addModalBrand && this.state.onCloseAfterApi) || (prevState.editModalBrand && !this.state.editModalBrand && this.state.onCloseAfterApi)) {
      this.pagination.dataCall();
    }
    if (this.state.brand_status !== prevState.brand_status) {
      // this.brandsPaginationData();
      // this.table.state.onFetchData(this.table.state, this.table.state.instance)
      this.pagination.dataCall();
    }
  }

  paginationCall = (data) => {
    return this.props.getBrandsPaginationCall(data.page, data.filter, data.sort, data.pageSize, this.state.brand_status);
  }

  render() {
    const { /*filtered*/ } = this.state;
    // console.log('filtered', filtered);
    const { editBrand /*, brandsData, pages, loadingBrand*/ } = this.props;
    const columns = [
      {
        Header: "Brands Listing",
        headerClassName: 'headerPagination',
        columns: [
          {
            Header: "Brand Name",
            accessor: "title",
            className: "action-center"
          },
          {
            Header: "Domain Slug",
            accessor: "domain_slug",
            className: "action-center"
          },
          {
            Header: "Download",
            accessor: "resource_file",
            className: "action-center",
            Cell: ({ value }) => (
              <div>
                {
                  value &&
                  <MyImage alt={value.file_title} key={value.id} src={value.file_path + '?rand=' + returnTimeStamp(value.updated_at)} height="auto" width="100px" />
                }

              </div>
            ),
            sortable: false,
          },
          {
            Header: "Master",
            accessor: "is_master",
            Cell: ({ row }) => (
              <label className="container-check">
                &nbsp;
                <input
                  type="checkbox" name="is_master" className="checkbox disabled" value={row.is_master ? 1 : 0} onChange={this.handleBrandStatus(row.id)} checked={row.is_master ? 1 : 0}
                  disabled={row.is_master ? true : false} /><span className="checkmark"></span>
              </label>
            ),
            className: "action-center",
            sortable: false
          },
          {
            Header: "Active",
            accessor: "is_active",
            Cell: ({ value, original }) => {
              return (
                <label className="container-check">
                  &nbsp;
                  <input
                    type="checkbox" name="is_active" className="checkbox" value={original.is_active ? 1 : 0} onChange={() => this.handleBrandActiveStatus(original.id)} checked={original.is_active ? 1 : 0} /><span className="checkmark"></span>
                </label>
              )
            },
            className: "action-center",
            sortable: false
          },
          {
            Header: "Actions",
            accessor: "id",
            Cell: ({ value, original }) => (
              <div className='flexElem alignCenter'>
                <OverlayTrigger placement="bottom" overlay={<Popover id="tooltip">{hasPermission('brand-edit') ? 'Edit Brand' : adminLabels.DONT_ALLOW}</Popover>}>
                  <span>
                    {
                      hasPermission('brand-edit') &&
                      <button type='button' className="editIcon orderDetailsAction" onClick={() => this.props.getBrandCall(value)}>&nbsp;</button>
                    }
                    {
                      !hasPermission('brand-edit') &&
                      <button type='button' className="editIcon orderDetailsAction button_disabled" >&nbsp;</button>
                    }
                  </span>
                </OverlayTrigger>
                <OverlayTrigger placement="bottom" overlay={<Popover id="tooltip">More Buttons</Popover>}>
                  <span>
                    {
                      // hasPermission('faqs-list') &&
                      <Link to={'/Admin/brands/' + value + '/moreButtons'}><i className="fa fa-delicious" style={{ opacity: 0.8 }}></i></Link>

                    }
                    {/* {
                      !hasPermission('faqs-list') &&
                      <button type='button' className="button_disabled faqs_icon" >&nbsp;</button>
                    } */}
                  </span>
                </OverlayTrigger>
                <OverlayTrigger placement="bottom" overlay={<Popover id="tooltip">{hasPermission('faqs-list') ? 'Faqs List' : adminLabels.DONT_ALLOW}</Popover>}>
                  <span>
                    {
                      hasPermission('faqs-list') &&
                      <Link to={'/Admin/brands/' + value + '/faqs'} className="faqs_icon">&nbsp;</Link>
                    }
                    {
                      !hasPermission('faqs-list') &&
                      <button type='button' className="button_disabled faqs_icon" >&nbsp;</button>
                    }
                  </span>
                </OverlayTrigger>
                <OverlayTrigger placement="bottom" overlay={<Popover id="tooltip">{hasPermission('transmittal-templates-list') ? 'Customer Service Templates' : adminLabels.DONT_ALLOW}</Popover>}>
                  <span>
                    {
                      hasPermission('transmittal-templates-list') &&
                      <Link to={'/Admin/brands/' + value + '/transmittal-templates'} className=" generalSettings_icon"></Link>
                    }
                    {
                      !hasPermission('transmittal-templates-list') &&
                      <button type='button' className="button_disabled generalSettings_icon" >&nbsp;</button>
                    }
                  </span>
                </OverlayTrigger>
                <OverlayTrigger placement="bottom" overlay={<Popover id="tooltip">{hasPermission('app-setting-list') ? 'App Setting List' : adminLabels.DONT_ALLOW}</Popover>}>
                  <span>
                    {
                      hasPermission('app-setting-list') &&
                      <Link to={'/Admin/brands/' + value + '/app-setting'} className="appSettings_icon"></Link>
                    }
                    {
                      !hasPermission('app-setting-list') &&
                      <button type='button' className="button_disabled appSettings_icon" >&nbsp;</button>
                    }
                  </span>
                </OverlayTrigger>
                {
                  original.has_itinerary ?
                    <OverlayTrigger placement="bottom" overlay={<Popover id="tooltip">{hasPermission('app-setting-list') ? 'Create Itinerary' : adminLabels.DONT_ALLOW}</Popover>}>
                      <span style={{ paddingLeft: '10px' }}>
                        {
                          hasPermission('app-setting-list') &&
                          <Link to={`/Admin/create-itinerary/${value}`}><img src={calendar} height={18} /></Link>
                        }
                        {
                          !hasPermission('app-setting-list') &&
                          <button type='button' className="button_disabled appSettings_icon" >&nbsp;</button>
                        }
                      </span>
                    </OverlayTrigger>
                    : ''
                }
              </div>
            ),
            className: "action-center",
            sortable: false
          }
        ]
      }
    ];
    const options = [
      {
        value: '',
        label: 'All'
      },
      {
        value: '1',
        label: 'Active'
      },
      {
        value: '0',
        label: 'In-Active'
      }
    ]
    return (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                ctTableResponsive
                content={
                  <div>
                    <Grid fluid>
                      <Row>
                        <Col md={12}>
                          <div className="secHeading">
                            Brands List
                          </div>
                        </Col>
                        <Col md={12}>
                          <div className="flexElem flexResponsive" style={{ "alignItems": "flex-start", "justifyContent": "space-between" }}>
                            <div className="flexElem">
                              <OpenModalButton
                                openModal={hasPermission('brand-create') ? openModal.bind(this, "addModalBrand") : null}
                                classButton={['backButton pt-sm no_radius pb-sm primary mt-none btn-block btn-info btn mr-md', !hasPermission('brand-create') ? 'button_disabled' : '']}
                                buttonName="Add Brand"
                                tooltipText={hasPermission('brand-create') ? 'Add Brand' : adminLabels.DONT_ALLOW}
                                classIcon={['fa', 'fa-plus']}
                              />
                              <Select
                                isSearchable={false}
                                isMulti={false}
                                styles={{
                                  container: (provided) => {
                                    return {
                                      ...provided,
                                      width: '100%',
                                      minWidth: '200px'
                                    }
                                  },
                                  menu: (provided) => {
                                    return {
                                      ...provided,
                                      width: '200px'
                                    }
                                  }
                                }}
                                value={
                                  options.map(item => {
                                    if (item.value === this.state.brand_status) {
                                      return item
                                    }
                                  })
                                }
                                options={options}
                                onChange={this.selectChangeHandler}
                                name='brand_status'
                              />
                              {/* <select value={this.state.brand_status} className='form-control ml-md' name="brand_status" id="" onChange={this.selectChangeHandler}>
                                <option value="1">Active</option>
                                <option value="0">In-Active</option>
                                <option value="">All</option>
                              </select> */}
                            </div>
                            {/* <div className="custom-search-input">
                              <FormInputs
                                ncols={["col-md-12"]}
                                onChange={this.filterSearchHandle}
                                proprieties={[
                                  {
                                    type: "text",
                                    bsClass: "form-control",
                                    placeholder: "Search Brands",
                                    onChange: this.filterSearchHandle,
                                    name: "filter"
                                  }
                                ]}
                              />
                            </div> */}
                          </div>
                        </Col>
                        {/* <Col md={12} className="mt-md">
                          <ReactTable
                            noDataText='No brand found'
                            data={brandsData}
                            pages={pages}
                            ref={table => this.table = table}
                            defaultSorted={
                              [
                                {
                                  id: 'title',
                                  desc: true
                                }
                              ]
                            }
                            columns={columns}
                            filtered={filtered}
                            defaultPageSize={10}
                            loading={loadingBrand}
                            className="-striped listing"
                            loadingText={'Loading...'}
                            pageData={this.brandsPaginationData}
                            manual
                            onFetchData={(state, instance) => {
                              console.log('filter', state.filter);
                              var sort = state.sorted.length === 0 ? '' : state.sorted[0].id + ',desc:' + state.sorted[0].desc;
                              state.pageData(state.page + 1, state.filtered, sort, state.pageSize);
                            }}
                          />
                        </Col> */}
                        <Pagination
                          ref={(p) => this.pagination = p}
                          showPagination={false}
                          noDataText='No brands found'
                          columns={columns}
                          pageSize={20}
                          getDataCall={this.paginationCall}
                          filterView={true}
                          defaultSorted={
                            [
                              {
                                id: 'title',
                                desc: true
                              }
                            ]
                          }
                          // downloadData={true}
                          // downloadFileName={'Orders'}
                          // lowerContent = {null}
                          filterPlaceHolder={'Brands'}
                          getRowProps={this.getRowProps}
                        // showAllToggle={true}
                        />
                      </Row>
                    </Grid>

                    {/*Add Brand Modal Start*/}
                    <ThemeModal modalClass="" title='Add Brand' show={this.state.addModalBrand} hide={() => { this.toggleModal('addModalBrand') }} >
                      <BrandForm
                        formTypePro="add"
                        brandPro={OC.BRAND}
                        closeModel={closeModalAfterApi.bind(this, "addModalBrand", "onCloseAfterApi")}
                      />
                    </ThemeModal>
                    {/*Add Brand Modal End*/}

                    {/*Edit Brand Modal start*/}
                    {
                      editBrand &&
                      <ThemeModal modalClass="" title='Edit Brand' show={this.state.editModalBrand} hide={() => { this.toggleModal('editModalBrand') }} >
                        <BrandForm
                          formTypePro="edit"
                          brandPro={editBrand}
                          closeModel={closeModalAfterApi.bind(this, "editModalBrand", "onCloseAfterApi")}
                        />
                      </ThemeModal>
                    }
                    {/*Edit Brand Modal End*/}
                  </div>
                } />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  };
};

const mapDispatchToProps = dispatch => {
  return ({
    getBrandsPaginationCall: (page, filter, sort, pageSize, status) => { return dispatch(getBrandsPagination(page, filter, sort, pageSize, status)) },
    getBrandCall: (id) => { dispatch(getBrand(id)) },
    deleteBrandCall: (id) => { return dispatch(deleteBrand(id)) },
    handleBrandStatusCall: (brandId, data) => { return dispatch(updateBrandStatus(brandId, data)) },
    handleBrandActiveStatus: (brandId) => dispatch(handleBrandActiveStatus(brandId))
  });
};

function mapStateToProps(state) {
  const { editBrand, loadingBrand, brandsData, pages, current_page } = state.brandsReducer;
  return {
    editBrand, loadingBrand, brandsData, pages, current_page
  };
};

const Brands = connect(mapStateToProps, mapDispatchToProps)(BrandsListPage);
export default Brands;
