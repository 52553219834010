import { adminConstants } from "../constant/admin-constant";

const initialState = {
    agencyTypePagination: {},
    agencyTypeSingle: {}
}

export const agencyTypeReducer = (state = initialState, action) => {
    switch (action.type) {

        case adminConstants.GET_AGENCY_TYPES_PAGINATION_SUCCESS:
            return {
                ...state,
                agencyTypePagination: action.payload.data
            }
        case adminConstants.GET_AGENCY_TYPES_SINGLE_SUCCESS:
            return {
                ...state,
                agencyTypeSingle: action.payload.data
            }
        case adminConstants.GET_AGENCY_TYPES_SINGLE_FAILURE:
            return {
                ...state,
                error: action.payload
            }
        case adminConstants.STORE_AGENCY_TYPES_SINGLE_SUCCESS:
            {
                const data = [...state.agencyTypePagination.data];
                data.push(action.payload.data);
                return {
                    ...state,
                    agencyTypePagination: {
                        ...state.agencyTypePagination,
                        data: data
                    }
                }
            }
        case adminConstants.STORE_AGENCY_TYPES_SINGLE_FAILURE:
            return {
                ...state,
                error: action.payload
            }
        case adminConstants.UPDATE_AGENCY_TYPES_SINGLE_SUCCESS:
            {
                return { ...state, loading:false , updatedAgencyType:action.payload.data};
            }
        case adminConstants.UPDATE_AGENCY_TYPES_SINGLE_FAILURE:
            return {
                ...state,
                error: action.payload
            }
        case adminConstants.DELETE_AGENCY_TYPES_SINGLE_SUCCESS:
            return {
                ...state,
                agencyTypePagination: {
                    ...state.agencyTypePagination,
                    data: action.payload
                }
            }
        case adminConstants.DELETE_AGENCY_TYPES_SINGLE_FAILURE:
            return {
                ...state,
                error: action.payload
            }
        default:
            return state
    }
}
