import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Grid, Row, Col } from 'react-bootstrap'
import Card from '../../Card/Card'
import { inputChangeHandler, getFormattedDate } from '../../../helper/helperFunctions'
import { documentGenerator, showOrdersFlyersList, addContactLogEntries } from '../../../redux/actions/order-actions'
import Validator from 'simple-react-validator'
import { baseUrl } from '../../../helper/ApiCall'
import { flyersAllowedAgencies, flyersNotAllowedAgencies, toggleAllowStatus } from '../../../redux/actions/agency-actions'
import { Link } from 'react-router-dom'
import Pagination from '../../ThemeComponents/Pagination'

class PreAwarenessFlyerGenerator extends Component {
    constructor(props) {
        super(props)

        this.state = {
            departure_date: {
                from: '',
                to: ''
            },
            show_orders_flyers_list: false,
            orders_flyers_list: {}
        }
        this.inputChangeHandler = inputChangeHandler.bind(this)
        this.Validator = new Validator();
    }

    showOrdersFlyersList = () => {
        if (this.Validator.allValid()) {
            this.setState({
                show_orders_flyers_list: true
            })
        } else {
            this.forceUpdate();
            this.Validator.showMessages();
        }
    }

    generateHandler = (e) => {
        if (this.Validator.allValid()) {
        } else {
            e.preventDefault()
            this.forceUpdate()
            this.Validator.showMessages()
        }
    }

    addContactLogEntries = () => {
        if (this.Validator.allValid()) {
            const data = {
                departure_date: this.state.departure_date,
            }
            this.props.addContactLogEntries(data);

        } else {
            this.forceUpdate()
            this.Validator.showMessages()
        }
    }

    toggleAllowStatus = (id, value) => {
        const data = {
            id,
            value
        }
        this.props.toggleAllowStatus(data).then(res => {
            this.pagination1.dataCall();
            this.pagination2.dataCall();
        })
    }

    paginationCallAllowed = (data) => {
        return this.props.flyersAllowedAgencies();
    }

    paginationCallNotAllowed = (data) => {
        return this.props.flyersNotAllowedAgencies();
    }

    paginationCallFlyers = (data) => {
        const newData = {
            departure_date: this.state.departure_date,
        }
        return this.props.showOrdersFlyersList(newData).then(res => {
            this.setState({
                orders_flyers_list: res.data
            });
            return res
        });
    }


    render() {
        const columns = [
            {
                Header: 'Program Types',
                headerClassName: 'headerPagination',
                columns: [
                    {
                        Header: 'PDF Page',
                        accessor: 'title',
                        className: 'action-center'
                    },
                    {
                        Header: 'Company',
                        accessor: 'agency.name',
                        className: 'action-center'
                    },
                    {
                        Header: 'Barcode',
                        accessor: 'barcode',
                        width: 200,
                        className: 'action-center'
                    },
                    {
                        Header: 'Group Name',
                        accessor: 'group_name',
                        className: 'action-center'
                    },
                    {
                        Header: 'Username',
                        accessor: 'username',
                        className: 'action-center'
                    },
                    {
                        Header: 'Password',
                        accessor: 'password',
                        className: 'action-center'
                    },
                    {
                        Header: 'Contact Name',
                        accessor: 'group_leader_contact.first_name',
                        className: 'action-center'
                    },
                    {
                        Header: 'Departure Date',
                        accessor: 'order_Dates.departure_date.value',
                        className: 'action-center',
                        Cell: ({ value }) => {
                            return getFormattedDate(value)
                        }
                    },
                    {
                        Header: 'Contact Log',
                        accessor: 'title',
                        className: 'action-center',
                        Cell: ({ original }) => {
                            return <Link to={'/Admin/' + (original.reorder ? original.reorder : original.id) + '/order-edit'} className="blue_link">Contact Log</Link>
                        }
                    },
                ]
            }
        ]
        const allowed_columns = [
            {
                Header: 'Program Types',
                headerClassName: 'headerPagination',
                columns: [
                    {
                        Header: 'Name',
                        accessor: 'name',
                        className: 'action-center'
                    },
                    {
                        Header: 'Date Added',
                        accessor: 'created_at',
                        className: 'action-center',
                        Cell: ({ value }) => {
                            return getFormattedDate(value)
                        }
                    },
                    {
                        Header: 'Actions',
                        className: 'action-center',
                        Cell: ({ original }) => {
                            return <i style={{ cursor: 'pointer', color: 'tomato' }} onClick={() => { this.toggleAllowStatus(original.id, 0) }} className='ml-md fa fa-minus'>&nbsp;</i>
                        },
                        sortable: false
                    }
                ]
            }
        ]
        const not_allowed_columns = [
            {
                Header: 'Program Types',
                headerClassName: 'headerPagination',
                columns: [
                    {
                        Header: 'Name',
                        accessor: 'name',
                        className: 'action-center'
                    },
                    {
                        Header: 'Date Added',
                        accessor: 'created_at',
                        className: 'action-center',
                        Cell: ({ value }) => {
                            return getFormattedDate(value)
                        }
                    },
                    {
                        Header: 'Actions',
                        className: 'action-center',
                        Cell: ({ original }) => {
                            return <i style={{ cursor: 'pointer', color: 'limegreen' }} onClick={() => { this.toggleAllowStatus(original.id, 1) }} className='ml-md fa fa-plus'>&nbsp;</i>
                        },
                        sortable: false
                    }
                ]
            }
        ]
        // const { preAwarenessAgencies } = this.props;
        return (
            <div className='content'>
                <Grid fluid>
                    <Row>
                        <Col md={12}>
                            <Card content={
                                <React.Fragment>
                                    <div className="secHeading">PreAwareness Flyer Generator</div>
                                    <div>
                                        <Link to='/Admin/PreAwareness-Flyers-History' className='btn-primary btn btn-default'>PreAwareness Flyers History</Link>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <strong className="mb-sm mt-md display_ib">Does not generate for the following agencies</strong>
                                            {/* <ReactTable
                                                columns={not_allowed_columns}
                                                data={preAwarenessAgencies.not_allowed_agencies}
                                                defaultPageSize={5}
                                            /> */}
                                            <Pagination
                                                ref={(p) => this.pagination1 = p}
                                                showPagination={false}
                                                columns={not_allowed_columns}
                                                pageSize={5}
                                                getDataCall={this.paginationCallNotAllowed}
                                                filterView={false}
                                                // downloadData={true}
                                                // downloadFileName={'Orders'}
                                                // lowerContent = {null}
                                                // filterPlaceHolder = {'Orders'}
                                                getRowProps={this.getRowProps}
                                            // showAllToggle={true}
                                            />
                                        </div>
                                        <div className='col-md-6'>
                                            <strong className="mb-sm mt-md display_ib">Generates for the following agencies</strong>
                                            {/* <ReactTable
                                                columns={allowed_columns}
                                                data={preAwarenessAgencies.allowed_agencies}
                                                defaultPageSize={5}
                                            /> */}
                                            <Pagination
                                                ref={(p) => this.pagination2 = p}
                                                showPagination={false}
                                                columns={allowed_columns}
                                                pageSize={5}
                                                getDataCall={this.paginationCallAllowed}
                                                filterView={false}
                                                // downloadData={true}
                                                // downloadFileName={'Orders'}
                                                // lowerContent = {null}
                                                // filterPlaceHolder = {'Orders'}
                                                getRowProps={this.getRowProps}
                                            // showAllToggle={true}
                                            />
                                        </div>
                                    </div>
                                    <div className='orderContent'>
                                        <div className='flexElem' style={{ "flexWrap": "wrap", "justifyContent": "space-between" }}>
                                            <div className='orderDetailsHeader'>
                                                <span>Generate PreAwareness Flyer</span>
                                            </div>
                                            <div style={{ width: '100%', borderCollapse: 'collapse', border: 'none', borderTop: '1px solid #ccc' }} className="orderDetailsBox pb-md">
                                                <form id='flyer_form' onSubmit={this.generateHandler} action={baseUrl + 'documentGenerator'} method='post' target="_blank">
                                                    <strong className='ml-sm mt-sm display_ib'>Enter Date Range:</strong>
                                                    <div className='ml-sm mt-sm mb-sm'>
                                                        <div className="flexElem" style={{ alignItems: 'center' }}>
                                                            <label htmlFor="">
                                                                Departure Date Days from Today
                                                            <input className='form-control' onChange={(e) => { this.inputChangeHandler(e, /^(-)?\d*$/) }} value={this.state.departure_date.from} name='departure_date.from' type="text" id="" />
                                                            </label>
                                                            <span className="display_ib mt-sm ml-sm mr-sm">to</span>
                                                            <label htmlFor="">
                                                                Days from Today
                                                            <input onChange={(e) => { this.inputChangeHandler(e, /^(-)?\d*$/) }} name='departure_date.to' value={this.state.departure_date.to} className='form-control' type="text" id="" />
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <input type='hidden' name='type' value='flyers' />
                                                    <input type='hidden' name='from' value={this.state.departure_date.from} />
                                                    <input type='hidden' name='to' value={this.state.departure_date.to} />
                                                    {this.Validator.message('from', this.state.departure_date.from, 'required')}
                                                    {this.Validator.message('to', this.state.departure_date.to, 'required')}
                                                </form>
                                                <form id='envelope_form' onSubmit={this.generateHandler} action={baseUrl + 'documentGenerator'} method='post' target="_blank">
                                                    <input type='hidden' name='type' value='envelopes' />
                                                    <input type='hidden' name='from' value={this.state.departure_date.from} />
                                                    <input type='hidden' name='to' value={this.state.departure_date.to} />
                                                </form>
                                                <div>
                                                    <button type='submit' form='flyer_form' name='flyers' className='ml-md customBtn btn btn-primary btn btn-default'>Generate Flyers</button>
                                                    <button type='submit' form='envelope_form' name='envelopes' className='ml-md customBtn btn btn-primary btn btn-default'>Generate Envelopes</button>
                                                    <button onClick={this.showOrdersFlyersList} name='flyers_list' className='ml-md customBtn btn btn-primary btn btn-default'>Show Order Flyer List</button>
                                                    <button onClick={this.addContactLogEntries} name='add_log' className='ml-md customBtn btn btn-primary btn btn-default'>Add Contact Log Entries</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className='orderContent'>
                                        <div className='flexElem' style={{ "flexWrap": "wrap", "justifyContent": "space-between" }}>
                                            <div className='orderDetailsHeader'>
                                                <span>Generate Envelopes</span>
                                            </div>
                                            <div style={{ width: '100%', borderCollapse: 'collapse' }} className="orderDetailsBox">
                                                <form onSubmit={this.generateHandler} action={baseUrl + 'documentGenerator'} method='post' target="_blank">
                                                    <strong className='ml-sm'>Enter Date Range:</strong>
                                                    <div className='ml-sm mt-sm mb-sm'>
                                                        <label htmlFor="">
                                                            Departure Date Days from Today
                                                        <input readOnly className='form-control' onChange={(e) => { this.inputChangeHandler(e, /^(-)?\d*$/) }} value={this.state.departure_date.from} name='departure_date.from' type="text" id="" />
                                                        </label>
                                                        <label className='ml-md' htmlFor="">
                                                            Days from Today
                                                        <input readOnly onChange={(e) => { this.inputChangeHandler(e, /^(-)?\d*$/) }} name='departure_date.to' value={this.state.departure_date.to} className='form-control' type="text" id="" />
                                                        </label>
                                                        <div className='mt-md mb-sm'>
                                                            <button onClick={this.generateHandler} name='envelopes' className='customBtn btn btn-primary btn btn-default'>Generate Envelopes</button>
                                                        </div>
                                                    </div>
                                                    <input type='hidden' name='type' value='envelopes' />
                                                    <input type='hidden' name='from' value={this.state.departure_date.from} />
                                                    <input type='hidden' name='to' value={this.state.departure_date.to} />
                                                    {this.Validator.message('from', this.state.departure_date.from, 'required')}
                                                    {this.Validator.message('to', this.state.departure_date.to, 'required')}
                                                </form>

                                            </div>
                                        </div>
                                    </div> */}
                                    {
                                        this.state.show_orders_flyers_list &&
                                        <div className='orderContent'>
                                            <div className='flexElem' style={{ "flexWrap": "wrap", "justifyContent": "space-between" }}>
                                                <div className='orderDetailsHeader'>
                                                    <span>Flyers List</span>
                                                </div>
                                                <div className='container'>
                                                    <strong>Orders with Departure Date From {this.state.orders_flyers_list.date_after} - {this.state.orders_flyers_list.date_before}</strong>
                                                    {/* <ReactTable
                                                        columns={columns}
                                                        data={this.props.orders_flyers_list.orders}
                                                        defaultPageSize={10}
                                                    /> */}
                                                    <div className='row'>
                                                        <Pagination
                                                            ref={(p) => this.pagination = p}
                                                            showPagination={false}
                                                            columns={columns}
                                                            pageSize={10}
                                                            getDataCall={this.paginationCallFlyers}
                                                            filterView={false}
                                                            // downloadData={true}
                                                            // downloadFileName={'Orders'}
                                                            // lowerContent = {null}
                                                            // filterPlaceHolder = {'Orders'}
                                                            getRowProps={this.getRowProps}
                                                        // showAllToggle={true}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {/* <div className='orderContent'>
                                        <div className='flexElem' style={{ "flexWrap": "wrap", "justifyContent": "space-between" }}>
                                            <div className='orderDetailsHeader'>
                                                <span>Add Contact Log Entries</span>
                                            </div>
                                            <div style={{ width: '100%', borderCollapse: 'collapse' }} className="orderDetailsBox">
                                                <strong className='ml-sm'>Enter Date Range:</strong>
                                                <div className='ml-sm mt-sm mb-sm'>
                                                    <label htmlFor="">
                                                        Departure Date Days from Today To
                                                        <input readOnly className='form-control' onChange={(e) => { this.inputChangeHandler(e, /^(-)?\d*$/) }} value={this.state.departure_date.from} name='departure_date.from' type="text" id="" />
                                                    </label>
                                                    <label className='ml-md' htmlFor="">
                                                        Days from Today
                                                        <input readOnly onChange={(e) => { this.inputChangeHandler(e, /^(-)?\d*$/) }} name='departure_date.to' value={this.state.departure_date.to} className='form-control' type="text" id="" />
                                                    </label>
                                                    <div className='mt-md mb-sm'>
                                                        <button onClick={this.addContactLogEntries} name='add_log' className='customBtn btn btn-primary btn btn-default'>Add Contact Log Entries</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                </React.Fragment>
                            } />
                        </Col>
                    </Row>
                </Grid>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { orders_flyers_list } = state.ordersReducer
    const { preAwarenessAgencies } = state.agenciesReducer
    return {
        orders_flyers_list,
        preAwarenessAgencies
    }
}

const mapDispatchToProps = dispatch => {
    return {
        documentGenerator: (data) => { dispatch(documentGenerator(data)) },
        showOrdersFlyersList: (data) => { return dispatch(showOrdersFlyersList(data)) },
        addContactLogEntries: (data) => { dispatch(addContactLogEntries(data)) },
        flyersAllowedAgencies: () => { return dispatch(flyersAllowedAgencies()) },
        flyersNotAllowedAgencies: () => { return dispatch(flyersNotAllowedAgencies()) },
        toggleAllowStatus: (data) => { return dispatch(toggleAllowStatus(data)) }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PreAwarenessFlyerGenerator)
