import React, { Component } from "react";

class OrderWeTravelConfirm extends Component {
	render(){
		return(
			<div className='custom-ui'>
	            <div className="react-confirm-alert-body text-center">
	              	<div><i className="pe-7s-help1" style={{"fontSize":"40px", "color":"red"}}></i></div>
	              	
	              	<p className="marginFromTopPopUp" >{ this.props.description}?</p>
	              	<div className="react-confirm-alert-button-group alignFlexButtons">
	                	<button onClick={() => {
	                    	this.props.onNo()
	                    	this.props.onClosePro()
	                	}} className="btn btn-danger"><i className="pe-7s-close-circle"></i> No I do not have one at this time</button>
	                	
                        <button className="btn btn-success" onClick={() => {
	                    	this.props.onYes()
	                    	this.props.onClosePro()
	                	}}>
	                	<i className="pe-7s-check"></i> Yes</button>
	              </div>
	            </div>
	        </div>
		);
	};
}

export default OrderWeTravelConfirm;