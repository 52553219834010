import { adminConstants } from "../constant/admin-constant"

const initialState = {
    customMusic: []
}

export const customMusicReducer = (state = initialState, action) => {
    switch (action.type) {
        case adminConstants.GET_CUSTOM_MUSIC_SUCCESS:
            return {
                ...state,
                customMusic: action.payload.data
            }
        default:
            return state
    }
}
