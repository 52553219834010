import { itemCategoryService } from "../../services/item-category";
import { adminConstants } from "../constant/admin-constant";

export function getItemCategories() {
    return dispatch => {
        itemCategoryService.getItemCategories().then(
            response => {
                dispatch(success(response))
            },
            error => {
                dispatch(failure(error))
            }
        )
    }
    function success(response) { return { type: adminConstants.GET_ALL_ITEM_CATEGORIES_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.GET_ALL_ITEM_CATEGORIES_FAILURE, payload: error } }
}