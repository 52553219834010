import { adminConstants } from "../constant/admin-constant";
import { commentServices } from "../../services/comment";
import { alertActions } from './alert-actions';
import { frontEndUserConstants } from "../constant/front-end-constant";

export function getCommentCategories() {
    return dispatch => {
        commentServices.getCommentCategories().then(
            response => {
                dispatch(success(response))
            },
            error => {
                dispatch(failure(error))
            }
        )
    }
    function success(response) { return { type: adminConstants.GET_COMMENT_CATEGORIES_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.GET_COMMENT_CATEGORIES_FAILURE, payload: error } }
}

export function getAllComments(page, filter, sort, pageSize, cat_id, modelId, from, to, uuid) {
    return dispatch => {
        return commentServices.getAllComments(page, filter, sort, pageSize, cat_id, modelId, from, to, uuid).then(
            response => {
                dispatch(success(response))
                return response
            },
            error => {
                dispatch(failure(error))
            }
        )
    }
    function success(response) { return { type: adminConstants.GET_ALL_COMMENTS_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.GET_ALL_COMMENTS_FAILURE, payload: error } }
}

export function deleteComment(id) {
    return dispatch => {
        return commentServices.deleteComment(id).then(
            response => {
                // dispatch(success(commentList))
                // dispatch(alertActions.success(response.message))
                return response;
            },
            error => {
                dispatch(failure(error))
                dispatch(alertActions.error(error))
                return Promise.reject(error);
            }
        )
    }
    // function success(commentList) { return { type: adminConstants.DELETE_COMMENT_SUCCESS, payload: commentList } }
    function failure(error) { return { type: adminConstants.DELETE_COMMENT_FAILURE, payload: error } }
}
export function setCommentForOrder(comment) {
    return dispatch => {
   dispatch(success(comment))
    }
    function success(comment) { return { type: adminConstants.ADD_COMMENT, payload: comment } }
}

export function addComment(data, modelId) {
    return (dispatch, state) => {
        return commentServices.addComment(data, modelId).then(
            response => {
                dispatch(alertActions.success(response.message))
                return response
            },
            error => {
                dispatch(failure(error))
                dispatch(alertActions.error(error))
                return Promise.reject(error)
            }
        )
    }
    // function success(response) { return { type: adminConstants.ADD_COMMENT_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.ADD_COMMENT_FAILURE, payload: error } }
}

export function editComment(data, id) {
    return dispatch => {
        return commentServices.editComment(data, id).then(
            response => {
                // dispatch(success(comments))
                dispatch(alertActions.success(response.message))
                return response
            },
            error => {
                dispatch(failure(error))
                dispatch(alertActions.error(error))
                return Promise.reject(error)
            }
        )
    }
    // function success(response) { return { type: adminConstants.EDIT_COMMENT_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.EDIT_COMMENT_FAILURE, payload: error } }
}

export function toggleCommentHighlight(id, comments) {
    return dispatch => {
        commentServices.toggleCommentHighlight(id).then(
            response => {
                dispatch(success(comments))
                dispatch(alertActions.success(response.message))
            },
            error => {
                dispatch(failure(error))
                dispatch(alertActions.error(error))
            }
        )
    }
    function success() { return { type: adminConstants.TOGGLE_COMMENT_HIGHLIGHT_SUCCESS, comments: comments } }
    function failure(error) { return { type: adminConstants.TOGGLE_COMMENT_HIGHLIGHT_FAILURE, payload: error } }
}


export function getContactLogTypes(orderId) {
    return dispatch => {
        dispatch(request())
        commentServices.getContactLogTypes(orderId)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request() { return { type: adminConstants.GET_CONTACT_LOG_TYPES_REQUEST } }
    function success(response) { return { type: adminConstants.GET_CONTACT_LOG_TYPES_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.GET_CONTACT_LOG_TYPES_FAILURE, payload: error } }
}
export function getCommentCategoryByTitle(titles) {
    return dispatch => {
        return commentServices.getCommentCategoryByTitle(titles).then(
            response => {
                dispatch(success(response))
                return response
            },
            error => {
                dispatch(failure(error))
            }
        )
    }
    function success(response) { return { type: frontEndUserConstants.GET_SINGLE_COMMENT_CATEGORY_SUCCESS, payload: response } }
    function failure(error) { return { type: frontEndUserConstants.GET_SINGLE_COMMENT_CATEGORY_FAILURE, payload: error } }
}

export function getCommentsByColumn(data) {
    return dispatch => {
        commentServices.getCommentsByColumn(data).then(
            res => {
                dispatch(success(res));
            },
            err => {

            }
        )
    }
    function success(response) { return { type: adminConstants.GET_ALL_COMMENTS_SUCCESS, payload: response } }
    // function failure(error) { return { type: frontEndUserConstants.GET_SINGLE_COMMENT_CATEGORY_FAILURE, payload: error } }
}


export function updateOrderCsrStep(modelId, data) {
    return dispatch => {
        return commentServices.updateOrderCsrStep(modelId, data).then(
            response => {
                dispatch(successCsrSteps(response))
                dispatch(successShippingDetail(response))
                dispatch(alertActions.success(response.message))
                return response
            },
            error => {
                dispatch(alertActions.error(error))
                return Promise.reject(error)
            }
        )
    }

    function successCsrSteps(response) { return { type: adminConstants.UPDATE_ORDER_CSR_STEPS_SUCCESS, payload: response } }
    function successShippingDetail(response) { return { type: adminConstants.UPDATE_SHIPPING_DETAIL_SUCCESS, payload: response } }
}

export function getContent(account_id, order_id, code) {

    return dispatch => {
        return commentServices.getContent(account_id, order_id, code).then(
            response => {
                // dispatch(success(comments))
                // dispatch(alertActions.success(response.message))
                return response
            },
            error => {
                console.log('error', error);
                // dispatch(failure(error))
                dispatch(alertActions.error(error))
                return Promise.reject(error)
            }
        )
    }


    // return {
    //     currentStep : 'step1',
    //     content : 'haahahahhaa',
    //     subject:'subject hahaha',
    //     emails:['hassan', 'dawood'],
    //     title:'step one',
    // };
}

export function getLatestCommentByCategoryTitle(title, model_id) {
    return dispatch => {
        return commentServices.getLatestCommentByCategoryTitle(title, model_id)
    }
}