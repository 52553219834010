import React from 'react';
// import {
// Button
// } from "react-bootstrap";

import SimpleReactValidator from 'simple-react-validator';
import Select from 'react-select';
// import InputMask from 'react-input-mask';
// import { adminLabels } from '../../../redux/constant/admin-label-constant';
import { returnLabelsWithValues } from 'helper/helperFunctions';
import PhoneNumber from "components/ThemeComponents/phoneNumber";

class AgencyUserForm extends React.Component {
	constructor(props) {
		super(props);
		this.validatorAgencyUser = new SimpleReactValidator({ autoForceUpdate: this, locale: 'en' });
		this.state = {
			user: {
				...this.props.userPro,
				password: '',
				confirm_password: ''
			},
			submitted: false,
			formType: this.props.formTypePro || '',
		};

		this.handleAgencyUser = this.handleAgencyUser.bind(this);
		this.handleAgencyUserSubmit = this.handleAgencyUserSubmit.bind(this);
		this.handleChangeRole = this.handleChangeRole.bind(this);
	}
	handleChangeRole(selectedOption, name) {
		if (name.action === 'select-option') {
			this.setState(prevState => ({
				user: {
					...prevState.user,
					agencyRoles: [...prevState.user.agencyRoles, name.option.value]
				}
			}));
		}
		if (name.action === 'remove-value') {
			const agencyRolesTemp = this.state.user.agencyRoles;
			agencyRolesTemp.splice(agencyRolesTemp.indexOf(name.removedValue.value), 1);
			this.setState(prevState => ({
				user: {
					...prevState.user,
					agencyRoles: agencyRolesTemp
				}
			}))
		}
	}
	handleAgencyUser(e) {
		const { name, value, type } = e.target;
		const userState = { ...this.state.user };
		userState[name] = type === 'checkbox' ? e.target.checked ? true : false : value;
		this.setState({ user: userState });
	}
	handleAgencyUserSubmit(e) {
		e.preventDefault();
		if (this.validatorAgencyUser.allValid()) {
			this.setState({ submitted: true }, function () {
				this.props.handleAgencyUserSubmit(this.state.user, this.state.formType);
			});
		} else {
			this.setState({ submitted: false });
			this.validatorAgencyUser.showMessages();
			this.forceUpdate();
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if ((this.props.userPro !== prevProps.userPro) && this.props.frontEnd) {
			this.setState({
				user: this.props.userPro
			})
		}
	}

	render() {
		const { user, formType } = this.state;
		const { agencyRoles } = this.props;
		const frontEndBtnPurple = "pl-xlg pr-xlg themeBtn_12 purple_gradient hover_dark";
		const frontEndBtnRed = ' pl-xlg pr-xlg themeBtn_12 red_gradient hover_dark ml-md';
		return (
			<form style={{ flexGrow: this.props.frontEnd && 1 }} onSubmit={this.handleAgencyUserSubmit}>
				<div className="row">
					<div className="col-md-6">
						<div className='form-group'>
							<label htmlFor="name">Contact Name<span className="requiredClass">*</span></label>
							<input type="text" name="name" className="form-control" value={user.name} onChange={this.handleAgencyUser} />
							{this.validatorAgencyUser.message('name', user.name, 'required')}
						</div>
					</div>

					<div className="col-md-6">
						<div className='form-group'>
							<label htmlFor="email">Contact Phone<span className="requiredClass">*</span></label>
							<PhoneNumber
								value={user.phone1}
								handleParent={this.handleAgencyUser}
								validationName={'phone1'}
								validator={this.validatorAgencyUser}
								required={true}
								name={'phone1'}
							/>
						</div>
					</div>
				</div>
				<div className="row">

					<div className="col-md-6">
						<div className='form-group'>
							<label htmlFor="email">Contact Phone2</label>
							<PhoneNumber
								value={user.phone2}
								handleParent={this.handleAgencyUser}
								validationName={'phone2'}
								validator={this.validatorAgencyUser}
								required={false}
								name={'phone2'}
							/>
						</div>
					</div>

					<div className="col-md-6">
						<div className='form-group'>
							<label htmlFor="name">Select Access Level<span className="requiredClass">*</span></label>
							<Select
								value={returnLabelsWithValues(user.agencyRoles, agencyRoles)}
								onChange={this.handleChangeRole}
								options={agencyRoles}
								isSearchable={true}
								isMulti={true}
                                isDisabled={this.props.isSalesConsultant}
								placeholder={'Select Access Level'}
								isClearable={false}
							/>
							{this.validatorAgencyUser.message('agencyRoles', user.agencyRoles, 'required|min:1,array')}
						</div>
					</div>

					{
						/*
						<div className="col-md-6">
							<div className='form-group'>
								<label htmlFor="name">Contact Fax</label>
								<InputMask mask="999-999-9999" maskChar={null} type="text" name="fax" className="form-control fax_icon" placeholder="123-456-7890" value={user.fax} onChange={this.handleAgencyUser} />
								{this.validatorAgencyUser.message('fax', user.fax, ['regex:^(([0-9]{3})|[0-9]{3})[-]?[\0-9]{3}[-]?[0-9]{4}$'])}
							</div>
						</div>

						*/
					}
				</div>
				<div className="row">
					<div className="col-md-6">
						<div className='form-group'>
							<label htmlFor="name">Username<span className="requiredClass">*</span></label>
							<input type="text" name="username" className="form-control" value={user.username} onChange={this.handleAgencyUser} />
							{
								/*
								this.validatorAgencyUser.message('username', user.username, 'required|min:5')
								*/
							}
						</div>
					</div>
					<div className="col-md-6">
						<div className='form-group'>
							<label htmlFor="email">Email<span className="requiredClass">*</span></label>
							<input type="text" name="email" className="form-control email_icon" value={user.email} onChange={this.handleAgencyUser} /*readOnly={formType === 'edit' ? true : false}*/ />
							{this.validatorAgencyUser.message('email', user.email, 'required|email')}
						</div>
					</div>

				</div>
				<div className="row">
					<div className="col-md-6">
						<div className='form-group'>
							<label htmlFor="password">Password{formType === 'add' ? <span className="requiredClass">*</span> : null}</label>
							<input type="password" name="password" autoComplete="off" className="form-control password_icon" value={user.password} onChange={this.handleAgencyUser} />
							{
								formType === 'add' &&
								this.validatorAgencyUser.message('password', user.password, 'required|min:5')
							}
						</div>
					</div>
					<div className="col-md-6">
						<div className='form-group'>
							<label htmlFor="Phone">Confirm Password{formType === 'add' ? <span className="requiredClass">*</span> : null}</label>
							<input type="password" name="confirm_password" autoComplete="off" className="form-control password_icon" value={user.confirm_password} onChange={this.handleAgencyUser} />
							{
								formType === 'add' &&
								this.validatorAgencyUser.message('confirm_password', user.confirm_password, 'required|min:5')
							}
						</div>
					</div>
				</div>

				<div className="col-md-6">
					<div className=''>
						<label className="container-check checkbox_down"> Is Active?
	                  <input type="checkbox" name="is_active" className="checkbox" value={user.is_active ? 1 : 0} onChange={this.handleAgencyUser} checked={user.is_active ? 1 : 0} />
							<span className="checkmark"></span>
						</label>
						{/* {this.validatorPermission.message('is_active', user.is_active, 'required')} */}
					</div>
				</div>

				<div className="row">
					<div className="col-md-12">
						<div className={'text-center ' + (this.props.frontEnd ? 'section-frontEnd' : '')}>
							<button type='submit' className={!this.props.frontEnd ? "backButton pt-sm no_radius pb-sm success btn btn-lg btn-info ml-sm mt-sm btn-default" : frontEndBtnPurple} >Save</button>
							<button type='button' onClick={() => this.props.closeModel()} className={!this.props.frontEnd ? "backButton pt-sm no_radius pb-sm primary btn btn-lg ml-sm mt-sm btn-info" : frontEndBtnRed} >Cancel</button>
						</div>
					</div>
				</div>
			</form>
		);
	}
}


export default AgencyUserForm;
