import React from 'react';
import AgencyForm from 'components/Admin/Agency/AgencyForm';
import { objectsConstants as OC } from 'redux/constant/objects-constant';
import { connect } from 'react-redux';
import {
  Grid,
  Row,
  Col
} from "react-bootstrap";
import { Card } from "components/Card/Card.jsx";

class AddAgency extends React.Component {
	constructor(props){
		super(props);
		this.state = {
			agency : OC.AGENCY,
			formType:'add',
			selected:{}
		};
	};
	render(){
		return(
			<div className="content">
	        	<Grid fluid>
	          		<Row>
	            		<Col md={12}>
							<Card bsClass={["customCard"]}
								content={
									<div>
										<Grid fluid>
											<Row>
												<Col md={12}>
													<AgencyForm 
														agencyData={this.state.agency} 
														formType={this.state.formType}
														defaultBrandByName={true}
													/>
												</Col>
											</Row>
										</Grid>
									</div>
								}
							/>
	             		</Col>
	            	</Row>
	          	</Grid>
	        </div>
		);
	};
}

function mapStateToProps(state) {
    const { loadingAgency } = state.agenciesReducer;
    
    return {
        loadingAgency
    };
}

export default connect(mapStateToProps)(AddAgency);