import { customMusicServices } from "../../services/customMusicService"
import { generalActions } from "./generalActions"
import { adminConstants } from "../constant/admin-constant"

export function getCustomMusic() {
    return dispatch => {
        customMusicServices.getCustomMusic().then(
            res => {
                dispatch(generalActions.success(adminConstants.GET_CUSTOM_MUSIC_SUCCESS, res));
            },
            err => {
                dispatch(generalActions.failure(adminConstants.GET_CUSTOM_MUSIC_FAILURE, err));
            }
        )
    }
}