import React, { Component } from "react";
import { connect } from 'react-redux';
import { objectsConstants as OC } from 'redux/constant/objects-constant';
import SimpleReactValidator from 'simple-react-validator';
import { Button } from "react-bootstrap";
import { createFormData, focusInput,  } from 'helper/helperFunctions';
import { getProShowDestinations, storeProShowFile } from 'redux/actions/proShowFile-action';
import { getDestinations } from 'redux/actions/settings-actions';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { proShowFileService } from "../../../services/proShowFile";
import { alertActions } from "../../../redux/actions/alert-actions";
import ConfirmAlertForDelete from 'components/ThemeComponents/confirmAlertForDelete';
import { confirmAlert } from "react-confirm-alert";

const promiseOptions = (inputValue) =>
	new Promise((resolve) => {
		setTimeout(() => {
			resolve(proShowFileService.getProShowDestinationsAsync({ inputValue: inputValue }));
		}, 1000);
	}
	);


class ProShowDestinationFormPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			pro_show_file: { ...this.props.pro_show_file },
			submitted: false,
		};
		this.validatorProShowFile = new SimpleReactValidator({ autoForceUpdate: this, locale: 'en' });
		this.props.getProShowDestinationsCall({ inputValue: null, defaultOptions: [] }).then(result => {
        });

	}
	
	handleProShowFileSubmit = (e) => {
		if (this.validatorProShowFile.allValid()) {
			this.setState({ submitted: true }, function () {
				const { pro_show_file } = this.state;
				const fdData = createFormData({
					'destinations[]': pro_show_file.destinations,
					'proShowArray[]': this.props.proShowArray,
					'_method': 'POST',
				});
					proShowFileService.updateProshowDestination(fdData).then(
						response => {
							this.props.success(response.message);
							this.props.resetProShowArray();
							this.props.closeModel();
							this.props.getPagination();
						}
					);
			});
		} else {
			this.setState({ submitted: false });
			focusInput(this.validatorProShowFile.getErrorMessages());
			this.validatorProShowFile.showMessages();
			this.forceUpdate();
		}
	}
	
	handleChangeProShowFileDropDown = async (selectedOption, info) => {
		if (info.action === 'select-option') {
			if (typeof info.option === 'undefined') {
				this.setState(prevState => ({
					pro_show_file: {
						...prevState.pro_show_file,
						[info.name]: selectedOption.value
					}
				}));
			} else {
				this.setState(prevState => ({
					pro_show_file: {
						...prevState.pro_show_file,
						[info.name]: [...prevState.pro_show_file[info.name], info.option.value]
					}
				}));
			}
		}
		if (info.action === 'remove-value') {
			const agencyTypesTemp = this.state.pro_show_file[info.name];
			agencyTypesTemp.splice(agencyTypesTemp.indexOf(info.removedValue.value), 1);
			this.setState(prevState => ({
				pro_show_file: {
					...prevState.pro_show_file,
					[info.name]: agencyTypesTemp
				}
			}))
		}

		/*Modal for destination code*/
		if (info.action === 'create-option') {
			var lastSelectedOption = selectedOption.slice(-1).pop()
            console.log("keyyy",lastSelectedOption['label']);
			try {
				const res = await proShowFileService.storeProshowDestination({title: lastSelectedOption['label']});
				this.setState(prevState => ({
					pro_show_file: {
						...prevState.pro_show_file,
						['destinations']: [...prevState.pro_show_file['destinations'], res.destination.id]
					}
				}));
				this.props.success(res.message)
			} catch (err) {
				console.log("err", err);
				this.props.error(err.message)
			}
			
		}
		/*Modal for destination code*/

	}

	componentDidUpdate(prevProps, prevState) {

		/*Modal for destination code*/
		if (prevProps.storedDestination !== this.props.storedDestination) {
			this.setState(prevState => ({
				pro_show_file: {
					...prevState.pro_show_file,
					destinations: [...prevState.pro_show_file.destinations, this.props.storedDestination.id]
				}
			}));
		}
		/*Modal for destination code*/
	}

	updatePsDestinationConfirmation = (imgData) => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <ConfirmAlertForDelete typeName="Pro Show Destination" description="Are you sure to update pro show destination?" onClosePro={onClose} deleteType={() => { this.handleProShowFileSubmit() }} />
                )
            }
        })
    }

	render() {

		this.validatorProShowFile.purgeFields();
		const { pro_show_file, } = this.state;
		return (
			<div className="row section-Frontend">
				<form>
					{
						!pro_show_file.isDefault &&
						<React.Fragment>
							<div className="col-md-6" id="destinations">
								<label htmlFor="glemail">Select Destination<span className="requiredClass">*</span></label>
								<AsyncCreatableSelect
									isMulti={true}
									defaultOptions
									defaultValue={pro_show_file.destinationDefault}
									loadOptions={promiseOptions}
									onChange={this.handleChangeProShowFileDropDown}
									className="selectUser"
									placeholder="Pro show Destination"
									name='destinations'
								/>
								{
									this.validatorProShowFile.message('destinations', pro_show_file.destinations, 'required|min:' + parseInt(!pro_show_file.isDefault ? 0 : 1, 10) + ',array')
								}
							</div>

						</React.Fragment>
					}

					

					<div className="row">
						<div className="col-md-12">
							<div className='text-center'>
								<Button onClick={this.updatePsDestinationConfirmation} bsSize="large" className="backButton pt-sm no_radius pb-sm success btn btn-lg ml-sm mt-sm btn btn-default" >Save</Button>
								<Button bsSize="large" onClick={() => this.props.closeModel()} className="backButton pt-sm no_radius pb-sm info btn btn-lg btn-info ml-sm mt-sm" >Cancel</Button>
							</div>
						</div>
					</div>
				</form>
			</div>
		);
	}
}

const mapDispatchToProps = dispatch => {
	return ({
		
		storeProShowFileCall: (data) => { return dispatch(storeProShowFile(data)) },
		getDestinationsCall: () => { return dispatch(getDestinations()) },
		getProShowDestinationsCall: () => { return dispatch(getProShowDestinations()) },
		success: (message) => { return dispatch(alertActions.success(message)) },
		error: (message) => { return dispatch(alertActions.error(message)) },

	});
};
function mapStateToProps(state) {
    const { loadingDestination, storedDestination } = state.destinationsReducer;

	return {
		loadingDestination, storedDestination,
	};
}


const ProShowDestinationForm = connect(mapStateToProps, mapDispatchToProps)(ProShowDestinationFormPage);
export default ProShowDestinationForm;
