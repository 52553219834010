import React, { Component } from "react";
import { connect } from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';
import { getCountries } from 'redux/actions/settings-actions';
import AddressForm from 'components/ThemeComponents/addressForm';
import { shippingDetailEdit, updateShippingDetails, updateProjectCameraShipDate, processShipStatus, updateOrderDate, toggleOrderEquipmentStatus, getOrder } from 'redux/actions/order-actions';
import { Card } from "components/Card/Card.jsx";
import { Button, OverlayTrigger, Popover, } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { openModal, closeModal, dateFormatMy } from 'helper/helperFunctions';
import OpenModalButton from 'components/ThemeComponents/openModelButton';
import ShipStatusForm from "./ShipStatusForm";
import AssignedItemShowAndModal from './AssignedItemShowAndModal';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {getAllShippingMethods} from "../../../redux/actions/shippingMethod-actions";
import shippingService from "../../../services/shipping";
import { confirmAlert } from 'react-confirm-alert';
import ConfirmEditTracking from "../../ThemeComponents/ConfirmEditTracking";
import AWS from 'aws-sdk';
import { alertActions } from "../../../redux/actions/alert-actions";
import ShippingService from "../../../services/shipping";
var Modal = require('react-bootstrap-modal');
var FileSaver = require('file-saver');
var { isEmpty, isEqual } = require('lodash');
const moment = window.moment;
class ShippingDetailShowAndModalPage extends Component {
	constructor(props) {
		super(props);
		this.validatorShippingDetail = new SimpleReactValidator({ autoForceUpdate: this, locale: 'en' });
		this.state = {
			shipping_details: this.props.shipping_details,
			order_details: this.props.order_details,
			agency_details: this.props.agency_details,
			pricing_details: this.props.pricing_details,
			editShippingModal: window.location.href.indexOf("orderDetailsHeader") > -1 ? true : false,
			options: {
				statespre_trip_material_address: [],
				statesvideo_shipping_address: [],
			},
			projected_camera_ship_date: new Date(),
			cameraShippingModal: false,
			videoShippingModal: false,
			mosaicShippingModal: false,
			returnShippingModal: false,
			sameAddress: false,
            is_france: false,
            ptma_initial_country_France: false,
            vsa_initial_country_France: false,
            vsa_is_france: false,
            ptma_is_france: false,
		};

		// this.shippingDetailUpdate = this.shippingDetailUpdate.bind(this);
		this.clearState = this.clearState.bind(this);
		this.addressDetail = this.addressDetail.bind(this);
	}
	// shippingDetailUpdate(shippingDetail, type) {
	// 	const shippingDetailTemp = { ...this.state.shipping_details };
	// 	console.log(shippingDetailTemp.video_shipping_address);
	// 	shippingDetailTemp[type] = shippingDetail;
	// 	// this.props.editShippingDetailCall(shippingDetailTemp, type);
	// 	this.setState({shipping_details:shippingDetailTemp});
	// }

    // editConfirmTracking = (id) => {
    //     confirmAlert({
    //         customUI: ({ onClose }) => {
    //             return (
    //                 <confirmEditTracking typeName="Button" description="Are you sure you want to delete this button" onClosePro={onClose} deleteType={() => this.deleteMoreButtonHandler(id)} />
    //             )
    //         }
    //     })
    // }

	clearState(type) {
		this.setState(prevState => ({
			...prevState, options: {
				...prevState.options, ['states' + type]: []
			}
		}));
	}
	setAddressStates(type, statesData) {
		this.setState(prevState => ({
			...prevState, options: {
				...prevState.options, ['states' + type]: statesData
			}
		}), function () {
			// console.log('state ' + type, this.state.options['states' + type]);
		});
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.shipping_details !== prevProps.shipping_details) {
			this.setState(
				{
					shipping_details: this.props.shipping_details,
					projected_camera_ship_date: this.props.shipping_details.order_dates.projected_camera_ship_date.value
				});
		}
		if (this.state.shipping_details !== prevState.shipping_details) {
			this.setState(
				{
					shipping_details: this.state.shipping_details,
				});
		}

		if (this.props.agency_details !== prevProps.agency_details) {
			this.setState(
				{
					agency_details: this.props.agency_details,
					projected_camera_ship_date: this.props.shipping_details.order_dates.projected_camera_ship_date.value
				});
		}

		if (this.props.pricing_details !== prevProps.pricing_details) {
			this.setState(
				{
					pricing_details: this.props.pricing_details,
				});
		}
        if (prevProps.shipping_details !== this.props.shipping_details && this.props.shipping_details.pre_trip_material_address && this.props.shipping_details.pre_trip_material_address.country && this.props.shipping_details.pre_trip_material_address.country.title && this.props.shipping_details.pre_trip_material_address.country.title === 'France') {
            this.setState({
                ptma_initial_country_France : true
            })
        }
        if (prevProps.shipping_details !== this.props.shipping_details && this.props.shipping_details.video_shipping_address && this.props.shipping_details.video_shipping_address.country && this.props.shipping_details.video_shipping_address.country.title && this.props.shipping_details.video_shipping_address.country.title === 'France') {
            this.setState({
                vsa_initial_country_France : true
            })
        }
        if (this.props.childState){
            this.props.childState(this.state.is_france, this.state.ptma_initial_country_France, this.state.ptma_initial_country_France, this.state.vsa_initial_country_France, this.state.ptma_is_france, this.state.vsa_is_france)
        }
        if (this.state.editShippingModal !== prevState.editShippingModal){
            this.setState({
                sameAddress: false
            })
        }

		// if (this.props.shipping_details.camera_ship_status !== prevProps.shipping_details.camera_ship_status) {
		// 	// console.log('this shipping details', this.props.shipping_details.camera_ship_status);
		// 	// console.log('old shipping details', prevProps.shipping_details.camera_ship_status);
		// 	if (this.props.shipping_details.camera_ship_status) {
		// 		if (!prevProps.shipping_details.camera_ship_status && this.props.shipping_details.camera_ship_status.id) {
		// 			// console.log('call get order 1')
		// 			this.props.getOrderCall(this.props.orderId);
		// 		}
		// 		else if (prevProps.shipping_details.camera_ship_status) {
		// 			if (this.props.shipping_details.camera_ship_status.id !== prevProps.shipping_details.camera_ship_status.id) {
		// 				// console.log('call get order 2')
		// 				this.props.getOrderCall(this.props.orderId);
		// 			}
		// 		}
		// 	}
		// }
	}
	componentDidMount() {
		this.props.getCountriesCall();
		this.props.getAllShippingMethodsCall();
	}

	returnAddressObjectForComparison = (obj) => {
		const newObj = Object.assign({},
			{
				// street_address_1: obj.street_address_1,
				street_address_2: obj.street_address_2,
				city: obj.city,
				state_id: obj.state_id,
				country_id: obj.country_id,
				zipcode: obj.zipcode,
				country: obj.country,
				state: obj.state,
                street_address_1: obj.street_address_1,
			})
		return newObj;
	}

	addressDetail(addressObject, item) {
		// let agency_primary_address;
		let color = '';
        let is_agency_Address = false;
		if (this.state.agency_details) {
			if (!isEmpty(this.state.agency_details.primary_address)) {
				const agency_primary_address = this.returnAddressObjectForComparison(this.state.agency_details.primary_address);
				const tempAddressObject = this.returnAddressObjectForComparison(addressObject);
                // console.log('agency primary', agency_primary_address);
                // console.log('tempAddressObject', tempAddressObject);
        //         console.log("is_ptm_address_france", this.props.is_ptm_address_france);
        // console.log("is_video_address_france", this.props.is_video_address_france);
				if (isEqual(agency_primary_address, tempAddressObject)) {
					color = 'red';
                    is_agency_Address = true;

				}
				else {
					color = '';
				}
			}
		}
		// const shipping_details = this.props.shipping_details;
		const order_details = this.props.order_details;

		return (

			        <React.Fragment>
                        <tr >
                            <td className="td_heading">{item} Shipping Address</td>
                            <td className="td_heading">
                                <OverlayTrigger placement='top' overlay={<Popover id='copyLinkFr' >Copy to clipboard</Popover>} >

                                    {
                                        (addressObject && addressObject.country && addressObject.country.title == 'France') ?
											<>
											{
												order_details && order_details.group_name &&
												<CopyToClipboard text={
													`${this.state.agency_details.name}\n`+
													`${is_agency_Address ? '' : order_details.group_name+'\n'}`+
													`${is_agency_Address ? order_details.agency_sales_rep.name : order_details.group_leader_contact.name}\n`+
													`${addressObject.street_address_1}\n`+
													`${ addressObject.street_address_2 ? addressObject.street_address_2 + '\n' : '' }`+
													`${addressObject.zipcode && addressObject.zipcode ? addressObject.zipcode : ''} ${addressObject.city}, ${addressObject.country.title}`
												}
												>

												<button className="orderDetailsAction copyColorAction" type="button">
													<span className="">&nbsp;</span>
												</button>
												</CopyToClipboard>
											}
											</>
										:
										(addressObject) ?
											<>
											{
												order_details && order_details.group_name &&
												<CopyToClipboard text={
													`${is_agency_Address ? this.state.agency_details.name : order_details.group_name}\n`+
													`${is_agency_Address ? order_details.agency_sales_rep.name : order_details.group_leader_contact.name}\n`+
													`${addressObject.street_address_1}\n`+
													`${addressObject.street_address_2 ? addressObject.street_address_2 + '\n' : '' }`+
													`${addressObject.city}, ${addressObject.state && addressObject.state.code && addressObject.state.code ? addressObject.state.code : ''} ${addressObject.zipcode && addressObject.zipcode ? addressObject.zipcode : ''}`
												}
												>


													<button className="orderDetailsAction copyColorAction" type="button">
														<span className="">&nbsp;</span>
													</button>
												</CopyToClipboard>
											}
											</>
										:
										''
                                    }
                                </OverlayTrigger>
                            </td>
                        </tr>

                        {
                            (addressObject && addressObject.country && addressObject.country.title == 'France') ?

                                <>
                                    <tr style={{ color: color }}>
                                        <td className={item === "Camera" ? "" : ""}>
                                            {addressObject.street_address_1} {addressObject.street_address_2 ? addressObject.street_address_2 : ''}
                                        </td>
                                    </tr>

                                    <tr style={{ color: color }}>
                                        {/* <td className={item === "Camera" ? "" : ""}>{addressObject.city || addressObject.zipcode ? addressObject.zipcode.concat(" ", addressObject.city.concat(", ",addressObject.country.title)) : ''} </td> */}
                                        <td className={item === "Camera" ? "" : ""}>{(addressObject.zipcode ? addressObject.zipcode : '') + ' ' + addressObject.city + ', ' + addressObject.country.title} </td>
                                    </tr>

                                    {/* <tr style={{ color: color }}>
                                        <td className={item === "Camera" ? "" : ""}>{addressObject.country ? addressObject.country.title : ''}</td>
                                    </tr> */}
                                </>

                                :
								(addressObject) ?
									<>
										<tr style={{ color: color }}>
											<td className={item === "Camera" ? "" : ""}>{addressObject.street_address_1}</td>
										</tr>
										{
											addressObject.street_address_2 &&
											<tr style={{ color: color }}>
												<td className={item === "Camera" ? "" : ""}>{addressObject.street_address_2}</td>
											</tr>
										}
										<tr style={{ color: color }}>
											{/* <td className={item === "Camera" ? "" : ""}>{addressObject.city || addressObject.zipcode ? addressObject.city.concat(", ", addressObject.state && addressObject.state.title && addressObject.state.title) + ' ' : ''}{addressObject.country || addressObject.state ? addressObject.zipcode + ' ' + addressObject.country.title : ''}</td> */}
											<td className={item === "Camera" ? "" : ""}>{addressObject.city && addressObject.city}{addressObject.state && addressObject.state.title && ', ' +addressObject.state.title} {addressObject.zipcode ? addressObject.zipcode :  ''} {addressObject.country.title}</td>
										</tr>
									</>
								:
								''
                        }
                    </React.Fragment>
		);
	}

	samePreTripAddressHandler = (e) => {
		const target = e.target;
		this.setState({
			sameAddress: target.checked
		}, () => {
			if (target.checked) {
				const data = this.state.shipping_details.pre_trip_material_address;
				delete data.id;
				this.setState((state) => {
					return {
						shipping_details: {
							...state.shipping_details,
							video_shipping_address: { ...data }
						}
					}
				},() => {
                    if (this.state.shipping_details.video_shipping_address.country.title === "France") {
                        this.setState({
                            vsa_is_france: true,
                            vsa_initial_country_France: true
                        })
                    }
                    if (this.state.shipping_details.video_shipping_address.country.title !== "France") {
                        this.setState({
                            vsa_is_france: false,
                            vsa_initial_country_France: false
                        })
                    }
                })
			}
		})
	}

	projectCameraShipDateChangeHandler = (value) => {
		// console.log('value', value);
        let date = moment(new Date(value)).format('YYYY-MM-DD 00:00:00');

		this.setState(prevState => ({
			...prevState, shipping_details: {
				...prevState.shipping_details, order_dates: {
					...prevState.shipping_details.order_dates, projected_camera_ship_date: {
						...prevState.shipping_details.order_dates.projected_camera_ship_date, value: date
					}
				}
			}
		}), () => {
			// console.log('orderDates shipping details', this.state.shipping_details.order_dates);
		});


		// this.setState({
		// 	projected_camera_ship_date: value === null ? new Date() : value
		// }, ()=>{
		// 	console.log('projected camera ship', this.state.projected_camera_ship_date);
		// });
	}

	projectCameraShipDateFormHandler = (e) => {
		e.preventDefault();
		const data = {
			slug: this.state.shipping_details.order_dates.projected_camera_ship_date.slug,
			value: this.state.shipping_details.order_dates.projected_camera_ship_date.value,
		}
		this.props.updateProjectCameraShipDateCall(this.props.orderId, data);
	}

	updateShippingDetatilsFormHandler = () => {
        // return
        try{
            if (this.state.ptma_initial_country_France || this.state.ptma_is_france) {

                this.validatorShippingDetail.fields.PreTripMaterialAddressState = true;
                this.validatorShippingDetail.errorMessages.PreTripMaterialAddressState = null;
            }
            if (this.state.vsa_initial_country_France || this.state.vsa_is_france) {

                this.validatorShippingDetail.fields.VideoShippingAddressState = true;
                this.validatorShippingDetail.errorMessages.VideoShippingAddressState = null;
            }
        }catch (e) {
            console.log("eeeeeee", e);
        }
		if (this.validatorShippingDetail.allValid()) {
			const ShippingDetails = {
				shipping_details: {
					pre_trip_material_address: this.ctrl_PTMA.returnAddressData(),
					video_shipping_address: this.ctrl_VSA.returnAddressData()
				}
			}
			this.props.updateShippingDetailsCall(this.props.orderId, ShippingDetails).then(
				response => {
					this.setState({
						editShippingModal: false
					})
				}
			);
		} else {
			this.validatorShippingDetail.showMessages();
			this.forceUpdate();
		}
	}

	clearProduction = () => {
		const data = this.props.order_details.order_dates.receive_date;
		data.value = null;
		this.props.updateOrderDate(this.props.orderId, data).then(
			res => {
				this.props.getOrder(this.props.orderId)
			}
		)
	}

	toggleOrderEquipmentStatus = () => {
		const value = !this.props.production_details.no_equipment_upload_only;
		const data = { value };
		this.props.toggleOrderEquipmentStatusCall(this.props.orderId, data);
	}

    getChildState = (childState) => {
        this.setState({
            is_france: childState
        })
    }

    getChildrenStateVsa = (state) =>{
        this.setState({
            vsa_is_france: state
        })
    }

    getChildrenStatePtma = ( childrenState ) => {
        this.setState({
            ptma_is_france : childrenState
        })
    }
    setStateOfParent = (newValue) => {
        this.setState({
                ptma_initial_country_France: newValue
            });
    }
    setStateOfParentVsa = (newValue) => {
        this.setState({
            vsa_initial_country_France: newValue
            });
    }

    refundLabel = (shipStatus) => {
        let data = {
            label_id: shipStatus.label_id,
            shipping_id: shipStatus.id,
            order_id: this.props.orderId
        }
        let showErrorMessage = '';
        shippingService.refundLabel(data)
            .then(response => {
                if (response.errors) {
                    this.props.error(response.errors[0].error_message);
                } else if (!response.errors) {
					this.props.getOrder(this.props.orderId);
                    this.props.success('The Label is Refund Successfully');
                }
            })
            .catch(err => {
                Object.keys(err).map(x => {
                    showErrorMessage = err[x][0];
                    this.props.error(showErrorMessage);
                    });
            });
    }

    refundLabelConfirm = (shippingDetails) => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <ConfirmEditTracking typeName="Refund" description="This will void your shipping label?" onClosePro={onClose} editModel={() => this.refundLabel(shippingDetails)} />
                )
            }
        })
    }

    downloadLabel = (shipStatus) => {
        ShippingService.fetchReprintLabel(shipStatus.label_id)
            .then(response => {
                console.log("DATA FROM SHIPPING ADDRESS ", response.labels);
                if (response.labels[0]) {
                    window.open(response.labels[0].href);
                }
            })
            .catch(err => {
                console.log("ERROR OCCURED", err);
            });
    }


	render() {
        // this.validatorShippingDetail.purgeFields();
		const { editShippingModal, cameraShippingModal, videoShippingModal, mosaicShippingModal, returnShippingModal, shipping_details, pricing_details, is_france, ptma_initial_country_France, vsa_initial_country_France, ptma_is_france, vsa_is_france } = this.state;
		const {
			countries,
			order_details,
			production_details,
            is_ttk
		} = this.props;
		return (
			<div className="orderContent">
				<div className="flexElem" style={{ "flexWrap": "wrap", "justifyContent": "space-between" }}>
					<div className="orderDetailsHeader" id="orderDetailsHeader">
						<span>Shipping Details</span>

						<span className="controls">
							<OpenModalButton
								openModal={openModal.bind(this, "editShippingModal")}
								classButton={['editIcon orderDetailsAction']}
								buttonName="Edit Shipping Details"
								tooltipText="Edit Shipping Details"
								classIcon={[]}
							/>

						</span>
					</div>

					<div className="orderDetailsBox box_small">
						<table className="table orderTable" style={{ "marginTop": "0" }}>
							<tbody>{this.addressDetail(shipping_details['pre_trip_material_address'], 'Pre-Trip Material')}</tbody>
						</table>

						<table className="table orderTable">
							<tbody>{this.addressDetail(shipping_details['video_shipping_address'], 'Video')}</tbody>
						</table>

						{
							shipping_details.camera_ship_status === null
								?
								<table className="table orderTable">
									<tbody>
										<tr>
											<td className="td_heading">Projected Pre-Trip Materials Ship Date</td>
										</tr>
										{
											'order_dates' in shipping_details &&
											<tr>
												<td>
													<form className="flexElem orderSettingText flexResponsive" style={{ "alignItems": "stretch" }} onSubmit={this.projectCameraShipDateFormHandler} >
														<DatePicker
															className="form-control calender_icon mt-md"
															onChange={this.projectCameraShipDateChangeHandler}
															selected={shipping_details.order_dates.projected_camera_ship_date.value ? new Date(shipping_details.order_dates.projected_camera_ship_date.value) : new Date()}
															minDate={Date.now()}
														/>
														<Button type='submit' className="customBtn btn ml-sm btn-primary" style={{ "marginTop": "15px" }}>Change</Button>
													</form>
												</td>
											</tr>
										}
									</tbody>
								</table>
								:
								<table className="table orderTable">
									<tbody>
										<tr>
											<td className="td_heading">Pre-Trip Materials Process Date </td>
										</tr>
										<tr>
											<td>
												{
													shipping_details.camera_ship_status != null &&
													dateFormatMy(shipping_details.camera_ship_status.shipped_date)
												}
											</td>
										</tr>
										{/* <tr>
											<td>
												{
													shipping_details.camera_ship_status != null &&
														!isEmpty(shipping_details.camera_ship_status.shipping_method) ?
														<Button className="customBtn btn btn-primary mt-sm" onClick={this.props.processShipStatusCall.bind(this, this.props.orderId, { status: 0, slug: 'camera' })} >Unprocess Pre-Trip Materials Shipment</Button>
														: null
												}
											</td>
										</tr> */}
									</tbody>
								</table>
						}
					</div>
					<div className="orderDetailsBox box_large">
						{
							shipping_details ?
								<table className='table mt-none orderTable ship-status-table' style={{ borderCollapse: 'separate', borderSpacing: '2px' }} >
									<tbody >
										<tr>
											<td className="td_heading" style={{ "verticalAlign": "top" }}>Pre-Trip Materials Ship Status</td>
											<td>
												<div className='row flexElem align-items-center'>
													{
														<div className='col-md-8'>
															{
																shipping_details.camera_ship_status != null &&
																	'shipped_date' in shipping_details.camera_ship_status != null
																	?
																	<div>
																		<strong className="innerLabel">Shipped Date: </strong>
																		{new Date(shipping_details.camera_ship_status.shipped_date).toDateString()}
																	</div>
																	:
																	null
															}
															{
																shipping_details.camera_ship_status != null &&
																	!isEmpty(shipping_details.camera_ship_status.shipping_method) ?
																	<div>
																		<strong className="innerLabel">Shipping Method: </strong>
																		{shipping_details.camera_ship_status.shipping_method.title}
																	</div>
																	:
																	null
															}
															{/* {shipping_details.camera_ship_status != null &&
																'process_date' in shipping_details.camera_ship_status != null ?
																<div>
																	<strong className="innerLabel">Processed Date: </strong>
																	{new Date(shipping_details.camera_ship_status.process_date).toDateString()}
																</div>
																:
																null
															} */}
														</div>
													}
													<div className='col-md-4 text-right'>
														<div className="refundStyle">
															<div>
																{
																	shipping_details.camera_ship_status && shipping_details.camera_ship_status.tracking_number != null &&
																	shipping_details.camera_ship_status.shipping_method.tracking_url != null &&
																	// new Date(order_details.order_dates.departure_date.value) > Date.now() &&
																	shipping_details.camera_ship_status.label_id !== null ?
																	<React.Fragment>
                                                                        <OverlayTrigger placement='bottom' overlay={<Popover id='refund' >Refund</Popover>} >
																			<a rel="noopener noreferrer" onClick={() => {this.refundLabelConfirm(shipping_details.camera_ship_status)}}  className='assignIcon orderDetailsAction blue_link mr-sm' >&nbsp;</a>
                                                                        </OverlayTrigger>
                                                                        <OverlayTrigger placement='bottom' overlay={<Popover id='download_label' >Download Label</Popover>} >
																			<a rel="noopener noreferrer" onClick={() => {this.downloadLabel(shipping_details.camera_ship_status)}} target="_blank" className="downloadIcon orderDetailsAction blue_link mr-sm">&nbsp;</a>
                                                                        </OverlayTrigger>
																	</React.Fragment>

																	: null
																}
																<OpenModalButton
																	openModal={openModal.bind(this, 'cameraShippingModal')}
																	classButton={['editIcon orderDetailsAction']}
																	buttonName='&nbsp;'
																	name={'Pre-Trip Materials Ship Status'}
																	tooltipText={((shipping_details.camera_ship_status && shipping_details.camera_ship_status.tracking_number) || order_details.settings.finalize_by_qa.value == 1) ? 'Are you sure you want to edit this shipping?' : 'change'}
																	tracking_number={shipping_details.camera_ship_status && shipping_details.camera_ship_status.tracking_number }
																	// disabled={shipping_details.camera_ship_status ? shipping_details.camera_ship_status.tracking_number : false}
																	classIcon={[]}
																	log={console.log("logggg", (order_details.order_dates.receive_date.value || order_details.settings.finalize_by_qa.value == 1) ? true : false)}
																	notChangeable={((shipping_details.camera_ship_status && shipping_details.camera_ship_status.tracking_number && order_details.order_dates.receive_date.value) || order_details.settings.finalize_by_qa.value == 1) ? true : false}
																/>
															</div>
															<div>
																{
																	shipping_details.camera_ship_status != null ?
																		shipping_details.camera_ship_status.shipping_method != null ?
																			shipping_details.camera_ship_status.tracking_number != null && shipping_details.camera_ship_status.shipping_method.tracking_url != null ?
																							<a rel="noopener noreferrer" target='_blank' href={shipping_details.camera_ship_status.shipping_method.tracking_url} className='trackIcon orderDetailsAction blue_link' >Track</a>
																					: shipping_details.camera_ship_status.tracking_number != null && shipping_details.camera_ship_status.shipping_method.tracking_url == null ?
																					shipping_details.camera_ship_status.tracking_number
																					: null
																			: null
																		: null
																}
															</div>


														</div>
													</div>
												</div>
											</td>
										</tr>
										{/* <tr>
											<td className="td_heading" style={{ "verticalAlign": "top" }}>Return Label Ship Status</td>
											<td>
												<div className='row flexElem align-items-center' >
													<div className='col-md-9' >
														{shipping_details.return_label_ship_status != null &&
															'shipped_date' in shipping_details.return_label_ship_status != null ?
															<div>
																<strong className="innerLabel">Shipped Date: </strong>
																{new Date(shipping_details.return_label_ship_status.shipped_date).toDateString()}
															</div>
															:
															null
														}
														{
															shipping_details.return_label_ship_status !== null &&
																!isEmpty(shipping_details.return_label_ship_status) ?
																<div>
																	<strong className="innerLabel">Shipping Method: </strong>
																	{shipping_details.return_label_ship_status.shipping_method.title}
																</div>
																:
																null
														}
													</div>
													<div className='col-md-3 text-right' >
														<OpenModalButton
															openModal={openModal.bind(this, 'returnShippingModal')}
															classButton={['editIcon orderDetailsAction']}
															buttonName='&nbsp;'
                                                            name={'Return Label Ship Status'}
															tooltipText={shipping_details.return_label_ship_status && shipping_details.return_label_ship_status.tracking_number ? 'change' : 'change'}
															tracking_number={shipping_details.return_label_ship_status && shipping_details.return_label_ship_status.tracking_number }
															// disabled={shipping_details.return_label_ship_status ? shipping_details.return_label_ship_status.tracking_number : false}
															classIcon={[]} />
														{
															shipping_details.return_label_ship_status != null ?
																shipping_details.return_label_ship_status.shipping_method != null ?
																	shipping_details.return_label_ship_status.tracking_number != null && shipping_details.return_label_ship_status.shipping_method.tracking_url != null ?
																		<a rel="noopener noreferrer" target='_blank' href={shipping_details.return_label_ship_status.shipping_method.tracking_url} className='trackIcon orderDetailsAction blue_link' >Track</a>
																		: null
																	: null
																: null
														}
													</div>
												</div>
											</td>
										</tr> */}
										{
                                            !is_ttk &&
                                            <tr>
                                                <td className="td_heading" style={{ "verticalAlign": "top" }}>Video Ship Status</td>
                                                <td>
                                                    <div className='row flexElem align-items-center' >
                                                        <div className='col-md-9' >
                                                            {shipping_details.video_ship_status != null &&
                                                                'shipped_date' in shipping_details.video_ship_status != null ?
                                                                <div>
                                                                    <strong className="innerLabel">Shipped Date: </strong>
                                                                    {new Date(shipping_details.video_ship_status.shipped_date).toDateString()}
                                                                </div>
                                                                :
                                                                null
                                                            }
                                                            {
                                                                shipping_details.video_ship_status !== null &&
                                                                    !isEmpty(shipping_details.video_ship_status.shipping_method) ?
                                                                    <div>
                                                                        <strong className="innerLabel">Shipping Method: </strong>
                                                                        {shipping_details.video_ship_status.shipping_method.title}
                                                                    </div>
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                        <div className='col-md-4 text-right' >
															<div className="refundStyle">
																<div>
																	{
																		shipping_details.video_ship_status && shipping_details.video_ship_status.tracking_number != null &&
																		shipping_details.video_ship_status.shipping_method.tracking_url != null &&
																		// new Date(order_details.order_dates.departure_date.value) > Date.now() &&
																		shipping_details.video_ship_status.label_id !== null ?
																		<React.Fragment>
																			<OverlayTrigger placement='bottom' overlay={<Popover id='refund' >Refund</Popover>} >
																				<a rel="noopener noreferrer" onClick={() => {this.refundLabelConfirm(shipping_details.video_ship_status)}}  className='assignIcon orderDetailsAction blue_link mr-sm' >&nbsp;</a>
																			</OverlayTrigger>
																			<OverlayTrigger placement='bottom' overlay={<Popover id='download_label' >Download Label</Popover>} >
																				<a rel="noopener noreferrer" onClick={() => {this.downloadLabel(shipping_details.video_ship_status)}} target="_blank" className="downloadIcon orderDetailsAction blue_link mr-sm">&nbsp;</a>
																			</OverlayTrigger>
																		</React.Fragment>

																		: null
																	}
																	<OpenModalButton
																		openModal={openModal.bind(this, 'videoShippingModal')}
																		classButton={['editIcon orderDetailsAction']}
																		buttonName='&nbsp;'
																		name={'Video Ship Status'}
																		tooltipText={shipping_details.video_ship_status && shipping_details.video_ship_status.tracking_number ? 'change' : 'change'}
																		tracking_number={shipping_details.video_ship_status && shipping_details.video_ship_status.tracking_number }
																		// disabled={shipping_details.video_ship_status ? shipping_details.video_ship_status.tracking_number : false}
																		classIcon={[]} />
																</div>
																<div>
																	{
																		shipping_details.video_ship_status != null ?
																			shipping_details.video_ship_status.shipping_method != null ?
																				shipping_details.video_ship_status.tracking_number != null && shipping_details.video_ship_status.shipping_method.tracking_url != null ?
																					<a rel="noopener noreferrer" target='_blank' href={shipping_details.video_ship_status.shipping_method.tracking_url} className='trackIcon orderDetailsAction blue_link' >Track</a>
																					: null
																				: null
																			: null
																	}
																</div>
															</div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        }
										{
											'20x30 PhotoMozaix' in pricing_details.order_items && !isEmpty(pricing_details.order_items) && pricing_details.order_items['20x30 PhotoMozaix'].quantity > 0 &&
											<tr >
												<td className="td_heading" style={{ "verticalAlign": "top" }}>Mozaix Ship Status</td>
												<td>
													<div className='row flexElem align-items-center' >
														<div className='col-md-9' >
															{shipping_details.mosaic_ship_status != null &&
																'shipped_date' in shipping_details.mosaic_ship_status != null ?
																<div>
																	<strong className="innerLabel">Shipped Date: </strong>
																	{new Date(shipping_details.mosaic_ship_status.shipped_date).toDateString()}
																</div>
																:
																null
															}
															{
																shipping_details.mosaic_ship_status !== null &&
																	!isEmpty(shipping_details.mosaic_ship_status.shipping_method) ?
																	<div>
																		<strong className="innerLabel">Shipping Method: </strong>
																		{shipping_details.mosaic_ship_status.shipping_method.title}
																	</div>
																	:
																	null
															}
														</div>
														<div className='col-md-4 text-right' >
															<div className="refundStyle">
																<div>
																	{
																		shipping_details.mosaic_ship_status && shipping_details.mosaic_ship_status.tracking_number != null &&
																		shipping_details.mosaic_ship_status.shipping_method.tracking_url != null &&
																		// new Date(order_details.order_dates.departure_date.value) > Date.now() &&
																		shipping_details.mosaic_ship_status.label_id !== null ?
																		<React.Fragment>
																			<OverlayTrigger placement='bottom' overlay={<Popover id='refund' >Refund</Popover>} >
																				<a rel="noopener noreferrer" onClick={() => {this.refundLabelConfirm(shipping_details.mosaic_ship_status)}}  className='assignIcon orderDetailsAction blue_link mr-sm' >&nbsp;</a>
																			</OverlayTrigger>
																			<OverlayTrigger placement='bottom' overlay={<Popover id='download_label' >Download Label</Popover>} >
																				<a rel="noopener noreferrer" onClick={() => {this.downloadLabel(shipping_details.mosaic_ship_status)}} target="_blank" className="downloadIcon orderDetailsAction blue_link mr-sm">&nbsp;</a>
																			</OverlayTrigger>
																		</React.Fragment>

																		: null
																	}
																	<OpenModalButton
																		openModal={openModal.bind(this, 'mosaicShippingModal')}
																		classButton={['editIcon orderDetailsAction']}
																		buttonName='&nbsp;'
																		name={'Mosaix Ship Status'}
																		tooltipText={shipping_details.mosaic_ship_status && shipping_details.mosaic_ship_status.tracking_number ? 'change' : 'change'}
																		tracking_number={shipping_details.mosaic_ship_status && shipping_details.mosaic_ship_status.tracking_number }
																		// disabled={shipping_details.mosaic_ship_status ? shipping_details.mosaic_ship_status.tracking_number : false}
																		classIcon={[]} />
																</div>
																<div>
																	{
																		shipping_details.mosaic_ship_status != null ?
																			shipping_details.mosaic_ship_status.shipping_method != null ?
																				shipping_details.mosaic_ship_status.tracking_number != null && shipping_details.mosaic_ship_status.shipping_method.tracking_url != null ?
																					<a rel="noopener noreferrer" target='_blank' href={shipping_details.mosaic_ship_status.shipping_method.tracking_url} className='trackIcon orderDetailsAction blue_link' >Track</a>
																					: null
																				: null
																			: null
																	}
																</div>
															</div>
														</div>
													</div>
												</td>
											</tr>
										}

									</tbody>
								</table>
								:
								null
						}
						{
							'title' in order_details.program_type
								?
								shipping_details.camera_ship_status && (!order_details.program_type.title.toLowerCase().includes('upload') && !order_details.program_type.title.toLowerCase().includes('demo')) &&
								<div>
									<AssignedItemShowAndModal camera_ship_status={shipping_details.camera_ship_status} order_details={order_details} assignedItems={shipping_details.assignedItems} orderId={this.props.orderId} shipping_details={shipping_details} pricing_details={pricing_details} />
								</div>
								:
								null
						}
						{
							!isEmpty(shipping_details) && !isEmpty(shipping_details.assignedItems) && shipping_details.assignedItems.length > 0 &&
							<div>
								<table className="table orderTable">
									<tbody>
										<tr>
											<td className="td_heading" style={{ "verticalAlign": "top" }} >Order Equipment Status</td>
										</tr>
										<tr>
											<td>&nbsp;</td>
										</tr>
										<tr>
											<td>
												{
													production_details.no_equipment_upload_only
														?
														<button onClick={this.toggleOrderEquipmentStatus} className="returningIcon orderDetailsAction">Equipment is Returning</button>
														:
														<button onClick={this.toggleOrderEquipmentStatus} className="editIcon orderDetailsAction">Equipment Not Returning-Upload Only</button>

												}
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						}
					</div>
				</div>

				<Modal backdrop={'static'} show={editShippingModal} onHide={closeModal.bind(this, "editShippingModal")} aria-labelledby="ModalHeader" className="editOrderDetail" >
					<Modal.Header closeButton>
						<Modal.Title id='ModalHeader' className="headerTitle">Edit Order Information</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div className="row customHalf lessSpace">
							<div className="col-md-6">
								<Card title="Pre-Trip Material Address" bsClass={['innerCard']} content={
									<React.Fragment>
										<AddressForm
											orderForm
											provideCtrl={ctrl => this.ctrl_PTMA = ctrl}
											htmlElement="PreTripMaterialAddress"
											clearState={this.clearState}
											type="pre_trip_material_address"
											options={
												{
													'countries': countries
												}
											}
											adressObject={shipping_details.pre_trip_material_address}
											title="Pre-Trip Material Address"
											validatorInstance={this.validatorShippingDetail}
											requiredField={

                                                    {
                                                        'street_address_1': 'required',
                                                        'country_id': 'required',
                                                        'city': 'required',
                                                        'state_id': !(ptma_initial_country_France || ptma_is_france) ? ['required'] : '',
                                                        // 'zipcode': 'required'
                                                    }

											}
											isClearableCountryPro={false}
                                            childState={this.getChildState}
                                            childrenStatePtma={this.getChildrenStatePtma}
                                            // childrenStateVsa={this.getChildrenStateVsa}
                                            setStateOfParent={this.setStateOfParent}
										/>
										<div className='text-right'>
											{
												order_details && order_details.group_name &&
												<OverlayTrigger placement='top' overlay={<Popover id='copyLinkMaterial' >Copy to clipboard</Popover>} >
													<CopyToClipboard text={
														`${order_details && order_details.group_name && order_details.group_name}\n`+
														`${order_details && order_details.group_leader_contact.name}\n`+
														`${shipping_details.pre_trip_material_address.street_address_1}\n`+
														`${shipping_details.pre_trip_material_address.street_address_2 ? shipping_details.pre_trip_material_address.street_address_2 + '\n' : '' }`+
														`${shipping_details.pre_trip_material_address.city}, ${shipping_details.pre_trip_material_address.state && shipping_details.pre_trip_material_address.state.code && shipping_details.pre_trip_material_address.state.code ? shipping_details.pre_trip_material_address.state.code : ''} ${shipping_details.pre_trip_material_address.zipcode ? shipping_details.pre_trip_material_address.zipcode : ''}`
													}
													>

														<button className="orderDetailsAction copyColorAction" type="button">
															<span className="">&nbsp;</span>
														</button>
													</CopyToClipboard>
												</OverlayTrigger>
											}
										</div>
									</React.Fragment>
								}
								/>
							</div>

							<div className="col-md-6 pull-right">
								<Card title="Video Shipping Address" bsClass={['innerCard']} content={
									<React.Fragment>
										<AddressForm
											orderForm
											provideCtrl={ctrl => this.ctrl_VSA = ctrl}
											htmlElement="VideoShippingAddress"
											clearState={this.clearState}
											type="video_shipping_address"
											options={
												{
													'countries': countries
												}
											}
											adressObject={shipping_details.video_shipping_address}
											title="Video Shipping Address"
											validatorInstance={this.validatorShippingDetail}
											requiredField={
                                                    {
                                                        'street_address_1': 'required',
                                                        'country_id': 'required',
                                                        'city': 'required',
                                                        'state_id': !(vsa_initial_country_France || vsa_is_france) ? ['required'] : '',
                                                        // 'zipcode': 'required'
                                                    }
											}
											isClearableCountryPro={false}
                                            // childrenStatePtma={this.getChildrenStatePtma}
                                            childrenStateVsa={this.getChildrenStateVsa}
                                            setStateOfParentVsa={this.setStateOfParentVsa}
										/>
										<div className='text-right'>
											{
												order_details && order_details.group_name &&
												<OverlayTrigger placement='top' overlay={<Popover id='copyLinkVid' >Copy to clipboard</Popover>} >
													<CopyToClipboard text={
														`${order_details.group_name}\n`+
														`${order_details.group_leader_contact.name}\n`+
														`${shipping_details.video_shipping_address.street_address_1}\n`+
														`${shipping_details.video_shipping_address.street_address_2 ? shipping_details.video_shipping_address.street_address_2 + '\n' : '' }`+
														`${shipping_details.video_shipping_address.city}, ${shipping_details.pre_trip_material_address.state && shipping_details.pre_trip_material_address.state.code && shipping_details.pre_trip_material_address.state.code ? shipping_details.pre_trip_material_address.state.code : ''} ${shipping_details.video_shipping_address.zipcode ? shipping_details.pre_trip_material_address.zipcode : ''}`
													}
													>
														<button className="orderDetailsAction copyColorAction" type="button">
															<span className="">&nbsp;</span>
														</button>
													</CopyToClipboard>
												</OverlayTrigger>
											}
										</div>
									</React.Fragment>
								}
								/>
								<div className="flexElem" style={{ "justifyContent": "space-between", "alignItems": "center" }}>
									<label className="container-check">Same As Pre-Trip Material Address
										<input checked={this.state.sameAddress} onChange={this.samePreTripAddressHandler} type='checkbox' className='checkbox' />
										<span className="checkmark"></span>
									</label>

									<div className='text-right' >
										<Button bsSize='large' type='button' className="backButton pt-sm no_radius pb-sm primary btn btn-md btn-info" onClick={this.updateShippingDetatilsFormHandler} >Save</Button>
									</div>
								</div>
							</div>
						</div>
					</Modal.Body>
				</Modal>
				<Modal backdrop={'static'} show={cameraShippingModal} onHide={closeModal.bind(this, "cameraShippingModal")} aria-labelledby="ModalHeader" >
					<Modal.Header closeButton>
						<Modal.Title id='ModalHeader' className="headerTitle">Shipping Information</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div className="clearfix">
							<Card bsClass={['innerCard']} content={
								<ShipStatusForm dataCall={() => this.props.getOrder(this.props.orderId)} onHide={closeModal.bind(this, "cameraShippingModal")} orderId={this.props.orderId} item='camera' shippingDetails={shipping_details.camera_ship_status} />
							} />
						</div>
					</Modal.Body>
				</Modal>
				<Modal backdrop={'static'} show={videoShippingModal} onHide={closeModal.bind(this, "videoShippingModal")} aria-labelledby="ModalHeader" >
					<Modal.Header closeButton>
						<Modal.Title id='ModalHeader' className="headerTitle">Shipping Information</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div className="clearfix">
							<ShipStatusForm dataCall={() => this.props.getOrder(this.props.orderId)} onHide={closeModal.bind(this, "videoShippingModal")} orderId={this.props.orderId} item='video' shippingDetails={shipping_details.video_ship_status} />
						</div>
					</Modal.Body>
				</Modal>
				<Modal backdrop={'static'} show={mosaicShippingModal} onHide={closeModal.bind(this, "mosaicShippingModal")} aria-labelledby="ModalHeader" >
					<Modal.Header closeButton>
						<Modal.Title id='ModalHeader' className="headerTitle">Shipping Information</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div className="clearfix">
							<ShipStatusForm dataCall={() => this.props.getOrder(this.props.orderId)} onHide={closeModal.bind(this, "mosaicShippingModal")} orderId={this.props.orderId} item='mosaic' shippingDetails={shipping_details.mosaic_ship_status}
                            video_ship_tracking_number={shipping_details.video_ship_status && shipping_details.video_ship_status.tracking_number }/>
						</div>
					</Modal.Body>
				</Modal>
				<Modal backdrop={'static'} show={returnShippingModal} onHide={closeModal.bind(this, "returnShippingModal")} aria-labelledby="ModalHeader" >
					<Modal.Header closeButton>
						<Modal.Title id='ModalHeader' className="headerTitle">Shipping Information</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div className="clearfix">
							<ShipStatusForm dataCall={() => this.props.getOrder(this.props.orderId)} onHide={closeModal.bind(this, "returnShippingModal")} orderId={this.props.orderId} item='return_label' shippingDetails={shipping_details.return_label_ship_status} />
						</div>
					</Modal.Body>
				</Modal>
			</div>
		);
	};
}


const mapDispatchToProps = dispatch => {
	return ({
		getCountriesCall: () => { dispatch(getCountries()) },
		editShippingDetailCall: (orderDetailData, type) => { dispatch(shippingDetailEdit(orderDetailData, type)) },
		updateShippingDetailsCall: (orderId, Data) => { return dispatch(updateShippingDetails(orderId, Data)) },
		updateProjectCameraShipDateCall: (orderId, Data) => { return dispatch(updateProjectCameraShipDate(orderId, Data)) },
		processShipStatusCall: (orderId, data) => { dispatch(processShipStatus(orderId, data)) },
		updateOrderDate: (id, data) => { return dispatch(updateOrderDate(id, data)) },
		toggleOrderEquipmentStatusCall: (orderId, data) => { dispatch(toggleOrderEquipmentStatus(orderId, data)) },
		getOrder: (id) => { dispatch(getOrder(id)) },
		success: (message) => { return dispatch(alertActions.success(message))},
		error: (message) => { return dispatch(alertActions.error(message))},
        getAllShippingMethodsCall: () => { dispatch(getAllShippingMethods()) },
	});
};

function mapStateToProps(state) {
	const { loadingSettings, countries } = state.settingsReducer;
	const { loadingOrder, order_details } = state.ordersReducer;

	return {
		loadingSettings, countries,
		loadingOrder,
		order_details
	};
}


const ShippingDetailShowAndModal = connect(mapStateToProps, mapDispatchToProps)(ShippingDetailShowAndModalPage);
export default ShippingDetailShowAndModal;
