import React, { Component } from "react";
import { connect } from 'react-redux';
import 'react-table/react-table.css';
import { openModal, closeModal } from 'helper/helperFunctions';
import { Grid, Row, Col, OverlayTrigger, Popover } from "react-bootstrap";
import { Card } from "components/Card/Card.jsx";
import OpenModalButton from 'components/ThemeComponents/openModelButton';
import { hasPermission } from 'helper/hasPermission';
import { adminLabels } from 'redux/constant/admin-label-constant';
import ItemForm from "./ItemForm";
import { objectsConstants as OC } from 'redux/constant/objects-constant';
import { getItemPagination, storeItem, editItem, updateItem, deleteItem } from 'redux/actions/item-actions';
import { getItemTypes, getItemCategories } from 'redux/actions/settings-actions';
import Confirmalertfordelete from 'components/ThemeComponents/confirmAlertForDelete';
import { confirmAlert } from 'react-confirm-alert';
import { Link } from 'react-router-dom';
import Pagination from "../../ThemeComponents/Pagination";
var Modal = require('react-bootstrap-modal');

class ItemListPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			createItemModal: false,
			editItemModal: false,
			sorted: '',
			filtered: '',
			pageSize: 10,
			page: 0,
		};
		this.props.getItemTypesCall();
		this.props.getItemCategoriesCall();
		// this.props.getItemsCall('Products');
		// this.props.getAgenciesCall();
	};
	itemPaginationData = (page = this.state.page, filtered = this.state.filtered, sorted = this.state.sorted, pageSize = this.state.pageSize) => {
		this.props.getItemsPaginationCall(page, filtered, sorted, pageSize);
	}
	filterSearchHandle = (e) => {
		this.setState({
			'filtered': e.target.value
		});
	}
	handleItemSubmit = (ItemData, formType) => {
		if (formType === 'create') {
			this.props.storeItemCall(ItemData).then(
				response => {
					this.setState({
						createItemModal: false
					}, function () {
						// this.itemPaginationData();
						this.pagination.dataCall();
					});
				}
			);
		}
		else if (formType === 'edit') {
			const { editItemFormType } = this.state;
			this.props.updateItemCall(ItemData, this.props.editItem.id, editItemFormType).then(
				response => {
					this.setState({
						editItemModal: false
					}, function () {
						// this.itemPaginationData();
						this.pagination.dataCall();
					});
				}
			);
		}
	}

	deleteItem = (itemId) => {
		this.props.deleteItemCall(itemId).then(
			response => {
				// const { page, pageSize } = this.state;
				// this.itemPaginationData();
				this.pagination.dataCall();
			}
		);
	}
	deleteConfirmItem = (itemId) => {
		confirmAlert({
			customUI: ({ onClose }) => {
				return (
					<Confirmalertfordelete typeName="Item" description="Are you sure to delete the item?" onClosePro={onClose} deleteType={() => this.deleteItem(itemId)} />
				)
			}
		})
	}

	componentDidUpdate(prevProps, nextProps) {
		if (prevProps.currentPageItem !== this.props.currentPageItem) {
			this.setState({ page: this.props.currentPageItem })
		}
		if ((prevProps.editItem !== this.props.editItem) && this.props.editItem) {
			this.setState({ editItemModal: true });
		}
	}

	paginationCall = (data) => {
		return this.props.getItemsPaginationCall(data.page, data.filter, data.sort, data.pageSize);
	}

	render() {
		// const { filtered } = this.state;
		const { editItem/*, itemsPagination, loadingItem, lastPageItem*/, itemTypes, itemCategories } = this.props;
		const columns = [
			{
				Header: "Item Listing",
				headerClassName: 'headerPagination',
				columns: [
					{
						Header: "Item Name",
						accessor: "title",
						className: "action-center",
						Cell: ({ value, original }) => (
							<div>
								{hasPermission('item-list') ? <Link to={'/Admin/inventories?item_id=' + original.id} className="blue_link">{original.title}</Link> : original.title}
							</div>
						),
					},
					{
						Header: "Category",
						accessor: "item_category.title",
						className: "action-center",
					},
					{
						Header: "Type",
						accessor: "item_type.title",
						className: "action-center",
					},
					{
						Header: "Price",
						accessor: "price",
						className: "action-center",
					},
					/*{
							Header: "Serial Number",
							accessor: "serial_number",
							className: "action-center",
					},
					{
							Header: "Barcode",
							width: 200,
							accessor: "barcode",
							className: "action-center",
					},*/
					{
						Header: "Actions",
						accessor: "id",
						Cell: ({ row }) => (
							<div>
								<OverlayTrigger placement="bottom" overlay={<Popover id="tooltip">{hasPermission('user-edit') ? 'Edit Item' : adminLabels.DONT_ALLOW}</Popover>}>
									<span>
										{
											hasPermission('user-edit') &&
											<button type='button' className="editIcon orderDetailsAction" onClick={() => this.props.editItemCall(row._original.id)}>&nbsp;</button>
										}
										{
											!hasPermission('user-edit') &&
											<button type='button' className="editIcon orderDetailsAction button_disabled" >&nbsp;</button>
										}


									</span>
								</OverlayTrigger>
								{

									<OverlayTrigger placement="bottom" overlay={<Popover id="tooltip">{hasPermission('user-delete') ? 'Delete Item' : adminLabels.DONT_ALLOW}</Popover>}>
										<span>
											{
												hasPermission('user-delete') &&
												<button type='button' className="deleteIcon orderDetailsAction" onClick={() => this.deleteConfirmItem(row._original.id)}>&nbsp;</button>
											}
											{
												!hasPermission('user-delete') &&
												<button type='button' className="deleteIcon orderDetailsAction button_disabled" >&nbsp;</button>
											}
										</span>
									</OverlayTrigger>

								}
							</div>
						),
						className: "action-center",
						sortable: false
					}
				]
			}
		];
		return (
			<div className="content">
				<Grid fluid>
					<Row>
						<Col md={12}>
							<Card
								ctTableResponsive
								content={
									<div>
										<Grid fluid>
											<Row>
												<Col md={12}>
													<div className="secHeading">Items List</div>
												</Col>

												<Col md={12}>
													<div className="flexElem flexResponsive" style={{ "alignItems": "flex-start", "justifyContent": "space-between" }}>
														<div className="">
															<OpenModalButton
																openModal={hasPermission('user-create') ? openModal.bind(this, "createItemModal") : null}
																classButton={['backButton pt-sm no_radius pb-sm primary mt-none btn-block btn-info btn', !hasPermission('user-create') ? 'button_disabled' : '']}
																buttonName="Add Item"
																tooltipText={hasPermission('user-create') ? 'Add Item' : adminLabels.DONT_ALLOW}
																classIcon={['fa', 'fa-plus']}
															/>
														</div>
														{/* <div className="custom-search-input">
															<FormInputs
																ncols={["col-md-12"]}
																onChange={this.filterSearchHandle}
																proprieties={[
																	{
																		type: "text",
																		bsClass: "form-control",
																		placeholder: "Search Item",
																		onChange: this.filterSearchHandle,
																		name: "filter"
																	}
																]}
															/>
														</div> */}
													</div>
												</Col>

												<Col md={12} className="mt-md">
													{/* <ReactTable
														noDataText='No Item found'
														data={itemsPagination}
														pages={lastPageItem}
														loading={loadingItem}
														columns={columns}
														filtered={filtered}
														defaultPageSize={10}
														className="-striped listing responsive"
														pageData={this.itemPaginationData}
														manual
														onFetchData={(state, instance) => {
															var sort = state.sorted.length === 0 ? '' : state.sorted[0].id + ',desc:' + state.sorted[0].desc;
															state.pageData(state.page + 1, state.filtered, sort, state.pageSize);
														}}
													/> */}
													<Pagination
														ref={(p) => this.pagination = p}
														showPagination={false}
														columns={columns}
														pageSize={10}
														noDataText='No Item found'
														getDataCall={this.paginationCall}
														filterView={true}
														// downloadData={true}
														// downloadFileName={'Orders'}
														// lowerContent = {null}
														filterPlaceHolder={'Items'}
														getRowProps={this.getRowProps}
													// showAllToggle={true}
													/>
												</Col>
											</Row>
										</Grid>

										{/*Add Item Modal Start*/}
										{
											<Modal backdrop={'static'} show={this.state.createItemModal} onHide={closeModal.bind(this, "createItemModal")} aria-labelledby="ModalHeader" >
												<Modal.Header closeButton>
													<Modal.Title id='ModalHeader' className="headerTitle">Create Item</Modal.Title>
												</Modal.Header>
												<Modal.Body>
													<div className="row">
														<div className="col-md-12">
															<Card bsClass={["innerCard mb-none"]} content={
																<ItemForm
																	itemPro={OC.ITEM}
																	closeModel={closeModal.bind(this, "createItemModal")}
																	formTypePro="create"
																	componentPro="Item"
																	handleItemSubmit={this.handleItemSubmit}
																	options={{
																		itemTypes: itemTypes,
																		itemCategories: itemCategories
																	}}
																	itemType={"Create"}
																/>
															} />
														</div>
													</div>
												</Modal.Body>
											</Modal>
										}

										{/*Add Item Modal End*/}

										{/*Edit Item Modal start*/}
										{
											editItem &&
											<Modal backdrop={'static'} show={this.state.editItemModal} onHide={closeModal.bind(this, "editItemModal")} aria-labelledby="ModalHeader" >
												<Modal.Header closeButton>
													<Modal.Title id='ModalHeader' className="headerTitle">Edit Item</Modal.Title>
												</Modal.Header>
												<Modal.Body>
													<div className="row">
														<div className="col-md-12">
															<Card bsClass={["innerCard mb-none"]} content={
																<ItemForm
																	itemPro={editItem}
																	closeModel={closeModal.bind(this, "editItemModal")}
																	formTypePro="edit"
																	componentPro="Item"
																	handleItemSubmit={this.handleItemSubmit}
																	options={{
																		itemTypes: itemTypes,
																		itemCategories: itemCategories
																	}}
																/>
															} />
														</div>
													</div>
												</Modal.Body>
											</Modal>

										}
										{/*Edit Item Modal End*/}
									</div>
								} />
						</Col>
					</Row>
				</Grid>
			</div>
		);
	};
}

const mapDispatchToProps = dispatch => {
	return ({
		getItemsPaginationCall: (page, filter, sort, pageSize) => { return dispatch(getItemPagination(page, filter, sort, pageSize)) },
		storeItemCall: (ItemData) => { return dispatch(storeItem(ItemData)) },
		editItemCall: (value) => { dispatch(editItem(value)) },
		updateItemCall: (data, id, type) => { return dispatch(updateItem(data, id, type)) },
		deleteItemCall: (id) => { return dispatch(deleteItem(id)) },
		getItemTypesCall: () => { dispatch(getItemTypes()) },
		getItemCategoriesCall: () => { dispatch(getItemCategories()) },
	});
};

function mapStateToProps(state) {
	const { loadingItem, itemsPagination, lastPageItem, currentPageItem, editItem } = state.itemReducer;
	const { loadingSettings, itemTypes, itemCategories } = state.settingsReducer;
	return {
		loadingItem, itemsPagination, lastPageItem, currentPageItem, editItem,
		loadingSettings, itemTypes, itemCategories,
	};
};

const ItemList = connect(mapStateToProps, mapDispatchToProps)(ItemListPage);
export default ItemList;