import React from "react";
import { withRouter } from "react-router-dom";
import $ from 'jquery'

class Scroller extends React.Component {
    constructor(props) {
        super(props);
        this.top = React.createRef();
    }
    componentDidMount() {
        $("html,body").animate({ scrollTop: '0' }, 500)
    }
    componentDidUpdate(prevProps) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            $("html,body").animate({ scrollTop: '0' }, 500)
        }
    }

    render() {
        return (
            <div>
                {this.props.children}
            </div>
        );
    }
}

export default withRouter(Scroller)