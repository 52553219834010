import { frontEndUserConstants } from "../constant/front-end-constant";

const initialState = {
    musicLibrary: []
}

export function musicLibraryReducer(state = initialState, action) {
    switch (action.type) {
        case frontEndUserConstants.GET_ALL_MUSIC_SUCCESS:
            return {
                ...state,
                musicLibrary: action.payload.data
            }
        default:
            return state
    }
}