import React, { Component,useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { MdClose } from 'react-icons/md';
import FileUpload from 'components/Upload/FileUpload';
import ImagePreview from 'components/Upload/ImagePreview';
import { storeAgencyDocument, deleteAgencyDocument, getAgencyDocumentsPagination } from 'redux/actions/agency-resource-actions';
import { Button, Table } from "react-bootstrap";
import { getAgencyFolderResources } from 'redux/actions/order-actions';
import { confirmAlert } from 'react-confirm-alert';
import ConfirmAlertForDelete from 'components/ThemeComponents/confirmAlertForDelete';
import Validator from 'simple-react-validator';
import DatePicker from "react-datepicker";
import { dateFormatMy, s3StaticPath, closeModal, returnLabelWithValue } from 'helper/helperFunctions';
import { SC } from 'helper/ServerCall';
import Select from 'react-select';
import TreeView from '@mui/lab/TreeView';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import DoNotDisturbOnOutlinedIcon from '@mui/icons-material/DoNotDisturbOnOutlined';
import TreeItem from '@mui/lab/TreeItem';
import { alertActions } from '../../../redux/actions/alert-actions';
import Folder from '../Folder';
// import MyImage from 'components/Upload/MyImage';
var Modal = require('react-bootstrap-modal');
const moment = window.moment;
class AgencyDocuments extends Component {
    constructor(props) {
        super(props);
        this.dateValidator = new Validator();
        var date = moment(new Date()).format('YYYY-MM-DD')
        this.state = {
            schDate: date,
            files: [],
            agencyDocumentsData: [],
            documentType: '',
            agency_folder_id: null,
            callData: [],
            addFolderModal: false,
            folderName: '',
            selectedFolder: 'Please select a folder',
            expanded: [],
            is_folder: true,
            folders:[],
        }
        // this.props.onOrderResourceFormatsCall(this.props.resourceTypeText).then(response => {
        //     this.setState({ resourceType: response });
        // });
        // console.log(this.props.agency_id, 'agency id');
        if (this.props.formType === 'edit') {
            this.props.getAgencyDocumentsPaginationCall(this.props.agency_id).then(response => {
                // console.log(response, 'responseee');
                this.setState({ agencyDocumentsData: response });
            });
        }
        // if (this.props.formType === 'add') {
        //     this.props.clearOrderResources();
        // }
        this.Validator = new Validator();

        this.props.getAgencyFolderResourcesCall(this.props.agency_id).then(response => {
            console.log("responseGlobalDocs", response);
                this.setState({
                    callData: response.data.data,
                    folders: response.data.folder_array
                });
        })
    }

    getAgencyFolderResources = (id) => {
        return SC.getCall(`getAgencyFolderResources/${id}`);
    }
    storeAgencyFolder = (data) => {
        return SC.postCall('storeAgencyFolder' , data );
    }
    confirmResourceDelete = (index, id) => {
        confirmAlert({
            customUI: ({ onClose }) => <ConfirmAlertForDelete typeName='Resource' onClosePro={onClose} deleteType={this.onResourceDeleteHandler.bind(this, index, id)} />
        })
    }
    folderSubmitHandler = () => {
        if(this.state.folderName !== "") {
            const data = {name: this.state.folderName, agency_id: this.props.agency_id}
            this.storeAgencyFolder(data).then(
                response => {

                    this.props.success(response.message);
                    this.setState({
                        addFolderModal: false
                    },()=>{
                        this.props.getAgencyFolderResourcesCall(this.props.agency_id).then(response =>{
                            this.setState({
                                callData: response.data.data,
                                folders: response.data.folder_array
                            });
                        })
                    });

                }
            );
        }
    }
    // DateChangeHandler = (value, field) => {
    //     this.setState({ [field]: value === null ? '' : value }, () => {
    //         if (this.dateValidator.allValid()) {
    //             //this.pagination.dataCall();;
    //             // console.log("dateChange", value);
    //             // console.log("schDate", this.state.schDate);
    //         } else {
    //             this.dateValidator.showMessages();
    //             this.forceUpdate();
    //         }
    //     })
    // }
    // dateElement(label, name, value, rules) {
    //     var selectedDate = this.state[name] !== '' ? new Date(this.state[name]) : '';
    //     return (
    //         <div className='form-group fullCalendar' id={name}>
    //             <label>{label}</label>
    //             <DatePicker
    //                 className="form-control calender_icon"
    //                 minDate={Date.now()}
    //                 selected={selectedDate}
    //                 name={name}
    //                 onChange={(event) => this.DateChangeHandler(event, name)}
    //             />

    //         </div>
    //     );
    // }

    setResourceFile = (key, value) => {
        this.setState((prevState) => {
            return {
                files: [
                    ...prevState.files,
                    value
                ]
            }
        });
    }

    documentSubmitHandler = () => {
        const fd = new FormData();
        // console.log(this.props.notEditable, this.props.formType)
        if (this.props.formType === 'edit') {
            // console.log(this.state.files)
            if (this.Validator.allValid()) {
                // fd.append('schDate', this.state.schDate);
                fd.append('file', this.state.files[0]);
                fd.append('agency_folder_id', this.state.agency_folder_id);
                // fd.append('resource_type_id', this.state.resourceType.id);
                // this.props.storeAgencyDocumentCall(this.props.agency_id, fd).then(
                this.props.storeAgencyDocumentCall(this.props.agency_id, fd).then(
                    response => {
                        this.setState({
                            files: []
                        });
                        this.props.getAgencyFolderResourcesCall(this.props.agency_id).then(response =>{
                            this.setState({
                                callData: response.data.data,
                                folders: response.data.folder_array
                            });
                        })
                        this.props.getAgencyDocumentsPaginationCall(this.props.agency_id).then(response => {
                            this.setState({ agencyDocumentsData: response });
                        });
                        this.Validator.hideMessages();
                    }
                );
            } else {
                this.Validator.showMessages();
                this.forceUpdate();
            }
        } else {
            // fd.append('schDate', this.state.schDate);
            fd.append('file', this.state.files[0]);
            // fd.append('resource_type_id', this.state.resourceType.id);
            // this.props.storeAgencyDocumentCall(this.props.agency_id, fd).then(
            this.props.storeAgencyDocumentCall(this.props.agency_id, fd).then(
                response => {
                    this.setState({
                        files: []
                    })
                    this.props.history.push('/dashboard')
                }
            );
        }
    }

    removeFile = (key, index) => {
        const files = [...this.state.files]
        files.splice(index, 1);

        this.setState({
            files: files,
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.formSubmitted && (this.props.formSubmitted !== prevProps.formSubmitted)) {
            if (this.state.files.length > 0 && this.props.formType === 'add') {
                this.documentSubmitHandler();
            }
            this.setState({
                submitComment: true
            }, () => {
                this.setState({
                    submitComment: false
                })
            });
        }

        if(this.props.AgencyFolderDocuments !== prevProps.AgencyFolderDocuments) {
            this.setState({
                callData: this.props.AgencyFolderDocuments.data.data
            });
        }
    }

    onResourceDeleteHandler = (index, id) => {
        const agencyDocumentsData = [...this.state.agencyDocumentsData];
        agencyDocumentsData.splice(index, 1);
        this.props.deleteAgencyDocumentCall(this.props.agency_id, id).then(response => {
            this.props.getAgencyFolderResourcesCall(this.props.agency_id).then(response =>{
                this.setState({
                    callData: response.data.data,
                    folders: response.data.folder_array
                });
            })
            this.props.getAgencyDocumentsPaginationCall(this.props.agency_id).then(response => {
                this.setState({ agencyDocumentsData: response });
            });
        });
    }

    confirmResourceDelete = (index, id) => {
        confirmAlert({
            customUI: ({ onClose }) => <ConfirmAlertForDelete typeName='File' onClosePro={onClose} deleteType={this.onResourceDeleteHandler.bind(this, index, id)} />
        })
    }
    onFolderClick = (e, data, nodes, nodeId) => {
        const removeId = e.target.getAttribute('Mui-expanded');
        // console.log("removedId", e.target);
        // console.log("data", data);
        // console.log("action", action);
        // console.log("nodes", nodes.length);
        const expandingNodes = nodes.filter(x => !this.state.expanded.includes(x));
        // if ()
        this.setState({
            expanded:expandingNodes,
            selectedFolder: data.folder_name,
            agency_folder_id: data.folder_id,
            is_folder: true
        })
    }
    openAddFoderModal = () => {
        this.setState({
            addFolderModal: !this.state.addFolderModal
        })
    }
    storeFodlerhandler = (e) => {
        console.log("value", e.target.value);
        // var folderName = this.state.folderName;
        // folderName = e.target.value;
        this.setState({
            folderName: e.target.value
        })
    }

    handleChangeFolder = (selectedOptionType, info) => {
        // console.log("selected option type: " , selectedOptionType);
        // console.log("info " , info);
        if ( selectedOptionType.value === "-1")
        {
            this.setState({
                agency_folder_id: null,
                selectedFolder: selectedOptionType.label,
            })
        }else{
            this.setState({
                selectedFolder: selectedOptionType.label,
                agency_folder_id: selectedOptionType.value
            })
        }
    }

    render() {
        const { isSchedule, header } = this.props;
        const { schDate, agencyDocumentsData, resourceType, callData, addFolderModal, folders } = this.state;
        let itemId = 1;
        // console.log(agencyDocumentsData, 'agencyDocumentsData');
        // const formatsMime = returnFormats(resourceType.resource_formats, 'mime_type').join(',');
        // const extensionsMime = returnFormats(resourceType.resource_formats, 'extension').join(',');
        return  (
                <div className="form_tile">
                    <div className="form_tile_content">
                        {
                            <React.Fragment>
                                <div className="flexElem respFlex spaceBetween form_tile_row">
                                    <div className="col-md-12">
                                        <div className="form_tile_title">Upload New File</div>
                                    </div>
                                </div>
                                <div className="flexElem respFlex spaceBetween alignCenter form_tile_row">
                                    <div className="col-md-4">
                                        <button onClick={() => { this.uploadForm.open() }} type="button" className="customFile block theme_color">
                                            <img src={s3StaticPath('img/upload_photo.png')} alt="Upload" />
                                            <span>Choose File</span>
                                        </button>
                                    </div>
                                    <div className="col-md-8">
                                        <div className='row flexElem flexResponsive align-items-center' >
                                            <div className="col-md-12">
                                                <div className="col-md-6" >
                                                    {
                                                        isSchedule &&
                                                        this.dateElement('Date to appear on app', 'schDate', schDate !== '' && moment(schDate, 'MM-DD-YYYY').format('L'), [{ before_or_equal: moment(this.state.schDate) }])
                                                    }
                                                </div>
                                                {/* Select Folder:  */}
                                                {
                                                    folders &&
                                                    <div className="col-md-6" >
                                                        <div className='form-group' id="folderId">
                                                            <label htmlFor="name">Select Folder</label>
                                                                <Select
                                                                    value={returnLabelWithValue(this.state.agency_folder_id, folders)}
                                                                    onChange={this.handleChangeFolder}
                                                                    options={folders}
                                                                    // options={options.itemTypes}
                                                                    isSearchable={false}
                                                                    isMulti={false}
                                                                    placeholder={'Select Folder'}
                                                                    isClearable={false}
                                                                    name="agency_folder_id"
                                                                />
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>


                                    <div className="col-md-5">
                                        {
                                            (this.props.notEditable || this.props.formType === 'edit') &&
                                            <React.Fragment>
                                                <button onClick={this.documentSubmitHandler} type="button" className="backButton pt-sm no_radius pb-sm primary mt-none btn btn-info ">Upload</button><br />
                                            </React.Fragment>
                                        }
                                        <div style={{ display: 'none' }} >
                                            <FileUpload
                                                ref={el => { this.uploadForm = el }}
                                                setResourceFile={this.setResourceFile}
                                                multiple={false}
                                                allowedFormatsStringMimePro={'image/jpeg, image/png, video/quicktime, video/mp4, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/pdf ,application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}
                                                allowedFormatsStringExtPro={'jpg, png, docx, doc, Jpeg, pdf, xls, xlsx, mov, mp4'}
                                            />
                                        </div>
                                        {
                                            this.state.files.length < 1 &&

                                            <span className="theme_color text_16 text_elipsis">No File Chosen</span>
                                        }
                                    </div>
                                </div>
                                {this.Validator.message('Documents', this.state.files, "required|min:1,array")}
                            </React.Fragment>
                        }
                        <div>
                            <ImagePreview
                                filesToPreview={this.state.files}
                                removeFile={this.removeFile} />
                            {/* {this.props.progress !== 0 && <span>{this.props.progress}</span>} */}
                        </div>
                        {/* Start */}
                        {/* {
                                        'frontend' in this.props && this.props.frontend
                                            ?
                                            null
                                            :
                                            Object.keys(this.state.resource).length > 0
                                                ?
                                                <div>
                                                    <div className="col-md-4">
                                                        <div className='row flexElem flexResponsive align-items-center' >
                                                            <div className="col-md-8" >
                                                                {
                                                                    this.dateElement('Schedule Date', 'schDate', schDate !== '' && moment(schDate, 'YYYY-MM-DD'), [{ before_or_equal: moment(this.state.schDate) }])
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className='row flexElem flexResponsive align-items-center' >
                                                            <div className="col-md-8" >
                                                                <div className='form-group' id="folderId">
                                                                    <label htmlFor="name">Select Folder</label>
                                                                        <Select
                                                                            value={returnLabelWithValue(this.state.agency_folder_id, folders)}
                                                                            onChange={this.handleChangeFolder}
                                                                            options={folders}
                                                                            // options={options.itemTypes}
                                                                            isSearchable={true}
                                                                            isMulti={false}
                                                                            placeholder={'Select Folder'}
                                                                            isClearable={false}
                                                                            name="agency_folder_id"
                                                                        />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className='btn-toolbar text-center mb-lg'>
                                                            <Button onClick={this.handleOrderResoruceSubmit} className="backButton pt-sm no_radius pb-sm success btn btn-lg ml-sm mt-xlg btn-default" >Save</Button>
                                                            <Button className="backButton pt-sm no_radius pb-sm primary btn btn-lg ml-sm mt-xlg btn-info" onClick={this.cancelResourceUploadHandler} >Cancel</Button>
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                ''
                                    } */}


                        {
                            this.props.formType === 'edit' &&
                            <div className="flexElem respFlex spaceBetween form_tile_row">
                                <div className="col-md-12">
                                    <div className="table-responsive">
                                        <table className="table theme_table mt-md">
                                            <thead>
                                                <tr className="border-bottom">
                                                    <th width="35%">File Name</th>
                                                    <th width="25%">Uploaded Date</th>
                                                    {/* <th width="20%">Status</th> */}
                                                    <th width="20%">Action</th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {
                                                    callData &&
                                                    callData.map((item, index) => {
                                                        if (item.file_path) {
                                                            return(
                                                                <tr key={item.id}>
                                                                    <td><a style={{ wordBreak: 'break-all' }} className='blue_link' target="_blank" href={item.file_path} rel="noopener noreferrer">{item.original_file_name}</a></td>
                                                                    <td>{dateFormatMy(item.created_at)}</td>
                                                                    {/* <td>{item.is_scheduled === 0 ? 'Sent' : 'Scheduled'}</td> */}
                                                                    <td>
                                                                        <button onClick={() => { this.confirmResourceDelete(index, item.id) }} type='button' className="linkBtn text_14 text-danger"><MdClose /></button>
                                                                        <a className='ml-md' href={item.file_path}><i className='fa fa-download'></i></a>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        }
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            this.state.callData &&

                            this.state.callData.map((item, key , index) => {
                                // console.log("index: " , key);
                                // console.log("item: " , item);
                                if (item.folder_id){
                                    return (
                                        <Folder key = {itemId} agencyId = {this.props.agency_id} item = {item} updatebackend = {this.updatebackenddrag} ex = {this.state.expanded} onFolderClick = {this.onFolderClick} confirmResourceDelete = {this.confirmResourceDelete} dragable = {true}/>

                                    )
                                }
                            })


                        }
                         <div className="flexElem respFlex spaceBetween form_tile_row">
                            <div className="col-md-12">
                                <div className="col-md-5">
                                    <a type='button' onClick={this.openAddFoderModal} className="backButton pt-sm no_radius pb-sm primary mt-md mb-md btn btn btn-info  btn btn-default">Add New Folder</a>
                                </div>
                            </div>
                        </div>

                    </div>

                    {/* Add Folder Model */}
                    <Modal size="sm" backdrop={'static'} show={addFolderModal} id="" onHide={closeModal.bind(this, "addFolderModal")} aria-labelledby="ModalHeader" className="" >
                        <Modal.Header closeButton>
                            <Modal.Title id='ModalHeader' className="headerTitle">Add New Folder</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="clearfix">
                                <input type="text" value={this.state.folderName} onChange={this.storeFodlerhandler} className="form-control" placeholder="Enter Folder Name"/>
                                <br />
                                {/* <button onClick={closeModal.bind(this, "addFolderModal")}  className="folderBtn mr-md "> Back</button> */}


                                <button onClick={() => this.folderSubmitHandler()} style={{float: "right"}} className="backButton pt-sm no_radius pb-sm primary mt-none btn btn btn-info  btn btn-default">Submit</button>
                            </div>
                        </Modal.Body>
                    </Modal>

                </div>
            )
    }
}

const mapStateToProps = (state) => {
    // const {agencyDocumentsData} = state.agencyResourceReducer;
    const { AgencyFolderDocuments } = state.ordersReducer;
    return {
        // agencyDocumentsData
        AgencyFolderDocuments
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getAgencyDocumentsPaginationCall: (agency_id) => { return dispatch(getAgencyDocumentsPagination(agency_id)) },
        storeAgencyDocumentCall: (agencyId, AgencyDocumentData) => { return dispatch(storeAgencyDocument(agencyId, AgencyDocumentData))},
        deleteAgencyDocumentCall: (agency_id, id) => { return dispatch(deleteAgencyDocument(agency_id, id))},
        success: (message) => { return dispatch(alertActions.success(message))},
        getAgencyFolderResourcesCall: (agencyId) => { return dispatch(getAgencyFolderResources(agencyId)) },
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AgencyDocuments))
