import { adminConstants } from "../constant/admin-constant"

const initialState = {
    mosaicStyles: []
}

export const mosaicReducer = function (state = initialState, { type, payload }) {
    switch (type) {

        case adminConstants.GET_ALL_MOSAIC_STYLES_SUCCESS:
            return {
                ...state,
                mosaicStyles: payload.data
            }

        default:
            return state
    }
}
