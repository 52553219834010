import { SC } from "helper/ServerCall";

export const schedulerServices = {
    getCallSchedulers,
    addEventtoScheduler
}


function getCallSchedulers() {
	return SC.getCall(`callScheduler`);
}

function addEventtoScheduler(data) {
	return SC.postCall('callScheduler', data);
}