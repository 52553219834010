import { adminConstants } from '../constant/admin-constant';

const initialState = {loadingAgencyResource: false, editAgencyResource:'', agencyResourcesData:{
	resource_types:[],
	agency:{}
}, loadingAgencyDocument: false, editAgencyDocument:'', agencyDocumentsData:{
    
}};
export function agencyResourceReducer(state = initialState, action){
	switch(action.type){

		case adminConstants.PAGINATION_AGENCY_RESOURCES_REQUEST:
			return { ...state, loadingAgencyResource:true, editAgencyResource:'', storedAgencyResource:'', updatedAgencyResource:'', deleteAgencyResource:false, agencyResourceTypes:'' };
		case adminConstants.PAGINATION_AGENCY_RESOURCES_SUCCESS:
			return { ...state, agencyResourcesData:action.payload.resourceTypes, loadingAgencyResource: false};
		case adminConstants.PAGINATION_AGENCY_RESOURCES_FAILURE:
			return { ...state, loadingAgencyResource:false, error : action.payload };

		case adminConstants.PAGINATION_AGENCY_DOCUMENTS_REQUEST:
			return { ...state, loadingAgencyDocument:true, editAgencyDocument:'', storedAgencyDocument:'', updatedAgencyDocument:'', deleteAgencyDocument:false, agencyDocumentTypes:'' };
		case adminConstants.PAGINATION_AGENCY_DOCUMENTS_SUCCESS:
			return { ...state, loadingAgencyDocument:false, agencyDocumentsData:action.payload};
		case adminConstants.PAGINATION_AGENCY_DOCUMENTS_FAILURE:
			return { ...state, loadingAgencyDocument:false, error : action.payload };

		case adminConstants.GET_AGENCY_RESOURCE_TYPE_REQUEST:
			return { ...state, loadingAgencyResource:true };
		case adminConstants.GET_AGENCY_RESOURCE_TYPE_SUCCESS:
			return { ...state, agencyResourceTypes:action.payload.agencyResourceTypes, loadingAgencyResource: false };
		case adminConstants.GET_AGENCY_RESOURCE_TYPE_FAILURE:
			return { ...state, loadingAgencyResource:false, error : action.payload };


		case adminConstants.STORE_AGENCY_RESOURCE_REQUEST:
			return { ...state, loadingAgencyResource:true, editAgencyResource:'', storedAgencyResource: '', agencyResourceTypes:'' }
		case adminConstants.STORE_AGENCY_RESOURCE_SUCCESS:
			return { ...state, loadingAgencyResource:false, storedAgencyResource: action.payload.resourceTypes};
		case adminConstants.STORE_AGENCY_RESOURCE_FAILURE:
			return { ...state, loadingAgencyResource:false, error:action.payload };

		case adminConstants.STORE_AGENCY_DOCUMENTS_REQUEST:
			return { ...state, loadingAgencyDocument:true, editAgencyDocument:'', storedAgencyDocument: '', agencyDocumentTypes:'' }
		case adminConstants.STORE_AGENCY_DOCUMENTS_SUCCESS:
			return { ...state, loadingAgencyDocument:false, storedAgencyDocument: action.payload};
		case adminConstants.STORE_AGENCY_DOCUMENTS_FAILURE:
			return { ...state, loadingAgencyDocument:false, error:action.payload };

		case adminConstants.GET_AGENCY_RESOURCE_REQUEST:
			return { ...state, loadingAgencyResource:true };
		case adminConstants.GET_AGENCY_RESOURCE_SUCCESS:
			return { ...state, loadingAgencyResource:false, editAgencyResource : action.payload.agencyResource };
		case adminConstants.GET_AGENCY_RESOURCE_FAILURE:
			return { ...state, loadingAgencyResource:false, error : action.payload };

		case adminConstants.EDIT_AGENCY_RESOURCE_REQUEST:
			return { ...state, loadingAgencyResource:true, updatedAgencyResource:'' };
		case adminConstants.EDIT_AGENCY_RESOURCE_SUCCESS:
			return { ...state, loadingAgencyResource:false , updatedAgencyResource:action.payload.resourceTypes};
		case adminConstants.EDIT_AGENCY_RESOURCE_FAILURE:
			return { ...state, loadingAgencyResource:false, error : action.payload };

		case adminConstants.DELETE_AGENCY_RESOURCE_REQUEST:
			return { ...state, loadingAgencyResource:true, deleteAgencyResource: false }
		case adminConstants.DELETE_AGENCY_RESOURCE_SUCCESS:
			return { ...state, loadingAgencyResource:false, deleteAgencyResource: true};
		case adminConstants.DELETE_AGENCY_RESOURCE_FAILURE:
			return { ...state, loadingAgencyResource:false, error:action.payload };

		case adminConstants.DELETE_AGENCY_DOCUMENTS_REQUEST:
			return { ...state, loadingAgencyDocument:true, deleteAgencyDocument: false }
		case adminConstants.DELETE_AGENCY_DOCUMENTS_SUCCESS:
			return { ...state, loadingAgencyDocument:false, deleteAgencyDocument: true};
		case adminConstants.DELETE_AGENCY_DOCUMENTS_FAILURE:
			return { ...state, loadingAgencyDocument:false, error:action.payload };

		default:
			return { ...state };
	}
}
