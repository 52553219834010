import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { MdClose } from 'react-icons/md';
import FileUpload from 'components/Upload/FileUpload';
import Folder from '../../../Admin/Folder';
import ImagePreview from 'components/Upload/ImagePreview';
import { clearOrderResources, getOrderResourceFormats, getOrderResources, storeOrderResource, orderResourceDelete, getFolderOrderResources } from 'redux/actions/order-actions';
// import Comment from 'components/FrontEnd/Order/OrderForm/Comment';
import { confirmAlert } from 'react-confirm-alert';
import ConfirmAlertForDelete from 'components/ThemeComponents/confirmAlertForDelete';
import Validator from 'simple-react-validator';
import DatePicker from "react-datepicker";
import { returnFormats, s3StaticPath, dateFormatMyDocuments, closeModal, returnLabelWithValue } from 'helper/helperFunctions';
import User from 'helper/User';
import GlobalDocuments from '../../Account/GlobalDocuments';
import { Collapse } from 'react-bootstrap';
import 'react-sortable-tree/style.css'; // This only needs to be imported once in your app
import { SC } from 'helper/ServerCall';
import { alertActions } from '../../../../redux/actions/alert-actions';
import {Roles} from 'constants/Roles';
import Select from 'react-select';
import OrderFolder from '../../../Admin/OrderFolder';
import ToggleButton from 'react-toggle-button';
import { themeCSSConstants } from 'redux/constant/themeCSS';
import { orderService } from '../../../../services/order';

// import MyImage from 'components/Upload/MyImage';
var Modal = require('react-bootstrap-modal');
const moment = window.moment;
class Documents extends Component {
    constructor(props) {
        super(props)
        this.dateValidator = new Validator();
        var date = moment(new Date()).format('YYYY-MM-DD')
        this.state = {
            schDate: date,
            files: [],
            // submitComment: false,
            orderDocumentCollapse: false,
            orderResourcesDocuments: [],
            resourceType: '',
            length:0,
            order_folder_id: null,
            callData: [],
            addFolderModal: false,
            folderName: '',
            selectedFolder: 'No Folder Selected',
            expanded: [],
            is_folder: true,
            folders:[]
        }
        this.props.onOrderResourceFormatsCall(this.props.resourceTypeText).then(response => {
            this.setState({ resourceType: response });
        });
        if (this.props.formType === 'edit' || this.props.isPopUpForm === true) {
            this.props.onGetOrderResourcesCall(this.props.id, this.props.resourceTypeText).then(response => {
                if(response && response.length > 0) {
                    this.setState({ orderResourcesDocuments: response });
                }
            });
        }
        if (this.props.formType === 'add' && this.props.isPopUpForm !== true) {
            this.props.clearOrderResources();
        }
        this.Validator = new Validator();
        if (this.props.formType === 'edit' || this.props.isPopUpForm === true){
            this.props.onGetOrderFolderResourcesCall(this.props.id).then(response =>{
                console.log("response..........................", response);
               var folder = response.folder_array;
               var ids = [];
               Object.keys(folder).map((item) => {
                    if (!(folder[item].value == "-1")) {
                        ids.push(folder[item].value.toString());
                    }
                })
                this.setState({
                    expanded: ids,
                    callData: response.data,
                    folders: response.folder_array
                });
            })
        }
        // this.deleteOrderFolderResources(11).then(response =>{
        //     console.log("deleteFolderResources----------", response);
        // })
        // this.props.onGetOrderFolderResourcesCall(this.props.id).then(response =>{
        //     console.log("onGetOrderFolderResourcesCall", response);
        // })
    }
    openAddFoderModal = () => {
        this.setState({
            addFolderModal: !this.state.addFolderModal
        })
    }
    storeFodlerhandler = (e) => {
        // console.log("value", e.target.value);
        // var folderName = this.state.folderName;
        // folderName = e.target.value;
        this.setState({
            folderName: e.target.value
        })
    }
    folderSubmitHandler = () => {
        if(this.state.folderName !== "") {
            if (this.props.formType === "add" && this.props.isPopUpForm !== true) {
                var data = {name: this.state.folderName, uuid: this.props.uuid}
            } else if (this.props.formType === 'edit' || this.props.isPopUpForm === true) {
                var data = {name: this.state.folderName, order_id: this.props.id}
            }
            // if(+this.props.uuid > 0) {
            //     data.append('uuid', +this.props.uuid);
            // }
            this.storeFolder(data).then(
                response => {
                    this.setState({
                        selectedFolder: response.data.name,
                        order_folder_id: response.data.id,
                        is_folder: true
                    })
                    this.props.success(response.message);
                    this.setState({
                        addFolderModal: false,
                        folderName: ''
                    },()=>{
                        if (this.props.id === 0) {
                            let params = {
                                id: this.props.id,
                                uuid: this.props.uuid
                            }
                            this.uuidCall(params).then(response =>{
                                this.setState({
                                    callData: response.data.data,
                                    folders: response.data.folder_array
                                });
                            })
                        } else {

                            this.props.onGetOrderFolderResourcesCall(this.props.id).then(response =>{
                                this.setState({
                                    callData: response.data,
                                    folders: response.folder_array
                                });
                            })
                        }
                    });

                }
            );
        }
    }
    storeFolder = (data) => {
        return SC.postCall('orderFolder' , data );
    }
    uuidCall = (data) => {
        return SC.getCallWithData('getResourcesWithUuid' , data );
    }
    deleteOrderFolderResources = (id) => {
        return SC.deleteCall(`deleteOrderFolderResources/${id}` );
    }
    show = (id) => {
        return SC.getCall(`getResources/${id}` );
    }
    DateChangeHandler = (value, field) => {
        this.setState({ [field]: value === null ? '' : value }, () => {
            if (this.dateValidator.allValid()) {
                //this.pagination.dataCall();;
                // console.log("dateChange", value);
                // console.log("schDate", this.state.schDate);
            } else {
                this.dateValidator.showMessages();
                this.forceUpdate();
            }
        })
    }
    dateElement(label, name, value, rules) {
        var selectedDate = this.state[name] !== '' ? new Date(this.state[name]) : '';
        return (
            <div className='form-group fullCalendar' id={name}>
                <label>{label}</label>
                <DatePicker
                    className="form-control calender_icon"
                    minDate={Date.now()}
                    selected={selectedDate}
                    name={name}
                    onChange={(event) => this.DateChangeHandler(event, name)}
                />

            </div>
        );
    }

    setResourceFile = (key, value) => {
        this.setState((prevState) => {
            return {
                files: [
                    ...prevState.files,
                    value
                ]
            }
        });
    }

    resourceSubmitHandler = () => {

        if (this.props.notEditable || this.props.formType === 'edit') {
            // const fd = new FormData();
            // console.log(this.state.files)
            if (this.Validator.allValid()) {

                const files = [...this.state.files];
                const total_files_count = files.length;
                var uploaded_files_count = 0;

                files.forEach((file) => {
                    let fd_add = new FormData();
                    fd_add.append('schDate', this.state.schDate);
                    fd_add.append('file', file);
                    fd_add.append('resource_type_id', this.state.resourceType.id);
                    fd_add.append('order_folder_id', this.state.order_folder_id);
                    // if(+this.props.uuid > 0) {
                    //     fd_add.append('uuid', +this.props.uuid);
                    // }
                    this.props.onStoreOrderResourceCall(fd_add, this.props.id).then(
                        response => {
                            uploaded_files_count++;
                            if(uploaded_files_count === total_files_count) {
                                this.setState({
                                    files: []
                                })
                                this.props.onGetOrderResourcesCall(this.props.id, this.props.resourceTypeText).then(response => {
                                    this.setState({ orderResourcesDocuments: response });
                                });
                                this.props.onGetOrderFolderResourcesCall(this.props.id).then(response =>{
                                    // console.log("response", response);
                                    this.setState({
                                        callData: response.data,
                                        folders: response.folder_array
                                    });
                                })
                                this.Validator.hideMessages();
                            }

                        }
                    );
                });

                // fd.append('schDate', this.state.schDate);
                // fd.append('file', this.state.files[0]);
                // fd.append('resource_type_id', this.state.resourceType.id);
                // this.props.onStoreOrderResourceCall(fd, this.props.id).then(
                //     response => {
                //         this.setState({
                //             files: []
                //         });
                //         this.props.onGetOrderResourcesCall(this.props.id, this.props.resourceTypeText).then(response => {
                //             this.setState({ orderResourcesDocuments: response });
                //         });
                //         this.Validator.hideMessages();
                //     }
                // );


            } else {
                this.Validator.showMessages();
                this.forceUpdate();
            }
        }
        else {
            console.log("else case in Documentsss......");
            const files = [...this.state.files];
            const total_files_count = files.length;
            var uploaded_files_count = 0;

            files.forEach((file) => {
                let fd_add = new FormData();
                fd_add.append('schDate', this.state.schDate);
                fd_add.append('file', file);
                fd_add.append('resource_type_id', this.state.resourceType.id);
                fd_add.append('order_folder_id', this.state.order_folder_id);
                this.props.onStoreOrderResourceCall(fd_add, this.props.id).then(
                    response => {
                        uploaded_files_count++;
                        if(uploaded_files_count === total_files_count) {
                            this.setState({
                                files: []
                            })
                            this.props.history.push('/dashboard')
                        }

                    }
                );
            });
        }
    }

    removeFile = (key, index) => {
        const files = [...this.state.files]
        files.splice(index, 1);
        this.setState({
            files: files,
        });
    }

    componentDidUpdate(prevProps, prevState) {
        // console.log('this.props.notEditable', this.props.notEditable);
        if (this.props.formSubmitted && (this.props.formSubmitted !== prevProps.formSubmitted)) {
            if (this.state.files.length > 0 && this.props.formType === 'add') {
                this.resourceSubmitHandler();
            }
            // this.setState({
            //     submitComment: true
            // }, () => {
            //     this.setState({
            //         submitComment: false
            //     })
            // });
        }
        // if(this.state.length !== prevState.length){
        //     // console.log("function scucess");/\
        //     this.setState({
        //         orderDocumentCollapse: true
        //     })
        // }

        if(this.props.orderResourcesDocuments !== prevProps.orderResourcesDocuments && this.props.resourceTypeText !== 'audio_video') {
            // console.log('orderResourcesDocuments', this.props.orderResourcesDocuments);
            if(this.props.orderResourcesDocuments && this.props.orderResourcesDocuments.length > 0) {
                this.setState(
                    { orderResourcesDocuments: this.props.orderResourcesDocuments }
                );
            }
        }
        if(this.props.orderFolderDocuments !== prevProps.orderFolderDocuments) {
                this.setState({
                    callData: this.props.orderFolderDocuments.data,
                    folders: this.props.orderFolderDocuments.folder_array
                });
        }

        // console.log(this.state.length,'state length');
        if (this.state.folderName !== prevState.folderName) {
            this.setState({ folderName: this.state.folderName})
        }

        if (this.state.expanded !== prevState.expanded) {

            if(this.state.expanded.length === 0) {
                this.setState({
                    selectedFolder: 'No Folder Selected',
                    order_folder_id: null
                })
            }
        }
        // if (this.state.order_folder_id !== prevState.order_folder_id) {

            if(this.state.order_folder_id === null) {
                this.setState({
                    selectedFolder: 'No Folder Selected',
                    order_folder_id: -1
                })
            }
        // }
        if (this.state.is_folder !== prevState.is_folder) {

            if(this.state.is_folder === false) {
                this.setState({
                    selectedFolder: 'No Folder Selected',
                    order_folder_id: null
                })
            }
        }
    }

    onResourceDeleteHandler = (index, id) => {
        const orderResourcesDocuments = [...this.state.orderResourcesDocuments];
        orderResourcesDocuments.splice(index, 1);
        this.props.onResourceDeleteCall(orderResourcesDocuments, id).then(response => {
            this.props.onGetOrderResourcesCall(this.props.id, this.props.resourceTypeText).then(response => {
                this.setState({ orderResourcesDocuments: response });
            });

            this.props.onGetOrderFolderResourcesCall(this.props.id).then(response =>{
                this.setState({
                    callData: response.data,
                    folders: response.folder_array
                });
            })
        });
    }

    confirmResourceDelete = (index, id) => {
        confirmAlert({
            customUI: ({ onClose }) => <ConfirmAlertForDelete typeName='File' onClosePro={onClose} deleteType={this.onResourceDeleteHandler.bind(this, index, id)} />
        })
    }
    handleCallback = (childData) =>{
        this.setState({length: childData})
    }

    onFolderClick = (e, data, nodes, nodeId) => {
        console.log("nodes", nodes);
        const removeId = e.target.getAttribute('Mui-expanded');
        // const expandingNodes = nodes.filter(x => !this.state.expanded.includes(x));
        const expandingNodes = nodes;
        // if ()
        this.setState({
            expanded:expandingNodes,
            selectedFolder: data.folder_name,
            order_folder_id: data.folder_id,
            is_folder: true
        })
    }

    handleChangeFolder = (selectedOptionType, info) => {
        // console.log("selected option type: " , selectedOptionType);
        // console.log("info " , info);
        if ( selectedOptionType.value === "-1")
        {
            this.setState({
                order_folder_id: null,
                selectedFolder: selectedOptionType.label,
            })
        }else{
            this.setState({
                selectedFolder: selectedOptionType.label,
                order_folder_id: selectedOptionType.value
            })
        }
    }

    changeDocumentToggle = (item) => {
        console.log("item selected", item);
        const data = {
            document_id: item.id,
            is_public: !item.is_public 
        }
        orderService.publicDocumentToggle(this.props.id, data).then(response => {
            console.log("response",response);
            this.props.onGetOrderFolderResourcesCall(this.props.id).then(response =>{
                this.setState({
                    callData: response.data,
                    folders: response.folder_array
                });
            })
        });
    }


    render() {
        const { isSchedule, header } = this.props;
        const { schDate, orderResourcesDocuments, resourceType, callData, addFolderModal, selectedFolder , expanded, folders} = this.state;
        const formatsMime = returnFormats(resourceType.resource_formats, 'mime_type').join(',');
        const extensionsMime = returnFormats(resourceType.resource_formats, 'extension').join(',');
        let itemId = 1;
        return (
            <div className="form_tile">
                <div className="form_tile_heading flexElem alignCenter spaceBetween" id="" style={{cursor: 'pointer'}} onClick={() => {this.setState({orderDocumentCollapse: !this.state.orderDocumentCollapse})}}>
                    <div className="flexElem alignCenter">
                        <img src={s3StaticPath('img/documents.png')} alt="Documents" />
                        {
                            header
                                ?
                                header
                                :
                                <span>Documents</span>
                        }
                    </div>
                    <div>
                        <span className="mr-sm">Add</span><i className="fa fa-chevron-down" style={{fontSize: 'smaller', transition: '0.2s all ease', transform: this.state.orderDocumentCollapse ? 'rotate(180deg)' : 'rotate(0deg)'}}></i>
                    </div>
                </div>
             <Collapse in={this.state.orderDocumentCollapse}>
                {/* <div className="form_tile_content" > */}
                    <div className="form_tile_content">
                        {
                            this.props.agency_id &&
                                        <GlobalDocuments
                                          agency_id={this.props.agency_id}
                                          parentCallback = {this.handleCallback}
                                        />
                        }

                        {
                            // this.props.formType === 'edit' &&
                            this.props.resourceTypeText == 'audio_video' &&
                            <>

                                <div className="flexElem respFlex spaceBetween form_tile_row mt-lg">
                                    <div className="col-md-12">
                                        <div className="form_tile_title">
                                                <>Audio Video Files</>
                                        </div>
                                    </div>
                                </div>
                                <div className="flexElem respFlex spaceBetween form_tile_row">
                                    <div className="col-md-12">
                                        <div className="table-responsive">
                                            <table className="table_tile mb-md">
                                                <thead>
                                                    <tr className="border-bottom">
                                                        <th width="35%">File Name</th>
                                                        {/* <th width="25%">Uploaded Date</th> */}
                                                        <th width="20%">Status</th>
                                                        <th width="20%">Action</th>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    {
                                                        orderResourcesDocuments.map((item, index) => (
                                                            <tr key={item.id}>
                                                                <td><a style={{ wordBreak: 'break-all' }} className='blue_link' target="_blank" href={item.file_path} rel="noopener noreferrer">{item.original_file_name}</a></td>
                                                                {/* <td>{getFormattedDate(item.created_at)}</td> */}
                                                                <td>{item.is_scheduled === 0 ? 'Sent' : 'Scheduled'}</td>
                                                                <td>
                                                                    <button onClick={() => { this.confirmResourceDelete(index, item.id) }} type='button' className="linkBtn text_14 text-danger"><MdClose /></button>
                                                                    <a className='ml-md' href={item.file_path}><i className='fa fa-download'></i></a>
                                                                </td>
                                                            </tr>
                                                        ))
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </>
                        }

                        {
                            // this.props.formType === 'edit' &&
                            this.props.resourceTypeText === 'order_documents' &&
                            <>
                                <div className="flexElem respFlex spaceBetween form_tile_row mt-lg">
                                    <div className="col-md-12">
                                        <div className="flexElem mb-md">
                                            <span className="form_tile_title">Order Documents</span>
                                            <p className="smallText">Will appear only on this order.</p>
                                        </div>
                                    </div>
                                </div>

                                {
                                    this.props.resourceTypeText === 'order_documents' && callData &&
                                    //WorkDND
                                    callData.map((item, key) => {
                                        // console.log("index: " , key);
                                        // console.log("item: " , item);
                                        if (item.folder_id){
                                            return (
                                                <OrderFolder key = {key} orderId = {this.props.id} uuid = {this.props.uuid} item = {item} ex = {expanded} onFolderClick = {this.onFolderClick} confirmResourceDelete = {this.confirmResourceDelete}
                                                orderResourcesDocuments = {this.props.orderResourcesDocuments} noDelete = {User.hasRole([Roles.TRAVELER]) ? false : true} formType = {this.props.formType} changeDocumentToggle = {this.changeDocumentToggle}/>
                                                      )
                                        }
                                    })

                                }

                                {
                                    this.props.resourceTypeText === 'order_documents' &&
                                        <div className="flexElem respFlex spaceBetween form_tile_row">
                                            <div className="col-md-12">
                                                <div className="table-responsive">
                                                    <table className="table_tile mb-md">
                                                        <thead>
                                                            <tr className="border-bottom">
                                                                <th width="35%">File Name</th>
                                                                <th width="25%">Uploaded Date</th>
                                                                <th width="20%">Status</th>
                                                                <th width="20%">Action</th>
                                                                {
                                                                    !User.hasRole([Roles.TRAVELER]) &&
                                                                    <th width="20%">Traveler Can Access</th>
                                                                }
                                                            </tr>
                                                        </thead>

                                                        <tbody>
                                                            {
                                                                callData.map((item, index) => {
                                                                    // console.log("itemmmm", item),

                                                                    if (item.data){
                                                                    } else if (item.file_path){

                                                                        return(
                                                                        <tr key={item.id}>
                                                                            <td><a style={{ wordBreak: 'break-all' }} className='blue_link' target="_blank" href={item.file_path} rel="noopener noreferrer">{item.original_file_name}</a></td>
                                                                            <td>{dateFormatMyDocuments(item.created_at)}</td>
                                                                            <td>{item.is_scheduled === 0 ? 'Sent' : 'Scheduled'}</td>
                                                                            <td>
                                                                                {
                                                                                    !User.hasRole([Roles.TRAVELER]) &&
                                                                                    <button onClick={() => { this.confirmResourceDelete(index, item.id) }} type='button' className="linkBtn text_14 text-danger"><MdClose /></button>
                                                                                }
                                                                                <a className='ml-md' href={item.file_path}><i className='fa fa-download'></i></a>
                                                                            </td>
                                                                            {
                                                                                !User.hasRole([Roles.TRAVELER]) &&
                                                                                <td>
                                                                                    <ToggleButton
                                                                                        inactiveLabel={''}
                                                                                        activeLabel={''}
                                                                                        colors={themeCSSConstants.THUMBICONSTYLE}
                                                                                        trackStyle={{ height: '10px', width: '30px' }}
                                                                                        thumbStyle={themeCSSConstants.THUMBSTYLE}
                                                                                        thumbAnimateRange={[0, 15]}
                                                                                        value={item.is_public}
                                                                                        onToggle={() => this.changeDocumentToggle(item)}
                                                                                    />
                                                                                </td>
                                                                            }
                                                                        </tr>
                                                                    )
                                                                    }else{
                                                                        return null
                                                                    }
                                                            })
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                }
                            </>
                        }

                        {/* {
                            this.props.resourceTypeText === 'order_documents' && !User.hasRole([Roles.TRAVELER]) &&
                            <div className="flexElem respFlex spaceBetween form_tile_row">
                                <div className="col-md-12">
                                    <div className="col-md-5">
                                        <button onClick={this.openAddFoderModal} className="folderBtn mt-md mb-md">Add New Folder</button>
                                    </div>
                                </div>
                            </div>
                        } */}
                        {/* {
                            this.props.resourceTypeText === 'order_documents' &&
                            <div style={{ display: 'list-item' , listStyle: 'none' }}className="">
                               {
                                   expanded.length > 0 &&
                                    <label className="container-check mb-sm mt-sm">
                                        <span className="ml-xl mb-xl">Upload in <strong>{selectedFolder}?</strong></span>
                                        <input type="checkbox" name="is_active" className="checkbox" value={this.state.is_folder} onChange={this.selectedFolderHandle} checked={this.state.is_folder}
                                        /><span className="checkmark"></span>
                                    </label>
                                }

                            </div>
                        } */}
                        {

                            !this.props.cannotUpload && !User.hasRole([Roles.TRAVELER]) &&
                                <React.Fragment>
                                    <div className="flexElem respFlex spaceBetween form_tile_row">
                                        <div className="col-md-12">
                                            <div className="form_tile_title">Upload New File</div>
                                        </div>
                                    </div>
                                    {/* Select Folder */}
                                    {
                                        this.props.resourceTypeText === 'order_documents' && folders &&
                                        <div className="flexElem respFlex spaceBetween form_tile_row">
                                            <div className="col-md-12">
                                                <div className='form-group' id="folderId">
                                                    <label htmlFor="name">Select Folder</label>
                                                        <span className="addFolderLink">
                                                            <a style={{fontWeight: 'bold'}} onClick={this.openAddFoderModal}>Add New Folder</a>
                                                        </span>
                                                    <Select
                                                        value={returnLabelWithValue(this.state.order_folder_id, folders)}
                                                        onChange={this.handleChangeFolder}
                                                        options={folders}
                                                        // defaultValue={this.state.order_folder_id}
                                                        // options={options.itemTypes}
                                                        isSearchable={false}
                                                        isMulti={false}
                                                        placeholder={'Select Type'}
                                                        isClearable={false}
                                                        name="order_folder_id"
                                                    />
                                                    {/* {this.validatorItem.message('itemId', item.item_type_id, 'required')} */}
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    <div className="flexElem respFlex spaceBetween alignCenter form_tile_row">
                                        <div className="col-md-4">
                                            <button onClick={() => { this.uploadForm.open() }} className="themeBtn_14 hover_dark blue_theme_button" style={{borderRadius: '0px'}}>
                                                <img src={s3StaticPath('img/upload_photo_white.png')} alt="Upload" />
                                                <span>Choose File</span>
                                            </button>
                                        </div>
                                        <div className="col-md-8">
                                            <div className='row flexElem flexResponsive align-items-center' >
                                                <div className="col-md-12">
                                                    {
                                                        isSchedule &&
                                                        this.dateElement('Date to appear on app', 'schDate', schDate !== '' && moment(schDate, 'MM-DD-YYYY').format('L'), [{ before_or_equal: moment(this.state.schDate) }])
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-5">
                                            {
                                                (this.props.notEditable || this.props.formType === 'edit' || this.props.isPopUpForm === true) &&
                                                <React.Fragment>
                                                    <button onClick={this.resourceSubmitHandler} className="blue_theme_button themeBtn_12 block hover_dark">Upload</button>
                                                </React.Fragment>
                                            }
                                            <div style={{ display: 'none' }} >
                                                <FileUpload
                                                    ref={el => { this.uploadForm = el }}
                                                    setResourceFile={this.setResourceFile}
                                                    multiple={false}
                                                    allowedFormatsStringMimePro={formatsMime}
                                                    allowedFormatsStringExtPro={extensionsMime}
                                                />
                                            </div>
                                            {
                                                this.state.files.length < 1 &&
                                                <span className="theme_color text_16 text_elipsis">No File Chosen</span>
                                            }
                                        </div>
                                    </div>
                                    {
                                        (this.props.formType === 'add' && this.props.isPopUpForm !== true) &&
                                        <div class="flexElem respFlex spaceBetween form_tile_row">
                                            <div class="col-md-12">
                                                <center>Documents will be uploaded when you Save Order below</center>
                                            </div>
                                        </div>
                                    }
                                    {this.Validator.message('Documents', this.state.files, "required|min:1,array")}
                                </React.Fragment>

                        }
                        <div>
                            <ImagePreview
                                filesToPreview={this.state.files}
                                removeFile={this.removeFile} />
                            {this.props.progress !== 0 && <span>{this.props.progress}</span>}
                        </div>
                        <div className="flexElem respFlex spaceBetween form_tile_row">
                            <div className="col-md-6">
                                {/* <button className="themeBtn_14 blue_theme_button hover_dark block">Submit Order</button> */}
                            </div>
                        </div>

                    </div>
              {/* </div> */}
          </Collapse>

                        {/* Add Folder Model */}
            <Modal size="sm" backdrop={'static'} show={addFolderModal} id="" onHide={closeModal.bind(this, "addFolderModal")} aria-labelledby="ModalHeader" className="" >
                <Modal.Header closeButton>
                    <Modal.Title id='ModalHeader' className="headerTitle">Add New Folder</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="clearfix">
                        <input type="text" value={this.state.folderName} onChange={this.storeFodlerhandler} className="form-control" placeholder="Enter Folder Name"/>
                        <br />
                        {/* <button onClick={closeModal.bind(this, "addFolderModal")}  className="folderBtn mr-md "> Back</button> */}

                        <div className="section-frontEnd">
                            <button onClick={() => this.folderSubmitHandler()} style={{float: "right"}} className="pl-xlg pr-xlg themeBtn_12 blue_theme_button hover_dark">Submit</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { resourceType, orderResourcesDocuments, orderFolderDocuments } = state.ordersReducer;
    return {
        resourceType, orderResourcesDocuments, orderFolderDocuments
    }
}

const mapDispatchToProps = dispatch => {
    return {
        clearOrderResources: () => { dispatch(clearOrderResources()) },
        onOrderResourceFormatsCall: (type) => { return dispatch(getOrderResourceFormats(type)) },
        onGetOrderResourcesCall: (orderId, type) => { return dispatch(getOrderResources(orderId, type)) },
        onGetOrderFolderResourcesCall: (orderId) => { return dispatch(getFolderOrderResources(orderId)) },
        onStoreOrderResourceCall: (resource, id) => dispatch(storeOrderResource(resource, id)),
        onResourceDeleteCall: (resources, id) => { return dispatch(orderResourceDelete(resources, id)) },
        success: (message) => { return dispatch(alertActions.success(message))},
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Documents))
