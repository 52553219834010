import React from "react";
import { connect } from "react-redux";
import { processShipStatus } from "redux/actions/order-actions";
import { getAllShippingMethods } from "redux/actions/shippingMethod-actions";
import ReactValidator from "simple-react-validator";
import { confirmAlert } from 'react-confirm-alert';
import ConfirmEditTracking from "../../ThemeComponents/ConfirmEditTracking";


class ShipStatusForm extends React.Component {

    constructor(props) {
        super(props);
        // this.props.getAllShippingMethodsCall();
        this.state = {
            shipping_method_id: 4,
            // shipping_method_id: this.props.shippingDetails ? this.props.shippingDetails.shipping_method_id : 4,
            tracking_number: this.props.shippingDetails ? this.props.shippingDetails.tracking_number : '',
            status: 1,
            // status: this.props.shippingDetails ? 1 : 0,
            slug: this.props.item,
            sourceEnd : 'admin',
            // initialPageLoad : true,
            orderShippingId: this.props.shippingDetails ? this.props.shippingDetails.id : '',
            send_shipping_email: this.props.shippingDetails && this.props.shippingDetails.send_shipping_email ? this.props.shippingDetails.send_shipping_email : (this.props.shippingDetails && !this.props.shippingDetails.send_shipping_email ? 0 : 1)
        }
        this.validator = new ReactValidator();
    }


    videoShipConfirmTracking = () => {
        if (!this.props.video_ship_tracking_number && this.state.status === 1 && this.state.tracking_number) {
            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                        <ConfirmEditTracking typeName="No Video Tracking!" description="There is no video shipping tracking, Please investigate." onClosePro={onClose} mozaix={true} submit={() => this.shippingStatusSubmitHandler()} />
                    )
                }
            })
        } else {
            this.shippingStatusSubmitHandler();
        }
    }

    shippingStatusSubmitHandler = () => {
        if (this.validator.allValid()) {
            const data = { ...this.state };
            //Sending order shipping id along request.
            this.props.changeShipStatusCall(this.props.orderId, data).then(
                response => {
                    this.props.onHide()
                    if ('dataCall' in this.props && this.props.dataCall) {
                        this.props.dataCall();
                    }
                }
            );
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    inputChangeHandler = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    methodChangeHandler = (e) => {
        this.setState({
            [e.target.name]: e.target.value
            // initialPageLoad : false
        })
    }

    checkChange = (e) => {

        console.log('send_shipping_email ',e.target.checked);
        const check = e.target.checked;
        if (check) {
            this.setState({
                send_shipping_email: 1
            });
        }
        else {
            this.setState({
                send_shipping_email: 0
            });
        }
    }

    render() {
        const { shippingMethods, item, shippingDetails, send_shipping_email, reOrder } = this.props;
        console.log(shippingMethods,"Shipping methods", this.state.status)
        return (
            <div className='orderDetailsBox' style={{ "border": "none" }}>
                <table className='table orderTable' >
                    <tbody>
                        <tr>
                            <td className='td_heading text-capitalize' >{'reOrder' in this.props ? 'Reorder Purchase' :(item === 'camera' ? 'Pre-Trip Materials' : item === 'mosaic' ? item.replace('mosaic', 'mozaix') : item.replace('_', ' '))} Shipped</td>
                            <td>
                                <div>
                                <label><input onChange={this.inputChangeHandler} type='radio' name='status' checked={(parseInt(this.state.status, 10) === 1 || this.state.status === 0 ) ? true : null} value={1} /> Yes</label>
                                </div>
                                <div>
                                <label><input onChange={this.inputChangeHandler} type='radio' name='status'  value={0} /> No</label>
                                </div>
                                {this.validator.message('status', this.state.status, 'required')}
                            </td>
                        </tr>
                        <tr>
                            <td className='td_heading' >Shipping Method</td>
                            <td>
                                {shippingMethods.map(item => {
                                    return (
                                        <div key={item.id} >
                                            <label>
                                                {/* https://techliance.teamwork.com/#/tasks/19678153?c=9770438 */}
                                                {/* No this is still not working. Before an order is processed it does have the correct spots filled and the curser in the correct spot, but once the order is processed it does not have USPS selected and curser is not in tracking number spot. */}
                                                <input
                                                    type='radio'
                                                    onChange={this.methodChangeHandler}
                                                    name='shipping_method_id'
                                                    checked={ this.state.shipping_method_id == item.id  }
                                                    // defaultChecked={item.id === 4 ? true : false}
                                                    value={item.id}
                                                />
                                                {/* <input
                                                    type='radio'
                                                    onChange={this.inputChangeHandler}
                                                    name='shipping_method_id'
                                                    checked={ shippingDetails ? item.id === parseInt(this.state.shipping_method_id, 10) :
                                                        item.id === 4 && this.state.shipping_method_id === 4
                                                        ?
                                                        true
                                                        :
                                                        null
                                                    }
                                                    value={item.id}
                                                /> */}
                                                    {item.title.replace('mosaic', 'moazix')}
                                            </label>
                                        </div>
                                    )
                                })}
                                {this.validator.message('shipping method', this.state.shipping_method_id, 'required')}
                            </td>
                        </tr>
                        <tr>
                            <td className='td_heading' >Tracking Number</td>
                            <td>
                                <input type='text' onChange={this.inputChangeHandler} autoFocus value={this.state.tracking_number}  name='tracking_number' className='form-control' placeholder='Tracking Number' />
                            </td>
                        </tr>

                        <tr>
                            <td className='td_heading text-capitalize' >Send Shipping Email</td>
                            <td>
                                <div>
                                    <label className="container-check">
                                        <input
                                            type="checkbox"
                                            onChange={this.checkChange}
                                            name='send_shipping_email'
                                            checked={Number(this.state.send_shipping_email) ? true : false}
                                            className="checkbox"
                                            value={1}
                                        />
                                        <span className="checkmark"></span>
                                    </label>
                                </div>
                            </td>
                        </tr>

                    </tbody>
                </table>

                <div className="text-center"><button onClick={item === 'mosaic' ? this.videoShipConfirmTracking : this.shippingStatusSubmitHandler} className='backButton pt-sm no_radius pb-sm primary btn btn-md btn-info ml-sm mt-sm' >Save</button></div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { shippingMethods } = state.ShippingReducer;
    return { shippingMethods }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getAllShippingMethodsCall: () => { dispatch(getAllShippingMethods()) },
        changeShipStatusCall: (orderId, data) => { return dispatch(processShipStatus(orderId, data)) }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ShipStatusForm);
