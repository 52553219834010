import React, { Component } from "react";
import { helperFunctions } from "../../helper/helperFunctions";
import ThemeModal from "./ThemeModal";
// import { Modal } from "react-bootstrap";
var Modal = require('react-bootstrap-modal');

class LoginPopUp extends Component {
    constructor(props) {
        super(props)
        console.log("enterrrr");
        this.state = {
            loginErrorModal: false,

        }
    }
    componentDidUpdate(prevProps, prevState) {
       if (this.state.loginErrorModal !== prevState.loginErrorModal) {
            if (this.props.parentState) {
                this.props.parentState(this.state.loginErrorModal)
            }
       }
    }
    componentDidMount() {
        this.setState({
            loginErrorModal: true
        })
    }

    render() {
        const { REACT_APP_ITK_URL } = process.env;
        return (
            <ThemeModal size={'lg'} title={this.props.title} show={this.state.loginErrorModal} hide={helperFunctions.closeModal.bind(this, 'loginErrorModal')} >
               <p>Please use <a href={REACT_APP_ITK_URL} className="blue_link">Incentive TripKit </a> for login.</p>
               <p>If the above link is not working for you, then please use this url {REACT_APP_ITK_URL}</p>
            </ThemeModal>
        );
    }
}
export default LoginPopUp;
