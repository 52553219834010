import { SC } from '../helper/ServerCall';

export const brandService = {
    getBrandsPagination,
    storeBrand,
    getBrand,
    editBrand,
    deleteBrand,
    updateBrandStatus,

    getFaqsPagination,
    getFaqsCategories,
    storeFaq,
    getFaq,
    editFaq,
    deleteFaq,
    updateFaqStatus,


    getTransmittalTemplatesPagination,
    storeTransmittalTemplate,
    getTransmittalTemplate,
    editTransmittalTemplate,
    deleteTransmittalTemplate,
    getTransmittalTemplatesCategories,
    getTransmittalTemplatesTypes,
    updateTransmittalTemplateStatus,

    getBrandAppSetting,
    updateBrandAppSettings,
    handleBrandActiveStatus
}

function getBrandsPagination(page, filter, sort, pageSize, status) {
    return SC.getCall('brands?page=' + page + '&filter=' + filter + '&sort=' + sort + '&pageSize=' + pageSize + '&status=' + status);
}
function storeBrand(brandData) {
    return SC.postCall('brands', brandData);
}
function getBrand(brandId) {
    return SC.getCall('brands/' + brandId);
}
function editBrand(brandData, brandId) {
    return SC.postCall('brands/' + brandId, brandData);
}
function deleteBrand(brandId) {
    return SC.deleteCall('brands/' + brandId);
}
function updateBrandStatus(brandId, brandData) {
    return SC.putCall(`brandStatusChange/${brandId}`, brandData);
}
function getFaqsPagination(accountId, page, filter, sort, pageSize, is_web) {
    return SC.getCall(`brands/${accountId}/faqs?page=${page}&filter=${filter}&sort=${sort}&pageSize=${pageSize}&is_web=${is_web}`);
}
function getFaqsCategories() {
    return SC.getCall('faqs/categories');
}
function storeFaq(accountId, faqData) {
    return SC.postCall(`brands/${accountId}/faqs`, faqData);
}
function getFaq(accountId, faqId) {
    return SC.getCall(`brands/${accountId}/faqs/${faqId}`);
}
function editFaq(accountId, faqData, faqId) {
    return SC.putCall(`brands/${accountId}/faqs/${faqId}`, faqData);
}
function deleteFaq(accountId, faqId) {
    return SC.deleteCall(`brands/${accountId}/faqs/${faqId}`);
}
function updateFaqStatus(faqId, faqData) {
    return SC.putCall(`faqStatusChange/${faqId}`, faqData);
}
function getTransmittalTemplatesPagination(accountId, page, filter, sort, pageSize, status) {
    return SC.getCall(`brands/${accountId}/transmittalTemplate?page=${page}&filter=${filter}&sort=${sort}&pageSize=${pageSize}&status=${status}`);
}
function storeTransmittalTemplate(accountId, transmittalTemplateData) {
    return SC.postCall(`brands/${accountId}/transmittalTemplate`, transmittalTemplateData);
}
function getTransmittalTemplate(accountId, transmittalTemplateId) {
    return SC.getCall(`brands/${accountId}/transmittalTemplate/${transmittalTemplateId}`);
}
function editTransmittalTemplate(accountId, transmittalTemplateData, transmittalTemplateId) {
    return SC.putCall(`brands/${accountId}/transmittalTemplate/${transmittalTemplateId}`, transmittalTemplateData);
}
function deleteTransmittalTemplate(accountId, transmittalTemplateId) {
    return SC.deleteCall(`brands/${accountId}/transmittalTemplate/${transmittalTemplateId}`);
}
function getTransmittalTemplatesTypes() {
    return SC.getCall('transmittalTemplate/types');
}
function getTransmittalTemplatesCategories(typeId) {
    console.log('typeId 2', typeId);
    return SC.getCall(`transmittalTemplate/categories/${typeId}`);
}
function updateTransmittalTemplateStatus(transmittalTemplateId, transmittalTemplateData) {
    return SC.putCall(`transmittalTemplateStatusChange/${transmittalTemplateId}`, transmittalTemplateData);
}
function getBrandAppSetting(brandId) {
    return SC.getCall('getBrandAppSettingFields/' + brandId);
}
function updateBrandAppSettings(id, data) {
    return SC.putCall(`updateBrandAppSettings/${id}`, data);
}

function handleBrandActiveStatus(id) {
    return SC.putCall(`handleBrandActiveStatus/${id}`, {});
}