import React, { useState, useEffect } from "react";
import { withRouter } from 'react-router-dom';
import { orderService } from "../../../services/order";
import TravelersList from "./TravelersList";
import TravelerConversation from "./TravelerConversation";
// import GroupConversation from "./GroupConversation";
import './ChatModule.css';

function ChatWrapper(props) {

    const [usersMap, setUsersMap] = useState({});
    const [sendersList, setSendersList] = useState([]);
    const [summary_chats, setSummaryChats] = useState([]);
    const [groups, setGroups] = useState([]);
    const [filteredGroups, setFilteredGroups] = useState([]);
    const [receiversList, setReceiversList] = useState([]);
    const [allReceiversList, setAllReceiversList] = useState([]);
    const [sender, setSender] = useState(undefined);
    const [receiver, setReceiver] = useState(undefined);
    const [groupChat, setGroupChat] = useState(undefined);
    const [order_id, setOrderId] = useState('');


    async function getTravelersList() {
        try {
            setOrderId(props.match.params.order_id);
            const res = await orderService.getAllFollowingOrderUsers(props.match.params.order_id);
            const { summary_chats, users, usersMap } = res.result.data;
            setUsersMap(usersMap);
            const tempSenders = {};
            const tempReceivers = {};
            let groups = {};
            summary_chats.forEach(chat => {
                if (chat.sent_by in usersMap && chat.id.includes(chat.sent_by)) {
                    let user = usersMap[chat.sent_by];
                    tempSenders[user.id] = {
                        ...user,
                        selected: false,
                        type: 'sender',
                    }
                }
                if (chat.received_by in usersMap && chat.id.includes(chat.received_by)) {
                    let user = usersMap[chat.received_by];
                    tempReceivers[user.id] = {
                        ...user,
                        selected: false,
                        type: 'receiver',
                    }
                }

                if (chat.type == '2') {
                    groups[chat.id] = { ...chat }
                    chat.members.forEach(ch_mem => {
                        let user = usersMap[ch_mem];
                        tempSenders[user.id] = {
                            ...user,
                            selected: false,
                            type: 'sender',
                        }
                    })
                }
            });
            const senders = [...Object.values(tempSenders)];
            const receivers = [...Object.values(tempReceivers)];
            setSendersList(senders);
            setAllReceiversList(receivers);
            setGroups([...Object.values(groups)]);
            setSummaryChats(res.result.data.summary_chats);
        } catch (e) {
            console.log('Error in listing', e);
        }
    }

    useEffect(() => {
        getTravelersList();
    }, []);

    function setSelectedGroup(group) {
        const temp = filteredGroups.map(grp => {
            if (grp.id === group.id)
                grp.selected = true;
            else
                grp.selected = false;
            return grp;
        })

        setFilteredGroups(temp);

        setGroupChat(group);
        const receivers = unselectAllItems(receiversList);
        setReceiversList(receivers);
        setReceiver(undefined);
    }

    function unselectAllItems(arr) {
        const temp = [...arr].map(item => {
            item.selected = false;
            return item;
        })
        return temp;
    }

    function setSelectedSender(sender) {
        const temp = sendersList.map(tvl => {
            if (tvl.id === sender.id)
                tvl.selected = true;
            else
                tvl.selected = false;
            return tvl;
        })
        setSendersList(temp);
        setSender(sender);

        let receiver_ids = [];
        summary_chats.forEach(chat => {
            if (chat.sent_by === sender.id) {
                receiver_ids.push(chat.received_by);
            }
        })

        const temp_receiveres = [];
        allReceiversList.forEach(tvl => {
            if (receiver_ids.includes(tvl.id)) {
                temp_receiveres.push({ ...tvl });
            }
        })

        const filtered_groups = [];
        groups.forEach(group => {
            if (group.members.includes(Number(sender.id))) {
                filtered_groups.push({ ...group });
            }
        })

        setFilteredGroups(filtered_groups);
        setReceiversList(temp_receiveres);
        setReceiver(undefined);
        setGroupChat(undefined);
    }

    function setSelectedReceiver(receiver) {
        const groupsList = unselectAllItems(filteredGroups);
        setFilteredGroups(groupsList);
        setGroupChat(undefined);
        const temp = receiversList.map(tvl => {
            if (tvl.id === receiver.id)
                tvl.selected = true;
            else
                tvl.selected = false;
            return tvl;
        })
        setReceiversList(temp);
        setReceiver(receiver);
    }

    return (
        <div>
            {
                (sendersList.length > 0 || groups.length > 0) ?
                    // <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                    <div className="row chat-wrapper">
                        <div className="col-sm-12 col-md-4">
                            <TravelersList
                                travelersList={sendersList}
                                header={'Senders'}
                                setSelectedSender={setSelectedSender}
                                setSelectedReceiver={setSelectedReceiver}
                            />
                        </div>
                        <div className="col-sm-12 col-md-4">
                            <div className="listGroups">
                            <TravelersList
                                travelersList={receiversList}
                                header={'Receivers'}
                                groups={filteredGroups}
                                type={"receivers"}
                                setSelectedSender={setSelectedSender}
                                setSelectedReceiver={setSelectedReceiver}
                                setSelectedGroup={setSelectedGroup}
                            />
                            </div>
                        </div>
                        {
                            // (sender && receiver && !groupChat) ?
                            ((sender && receiver) || groupChat) ?
                                <div className="col-sm-12 col-md-4">
                                    <TravelerConversation sender={sender} receiver={receiver} groupChat={groupChat} summary_chats={summary_chats} order_id={order_id} />
                                </div>
                                :
                                <div className="col-sm-12 col-md-4">
                                </div>
                        }
                    </div>
                    :
                    <div style={{ minHeight: '500px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <div style={{ fontSize: '3em', fontWeight: '200px' }}>
                            No Data Available
                        </div>
                        <div>
                            <button className="btn btn-primary" onClick={() => { props.history.goBack() }}>Go Back</button>
                        </div>
                    </div>
            }
        </div>
    );
}

export default withRouter(ChatWrapper);
