import React, { Component } from "react";
import { connect } from 'react-redux';
import { Grid, Row, Col } from "react-bootstrap";
import { Card } from "components/Card/Card.jsx";
import { contactList } from "redux/actions/order-actions";
import ContactListPagination from './ContactListPagination';
import { dateFormatMy } from 'helper/helperFunctions';
import { Link } from 'react-router-dom';
import { getUiSettings } from 'redux/actions/settings-actions';
import "react-tabs/style/react-tabs.css";

class ContactListEQPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapseUiSettings: false,
      index: 0,
      numOfDays: 7
    }
    this.props.getUiSettingsCall();
  }

  handleChangeContactLogDays = (selectedOptionType, info) => {
    if (info['action'] === 'select-option') {
      // const temp = cloneDeep(this.state);
      // temp[info['name']] = selectedOptionType['value'];
      // if (info['name'] === 'program_type_id') {
      // this.props.getProgramItemCall(selectedOptionType['value']);
      this.setState({ numOfDays: selectedOptionType['value'] });
      // }
    }
  }
  render() {

    const VScolumns = [
      {
        Header: "Video Ship",
        headerClassName: 'headerPagination',
        columns: [
          {
            Header: "Barcode",
            accessor: "barcode",
            width: 200,
            Cell: ({ value, original }) => {
              return <Link target="_blank" to={'/Admin/' + (original.reorder ? original.reorder : original.id) + '/order-edit'} className="blue_link">{value}</Link>
            },
            className: "action-center",
          },
          {
            Header: "Group Name",
            accessor: "group_name",
            className: "action-center"
          },
          {
            Header: "Contact",
            accessor: "group_leader_contact.email",
            className: "action-center"
          },
          {
            Header: "Departure Date",
            accessor: "order_dates.departure_date",
            Cell: ({ value }) => (
              <div>
                {dateFormatMy(value.value)}
              </div>
            ),
            className: "action-center",
            id: 'order_dates.departure_date.value'
          },
          {
            Header: "Return Date",
            accessor: "order_dates.return_date",
            Cell: ({ value }) => (
              <div>
                {dateFormatMy(value.value)}
              </div>
            ),
            className: "action-center",
            id: "order_dates.return_date.value"
          },
          {
            id: 'contactLogLastComment',
            Header: "Contact Log Last Entry",
            accessor: "contactLogLastComment",
            Cell: ({ value }) => (
              <div>
                {value ? dateFormatMy(value) : 'N/A'}
              </div>
            ),
            className: "action-center",
          },
          {
            Header: "Video Special need date",
            accessor: "order_dates.video_special_need_date",
            Cell: ({ value }) => (
              <div>
                {dateFormatMy(value.value)}
              </div>
            ),
            className: "action-center",
            id: "order_dates.video_special_need_date.value"
          },
          {
            Header: "# of photos uploaded",
            accessor: "images_count",
            Cell: ({ value }) => (
              <div>
                {value}
              </div>
            ),
            className: "action-center",
            sortable: false,
          },
          {
            Header: "Contact Log",
            accessor: "id",
            Cell: ({ value }) => (
              <div>
                <Link className="orderDetailsAction editIcon" to={'/Admin/' + value + '/order-edit'}>&nbsp;</Link>
              </div>
            ),
            className: "action-center",
            sortable: false,
          },
        ]
      }
    ];

    const {
      loadingOrder,
      VSContactList_data, VSContactList_pages, VSContactList_current_page,
      uiSettings
    } = this.props;

    const { collapseUiSettings } = this.state;
    return (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                ctTableResponsive
                content={
                  <div>
                    <Grid fluid>
                      <Row>
                        <Card bsClass={["innerUiSettingsCard"]} title="Equipment Not Returned" collapseTrigger={collapseUiSettings} collapsHandler={() => this.setState({ collapseUiSettings: !this.state.collapseUiSettings })} collapsable={true}
                          content={
                            <div className="clearfix">
                              <Col md={4} className="mt-md">
                                <h6>Global Settings</h6>
                                <ul>
                                  {
                                    uiSettings.globalSetting.map((ui2, index) => (
                                      <li key={index}>
                                        <span style={{ color: ui2.value }}>{ui2.description + ':' + ui2.value}</span>
                                      </li>
                                    )
                                    )
                                  }
                                </ul>
                              </Col>
                              <Col md={4} className="mt-md">
                                <h6>Item Categories Settings</h6>
                                <ul>
                                  {
                                    uiSettings.itemCategory.map((ui1, index) => (
                                      <li key={index}>
                                        <span style={{ color: ui1.contact_list_text_color }}>{ui1.title + ':' + ui1.contact_list_text_color}</span>
                                      </li>
                                    )
                                    )
                                  }
                                </ul>
                              </Col>
                              <Col md={4} className="mt-md">
                                <h6>Account Settings</h6>
                                <ul>
                                  {
                                    uiSettings.accountSetting.map((ui3, index) => (
                                      <li key={index}>
                                        <span style={{ backgroundColor: ui3.value }}>{ui3.model.title + ':' + ui3.value}</span>
                                      </li>
                                    )
                                    )
                                  }
                                </ul>
                              </Col>
                            </div>
                          }
                        />
                        <Col md={12} className="mt-md">
                          <div id="tabs" className="orderTabs colored">
                            <ContactListPagination
                              data={VSContactList_data}
                              pages={VSContactList_pages}
                              page={VSContactList_current_page}
                              loading={loadingOrder}
                              columns={VScolumns}
                              getContactListCall={this.props.getContactListCall}
                              callback={'VSContactList'}
                              case={2}
                              numOfDays={365}
                              searchPlaceHolder={'Orders not returned 7 days after return date'}
                              haveBackgroundColor={false}
                            />
                          </div>
                        </Col>
                      </Row>
                    </Grid>
                  </div>
                } />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return ({
    getContactListCall: (data) => { return dispatch(contactList(data)) },
    getUiSettingsCall: () => { dispatch(getUiSettings()) },
  });
};

function mapStateToProps(state) {
  const {
    loadingOrder,
    CSContactList_data, CSContactList_pages, CSContactList_current_page,
    VSContactList_data, VSContactList_pages, VSContactList_current_page,
  } = state.ordersReducer;

  const { loadingSettings, uiSettings } = state.settingsReducer;
  return {
    loadingOrder,
    CSContactList_data, CSContactList_pages, CSContactList_current_page,
    VSContactList_data, VSContactList_pages, VSContactList_current_page,
    loadingSettings, uiSettings,
  };
}


const ContactListEQ = connect(mapStateToProps, mapDispatchToProps)(ContactListEQPage);
export default ContactListEQ;
