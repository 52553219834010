import React, { Component } from "react";
import { connect } from 'react-redux';
import { getReceivingReports } from "redux/actions/report-actions";
import ReactValidator from "simple-react-validator";
import { Row, Col, Grid } from "react-bootstrap";
import { Card } from "components/Card/Card.jsx";
import { dateFormatMy, returnLabelWithValue } from 'helper/helperFunctions';
import { objectsConstants as OC } from 'redux/constant/objects-constant';
import Pagination from 'components/ThemeComponents/Pagination';
import Select from 'react-select';
import { Link } from 'react-router-dom';
import { reportService } from "../../../services/report";
const moment = window.moment;

class RecentlyReceiveOrders extends Component {
	constructor(props) {
		super(props);
		this.validator = new ReactValidator();
		this.state = {

			numOfDays: 14,
			collapseUiSettings: true,
		}
		this.getRecetlyReceivedOrders = this.getRecetlyReceivedOrders.bind(this);
		this.getRowProps = this.getRowProps.bind(this);
	}
	getRecetlyReceivedOrders(data) {
		data = { ...data, numOfDays: this.state.numOfDays };
		return reportService.getRecetlyReceivedOrders(data);
	}
	getRowProps(rowData) {
		let data = {};
		if (parseInt(rowData.settings.finalize_by_editor.value, 10) === 1) {
			data = { color: 'red' }
		}
		if (rowData.order_dates.receive_date.value === "" && rowData.shippingItemsCase > 0) {
			data = { color: 'green' }
		}
		if (rowData.no_equipment_upload_only === 1) {
			data = { color: 'pink' }
		}
		if ((rowData.order_dates.receive_date.value !== "") && (parseInt(rowData.settings.finalize_by_editor.value, 10) === 0) && (parseInt(rowData.settings.on_hold.value, 10) === 1)) {
			if (moment(rowData.settings.photos_submitted_for_video_production.updated_at) < moment(rowData.order_dates.upload_cut_off_date.value)) {
				data = { color: 'blue' }

			}
		}
		return data;
	}


	componentDidUpdate(prevProps, prevState) {
		if (prevState.numOfDays !== this.state.numOfDays) {
			this.pagination.dataCall();
		}
	}
	handleChangeContactLogDays = (selectedOptionType, info) => {
		if (info['action'] === 'select-option') {
			this.setState({ numOfDays: selectedOptionType['value'] });
		}
	}
	render() {
		const columns = [
			{
				Header: "Report Testing",
				headerClassName: 'headerPagination',
				columns: [
					{
						Header: "Barcode",
						accessor: "barcode",
						className: "action-center",
						Cell: ({ value, original }) => {
							return <Link to={'/Admin/' + (original.reorder ? original.reorder : original.id) + '/lab-production-report'} target="_blank" className="blue_link">{value}</Link>
						},
                        width: 200,
					},

					{
						Header: "Group Name",
						accessor: "group_name",
						className: "action-center",
						sortable: false,
					},
					{
						Header: "Departure Date",
						accessor: "order_dates.departure_date",
						Cell: ({ value }) => (
							<div>
								{dateFormatMy(value.value)}
							</div>
						),
						className: "action-center",
						sortable: false,
					},
					{
						Header: "Submission Date",
						accessor: "settings.photos_submitted_for_video_production",
						Cell: ({ value }) => (
							<div>
								{dateFormatMy(value.updated_at)}
							</div>
						),
						className: "action-center",
						sortable: false,
					},
					{
						Header: "Video Special Need Date",
						accessor: "order_dates.video_special_need_date",
						Cell: ({ value }) => (
							<div>
								{dateFormatMy(value.value)}
							</div>
						),
						className: "action-center redText",
						sortable: false,
					},
					{
						Header: "Received Date",
						accessor: "order_dates.receive_date.value",
						Cell: ({ value }) => (
							<div>
								{dateFormatMy(value)}
							</div>
						),
						className: "action-center",
						sortable: false,
					},
					{
						Header: "Number of Photos",
						accessor: "originalImages",
						className: "action-center",
						sortable: false,
					},
					{
						Header: "Submitted By",
						accessor: "settings.photos_submitted_for_video_production.updated_by_user",
						className: "action-center",
						sortable: false,
                        Cell: ({ original, value }) => (
                            console.log("original", value),
							<div>
								{
                                    value.id === 91
                                        ?
                                        <span>System</span>
                                         :
                                          value.roles[0].label === "Photo Admin"
                                           ?
                                           <span>PA</span>
                                            :
                                             <span>GTV rep</span>
                                }
							</div>
						),
					}
				]
			}
		];
		return (
			<div className="content">
				<Grid fluid>
					<Row>
						<Col md={12}>
							<Card
								ctTableResponsive
								content={
									<div>
										<Grid fluid>
											<div className="row">
                                                <Col md={12}>
                                                    <div className="secHeading">
                                                    Recently Received Orders
                                                    </div>

												</Col>
												{/* <div className="col-md-4 col-md-offset-4  mt-md">
													<Select
														value={returnLabelWithValue(numOfDays, OC.UPLOADPHOTOSFORVIDEOPRORECEIVEDAYS)}
														onChange={this.handleChangeContactLogDays}
														options={OC.UPLOADPHOTOSFORVIDEOPRORECEIVEDAYS}
														isSearchable={true}
														isMulti={false}
														name='numOfDays'
														placeholder={'Select Days'}
													/>
												</div> */}
												<Col md={12} className="mt-md">
													<Pagination
														ref={(p) => this.pagination = p}
														columns={columns}
														getDataCall={this.getRecetlyReceivedOrders}
														filterView={false}
														getRowProps={this.getRowProps}
														pageSize={100}
													/>
												</Col>

											</div>
										</Grid>
									</div>
								} />
						</Col>
					</Row>
				</Grid>
			</div>
		);
	}
}

const mapDispatchToProps = dispatch => {
	return ({
		getReceivingReportsCall: (data) => { return dispatch(getReceivingReports(data)) },
	});
};

function mapStateToProps(state) {
	return {
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(RecentlyReceiveOrders);
