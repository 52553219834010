import React, { Component } from "react";
import { Navbar } from "react-bootstrap";
import HeaderLinks from "components/Admin/Header/HeaderLinks";
import { basePathClient } from 'helper/basePathClient';
import { s3StaticPath } from 'helper/helperFunctions';
import {
  // Tooltip,
  OverlayTrigger,
  Popover
} from "react-bootstrap";
import { Link } from 'react-router-dom';
import dashboardRoutes from "routes/dashboard.jsx";
import MyImage from 'components/Upload/MyImage';

class Header extends Component {
  constructor(props) {
    super(props);
    this.mobileSidebarToggle = this.mobileSidebarToggle.bind(this);
    this.getAlert = this.getAlert.bind(this);
    this.state = {
      sidebarExists: true,
      canvasMenuToggle: false
    };
  }
  getAlert() {
    alert('getAlert from Child');
  }

  mobileSidebarToggle(e) {
    if (this.state.sidebarExists === true) {
      this.setState({
        sidebarExists: true
      });
    }
    e.preventDefault();
    document.documentElement.classList.toggle("nav-open");
    var node = document.createElement("div");
    node.id = "bodyClick";
    node.onclick = function () {
      this.parentElement.removeChild(this);
      document.documentElement.classList.toggle("nav-open");
    };
    document.body.appendChild(node);
  }
  getBrand() {
    var name;
    dashboardRoutes.map((prop, key) => {
      if (prop.collapse) {
        prop.views.map((prop, key) => {
          if (prop.path === this.props.location.pathname) {
            name = prop.name;
          }
          return null;
        });
      } else {
        if (prop.redirect) {
          if (prop.path === this.props.location.pathname) {
            name = prop.name;
          }
        } else {
          if (prop.path === this.props.location.pathname) {
            name = prop.name;
          }
        }
      }
      return null;
    });
    return name;
  }
  render() {
    return (
      <Navbar className="CustomHeader" fluid>
        <Navbar.Header>
          <OverlayTrigger overlay={<Popover id="tooltip">Reload Current Module</Popover>}>
            <Navbar.Brand>
              <div className="logo">
                <Link to={basePathClient + 'Admin'} className="simple-text logo-normal">
                  <div className="logo-img">
                    <MyImage src={s3StaticPath('img/gtv_logo_header.png')} alt="logo_image" height="30px" width="120px" />
                  </div>
                </Link>
              </div>
            </Navbar.Brand>
          </OverlayTrigger>

          <Navbar.Toggle onClick={this.mobileSidebarToggle} />
        </Navbar.Header>
        <Navbar.Collapse>
          <HeaderLinks />
        </Navbar.Collapse>
      </Navbar>
    );
  }
};

export default Header;
