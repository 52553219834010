import React from 'react';
import {
	Button
} from "react-bootstrap";

import SimpleReactValidator from 'simple-react-validator';
import { adminLabels } from '../../../redux/constant/admin-label-constant';

class RoleForm extends React.Component {
	constructor(props) {
		super(props);
		this.validatorRole = new SimpleReactValidator({
			messages: {
				min: adminLabels.MIN,
			},
		});
		this.state = {
			role: {
				name: this.props.rolePro.name || '',
				label: this.props.rolePro.label || '',
				//permission: this.props.rolePro.permission || [],
			},
			submitted: false,
			formType: this.props.formTypePro || '',
		};

		this.handleRole = this.handleRole.bind(this);
		this.handleRoleSubmit = this.handleRoleSubmit.bind(this);
		//this.handleChangeRole = this.handleChangeRole.bind(this);
		//this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
	}

	/*handleChangeRole(selectedOption, name) {
		const roleState = { ...this.state.role };
		if (name.action === 'select-option') {
			roleState['permission'].push(name.option.value);
		}
		if (name.action === 'remove-value') {
			roleState['permission'].splice(roleState['permission'].indexOf(name.removedValue.value), 1);
		}
		this.setState({ role: roleState }, function () {
			this.setState({ selectedOption });
		});
	}
	handleCheckboxChange(value,index,e) {
		const roleState = {...this.state.role}
		if(e.target.checked === true){
			roleState['permission'].push(value);
		}else{
			roleState['permission'].splice(index,1);
		}
		this.setState({
			role: roleState
		},function(){
			this.setState({
				selectedOption: roleState
			})
		});
	}*/
	handleRole(e) {
		const { name, value } = e.target;
		const roleState = { ...this.state.role };
		roleState[name] = value;
		this.setState({ role: roleState });
	}
	handleRoleSubmit(e) {
		e.preventDefault();
		if (this.validatorRole.allValid()) {
			this.setState({ submitted: true }, function () {
				if (this.state.formType === 'edit')
					this.props.handleRoleEditSubmitParPro(this.state.role);
				else
					this.props.handleRoleSubmitParPro(this.state.role);
			});
		} else {
			
			this.setState({ submitted: false });

			this.validatorRole.showMessages();
			this.forceUpdate();
		}
	}

	

	render() {
		const { role, formType, /*selectedOption, options*/ } = this.state;
		return (
			<form onSubmit={this.handleRoleSubmit}>
				<div className="row">
					<div className="col-md-12">
						<div className='form-group'>
							<label htmlFor="name">Role Name<span className="requiredClass">*</span></label>
							<input type="text" name="name" className="form-control" value={role.name} onChange={this.handleRole} />
							{this.validatorRole.message('roleName', role.name, 'required')}
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-md-12">
						<div className='form-group'>
							<label htmlFor="name">Role Label<span className="requiredClass">*</span></label>
							<input type="text" name="label" className="form-control" value={role.label} onChange={this.handleRole} />
							{this.validatorRole.message('rolelabel', role.label, 'required')}
						</div>
					</div>
				</div>
				{/*
				<div className="row">
					<div className="col-md-12">
						<div className='form-group'>
							<label htmlFor="name">Select Permission<span className="requiredClass">*</span></label>
							<Select
								value={selectedOption}
								onChange={this.handleChangeRole}
								options={options}
								isSearchable={true}
								isMulti={true}
								placeholder={'Select Permission'}
							/>
							{this.validatorRole.message('permission', role.permission, 'required|array|min:1')}</div>
					</div>
				</div>
				*/}
				<div className="row">
					<div className="col-md-12">
						<div className='text-center'>
							<Button type='submit' bsSize="large" bsStyle="info" className="backButton pt-sm no_radius pb-sm success btn btn-lg btn-info ml-sm mt-sm btn-default  btn btn-lg btn-info" >Save</Button>
							{
								formType === 'edit' &&
								<Button bsSize="large" bsStyle="info" onClick={() => this.props.closeModalRoleEditPro()} className="backButton pt-sm no_radius pb-sm primary btn btn-lg ml-sm mt-sm btn-info " >Cancel</Button>
							}
							{
								formType === 'add' &&
								<Button bsSize="large" bsStyle="info" onClick={() => this.props.closeModalRolePro()} className="backButton pt-sm no_radius pb-sm primary btn btn-lg ml-sm mt-sm btn-info " >Cancel</Button>
							}
						</div>
					</div>
				</div>
			</form>
		);
	}
}

export default RoleForm;