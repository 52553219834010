import { adminConstants } from '../constant/admin-constant';

let userAdmin = JSON.parse(localStorage.getItem('user'));
const initialState = userAdmin ? { loggedIn:true, userAdmin:userAdmin, loading:false, dashboardCount:'', menu:{'parent_labels':{}, 'permissions':{}}} : { loggedIn:false, userAdmin:'', loading:false };

export function adminReducer(state = initialState, action){
	// console.log('type', action.type);
	switch(action.type){
		case adminConstants.LOGIN_ADMIN_REQUEST:
			return { ...state, loading:true };
		case adminConstants.LOGIN_ADMIN_SUCCESS:
			return { ...state, loading:false, userAdmin : action.payload, loggedIn:true };
		case adminConstants.LOGIN_ADMIN_FAILURE:
			return { ...state, loading:false };

		case adminConstants.GET_DASHBOARD_REQUEST:
			return { ...state, loading:true };
		case adminConstants.GET_DASHBOARD_SUCCESS:
			return { ...state, loading:false, dashboardCount : action.payload.dashboardCount };
		case adminConstants.GET_DASHBOARD_FAILURE:
			return { ...state, loading:false, error : action.payload };


		case adminConstants.GET_MENU_DASHBOARD_REQUEST:
			return { ...state, loading:true };
		case adminConstants.GET_MENU_DASHBOARD_SUCCESS:
			// return { ...state, loading:false, menu : action.payload.menu };
			return { ...state, loading:false, menu : action.payload && action.payload.menu ? action.payload.menu : initialState.menu };
		case adminConstants.GET_MENU_DASHBOARD_FAILURE:
			return { ...state, loading:false, error : action.payload };

		case adminConstants.LOGOUT_ADMIN_REQUEST:
			return {...state};
		case adminConstants.LOGOUT_ADMIN_SUCCESS:
			return {...state};
		case adminConstants.LOGOUT_ADMIN_FAILURE:
			return {...state};

		default:

			return {...state};
	}
}