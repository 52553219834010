import React from 'react';
// import { Link } from 'react-router-dom'
// import logo from '../../../assets/img/logo_white.png';
import { logout } from 'redux/actions/front-end-action';
import SideNavDashboard from 'layouts/Frontend/Dashboard/SideNavDashboard';
// import Logo from '../../../components/FrontEnd/Brand/Logo';
export const Dashboard = function (Component) {
  return class extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        showNav: false
      }
    }
    handleNav = () => {
      this.setState({ showNav: !this.state.showNav })
    }

    logout = () => {
      this.props.dispatch(logout());
    }

    render() {

      return (
        <React.Fragment>

          <SideNavDashboard logout={this.logout} handleNav={this.handleNav} showNav={this.state.showNav} fetchUploaderInfo={true} />

          {/* <div className="subNav">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <ul className="flexElem alignCenter spaceBetween">
                    <li><Link className="d-inline-block" to="/dashboard">
                      <Logo className='w-25' />
                    </Link></li>
                    <li>
                      <button type="button" className="sideNavBtn" onClick={this.handleNav}><IoMdMenu /></button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div> */}
          <div className="dashboardContainer">
            <Component {...this.props} />
          </div>
        </React.Fragment>
      )
    }
  }
}
