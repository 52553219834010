import React, { Component } from "react";
import { connect } from 'react-redux';
import Select from 'react-select';
import { returnLabelWithValue } from 'helper/helperFunctions';
import { submitInvoicingImport } from "redux/actions/invoicing-actions";
import { getAllAgencies } from 'redux/actions/agency-actions';
//import { Link } from "react-router-dom";
import { getAgencyType } from 'redux/actions/settings-actions';
import {
    Grid,
    Row,
    Col,
    OverlayTrigger,
    Popover,
    Button
} from "react-bootstrap";
import { Card } from "components/Card/Card.jsx";
import CSVReader from 'react-csv-reader';

class InvoicingImportPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            numDays: 0,
            agency_id: 0,
            group_type: 0,
            orderData: {},
            newInvData: {},
            papaparseOptions: {
                header: true,
                dynamicTyping: true,
                skipEmptyLines: true,
                transformHeader: header => header.toLowerCase().replace(/\W/g, "_")
            }
        };
        this.props.getAgenciesCall();
        this.props.getAgencyTypeCall();
        this.handleForce = this.handleForce.bind(this);
        this.handleChangeOrderDetailDropDown = this.handleChangeOrderDetailDropDown.bind(this);
        this.handleChangeAgencyDropDown = this.handleChangeAgencyDropDown.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        // console.log('this.props.reportsData',this.props.reportsData);
        if (prevProps.settingsData !== this.props.settingsData) {
            const days = this.props.settingsData.value;
            this.setState({
                numDays: days
            })
            // console.log('updated');
        }
        if (prevProps.invData !== this.props.invData) {
            this.setState({
                newInvData: this.props.invData
            }, () => { /*console.log('this.state.newInvData',this.state.newInvData)*/ })
        }
    }

    handleChangeOrderDetailDropDown(selectedOptionType, info) {
        if (info['action'] === 'select-option') {
            this.setState({
                agency_id: selectedOptionType['value']
            }, () => { /*console.log('agency id', this.state.agency_id)*/ });
        }
    }
    handleChangeAgencyDropDown(selectedOptionType, info) {
        if (info['action'] === 'select-option') {
            this.setState({
                group_type: selectedOptionType['value']
            }, () => { /*console.log('group_type', this.state.group_type)*/ });
        }
    }

    handleForce = (data, fileName) => {
        this.setState({
            orderData: data
        }, () => { /*console.log('orderData', this.state.orderData)*/ });
    }
    sendOrdersTOInvoice = () => {
        const agency_id = this.state.agency_id;
        const group_type = this.state.group_type;
        const orderData = this.state.orderData;
        if (!agency_id || agency_id === 0) {
            alert('Please Select The Agency');
            return

        }
        if (!group_type || group_type === 0) {
            alert('Please Select Group Type');
            return

        }
        this.props.submitInvoicingImportCall({ agency_id: agency_id, group_type: group_type, orders: orderData }).then(
            response => {
                document.getElementById('react-csv-reader-input').value = '';
                this.setState({
                    numDays: 0,
                    agency_id: 0,
                    group_type: 0,
                    orderData: {},
                });
                this.props.getAgenciesCall();
            },
            error => {

            }
        );

    }




    render() {
        const { group_type, agency_id, papaparseOptions, newInvData } = this.state;
        const { agenciesOption, agency_type_id } = this.props;
        //console.log('this.state...', this.state);
        return (
            <div className="content">
                <Grid fluid>
                    <Row>
                        <Col md={12}>
                            <Card bsClass={["customCard"]} content={
                                <div>
                                    <Grid fluid>
										<Row>
											<Col md={12} >
                                                {
                                                newInvData && Object.keys(newInvData).length > 0 &&
                                                    <React.Fragment>
                                                        <div className="col-md-4 col-md-offset-4 mt-lg">
                                                            <div className="alert-success alert">Total Rows Imported: {newInvData.processed.length}</div>
                                                        </div>

                                                        <div className="col-md-4 col-md-offset-4 mt-lg">
                                                            <div className="alert-danger alert">Rows Failed:{
                                                                newInvData.missing.map((item, i) => {
                                                                    return <p key={i}>{item}</p>;
                                                                })
                                                            } </div>
                                                        </div>
                                                    </React.Fragment>
                                                }

                                                <div className="col-md-4 col-md-offset-4">
                                                    <div className="form-group" id="agency">
                                                        <Select
                                                            value={returnLabelWithValue(agency_id, agenciesOption) || null}
                                                            onChange={this.handleChangeOrderDetailDropDown}
                                                            options={agenciesOption}
                                                            isSearchable={true}
                                                            isMulti={false}
                                                            name='agency_id'
                                                            placeholder={'Select Agency'}
                                                        />

                                                    </div>

                                                    <div className="form-group" id="groupType">
                                                        <Select
                                                            value={returnLabelWithValue(group_type, agency_type_id)}
                                                            onChange={this.handleChangeAgencyDropDown}
                                                            options={agency_type_id}
                                                            isSearchable={true}
                                                            isMulti={false}
                                                            name='group_type'
                                                            placeholder={'Select Group Type'}

                                                        />

                                                    </div>

                                                    <div className="form-group mt-md">
                                                        <CSVReader
                                                            cssClass="react-csv-input"
                                                            onFileLoaded={this.handleForce}
                                                            parserOptions={papaparseOptions}
                                                        />
                                                    </div>

                                                    <div className="form-group mt-md">
                                                        <OverlayTrigger placement="bottom" trigger={['hover']} overlay={<Popover id="tooltip">Import Orders</Popover>}>
                                                            <Button type='button' className="backButton pt-sm no_radius btn-block pb-sm primary mt-none btn btn btn-info " onClick={this.sendOrdersTOInvoice} ><i className="fa fa-plus" />Import</Button>
                                                        </OverlayTrigger>
                                                    </div>

                                                    <div className="form-group mt-md">
                                                        <a style={{fontWeight:"400", fontSize:"15px"}} target="_blank" rel="noopener noreferrer" href="https://gtvoriginalimages.s3.amazonaws.com/production/global/orderTemplate.csv" className="linkColor">Template to Give to T/Os to Fill in Orders</a>
                                                    </div>
                                                    <div className="form-group mt-md">
                                                        <a style={{fontWeight:"400", fontSize:"15px"}} target="_blank" rel="noopener noreferrer" href="https://gtvoriginalimages.s3.amazonaws.com/production/global/orderTemplateWithGoodHeaders.csv" className="linkColor">Template With Good Headers to Give to T/Os to Fill in Orders</a>
                                                    </div>
                                                </div>
											</Col>
										</Row>
                                    </Grid>
                                </div>
                            } />
                        </Col>
                    </Row>
                </Grid>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return ({
        getAgenciesCall: () => { dispatch(getAllAgencies()) },
        getAgencyTypeCall: () => { dispatch(getAgencyType()) },
        submitInvoicingImportCall: (ordersData) => {
            return dispatch(submitInvoicingImport(ordersData))
        },

    });
};

function mapStateToProps(state) {
    const { loadingAgency, agenciesOption } = state.agenciesReducer;
    const { agency_type_id } = state.settingsReducer;
    const { invData } = state.invoicingReducer;
    return {
        loadingAgency, agenciesOption, invData, agency_type_id
    };
}


const InvoicingImport = connect(mapStateToProps, mapDispatchToProps)(InvoicingImportPage);
export default InvoicingImport;