import React, { Component } from 'react'
import { connect } from 'react-redux'
// import { Link } from 'react-router-dom'
import { getBrandingInfoById, getBrandingInfoBySlug } from 'redux/actions/account-action';
import { helperFunctions as HF } from 'helper/helperFunctions'
// import MyImage from 'components/Upload/MyImage';
import User from 'helper/User';
import { Roles } from '../../../constants/Roles';

class FooterLogo extends Component {

    addDefaultSrc = (ev) => {
        ev.target.src = HF.s3StaticPath('img/logo-placeholder.png');
    }

    render() {
        const { brandingInfo } = this.props;
        const brandLogo = brandingInfo.account_resources.logo_file;
        return (
            <React.Fragment>
                {
                    User.hasRole([Roles.TRAVELER, Roles.PHOTO_ADMIN])
                    ?
                        <React.Fragment>
                            <img src={User.customerSlatesLogo() ?User.customerSlatesLogo():User.agencyLogo() } onError={this.addDefaultSrc} className={this.props.className} style={this.props.style} alt="logo" />
                        </React.Fragment>
                    :
                        brandLogo
                        ?
                            <img src={brandLogo.file_path + brandLogo.file_title} onError={this.addDefaultSrc} className={this.props.className} style={this.props.style} alt="logo" />
                        :
                            null
                }
                
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    const { brandingInfo } = state.accountReducer;
    return {
        brandingInfo
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getBrandingInfoById: (id) => { dispatch(getBrandingInfoById(id)) },
        getBrandingInfoBySlug: (slug) => { dispatch(getBrandingInfoBySlug(slug)) }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(FooterLogo)
