import React, { Component } from "react";
import { connect } from 'react-redux';
import { getBrandAppSetting, updateBrandAppSettings, getBrand } from 'redux/actions/brand-actions';
import { Card } from "components/Card/Card.jsx";
import { Grid, Row, Col, Button } from "react-bootstrap";
import { Link } from 'react-router-dom';
import InputColor from 'react-input-color';
var cloneDeep = require('lodash.clonedeep');


class AppSettingPage extends Component {
	constructor(props) {
		super(props);
		const { accountId } = this.props.match.params;
		this.state = {
			accountId: accountId,
			brandAppSettingFields: this.props.brandAppSettingFields,
			submitted: false,
			brand_name: ''
		};

		this.changeAppSettings = this.changeAppSettings.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.props.getBrandAppSettingCall(this.state.accountId);
		this.createInput = this.createInput.bind(this);
	}
	changeAppSettingsColor = (key, k) => (value) => {
		const brandAppSettingFieldsState = cloneDeep(this.state.brandAppSettingFields);
		brandAppSettingFieldsState[key]['settings'][k]['value'] = value.hex;
		this.setState({ brandAppSettingFields: brandAppSettingFieldsState });
	}

	componentDidMount() {
		this.props.getBrandCall(this.state.accountId).then(
			res => {
				this.setState({
					brand_name: res.brand.title
				})
			}
		);
	}


	changeAppSettings(e, key, k) {

		const { value } = e.target;
		const brandAppSettingFieldsState = cloneDeep(this.state.brandAppSettingFields);
		brandAppSettingFieldsState[key]['settings'][k]['value'] = value;
		this.setState({ brandAppSettingFields: brandAppSettingFieldsState }, () => {
			console.log('tetuu b', this.state.brandAppSettingFields[key]['settings'][k]);
		});
	}
	componentDidUpdate(prevProps, prevState) {
		if (prevProps.brandAppSettingFields !== this.props.brandAppSettingFields) {
			this.setState({ brandAppSettingFields: this.props.brandAppSettingFields });
		}
	}
	handleSubmit(e) {
		e.preventDefault();

		this.setState({ submitted: true }, function () {
			const { brandAppSettingFields, accountId } = this.state;
			this.props.updateBrandAppSettingsCall(accountId, { brandAppSettingFields: brandAppSettingFields });
		});
	}
	createInput(data, parentKey, childKey) {
		var classData = '';
		if (data.type === 'color') {
			classData = data.class + ' inputColor';
			if (!data.value)
				classData = classData + ' transparentColor';
			return (
				<React.Fragment>
					<div className='form-group flexElem alignCenter'>
						<div className="colorPickerRound">
							<InputColor
								initialHexColor={data.value}
								onChange={this.changeAppSettingsColor(parentKey, childKey)}
								placement="right"
								className={classData}
								name={data.key}
							/>
							{/*<input type={data.type} name={data.key} className={ classData }  value={data.value}  onChange={(e)=>this.changeAppSettings(e, parentKey, childKey)} />*/}
						</div>
						<label className="ml-sm mb-none" htmlFor="name">{data.label}</label>
					</div>
				</React.Fragment>
			);

		} else if (data.type === 'text') {
			classData = data.class + ' form-control';
			return (
				<React.Fragment>
					<div className='form-group  '>
						<label className="" htmlFor="name">{data.label}</label>
						<input type={data.type} name={data.key} className={classData} value={data.value} onChange={(e) => this.changeAppSettings(e, parentKey, childKey)} />
					</div>
				</React.Fragment>
			);
		}
	}
	render() {
		const { brandAppSettingFields, brand_name } = this.state;
		return (
			<div className="content">
				<Grid fluid>
					<Row>
						<Col md={12}>
							<Card bsClass={["customCard"]}
								content={
									<div>
										<Grid fluid>
											<Row>
												<Col md={12}>
													<div className="secHeading">
														{brand_name} App Settings
													</div>
													<div className="content" >
														<form>
															{
																brandAppSettingFields.map((prop, key) => {
																	return (
																		<Card title={prop.sectionName} bsClass={["innerCard"]} key={key} content={
																			<div className="flexElem flexResponsive flexEnd clearfix">
																				<span></span>
																				{
																					prop.settings.map((p, k) => {
																						return (
																							<Col md={4} key={k}>
																								{
																									this.createInput(p, key, k)
																								}
																							</Col>
																						)
																					})
																				}
																			</div>
																		} />
																	)
																})
															}

															<Row>
																<Col md={12} >
																	<div className='text-center'>
																		<Button onClick={this.handleSubmit} bsSize="large" bsStyle="info" className="backButton pt-sm no_radius pb-sm success btn btn-lg btn-info ml-sm mt-sm btn-default  btn btn-lg btn-info" >Save</Button>
																		<Link to={'/Admin/brands'} className="backButton pt-sm no_radius pb-sm primary btn btn-lg ml-sm mt-sm btn-info ">Back</Link>

																	</div>
																</Col>
															</Row>
														</form>
													</div>
												</Col>
											</Row>
										</Grid>
									</div>
								}
							/>
						</Col>
					</Row>
				</Grid>
			</div>

		)
	}
}

const mapDispatchToProps = dispatch => {
	return ({
		getBrandAppSettingCall: (brandId) => { dispatch(getBrandAppSetting(brandId)) },
		updateBrandAppSettingsCall: (brandId, data) => { dispatch(updateBrandAppSettings(brandId, data)) },
		getBrandCall: (id) => { return dispatch(getBrand(id)) },
	});
};

function mapStateToProps(state) {
	const { brandAppSettingFields } = state.brandsReducer;

	return {
		brandAppSettingFields
	};
}


const AppSetting = connect(mapStateToProps, mapDispatchToProps)(AppSettingPage);
export default AppSetting;