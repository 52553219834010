import React from 'react';
import {
	Button
} from "react-bootstrap";
import { connect } from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';
import { storeDestination, editDestination } from 'redux/actions/destination-actions';
// import { adminLabels } from '../../../redux/constant/admin-label-constant';

class DestinationFormPage extends React.Component {
	constructor(props) {
		super(props);
		this.validatorDestination = new SimpleReactValidator({ autoForceUpdate: this, locale: 'en' });
		this.state = {
			destination: {
				...this.props.destinationPro
			},
			submitted: false,
			formType: this.props.formTypePro || '',
		};

		this.handleDestination = this.handleDestination.bind(this);
		this.handleDestinationSubmit = this.handleDestinationSubmit.bind(this);
	}

	handleDestination(e) {
		const { name, value } = e.target;
		const destinationState = { ...this.state.destination };
		destinationState[name] = value;
		this.setState({ destination: destinationState });
	}
	handleDestinationSubmit(e) {
		e.preventDefault();
		if (this.validatorDestination.allValid()) {
			this.setState({ submitted: true }, function () {
				const { formType } = this.state;
				if (formType === 'add') {
					this.props.storeDestinationCall(this.state.destination).then(
						response => {
							this.props.closeModel();
						}
					);
				}
				else if (formType === 'edit') {
					this.props.editDestinationCall(this.state.destination, this.state.destination.id).then(
						response => {
							this.props.closeModel();
						}
					);
				}
			});
		} else {
			this.setState({ submitted: false });
			this.validatorDestination.showMessages();
			this.forceUpdate();
		}
	}

	render() {
		const { destination } = this.state;
		return (
			<form >
				<div className="row">
					<div className="col-md-12">
						<div className='form-group'>
							<label htmlFor="name">Destination Name<span className="requiredClass">*</span></label>
							<input type="text" name="title" className="form-control" value={destination.title} onChange={this.handleDestination} />
							{this.validatorDestination.message('destinationName', destination.title, 'required')}
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-md-12">
						<div className='text-center'>
							<Button onClick={this.handleDestinationSubmit} bsSize="large" bsStyle="info" className="backButton pt-sm no_radius pb-sm success btn btn-lg btn-info ml-sm mt-sm btn-default  btn btn-lg btn-info" >Save</Button>
							<Button bsSize="large" bsStyle="info" onClick={() => this.props.closeModel()} className="backButton pt-sm no_radius pb-sm primary btn btn-lg ml-sm mt-sm btn-info " >Cancel</Button>
						</div>
					</div>
				</div>
			</form>
		);
	}
}


const mapDispatchToProps = dispatch => {
	return ({
		storeDestinationCall: (destinationData) => { return dispatch(storeDestination(destinationData)) },
		editDestinationCall: (destinationData, id) => { return dispatch(editDestination(destinationData, id)) },
	});
};

function mapStateToProps(state) {
	const { loadingDestination } = state.destinationsReducer;
	return {
		loadingDestination,
	};
};

const DestinationForm = connect(mapStateToProps, mapDispatchToProps)(DestinationFormPage);

export default DestinationForm;