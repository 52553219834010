import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Grid, Row, Col } from 'react-bootstrap'
import Card from '../../Card/Card'
import ReactSelect from 'react-select'
import { getOrderMosaics } from '../../../redux/actions/order-actions'
import { selectChangeHandler, clean, columnFilters, createSortString, getFormattedDate } from '../../../helper/helperFunctions'
import Pagination from '../../ThemeComponents/Pagination'

class MosaicsList extends Component {
    constructor(props) {
        super(props)

        this.state = {
            filtered: '',
            defaultPageSize: 10,
            mosaicType: 'mosaic_style'
        }
        this.selectChangeHandler = selectChangeHandler.bind(this)
        this.clean = clean.bind(this)
        this.table = React.createRef()
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.mosaicType !== prevState.mosaicType) {
            this.pagination.dataCall();
        }
    }


    inputChangeHandler = (e) => {
        this.setState({
            filtered: [e.target.value]
        })
    }

    paginationCall = (data) => {
        let matchFilterWith;
        if (this.pagination) {
            if (this.pagination.reactTable) {
                matchFilterWith = columnFilters(this.pagination.reactTable.state.columns[0].columns)
            }
        }
        let sorted = data.sort.split(',');
        let { sort, orderType } = createSortString(sorted);
        let newData = {
            page: data.page,
            pageSize: data.pageSize,
            sort,
            type: this.state.mosaicType,
            filter: data.filter,
            matchFilterWith,
            orderType
        }
        return this.props.getOrderMosaics(newData);
    }

    render() {
        const { mosaicType } = this.state;
        // const { orderMosaics } = this.props;
        const custom_image_mosaics = 'custom_image_mosaics'
        const columns = [
            {
                Header: 'Program Types',
                headerClassName: 'headerPagination',
                columns: [
                    {
                        Header: 'Order',
                        accessor: 'order.id',
                        className: 'action-center',
                    },
                    {
                        Header: 'Master',
                        accessor: 'item_quantity',
                        className: 'action-center',
                        sortable: false
                    },
                    {
                        Header: '20x30',
                        accessor: 'mosaicsFile',
                        className: 'action-center',
                        Cell: ({ value }) => {
                            return value === false ? 'No' : 'Yes'
                        },
                        sortable: false
                    },
                    {
                        Header: 'Letter',
                        className: 'action-center',
                        Cell: () => {
                            return 'Yes'
                        },
                        sortable: false
                    },
                    {
                        Header: 'Instructions',
                        accessor: 'instructionsFile',
                        className: 'action-center',
                        Cell: ({ value }) => {
                            return value === false ? 'No' : 'Yes'
                        },
                        sortable: false
                    },
                    {
                        Header: 'Videos Produced',
                        accessor: 'order.orderSettings.finalize_by_qa.value',
                        className: 'action-center',
                        Cell: ({ value }) => {
                            if (value) {
                                if (value === '1') {
                                    return 'Yes'
                                } else {
                                    return 'No'
                                }
                            } else {
                                return ''
                            }
                        },
                        id: 'settings.finalize_by_qa.value'
                    },
                    {
                        Header: 'Video Producer',
                        accessor: 'order.producer.name',
                        className: 'action-center',
                        show: mosaicType === custom_image_mosaics,
                        id: 'producer.name'
                    },
                    {
                        Header: 'Finish By Date',
                        accessor: 'order.order_Dates.finish_by_date.value',
                        className: 'action-center',
                        Cell: ({ value }) => {
                            return this.clean(getFormattedDate(value))
                        },
                        id: 'orderDate.finish_by_date.value'
                    },
                    {
                        Header: 'Video Ship Date',
                        accessor: 'order.video_ship_status[0].created_at',
                        className: 'action-center',
                        id: 'order.videoShipStatus.created_at',
                        show: mosaicType === custom_image_mosaics,
                        Cell: ({ value }) => {
                            return getFormattedDate(value)
                        }
                    },
                    {
                        Header: 'Destination',
                        accessor: 'orderDestinations',
                        className: 'action-center',
                        show: mosaicType === custom_image_mosaics,
                    },
                ]
            }
        ]
        const options = [
            {
                label: 'Mosaic Style',
                value: 'mosaic_style'
            },
            {
                label: 'Custom Image Mosaics',
                value: 'custom_image_mosaics'
            },
            {
                label: 'Additional Mosaics',
                value: 'additional_mosaics'
            }
        ]
        return (
            <div className='content'>
                <Grid fluid>
                    <Row>
                        <Col md={12}>
                            <Card content={
                                <React.Fragment>
                                    <div className="secHeading">Mosaics List</div>
                                    <div className="flexElem flexResponsive mb-md" style={{ "justifyContent": 'space-between' }}>
                                        <div style={{ width: '20%' }}>
                                            <ReactSelect
                                                value={options.find(item => {
                                                    return item.value === mosaicType
                                                })}
                                                onChange={this.selectChangeHandler}
                                                options={options}
                                                placeholder="Filter Mosaics"
                                                isMulti={false}
                                                name='mosaicType'
                                            />
                                        </div>
                                    </div>
                                    {/* <ReactTable
                                        columns={columns}
                                        manual
                                        ref={this.table}
                                        filtered={this.state.filtered}
                                        defaultPageSize={this.state.defaultPageSize}
                                        pages={orderMosaics.last_page}
                                        data={orderMosaics.data}
                                        onFetchData={(state) => {
                                            let matchFilterWith = [];
                                            state.columns[0].columns.forEach(column => {
                                                if (column.id !== undefined || column.accessor !== undefined) {
                                                    if (column.id) {
                                                        matchFilterWith.push(column.id)
                                                    } else {
                                                        matchFilterWith.push(column.accessor)
                                                    }
                                                }
                                            })
                                            let sort = "", orderType = 'asc', filter = state.filtered;
                                            if (state.sorted.length > 0) {
                                                sort = state.sorted[0].id;
                                                orderType = state.sorted[0].desc;
                                                if (orderType === true) {
                                                    orderType = 'desc'
                                                } else {
                                                    orderType = 'asc'
                                                }
                                            }
                                            const data = {
                                                page: state.page + 1,
                                                pageSize: state.pageSize,
                                                sort,
                                                type: mosaicType,
                                                filter,
                                                matchFilterWith,
                                                orderType
                                            }
                                            this.props.getOrderMosaics(data)
                                        }}
                                    /> */}
                                    <div className='row'>
                                        <Pagination
                                            ref={(p) => this.pagination = p}
                                            showPagination={false}
                                            columns={columns}
                                            pageSize={10}
                                            getDataCall={this.paginationCall}
                                            filterView={false}
                                            noDataText='No Mosaics Found'
                                            // downloadData={true}
                                            // downloadFileName={'Orders'}
                                            // lowerContent = {null}
                                            // filterPlaceHolder = {'Orders'}
                                            getRowProps={this.getRowProps}
                                        // showAllToggle={true}
                                        />
                                    </div>
                                </React.Fragment>
                            } />
                        </Col>
                    </Row>
                </Grid>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { orderMosaics } = state.ordersReducer
    return {
        orderMosaics
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getOrderMosaics: (data = null) => { return dispatch(getOrderMosaics(data)) }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(MosaicsList)
