import React, { Component } from "react";
import { connect } from "react-redux";
import { s3StaticPath } from "helper/helperFunctions";

class VideoTitles extends Component {
    constructor(props) {
        super(props);

        this.state = {
            production_details: this.props.production_details
        };
    }

    titlesChangeHandler = e => {
        const value = e.target.value;
        const container = e.target.name;
        this.setState(prevState => ({
            production_details: {
                ...prevState.production_details,
                video_title_templates: {
                    ...prevState.production_details.video_title_templates,
                    [container]: {
                        ...prevState.production_details.video_title_templates[
                            container
                        ],
                        pivot: {
                            ...prevState.production_details
                                .video_title_templates[container].pivot,
                            value: value
                        }
                    }
                }
            }
        }));
    };

    componentDidUpdate(prevProps, prevState) {
        if (this.props.production_details !== prevProps.production_details) {
            this.setState({
                production_details: this.props.production_details
            });
        }
        if (this.state.production_details !== prevState.production_details) {
            this.returnState();
        }
    }

    returnState = () => {
        this.props.returnState(
            this.state.production_details,
            "production_details"
        );
    };

    render() {
        const { production_details } = this.state;
        const { /*received_date*/ submited_for_production } = this.props;

        // console.log("submited_for_production", received_date);

        return (
            <div className="form_tile">
                <div className="form_tile_heading">
                    <img
                        src={s3StaticPath("img/video_titles.png")}
                        alt={
                            this.props.panelTitle
                                ? this.props.panelTitle
                                : "Video Titles"
                        }
                    />
                    <span>
                        {this.props.panelTitle
                            ? this.props.panelTitle
                            : "Video Titles"}
                    </span>
                </div>
                <div className="form_tile_content">
                    <div className="flexElem respFlex spaceBetween form_tile_row">
                        <div className="col-md-4">Opening Title</div>
                        <div className="col-md-8 text-primary">
                            <textarea
                                onChange={this.titlesChangeHandler}
                                disabled={submited_for_production ? true : false}
                                name="ot1"
                                value={
                                    production_details.video_title_templates.ot1
                                        .pivot.value
                                }
                                placeholder="Opening Title"
                                className="form_tile_input tile_textarea block"
                            />
                            {this.props.validatorInstance.message(
                                "Opening Title",
                                production_details.video_title_templates.ot1
                                    .pivot.value,
                                "required"
                            )}
                        </div>
                    </div>
                    <div className="flexElem respFlex spaceBetween form_tile_row">
                        <div className="col-md-4">Closing #1</div>
                        <div className="col-md-8 text-primary">
                            <textarea
                                onChange={this.titlesChangeHandler}
                                disabled={submited_for_production ? true : false}
                                name="ct1"
                                value={
                                    production_details.video_title_templates.ct1
                                        .pivot.value
                                }
                                placeholder="Closing Title #1"
                                className="form_tile_input tile_textarea block"
                            />
                            {this.props.validatorInstance.message(
                                "Closing Title #1",
                                production_details.video_title_templates.ct1
                                    .pivot.value,
                                "required"
                            )}
                        </div>
                    </div>
                    <div className="flexElem respFlex spaceBetween form_tile_row">
                        <div className="col-md-4">Closing #2</div>
                        <div className="col-md-8 text-primary">
                            <textarea
                                onChange={this.titlesChangeHandler}
                                disabled={submited_for_production ? true : false}
                                name="ct2"
                                value={
                                    production_details.video_title_templates.ct2
                                        .pivot.value
                                }
                                placeholder="Closing Title #2"
                                className="form_tile_input tile_textarea block"
                            />
                            {this.props.validatorInstance.message(
                                "Closing Title #2",
                                production_details.video_title_templates.ct2
                                    .pivot.value,
                                "required"
                            )}
                        </div>
                    </div>
                    <div className="flexElem respFlex spaceBetween form_tile_row">
                        <div className="col-md-4">Closing #3</div>
                        <div className="col-md-8 text-primary">
                            <textarea
                                onChange={this.titlesChangeHandler}
                                disabled={submited_for_production ? true : false}
                                name="ct3"
                                value={
                                    production_details.video_title_templates.ct3
                                        .pivot.value
                                }
                                placeholder="Closing Title #3"
                                className="form_tile_input tile_textarea block"
                            />
                            {this.props.validatorInstance.message(
                                "Closing Title #3",
                                production_details.video_title_templates.ct3
                                    .pivot.value,
                                "required"
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(VideoTitles);
