import { SC } from '../helper/ServerCall';

export const permissionService = {
    getAllPermissions,
    getPermissionsPagination,
    storePermission,
    getPermission,
    editPermission,
    deletePermission,
    getAllParentLabel,
    getAlreadyExistingOrders,
}

function getAllPermissions() {
    return SC.getCall('getAllPermissions');
}
function getAllParentLabel() {
    return SC.getCall('getAllParentLabels');
}
function getPermissionsPagination(page, filter, sort, pageSize) {
    return SC.getCall('permission?page=' + page + '&filter=' + filter + '&sort=' + sort + '&pageSize=' + pageSize);
}
function storePermission(permissionData) {
    return SC.postCall('permission', permissionData);
}
function getPermission(permissionId) {
    return SC.getCall('permission/' + permissionId);
}
function editPermission(permissionData, permissionId) {
    return SC.putCall('permission/' + permissionId, permissionData);
}
function deletePermission(permissionId) {

    return SC.deleteCall('permission/' + permissionId);
}
function getAlreadyExistingOrders() {
    return SC.getCall('getAlreadyExistingOrders');
}