import React, { Component } from 'react';
import $ from 'jquery';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import { IoMdPlay } from 'react-icons/io';
import { s3StaticPath } from 'helper/helperFunctions';
// import MainClients from './MainClients';

class TourOperatorBranding extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isHovered: false,
    };
    this.handleHover = this.handleHover.bind(this);
  }
  handleHover() {
    // this.setState(prevState => ({
    //     isHovered: !prevState.isHovered
    // }));

    $("#clients div.client").hover(function () {
      $("#clients div.client").removeClass("pink_gradient")
      $(this).addClass("pink_gradient")
    }, function () {
      $("#clients div.client").removeClass("pink_gradient")
    })
  }
  render() {
    // const hoverClass = this.state.isHovered ? "client pink_gradient" : "client";
    // let sliderSettings = {
    //   dots: true,
    //   autoplay: true,
    //   infinite: true,
    //   speed: 500,
    //   slidesToShow: 1,
    //   slidesToScroll: 1,
    //   accessibility: false,
    //   className: 'benefits',
    //   prevArrow: <div><MdArrowBack /></div>,
    //   nextArrow: <div><MdArrowForward /></div>,
    // };

    return (
      <React.Fragment>

        <section id="tourOperator" className="section">
          <h2 className="panel_heading">Tour Operator Branding</h2>

          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="text_22 text-center mt-lg text-muted dark">
                  In every aspect of the Group Memories&trade; program, <strong>Your Logo will be featured!</strong>
                </div>
              </div>
            </div>
          </div>
        </section>


        <section className="section brandingSections">
          <div className="container-fluid">
            <div className="student_heading text-center section_heading" style={{ color: '#3b9ae3' }}>
              Included Branding
            </div>

            <div className="row flexElem flexResponsive alignCenter">
              <div className="col-md-5 col-md-offset-1">
                <p className="text_24">Your Logo appears on the 1st page of the information packet that we mail or email to the group leader before departure.</p>
              </div>

              <div className="col-md-6">
                <img className="frontImg" src={s3StaticPath('img/operator/to_branding_info_packet.png')} alt="frontImg" />
                <img className="bgColor" src={s3StaticPath('img/operator/blue_bg.png')} alt="Background Color" />
              </div>
            </div>
          </div>
        </section>

        <section className="section brandingSections">
          <div className="container-fluid">
            <div className="row flexElem flexResponsive alignCenter">
              <div className="col-md-5 col-md-offset-1">
                <p className="text_24">Your Logo appears on the app cards sent with information packet. We send an app card for every traveler.</p>
              </div>

              <div className="col-md-6">
                <img className="frontImg" src={s3StaticPath('img/operator/to_branding_app_card.png')} alt="frontImg" />
                <img className="bgColor" src={s3StaticPath('img/operator/purple_bg.png')} alt="Background Color" />
              </div>
            </div>
          </div>
        </section>

        <section className="section brandingSections">
          <div className="container-fluid">
            <div className="row flexElem flexResponsive alignCenter">
              <div className="col-md-5 col-md-offset-1">
                <p className="text_24">Your Logo appears when travelers login to view photos on the Group Memories Website.</p>
              </div>

              <div className="col-md-6">
                <img className="frontImg" src={s3StaticPath('img/operator/to_branding_photo_gallery.png')} alt="frontImg" />
                <img className="bgColor" src={s3StaticPath('img/operator/blue_bg.png')} alt="Background Color" />
              </div>
            </div>
          </div>
        </section>

        <section className="section brandingSections">
          <div className="container-fluid">
            <div className="row flexElem flexResponsive alignCenter">
              <div className="col-md-5 col-md-offset-1">
                <p className="text_24">Your logo appears at the top of the screen when travelers login to the Group Memories App.</p>
              </div>

              <div className="col-md-6">
                <img className="frontImg" src={s3StaticPath('img/operator/to_branding_app.png')} alt="frontImg" />
                <img className="bgColor" src={s3StaticPath('img/operator/purple_bg.png')} alt="Background Color" />
              </div>
            </div>
          </div>
        </section>

        <section className="section brandingSections">
          <div className="container-fluid">
            <div className="row flexElem flexResponsive alignCenter">
              <div className="col-md-5 col-md-offset-1">
                <p className="text_24">Your logo appears at the beginning of every video.</p>
              </div>

              <div className="col-md-6">
                <img className="frontImg" src={s3StaticPath('img/operator/to_branding_dvd_beginning.png')} alt="frontImg" />
                <img className="bgColor" src={s3StaticPath('img/operator/blue_bg.png')} alt="Background Color" />
              </div>
            </div>
          </div>
        </section>

        <section className="section brandingSections">
          <div className="container-fluid">
            <div className="row flexElem flexResponsive alignCenter">
              <div className="col-md-5 col-md-offset-1">
                <p className="text_24">Your logo and contact info appears at the end of every video.</p>
              </div>

              <div className="col-md-6">
                <img className="frontImg" src={s3StaticPath('img/operator/to_branding_DVD_End.png')} alt="frontImg" />
                <img className="bgColor" src={s3StaticPath('img/operator/purple_bg.png')} alt="Background Color" />
              </div>
            </div>
          </div>
        </section>

        <section className="section brandingSections">
          <div className="student_heading text-center mt-xxlg section_heading">
            Additional Branding Available With our <strong>Partner Program</strong>
          </div>

          <div className="container-fluid">
            <div className="row flexElem flexResponsive alignCenter">
              <div className="col-md-5 col-md-offset-1">
                <p className="text_24">Your logo appears as a watermark throughout every video.</p>
              </div>

              <div className="col-md-6">
                <img className="frontImg" src={s3StaticPath('img/operator/to_branding_watermark.png')} alt="frontImg" />
                <img className="bgColor" src={s3StaticPath('img/operator/blue_bg.png')} alt="Background Color" />
              </div>
            </div>
          </div>
        </section>

        <section className="section brandingSections">
          <div className="container-fluid">
            <div className="row flexElem flexResponsive alignCenter">
              <div className="col-md-5 col-md-offset-1">
                <p className="text_24">Your Logo and website will appear at the beginning and end of the video to direct travelers to your website to login and view their photos.</p>
              </div>

              <div className="col-md-6">
                <img className="frontImg" src={s3StaticPath('img/operator/to_branding_pw_slate.png')} alt="frontImg" />
                <img className="bgColor" src={s3StaticPath('img/operator/purple_bg.png')} alt="Background Color" />
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default TourOperatorBranding;
