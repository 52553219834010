export const uploaderErrors = function (orderUploadInfo, errorMessage, showUploader, photos_submitted_for_video_production, uploadDateHandler, Status, Roles, Link, User, React, REACT_APP_GM_URL) {
    if ('allow_online_reorder' in orderUploadInfo && 'cleared_date' in orderUploadInfo) {
        if (Number(orderUploadInfo.allow_online_reorder.value) === 1) {
            errorMessage = <React.Fragment>
                <p>Sorry, Photos have been removed</p>
                <p>The photos for <strong>{orderUploadInfo.group_name}</strong> tour to <strong>{orderUploadInfo.destinations}</strong> have been removed from our system</p>
                <br />
                <p>Do you need a CD of Pictures, a copy of your Keepsake DVD Video, or a PhotoMosaic? If so please, <Link to='/keep_sake' className='blue_link'>Click Here to Reorder Keepsakes</Link></p>
            </React.Fragment>
        } else {
            errorMessage = <React.Fragment>
                <p>Sorry, Photos have been removed</p>
                <p>The photos for <strong>{orderUploadInfo.group_name}</strong> tour to <strong>{orderUploadInfo.destinations}</strong> have been removed from our system</p>
                <br />
                <p>Photos were removed because it has been more than 6 months from your trip date. To see if a CD of Pictures, a copy of your Keepsake DVD Video, or a PhotoMosaic, is still available, please <a href={REACT_APP_GM_URL+'contact'} className='blue_link'>Contact Us</a>. If they are available, we will enable the reorder feature on this website.</p>
            </React.Fragment>
        }
    }
    else if (Number(orderUploadInfo.approved_for_upload.value) === 0) {
        errorMessage = <p>Photos Unavailable <br />
            The photos for {orderUploadInfo.group_name} Visit`s tour to {orderUploadInfo.destinations} have not been edited and made available for online viewing. Please check back later.</p>
    // } else if (orderUploadInfo.cameraShipStatus === null && Number(orderUploadInfo.finalize_by_qa.value) !== 1) {
    //     errorMessage = <strong>Wait for the camera to be shipped</strong>
    }
    else if (Number(photos_submitted_for_video_production.value) === 1 && Number(orderUploadInfo.finalize_by_qa.value) !== 1) {
        errorMessage = <strong>Uploading has closed because photos have been submitted for video production. Please check back later to see your edited photos and finished video.</strong>;
    }
    else if (Number(orderUploadInfo.approved_for_upload.value) === 0 || Number(orderUploadInfo.finalize_by_qa.value) === 1) {
        showUploader = false;
        // errorMessage = <strong>You are not approved to upload photos</strong>
    } else if (Number(orderUploadInfo.disable_traveler_for_upload.value) === 1 && User.hasRole(Roles.TRAVELER)) {
        errorMessage = <React.Fragment>
            <strong>Sorry, only the photo administrator has permission to upload photos.</strong>
            {/* <br /><br /> */}
            {/* <a href="gallery_new.php?Barcode='.$session.'" class="btn btn-success fileinput-button"><font color="#FFFFFF">View Uploaded Photos</font></a> */}
        </React.Fragment>
    } else if (orderUploadInfo.upload_date_passed) {
        errorMessage = <strong>
            Sorry, you have reached your Upload Cut Off Date and uploading has closed. We will soon submit your photos for video production.
            Please check back later to see your edited photos and finished video.
            {
                (User.hasRole(Roles.PHOTO_ADMIN) && Number(photos_submitted_for_video_production) === 0) ?
                    <div className='mt-md flexElem respFlex'>
                        <div><button onClick={() => { uploadDateHandler(2) }} className='themeBtn_14 blue_theme_button hover_dark mr-md'>Increase Date By 2 Days</button></div>
                        {/* <div><button onClick={() => { uploadDateHandler(5) }} className='themeBtn_14 blue_theme_button hover_dark ml-md mr-md'>Increase Date By 5 Days</button></div> */}
                        <div><button onClick={() => { uploadDateHandler(7) }} className='themeBtn_14 blue_theme_button hover_dark'>Increase Date By 7 Days</button></div>
                    </div>
                    :
                    <div>
                        <p>To receive an uploading time extension <a href={REACT_APP_GM_URL+'contact'}>Contact us</a> or login as your group's Photo Administrator</p>
                    </div>
            }
        </strong>
    }
    else if (orderUploadInfo.photos_limit_reached) {
        errorMessage = <strong>Maximum number of photos reached</strong>
    }
    else {
        showUploader = true;
    }
    return { showUploader, errorMessage }
}
