import { SC } from '../helper/ServerCall';

export const frontEndUserService = {
    login,
    logout,
    getAdudicators,
    getAdudicatorsRecordings,
    forgetUsername,
    getMessage,
    sendPasswordResetLink,
    resetPassword,
    sendContactEmail
}

function login(email, password, login_type = 'web') {
    return SC.postCall('user/gmlogin', { email: email, password });
}

function forgetUsername(email, request_type = 'web') {
    return SC.postCall('forgetMusicDirector', { email });
}

function logout() {
    return SC.postCall('logout', []);
}

function getAdudicators(musicDirectorData) {

    var formData = new FormData();
    formData.append('musicDirector_id', musicDirectorData.musicDirector_id);
    formData.append('username', musicDirectorData.username);
    formData.append('request_type', musicDirectorData.request_type);

    return SC.postCall('getAdjudicators', formData);
}

function getAdudicatorsRecordings(adjudicatorObject, page) {

    var formData = new FormData();
    formData.append('musicDirector_id', adjudicatorObject.musicDirector_id);
    formData.append('adjudicator_id', adjudicatorObject.adjudicator_id);
    formData.append('request_type', adjudicatorObject.request_type);

    return SC.postCall('getAdjudicatorCommentsWeb?page=' + page, formData);
}

function getMessage(data) {
    return SC.postCallWithoutAuth('getMessage', data);
}

function sendPasswordResetLink(username) {
    return SC.postCallWithoutAuth('sendPasswordResetLink', { username });
}
function resetPassword(data) {
    return SC.postCallWithoutAuth('resetPassword', data);
}
function sendContactEmail(data) {
    return SC.postCallWithoutAuth('sendContactEmail', data);
}