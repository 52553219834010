import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Grid, Row, Col } from 'react-bootstrap'
import Card from '../../Card/Card'
import { getAgencyTypePagination, deleteAgencyType } from '../../../redux/actions/agencyType-action';
import { confirmAlert } from 'react-confirm-alert';
import ConfirmAlertForDelete from '../../ThemeComponents/confirmAlertForDelete';
import ThemeModal from '../../ThemeComponents/ThemeModal';
import { helperFunctions as HF } from '../../../helper/helperFunctions'
import AgencyTypeForm from './AgencyTypeForm';
import Pagination from '../../ThemeComponents/Pagination'

class AgencyTypeList extends Component {

    constructor(props) {
        super(props)

        this.state = {
            filtered: '',
            pageSize: 10,
            AgencyTypeEditModal: false,
            AgencyTypeCreateModal: false,
            agencyTypeEditData: {
                id: '',
                index: ''
            }
        }
        this.confirmDeleteHandler = this.confirmDeleteHandler.bind(this);
        this.showModal = this.showModal.bind(this);
        this.deleteAgencyTypeHandler = this.deleteAgencyTypeHandler.bind(this);
        this.inputChangeHandler = this.inputChangeHandler.bind(this);
    }

    showModal(e, id = '', index = '') {
        this.setState({
            [e.target.name]: true,
            agencyTypeEditData: {
                id: id,
                index: index
            }
        });
    }

    inputChangeHandler(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    deleteAgencyTypeHandler(id, index) {
        this.props.deleteAgencyTypeCall(id).then(res => {
            console.log(this.pagination);
            this.pagination.dataCall();
        });
    }

    confirmDeleteHandler(id, index) {
        confirmAlert({
            customUI: ({ onClose }) => <ConfirmAlertForDelete typeName='Agency Type' onClosePro={onClose} deleteType={() => this.deleteAgencyTypeHandler(id, index)} />
        })
    }

    paginationCall = (data) => {
        return this.props.getAgencyTypesPaginationCall(data.page, data.filter, data.sort, data.pageSize);
    }


    render() {
        const columns = [
            {
                Header: 'Agency Types',
                headerClassName: 'headerPagination',
                columns: [
                    {
                        Header: 'Title',
                        accessor: 'title',
                        className: 'action-center'
                    },
                    {
                        Header: 'Code',
                        accessor: 'code',
                        className: 'action-center'
                    },
                    {
                        Header: 'Actions',
                        className: 'action-center',
                        sortable: false,
                        Cell: ({ original, index }) => {
                            return (
                                <React.Fragment>
                                    <button name='AgencyTypeEditModal' onClick={(e) => { this.showModal(e, original.id, index) }} type='button' className="orderDetailsAction editIcon" >&nbsp;</button>
                                    <button onClick={() => this.confirmDeleteHandler(original.id, index)} type='button' className="orderDetailsAction ml-md deleteIcon" >&nbsp;</button>
                                </React.Fragment>
                            )
                        }
                    }
                ]
            }
        ]
        // const { agencyTypePagination } = this.props;
        return (
            <div className='content' >
                <Grid fluid>
                    <Row>
                        <Col md={12}>
                            <Card content={
                                <React.Fragment>
                                    <div className="secHeading">Agency Types List</div>
                                    <div className='flexElem' style={{ justifyContent: 'space-between' }}>
                                        <button onClick={(e) => this.showModal(e)} name='AgencyTypeCreateModal' className='backButton my-20 pt-sm no_radius pb-sm primary text-xs btn btn-lg btn-info btn btn-lg btn-info  btn btn-default' ><i className="fa fa-plus" />Create Agency Type</button>
                                        {/* <div className='col-md-4 my-20' >
                                            <input onChange={this.inputChangeHandler} name='filtered' type='text' className='form-control' placeholder='Search...' />
                                        </div> */}
                                    </div>
                                    {/* <ReactTable
                                        manual
                                        filtered={this.state.filtered}
                                        columns={columns}
                                        pages={agencyTypePagination.last_page}
                                        data={agencyTypePagination.data}
                                        defaultPageSize={this.state.pageSize}
                                        onFetchData={(state) => {
                                            var sort = state.sorted.length === 0 ? '' : state.sorted[0].id + ',desc:' + state.sorted[0].desc;
                                            this.props.getAgencyTypesPaginationCall(state.page + 1, state.filtered, sort, state.pageSize);
                                        }}
                                    /> */}
                                    <div className='row'>
                                        <Pagination
                                            ref={(p) => this.pagination = p}
                                            showPagination={false}
                                            columns={columns}
                                            pageSize={10}
                                            filterView={true}
                                            filterPlaceHolder={'Agency Type'}
                                            getDataCall={this.paginationCall}
                                            noDataText='No Agency Type'
                                            // downloadData={true}
                                            // downloadFileName={'Orders'}
                                            // lowerContent = {null}
                                            // filterPlaceHolder = {'Orders'}
                                            getRowProps={this.getRowProps}
                                        // showAllToggle={true}
                                        />
                                    </div>
                                </React.Fragment>
                            } />
                        </Col>
                    </Row>
                </Grid>
                <ThemeModal size={'lg'} title='Edit Agency Type' show={this.state.AgencyTypeEditModal} hide={HF.closeModal.bind(this, 'AgencyTypeEditModal')} >
                    <AgencyTypeForm getPagination={this.pagination ? this.pagination.dataCall : null} hide={HF.closeModal.bind(this, 'AgencyTypeEditModal')} formType='edit' data={this.state.agencyTypeEditData} />
                </ThemeModal>
                <ThemeModal size={'lg'} title='Create Agency Type' show={this.state.AgencyTypeCreateModal} hide={HF.closeModal.bind(this, 'AgencyTypeCreateModal')} >
                    <AgencyTypeForm getPagination={this.pagination ? this.pagination.dataCall : null} hide={HF.closeModal.bind(this, 'AgencyTypeCreateModal')} formType='add' />
                </ThemeModal>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { agencyTypePagination } = state.agencyTypeReducer;
    return {
        agencyTypePagination
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getAgencyTypesPaginationCall: (page, filter, sort, pageSize) => { return dispatch(getAgencyTypePagination(page, filter, sort, pageSize)) },
        deleteAgencyTypeCall: (id, data) => { return dispatch(deleteAgencyType(id, data)) }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AgencyTypeList)
