import React, { Component } from "react";
import {
  Button,
  Collapse
} from "react-bootstrap";
import { s3StaticPath } from 'helper/helperFunctions'

export class Card extends Component {


  render() {
    return (
      <div className={"card" + (this.props.plain ? " card-plain" : "") + " " + (this.props.bsClass ? this.props.bsClass.join(' ') : "")}>
        {
          this.props.collapsable ?

            <React.Fragment>
              {
                ((this.props.title && this.props.title !== '') || (this.props.category && this.props.category !== '')) ?
                  <div className={"header" + (this.props.hCenter ? " text-center" : "")}>
                    <h4 className={"title headerTitle " + (this.props.collapseTrigger ? "" : "collapsClosed")}>{this.props.title}
                      <Button
                        className="arrow_toggle"
                        onClick={this.props.collapsHandler}
                        aria-controls="example-collapse-text"
                        aria-expanded={this.props.collapseTrigger}
                      >
                        {this.props.collapseTrigger ? <img src={s3StaticPath('img/arrow_down.png')} alt="Arrow" className="arrow_down" /> : <img src={s3StaticPath('img/arrow_down.png')} alt="Arrow" className="arrow_up" />}
                      </Button>
                    </h4>
                    <p className="category">{this.props.category}</p>
                  </div>
                  :
                  <div className={"header" + (this.props.hCenter ? " text-center" : "")}>
                    <h4 className={"title headerTitle " + (this.props.collapseTrigger ? "" : "collapsClosed")}>
                    &nbsp;
                      <Button
                        className="arrow_toggle"
                        onClick={this.props.collapsHandler}
                        aria-controls="example-collapse-text"
                        aria-expanded={this.props.collapseTrigger}
                      >
                        {this.props.collapseTrigger ? <img src={s3StaticPath('img/arrow_down.png')} alt="Arrow" className="arrow_down" /> : <img src={s3StaticPath('img/arrow_down.png')} alt="Arrow" className="arrow_up" />}
                      </Button>
                    </h4>
                    <p className="category">{this.props.category}</p>
                  </div>
              }

              <Collapse in={this.props.collapseTrigger}>
                <div
                  className={
                    "content" +
                    (this.props.ctAllIcons ? " all-icons" : "") +
                    (this.props.ctTableFullWidth ? " table-full-width" : "") +
                    (this.props.ctTableResponsive ? " table-responsive" : "") +
                    (this.props.ctTableUpgrade ? " table-upgrade" : "")
                  }
                >
                  {this.props.content}

                  <div className="footer">
                    {this.props.legend}
                    {this.props.stats != null ? <hr /> : ""}
                    <div className="stats">
                      <i className={this.props.statsIcon} /> {this.props.stats}
                    </div>
                  </div>
                </div>
              </Collapse>
            </React.Fragment>
            :
            <React.Fragment>
              {
                ((this.props.title && this.props.title !== '') || (this.props.category && this.props.category !== '')) &&
                <div className={"header" + (this.props.hCenter ? " text-center" : "")}>
                  <h4 className="title headerTitle">{this.props.title}</h4>
                  <p className="category">{this.props.category}</p>
                </div>
              }

              <div
                className={
                  "content" +
                  (this.props.ctAllIcons ? " all-icons" : "") +
                  (this.props.ctTableFullWidth ? " table-full-width" : "") +
                  (this.props.ctTableResponsive ? " table-responsive" : "") +
                  (this.props.ctTableUpgrade ? " table-upgrade" : "")
                }
              >
                {this.props.content}

                <div className="footer">
                  {this.props.legend}
                  {this.props.stats != null ? <hr /> : ""}
                  <div className="stats">
                    <i className={this.props.statsIcon} /> {this.props.stats}
                  </div>
                </div>
              </div>
            </React.Fragment>
        }
      </div>
    );
  }
}

export default Card;
