export const themeCSSConstants = {
	THUMBICONSTYLE: {
    activeThumb: {
      base: '#363C77',
    },
    inactiveThumb: {
      base: 'rgb(255, 255, 255)',
    },
    active: {
      base: 'rgb(126, 153, 200)',
      hover: 'rgb(177, 191, 215)',
    },
    inactive: {
      base: 'rgb(177, 191, 215)',
      hover: 'rgb(177, 191, 215)',
    }
  },
  DISABLECOLOR : {
    active: {
      base: 'rgb(211,211,211)',
      hover: 'rgb(95,96,98)',
    },
    inactive: {
      base: 'rgb(211,211,211)',
      hover: 'rgb(95,96,98)',
    }
  },
  THUMBSTYLE:{
  	width:"15px", 
  	height:"15px", 
  	display:"flex", 
  	alignSelf:" center",
  	boxShadow:"rgba(0, 0, 0, 0.12) 0px 0px 2px, rgba(0, 0, 0, 0.24) 0px 2px 4px", 
  	borderRadius:" 50%", 
  	boxSizing:" borderBox", 
  	position:" relative", 
  	backgroundColor:" rgb(62, 130, 247)", left:" -10px"
  },
}