import React from "react";
import {s3StaticPath} from 'helper/helperFunctions';
import {connect} from 'react-redux';
import {
    Button
} from "react-bootstrap";
import {alertActions} from 'redux/actions/alert-actions';
import {logout} from "../../redux/actions/admin-user-action";
import {withRouter} from "react-router-dom";


// import { history } from 'helper/history';
class ErrorBoundaryPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: false,
            message: null,
            errorInfo: null,
            errComponentStack: null
        };
        this.jumpToDashboard = this.jumpToDashboard.bind(this);
        this.forceLogout = this.forceLogout.bind(this);
    }

    jumpToDashboard() {
        // console.log(this.state.error);
        this.setState({error: false, message: ""}, function () {
            //   localStorage.removeItem('user');
            //   history.push('/Admin-login');
            //   window.location.assign("/Admin-login");
            window.location.assign("/Admin/Dashboard");
            //history.push('/Admin/dashboard');
        });
    }

    forceLogout() {
        // console.log(this.state.error);
        this.setState({error: false, message: ""}, function () {
              localStorage.removeItem('user');
            //   history.push('/Admin-login');
            //   history.go();
              window.location.assign("/Admin-login");
              window.location.reload();
        });
    }

    componentDidCatch(err, errInfo) {
        console.log('error 123', err);
        console.log('error information', errInfo);
        console.log('error information', errInfo.componentStack.toString());
        this.setState({
            error: true,
            message: err,
            errInfo: errInfo,
            errComponentStack: errInfo.componentStack.toString(),
        }, () => {
            // console.log('message', this.state);
            this.props.errorNotifyCall({error: {error:this.state.message, errComponentStack: this.state.errComponentStack, errInfo: this.state.errInfo}});
        });
    }

//   componentDidUpdate(prevProps, prevState, snapshot) {
//     if(this.props.history.location.pathname!== prevProps.history.location.pathname){
//         this.setState({
//         error: false
//         })
//     }
// }

    render() {
        if (this.state.error) {
            return (
                <div className="content">
                    <div>
                        <img
                            src={s3StaticPath('img/error.jpg')}
                            style={{height: '100%', width: '100%'}}
                            alt="error handler"
                        />
                    </div>
                    <div className="text-center">
                        <span style={{textAlign: "center", fontSize: 16, padding: 10}}>
                            Something Went Wrong. Try Again Later
                        </span>
                        <Button type='button' className="outOfTableButton customButton btn btn-info" onClick={this.jumpToDashboard} >Dashboard</Button>
                        &nbsp;&nbsp;&nbsp;
                        <Button type='button' className="outOfTableButton customButton btn btn-info" onClick={this.forceLogout}>Logout</Button>
                        {/* <Button type='button' className="outOfTableButton customButton btn btn-info" onClick={() => { this.props.onLogoutClick() }}>Logout</Button> */}
                    </div>
                    {
                        /*
                        <details style={{ textAlign: "center", fontSize: 8, padding: 10 }}>
                            {this.state.message}
                        </details>
                        */
                    }
                </div>
            );
        } else {
            return this.props.children;
        }
    }
}

// const styles = {
//   errorContainer: {
//     display: "flex",
//     justifyContent: "center",
//     alignItems: "center"
//   }
// };


function mapDispatchToProps(dispatch) {
    return dispatch => {
        return {
            errorNotifyCall: (error) => {
                return dispatch(alertActions.errorNotify(error))
            },
            // refreshPage: () => { window.location.reload(); },
            onLogoutClick: () => {
                dispatch(logout());
                // dispatch(this.refreshPage());

                // history.push('/Admin-login');
                // history.go();
                // this.setState({error: false, message: ""}, function () {
                //     //   localStorage.removeItem('user');
                //     //   history.push('/Admin-login');
                //     //   window.location.assign("/Admin-login");
                //     window.location.assign("/Admin/Dashboard");
                //     //history.push('/Admin/dashboard');
                // });
            }
        }
    }
}

// const mapDispatchToProps = dispatch => {
//   return ({
//     errorNotifyCall: (error) => { return dispatch(alertActions.errorNotify(error)) },
//     onLogoutClick: () => { dispatch(logout()); refreshPage(); },
//     refreshPage = () => { window.location.reload(); }
//   });
// };

function mapStateToProps(state) {

    return {};
};

const ErrorBoundary = connect(mapStateToProps, mapDispatchToProps)(ErrorBoundaryPage);
export default withRouter(ErrorBoundary);
