import { adminConstants } from '../constant/admin-constant';
import { invoicingService } from '../../services/invoicing';
import { alertActions } from './alert-actions';

export function getInvoicingOrders(value, fromDate, toDate, use_global_buffer, page, pageSize, sorted) {
    return dispatch => {
        dispatch(request())
        return invoicingService.getInvoicingOrders(value, fromDate, toDate, use_global_buffer, page, pageSize, sorted).then(
            response => {
                dispatch(success(response));
                return response;
            }).catch(error => {
                dispatch(failure(error))
                Promise.reject(error)
            })
    }
    function request() { return { type: adminConstants.GET_INVOICING_ORDERS_REQUEST } }
    function success(response) { return { type: adminConstants.GET_INVOICING_ORDERS_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.GET_INVOICING_ORDERS_FAILURE, payload: error } }
}

export function getRecentlyInvoicedOrders(value, fromDate, toDate, page, pageSize, sorted) {
    return dispatch => {
        dispatch(request())
        return invoicingService.getRecentlyInvoicedOrders(value, fromDate, toDate, page, pageSize, sorted).then(
            response => {
                dispatch(success(response));
                return response;
            }).catch(error => {
                dispatch(failure(error))
                Promise.reject(error)
            })
    }
    function request() { return { type: adminConstants.GET_RECENTLY_INVOICED_ORDERS_REQUEST } }
    function success(response) { return { type: adminConstants.GET_RECENTLY_INVOICED_ORDERS_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.GET_RECENTLY_INVOICED_ORDERS_FAILURE, payload: error } }
}

export function getPrintInvoices() {
    return dispatch => {
        dispatch(request())
        invoicingService.getPrintInvoices().then(
            response => {
                dispatch(success(response))
            }).catch(error => {
                dispatch(failure(error))
            })
    }
    function request() { return { type: adminConstants.STORE_INVOICING_PRINT_REQUEST } }
    function success(response) { return { type: adminConstants.STORE_INVOICING_PRINT_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.STORE_INVOICING_PRINT_FAILURE, payload: error } }
}

export function submitInvoicingOrders(value, invoiceDate) {
    console.log(invoiceDate,"InvoiceDate");
    return dispatch => {
        dispatch(request())
        return invoicingService.submitInvoicingOrders(value, invoiceDate).then(
            response => {
                console.log('response', response.message);
                dispatch(success(response));
                dispatch(alertActions.success(response.message));
                return response;
            }).catch(error => {
                dispatch(failure(error));
                dispatch(alertActions.error(error));
                return Promise.reject(error);
            })
    }
    function request() { return { type: adminConstants.STORE_INVOICING_ORDERS_REQUEST } }
    function success(response) { return { type: adminConstants.STORE_INVOICING_ORDERS_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.STORE_INVOICING_ORDERS_FAILURE, payload: error } }
}

export function submitRecentlyInvoicedOrders(value, invoiceDate) {
    return dispatch => {
        dispatch(request())
        return invoicingService.submitRecentlyInvoicedOrders(value, invoiceDate).then(
            response => {
                console.log('response', response.message);
                dispatch(success(response));
                dispatch(alertActions.success(response.message));
                return response;
            }).catch(error => {
                dispatch(failure(error));
                dispatch(alertActions.error(error));
                return Promise.reject(error);
            })
    }
    function request() { return { type: adminConstants.STORE_RECENLTY_INVOICED_ORDERS_REQUEST } }
    function success(response) { return { type: adminConstants.STORE_RECENLTY_INVOICED_ORDERS_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.STORE_RECENLTY_INVOICED_ORDERS_FAILURE, payload: error } }
}


export function getInvoicingSettings() {
    return dispatch => {
        dispatch(request())
        return invoicingService.getInvoicingSettings().then(
            response => {
                // console.log('response', response);

                dispatch(success(response))
                return response;
            }).catch(error => {

                dispatch(failure(error));
                return Promise.reject(error);
            })
    }
    function request() { return { type: adminConstants.GET_INVOICING_SETTING_REQUEST } }
    function success(response) { return { type: adminConstants.GET_INVOICING_SETTING_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.GET_INVOICING_SETTING_FAILURE, payload: error } }
}


export function getInvoicingImport() {
    return dispatch => {
        dispatch(request())
        invoicingService.getInvoicingImport().then(
            response => {
                console.log('response', response);
                dispatch(success(response))
            }).catch(error => {
                dispatch(failure(error))
            })
    }
    function request() { return { type: adminConstants.GET_INVOICING_IMPORT_REQUEST } }
    function success(response) { return { type: adminConstants.GET_INVOICING_IMPORT_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.GET_INVOICING_IMPORT_FAILURE, payload: error } }
}

export function submitInvoicingSettings(value) {
    return dispatch => {
        dispatch(request())
        return invoicingService.submitInvoicingSettings(value).then(
            response => {
                console.log('response', response.message);
                dispatch(success(response));
                dispatch(success(response.message));
                return response;
            }).catch(error => {
                dispatch(failure(error));
                dispatch(error(error));
                return Promise.reject(error);
            })
    }
    function request() { return { type: adminConstants.STORE_INVOICING_SETTING_REQUEST } }
    function success(response) { return { type: adminConstants.STORE_INVOICING_SETTING_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.STORE_INVOICING_SETTING_FAILURE, payload: error } }
}


export function submitInvoicingImport(orders) {
    //console.log(agency_id,orders);
    return dispatch => {
        dispatch(request())
        return invoicingService.submitInvoicingImport(orders).then(
            response => {
                console.log('response', response);
                dispatch(success(response));
                // dispatch(success(response.message));
                return response;
            }).catch(error => {
                dispatch(failure(error));
                dispatch(error(error));
                return Promise.reject(error);
            })
    }
    function request() { return { type: adminConstants.STORE_INVOICING_IMPORT_REQUEST } }
    function success(response) { return { type: adminConstants.STORE_INVOICING_IMPORT_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.STORE_INVOICING_IMPORT_FAILURE, payload: error } }
}

