import React, { Component } from 'react';
import $ from 'jquery';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { PanelGroup, Panel } from 'react-bootstrap';

import { s3StaticPath, scrollLogin } from 'helper/helperFunctions';
import Validator from 'simple-react-validator'
import { sendContactEmail } from '../../redux/actions/front-end-action';
import info_email from '../../assets/info_email.svg';
import dmartinson_email from '../../assets/dmartinson_email.svg';


class ContactUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isHovered: false,
      full_name: '',
      phone: '',
      email: '',
      message: '',
      company: ''
    };
    this.handleHover = this.handleHover.bind(this);
    this.openChat = this.openChat.bind(this);
    this.contactValidator = new Validator();
  }

  componentDidMount(){
    this.openChat()
  }

  handleHover() {
    // this.setState(prevState => ({
    //     isHovered: !prevState.isHovered
    // }));

    $("#clients div.client").hover(function () {
      $("#clients div.client").removeClass("pink_gradient")
      $(this).addClass("pink_gradient")
    }, function () {
      $("#clients div.client").removeClass("pink_gradient")
    })
  }

  componentDidMount() {
    const script = document.createElement("script");
    script.src = "https://www.mylivechat.com/chatbutton.aspx?hccid=96602185";
    script.async = true;
    document.body.appendChild(script);
  }

  openChat = () => {
    new function(){
      if(!(window.location.href.indexOf("Admin") > -1)) {
        var url='https://a7.mylivechat.com/livechat/livechat.aspx?hccid=96602185&apimode=chatbutton';
        var s=document.getElementsByTagName('script')[0];
        var ns=document.createElement('script');
        ns.setAttribute('type','text/javascript');
        ns.setAttribute('src',url);
        s.parentNode.insertBefore(ns,s);
        // console.log(ns);
      }
    }
  }

  inputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  submitContactForm = () => {
    if (this.contactValidator.allValid()) {
      const data = {
        full_name: this.state.full_name,
        phone: this.state.phone,
        email: this.state.email,
        message: this.state.message,
        company: this.state.company
      }
      this.props.sendContactEmail(data).then(
        res => {
          const reset = {
            full_name: '',
            phone: '',
            email: '',
            message: '',
            company: ''
          }
          this.setState(prevState => ({
            ...prevState,
            ...reset
          }), () => {
            this.contactValidator.hideMessages();
            this.contactValidator.purgeFields();
            this.forceUpdate();
          })
        },
        err => {

        }
      );
    } else {
      this.contactValidator.showMessages();
      this.forceUpdate();
    }
  }
  render() {
    // const hoverClass = this.state.isHovered ? "client pink_gradient" : "client";
    // let sliderSettings = {
    //   dots: true,
    //   autoplay: true,
    //   infinite: true,
    //   speed: 500,
    //   slidesToShow: 1,
    //   slidesToScroll: 1,
    //   accessibility: false,
    //   className: 'benefits',
    //   prevArrow: <div><MdArrowBack /></div>,
    //   nextArrow: <div><MdArrowForward /></div>,
    // };

    return (
      <React.Fragment>

        <section id="contactUs" className="section">
          <h2 className="panel_heading">Contact Us</h2>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <p className="text_22 text-center mt-md">Do you have a question or need assistance with a Group Memories Product or Service?</p>
              </div>

              <div className="col-md-6 mt-xlg">
                <div className="liveChatBanner">
                  <div>
                    <p>Live <span className="text-white">Chat</span></p>
                    {/* <p className="text_18 text-black">Call or Email</p> */}
                    <div id="MyLiveChatContainer"></div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6 mt-xlg">
                    <div className="supportBox text-center">
                      <img src={s3StaticPath('img/traveler_support.png')} alt="" />

                      <p className="text_16 mt-xlg"><strong className="semibold">Traveler Support</strong></p>
                      <p className="text_14 mt-xs"><img src={info_email} alt="email" /></p>
                      <p className="text_18 mt-xs">1.888.533.7637 x213</p>
                      <p className="text-primary mt-xs">(EST Hours: 9:00AM - 5:00PM)</p>
                    </div>
                  </div>
                  <div className="col-md-6 mt-xlg">
                    <div className="supportBox text-center">
                      <img src={s3StaticPath('img/tour_operator_support.png')} alt="" />

                      <p className="text_16 mt-xlg"><strong className="semibold">Tour Operator Support</strong></p>
                      <p style={{ letterSpacing: '0.5px' }} className="text_14 mt-xs"><img src={dmartinson_email} alt="email" /></p>
                      <p className="text_18 mt-xs">1.888.533.7637 x205</p>
                      <p className="text-primary mt-xs">(EST Hours: 9:00AM - 5:00PM)</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mt-xlg contactFormContainer">
                <div className="flexElem respFlex">
                  <div className="hasFieldIcon">
                    <span className="fieldIcon userIcon">&nbsp;</span>
                    <input onChange={this.inputChange} value={this.state.full_name} type="text" name='full_name' className="form-control" placeholder="Full Name" />
                    {this.contactValidator.message('full_name', this.state.full_name, 'required')}
                  </div>
                  <div className="hasFieldIcon">
                    <span className="fieldIcon companyIcon">&nbsp;</span>
                    <input onChange={this.inputChange} value={this.state.company} name='company' type="text" className="form-control" placeholder="Company" />
                    {this.contactValidator.message('company', this.state.company, 'required')}
                  </div>
                </div>

                <div className="flexElem respFlex">
                  <div className="hasFieldIcon">
                    <span className="fieldIcon emailIcon">&nbsp;</span>
                    <input onChange={this.inputChange} value={this.state.email} name='email' type="email" className="form-control" placeholder="Email" />
                    {this.contactValidator.message('email', this.state.email, 'required|email')}
                  </div>
                  <div className="hasFieldIcon">
                    <span className="fieldIcon userIcon">&nbsp;</span>
                    <input onChange={this.inputChange} value={this.state.phone} name='phone' type="text" className="form-control" placeholder="Phone" />
                    {this.contactValidator.message('phone', this.state.phone, 'required|phone')}
                  </div>
                </div>

                <div className="flexElem block respFlex">
                  <div><textarea name='message' value={this.state.message} onChange={this.inputChange} className="form-control" placeholder="Message"></textarea></div>
                </div>
                {this.contactValidator.message('message', this.state.message, 'required')}

                <div className="flexElem block respFlex">
                  <div className="text-center">
                    <button onClick={this.submitContactForm} type="button" className="themeBtn_14 no_radius hover_dark red_gradient">Submit Contact Form</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section bg_alter">
          <h2 className="panel_heading">Frequently Asked Questions</h2>

          <div className="container mt-xlg">
            <div className="row">
              <div className="col-md-12">
                <PanelGroup accordion id="faq">
                  <Panel eventKey="1">
                    <Panel.Heading>
                      <Panel.Title toggle className="text_16"> How much does this service cost? </Panel.Title>
                    </Panel.Heading>
                    <Panel.Body collapsible className="text_14" style={{ lineHeight: "1.75" }}>
                    If you are planning your own trip and want to deal with us directly, it could be as low as <strong className="semibold">$15</strong> a traveler <strong className="semibold">(min order $150.00)</strong>. If you are traveling with a Tour Operator, our product my already be included in your trip. Please call your Tour Operator today and find out if our app is included and if not ask them how it can be included.
                    </Panel.Body>
                  </Panel>
                  {/* <Panel eventKey="2">
                    <Panel.Heading>
                      <Panel.Title toggle className="text_16"> Can I send more photos? </Panel.Title>
                    </Panel.Heading>
                    <Panel.Body collapsible className="text_14" style={{ lineHeight: "1.75" }}>
                      Yes. We allow you to have up to <strong className="semibold">250</strong> photos per camera/memory card/usb flash drive. More photos are allowed for larger groups and longer trips.

                      <div className="col-md-8 col-md-offset-2">
                        <table className="table mt-lg" style={{ border: "1px solid #ccc" }}>
                          <thead>
                            <tr>
                              <th>Group Size</th>
                              <th>Length of Trip</th>
                              <th>Approximate Number of Photos</th>
                            </tr>
                          </thead>

                          <tbody>
                            <tr>
                              <td className="text_14">15-120</td>
                              <td className="text_14">up to 5 days</td>
                              <td className="text_14">250-300 <small>(add 50 for each additional day)</small></td>
                            </tr>
                            <tr>
                              <td className="text_14">121-180</td>
                              <td className="text_14">up to 5 days</td>
                              <td className="text_14">300-500 <small>(add 50 for each additional day)</small></td>
                            </tr>
                            <tr>
                              <td className="text_14">180-240</td>
                              <td className="text_14">up to 5 days</td>
                              <td className="text_14">550-600 <small>(add 50 for each additional day)</small></td>
                            </tr>
                            <tr>
                              <td className="text_14">240+</td>
                              <td className="text_14">up to 5 days</td>
                              <td className="text_14">600-700 <small>(add 50 for each additional day)</small></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </Panel.Body>
                  </Panel> */}
                  <Panel eventKey="3">
                    <Panel.Heading>
                      <Panel.Title toggle className="text_16"> Do You Edit the photos? </Panel.Title>
                    </Panel.Heading>
                    <Panel.Body collapsible className="text_14" style={{ lineHeight: "1.75" }}>
                      Yes, a photo editor views every image. They delete photos that do not meet our quality standards, enhance photos by cropping, straightening, and color correction. We also watch for photos that might be objectionable.
                    </Panel.Body>
                  </Panel>
                  <Panel eventKey="4">
                    <Panel.Heading>
                      <Panel.Title toggle className="text_16"> Can I have access to the photos used in the video? </Panel.Title>
                    </Panel.Heading>
                    <Panel.Body collapsible className="text_14" style={{ lineHeight: "1.75" }}>
                        Yes, once the photos are edited they are posted to this secure website. You will use the same special <strong className="semibold">username</strong> and <strong className="semibold">password</strong> your group used for the app. You can review and download the photos for free and also link them directly to your Facebook. You can also order a CD of all the images of the highest resolution photos uploaded and save time downloading them all.
                    </Panel.Body>
                  </Panel>
                  {/* <Panel eventKey="5">
                    <Panel.Heading>
                      <Panel.Title toggle className="text_16"> What happens if the camera breaks or is stolen? </Panel.Title>
                    </Panel.Heading>
                    <Panel.Body collapsible className="text_14" style={{ lineHeight: "1.75" }}>
                      It doesn't happen often, but if it does happen. Just give us a call at <strong className="semibold">1-888-533-7637 ext.213</strong> and we will work with you on getting other photos.
                    </Panel.Body>
                  </Panel> */}
                  <Panel eventKey="6">
                    <Panel.Heading>
                      <Panel.Title toggle className="text_16"> Can I reorder a copy of my Group Travel Video? </Panel.Title>
                    </Panel.Heading>
                    <Panel.Body collapsible className="text_14" style={{ lineHeight: "1.75" }}>
                        Yes, once the video is produced, you will have access to download the video to any device. If you would like a DVD, simply <Link className="linkColor link_hover" onClick={(e) => { e.preventDefault(); scrollLogin() }} to="/Login">login</Link> with your group's <strong className="semibold">username</strong> and <strong className="semibold">password</strong> and click on the reorder button. If you are already logged in, <Link to="/keep_sake" className="linkColor link_hover">Click here</Link> to order.
                    </Panel.Body>
                  </Panel>
                  <Panel eventKey="7">
                    <Panel.Heading>
                      <Panel.Title toggle className="text_16"> Do you offer any other products made from my group's pictures? </Panel.Title>
                    </Panel.Heading>
                    <Panel.Body collapsible className="text_14" style={{ lineHeight: "1.75" }}>
                    Yes, you may order a cd or flash drive with all of the photos taken on your tour or <strong className="semibold">20"x30"</strong> PhotoMozaix&trade;. To learn more about PhotoMozaix, visit <Link to="/Mozaix" className="linkColor link_hover">www.grouptmemories.co.uk/mozaix</Link>. <Link to="/keep_sake" className="linkColor link_hover">Click here</Link> to order a CD of photos.
                    </Panel.Body>
                  </Panel>
                  <Panel eventKey="8">
                    <Panel.Heading>
                      <Panel.Title toggle className="text_16"> Can I upload my digital camera photos to you online? </Panel.Title>
                    </Panel.Heading>
                    <Panel.Body collapsible className="text_14" style={{ lineHeight: "1.75" }}>
                      Yes, simply <Link className="linkColor link_hover" onClick={(e) => { e.preventDefault(); scrollLogin() }} to="/Login">login</Link> to this website with the same <strong className="semibold">username</strong> and <strong className="semibold">password</strong> shown on your app card. Follow the easy directions to add your photos. Be sure to add your name so it appears under the photo on the app and website so others will know it is your photo.
                    </Panel.Body>
                  </Panel>
                  <Panel eventKey="9">
                    <Panel.Heading>
                      <Panel.Title toggle className="text_16"> What about privacy? Is the photo and video access private? </Panel.Title>
                    </Panel.Heading>
                    <Panel.Body collapsible className="text_14" style={{ lineHeight: "1.75" }}>
                      Access to your photos and Group Memories is password protected, so only those who go on the trip and their family can have access them. We do not ask for, nor need, the user's email address. That is why there is a <strong className="semibold">username</strong> and <strong className="semibold">password</strong> to access the app. No private information is gathered from the user's app device. Privacy is very important to us and we take it very seriously. No third party receives any information about you, because in fact, there is no information to share. So, your privacy is secure when using our app or website. If you have a traveler who does not want their photo taken, it is their responsibility to let people know this. The app does have a convenient social media sharing feature that allows users to post photos to <strong className="semibold">Facebook</strong>, <strong className="semibold">Twitter</strong>, and <strong className="semibold">Instagram</strong>. Your group has the option to have all uploaded photos approved for viewing before they can be seen on the app or website. The Approve for Viewing can only be done by logging into the website as the Group Leader/Photo Administrator.
                    </Panel.Body>
                  </Panel>
                  <Panel eventKey="10">
                    <Panel.Heading>
                      <Panel.Title toggle className="text_16"> How do I delete a photo I uploaded? </Panel.Title>
                    </Panel.Heading>
                    <Panel.Body collapsible className="text_14" style={{ lineHeight: "1.75" }}>
                        On the app, a user can delete their own photo by holding it down for 2 seconds and then deleting. The <strong className="semibold">Photo Administrator</strong> can delete any photo at anytime. Only the Photo Administrator has the ability to delete photos on our website. Once a photo is deleted, it can not be recovered.
                    </Panel.Body>
                  </Panel>
                  <Panel eventKey="11">
                    <Panel.Heading>
                      <Panel.Title toggle className="text_16"> Why can't I see the photos that have been uploaded? </Panel.Title>
                    </Panel.Heading>
                    <Panel.Body collapsible className="text_14" style={{ lineHeight: "1.75" }}>
                      The <strong className="semibold">Photo Administrator</strong> for your group may have selected to require pre-approval of the photos prior to posting in your group's gallery.
                    </Panel.Body>
                  </Panel>
                  <Panel eventKey="12">
                    <Panel.Heading>
                      <Panel.Title toggle className="text_16"> My group's upload deadline hasn't been reached but the gallery says that uploading is closed. </Panel.Title>
                    </Panel.Heading>
                    <Panel.Body collapsible className="text_14" style={{ lineHeight: "1.75" }}>
                      Depending on the special instructions we have received from the <strong className="semibold">Photo Administrator</strong> for your group, production may have been started early at their request. Please contact us for clarification on your specific order.
                    </Panel.Body>
                  </Panel>
                  <Panel eventKey="13">
                    <Panel.Heading>
                      <Panel.Title toggle className="text_16"> I did not get my photos uploaded by the deadline. Can my pictures still be included? </Panel.Title>
                    </Panel.Heading>
                    <Panel.Body collapsible className="text_14" style={{ lineHeight: "1.75" }}>
                      Depending on the special instructions we have received from the <strong className="semibold">Photo Administrator</strong> for your group, we may be able to accept your photos. Please contact us with your request and we will do our best to accommodate you.
                    </Panel.Body>
                  </Panel>
                  <Panel eventKey="14">
                    <Panel.Heading>
                      <Panel.Title toggle className="text_16"> How do I upload video or audio clips? </Panel.Title>
                    </Panel.Heading>
                    <Panel.Body collapsible className="text_14" style={{ lineHeight: "1.75" }}>
                        All groups are approved for video/audio uploading. The <strong className="semibold">Photo Administrator</strong> of your group is the only person allowed to submit video or audio clips. Therefore you must send cliips to them for approval and they will upload them via our website with their special login.
                    </Panel.Body>
                  </Panel>
                  <Panel eventKey="15">
                    <Panel.Heading>
                      <Panel.Title toggle className="text_16"> What size and resolution do my photos need to be? </Panel.Title>
                    </Panel.Heading>
                    <Panel.Body collapsible className="text_14" style={{ lineHeight: "1.75" }}>
                      Our uploader is designed to resize your images to match our production process. It is best that the pixel height or width be no smaller than <strong className="semibold">1200</strong>. We can still accept images that are smaller, but if the width or height is under <strong className="semibold">480</strong>, it will start to lower the quality of the video.
                    </Panel.Body>
                  </Panel>
                  <Panel eventKey="16">
                    <Panel.Heading>
                      <Panel.Title toggle className="text_16"> How do I upload more than 1 photo at a time? </Panel.Title>
                    </Panel.Heading>
                    <Panel.Body collapsible className="text_14" style={{ lineHeight: "1.75" }}>
                        The app currently allows for up to 20 <strong className="semibold">photos</strong> at a time. You may upload unlimited amounts from our website uploader.
                    </Panel.Body>
                  </Panel>
                  <Panel eventKey="17">
                    <Panel.Heading>
                      <Panel.Title toggle className="text_16"> When will my group receive the Storytelling Video? </Panel.Title>
                    </Panel.Heading>
                    <Panel.Body collapsible className="text_14" style={{ lineHeight: "1.75" }}>
                        All videos are delivered electronically and are able to be downloaded, saved or streamed to any device. Production time generally takes approximately <strong className="semibold">2-3 weeks</strong> from the time your order is submitted into production. All app users will get a notification the video is ready to enjoy.
                    </Panel.Body>
                  </Panel>
                  <Panel eventKey="18">
                    <Panel.Heading>
                      <Panel.Title toggle className="text_16"> Can I order a CD of photos? </Panel.Title>
                    </Panel.Heading>
                    <Panel.Body collapsible className="text_14" style={{ lineHeight: "1.75" }}>
                        Yes. Once the photos have been edited and your video has been produced, you will be able to log in to order a CD or flash drive of the edited photos, additional DVD copies, and more. <Link to="/keep_sake" className="linkColor link_hover">Click here</Link> to order a CD of photos.
                    </Panel.Body>
                  </Panel>
                  <Panel eventKey="19">
                    <Panel.Heading>
                      <Panel.Title toggle className="text_16"> Are the PhotoMozaix&trade; included as part of our package? </Panel.Title>
                    </Panel.Heading>
                    <Panel.Body collapsible className="text_14" style={{ lineHeight: "1.75" }}>
                      We offer the <strong className="semibold">PhotoMozaix</strong> as an individual purchase or quantity discount if multiple travelers would like to order one. Once the video is produced, you can <Link className="linkColor link_hover" onClick={(e) => { e.preventDefault(); scrollLogin() }} to="/Login">login</Link> with your group's <strong className="semibold">username</strong> and <strong className="semibold">password</strong> and click on the reorder button to order more <strong className="semibold">PhotoMozaix</strong>.
                    </Panel.Body>
                  </Panel>
                  <Panel eventKey="20">
                    <Panel.Heading>
                      <Panel.Title toggle className="text_16"> Can your products only be purchased through my travel company or can it be directly purchased for other types of events? </Panel.Title>
                    </Panel.Heading>
                    <Panel.Body collapsible className="text_14" style={{ lineHeight: "1.75" }}>
                      We do sell directly to customers. Please call us at <strong className="semibold">1-888-533-7637 x204</strong> for more information.
                    </Panel.Body>
                  </Panel>
                  {/* <Panel eventKey="21">
                    <Panel.Heading>
                      <Panel.Title toggle className="text_16"> How do you select the photos for the Photo of the Month contest? </Panel.Title>
                    </Panel.Heading>
                    <Panel.Body collapsible className="text_14" style={{ lineHeight: "1.75" }}>
                      As our production team is editing your photos, they will submit the photos that really catch their attention. They are professional photography editors and have edited <strong className="semibold">100s</strong> of <strong className="semibold">1000s</strong> of photos. So to answer your question, the better photos you take, the better your chances are for inclusion in the contest. So, catch that magical lighting or get that perfect angle and you could be the next <strong className="semibold">Photo of the Month Contest</strong> winner.
                    </Panel.Body>
                  </Panel> */}
                </PanelGroup>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
const mapDispatchToProps = dispatch => {
  return {
    sendContactEmail: (data) => dispatch(sendContactEmail(data))
  }
}
export default connect(null, mapDispatchToProps)(ContactUs);
