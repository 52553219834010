import React, { Component } from 'react';
// import footer_logo from "assets/img/footer_logo.png";
import Logo from 'components/FrontEnd/Brand/Logo';
// import MyImage from 'components/Upload/MyImage';
import { Row, Col, ListGroup } from "react-bootstrap";

import { Link } from "react-router-dom";
import { hasRole } from 'helper/helperFunctions';
import FooterLogo from '../Brand/FooterLogo'
import { FB, IG, LI, LogoFooter, TW, YT, Facebook, Youtube, Linkedin, Instagram, Twitter, Pin, CellPhone, Email } from '../../../assets/media/groupmemory/gmIcons';
// import FB from "../../../assets/groupmemory/fb-white.svg";

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: ''
    }
    this.fbLike = this.fbLike.bind(this);
  }

  fbLike() {
    (function (d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s); js.id = id;
      js.src = "//connect.facebook.net/en_US/sdk.js#xfbml=1&version=v2.0";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
  }

  componentDidMount() {
    this.fbLike();
  }

  render() {

    const { REACT_APP_GM_URL } = process.env;
    const externalUrl = REACT_APP_GM_URL + 'policy';
    return (
      <footer id="footer" className="bgDark">
        <div className="socialWrapper py-2">
          <ul className="socialLinks container mx-auto">
            <li>
              <Link
                to={"https://www.facebook.com/groupmemoriesltd/"}
                target="_blank"
                className="socialIcon"
              >
                <img
                  src={FB}
                  height={27}
                  width={15}
                  alt="Picture of the author"
                  className="transition-all duration-300"
                />
                <img
                  src={Facebook}
                  height={27}
                  width={15}
                  alt="Picture of the author"
                  className="transition-all duration-300"
                />
              </Link>
            </li>
            <li>
              <Link
                to={"https://www.youtube.com/@groupmemoriesltd"}
                target="_blank"
                className="socialIcon"
              >
                <img
                  src={YT}
                  width={24}
                  height={27}
                  alt="Picture of the author"
                  className="transition-all duration-300"
                />
                <img
                  src={Youtube}
                  width={24}
                  height={27}
                  alt="Picture of the author"
                  className="transition-all duration-300"
                />
              </Link>
            </li>
            <li>
              <Link
                to={"https://www.instagram.com/gmltd23/"}
                target="_blank"
                className="socialIcon"
              >
                <img
                  src={IG}
                  height={27}
                  width={24}
                  alt="Picture of the author"
                  className="transition-all duration-300"
                />
                <img
                  src={Instagram}
                  height={27}
                  width={24}
                  alt="Picture of the author"
                  className="transition-all duration-300"
                />
              </Link>
            </li>
            <li>
              <Link
                to={"https://www.linkedin.com/in/groupmemoriesltd/"}
                target="_blank"
                className="socialIcon"
              >
                <img
                  src={LI}
                  height={27}
                  width={24}
                  alt="Picture of the author"
                  className="transition-all duration-300"
                />
                <img
                  src={Linkedin}
                  height={27}
                  width={24}
                  alt="Picture of the author"
                  className="transition-all duration-300"
                />
              </Link>
            </li>
            <li>
              <Link
                to={"https://twitter.com/Groupmemories23"}
                target="_blank"
                className="socialIcon"
              >
                <img
                  src={TW}
                  height={27}
                  width={24}
                  alt="Picture of the author"
                  className="hover:scale-95 transition-all duration-300"
                />
                <img
                  src={Twitter}
                  height={27}
                  width={24}
                  alt="Picture of the author"
                  className="hover:scale-95 transition-all duration-300"
                />
              </Link>
            </li>
          </ul>
        </div>
        <div className="footerWrap containerFooter">
          <div className="footerGrid">
            <div className='text-center'>
              <Link to="/">
                <img
                  src={LogoFooter}
                  width={240}
                  height={142}
                  alt="Picture of the author"
                  className="hover:opacity-75 transition-all duration-300 mx-auto"
                />
              </Link>
            </div>
            <div>
              <p className="text-white">
                Group Memories Ltd. has been dedicated to preserving and
                crafting memories for the UK audience since 2023, focusing
                specifically on the student and senior adult travel market.
              </p>
            </div>
            <div className="text-white externalLinks">
              <div
                className="grid grid-cols-5"
                style={{ lineHeight: 1.25 }}
              >
                <div className="textMDCenter">
                  <img
                    src={Pin}
                    width={"auto"}
                    height={"auto"}
                    alt="App store"
                    className="w-4 mx-auto mt-1"
                  />
                </div>
                <div className="col-span-4 mb-1 text-left">
                  <h6>Address</h6>
                  <Link
                    to={"https://maps.app.goo.gl/RSNUGfpx5dtsxdig6"}
                    target="_blank"
                    className="anchor"
                  >
                    124 City Road, London, EC1V 2NX
                  </Link>
                </div>
                <div className="textMDCenter">
                  <img
                    src={CellPhone}
                    width={"auto"}
                    height={"auto"}
                    alt="App store"
                    className="w-4 mx-auto mt-1"
                  />
                </div>
                <div className="col-span-4 mb-1 text-left">
                  <h6>Phone</h6>
                  <Link
                    to={"tel:+443301338909"}
                    className="anchor"
                  >
                    033 0133 8909
                  </Link>
                </div>
                <div className="textMDCenter">
                  <img
                    src={Email}
                    width={"auto"}
                    height={"auto"}
                    alt="App store"
                    className="h-4 mx-auto mt-1"
                  />
                </div>
                <div className="col-span-4 mb-1 text-left">
                  <h6>Email</h6>
                  <Link to={"mailto:#"} className="anchor">
                    info@groupmemories.co.uk
                  </Link>
                </div>
              </div>
            </div>
            <div>
              <div className="pagesLink flex flex-col gap-3 lg:pl-32">
                <a
                  href={REACT_APP_GM_URL + 'contact'}
                  className="anchor"
                >
                  Contact Us
                </a>
                <a
                  href={REACT_APP_GM_URL + 'policy'}
                  className="anchor"
                >
                  Terms & Conditions
                </a>
                <a
                  href={REACT_APP_GM_URL + 'terms'}
                  className="anchor"
                >
                  Privacy Policy
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="footerCopyrights">
          <div className="footerCopyrightsInner containerFooter">
            <span className="text-white">© 2023 Group Memories™</span>
            <div className="textGreen">
              Company Registration Number:
              <span>15132897</span>
            </div>
          </div>
        </div>
      </footer>
      // <footer id="footer" style={{background:"#3B3D44"}}>
      //   <div className='container mx-auto'>

      //   </div>
      //   <div className="container">
      //     <div className="flexElem respFlex py25">
      //       <Row className='py25'>
      //         <Col md={6} lg={3} className='text-center'>
      //         <Link to="/">
      //         <img src={LogoFooter} width={240} alt='Logo' />
      //         </Link>
      //         </Col>
      //         <Col md={6} lg={3}>
      //         <p className="text-white px1-25">
      //           Group Memories has been serving the UK audience by preserving and creating memories since 2023, specifically the student and senior adult travel market with our Group Memories Ltd. 
      //         </p>
      //         </Col>
      //         <Col md={6} lg={3} className='px1-25'>
      //         <ul className="footer_menus flexGap10 flexElem alignEnd">
      //               <li className="link_hover mb0f">
      //                 <Link to={"https://www.facebook.com/groupmemoriesltd/"} target="_blank" className="text-white socialIconBox">
      //                 <img
      //                   src={FB}
      //                   width={16} height={16}
      //                   alt="Picture of the author"
      //                   className="hover:invert-1"
      //                 />
      //                 </Link>
      //               </li>
      //               <li className="link_hover mb0f">
      //                 <Link to={"https://www.youtube.com/@groupmemoriesltd"} target="_blank" className="text-white socialIconBox">
      //                 <img
      //                   src={YT}
      //                   width={16}
      //                   alt="Picture of the author"
      //                   className="hover:invert-1"
      //                 />
      //                 </Link>
      //               </li>
      //               <li className="link_hover mb0f">
      //                 <Link to={"https://www.instagram.com/gmltd23/"} target="_blank" className="text-white socialIconBox">
      //                 <img
      //                   src={IG}
      //                   width={16}
      //                   alt="Picture of the author"
      //                   className="hover:invert-1"
      //                 />
      //                 </Link>
      //               </li>
      //               <li className="link_hover mb0f">
      //                 <Link to={"https://www.linkedin.com/in/groupmemoriesltd/"} target="_blank" className="text-white socialIconBox">
      //                 <img
      //                   src={LI}
      //                   width={16}
      //                   alt="Picture of the author"
      //                   className="hover:invert-1"
      //                 />
      //                 </Link>
      //               </li>
      //               <li className="link_hover mb0f">
      //                 <Link to={"https://twitter.com/Groupmemories23"} target="_blank" className="text-white socialIconBox">
      //                 <img
      //                   src={TW}
      //                   width={16}
      //                   alt="Picture of the author"
      //                   className="hover:invert-1"
      //                 />
      //                 </Link>
      //               </li>
      //             </ul>
      //         </Col>
      //         <Col md={6} lg={3}>
      //         <ul className="footer_menus">
      //               <li className="link_hover"><a href={REACT_APP_GM_URL+'contact'} className="text-white gmLink">Contact Us</a></li>
      //               <li className="link_hover"><a href={REACT_APP_GM_URL+'policy'} className="text-white gmLink">Privacy Policy</a></li>
      //               <li className="link_hover"><a href={REACT_APP_GM_URL+'terms'} className="text-white gmLink">Terms & Conditions</a></li>
      //             </ul>
      //         </Col>
      //       </Row>
      //     </div>
      //       <Row className='pb-xlg'>
      //         <div className="text-center text-white" style={{marginTop: '8px', textSize: '17px'}}>
      //           <div>
      //             <span >124 City Road, London, EC1V 2NX</span>
      //           </div>
      //           <div style={{ color: "#09CCAE" }}>
      //             Company Registration Number:
      //             <span style={{marginLeft: '5px'}}>15132897</span>
      //           </div>
      //         <span>© 2023 Group Memories™</span>
      //           {/* Made with{" "}
      //           <span className="mx-1" style={{ color: "tomato" }}>
      //             ♥{" "}
      //           </span>
      //           in London, UK. */}
      //         </div>
      //       </Row>
      //     </div>
      // </footer>
    );
  }
}
export default Footer;
