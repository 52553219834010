import React, { Component } from 'react'
import { SC } from 'helper/ServerCall';
import ToggleButton from 'react-toggle-button';
import { themeCSSConstants } from 'redux/constant/themeCSS';
import {objectsConstants as OC} from 'redux/constant/objects-constant';
import $ from 'jquery'

export default class OrderMoreButtonsWeTravel extends Component {


    constructor(props) {
        super(props);
        this.state = {
            weTravelButton: OC.MOREBUTTONS,
            oldState: false
        }
    }

    // getAgencyWeTravelButton() {
    //     return SC.getCall('getAgencyWeTravelButton/' + this.props.agency_id );
    // }

    getAgencyWeTravelButton(order_id, agency_id, uuid) {
        return SC.getCall('getOrderWeTravelButton/' + this.props.order_id + '?agency_id='+this.props.agency_id + '&uuid='+ this.props.uuid );
    }

    componentDidMount() {
        let response = this.getAgencyWeTravelButton().then( (response) =>  {
            console.log(response, 'response#21');
            this.setState({
                weTravelButton: (response.data && response.data.weTravel && response.data.weTravel.order) ? response.data.weTravel.order : ((response.data && response.data.weTravel && response.data.weTravel.agency) ? response.data.weTravel.agency : '')
            }, () => {
                this.props.childState(this.state.weTravelButton)
            })
        }
        )
    }


    updateMoreButton() {

        if(+this.props.order_id > 0) {
            this.state.weTravelButton.order_id = +this.props.order_id;
        }
        else if(+this.props.uuid > 0) {
            this.state.weTravelButton.uuid = +this.props.uuid;
        }

        this.props.childState(this.state.weTravelButton)
        if(this.state.weTravelButton.link !== this.state.oldState.link){
            $('#moreBtn').css("border-color", "#bebebe")
            return SC.postCall('updateMoreButton/' + this.state.weTravelButton.id, this.state.weTravelButton );
        }else if(this.state.weTravelButton.link){
            $('#moreBtn').css("border-color", "#bebebe")
        }else{
            $('#moreBtn').focus().css("border-color", "red")
        }

    }

    handleOptions = (name, value) => {
        // console.log(name, value, 'value#36');
		let tempValue = !+value;
        tempValue = tempValue ? 1 : 0;
        // console.log(name, tempValue, 'value#39');

        // console.log(this.state.weTravelButton, 'this.state.weTravelButton#42');
		this.setState(prevState => ({
            weTravelButton: {
                ...prevState.weTravelButton,
                [name]: tempValue
            }
		})
        ,
        () => {
            if (name === 'show_on_all_orders' || name === 'option_at_order_placement') {
                var WtOrder = 'show_on_all_orders';
                if (name === WtOrder)
                    WtOrder = 'option_at_order_placement';
                this.setState(prevState => ({
                    weTravelButton: {
                        ...prevState.weTravelButton,
                        [WtOrder]:0
                    }
                }),() => {
                    this.updateMoreButton();
                });
            }
            else if (name === 'is_active' && tempValue == 0) {
                this.setState(prevState => ({
                    weTravelButton: {
                        ...prevState.weTravelButton,
                        ['show_on_all_orders']:0,
                        ['option_at_order_placement']:0
                    }
                }),() => {
                    this.updateMoreButton();
                });
            }
            else {
                this.setState(prevState => ({
                    weTravelButton: {
                        ...prevState.weTravelButton,
                        ['show_on_all_orders']:1,
                        ['option_at_order_placement']:0
                    }
                }),() => {
                    this.updateMoreButton();
                });
            }
        });

        // console.log(this.state.weTravelButton, 'this.state.weTravelButton#74');
        ;
	}



	handleAgencyLink = (name, value) => {
		this.setState(prevState => ({
            oldState: prevState,
            weTravelButton: {
                ...prevState.weTravelButton,
                [name]: value
            }
		}))
	}

    render() {
        const {weTravelButton} = this.state;

        // console.log(weTravelButton, '+weTravelButton.is_active#83')
        return (
            <div>
                <div className='noMarginTable frontEndTable noBorderTable mb-lg'>

                    {(weTravelButton && weTravelButton.is_active === 1) ?
                            <div className="row">
                                <div className="flexElem alignCenter spaceBetween col-md-12" style={{marginTop: '7px', marginRight: '-30px'}}>
                                    <div className="togglePadding">
                                        <label className="container-check">
                                            <div>Pay with WeTravel</div>
                                            {/* <p className="text-muted">Make a payment towards your trip</p> */}
                                        </label>
                                    </div>

                                    {/* {
                                        this.props.order_id ?
                                        <div>
                                            <p className="text-muted">Make a payment towards your trip</p>
                                        </div>
                                        : ''
                                    } */}
                                </div>
                                {/* {Number(order_details && (order_details.settings.accepts_wt_payments) ? +order_details.settings.accepts_wt_payments.value : ((agency && agency.settings.accepts_wt_payments) ? +agency.settings.accepts_wt_payments.value : 0)) ? */}
                                    <div className="col-md-12">
                                        <input type="text" id="moreBtn" placeholder="Place payment link here, If left blank this button will NOT appear on the app" onFocus={() => this.setState(prevState => ({oldState: prevState.weTravelButton}))} onKeyDown={(e) => e.which == 13 && e.preventDefault()} onKeyPress={(e) => e.which == 13 && e.preventDefault()}
                                            onKeyUp={(e) => e.which == 13 && (e.preventDefault(),
                                            this.updateMoreButton())}
                                            value={weTravelButton.link ? weTravelButton.link : ''} className="form-control"
                                            onBlur={(e) => this.updateMoreButton()}
                                            onChange={(e) => this.handleAgencyLink('link', e.target.value)} />
                                    </div>

                                {/* // : ''
                                // } */}
                            </div>
                        : ""
                        }
                </div>
            </div>
        )
    }
}
