import React, { Component } from "react";
import InputMask from 'react-input-mask';
import { getPhoneFormat } from "../../helper/helperFunctions";
import User from "../../helper/User";

const PhoneFormat = getPhoneFormat('uk');

class CustomCellPhoneNumber extends Component {

	constructor(props) {
		super(props);
		this.state = {
			validatorArrayFrance : [
				{
					'regex': [/^[\d]{2}[-]?[\d]{1}[-]?[\d]{2}[-]?[\d]{2}[-]?[\d]{2}[-]?[\d]{2}$/]
				},
			],
            validatorArrayOthers : [
				{
					'regex': [/^[\d]{3}[-]?[\d]{3}[-]?[\d]{4}$/]
				},
			],
            ukValidator : PhoneFormat[0]
		}

	}
	componentDidMount(){
		const { required } = this.props;
		if ( required ) {
			this.setState({ validatorArrayOthers: [...this.state.validatorArrayOthers, 'required'] })
			this.setState({ validatorArrayFrance: [...this.state.validatorArrayFrance, 'required'] })
			this.setState({ ukValidator: [...this.state.ukValidator, 'required'] })
		}
	}

	render(){
		const { validatorArrayOthers, validatorArrayFrance, ukValidator } = this.state;
		const { name, value, validationName,
            vsa_initial_country_France,
            ptma_initial_country_France,
            ptma_is_france,
            vsa_is_france,
            frontend_order_is_France} = this.props;

        let mask = PhoneFormat[1];
        let placeHolder = PhoneFormat[2];

        let is_order_france = false;

        is_order_france = vsa_initial_country_France || ptma_initial_country_France || ptma_is_france || vsa_is_france
		return(
            <div>
                {
                    is_order_france || frontend_order_is_France

                        ?
                            <React.Fragment>
                                <InputMask
                                    mask={"99-9-99-99-99-99"}
                                    maskChar={null}
                                    id={this.props.elemId}
                                    type="text"
                                    name={name}
                                    className="form-control phone_icon"
                                    placeholder="12-3-45-67-89-10"
                                    value={value}
                                    onChange={this.props.handleParent}
                                    onClick={this.props.handleParent}
                                />
                                <div style={{width: '100%'}}>
                                    {
                                        this.props.validator &&
                                            this.props.validator.message(validationName, value, validatorArrayFrance)
                                    }
                                </div>
                            </React.Fragment>
                        :
                            <React.Fragment>
                                <InputMask
                                    mask={User.brand('domain_slug').includes('gm') ? mask : "999-999-9999"}
                                    maskChar={null}
                                    id={this.props.elemId}
                                    type="text"
                                    name={name}
                                    className="form-control phone_icon"
                                    placeholder={User.brand('domain_slug').includes('gm') ? placeHolder : "123-456-7890"}
                                    value={value}
                                    onChange={this.props.handleParent}
                                    onClick={this.props.handleParent}
                                />
                                <div style={{width: '100%'}}>
                                    {
                                        this.props.validator &&
                                            this.props.validator.message(validationName, value, User.brand('domain_slug').includes('gm') ? ukValidator : validatorArrayOthers)
                                    }
                                </div>
                            </React.Fragment>
                }
            </div>
		);
	};
}



export default CustomCellPhoneNumber;
