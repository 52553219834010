import React, { Component } from "react";
import {
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";

class CustomCheckbox extends Component {
  render() {
    const { tooltipText , label, value, indexArray, name, parentState } = this.props;
    return (
      <div className='form-group'>
        <label className="container-check"> { label }
          <OverlayTrigger placement="right" overlay={<Tooltip id="tooltip">{ tooltipText }</Tooltip>}>
            <span className="question_mark">?</span>
          </OverlayTrigger>
          {
            Array.isArray(indexArray) &&
            <input type="checkbox" name={name} className="checkbox"  value={value} onChange={(event)=>this.props.handleChandgeCheckBox(event, indexArray, parentState)} checked={value} />
          }

          {
            !Array.isArray(indexArray) &&
            <input type="checkbox" name={name} className="checkbox"  value={value} onChange={this.props.handleChandgeCheckBox} checked={value} />
          }
          
          <span className="checkmark"></span>
        </label>
      </div>
    );
  }
}

export default CustomCheckbox;
