import React, { Component } from 'react'
import { SC } from 'helper/ServerCall';
import ToggleButton from 'react-toggle-button';
import { themeCSSConstants } from 'redux/constant/themeCSS';
import {objectsConstants as OC} from 'redux/constant/objects-constant';

export default class OrderMoreButtonsWeTravel extends Component {


    constructor(props) {
        super(props);
        this.state = {
            weTravelButton: OC.MOREBUTTONS,
            agencyWeTravelButton: OC.MOREBUTTONS,
            oldState: false
        }
    }



    getOrderWeTravelButton() {
        return SC.getCall('getOrderWeTravelButton/' + this.props.order_id );
    }
    componentDidMount() {
        let response = this.getOrderWeTravelButton().then( (response) =>  {
            // console.log(response, 'response#21');
            let wt_button = '';
            let agency_wt_button = '';
            if(response.data.weTravel && response.data.weTravel.order) {
                wt_button = response.data.weTravel.order;
            }
            else {
                wt_button = response.data.weTravel.agency;
            }
            if(response.data.weTravel && response.data.weTravel.agency) {
                agency_wt_button = response.data.weTravel.agency;
            }

            this.setState({
                weTravelButton: wt_button
            });

            this.setState({
                agencyWeTravelButton: agency_wt_button
            })
        }
        )


    }


    updateMoreButton() {
        if(+this.props.order_id > 0) {
            this.state.weTravelButton.order_id = +this.props.order_id;
        }
        if(this.state.weTravelButton.link !== this.state.oldState.link){
          return SC.postCall('updateMoreButton/' + this.state.weTravelButton.id, this.state.weTravelButton );
        }
    }

    handleOptions = (name, value) => {
        // console.log(name, value, 'value#36');
		let tempValue = !+value;
        tempValue = tempValue ? 1 : 0;
        // console.log(name, tempValue, 'value#39');

        // console.log(this.state.weTravelButton, 'this.state.weTravelButton#42');
		this.setState(prevState => ({
            weTravelButton: {
                ...prevState.weTravelButton,
                [name]: tempValue
            }
		})
        ,
        () => {
            if (name === 'show_on_all_orders' || name === 'option_at_order_placement') {
                var WtOrder = 'show_on_all_orders';
                if (name === WtOrder)
                    WtOrder = 'option_at_order_placement';
                this.setState(prevState => ({
                    weTravelButton: {
                        ...prevState.weTravelButton,
                        [WtOrder]:0
                    }
                }),() => {
                    this.updateMoreButton();
                });
            }
            else if (name === 'is_active' && tempValue == 0) {
                this.setState(prevState => ({
                    weTravelButton: {
                        ...prevState.weTravelButton,
                        ['show_on_all_orders']:0,
                        ['option_at_order_placement']:0
                    }
                }),() => {
                    this.updateMoreButton();
                });
            }
            else {
                this.setState(prevState => ({
                    weTravelButton: {
                        ...prevState.weTravelButton,
                        ['show_on_all_orders']:1,
                        ['option_at_order_placement']:0
                    }
                }),() => {
                    this.updateMoreButton();
                });
            }
        });

        // console.log(this.state.weTravelButton, 'this.state.weTravelButton#74');
        ;
	}



	handleAgencyLink = (name, value) => {
		this.setState(prevState => ({
            oldState: prevState,
            weTravelButton: {
                ...prevState.weTravelButton,
                [name]: value
            }
		}))
	}

    render() {
        const {weTravelButton, agencyWeTravelButton} = this.state;
        return (
            <div>
                    {/* <div>-----------------///----------------</div> */}
                    <div className='row container-fluid'>
                                {(agencyWeTravelButton && agencyWeTravelButton.is_active === 1) ?
                                    <div className="clearfix row" style={{display: 'flex', justifyContent: 'center', flexWrap: 'wrap'}}>

                                        <div className="flexElem alignCenter col-md-4">
                                            <div className="togglePadding">
                                                <label className="container-check">
                                                    <div>Pay with WeTravel</div>
                                                    <p className="text-muted">Make a payment towards your trip</p>
                                                </label>
                                            </div>
                                        </div>
                                        {/* <div className="flexElem alignCenter col-md-6">
                                            <label className="container-check alignCenter"> */}
                                                {/* <ToggleButton
                                                    inactiveLabel={''}
                                                    activeLabel={''}
                                                    colors={themeCSSConstants.THUMBICONSTYLE}
                                                    trackStyle={{ height: '10px', width: '30px' }}
                                                    thumbStyle={themeCSSConstants.THUMBSTYLE}
                                                    thumbAnimateRange={[0, 15]}
                                                    value={order_details && (order_details.settings.accepts_wt_payments) ? +order_details.settings.accepts_wt_payments.value : ((agency && agency.settings.accepts_wt_payments) ? +agency.settings.accepts_wt_payments.value : 0)}
                                                    onToggle={() => this.handleOptions("accepts_wt_payments", (agency && agency.settings.accepts_wt_payments) ? +agency.settings.accepts_wt_payments.value : 0)} /> */}
                                                {/* <div className='header'>Pay with WeTravel</div>&nbsp;
                                                <p className="text-muted">Make a payment towards your trip</p>
                                            </label>
                                        </div> */}
                                        {/* {Number(order_details && (order_details.settings.accepts_wt_payments) ? +order_details.settings.accepts_wt_payments.value : ((agency && agency.settings.accepts_wt_payments) ? +agency.settings.accepts_wt_payments.value : 0)) ? */}

                                            <div className="col-md-6">
                                                <input type="text" placeholder="Place payment link here, If left blank this button will NOT appear on the app" onFocus={() => this.setState(prevState => ({oldState: prevState.weTravelButton}))} onKeyDown={(e) => e.which == 13 && e.preventDefault()} onKeyPress={(e) => e.which == 13 && e.preventDefault()}
                                                onKeyUp={(e) => e.which == 13 && (e.preventDefault(),
                                                this.updateMoreButton())}
                                                value={weTravelButton.link} className="form-control"
                                                onBlur={(e) => this.updateMoreButton()}
                                                onChange={(e) => this.handleAgencyLink('link', e.target.value)} />
                                            </div>
                                            {/* :
                                            ''} */}

                                            <div className="col-md-12"><hr /></div>
                                    </div>
                                    : ''
                                }
                    </div>
            </div>
        )
    }
}
