import Dashboard from "layouts/Dashboard/Dashboard.jsx";
import Frontend from "layouts/Frontend/Frontend.jsx";
import AdminLogin from "layouts/Dashboard/AdminAuth.jsx";
import PrintOrderOptions from "../components/FrontEnd/Order/OrderOptions/PrintOrderOptions";
import printLPR from "../components/Admin/Reports/printLPR";
import printReOrderLPR from "../components/Admin/Reports/printReOrderLPR";
import PrintOrderSummary from "../components/FrontEnd/Order/PrintOrderSummary";
import VideoPreview from 'components/Upload/VideoPreview';

var indexRoutes = [
	{
		path: "/print-order-summary/:id",
		name: "Print Order Summary",
		component: PrintOrderSummary,
		exactPro: false
		// auth: true,
		// role: [Roles.AGENCY_USER],
		// showMenu: false
	},
	{
		path: '/Admin/:id/print_reorder_LPR',
		name: 'Reorder LPR',
		component: printReOrderLPR,
		permission: "print-reorder-lpr"
	},
	{
		path: '/Admin/:id/print_LPR',
		name: 'Print Lab Production Report',
		component: printLPR
	},
	{
		path: "/print-order-options/:id",
		name: "Print Order Options",
		component: PrintOrderOptions,
		exactPro: false,
	},
	{
		path: "/show-video/:fileId?",
		name: "Show Video",
		component: VideoPreview,
		exactPro: false,
	},
	
	{ path: "/Admin-login", name: "AdminLogin", component: AdminLogin, exactPro: false },
	{ path: "/Admin", name: "Home", component: Dashboard, exactPro: false },
	{ path: "/", name: "Frontend", component: Frontend, exactPro: false },
];

export default indexRoutes;
