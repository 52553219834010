import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Grid,
  Row,
  Col,
  OverlayTrigger,
  Popover
} from "react-bootstrap";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Card } from "components/Card/Card.jsx";
import { getTransmittalTemplatesPagination, getTransmittalTemplate, deleteTransmittalTemplate, updateTransmittalTemplateStatus, getBrand } from 'redux/actions/brand-actions';
// import { permissionActions } from 'redux/actions/permission-action';
import 'react-table/react-table.css';
import TransmittalTemplateForm from "./TransmittalTemplateForm";
// import { FormInputs } from "components/FormInputs/FormInputs.jsx";
import { hasPermission } from 'helper/hasPermission';
import Confirmalertfordelete from 'components/ThemeComponents/confirmAlertForDelete';
import { adminLabels } from 'redux/constant/admin-label-constant';
import OpenModalButton from 'components/ThemeComponents/openModelButton';
import { toggleModal, selectChangeHandler, openModal, closeModalAfterApi } from 'helper/helperFunctions';
// import { Link } from 'react-router-dom';
import { objectsConstants as OC } from 'redux/constant/objects-constant';
import ThemeModal from 'components/ThemeComponents/ThemeModal';
// var Modal = require('react-bootstrap-modal');
// import DownloadFile from 'components/ThemeComponents/downloadFile';
import parse from 'html-react-parser';
import Select from 'react-select'
import Pagination from '../../../ThemeComponents/Pagination';

class TransmittalTemplatesListPage extends Component {
  constructor(props) {
    super(props);
    const { accountId } = this.props.match.params;

    this.state = {
      addModalTransmittalTemplate: this.props.addModalTransmittalTemplate,
      editModalTransmittalTemplate: this.props.editModalTransmittalTemplate,
      onCloseAfterApi: false,
      sorted: '',
      filtered: '',
      pageSize: 10,
      page: 0,
      accountId: parseInt(accountId, 10),
      brand_status: '1',
    };

    this.toggleModal = toggleModal.bind(this);
    this.selectChangeHandler = selectChangeHandler.bind(this);
  };
  filterSearchHandle = (e) => {
    this.setState({
      'filtered': e.target.value
    });
  }
  deleteTransmittalTemplate = (transmittalTemplateId) => {
    const { accountId } = this.state;

    this.props.deleteTransmittalTemplateCall(accountId, transmittalTemplateId).then(response => {
      this.pagination.dataCall();
    });
  }
  deleteConfirmTransmittalTemplate = (transmittalTemplateId) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <Confirmalertfordelete typeName="TransmittalTemplate" description="Are you sure to delete the transmittalTemplate?" onClosePro={onClose} deleteType={() => this.deleteTransmittalTemplate(transmittalTemplateId)} />
        )
      }
    })
  }
  transmittalTemplatesPaginationData = (page = this.state.page, filtered = this.state.filtered, sorted = this.state.sorted, pageSize = this.state.pageSize, status) => {
    // const { /*accountId */ } = this.state;
    this.pagination.dataCall();
  }

  handleTransmittalTemplateStatus = transmittalTemplateId => (e) => {
    //console.log('transmittalTemplateId', transmittalTemplateId);
    this.props.handleTransmittalTemplateStatusCall(transmittalTemplateId, { is_active: e.target.checked ? true : false }).then(
      response => {
        this.pagination.dataCall();
      }
    );
  }

  componentDidMount() {
    this.props.getBrandCall(this.props.match.params.accountId);
  }


  componentDidUpdate(prevProps, prevState) {
    if ((prevProps.editTransmittalTemplate !== this.props.editTransmittalTemplate) && this.props.editTransmittalTemplate) {
      this.setState({ editModalTransmittalTemplate: true });
    }
    if (prevProps.transmittalTemplatesCurrent_page !== this.props.transmittalTemplatesCurrent_page) {
      this.setState({ page: this.props.transmittalTemplatesCurrent_page }, () => {
        //console.log('page state', this.state.page);
      })
    }
    if ((!prevState.addModalTransmittalTemplate && this.state.addModalTransmittalTemplate) || (!prevState.editModalTransmittalTemplate && this.state.editModalTransmittalTemplate)) {
      this.setState({ onCloseAfterApi: false })
    }
    if ((prevState.addModalTransmittalTemplate && !this.state.addModalTransmittalTemplate && this.state.onCloseAfterApi) || (prevState.editModalTransmittalTemplate && !this.state.editModalTransmittalTemplate && this.state.onCloseAfterApi)) {
      // this.transmittalTemplatesPaginationData();
      this.pagination.dataCall();
    }
    if (this.state.brand_status !== prevState.brand_status) {
      this.pagination.dataCall();
    }
  }

  paginationCall = (data) => {
    const { accountId, brand_status } = this.state;
    return this.props.getTransmittalTemplatesPaginationCall(accountId, data.page, data.filter, data.sort, data.pageSize, brand_status);
  }

  render() {
    const { /*filtered,*/ accountId } = this.state;
    const { editBrand, editTransmittalTemplate/*, transmittalTemplatesData, transmittalTemplatesPages, loadingBrand*/ } = this.props;
    let is_master = false;
    is_master = editBrand && editBrand.domain_slug.match('master') ? true : false;
    console.log("is_master", is_master);
    const columns = [
      {
        Header: "TransmittalTemplates Listing",
        headerClassName: 'headerPagination',
        columns: [
          {
            Header: "Description",
            accessor: "description",
            className: "action-center"
          },
          {
            Header: "Content",
            accessor: "content",
            className: "action-center",
            Cell: ({ value }) => (
              <div>
                {
                  value.length < 15
                    ?
                    parse(`${value}...`)
                    :
                    parse(`${value.substring(0, 32)}...`)

                }
              </div>
            )
          },
          {
            Header: "Category",
            accessor: "transmittal_category.label",
            className: "action-center"
          },
          {
            Header: "Type",
            accessor: "transmittal_type.title",
            className: "action-center"
          },

          {
            Header: "Subject",
            accessor: "subject",
            className: "action-center"
          },
          {
            Header: "Status",
            accessor: "is_active",
            Cell: ({ row }) => (
              <label className="container-check">
                &nbsp;
                <input
                  type="checkbox" name="is_active" className="checkbox" value={row.is_active ? 1 : 0} onChange={this.handleTransmittalTemplateStatus(row.id)} checked={row.is_active ? 1 : 0}
                /><span className="checkmark"></span>
              </label>
            ),
            className: "action-center",
            sortable: false
          },
          {
            Header: "Actions",
            accessor: "id",
            Cell: ({ value }) => (
              <div>
                <OverlayTrigger placement="bottom" overlay={<Popover id="tooltip">{hasPermission('transmittalTemplate-edit') ? 'Edit Template' : adminLabels.DONT_ALLOW}</Popover>}>
                  <span>
                    {
                      hasPermission('transmittalTemplate-edit') &&
                      <button type='button' className="editIcon orderDetailsAction" onClick={() => this.props.getTransmittalTemplateCall(accountId, value)}>&nbsp;</button>
                    }
                    {
                      !hasPermission('transmittalTemplate-edit') &&
                      <button type='button' className="editIcon orderDetailsAction button_disabled" >&nbsp;</button>
                    }
                  </span>
                </OverlayTrigger>
                {/* <OverlayTrigger placement="bottom" overlay={<Popover id="tooltip">{hasPermission('transmittalTemplate-delete') ? 'Delete Template' : adminLabels.DONT_ALLOW}</Popover>}>
                  <span>
                    {
                      hasPermission('transmittalTemplate-delete') &&
                      <button type='button' className="deleteIcon ml-xs orderDetailsAction" onClick={() => this.deleteConfirmTransmittalTemplate(value)}>&nbsp;</button>
                    }
                    {
                      !hasPermission('transmittalTemplate-delete') &&
                      <button type='button' className="deleteIcon ml-xs orderDetailsAction button_disabled" >&nbsp;</button>
                    }
                  </span>
                </OverlayTrigger> */}
              </div>
            ),
            className: "action-center",
            sortable: false
          }
        ]
      }
    ];
    const options = [
      {
        value: '',
        label: 'All'
      },
      {
        value: '1',
        label: 'Active'
      },
      {
        value: '0',
        label: 'In-Active'
      }
    ]
    return (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                ctTableResponsive
                content={
                  <div>
                    <Grid fluid>
                      <Row>
                        <Col md={12}>
                          <div className="secHeading">
                            {editBrand ? (editBrand.title + ' ') : ''}Template List
                          </div>
                        </Col>
                        <Col md={12}>
                          <div className="flexElem flexResponsive" style={{ "alignItems": "flex-start", "justifyContent": "space-between" }}>
                            <div className="flexElem">
                              <OpenModalButton
                                openModal={hasPermission('transmittalTemplate-create') ? openModal.bind(this, "addModalTransmittalTemplate") : null}
                                classButton={['backButton pt-sm no_radius pb-sm primary mt-none btn-block btn-info btn mr-md', !hasPermission('transmittalTemplate-create') ? 'button_disabled' : '']}
                                buttonName="Add Template"
                                tooltipText={hasPermission('transmittalTemplate-create') ? 'Add Template' : adminLabels.DONT_ALLOW}
                                classIcon={['fa', 'fa-plus']}
                              />
                              <Select
                                isSearchable={false}
                                isMulti={false}
                                value={options.map(item => {
                                  if (item.value === this.state.brand_status) {
                                    return item;
                                  }
                                })}
                                styles={{
                                  container: (provided) => {
                                    return {
                                      ...provided,
                                      width: '100%',
                                      minWidth: '200px'
                                    }
                                  },
                                  menu: (provided) => {
                                    return {
                                      ...provided,
                                      width: '200px'
                                    }
                                  }
                                }}
                                options={options}
                                onChange={this.selectChangeHandler}
                                name='brand_status'
                              />
                              {/* <select value={this.state.brand_status} className='form-control ml-md' name="brand_status" id="" onChange={this.selectChangeHandler}>
                                <option value="1">Active</option>
                                <option value="0">In-Active</option>
                                <option value="">All</option>
                              </select> */}
                            </div>
                            {/* <div className="custom-search-input text-right">
                              <FormInputs
                                ncols={["col-md-12"]}
                                onChange={this.filterSearchHandle}
                                proprieties={[
                                  {
                                    type: "text",
                                    bsClass: "form-control",
                                    placeholder: "Search Templates",
                                    onChange: this.filterSearchHandle,
                                    name: "filter"
                                  }
                                ]}
                              />
                            </div> */}
                          </div>
                        </Col>
                        {/* <Col md={12} className="mt-md">
                          <ReactTable
                            ref={(table) => this.table = table}
                            noDataText='No transmittal Template found'
                            data={transmittalTemplatesData}
                            pages={transmittalTemplatesPages}
                            columns={columns}
                            filtered={filtered}
                            defaultPageSize={50}
                            loading={loadingBrand}
                            className="-striped listing"
                            loadingText={'Loading...'}
                            pageData={this.transmittalTemplatesPaginationData}
                            manual
                            onFetchData={(state, instance) => {
                              var sort = state.sorted.length === 0 ? '' : state.sorted[0].id + ',desc:' + state.sorted[0].desc;
                              let status = this.state.brand_status;
                              state.pageData(state.page + 1, state.filtered, sort, state.pageSize, status);
                            }}
                          />
                        </Col> */}
                        <Pagination
                          ref={(p) => this.pagination = p}
                          showPagination={false}
                          noDataText='No transmittal Template found'
                          columns={columns}
                          pageSize={50}
                          getDataCall={this.paginationCall}
                          filterView={true}
                          // downloadData={true}
                          // downloadFileName={'Orders'}
                          // lowerContent = {null}
                          filterPlaceHolder={'Templates'}
                          getRowProps={this.getRowProps}
                        // showAllToggle={true}
                        />
                      </Row>
                    </Grid>

                    {/*Add TransmittalTemplate Modal Start*/}
                    <ThemeModal modalClass="" title='Add Template' show={this.state.addModalTransmittalTemplate} hide={() => { this.toggleModal('addModalTransmittalTemplate') }} >
                      <TransmittalTemplateForm
                        formTypePro="add"
                        transmittalTemplatePro={{ ...OC.TRANSMITTALTEMPLATE, account_id: accountId }}
                        closeModel={closeModalAfterApi.bind(this, "addModalTransmittalTemplate", "onCloseAfterApi")}
                        is_master={is_master}
                      />
                    </ThemeModal>
                    {/*Add TransmittalTemplate Modal End*/}

                    {/*Edit TransmittalTemplate Modal start*/}
                    {
                      editTransmittalTemplate &&
                      <ThemeModal modalClass="" title='Edit Template' show={this.state.editModalTransmittalTemplate} hide={() => { this.toggleModal('editModalTransmittalTemplate') }} >
                        <TransmittalTemplateForm
                          formTypePro="edit"
                          transmittalTemplatePro={editTransmittalTemplate}
                          closeModel={closeModalAfterApi.bind(this, "editModalTransmittalTemplate", "onCloseAfterApi")}
                          is_master={is_master}
                        />
                      </ThemeModal>
                    }
                    {/*Edit TransmittalTemplate Modal End*/}
                  </div>
                } />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  };
};

const mapDispatchToProps = dispatch => {
  return ({
    getTransmittalTemplatesPaginationCall: (accountId, page, filter, sort, pageSize, status) => { return dispatch(getTransmittalTemplatesPagination(accountId, page, filter, sort, pageSize, status)) },
    getTransmittalTemplateCall: (accountId, id) => { dispatch(getTransmittalTemplate(accountId, id)) },
    deleteTransmittalTemplateCall: (accountId, id) => { return dispatch(deleteTransmittalTemplate(accountId, id)) },
    handleTransmittalTemplateStatusCall: (transmittalTemplateId, data) => { return dispatch(updateTransmittalTemplateStatus(transmittalTemplateId, data)) },
    getBrandCall: (id) => { dispatch(getBrand(id)) },
  });
};

function mapStateToProps(state) {
  const { editBrand, editTransmittalTemplate, loadingBrand, transmittalTemplatesData, transmittalTemplatesPages, transmittalTemplatesCurrent_page } = state.brandsReducer;
  return {
    editBrand, editTransmittalTemplate, loadingBrand, transmittalTemplatesData, transmittalTemplatesPages, transmittalTemplatesCurrent_page
  };
};

const TransmittalTemplates = connect(mapStateToProps, mapDispatchToProps)(TransmittalTemplatesListPage);
export default TransmittalTemplates;
