import React, { Component } from 'react'
import { connect } from 'react-redux'
import { resetPassword, login } from '../../redux/actions/front-end-action';
import qs from 'query-string'

class Reset extends Component {
    constructor(props) {
        super(props);
        this.state = {
            password: '',
            password_confirmation: '',
            token: '',
            username: ''
        }
    }
    componentDidMount() {
        const username = qs.parse(this.props.location.search).username;
        const { token } = this.props.match.params
        if (username) {
            this.setState({ username, token })
        }
    }

    reset = () => {
        const data = this.state;
        this.props.resetPassword(data).then(
            res => {
                const { user } = res;
                setTimeout(() => {
                    this.props.loginCall(user.username, this.state.password)
                }, 1000);
            },
            err => {

            }
        )
    }

    render() {
        console.log(this.state);
        return (
            <div className="main_login">
                <div className="login_form_container">
                    <div className="login_form text-center">
                        <h1 className="text-center">Reset Password</h1>
                        <form name="form">
                            <input placeholder='password' className='form-control' type="text" onChange={(e) => { this.setState({ password: e.target.value }) }} name="password" id="" />
                            <input placeholder='confirm password' className='form-control mt-md' type="text" onChange={(e) => { this.setState({ password_confirmation: e.target.value }) }} name="password_confirmation" id="" />
                            <div>
                                <button className="btn btn-default btn_login mt-lg" type='button' onClick={this.reset}>reset</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        resetPassword: (data) => dispatch(resetPassword(data)),
        loginCall: (email, password) => { dispatch(login(email, password)) },
    }
}

export default connect(null, mapDispatchToProps)(Reset)
