import React, { Component } from "react";
import { connect } from 'react-redux';
import OpenModalButton from 'components/ThemeComponents/openModelButton';
import { openModal, s3StaticPath } from 'helper/helperFunctions';
var { isEmpty } = require('lodash');

class ShippingTracking extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		const { shipping_details } = this.props;
		return(
			<div className="form_tile">
				<div className="form_tile_heading">
                    <img src={s3StaticPath('img/shipping_information.png')} alt="Dates" />
                    <span>Shipping Tracking</span>
                </div>

                <div className="">
                    <div className="row">
                        {
                            shipping_details.camera_ship_status != null &&
                            <div className="col-md-12">
                                <div className="col-md-4 mt-sm mb-sm">Pre-Trip Materials Ship Date</div>
                                <div className="col-md-8" style={{backgroundColor: 'whiteSmoke'}}>
                                    {
                                        shipping_details.camera_ship_status != null &&
                                            'shipped_date' in shipping_details.camera_ship_status != null
                                            ?
                                            <div className="mt-md">
                                                <strong className="innerLabel text-medium">Shipped Date: </strong>
                                                <span>
                                                    {new Date(shipping_details.camera_ship_status.shipped_date).toDateString()}
                                                </span>
                                            </div>
                                            :
                                            null
                                    }
                                    {
                                        shipping_details.camera_ship_status != null &&
                                            !isEmpty(shipping_details.camera_ship_status.shipping_method) ?
                                            <div className="mb-md">
                                                <strong className="innerLabel text-medium">Shipping Method: </strong>
                                                {shipping_details.camera_ship_status.shipping_method.title}
                                            </div>
                                            :
                                            null
                                    }
                                </div>
                            </div>
                        }
                    </div>

                    <div className="row">
                        {
                            shipping_details.video_ship_status != null &&
                            <div className="col-md-12">
                                <div className="col-md-4 mt-sm mb-sm">Video Ship Date</div>
                                <div className="col-md-8" style={{backgroundColor: 'whiteSmoke'}}>
                                    {
                                        shipping_details.video_ship_status != null &&
                                        'shipped_date' in shipping_details.video_ship_status != null
                                            ?
                                            <div className="mt-md">
                                                <strong className="innerLabel text-medium">Shipped Date: </strong>
                                                <span>
                                                    {new Date(shipping_details.video_ship_status.shipped_date).toDateString()}
                                                </span>
                                            </div>
                                            :
                                            null
                                    }
                                    {
                                        shipping_details.video_ship_status !== null &&
                                            !isEmpty(shipping_details.video_ship_status.shipping_method) ?
                                            <div className="mb-md">
                                                <strong className="innerLabel text-medium">Shipping Method: </strong>
                                                {shipping_details.video_ship_status.shipping_method.title}
                                                <span>
                                                    {
                                                    shipping_details.video_ship_status != null ?
                                                        shipping_details.video_ship_status.shipping_method != null ?
                                                            shipping_details.video_ship_status.tracking_number != null && shipping_details.video_ship_status.shipping_method.tracking_url != null ?
                                                                <a rel="noopener noreferrer text-medium" target='_blank' href={shipping_details.video_ship_status.shipping_method.tracking_url} className='trackIcon orderDetailsAction blue_link underline' >&nbsp;Track</a>
                                                                : null
                                                            : null
                                                        : null
                                                    }
                                                </span>
                                            </div>
                                            :
                                        null
                                    }


                                        {/* {
                                            shipping_details.video_ship_status != null ?
                                                shipping_details.video_ship_status.shipping_method != null ?
                                                    shipping_details.video_ship_status.tracking_number != null && shipping_details.video_ship_status.shipping_method.tracking_url != null ?
                                                        <a rel="noopener noreferrer text-medium" target='_blank' href={shipping_details.video_ship_status.shipping_method.tracking_url} className='trackIcon orderDetailsAction blue_link' >Track</a>
                                                        : null
                                                    : null
                                                : null
                                        } */}
                                </div>
                            </div>
                        }
                    </div>

                </div>
				{/* <table className='table mt-none orderTable ship-status-table' style={{ borderCollapse: 'separate', borderSpacing: '2px' }} >
					<tbody >
						{
							shipping_details.camera_ship_status != null &&
							<tr>
								<td className="td_heading" style={{ "verticalAlign": "top" }}>Pre-Trip Materials Ship Date</td>
								<td>
									<div className='row flexElem align-items-center'>
										{
											<div className='col-md-12 text-medium'>
												{
													shipping_details.camera_ship_status != null &&
														'shipped_date' in shipping_details.camera_ship_status != null
														?
														<div>
															<strong className="innerLabel text-medium">Shipped Date: </strong>
															<span>
                                                                {new Date(shipping_details.camera_ship_status.shipped_date).toDateString()}
                                                            </span>
														</div>
														:
														null
												}
												{
													shipping_details.camera_ship_status != null &&
														!isEmpty(shipping_details.camera_ship_status.shipping_method) ?
														<div>
															<strong className="innerLabel text-medium">Shipping Method: </strong>
															{shipping_details.camera_ship_status.shipping_method.title}
														</div>
														:
														null
												}
												{shipping_details.camera_ship_status != null &&
													'process_date' in shipping_details.camera_ship_status != null ?
													<div>
														<strong className="innerLabel">Processed Date: </strong>
														{new Date(shipping_details.camera_ship_status.process_date).toDateString()}
													</div>
													:
													null
												}
											</div>
										}

									</div>
								</td>
							</tr>
						}
						{
							shipping_details.video_ship_status != null &&
							<tr>
								<td className="td_heading" style={{ "verticalAlign": "top" }}>Video Ship Date</td>
								<td>
									<div className='row flexElem align-items-center' >
										<div className='col-md-12 text-medium' >
											{shipping_details.video_ship_status != null &&
												'shipped_date' in shipping_details.video_ship_status != null ?
												<div className="mt-md mb-md">
													<strong className="innerLabel text-medium">Shipped Date: </strong>
													<span>
                                                        {new Date(shipping_details.video_ship_status.shipped_date).toDateString()}
                                                    </span>
												</div>
												:
												null
											}
											{
												shipping_details.video_ship_status !== null &&
													!isEmpty(shipping_details.video_ship_status.shipping_method) ?
													<div className="mt-md mb-md">
														<strong className="innerLabel text-medium">Shipping Method: </strong>
														{shipping_details.video_ship_status.shipping_method.title}
													</div>
													:
													null
											}
										</div>
										<div className='col-md-3 text-right' >

											{
												shipping_details.video_ship_status != null ?
													shipping_details.video_ship_status.shipping_method != null ?
														shipping_details.video_ship_status.tracking_number != null && shipping_details.video_ship_status.shipping_method.tracking_url != null ?
															<a rel="noopener noreferrer text-medium" target='_blank' href={shipping_details.video_ship_status.shipping_method.tracking_url} className='trackIcon orderDetailsAction blue_link' >Track</a>
															: null
														: null
													: null
											}
										</div>
									</div>
								</td>
							</tr>

						}
					</tbody>
				</table> */}

			</div>
		)
	}
}




export default ShippingTracking;
