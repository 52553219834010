import React, { Component } from 'react'
import { connect } from 'react-redux'
import { receiveOrder } from '../../../redux/actions/order-actions'
import { Grid, Row, Col } from 'react-bootstrap'
import Card from '../../Card/Card'
import { Link, Redirect } from 'react-router-dom'
import { inputChangeHandler } from '../../../helper/helperFunctions'
import { Status } from '../../../constants/Status'

class Receive extends Component {

    constructor(props) {
        super(props)

        this.state = {
            barcode: '',
            status: undefined
        }
        this.inputChangeHandler = inputChangeHandler.bind(this);
    }

    receiveOrderHandler = () => {
        const barcode = this.state.barcode
        this.props.receiveOrder({ barcode });
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.order_receive_status !== prevProps.order_receive_status) {
            this.setState({
                status: true
            })
        }
    }


    render() {
        const { order_receive_status } = this.props;
        return (
            <div className='content'>
                <Grid fluid>
                    <Row>
                        <Col md={12}>
                            <Card content={
                                <React.Fragment>
                                    <div className="secHeading">Receive Order</div>
                                    <h6>Use these options to receive cameras coming in from groups</h6>
                                    <label htmlFor="">Please scan or enter barcode</label>
                                    <input onChange={(e) => { this.inputChangeHandler(e, /^\d{0,13}$/) }} value={this.state.barcode} type="text" name="barcode" id="" className='form-control w-25' />
                                    <button onClick={this.receiveOrderHandler} className='customBtn btn btn-primary btn btn-default mt-md'>Submit</button>
                                    {
                                        this.state.status !== undefined &&
                                        order_receive_status &&
                                        (!order_receive_status.cameraShipped && order_receive_status.status !== Status.CANCELLED) &&
                                        <p className='mt-md'>THE CAMERAS FOR ORDER <strong>{this.state.barcode}</strong> HAVE NOT BEEN SHIPPED OUT. YOU CAN NOT RECEIVE IT YET</p>
                                    }
                                    {
                                        this.state.status !== undefined &&
                                        order_receive_status &&
                                        (order_receive_status.status !== Status.CANCELLED && order_receive_status.equipmentShipped) &&
                                        <p className='mt-md'>ORDER HAS ALREADY BEEN RECEIVED <Link className='blue_link' to={`/Admin/${order_receive_status.order_id}/lab-production-report`}>EDIT/PRINT LAB PROCESSING REPORT</Link></p>
                                    }
                                    {
                                        this.state.status !== undefined &&
                                        order_receive_status &&
                                        (order_receive_status.status !== Status.CANCELLED && !order_receive_status.equipmentShipped) &&
                                        <Redirect to={`/Admin/${order_receive_status.order_id}/lab-production-report`} />
                                    }
                                </React.Fragment>
                            } />
                        </Col>
                    </Row>
                </Grid>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { order_receive_status } = state.ordersReducer;
    return {
        order_receive_status
    }
}

const mapDispatchToProps = dispatch => {
    return {
        receiveOrder: (data) => { dispatch(receiveOrder(data)) },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Receive)
