import { alertConstants } from '../constant/alert-constant';
import { alertService } from "../../services/alert";

export const alertActions = {
    success,
    error,
    clear,
    errorNotify,
    successAutoCloseFalse,
    errorAutoCloseFalse,
};

function success(message, notiType='default') {
    return { type: alertConstants.SUCCESS, message, notiType };
}

function successAutoCloseFalse(message, notiType='default') {
    return { type: alertConstants.SUCCESS_AUTO_CLOSE_OFF, message, notiType };
}

function error(message, notiType='default') {
    return { type: alertConstants.ERROR, message, notiType };
}


function errorAutoCloseFalse(message, notiType='default') {
    return { type: alertConstants.ERROR_AUTO_CLOSE_OFF, message, notiType };
}


function clear() {
    return { type: alertConstants.CLEAR };
}

function errorNotify(error) {
	// console.log('error', error);
	return dispatch => {
        alertService.errorNotify(error).then(
            response => {
                // dispatch(success(response))
            },
            error => {
                // dispatch(failure(error))
            }
        )
    }
}