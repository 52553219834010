import React, { Component } from "react";
import { connect } from 'react-redux';
import { createLabelWithBarcode } from "redux/actions/report-actions";
import SimpleReactValidator from "simple-react-validator";
import { Row, Col, Grid } from "react-bootstrap";
import { Card } from "components/Card/Card.jsx";
import { withRouter } from 'react-router-dom';

class CreateLabel extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator({ autoForceUpdate: this, locale: 'en' });
        this.state = {
            barcode: '',
            address: false
        }
    }

    inputHandler = (e) => {
        const { name, value, type } = e.target;
        if (type === 'checkbox') {
            this.setState({
                [name]: !this.state.address
            })
        } else {
            this.setState({
                [name]: value
            })
        }
    }
    handleOnSubmit = async (e) => {
        if (this.validator.allValid()) {
            const res = await this.props.createLabelWithBarcodeCall({ barcode: this.state.barcode, TO_address_status: this.state.address });
            if (res.data.status) {
                window.open(`${res.data.order.base_url}/printOrderLabel/${res.data.order.id}?order_page=${true}`, '_blank');
                // this.props.history.push()
                // href={`${this.props.base_url}/printOrderLabel/${this.props.orders}?order_page=${this.props.order_page}`} 
                // <PrintOrderLabel base_url={this.props.base_url} className='appCardBtn' order_page={true} orders={res.data.order} text='(RE) Generate Barcode Label' />
            }
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }
    render() {
        return (
            <div className="content">
                <Grid fluid>
                    <Row>
                        <Col md={12}>
                            <Card
                                ctTableResponsive
                                content={
                                    <div>
                                        <Grid fluid>
                                            <div className="row">
                                                <div className="col-md-4 col-md-offset-4 mt-lg">
                                                    <div style={{ color: 'blue' }}>{this.props.orderReceiveMessage}</div>
                                                    <div>Enter Barcode to Create Label: </div>
                                                    <div>
                                                        <input type="text" className="form-control mt-xs" name="barcode" onChange={this.inputHandler} />
                                                        {
                                                            this.validator.message('Barcode', this.state.barcode, 'required')
                                                        }
                                                    </div>
                                                    <div className="flexElem alignCenter mt-md mb-xlg">
                                                        <div className="block mr-md">
                                                            <button className='customBtn btn btn-primary block' onClick={this.handleOnSubmit}>Create Label</button>
                                                        </div>

                                                        <div className="block ml-md">
                                                            <label htmlFor="ToAddress" className="container-check mb-none">
                                                                <input type="checkbox" id="ToAddress" value={this.state.address} name='address' onChange={this.inputHandler} /> T/O Address
                                                                <span className="checkmark"></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Grid>
                                    </div>
                                } />
                        </Col>
                    </Row>
                </Grid>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return ({
        createLabelWithBarcodeCall: (data) => { return dispatch(createLabelWithBarcode(data)) },//example function
    });
};

function mapStateToProps(state) {
    const { orderReceivedStatus, orderReceiveMessage, order } = state.reportReducer;
    return {
        orderReceivedStatus, orderReceiveMessage, order
    };
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CreateLabel));