import Select from "react-select";
import React, { useEffect, useRef, useState } from "react";
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";
import moment from "moment";
import { Row, Col } from 'react-bootstrap'
import SimpleReactValidator from "simple-react-validator";
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import GooglePlacesAutocomplete from "./GooglePlacesAutocomplete";

// const format = "HH:mm";
const format = 'h:mm a';

const now = moment()
    .hour(0)
    .minute(0);

export const ItineraryForm = props => {
    const [itinerary_data, setItineraryData] = useState({
        ...props.selected_itinerary
    });
    const [, forceUpdate] = useState();
    const validator = useRef(
        new SimpleReactValidator({
            autoForceUpdate: { forceUpdate: forceUpdate }
        })
    );
    const [value, setValue] = useState(null);

    function inputHandler(e) {
        const { name, value } = e.target;
        setItineraryData({
            ...itinerary_data,
            [name]: value
        });
    }

    function selectHandler(place) {
        // setValue(value);
        setItineraryData({
            ...itinerary_data,
            ...place
        });
    }

    function handleTimePicker(timeObj, name) {
        const time = timeObj ? timeObj.format(format) : null;
        const temp_itinerary = { ...itinerary_data };

        if (name === 'start_time') {
            temp_itinerary.end_time = null;
            temp_itinerary.end_time_string = ""
        }

        temp_itinerary[name] = timeObj ? moment(timeObj.format(format), format) : null;
        temp_itinerary[name + "_string"] = timeObj ? timeObj.format(format) : "";

        if (name === 'end_time' && timeObj) {
            const hours_arr = disabledHours();
            const minutes_arr = disabledMinutes(timeObj.hours());
            if (hours_arr.includes(timeObj.hours())) {
                temp_itinerary.end_time = null;
                temp_itinerary.end_time_string = ""
            }
            if(minutes_arr.includes(timeObj.minutes())) {
                temp_itinerary.end_time = null;
                temp_itinerary.end_time_string = ""
            }
        }

        setItineraryData({
            ...temp_itinerary
        });
    }

    async function submitForm(e, confirm = false) {
        e.preventDefault();
        if (validator.current.allValid()) {
            try {
                if (!props.editing) {
                    props.return_submitted_data(itinerary_data);
                } else {
                    // ? setting the editing flag true
                    props.return_submitted_data(itinerary_data, true);
                }
                // props.closeModel();
            } catch (e) {
                // props.errorAlert(e);
                console.log("Error while duplicating", e);
            }
        } else {
            validator.current.showMessages();
            forceUpdate(1);
        }
    }

    const render = status => {
        // if (status === Status.FAILURE) return "asd";
        return "sping";
    };

    useEffect(() => {
        setItineraryData({ ...props.selected_itinerary });
    }, [props.selected_itinerary]);

    function generateOptions(length, excludedOptions) {
        const arr = [];
        for (let value = 0; value < length; value++) {
            if (excludedOptions.indexOf(value) < 0) {
                arr.push(value);
            }
        }
        return arr;
    }

    function disabledHours() {
        const { start_time } = itinerary_data;
        const hour = start_time.hour();
        const disabled_hours = ([...Array(hour).keys()].map(x => x++));
        return disabled_hours;
    }

    function disabledMinutes(h) {
        const { start_time } = itinerary_data;
        const minutes = start_time.minutes();
        const hour = start_time.hour();
        const disabled_minutes = [];
        const excluded_minutes = ([...Array(60).keys()].map(x => x++));
        for (let i = minutes + 1; i < 60; i++) {
            disabled_minutes.push(i);
        }
        if (h === hour) {
            return generateOptions(60, disabled_minutes);
        } else {
            return generateOptions(60, excluded_minutes);
        }
    }

    return (
        <>
            <form className="mb0">
                <div>
                    <Wrapper
                        apiKey={process.env.REACT_APP_GOOGLE_API}
                        render={render}
                        libraries={["places"]}
                    >
                        <GooglePlacesAutocomplete
                            view_mode={props.view_mode}
                            getPlaceData={selectHandler}
                            destination_name={itinerary_data.destination_name}
                        />
                    </Wrapper>
                </div>
                <Row>
                    <Col sm={12} md={6}>
                        <label className="mt-lg">Start Time</label>
                        <div className="w-100">
                            <TimePicker
                                showSecond={false}
                                defaultValue={now}
                                value={itinerary_data.start_time}
                                onChange={value => {
                                    handleTimePicker(value, "start_time");
                                }}

                                format={format}
                                disabled={props.view_mode}
                                use12Hours
                                className="input w-100"
                            />
                            {validator.current.message( "Start Time", itinerary_data.start_time, "required")}
                        </div>
                    </Col>
                    <Col sm={12} md={6}>
                        <label className="mt-lg">End Time</label>
                        <div className="w-100">
                            <TimePicker
                                showSecond={false}
                                defaultValue={now}
                                value={itinerary_data.end_time}
                                onChange={value => {
                                    handleTimePicker(value, "end_time");
                                }}
                                format={format}
                                disabledHours={disabledHours}
                                disabledMinutes={disabledMinutes}
                                use12Hours
                                disabled={props.view_mode || (!props.view_mode && itinerary_data.start_time ? false : true)}
                                inputReadOnly
                                className="input w-100"
                            />
                            {validator.current.message(
                                "End Time",
                                itinerary_data.end_time,
                                "required"
                            )}
                        </div>
                    </Col>
                    <Col sm={12}>
                        <label className="mt-lg">Description</label>
                        <textarea
                            type="text"
                            name="description"
                            className="form-control" rows={6}
                            placeholder="Type here"
                            disabled={props.view_mode}
                            value={itinerary_data.description || ''}
                            onChange={inputHandler}
                        />
                    </Col>
                </Row>

                <div className="places plavesWalls mt-lg">
                    {itinerary_data.photos &&
                        itinerary_data.photos.length > 0 &&
                        itinerary_data.photos.map((photo, index) => {
                            return <img src={photo.thumb_path} key={index} />;
                        })}
                </div>
                <div className="flexCenter mt-lg">
                    <button className="addDayBtn" onClick={submitForm} disabled={props.view_mode}>
                        Save
                    </button>
                    <button className="addDayBtn" type="button" onClick={() => { props.closeModal() }}>
                        Close
                    </button>
                </div>

            </form>
        </>
    );
};
