import { adminConstants } from '../constant/admin-constant';
import { alertActions } from './alert-actions';
import { agencyResourceService } from '../../services/agencyResource';
import { frontEndUserConstants } from '../constant/front-end-constant';

export function storeAgencyResource(agencyId, AgencyResourceData) {
    return dispatch => {
        dispatch(request());
       return agencyResourceService.storeAgencyResource(agencyId, AgencyResourceData)
            .then(
                response => {
                    dispatch(success(response));
                    dispatch(alertActions.success(response.message));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    }

    function request() { return { type: adminConstants.STORE_AGENCY_RESOURCE_REQUEST } }
    function success(response) { return { type: adminConstants.STORE_AGENCY_RESOURCE_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.STORE_AGENCY_RESOURCE_FAILURE, payload: error } }
}


export function getAgencyResource(agency_id, id) {
    return dispatch => {
        dispatch(request());
        agencyResourceService.getAgencyResource(agency_id, id)
            .then(
                AgencyResource => {
                    dispatch(success(AgencyResource));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    }

    function request() { return { type: adminConstants.GET_AGENCY_RESOURCE_REQUEST } }
    function success(AgencyResource) { return { type: adminConstants.GET_AGENCY_RESOURCE_SUCCESS, payload: AgencyResource } }
    function failure(error) { return { type: adminConstants.GET_AGENCY_RESOURCE_FAILURE, payload: error } }
}

export function editAgencyResource(agencyId, AgencyResourceData) {

    return dispatch => {
        dispatch(request());
        agencyResourceService.editAgencyResource(agencyId, AgencyResourceData)
            .then(
                response => {
                    dispatch(success(response));
                    dispatch(alertActions.success(response.message));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    }

    function request() { return { type: adminConstants.EDIT_AGENCY_RESOURCE_REQUEST } }
    function success(response) { return { type: adminConstants.EDIT_AGENCY_RESOURCE_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.EDIT_AGENCY_RESOURCE_FAILURE, payload: error } }
}


export function deleteAgencyResource(agency_id, id) {
    return dispatch => {
        dispatch(request());
         return agencyResourceService.deleteAgencyResource(agency_id, id)
            .then(
                response => {
                    dispatch(success(response));
                    dispatch(alertActions.success(response.message));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    }

    function request() { return { type: adminConstants.DELETE_AGENCY_RESOURCE_REQUEST } }
    function success(response) { return { type: adminConstants.DELETE_AGENCY_RESOURCE_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.DELETE_AGENCY_RESOURCE_FAILURE, payload: error } }
}

export function getAgencyResourcesPagination(agency_id) {
    return dispatch => {
        dispatch(request())
        agencyResourceService.getAgencyResourcesPagination(agency_id)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request() { return { type: adminConstants.PAGINATION_AGENCY_RESOURCES_REQUEST } }
    function success(response) { return { type: adminConstants.PAGINATION_AGENCY_RESOURCES_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.PAGINATION_AGENCY_RESOURCES_FAILURE, payload: error } }
}
            //  <----------------------- Store Agency Documents ------------------> //

export function storeAgencyDocument(agencyId, AgencyDocumentData) {
    return dispatch => {
        dispatch(request());
        return agencyResourceService.storeAgencyDocument(agencyId, AgencyDocumentData)
            .then(
                response => {
                    dispatch(success(response));
                    dispatch(alertActions.success(response.message));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    }

    function request() { return { type: adminConstants.STORE_AGENCY_DOCUMENTS_REQUEST } }
    function success(response) { return { type: adminConstants.STORE_AGENCY_DOCUMENTS_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.STORE_AGENCY_DOCUMENTS_FAILURE, payload: error } }
}
            //  <-----------------------Agency Documents Pagination ------------------> //

export function getAgencyDocumentsPagination(agency_id) {
    return dispatch => {
        dispatch(request())
        return agencyResourceService.getAgencyDocumentsPagination(agency_id)
            .then(
                response => {
                    dispatch(success(response.data));
                    return response.data;
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request() { return { type: adminConstants.PAGINATION_AGENCY_DOCUMENTS_REQUEST } }
    function success(response) { return { type: adminConstants.PAGINATION_AGENCY_DOCUMENTS_SUCCESS, payload: response.data } }
    function failure(error) { return { type: adminConstants.PAGINATION_AGENCY_DOCUMENTS_FAILURE, payload: error } }
}

            //  <-----------------------Delete Agency Documents ------------------> //

export function deleteAgencyDocument(agency_id, id) {
    return dispatch => {
        dispatch(request());
            return agencyResourceService.deleteAgencyDocument(agency_id, id)
            .then(
                response => {
                    dispatch(success(response));
                    dispatch(alertActions.success(response.message));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    }

    function request() { return { type: adminConstants.DELETE_AGENCY_DOCUMENTS_REQUEST } }
    function success(response) { return { type: adminConstants.DELETE_AGENCY_DOCUMENTS_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.DELETE_AGENCY_DOCUMENTS_FAILURE, payload: error } }
}

export function getAgencyResourcesTypes(agency_id) {
    return dispatch => {
        dispatch(request())
        agencyResourceService.getAgencyResourcesTypes(agency_id)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request() { return { type: adminConstants.GET_AGENCY_RESOURCE_TYPE_REQUEST } }
    function success(response) { return { type: adminConstants.GET_AGENCY_RESOURCE_TYPE_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.GET_AGENCY_RESOURCE_TYPE_FAILURE, payload: error } }
}

export function getResourcesByRole(agency_id, order_id = null) {
    return dispatch => {
        agencyResourceService.getResourcesByRole(agency_id, order_id)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function success(response) { return { type: frontEndUserConstants.GET_AGENCY_RESOURCES_BY_ROLE_SUCCESS, payload: response } }
    function failure(error) { return { type: frontEndUserConstants.GET_AGENCY_RESOURCES_BY_ROLE_FAILURE, payload: error } }
}

export function getOrdersResourcesByRole(order_id) {
    return dispatch => {
        agencyResourceService.getOrdersResourcesByRole(order_id)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function success(response) { return { type: adminConstants.GET_ORDERS_RESOURCES_BY_ROLE_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.GET_ORDERS_RESOURCES_BY_ROLE_FAILURE, payload: error } }
}

export function getAgencyResourceByType(type, agency_id) {
    return dispatch => {
        return agencyResourceService.getAgencyResourceByType(type, agency_id);
    }
}
