import React, { Component } from "react";
import { returnExtFromImage } from "helper/helperFunctions";
import { toast } from 'react-toastify';
import AWS from 'aws-sdk';
import parseHtml from 'html-react-parser'
var FileSaver = require('file-saver');
var JSZip = require('jszip');
var JSZipUtils = require('jszip-utils');
AWS.config.update({
	// apiVersion: "2010-12-01",
	accessKeyId: 'AKIAJH3U26XFHTAHOHDA',
	accessSecretKey: '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',
	region: "us-east-1"
});

class downloadFile extends Component {
	constructor(props) {
		super(props);
		this.downloadFile = this.downloadFile.bind(this);
		this.makezip = this.makezip.bind(this);
	}
	async urlToPromise(url) {
		return await new Promise(function (resolve, reject) {
			JSZipUtils.getBinaryContent(url, function (err, data) {
				if (err) {
					reject(err);
				} else {
					resolve(data);
				}
			});
		});
	}

	downloadImage = (url) => {
		let urlArray = url.split("/")
		let bucket = urlArray[2];
		let key = `${urlArray[3]}/${urlArray[4]}/${urlArray[5]}/${urlArray[6]}/${urlArray[7]}/${urlArray[8]}`
		let s3 = new AWS.S3({ params: { Bucket: bucket } })
		let params = { Bucket: bucket, Key: key }
		s3.getObject(params, (err, data) => {
			if (err) {
				console.log('err from s3', err);
			}
			let blob = new Blob([data.Body], { type: data.ContentType });
			let link = document.createElement('a');
			link.href = window.URL.createObjectURL(blob);
			link.download = url;
			link.click();
		})
	}
	async downloadFile() {
		const { files, multiple, downloadName } = this.props;
		let toastIdImg = null;
		toastIdImg = toast('Download in progress', {
			type: toast.TYPE.INFO,
			progress: 0,
			position: "bottom-right",
		});
		if (multiple) {
			var zip = new JSZip();
			await this.makezip(files, zip).then(zip=>{
				zip.generateAsync({ type: "blob" }).then(function (content) {
					if (downloadName) {
						FileSaver.saveAs(content, downloadName + '.zip');
					} else {
						FileSaver.saveAs(content, 'All.zip');
					}

					toast.update(toastIdImg, {
						// render: 'Downloading in progress '+ percentage +'%',
						render: 'Downloading completed',
						progress: 1,
						type: toast.TYPE.INFO,

						position: "bottom-right",
						hideProgressBar: false,
					});
					// toast.dismiss(toastIdImg)
				});
			});

		} else {
			FileSaver.saveAs(files['file_path'], files['file_title']);
			toast.update(toastIdImg, {
				render: 'Downloading completed',
				progress: 1,
				type: toast.TYPE.INFO,
				position: "bottom-right",
			});
			toast.dismiss(toastIdImg)
		}
	}
	async makezip(files, zip, folderName = null) {
		/*if folder name exist in props to create new nested folders*/
		if (folderName)
			var tempFolder = zip.folder(folderName);

		for (var i in files) {
			files[i].hasOwnProperty('file_title')
			if (!Array.isArray(files[i])) {
				if (typeof files[i]['file_title'] !== 'undefined' && typeof files[i]['file_path'] !== 'undefined') {
					await this.urlToPromise(files[i]['file_path']).then(response=>{
						if (!folderName)
							zip.file(files[i]['file_title'] + '.' + returnExtFromImage(files[i]['file_path']), response, { base64: true, binary: true });
						else
							tempFolder.file(files[i]['file_title'] + '.' + returnExtFromImage(files[i]['file_path']), response, { base64: true, binary: true });

					}).catch(err=>{

					});
				}
			}
			else if (Array.isArray(files[i])) {
				/*recursive loop for nested folders files*/
				await this.makezip(files[i], zip, i);
			}
		}
		return zip;
	}



	render() {
		const { /*title,*/ extraClass/*, files*/ } = this.props;
		return (
			<React.Fragment>
				{/*<button onClick={ ()=>this.downloadImage(files['file_path']) }>Click me</button>*/}
				<button className={"orderDetailsAction " + (this.props.whiteDownloadIcon ? " " : "downloadIcon ") + extraClass} onClick={this.downloadFile}>{this.props.space?parseHtml(this.props.space):null}{this.props.whiteDownloadIcon ? <i className='fa fa-download'> </i>: null}{this.props.title}</button>
			</React.Fragment>
		);
	}
}

export default downloadFile;
