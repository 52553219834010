import React, { useEffect, useRef, useState } from 'react'

const GooglePlacesAutocomplete = (props) => {
    const autoCompleteRef = useRef();
    const inputRef = useRef();

    const options = {
        fields: ["address_components", "geometry", "icon", "name", 'photos', 'place_id','icon','icon_mask_base_uri','icon_background_color' ],
        types: ["establishment"]
    };

    const photosUrls = async (photos) => {
        let temp_data = [];
        for (let i = 0; i < 5; i++) {
            const photo = photos[i] ? photos[i] : null;
            if (photo) {
                temp_data.push({
                    full_path: photo.getUrl(),
                    thumb_path: photo.getUrl({ maxWidth: 200, maxHeight: 200 }),
                })
            }
        }
        return temp_data;
    }

    useEffect(() => {
        autoCompleteRef.current = new window.google.maps.places.Autocomplete(
            inputRef.current,
            options
        );
        autoCompleteRef.current.addListener("place_changed", async () => {
            try {
                const place = await autoCompleteRef.current.getPlace();
                const lat = place.geometry.location.lat();
                const long = place.geometry.location.lng();
                const photos = await photosUrls(place.photos);
                let country = '';
                place.address_components.forEach(ad_comp => {
                    if (ad_comp.types.includes('country')) {
                        country = ad_comp.long_name;
                    }
                })
                props.getPlaceData({
                    ...place,
                    destination_name: place.name,
                    google_place_id: place.place_id,
                    lat,
                    long,
                    country,
                    photos,
                });
            } catch (e) {
                console.log("🚀 ~ file: ItineraryForm.jsx:211 ~ e", e)
            }
        });
    });

    useEffect(function () {
        inputRef.current.value = props.destination_name || ''
    }, [props.destination_name])

    return (
        <div>
            <input placeholder='Add a place' className='input addPlaceGoogle w-100' ref={inputRef} disabled={props.view_mode} />
        </div>
    )
}

export default GooglePlacesAutocomplete;