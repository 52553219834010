import {
	adminConstants
} from '../constant/admin-constant';

const initialState = {
	loadingOrder: false,
	ordersData: [],
	orderWithStatusData: [],
	reportsData: [],
	todaysData: [],
	accesslogData: [],
	weblogData: [],
	orderBookingData: [],
	daysData: [],
	ucdData: [],
	contactlogData: [],
	OrderLogData: [],
};

export function reportReducer(state = initialState, action) {

	switch (action.type) {

		case adminConstants.GET_ALL_ORDERS_WITH_STATUS_REQUEST:
			return {
				...state,
				loadingOrder: true
			}

		case adminConstants.GET_ALL_ORDERS_WITH_STATUS_SUCCESS:
			return {
				...state,
				orderWithStatusData: action.payload.data.pagination.data,
				OWSpages: action.payload.data.pagination.last_page,
				loadingOrder: false,
				OWScurrent_page: action.payload.data.pagination.current_page,
				OWStotal_orders: action.payload.data.pagination.total,
				ordersCountWithModerator: action.payload.data.ordersCountWithModerator,
			};
		case adminConstants.GET_ALL_ORDERS_WITH_STATUS_FAILURE:
			return {
				...state,
				loadingOrder: false,
				error: action.payload.error
			}

		case adminConstants.GET_ALL_VIDEO_SHIPMENT_REQUEST:
			return {
				...state,
				loadingOrder: true
			}

		case adminConstants.GET_ALL_VIDEO_SHIPMENT_SUCCESS:
			return {
				...state,
				reportsData: action.payload.data.pagination.data,
				REPORTSpages: action.payload.data.pagination.last_page,
				loadingOrder: false,
				REPORTScurrent_page: action.payload.data.pagination.current_page,
				REPORTStotal_orders: action.payload.data.pagination.total,
			};
		case adminConstants.GET_ALL_VIDEO_SHIPMENT_FAILURE:
			return {
				...state,
				loadingOrder: false,
				error: action.payload.error
			}


		case adminConstants.GET_ALL_TODAYS_SHIPMENT_REQUEST:
			return {
				...state,
				loadingOrder: true
			}

		case adminConstants.GET_ALL_TODAYS_SHIPMENT_SUCCESS:
			return {
				...state,
				todaysData: action.payload.data.pagination.data,
				daysData: action.payload.data.days,
				daysTotal: action.payload.data.daysTotal,
				REPORTSpages: action.payload.data.last_page,
				loadingOrder: false,
				REPORTScurrent_page: action.payload.data.current_page,
				REPORTStotal_orders: action.payload.data.total,
			};
		case adminConstants.GET_ALL_TODAYS_SHIPMENT_FAILURE:
			return {
				...state,
				loadingOrder: false,
				error: action.payload.error
			}
		case adminConstants.GET_ALL_WEB_LOG_REQUEST:
			return {
				...state,
				loadingOrder: true
			}

		case adminConstants.GET_ALL_WEB_LOG_SUCCESS:
			return {
				...state,
				weblogData: action.payload.data.pagination.data,
				REPORTSpages: action.payload.data.pagination.last_page,
				loadingOrder: false,
				REPORTScurrent_page: action.payload.data.pagination.current_page,
				REPORTStotal_orders: action.payload.data.pagination.total,
			};
		case adminConstants.GET_ALL_WEB_LOG_FAILURE:
			return {
				...state,
				loadingOrder: false,
				error: action.payload.error
			}

		case adminConstants.GET_ALL_CONTACT_LOG_REQUEST:
			return {
				...state,
				loadingOrder: true
			}

		case adminConstants.GET_ALL_CONTACT_LOG_SUCCESS:
			return {
				...state,
				contactlogData: action.payload.data.data,
				REPORTSpages: action.payload.data.last_page,
				loadingOrder: false,
				REPORTScurrent_page: action.payload.data.current_page,
				REPORTStotal_orders: action.payload.data.total,
			};
		case adminConstants.GET_ALL_CONTACT_LOG_FAILURE:
			return {
				...state,
				loadingOrder: false,
				error: action.payload.error
			}

		case adminConstants.GET_ALL_ACCESS_LOG_REQUEST:
			return {
				...state,
				loadingOrder: true
			}

		case adminConstants.GET_ALL_ACCESS_LOG_SUCCESS:
			return {
				...state,
				accesslogData: action.payload.data.pagination.data,
				REPORTSpages: action.payload.data.pagination.last_page,
				loadingOrder: false,
				REPORTScurrent_page: action.payload.data.pagination.current_page,
				REPORTStotal_orders: action.payload.data.pagination.total,
			};
		case adminConstants.GET_ALL_ACCESS_LOG_FAILURE:
			return {
				...state,
				loadingOrder: false,
				error: action.payload.error
			}

		case adminConstants.GET_ALL_ORDER_BOOKING_REQUEST:
			return {
				...state,
				loadingOrder: true
			}

		case adminConstants.GET_ALL_ORDER_BOOKING_SUCCESS:
			return {
				...state,
				orderBookingData: action.payload.data.pagination.data,
				REPORTSpages: 1,
				loadingOrder: false,
				REPORTScurrent_page: 1,
				REPORTStotal_orders: 1,
			};
		case adminConstants.GET_ALL_ORDER_BOOKING_FAILURE:
			return {
				...state,
				loadingOrder: false,
				error: action.payload.error
			}
		case adminConstants.GET_ALL_SALES_FRECAST_REQUEST:
			return {
				...state,
				loadingOrder: true
			}

		case adminConstants.GET_ALL_SALES_FRECAST_SUCCESS:
			return {
				...state,
				reportsData: action.payload.data.pagination.data,
				REPORTSpages: 1,
				loadingOrder: false,
				REPORTScurrent_page: 1,
				REPORTStotal_orders: 1,
			};
		case adminConstants.GET_ALL_SALES_FRECAST_FAILURE:
			return {
				...state,
				loadingOrder: false,
				error: action.payload.error
			}

		//
		case adminConstants.GET_RECEIVING_REPORTS_REQUEST:
			return {
				...state,
				loadingOrder: true
			}

		case adminConstants.GET_RECEIVING_REPORTS_SUCCESS:
			return {
				...state,
				ordersWithPhotoSubmitData: action.payload.data.orders.data,
				OWPSpages: action.payload.data.orders.last_page,
				loadingOrder: false,
				OWPScurrent_page: action.payload.data.orders.current_page,
				OWPStotal_orders: action.payload.data.orders.total
			};
		case adminConstants.GET_RECEIVING_REPORTS_FAILURE:
			return {
				...state,
				loadingOrder: false,
				error: action.payload.error
			}


		case adminConstants.CHECK_BARCODE_STATUS_REQUEST:
			return {
				...state,
				loadingOrder: true
			}
		case adminConstants.CHECK_BARCODE_STATUS_SUCCESS:
			return {
				...state,
				loadingOrder: false,
				order: action.payload.data,
				orderReceivedStatus: action.payload.data.status,
				orderReceiveMessage: action.payload.message,
			};
		case adminConstants.CHECK_BARCODE_STATUS_FAILURE:
			return {
				...state,
				loadingOrder: false,
				error: action.payload.error
			}

		case adminConstants.GET_ALL_UCD_REACHED_REQUEST:
			return {
				...state,
				loadingOrder: true
			}

		case adminConstants.GET_ALL_UCD_REACHED_SUCCESS:
			console.log('in the report reducer', action.payload.data.data);
			return {
				...state,
				ucdData: action.payload.data.pagination.data,
				ucdpages: action.payload.data.pagination.last_page,
				loadingOrder: false,
				ucdcurrent_page: action.payload.data.pagination.current_page,
				ucdtotal_orders: action.payload.data.pagination.total,
			};
		case adminConstants.GET_ALL_UCD_REACHED_FAILURE:
			return {
				...state,
				loadingOrder: false,
				error: action.payload.error
			}


		case adminConstants.GET_ALL_ORDER_LOG_REQUEST:
			return {
				...state,
				loadingOrder: true
			}

		case adminConstants.GET_ALL_ORDER_LOG_SUCCESS:
			return {
				...state,
				OrderLogData: action.payload.data.pagination.data,
				REPORTSpages: action.payload.data.pagination.last_page,
				loadingOrder: false,
				REPORTScurrent_page: action.payload.data.pagination.current_page,
				REPORTStotal_orders: action.payload.data.pagination.total,
			};
		case adminConstants.GET_ALL_ORDER_LOG_FAILURE:
			return {
				...state,
				loadingOrder: false,
				error: action.payload.error
			}

		case adminConstants.GET_INVOICING_ORDERS_SUCCESS:
			return {
				...state,
				invoicingOrders: action.payload.data.pagination.data
			}

		case adminConstants.GET_RECENTLY_INVOICED_ORDERS_SUCCESS:
			// console.log('pagination.data', action.payload.data.pagination);
			return {
				...state,
				recentlyInvoicedOrders: action.payload.data.pagination.data,
				total_amount: action.payload.data.pagination.total_amount ? action.payload.data.pagination.total_amount : 0,
				total_invoices: action.payload.data.pagination.total_invoices ? action.payload.data.pagination.total_invoices : 0,
				total_orders: action.payload.data.pagination.total_order ? action.payload.data.pagination.total_order : 0
			}

		default:
			return {
				...state
			};
	}
}
