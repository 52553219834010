import React from 'react';
import { connect } from 'react-redux';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import "react-tabs/style/react-tabs.css";



import UploadedImages from './ImagesTab/UploadedImages';
import QualityAssurance from './ImagesTab/QualityAssurance';
import ProductionManager from './ImagesTab/ProductionManager';
import Editor from './ImagesTab/Editor';
import { adminLabels as AL} from 'redux/constant/admin-label-constant';
import { helperFunctions as HF} from 'helper/helperFunctions';
import MyImage from 'components/Upload/MyImage';
const queryString = require('query-string');

class ImagesPage extends React.Component {
	constructor(props){
		super(props);
		const parsed = queryString.parse(window.location.search);
		var tabsImagesArray = [];
		// console.log('parsed', parsed);
		if (HF.hasRole(['admin'])) {
			tabsImagesArray = [
				'uploadedImages',
				'editor',
				'productionManager',
				'qa',
			];
		} else if (HF.hasRole(['editor'])) {
			tabsImagesArray = [
				'uploadedImages',
				'editor',
			];
		} else if (HF.hasRole(['producer'])) {
			tabsImagesArray = [
				'editor',
				'productionManager',
			];
		} else if (HF.hasRole(['qa'])) {
			tabsImagesArray = [
				'productionManager',
				'qa',
			];
		}
		this.state = {
			index:0,
			tabsImagesArray: tabsImagesArray,
			defaultActiveImageTabName: parsed.imageCode ? parsed.imageCode : '',
		};
	};
	componentDidMount() {
		window.scrollTo(0, 0);
		if (this.state.defaultActiveImageTabName) {
			// console.log('test', this.state.defaultActiveImageTabName);
			const index = this.state.tabsImagesArray.findIndex(item => {
				return item === this.state.defaultActiveImageTabName
			});
			// console.log('index', index);
			if (index !== -1) {

				this.setState({ index: index });
				// this.setState({
				//     index: index
				// }, ()=>{

				// });

				// console.log('index state two', this.state.index);
			}
		}
	}

	render(){
		const { orderId, order_details, shipping_details, userAdmin, production_details, allowSave, is_ttk} = this.props;
		return(
			<div className="mt-lg">
				<div id="tabs" className="orderTabs colored">
					<Tabs selectedIndex={this.state.index} onSelect={index => this.setState({ index: index })} >
						<TabList>

							{
								HF.hasRole(['admin', 'editor','Remote Editor','Remote Editor/Producer'])
								&&
								<Tab id="uploadedImages" disabled={allowSave}><MyImage height="38" src={HF.s3StaticPath('img/upload_image.png')} alt="detail icon" /> &nbsp;{AL.UPLOADED_IMAGES}</Tab>
							}
							{
								(
									(
										HF.hasRole(['editor', 'admin','Remote Editor','Remote Editor/Producer']) &&
										(order_details.editor_id === userAdmin.user.id)
									) ||
									(
										HF.hasRole(['admin']) && order_details.editor_id
									) ||
									(
										HF.hasRole(['producer', 'Remote Producer','Remote Editor/Producer'])
									)
								)
							 	&& !is_ttk &&
								<Tab id="editor"><MyImage height="38" src={HF.s3StaticPath('img/editor.png')} alt="detail icon" /> &nbsp;{AL.EDITOR}</Tab>
							}
							{
								(
									(
										order_details.agency_id !== null &&
										( HF.hasRole(['producer', 'admin', 'Remote Producer','Remote Editor/Producer']) && (order_details.producer_id === userAdmin.user.id) )
									) ||
									(
										order_details.agency_id !== null &&
										HF.hasRole(['admin']) && order_details.producer_id
									) ||
									(
										order_details.agency_id !== null &&
										HF.hasRole(['qa'])
									)
								)
								&& !is_ttk &&
								<Tab id="productionManager"><MyImage height="38" src={HF.s3StaticPath('img/production_manager.png')} alt="icon" /> &nbsp;{AL.PRODUCTION_MANAGER}</Tab>
							}
							{
								(
									(
										order_details.agency_id !== null &&
										( HF.hasRole(['qa', 'admin']) && (order_details.qa_id === userAdmin.user.id) )
									) ||
									(
										order_details.agency_id !== null &&
										HF.hasRole(['admin']) && order_details.qa_id
									)
								)
								&& !is_ttk &&
								<Tab id="qa"><MyImage height="38" src={HF.s3StaticPath('img/quality_assurance.png')} alt="setting icon" /> &nbsp;{AL.QUALITY_ASSURANCE}</Tab>
							}
						</TabList>
						{
							HF.hasRole(['admin', 'editor','Remote Editor','Remote Editor/Producer']) &&
							<TabPanel>
								<UploadedImages
									orderId={orderId}
									tabName={AL.UPLOADED_IMAGES}
									order_details={order_details}
								/>
							</TabPanel>
						}
						{
							(
								(
									HF.hasRole(['editor', 'admin','Remote Editor','Remote Editor/Producer']) &&
									(order_details.editor_id === userAdmin.user.id)
								) ||
								(
									HF.hasRole(['admin',]) && order_details.editor_id
								) ||
								(
									HF.hasRole(['producer', 'Remote Producer','Remote Editor/Producer'])
								)
							)
							&&
							<TabPanel>
								<Editor
									orderId={orderId}
									tabName={AL.EDITOR}
									order_details={order_details}
								/>
							</TabPanel>
						}
						{
							(
								(
									order_details.agency_id !== null &&
									( HF.hasRole(['producer', 'admin', 'Remote Producer','Remote Editor/Producer']) && (order_details.producer_id === userAdmin.user.id) )
								) ||
								(
									order_details.agency_id !== null &&
									HF.hasRole(['admin']) && order_details.producer_id
								) ||
								(
									order_details.agency_id !== null &&
									HF.hasRole(['qa'])
								)
							)
							&&
							<TabPanel >
								<ProductionManager
									orderId={orderId}
									tabName={AL.PRODUCTION_MANAGER}
									order_details={order_details}
									production_details={production_details}
								/>
							</TabPanel>
						}
						{
							(
								(
									order_details.agency_id !== null &&
									( HF.hasRole(['qa', 'admin']) && (order_details.qa_id === userAdmin.user.id) )
								) ||
								(
									order_details.agency_id !== null &&
									HF.hasRole(['admin']) && order_details.qa_id
								)
							)
							&&
							<TabPanel>
								<QualityAssurance
									orderId={orderId}
									tabName={AL.QUALITY_ASSURANCE}
									order_details={order_details}
									production_details={production_details}
                                    shipping_details={shipping_details}
								/>
							</TabPanel>
						}
					</Tabs>
				</div>
			</div>


		);
	};
}

function mapStateToProps(state) {
    const { loadingAgency } = state.agenciesReducer;
    const { loading, userAdmin } = state.adminReducer;
    const { allowSave } = state.ordersReducer;

    return {
        loadingAgency,
        loading, userAdmin,
        allowSave
    };
}

const Images = connect(mapStateToProps)(ImagesPage);
export default Images;
