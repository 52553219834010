import bugsnag from '@bugsnag/js'
import bugsnagReact from '@bugsnag/plugin-react'
import React from 'react'

let bugsnagClient;
if (process.env.NODE_ENV === 'development') {
    bugsnagClient = '';
}
else {
    bugsnagClient = bugsnag('b725163e83c9c1e1fb31afbd1e6a401a').use(bugsnagReact, React)
}

export default bugsnagClient

