import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Grid,
  Row,
  Col,
  OverlayTrigger,
  Popover
} from "react-bootstrap";
import { objectsConstants as OC } from 'redux/constant/objects-constant';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Card } from "components/Card/Card.jsx";
import { getUsersPagination, getUser, storeUser, editUser, deleteUser, updateUserStatus, showPassword } from 'redux/actions/user-actions';
import { getUserRoles } from 'redux/actions/role-actions';
import 'react-table/react-table.css';
import UserForm from "./UserForm";
import { hasPermission } from 'helper/hasPermission';
import Confirmalertfordelete from 'components/ThemeComponents/confirmAlertForDelete';
import ShowPassword from 'components/ThemeComponents/showPassword';
import { adminLabels } from 'redux/constant/admin-label-constant';
import OpenModalButton from 'components/ThemeComponents/openModelButton';
import { selectChangeHandler, hasRole, openModal, closeModal } from 'helper/helperFunctions';
import { ThemeFunctions as TF } from 'helper/ThemeFunctions';
import MyImage from 'components/Upload/MyImage';
import Select from 'react-select'
import { AiOutlineEye } from 'react-icons/ai';
import Pagination from '../../ThemeComponents/Pagination';
var Modal = require('react-bootstrap-modal');

class AdminUserListPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addModalUser: false,
      editModalUser: false,
      sorted: '',
      filtered: '',
      pageSize: 10,
      user_status: '1'
    };

    this.callEditUser = this.callEditUser.bind(this);
    this.deleteUser = this.deleteUser.bind(this);
    this.deleteConfirmUser = this.deleteConfirmUser.bind(this);
    this.usersPaginationData = this.usersPaginationData.bind(this);
    this.filterSearchHandle = this.filterSearchHandle.bind(this);
    this.handleUserSubmit = this.handleUserSubmit.bind(this);
    this.selectChangeHandler = selectChangeHandler.bind(this);
    this.props.getAllRolesCall();
  };
  handleUserSubmit(userData, formType) {
    if (formType === 'add')
      this.props.storeUserCall(userData);
    else if (formType === 'edit')
      this.props.editUserCall(userData, this.props.editUser.id);
  }

  filterSearchHandle(e) {
    this.setState({
      'filtered': e.target.value
    });
  }
  callEditUser(id) {
    this.props.getUserCall(id);
  }

  deleteUser(userId) {
    this.props.deleteUserCall(userId).then(res => {
      this.pagination.dataCall();
    });
  }

  deleteConfirmUser(userId) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <Confirmalertfordelete typeName="User" description="Are you sure to delete the admin user?" onClosePro={onClose} deleteType={() => this.deleteUser(userId)} />
        )
      }
    })
  }

  showPasswordModal = (userId) => {
    this.props.showPasswordCall(userId).then(response => {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <ShowPassword description={`Password for ${response.user.username} : `} user={response.user} onClosePro={onClose} />
          )
        }
      })
    })
  }

  usersPaginationData(page, filter, sort, pageSize, status) {
    this.setState({ pageSize: pageSize }, function () {
      this.props.getUsersCall(page, filter, sort, pageSize, status);
    })
  }

  handleUpdateUser = userId => (e) => {
    // return false;
    this.props.updateUserStatusCall(userId, { is_active: e.target.checked ? true : false }).then(
      response => {
        this.pagination.dataCall();
        // this.props.getUsersCall(this.props.current_page, '', '', this.state.pageSize, this.state.user_status);
        // this.props.usersPaginationData();
      }
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if ((prevProps.editUser !== this.props.editUser) && !this.props.loading) {
      this.setState({ editModalUser: true });
    }
    if ((prevProps.storedUser !== this.props.storedUser) && !this.props.loading) {
      this.setState({ addModalUser: false }, function () {
        this.pagination.dataCall();
        // this.props.getUsersCall(this.props.current_page, '', '', this.state.pageSize, this.state.user_status);
      });
    }
    if ((prevProps.updatedUser !== this.props.updatedUser) && !this.props.loading) {
      this.setState({ editModalUser: false }, function () {
        this.pagination.dataCall();
        // this.props.getUsersCall(this.props.current_page, '', '', this.state.pageSize, this.state.user_status);
      });
    }
    if ((prevProps.deleteUser !== this.props.deleteUser) && !this.props.loading) {
      this.pagination.dataCall();
      // this.props.getUsersCall(this.props.current_page, '', '', this.state.pageSize, this.state.user_status);
    }
    if (this.state.user_status !== prevState.user_status) {
      this.pagination.dataCall();
      // this.table.state.onFetchData(this.table.state, this.table.instance)
    }
  }

  paginationCall = (data) => {
    return this.props.getUsersCall(data.page, data.filter, data.sort, data.pageSize, this.state.user_status);
  }

  render() {
    const { /*filtered*/ } = this.state;
    const { editUser, roles/*, usersData, pages, loading*/ } = this.props;
    const columns = [
      {
        Header: "Users Listing",
        headerClassName: 'headerPagination',
        columns: [
          {
            Header: "User Name",
            accessor: "name",
            className: "action-center"
          },
          {
            Header: "Email",
            accessor: "email",
            className: "action-center"
          },
          {
            Header: "Roles",
            accessor: "roles",
            Cell: ({ value }) => (
              <div>
                {
                  value.map((prop, key) => {
                    return (<span className="badge badge-success" key={key}>{prop.name}</span>)
                  })
                }
              </div>
            ),
            className: "action-center",
            sortable: false
          },
          {
            Header: "Image",
            accessor: "image",
            Cell: ({ value, original }) => (
              <div>
                {
                  original.image ? <MyImage className="initialsLogo" alt="initialLogo" src={original.image.preview} /> : TF.initialsLogo(original.bgColor, original.name)

                }
              </div>
            ),
            className: "action-center",
            sortable: false
          },
          {
            Header: "Status",
            accessor: "is_active",
            Cell: ({ row }) => (
              <label className="container-check">
                &nbsp;
                <input type="checkbox" name="is_active" className="checkbox" value={row.is_active ? 1 : 0} onChange={this.handleUpdateUser(row.id)} checked={row.is_active ? 1 : 0}
                  readOnly={true} /><span className="checkmark"></span>
              </label>
            ),
            className: "action-center",
            sortable: false
          },
          {
            Header: "Actions",
            accessor: "id",
            Cell: ({ value }) => (
              <div>
                <OverlayTrigger placement="bottom" overlay={<Popover id="tooltip">{hasPermission('user-edit') ? 'Edit User' : adminLabels.DONT_ALLOW}</Popover>}>
                  <span>
                    {
                      hasPermission('user-edit') &&
                      <button type='button' className="editIcon orderDetailsAction" onClick={() => this.callEditUser(value)}>&nbsp;</button>
                    }
                    {
                      !hasPermission('user-edit') &&
                      <button type='button' className="editIcon orderDetailsAction button_disabled" >&nbsp;</button>
                    }
                  </span>
                </OverlayTrigger>
                {/* <OverlayTrigger placement="bottom" overlay={<Popover id="tooltip">{hasPermission('user-delete') ? 'Delete User' : adminLabels.DONT_ALLOW}</Popover>}>
                  <React.Fragment>
                    <span>
                      {
                        hasPermission('user-delete') &&
                        <button type='button' className="deleteIcon orderDetailsAction" onClick={() => this.deleteConfirmUser(value)}>&nbsp;</button>
                      }
                    </span>
                    <span>
                      {
                        !hasPermission('user-delete') &&
                        <button type='button' className="deleteIcon orderDetailsAction button_disabled" >&nbsp;</button>
                      }
                    </span>
                    <span>
                      {
                        hasRole(['admin']) &&
                        <button onClick={() => this.showPasswordModal(value)} className={'linkBtn'}><AiOutlineEye /></button>
                      }
                    </span>
                  </React.Fragment>
                </OverlayTrigger> */}

                <span>
                  {
                    hasRole(['admin']) &&
                    <button onClick={() => this.showPasswordModal(value)} className={'linkBtn'}><AiOutlineEye /></button>
                  }
                </span>


              </div>
            ),
            className: "action-center",
            sortable: false
          }
        ]
      }
    ];
    const options = [
      {
        value: '',
        label: 'All'
      },
      {
        value: '1',
        label: 'Active'
      },
      {
        value: '0',
        label: 'In-Active'
      }
    ]
    return (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                ctTableResponsive
                content={
                  <div>
                    <Grid fluid>
                      <Row>
                        <Col md={12}>
                          <div className="secHeading">Users List</div>
                        </Col>

                        <Col md={12}>
                          <div className="flexElem flexResponsive" style={{ "alignItems": "flex-start", "justifyContent": "space-between" }}>
                            <div className="flexElem">
                              <OpenModalButton
                                openModal={hasPermission('user-create') ? openModal.bind(this, "addModalUser") : null}
                                classButton={['backButton pt-sm no_radius pb-sm primary mt-none btn-block btn-info btn mr-md', !hasPermission('user-create') ? 'button_disabled' : '']}
                                buttonName="Add User"
                                tooltipText={hasPermission('user-create') ? 'Add User' : adminLabels.DONT_ALLOW}
                                classIcon={['fa', 'fa-plus']}
                              />
                              <Select
                                isSearchable={false}
                                isMulti={false}
                                styles={{
                                  container: (provided) => {
                                    return {
                                      ...provided,
                                      width: '100%',
                                      minWidth: '200px'
                                    }
                                  },
                                  menu: (provided) => {
                                    return {
                                      ...provided,
                                      width: '200px'
                                    }
                                  }
                                }}
                                value={
                                  options.map(item => {
                                    if (item.value === this.state.user_status) {
                                      return item
                                    }
                                  })
                                }
                                options={options}
                                onChange={this.selectChangeHandler}
                                name='user_status'
                              />
                              {/* <select value={this.state.user_status} className='form-control' name="user_status" id="" onChange={this.selectChangeHandler}>
                                <option value="1">Active</option>
                                <option value="0">In-Active</option>
                                <option value="">All</option>
                              </select> */}
                            </div>

                            {/* <div className="custom-search-input">
                              <FormInputs
                                ncols={["col-md-12"]}
                                onChange={this.filterSearchHandle}
                                proprieties={[
                                  {
                                    type: "text",
                                    bsClass: "form-control",
                                    placeholder: "Search Users",
                                    onChange: this.filterSearchHandle,
                                    name: "filter"
                                  }
                                ]}
                              />
                            </div> */}
                          </div>
                        </Col>

                        {/* <Col md={12} className="mt-md">
                          <ReactTable
                            noDataText='No user found'
                            data={usersData}
                            pages={pages}
                            loading={loading}
                            columns={columns}
                            filtered={filtered}
                            defaultPageSize={10}
                            defaultSorted={[
                              {
                                id: 'name',
                                desc: false
                              }
                            ]}
                            className="-striped listing responsive"
                            pageData={this.usersPaginationData}
                            ref={table => this.table = table}
                            manual
                            onFetchData={(state, instance) => {
                              var sort = state.sorted.length === 0 ? '' : state.sorted[0].id + ',desc:' + state.sorted[0].desc;
                              let status = this.state.user_status
                              state.pageData(state.page + 1, state.filtered, sort, state.pageSize, status);
                            }}
                          />
                        </Col> */}
                        <Pagination
                          ref={(p) => this.pagination = p}
                          showPagination={false}
                          columns={columns}
                          pageSize={20}
                          getDataCall={this.paginationCall}
                          filterView={true}
                          filterPlaceHolder={'users'}
                          defaultSorted={
                            [
                              {
                                id: 'name',
                                desc: 'false'
                              },
                              {
                                id: 'is_active',
                                desc: 'false'
                              }
                            ]
                          }
                          // downloadData={true}
                          // downloadFileName={'Orders'}
                          // lowerContent = {null}
                          // filterPlaceHolder = {'Orders'}
                          noDataText='No user found'
                          getRowProps={this.getRowProps}
                        // showAllToggle={true}
                        />
                      </Row>
                    </Grid>

                    {/*Add User Modal Start*/}
                    {roles !== null &&
                      <Modal backdrop={'static'} show={this.state.addModalUser} onHide={closeModal.bind(this, "addModalUser")} aria-labelledby="ModalHeader" >
                        <Modal.Header closeButton>
                          <Modal.Title id='ModalHeader' className="headerTitle">Add User</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <div className="row">
                            <div className="col-md-12">
                              <Card bsClass={["innerCard mb-none"]} content={
                                <UserForm
                                  closeModel={closeModal.bind(this, "addModalUser")}
                                  formTypePro="add"
                                  userPro={OC.USER}
                                  handleUserSubmit={this.handleUserSubmit}
                                  componentPro="adminUser"
                                  roles={roles}
                                />
                              } />
                            </div>
                          </div>
                        </Modal.Body>
                      </Modal>
                    }

                    {/*Add User Modal End*/}

                    {/*Edit User Modal start*/}
                    {editUser && roles !== null &&
                      <Modal backdrop={'static'} show={this.state.editModalUser} onHide={closeModal.bind(this, "editModalUser")} aria-labelledby="ModalHeader" >
                        <Modal.Header closeButton>
                          <Modal.Title id='ModalHeader' className="headerTitle">Edit User</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <div className="row">
                            <div className="col-md-12">
                              <Card bsClass={["innerCard mb-none"]} content={
                                <UserForm
                                  closeModel={closeModal.bind(this, "editModalUser")}
                                  formTypePro="edit"
                                  userPro={editUser}
                                  handleUserSubmit={this.handleUserSubmit}
                                  roles={roles}
                                />
                              } />
                            </div>
                          </div>
                        </Modal.Body>
                      </Modal>
                    }
                    {/*Edit User Modal End*/}
                  </div>
                } />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  };
}

const mapDispatchToProps = dispatch => {
  return ({
    getUsersCall: (page, filter, sort, pageSize, status) => { return dispatch(getUsersPagination(page, filter, sort, pageSize, status)) },
    getUserCall: (id) => { dispatch(getUser(id)) },
    storeUserCall: (userData) => { dispatch(storeUser(userData)) },
    editUserCall: (userData, id) => { dispatch(editUser(userData, id)) },
    deleteUserCall: (id) => { return dispatch(deleteUser(id)) },
    getAllRolesCall: () => { dispatch(getUserRoles()) },
    updateUserStatusCall: (id, data) => { return dispatch(updateUserStatus(id, data)) },
    showPasswordCall: (id) => { return dispatch(showPassword(id)) }

  });
};

function mapStateToProps(state) {
  const { editUser, loading, usersData, pages, storedUser, updatedUser, deleteUser, current_page } = state.userReducer;
  const { roles } = state.rolesReducer;
  return {
    usersData, loading, pages, storedUser, updatedUser, editUser, deleteUser, current_page, roles
  };
}
const AdminUsers = connect(mapStateToProps, mapDispatchToProps)(AdminUserListPage);
export default AdminUsers;