import React, { Component } from "react";
import { connect } from 'react-redux';
import { Grid, Row, Col } from "react-bootstrap";
import { Card } from "components/Card/Card.jsx";
import { OrdersTOMissingResouce } from "redux/actions/order-actions";
import Pagination from 'components/ThemeComponents/Pagination';
import { dateFormatMy } from 'helper/helperFunctions';
import { Link } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { hasPermission } from 'helper/hasPermission';
import "react-tabs/style/react-tabs.css";

class OrdersTOMissingResourcePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      index: 0,
    }
  }
  getPaginationDataWithoutLogo = (data) => {
    return this.props.getOrderMissingAgencyResourceCall({ ...data, exceptResource: ['primary_logo'] });
  }
  getPaginationDataWithoutSlates = (data) => {
    return this.props.getOrderMissingAgencyResourceCall({ ...data, exceptResource: ['opening_slate', 'closing_slate'] });
  }
  getRowProps = (rowData) => {
    let data = {};
    if (rowData.customer_slates.id !== rowData.agency.id) {
      data = { backgroundColor: 'yellow' }
    }
    return data;
  }

  render() {
    const MissingLogoColumns = [
      {
        Header: "Camera Ship",
        headerClassName: 'headerPagination',
        columns: [
          {
            Header: "Agency Name",
            accessor: "customer_slates",
            className: "action-center",
            Cell: ({ value, original }) => (
                      <div>
                        { hasPermission('agency-edit') ? <Link target='_blank' to={'/Admin/' + value.id + '/agency-edit'} className="blue_link">{value.name}</Link> : value.name}
                      </div>
                    )
          },
        //   {
        //     Header: "To Profile",
        //     accessor: "customer_slates",
        //     className: "action-center",
        //     Cell: ({ value, original }) => (
        //       <div>
        //         { hasPermission('agency-edit') ? <Link target='_blank' to={'/Admin/' + value.id + '/agency-edit'} className="blue_link">To Profile</Link> : value.name}
        //       </div>
        //     )
        //   },
          // {
          //   Header: "Logo File",
          //   accessor: "customer_slates_resources",
          //   className: "action-center",
          //   Cell: ({ value, original }) => (
          //     <div>
          //       {
          //         'primary_logo' in value &&
          //         value.primary_logo.file_title
          //       }

          //     </div>
          //   )

          // },
        //   {
        //     Header: "Opening slate",
        //     accessor: "customer_slates_resources",
        //     className: "action-center",
        //     Cell: ({ value, original }) => (
        //       <div>
        //         {
        //           'opening_slate' in value &&
        //           value.opening_slate.file_title
        //         }

        //       </div>
        //     )

        //   },
        //   {
        //     Header: "Closing Slate",
        //     accessor: "customer_slates_resources",
        //     className: "action-center",
        //     Cell: ({ value, original }) => (
        //       <div>
        //         {
        //           'closing_slate' in value &&
        //           value.closing_slate.file_title
        //         }

        //       </div>
        //     )

        //   },
        //   {
        //     Header: "Password Slate",
        //     accessor: "customer_slates_resources",
        //     className: "action-center",
        //     Cell: ({ value, original }) => (
        //       <div>
        //         {
        //           'password_slate' in value &&
        //           value.password_slate.file_title
        //         }

        //       </div>
        //     )
        //   },
        {
            Header: "Projected PTM  Ship Date of 1st order",
            accessor: "order_dates",
            className: "action-center",
            Cell: ({ value, original }) => (
              <div>
                {
                  'projected_camera_ship_date' in value &&
                  dateFormatMy(value.projected_camera_ship_date.value)
                }
              </div>
            )
          },
          {
            Header: "Date Account Created",
            accessor: "customer_slates.created_at",
            className: "action-center",
            sortable: false,
            Cell: ({ value, original }) => (
              <div>
                {
                  dateFormatMy(value)
                }
              </div>
            )
          },
          {
            Header: "Barcode",
            accessor: "barcode",
            width: 200,
            className: "action-center",
            Cell: ({ value, original }) => (
              <div>
                { hasPermission('order-edit') ? <Link target='_blank' to={'/Admin/' + original.id + '/order-edit'} className="blue_link">{original.barcode}</Link> : original.barcode}
              </div>
            )
          },
        ]
      }
    ];

    const MissingSlatesColumns = [
      {
        Header: "Camera Ship",
        headerClassName: 'headerPagination',
        columns: [
          {
            Header: "Agency Name",
            accessor: "customer_slates",
            className: "action-center",
            Cell: ({ value, original }) => (
                <div>
                  { hasPermission('agency-edit') ? <Link target='_blank' to={'/Admin/' + value.id + '/agency-edit'} className="blue_link">{value.name}</Link> : value.name}
                </div>
              )
          },
        //   {
        //     Header: "To Profile",
        //     accessor: "customer_slates",
        //     className: "action-center",
        //     Cell: ({ value, original }) => (
        //       <div>
        //         { hasPermission('agency-edit') ? <Link target='_blank' to={'/Admin/' + value.id + '/agency-edit'} className="blue_link">{value.name}</Link> : value.name}
        //       </div>
        //     )
        //   },
          {
            Header: "Primary Logo File",
            accessor: "customer_slates_resources",
            className: "action-center",
            sortable: false,
            Cell: ({ value, original }) => (
              <div>
                {
                  'primary_logo' in value &&
                  value.primary_logo.file_title
                }

              </div>
            )
          },
          {
            Header: "Opening slate",
            accessor: "customer_slates_resources",
            className: "action-center",
            sortable: false,
            Cell: ({ value, original }) => (
              <div>
                {
                  'opening_slate' in value &&
                  value.opening_slate.file_title
                }

              </div>
            )
          },
          {
            Header: "Closing Slate",
            accessor: "customer_slates_resources",
            className: "action-center",
            sortable: false,
            Cell: ({ value, original }) => (
              <div>
                {
                  'closing_slate' in value &&
                  value.closing_slate.file_title
                }

              </div>
            )
          },
          // {
          //   Header: "Password Slate",
          //   accessor: "customer_slates_resources",
          //   className: "action-center",
          //   Cell: ({ value, original }) => (
          //     <div>
          //       {
          //         'password_slate' in value &&
          //         value.password_slate.file_title
          //       }

          //     </div>
          //   )
          // },
          {
            Header: "Return Date",
            accessor: "order_dates",
            className: "action-center",
            Cell: ({ value, original }) => (
              <div>
                {
                  'return_date' in value &&
                  dateFormatMy(value.return_date.value)
                }
              </div>
            )
          },
          {
            Header: "Barcode",
            accessor: "barcode",
            width: 200,
            className: "action-center",
            Cell: ({ value, original }) => (
              <div>
                { hasPermission('order-edit') ? <Link target='_blank' to={'/Admin/' + original.id + '/order-edit'} className="blue_link">{original.barcode}</Link> : original.barcode}
              </div>
            )
          },
        ]
      }
    ]

    return (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                ctTableResponsive
                content={
                  <div>
                    <Grid fluid>
                      <Row>
                        <Col md={12} className="mt-md">
                          <div id="tabs" className="orderTabs colored">
                            <Tabs selectedIndex={this.state.index} onSelect={index => this.setState({ index: index })} >
                              <TabList>
                                <Tab>Agency Missing Logo</Tab>
                                <Tab>Agency Missing Slates</Tab>
                              </TabList>
                              <TabPanel>
                                <Pagination
                                  ref={(p) => this.pagination = p}
                                  columns={MissingLogoColumns}
                                  getDataCall={this.getPaginationDataWithoutLogo}
                                  filterView={false}
                                  getRowProps={this.getRowProps}
                                />
                              </TabPanel>
                              <TabPanel>
                                <Pagination
                                  ref={(p) => this.pagination = p}
                                  columns={MissingSlatesColumns}
                                  getDataCall={this.getPaginationDataWithoutSlates}
                                  filterView={false}
                                  getRowProps={this.getRowProps}
                                />
                              </TabPanel>
                            </Tabs>
                          </div>
                        </Col>
                      </Row>
                    </Grid>
                  </div>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return ({
    getOrderMissingAgencyResourceCall: (data) => { return dispatch(OrdersTOMissingResouce(data)) }
  });
};

function mapStateToProps(state) {
  return {};
}

const OrdersTOMissingResource = connect(mapStateToProps, mapDispatchToProps)(OrdersTOMissingResourcePage);
export default OrdersTOMissingResource;
