import { adminConstants } from '../constant/admin-constant';

const initialState = {loadingDestination: false};

export function destinationsReducer(state = initialState, action){

	switch(action.type){

		case adminConstants.PAGINATION_DESTINATIONS_REQUEST:
			return { ...state, loadingDestination:true, destinations: {} };
		case adminConstants.PAGINATION_DESTINATIONS_SUCCESS:
			return { ...state, destinationsData:action.payload.data.pagination.data, pages:action.payload.data.pagination.last_page, loadingDestination: false, current_page:action.payload.data.pagination.current_page };
		case adminConstants.PAGINATION_DESTINATIONS_FAILURE:
			return { ...state, loadingDestination:false, error : action.payload };

		case adminConstants.STORE_DESTINATION_REQUEST:
			return { ...state, loadingDestination:true}
		case adminConstants.STORE_DESTINATION_SUCCESS:
			return { ...state, loadingDestination:false, storedDestination: action.payload.destination};
		case adminConstants.STORE_DESTINATION_FAILURE:
			return { ...state, loadingDestination:false, error:action.payload };

		case adminConstants.GET_DESTINATION_REQUEST:
			return { ...state, loadingDestination:true };
		case adminConstants.GET_DESTINATION_SUCCESS:
			return { ...state, loadingDestination:false, editDestination : action.payload.destination };
		case adminConstants.GET_DESTINATION_FAILURE:
			return { ...state, loadingDestination:false, error : action.payload };

		case adminConstants.EDIT_DESTINATION_REQUEST:
			return { ...state, loading:true};
		case adminConstants.EDIT_DESTINATION_SUCCESS:
			return { ...state, loading:false , updatedDestination:action.payload.destination};
		case adminConstants.EDIT_DESTINATION_FAILURE:
			return { ...state, loading:false, error : action.payload };
        
	    case adminConstants.EDIT_ALL_DESTINATIONS_REQUEST:
			return{ ...state, loading:true};
		case adminConstants.EDIT_ALL_DESTINATIONS_SUCCESS:
			return{ ...state, loading:false , editAllDestination:action.payload.destinations}	
		case adminConstants.EDIT_ALL_DESTINATIONS_FAILURE:
			return{...state, loading:false, error: action.payload};	

		case adminConstants.DELETE_DESTINATION_REQUEST:
			return { ...state, loading:true}
		case adminConstants.DELETE_DESTINATION_SUCCESS:
			return { ...state, loading:false};
		case adminConstants.DELETE_DESTINATION_FAILURE:
			return { ...state, loading:false, error:action.payload };

		default:
			return { ...state };
	}
}