import React, { Component } from 'react'
import { connect } from 'react-redux'
import { toggleImageStatus, changeImagesSortOrder } from 'redux/actions/imageActions';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import User from 'helper/User';
import { orderImages } from 'redux/actions/order-actions';
import Confirmalert from 'components/ThemeComponents/confirmAlert';
import { confirmAlert } from 'react-confirm-alert';
class ImageControls extends Component {

    constructor(props) {
        super(props)

        this.state = {
            slideShowModal: false
        }
    }

    toggleImagesStatus = (status) => {
        const images = this.props.imagesData.map(image => image.id);
        this.props.toggleImageStatusCall({ images, status });
    }




    deleteConfirmAgency = (e) => {
        var sortOrder = e.target.value;
        console.log("vallue", e.target.value);
        confirmAlert({
            customUI: ({ onClose }) => {
                return (

                    <Confirmalert
                        header={`Are you sure to do this action?`}
                        description={`The previous sorting cannot be recovered`}
                        onClosePro={onClose}
                        extraHtml={() => {
                            return (<span></span>);
                        }}

                        onYes={() => { this.changeImagesSortOrder(sortOrder) }}
                        yesLabel='Proceed'
                        noLabel='Cancel'
                    />

                )
            }
        })
    }


    changeImagesSortOrder = (sortOrder) => {
        this.props.changeImagesSortOrderCall(sortOrder, null, User.getProperty('order.id')).then(
            res => {
                this.props.orderImagesCall(User.order('id'), this.props.imageVersion, 'Uploaded-images', 1, '', '', this.state.pageSize, User.user.role.name);
            }
        );
    }

    saveDragDrop = (sortOrder) => {
        this.props.changeImagesSortOrderCall(sortOrder, this.props.images);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.saveDrag !== prevProps.saveDrag && this.props.saveDrag) {
            this.saveDragDrop('drag');
        }
    }


    render() {
        const { imagesData } = this.props;
        return (
            <React.Fragment>

                <div className="col-md-3 mt-lg mb-md">
                    {/* <p style={{color: '#394e81'}}>Gallery Viewing Options</p> */}
                    <div className="text-center">
                        <select className='form-control' value={this.state.pageSize}
                                style={{'marginLeft': 'auto', padding: '0'}} onChange={this.deleteConfirmAgency}
                                name="pageSize" id="">
                            <option value="original">Restore to Original Upload Order</option>
                            <option value="random"> Randomize Photos</option>
                            <option value="date">Sort By Date Taken</option>
                        </select>
                    </div>
                </div>

                <div className="col-md-4 mb-md inlineFlexElem noBreakLabel alignCenter" style={{paddingLeft: '0'}}>
                    <div className="text-center mt-xlg flexElem block spaceBetween" /*style={{flexWrap: 'wrap'}}*/>
                        <div className="customChk">
                            <input disabled={imagesData.length > 0 ? false : "disabled"} onChange={() => { this.toggleImagesStatus(1) }} value='1' type="radio" id="Check Approve All" name="status" />
                            <label htmlFor="Check Approve All" style={{fontSize: '17px'}}>Check Approve All</label>
                        </div>

                        <div className="customChk">
                            <input disabled={imagesData.length > 0 ? false : "disabled"} onChange={() => { this.toggleImagesStatus(0) }} value='0' type="radio" id="Uncheck Approve All" name="status" />
                            <label htmlFor="Uncheck Approve All" style={{fontSize: '17px'}}>Uncheck Approve All</label>
                        </div>
                    </div>
                </div>
                {/* <div className="row mt-xlg mb-xlg">
                    <div className="col-md-12">
                        <div className="uploaderActions alignCenter spaceBetween flexElem respFlex">
                            <div className="">
                                <OverlayTrigger
                                    placement="top"
                                    overlay={
                                        <Tooltip id='Approve'>
                                            This button should <strong className="semibold">Approve</strong> all photos.
                                        </Tooltip>
                                    }>
                                    <button disabled={imagesData.length > 0 ? false : "disabled"} onClick={() => { this.toggleImagesStatus(1) }} className="themeBtn_14 block no_radius dull_blue_gradient hover_dark">Check Approve All</button>
                                </OverlayTrigger>
                            </div>
                            <div className="">
                                <OverlayTrigger
                                    placement="top"
                                    overlay={
                                        <Tooltip id='Unapprove'>
                                            This button should <strong className="semibold">Unapprove</strong> all photos.
                                        </Tooltip>
                                    }>
                                    <button disabled={imagesData.length > 0 ? false : "disabled"} onClick={() => this.toggleImagesStatus(0)} className="themeBtn_14 block no_radius limegreen_gradient hover_dark">Uncheck Approve All</button>
                                </OverlayTrigger>
                            </div>
                            <div className="">
                                <OverlayTrigger
                                    placement="top"
                                    overlay={
                                        <Tooltip id='DateCreated'>
                                            This button will Sort the all Photos by <strong className="semibold">Date Created</strong>
                                        </Tooltip>
                                    }>
                                    <button disabled={imagesData.length > 0 ? false : "disabled"} onClick={() => this.deleteConfirmAgency('date')} className="themeBtn_14 block no_radius red_gradient hover_dark">Sort By Date Taken</button>
                                </OverlayTrigger>
                            </div>
                            <div className="">
                                <OverlayTrigger
                                    placement="top"
                                    overlay={
                                        <Tooltip id='originalOrder'>
                                            This button will Sort the all Photos by <strong className="semibold">Original Order</strong> that they were uploaded.
                                        </Tooltip>
                                    }>
                                    <button disabled={imagesData.length > 0 ? false : "disabled"} onClick={() => this.deleteConfirmAgency('original')} className="themeBtn_14 block no_radius medium_blue_gradient hover_dark">Restore to Original Upload Order</button>
                                </OverlayTrigger>
                            </div>
                            <div className="">
                                <OverlayTrigger
                                    placement="top"
                                    overlay={
                                        <Tooltip id='random'>
                                            This button will <strong className="semibold">Randomize</strong> the all Photos
                                        </Tooltip>
                                    }>
                                    <button disabled={imagesData.length > 0 ? false : "disabled"} onClick={() => this.deleteConfirmAgency('random')} className="themeBtn_14 block no_radius purple_blue_gradient hover_dark"> Randomize Photos</button>
                                </OverlayTrigger>
                            </div>
                        </div>
                    </div>
                </div> */}
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    const { imagesData } = state.ordersReducer;
    return {
        imagesData
    }
}

const mapDispatchToProps = dispatch => {
    return {
        toggleImageStatusCall: (images) => { dispatch(toggleImageStatus(images)) },
        changeImagesSortOrderCall: (sortOrder, images, order_id) => { return dispatch(changeImagesSortOrder(sortOrder, images, order_id)) },
        orderImagesCall: (orderId, version_type, tabName, page, filter, sort, pageSize, userRole) => { dispatch(orderImages(orderId, version_type, tabName, page, filter, sort, pageSize, userRole)) },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ImageControls)
