import { adminConstants } from '../constant/admin-constant';

const initialState = { loadingAgencyUser: false, agencyUsers: {}, editAgencyUser: '', agencyUsersData: [] };
export function agencyUserReducer(state = initialState, action) {

	switch (action.type) {

		case adminConstants.PAGINATION_AGENCY_USERS_REQUEST:
			return { ...state, loadingAgencyUser: true, agencyUsers: {}, editAgencyUser: '', storedAgencyUser: '', updatedAgencyUser: '', deleteAgencyUser: false };
		case adminConstants.PAGINATION_AGENCY_USERS_SUCCESS:
			return { ...state, agencyUsersData: action.payload.agencies.data, pages: action.payload.agencies.data.last_page, loadingAgencyUser: false, current_page: action.payload.agencies.data.current_page, agency: action.payload.agencies.agency };
		case adminConstants.PAGINATION_AGENCY_USERS_FAILURE:
			return { ...state, loadingAgencyUser: false, error: action.payload };

		case adminConstants.STORE_AGENCY_USER_REQUEST:
			return { ...state, loadingAgencyUser: true, editAgencyUser: '', storedAgencyUser: '' }
		case adminConstants.STORE_AGENCY_USER_SUCCESS:
			return { ...state, loadingAgencyUser: false, storedAgencyUser: action.payload.agencyUser };
		case adminConstants.STORE_AGENCY_USER_FAILURE:
			return { ...state, loadingAgencyUser: false, error: action.payload };

		case adminConstants.GET_AGENCY_USER_REQUEST:
			return { ...state, loadingAgencyUser: true };
		case adminConstants.GET_AGENCY_USER_SUCCESS:
			return { ...state, loadingAgencyUser: false, editAgencyUser: action.payload.agencyUser };
		case adminConstants.GET_AGENCY_USER_FAILURE:
			return { ...state, loadingAgencyUser: false, error: action.payload };

		case adminConstants.EDIT_AGENCY_USER_REQUEST:
			return { ...state, loadingAgencyUser: true, updatedAgencyUser: '' };
		case adminConstants.EDIT_AGENCY_USER_SUCCESS:
			return { ...state, loadingAgencyUser: false, updatedAgencyUser: action.payload.agencyUser };
		case adminConstants.EDIT_AGENCY_USER_FAILURE:
			return { ...state, loadingAgencyUser: false, error: action.payload };

		case adminConstants.DELETE_AGENCY_USER_REQUEST:
			return { ...state, loadingAgencyUser: true, deleteAgencyUser: false }
		case adminConstants.DELETE_AGENCY_USER_SUCCESS:
			return { ...state, loadingAgencyUser: false, deleteAgencyUser: true };
		case adminConstants.DELETE_AGENCY_USER_FAILURE:
			return { ...state, loadingAgencyUser: false, error: action.payload };

		default:
			return { ...state };
	}
}