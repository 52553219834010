import React from "react";
import { connect } from "react-redux";
import { Row, Col, OverlayTrigger, Popover } from "react-bootstrap";
import { getAllComments, deleteComment, addComment, editComment, toggleCommentHighlight, getContactLogTypes, updateOrderCsrStep } from "redux/actions/comment-actions";
import { openModal, hasRole } from "helper/helperFunctions";
import OpenModalButton from 'components/ThemeComponents/openModelButton';
import { Card } from "components/Card/Card.jsx";
import ReactValidator from "simple-react-validator";
import ConfirmAlertForDelete from "components/ThemeComponents/confirmAlertForDelete";
import CsrSteps from "components/Admin/Order/CsrSteps";
import { confirmAlert } from "react-confirm-alert";
import { ThemeFunctions as TF } from 'helper/ThemeFunctions';
import MyImage from 'components/Upload/MyImage';
import Pagination from "components/ThemeComponents/Pagination";
import CustomerServiceDetail from 'components/Admin/Order/CustomerServiceDetail';
import { withRouter } from 'react-router-dom';
import { getOrder } from 'redux/actions/order-actions';
var dateFormat = require('dateformat');

class CommentList extends React.Component {

    constructor(props) {
        super(props);
        this.table = React.createRef();

        // const {pathname} = this.props.location;


        // const location = useLocation();
        const { pathname } = this.props.location;
        const { id } = this.props.match.params;

        this.state = {
            filtered: '',
            pageSize: 20,
            to: '',
            from: '',
            commentAddFormModal: false,
            commentEditFormModal: false,
            comment: {
                comment: '',
                comment_category_id: this.props.comment_category_id,
                slug: '',
                uuid: this.props.withUuid ? this.props.order_details.uuid : '',
            },
            customerServiceDetailShow: pathname === `/Admin/${id}/order-edit` ? true : false,
            selectedOption: null,
            stepContentModal: false,
            formType: this.props.formType || '',
        };

        this.fromDateChangeHandler = this.fromDateChangeHandler.bind(this);
        this.validator = new ReactValidator();
        this.dateValidator = new ReactValidator();
        this.toggleCommentHighlightHandler = this.toggleCommentHighlightHandler.bind(this);
        this.commentChangeHandlerContactLog = this.commentChangeHandlerContactLog.bind(this);
        this.updateSettings = this.updateSettings.bind(this);


    }

    componentDidMount() {
        if (this.props.code === 'ContactLog') {
            this.props.getContactLogTypesCall(this.props.modelId);
        }
    }
    updateSettings(key, value) {

        const { modelId } = this.props;
        var settingData = {
            value: value,
        };
        this.props.updateOrderSettingsCall(modelId, key, settingData);
    }
    deleteCommentHandler = (id, index) => {
        this.props.deleteCommentCall(id).then(response => {
            this.pagination.dataCall();;
        });
    }

    openEditModal = (comment, index) => {
        this.setState({
            'comment': comment,
            id: index
        }, openModal.bind(this, 'commentEditFormModal'));
    }

    openAddModal = () => {
        this.setState((state) => {
            return {
                comment: {
                    ...state.comment,
                    comment: '',
                    slug: null,
                },
                selectedOption: null
            }
        }, openModal.bind(this, 'commentAddFormModal'));
    }

    commentChangeHandler = (e) => {
        const value = e.target.value;
        this.setState((state) => {
            return {
                comment: {
                    ...state.comment,
                    comment: value
                }
            }
        })
    }
    commentChangeHandlerContactLog = (selectedOption, info) => {
        if (info['action'] === 'select-option') {
            const contactLogDD = { ...this.state.comment };
            contactLogDD['comment'] = selectedOption['message'];
            contactLogDD['slug'] = selectedOption['slug'];
            this.setState({ selectedOption }, function () {
                this.setState({ comment: contactLogDD });
            });
        }
        if (info['action'] === 'clear') {
            this.setState({
                ...this.state,
                selectedOption: null
            });
        }
    }
    inputChangeHandler = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    fromDateChangeHandler = (value) => {
        this.setState({
            from: value !== null ? value : ''
        }, () => {
            this.table.current.state.from = this.state.from;
            if (this.state.to !== "") {
                if (1) {
                    this.pagination.dataCall();;
                } else {
                    this.dateValidator.showMessages();
                    this.forceUpdate();
                }
            } else {
                this.pagination.dataCall();;
            }
        })
    }

    toDateChangeHandler = (value) => {
        this.setState({
            to: value !== null ? value : ''
        }, () => {
            this.table.current.state.to = this.state.to;
            if (this.state.from !== "") {

                if (1) {
                    this.pagination.dataCall();;
                } else {
                    this.dateValidator.showMessages();
                    this.forceUpdate();
                }
            } else {
                this.pagination.dataCall();;
            }
        })
    }

    commentEditSubmitHandler = (e = null) => {
        let formType;
        if (e) {
            formType = e.target.name;
        } else if (!('id' in this.state.comment) && e === null) {
            formType = 'add';
        }
        if (this.validator.allValid()) {
            const data = {
                ...this.state.comment,
            }
            if (formType === 'add') {
                this.props.addCommentCall(data, this.props.modelId).then(
                    response => {
                        if (this.pagination) {
                            this.pagination.dataCall();
                        }
                        this.setState(state => {
                            return {
                                commentAddFormModal: false,
                                comment: {
                                    ...state.comment,
                                    comment: '',
                                }
                            }
                        }, function () {
                            if (this.props.code === 'ContactLog') {
                                this.props.getContactLogTypesCall(this.props.model_id);
                            }
                        })
                    }
                );
            } else if (formType === 'edit') {
                this.props.editCommentCall(data, data.id).then(
                    response => {
                        this.setState({
                            commentEditFormModal: false,
                            comment: {
                                comment: '',
                                comment_category_id: this.props.comment_category_id,
                                slug: '',
                                uuid: this.props.withUuid ? this.props.order_details.uuid : '',
                            }
                        })
                        this.pagination.dataCall();;
                    }
                )
            }
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    confirmCommentDelete = (id, index) => {
        confirmAlert({
            customUI: ({ onClose }) => <ConfirmAlertForDelete typeName='Comment' onClosePro={onClose} deleteType={this.deleteCommentHandler.bind(this, id, index)} />
        })
    }

    toggleCommentHighlightHandler(id, index) {
        const comments = [...this.props.comments.data];
        comments[index].highlight = !comments[index].highlight;
        this.props.toggleCommentHighlightHandlerCall(id, comments);
    }


    updateComments = () => {
        this.pagination.dataCall();;

    };

    getAgencyDataWithStateValue = (data) => {

        var from = this.state.from !== '' ? new Date(this.state.from).toDateString() : '';
        var to = this.state.to !== '' ? new Date(this.state.to).toDateString() : '';
        return this.props.getAllCommentsCall(data.page, data.filter, data.sort, data.pageSize, this.props.comment_category_id, this.props.modelId, from, to, this.props.order_details.uuid)



        // const departure_date = this.state.departure_date;
        // const status = this.state.status;
        // const advancedFilters = {
        //     status,
        //     departure_date: departure_date ? departure_date.toDateString() : departure_date
        // }
        // return this.props.getAgencyOrdersCall(data.page, this.state.filtered, data.sort, data.pageSize, User.agency('id'), advancedFilters);
    }

    componentWillUnmount() {
        if (this.state.comment.comment !== '') {
            this.commentEditSubmitHandler();
        }
    }

    paginationCall = (data) => {
        var from = this.state.from !== '' ? new Date(this.state.from).toDateString() : '';
        var to = this.state.to !== '' ? new Date(this.state.to).toDateString() : '';
        return this.props.getAllCommentsCall(data.page, data.filter, data.sort, data.pageSize, this.props.comment_category_id, this.props.modelId, from, to, this.props.order_details.uuid);
    }

    render() {
        const currentUser = JSON.parse(localStorage.getItem('user'));
        const { /* contactLogTypes, loadingOrder, production_details,*/ code, order_details, showSteps, shipping_details, pricing_details, csr_steps, programType } = this.props;
        const { /*selectedOption,*/ formType, customerServiceDetailShow } = this.state;
        this.validator.purgeFields();
        this.dateValidator.purgeFields();
        const columns = [
            {
                Header: 'comments',
                headerClassName: 'headerPagination',
                columns: [
                    {
                        Header: 'User',
                        accessor: 'created_by.name',
                        className: 'action-center',
                        width: 130,
                        Cell: ({ row, original }) => {
                            return (
                                <div>
                                    {
                                        row._original.created_by
                                            ?
                                            row._original.created_by.image
                                                ?
                                                <a rel="noopener noreferrer" href={`mailto:${row._original.created_by.email}`} target="_blank" style={{ backgroundColor: '#ffffa0' }} >
                                                    <MyImage className="initialsLogo" alt="initialLogo" src={row._original.created_by.image.preview} />
                                                </a>

                                                :
                                                <a rel="noopener noreferrer" href={`mailto:${row._original.created_by.email}`} target="_blank" style={{ backgroundColor: '#ffffa0' }} >
                                                    {
                                                        TF.initialsLogo(row._original.created_by.bgColor, row._original.created_by.name)
                                                    }
                                                </a>
                                            :
                                            'system'
                                    }
                                    <div>{dateFormat(original.created_at, "mmm dd, yyyy - h:MM TT")} </div>
                                </div>
                            )
                        }
                    },
                    {
                        Header: 'Comment',
                        accessor: 'comment,created_by',
                        className: 'action-center',
                        Cell: ({ original }) => {
                            return (
                                <div style={{ width: '100%', textAlign: 'left' }} >
                                    <div className="flexElem commentUserDate">
                                        <span style={{ color: "#4f9bde" }}>{original.created_by ? original.created_by.name : 'system'}</span>
                                    </div>

                                    <div style={original.highlight ? { backgroundColor: '#fff8a8', padding: '3px 5px', display: 'flex' } : null} ><span>{original.comment}</span></div>
                                </div>
                            )
                        }
                        // headerStyle: { display: 'none' },
                    },

                    {
                        Header: 'Type of Comment',
                        accessor: 'comment_category.title',
                        className: 'action-center',
                        width: 130,
                        show: this.props.code === 'All'
                    },
                    {
                        Header: 'Actions',
                        accessor: 'id',
                        className: 'action-center',
                        width: 150,
                        Cell: ({ original, index }) => {
                            return (
                                <div>
                                    {
                                        original.created_by &&
                                        original.created_by.id === currentUser.user.id &&
                                        <React.Fragment>
                                            <OpenModalButton
                                                openModal={this.openEditModal.bind(this, original, index)}
                                                classButton={['editComment orderDetailsAction']}
                                                buttonName="&nbsp;"
                                                tooltipText="Edit Comment"
                                                classIcon={[]}
                                            />
                                            <OverlayTrigger placement="bottom" overlay={<Popover id='copyLink' >Delete Comment</Popover>} >
                                                <button id="deleteComment" onClick={this.confirmCommentDelete.bind(this, original.id, index)} type='button' className="deleteComment orderDetailsAction" >&nbsp;</button>
                                            </OverlayTrigger>
                                        </React.Fragment>
                                    }

                                    <OverlayTrigger placement="bottom" overlay={<Popover id='copyLink' >Highlight Comment</Popover>} >
                                        <button id="highlightComment" onClick={this.toggleCommentHighlightHandler.bind(this, original.id, index)} type='button' className="highlightComment orderDetailsAction" >&nbsp;</button>
                                    </OverlayTrigger>
                                </div>
                            );
                        }
                    }
                ]
            }
        ]
        return (
            <React.Fragment>
                <div className="flexElem" style={{ "flexWrap": "wrap", "justifyContent": "space-between" }}>
                    <div className={ (this.props.is_ttk && this.props.title === 'Contact Log') ? 'orderDetailsHeader ttkOrderHeaderColor': 'orderDetailsHeader bg_gray'} >
                        <span>
                            {this.props.title} Comments { (this.props.is_ttk && this.props.title === 'Contact Log') ? ' - TTK Order': ''}
                        </span>
                        {
                            /*
                           !this.props.addExceptTab.includes(this.props.code) &&
                           <span className='controls' >
                               <OpenModalButton
                                   openModal={this.openAddModal.bind(this)}
                                   classButton={['editIcon orderDetailsAction']}
                                   buttonName="Add Comment"
                                   tooltipText="Add Comment"
                                   classIcon={[]}
                               />
                           </span>
                           */
                        }
                    </div>
                </div>
                <Row>
                    <Col md={12}>
                        <div className='p-10' >
                            <div className='row' >
                                {
                                    /*
                                        <div className='col-md-4'>
                                            <div className='row flexElem flexResponsive align-items-center' >
                                                <div className='col-md-3'  >
                                                    <label className='font-weight-bold mb-none' >Search</label>
                                                </div>
                                                <div className='col-md-8' >
                                                    <input onChange={this.inputChangeHandler} name='filtered' value={this.state.filtered} className='form-control search_icon' placeholder='General Search' />
                                                </div>
                                            </div>
                                        </div>
                                    */
                                }
                                {
                                    /*
                                        <div className='col-md-4' >
                                            <div className='row flexElem flexResponsive align-items-center' >
                                                <div className='col-md-3' >
                                                    <label className='font-weight-bold mb-none' >Date From</label>
                                                </div>
                                                <div className='col-md-8' >
                                                    <DatePicker onChange={this.fromDateChangeHandler} className="form-control calender_icon" selected={this.state.from} name="from" />
                                                    {this.dateValidator.message('from', moment(this.state.from), [{ before: moment('2019-07-01') }])}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-4' >
                                            <div className='row flexElem flexResponsive align-items-center' >
                                                <div className='col-md-3' >
                                                    <label className='font-weight-bold mb-none' >Date To</label>
                                                </div>
                                                <div className='col-md-8' >
                                                    <DatePicker onChange={this.toDateChangeHandler} className="form-control calender_icon" selected={this.state.to} name="to" />
                                                    {this.dateValidator.message('to', moment(this.state.to), [{ after: moment('2019-07-10') }])}
                                                </div>
                                            </div>
                                        </div>
                                    */
                                }
                                {
                                    !this.state.commentEditFormModal
                                        ?
                                        !this.props.addExceptTab.includes(this.props.code) &&
                                        <div className='col-md-12'>
                                            {
                                                (code === 'ContactLog' && customerServiceDetailShow)
                                                &&
                                                <React.Fragment>
                                                    <CustomerServiceDetail
                                                        orderId={order_details.id}
                                                        pricing_details={pricing_details}
                                                        order_details={order_details}
                                                        programType={programType}
                                                        getOrderCall={this.props.getOrderCall}
                                                        shipping_details={shipping_details}
                                                        csr_steps={csr_steps}

                                                    />
                                                    <hr style={{ 'borderColor': '#2f4576', 'borderWidth': '9px' }} />
                                                </React.Fragment>
                                            }
                                            <Card title={
                                                ('csrDaysBracket' in order_details && code === 'ContactLog' && showSteps)
                                                    ?
                                                    order_details.depatureDateDiffCreate <= 0
                                                        ?

                                                        `${order_details.csrDaysBracket}`
                                                        :
                                                        `${order_details.csrDaysBracket} days before the departure date`
                                                    :
                                                    null
                                            } bsClass={["innerCard"]} content={
                                                <div className="flexElem">

                                                    {
                                                        (code === 'ContactLog' && showSteps)
                                                        &&
                                                        <CsrSteps
                                                            updateComments={this.updateComments}
                                                        />
                                                    }
                                                    <div style={{ flexBasis: '100%' }}>
                                                        {
                                                            ("flag_follow_up" in order_details.settings && code === 'ContactLog' && showSteps) &&
                                                            <div className="flexElem alignCenter spaceBetween mb-md">
                                                                {
                                                                    order_details.depatureDateDiff >= 5 &&
                                                                    <div>
                                                                        {

                                                                            <OverlayTrigger placement="bottom" overlay={
                                                                                <Popover id="tooltip">
                                                                                    Ship Now, Leave on list
                                                                                    </Popover>
                                                                            }>
                                                                                <button onClick={this.props.updateOrderSettingsCall} disabled={Number(order_details.settings.approve_for_shipping.value) ? true : false} name='approve_for_shipping' value={Number(order_details.settings.approve_for_shipping.value)} className="orderDetailsAction">Ship Now, Leave on list </button>
                                                                            </OverlayTrigger>


                                                                        }
                                                                    </div>
                                                                }

                                                                <div>
                                                                    {
                                                                        parseInt(order_details.settings.flag_follow_up.value, 10) === 0
                                                                            ?
                                                                            <OverlayTrigger placement="bottom" overlay={
                                                                                <Popover id="tooltip">
                                                                                    Add to Orders Flagged For Follow Up List
                                                                                </Popover>
                                                                            }>
                                                                                <button onClick={this.props.updateOrderSettingsCall} name='flag_follow_up' value={Number(order_details.settings.flag_follow_up.value)} className="flagIcon orderDetailsAction">Flag For Follow Up</button>
                                                                            </OverlayTrigger>

                                                                            :
                                                                            <OverlayTrigger placement="bottom" overlay={
                                                                                <Popover id="tooltip">
                                                                                    Order Was Flagged For Follow Up on {order_details.settings.flag_follow_up.updated_at}
                                                                                </Popover>
                                                                            }>
                                                                                <button onClick={this.props.updateOrderSettingsCall} name='flag_follow_up' value={Number(order_details.settings.flag_follow_up.value)} className="clearIcon orderDetailsAction">Unflag </button>
                                                                            </OverlayTrigger>
                                                                    }
                                                                </div>
                                                            </div>
                                                        }

                                                        <div className={code === 'ContactLog' ? 'contactLogComments' : 'flexElem alignCenter'}>

                                                            <div style={{ flexBasis: '100%' }}>
                                                                <textarea rows={code === 'ContactLog' && showSteps ? '9' : ''} name='comment' onChange={this.commentChangeHandler} className='form-control' value={this.state.comment.comment} />
                                                                {this.validator.message('comment', this.state.comment.comment, 'required')}
                                                            </div>


                                                            <div>
                                                                <div className="text-center">
                                                                    <button ref={btn => this.addBtn = btn} name='add' type="button" className='backButton pt-sm no_radius pb-sm primary btn btn-md btn-info ml-sm' onClick={this.commentEditSubmitHandler} >Add Comment</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            } />
                                        </div>
                                        :
                                        <div className='col-md-12' >
                                            <Card bsClass={["innerCard"]} content={
                                                <div className='clearfix' >
                                                    {
                                                        <React.Fragment>
                                                            <div className='col-md-10'>
                                                                <textarea name='comment' onChange={this.commentChangeHandler} className='form-control' value={this.state.comment.comment} />
                                                                {this.validator.message('comment', this.state.comment.comment, 'required')}
                                                            </div>
                                                            <div className="col-md-2">
                                                                <button name='edit' type="button" className='backButton pt-sm no_radius pb-sm primary btn btn-md btn-info ml-sm mt-sm' onClick={this.commentEditSubmitHandler} >Update</button>
                                                            </div>
                                                        </React.Fragment>
                                                    }


                                                </div>
                                            } />
                                        </div>
                                }
                                {
                                    code === 'ContactLog' && formType === 'edit' &&
                                    <React.Fragment>

                                        {
                                            hasRole(['admin']) &&
                                            <div className='col-md-12' >
                                                <div className='' >
                                                    {
                                                        /*
                                                        "multiple_attempts_for_shipping" in order_details.settings &&
                                                        <div className="display_ib mt-md">
                                                            {
                                                                parseInt(order_details.settings.multiple_attempts_for_shipping.value, 10) === 0
                                                                    ?
                                                                    <OverlayTrigger placement="bottom" overlay={
                                                                        <Popover id="tooltip">
                                                                            Add to Multiple Attempts Made- Approve for Shipping list
                                                                        </Popover>
                                                                    }>
                                                                        <button onClick={this.props.updateOrderSettingsCall} name='multiple_attempts_for_shipping' value={Number(order_details.settings.multiple_attempts_for_shipping.value)} className="assignIcon orderDetailsAction">Multiple attempts for shipping</button>
                                                                    </OverlayTrigger>

                                                                    :
                                                                    <OverlayTrigger placement="bottom" overlay={
                                                                        <Popover id="tooltip">
                                                                            Order Was Added to Multiple Attempts Made- Approve for Shipping List on {order_details.settings.flag_follow_up.updated_at}
                                                                        </Popover>
                                                                    }>
                                                                        <button onClick={this.props.updateOrderSettingsCall} name='multiple_attempts_for_shipping' value={Number(order_details.settings.multiple_attempts_for_shipping.value)} className="deleteIcon orderDetailsAction">Multiple attempts for shipping</button>
                                                                    </OverlayTrigger>
                                                            }
                                                        </div>

                                                        "contacted_prior_departure" in order_details.settings &&
                                                        <div className="display_ib ml-md mt-md">
                                                            {
                                                                parseInt(order_details.settings.contacted_prior_departure.value, 10) === 0
                                                                    ?
                                                                    <OverlayTrigger placement="bottom" overlay={
                                                                        <Popover id="tooltip">
                                                                            Approve Contacted Prior to departure
                                                                        </Popover>
                                                                    }>
                                                                        <button onClick={this.props.updateOrderSettingsCall} name='contacted_prior_departure' value={Number(order_details.settings.contacted_prior_departure.value)} className="assignIcon orderDetailsAction">Approve Contacted Prior To Departure</button>
                                                                    </OverlayTrigger>

                                                                    :
                                                                    <OverlayTrigger placement="bottom" overlay={
                                                                        <Popover id="tooltip">
                                                                            Disapprove Contacted Prior to departure
                                                                        </Popover>
                                                                    }>
                                                                        <button  onClick={this.props.updateOrderSettingsCall} name='contacted_prior_departure' value={Number(order_details.settings.contacted_prior_departure.value)} className="clearIcon orderDetailsAction">Disapprove Contacted Prior To Departure</button>
                                                                    </OverlayTrigger>
                                                            }
                                                        </div>



                                                        "approve_shipping_still_need_contact" in order_details.settings &&
                                                        <div className="display_ib ml-md mt-md">
                                                            {
                                                                parseInt(order_details.settings.approve_shipping_still_need_contact.value, 10) === 0
                                                                    ?
                                                                    <OverlayTrigger placement="bottom" overlay={
                                                                        <Popover id="tooltip">
                                                                            Approve Shipping Still Need To Contact
                                                                        </Popover>
                                                                    }>
                                                                        <button onClick={this.props.updateOrderSettingsCall} name='approve_shipping_still_need_contact' value={Number(order_details.settings.approve_shipping_still_need_contact.value)}  className="assignIcon orderDetailsAction">Approve Shipping Contact</button>
                                                                    </OverlayTrigger>

                                                                    :
                                                                    <OverlayTrigger placement="bottom" overlay={
                                                                        <Popover id="tooltip">
                                                                            Disapprove Shipping Still Need To Contact
                                                                        </Popover>
                                                                    }>
                                                                        <button onClick={this.props.updateOrderSettingsCall} name='approve_shipping_still_need_contact' value={Number(order_details.settings.approve_shipping_still_need_contact.value)} className="clearIcon orderDetailsAction">Disapprove Shipping Contact</button>
                                                                    </OverlayTrigger>
                                                            }
                                                        </div>



                                                        "remove_from_contact_list" in order_details.settings &&
                                                        <div className="display_ib ml-md mt-md">
                                                            {
                                                                parseInt(order_details.settings.remove_from_contact_list.value, 10) === 0
                                                                    ?
                                                                    <OverlayTrigger placement="bottom" overlay={
                                                                        <Popover id="tooltip">
                                                                            Remove From Contact List
                                                                        </Popover>
                                                                    }>
                                                                        <button onClick={this.props.updateOrderSettingsCall} name='remove_from_contact_list' value={Number(order_details.settings.remove_from_contact_list.value)} className="assignIcon orderDetailsAction">Remove From Contact List</button>
                                                                    </OverlayTrigger>

                                                                    :
                                                                    <OverlayTrigger placement="bottom" overlay={
                                                                        <Popover id="tooltip">
                                                                            Add To Contact List
                                                                        </Popover>
                                                                    }>
                                                                        <button onClick={this.props.updateOrderSettingsCall} name='remove_from_contact_list' value={Number(order_details.settings.remove_from_contact_list.value)} className="clearIcon orderDetailsAction">Add To Contact List</button>
                                                                    </OverlayTrigger>
                                                            }
                                                        </div>
                                                        */
                                                    }


                                                </div>
                                            </div>
                                        }


                                    </React.Fragment>
                                }
                            </div>
                        </div>

                        {/* <ReactTable
                            className="-striped"
                            noDataText='No comments found'
                            manual
                            ref={this.table}
                            data={this.props.comments.data}
                            filtered={this.state.filtered}
                            columns={columns}
                            getTableProps={this.working}
                            pages={this.props.comments.last_page}
                            from={this.state.from}
                            to={this.state.to}
                            defaultPageSize={this.state.pageSize}
                            style={{
                                height: "400px" // This will force the table body to overflow and scroll, since there is not enough room
                            }}
                            onFetchData={(state) => {
                                var sort = state.sorted.length === 0 ? '' : state.sorted[0].id + ',desc:' + state.sorted[0].desc;
                                var from = state.from !== '' ? new Date(state.from).toDateString() : '';
                                var to = state.to !== '' ? new Date(state.to).toDateString() : '';
                                this.props.getAllCommentsCall(state.page + 1, state.filtered, sort, state.pageSize, this.props.comment_category_id, this.props.modelId, from, to, this.props.order_details.uuid)
                            }}
                        /> */}
                        <Pagination
                            ref={(p) => this.pagination = p}
                            showPagination={false}
                            columns={columns}
                            pageSize={20}
                            getDataCall={this.paginationCall}
                            noDataText='No Comments Found'
                            filterView={false}
                            /*style= { code === 'ContactLog' ? {height:'165px'}:null }*/
                            // downloadData={true}
                            // downloadFileName={'Orders'}
                            // lowerContent = {null}
                            // filterPlaceHolder = {'Orders'}
                            getRowProps={this.getRowProps}
                        // showAllToggle={true}
                        />
                        {
                            /*
                        <Modal backdrop={'static'} show={this.state.commentEditFormModal} onHide={closeModal.bind(this, 'commentEditFormModal')} aria-labelledby="ModalHeader" >
                            <Modal.Header closeButton>
                                <Modal.Title id='ModalHeader' className="headerTitle">Edit Comment</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Card bsClass={["innerCard"]} content={
                                    <div className='clearfix' >
                                        {
                                            <React.Fragment>
                                                <textarea name='comment' onChange={this.commentChangeHandler} className='form-control' value={this.state.comment.comment} />
                                                {this.validator.message('comment', this.state.comment.comment, 'required')}
                                            </React.Fragment>
                                        }

                                        <div className="text-center">
                                            <button name='edit' type="button" className='backButton pt-sm no_radius pb-sm primary btn btn-md btn-info ml-sm mt-sm' onClick={this.commentEditSubmitHandler} >Save</button>
                                        </div>
                                    </div>
                                } />
                            </Modal.Body>
                        </Modal>

                            <Modal backdrop={'static'} show={this.state.commentAddFormModal} onHide={closeModal.bind(this, 'commentAddFormModal')} aria-labelledby="ModalHeader" >
                                <Modal.Header closeButton>
                                    <Modal.Title id='ModalHeader' className="headerTitle">Add Comment</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Card bsClass={["innerCard"]} content={
                                        <div className='clearfix' >
                                            {
                                                code === 'ContactLog'
                                                ?
                                                    <React.Fragment>
                                                        <Select
                                                            value={selectedOption}
                                                            onChange={this.commentChangeHandlerContactLog}
                                                            options={contactLogTypes}
                                                            isSearchable={true}
                                                            isMulti={false}
                                                            placeholder={'Select ContactLog'}
                                                            isClearable={true}
                                                        />
                                                        <textarea name='comment' placeholder='Enter Comment' disabled={ selectedOption?true:false} onChange={this.commentChangeHandler} className='form-control' value={this.state.comment.comment} />
                                                        {this.validator.message('comment', this.state.comment.comment, 'required')}
                                                    </React.Fragment>
                                                :
                                                    <React.Fragment>
                                                        <textarea name='comment' onChange={this.commentChangeHandler} className='form-control' value={this.state.comment.comment} />
                                                        {this.validator.message('comment', this.state.comment.comment, 'required')}
                                                    </React.Fragment>
                                            }


                                            <div className="text-center">
                                                <button name='add' type="button" className='backButton pt-sm no_radius pb-sm primary btn btn-md btn-info ml-sm mt-sm' onClick={this.commentEditSubmitHandler} >save</button>
                                            </div>
                                        </div>
                                    } />
                                </Modal.Body>
                            </Modal>
                            */
                        }
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    const { comments, submitted, success, contactLogTypes } = state.commentReducer;
    const { loadingOrder, shipping_details, pricing_details, production_details, csr_steps, is_ttk } = state.ordersReducer;
    const { programType } = state.settingsReducer;
    // const { csr_steps } = state.ordersReducer;
    // const { order_details }     = state.ordersReducer;

    return {
        comments, submitted, success, contactLogTypes,
        loadingOrder, shipping_details, pricing_details, production_details, csr_steps,
        programType, is_ttk
    };
}

const mapDispatchToProps = dispatch => {
    return {
        getOrderCall: (orderId) => { dispatch(getOrder(orderId)) },
        getAllCommentsCall: (page, filter, sort, pageSize, cat_id, modelId, from, to, uuid) => { return dispatch(getAllComments(page, filter, sort, pageSize, cat_id, modelId, from, to, uuid)) },
        deleteCommentCall: (id) => { return dispatch(deleteComment(id)) },
        addCommentCall: (data, modelId) => { return dispatch(addComment(data, modelId)) },
        editCommentCall: (data, id, comments) => { return dispatch(editComment(data, id, comments)) },
        toggleCommentHighlightHandlerCall: (id, comments) => { dispatch(toggleCommentHighlight(id, comments)) },
        getContactLogTypesCall: (orderId) => { dispatch(getContactLogTypes(orderId)) },
        // updateSettingsDataCall: (model_id, key, settingData) => { dispatch(updateSettingsData(model_id, key, settingData)) },
        updateOrderCsrStepCall: (model_id, id, data) => { dispatch(updateOrderCsrStep(model_id, id, data)) },
        // getProgramTypeCall: () => { dispatch(getProgramType()) },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CommentList));
