import { combineReducers } from "redux";
import { adminReducer } from "./AdminReducer";
import { alert } from "./AlertReducer";
import { userReducer } from "./UserReducer";
import { rolesReducer } from "./RolesReducer";
import { permissionsReducer } from "./PermissionReducer";
import { frontEndUserReducer } from './FrontEndReducer';
import { agenciesReducer } from './AgencyReducer';
import { agencyUserReducer } from './AgencyUserReducer';
import { agencyResourceReducer } from './AgencyResourceReducer';
import { settingsReducer } from './SettingsReducer';
import { ordersReducer } from './OrderReducer';
import { moduleReducer } from "./ModuleReducer";
import { ShippingReducer } from "./ShippingReducer";
import { commentReducer } from "./CommentReducer";
import { holidayReducer } from "./HolidayReducer";
import { accountReducer } from "./AccountReducer";
import { itemCategoryReducer } from "./ItemCategoryReducer";
import { programTypeReducer } from "./ProgramTypeReducer";
import { agencyTypeReducer } from "./AgencyTypeReducer";
import { itemReducer } from "./ItemReducer";
import testimonialReducer from "./testimonialReducer";
import { musicLibraryReducer } from "./MusicLibraryReducer";
import { destinationsReducer } from "./DestinationReducer";
import { customMusicReducer } from "./customMusicReducer";
import { proShowFileReducer } from "./ProShowFileReducer";
import { mosaicReducer } from "./mosaicReducer";
import { brandsReducer } from "./BrandReducer";
import { reportReducer } from "./ReportReducer";
import { invoicingReducer } from "./InvoicingReducer";
import { moreButtonReducer } from "./MoreButtonReducer";

const rootReducer = combineReducers({
  adminReducer,
  userReducer,
  frontEndUserReducer,
  rolesReducer,
  permissionsReducer,
  agenciesReducer,
  agencyUserReducer,
  settingsReducer,
  ordersReducer,
  agencyResourceReducer,
  alert,
  moduleReducer,
  ShippingReducer,
  commentReducer,
  holidayReducer,
  accountReducer,
  itemCategoryReducer,
  programTypeReducer,
  agencyTypeReducer,
  itemReducer,
  testimonialReducer,
  musicLibraryReducer,
  destinationsReducer,
  customMusicReducer,
  proShowFileReducer,
  mosaicReducer,
  brandsReducer,
  reportReducer,
  invoicingReducer,
  moreButtonReducer,
});

export default rootReducer;
