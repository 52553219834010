import React, { Component, Fragment } from "react";
import { adminLabels } from 'redux/constant/admin-label-constant';
import { s3StaticPath } from 'helper/helperFunctions';
import $ from 'jquery';
import ReactPlayer from 'react-player';
// import screenfull from 'screenfull';
import { findDOMNode } from 'react-dom';
import VideoPreview from 'components/Upload/VideoPreview';

const previewStyle = {
	display: 'inline-block',
	width: 100,
	height: 100,
};

class ImagePreview extends Component {
	constructor(props) {
		super(props);


		this.previewImg = this.previewImg.bind(this);
		this.previewFile = this.previewFile.bind(this);
		this.previewVideo = this.previewVideo.bind(this);
		this.preview = this.preview.bind(this);
	}
	componentDidMount() {
		let stl = $(".imgContainer .prevImage").attr("style");
	}
	preview = (preview, name, index, type) => {
		if (this.props.type === 'original_mosaic') {
			return this.previewMozaix(preview, name, index);
		} else if (adminLabels.IMAGESHOW.includes(type)) {
			return this.previewImg(preview, name, index);
		} else if (adminLabels.VIDEOSHOW.includes(type)) {
			return this.previewVideo(preview, name, index);
		} else {
			return this.previewFile(preview, name, index);
		}
	}
	handleSetPlaybackRate = e => {
		this.setState({ playbackRate: parseFloat(e.target.value) })
	}

	handleClickFullscreen = () => {
		console.log('e',findDOMNode(this.player))
		// screenfull.request()
	}
	ref = player => {
		this.player = player
	}
	previewVideo = (src, name, index = null) => {
		return (

				<React.Fragment>
					<VideoPreview hideVideoControls={this.props.hideVideoControls} src={src} name={name} id={index}/>
					{
						(this.props.removeFile && index !== null) &&
						<div className="prevRemove">
							<span onClick={() => this.props.removeFile(this.props.type ? this.props.type : null, index)}>
								&times;
							</span>
						</div>
					}
				</React.Fragment>


		);
	}
	previewMozaix = (src, name, index = null) => {
		return (
			<div key={index}>
				<div className="imgContainer">
					{/*<Img
						className="prevImage"
						alt="Preview"
						key={src}
						src={src}
					/>
					*/}


					<img
						id={this.props.id}
						className="prevImage"
						alt="Preview"
						key={src}
						src={src}
						style={{...this.props.styleProp}}
						onLoad = { this.props.onLoad }
					/>





				</div>
				{
					this.props.showName &&
					<div className="prevName">
						{name}
					</div>
				}
				{
					(this.props.removeFile && index !== null || this.props.removeFile && this.props.resetImage) &&
					<div className="prevRemove">
						<span onClick={() => this.props.removeFile(this.props.type ? this.props.type : null, index)}>
							&times;
						</span>
					</div>
				}
			</div>
		);
	}
	previewImg = (src, name, index = null) => {
		return (
			<div key={index} className="prevImgContainer" style={this.props.width ? this.props.width : {}}>
				<div className="imgContainer" style={this.props.height ? this.props.height : {}}>
					{/*<Img
						className="prevImage"
						alt="Preview"
						key={src}
						src={src}
					/>
					*/}


					<img
						id={this.props.id}
						className="prevImage"
						alt="Preview"
						key={src}
						src={src}
						style={{...this.props.styleProp}}
						onLoad = { this.props.onLoad }
					/>





				</div>
				{
					this.props.showName &&
					<div className="prevName">
						{name}
					</div>
				}
				{
					(this.props.removeFile && index !== null || this.props.removeFile && this.props.resetImage) &&
					<div className="prevRemove">
						<span onClick={() => this.props.removeFile(this.props.type ? this.props.type : null, index)}>
							&times;
						</span>
					</div>
				}
			</div>
		);
	}
	previewFile = (src, name, index = null) => {
		return (
			<div key={index} className="prevImgContainer">
				{
					/*<Img
					alt="Preview"
					key={src}
					src={s3StaticPath('img/File-icon.svg')}
					style={previewStyle}
				/>
*/
				}

				<img
					alt="Preview"
					key={src}
					src={s3StaticPath('img/File-icon.svg')}
					style={previewStyle}
				/>

				<div>
					{name}
				</div>
				{
					(this.props.removeFile && index !== null) &&
					<div className="prevRemove">
						<span onClick={() => this.props.removeFile(this.props.type ? this.props.type : null, index)}>
							&times;
						</span>
					</div>

				}
			</div>
		);
	}
	render() {
		const { filesToPreview } = this.props;
		// console.log('hahahh', filesToPreview);
		return (
			<div className="content">
				{
					filesToPreview ?
						Array.isArray(filesToPreview) ?
							<div className="uploadImages flexElem flexResponsive">
								{
									filesToPreview.map((file, index) => (
										file.name
										?
											file.isMozaix
											?
												this.previewMozaix(file.preview, file.name, index)
											:
                                                adminLabels.IMAGESHOW.includes(file.type)
                                                ?
                                                    this.previewImg(file.preview, file.name, index)
                                                :
                                                this.previewFile(file.preview, file.name, index)
										:
										null
									)
									)
								}
							</div> :
							<Fragment>
								{
									filesToPreview.name ?
										this.preview(filesToPreview.preview, filesToPreview.name, filesToPreview.id, filesToPreview.type)
										/*
										adminLabels.IMAGESHOW.includes(filesToPreview.type) ?
											this.previewImg(filesToPreview.preview, filesToPreview.name, 0)

										:
											this.previewFile(filesToPreview.preview, filesToPreview.name, 0)
										*/


										: null
								}
							</Fragment>
						: null
				}
			</div>
		);
	};
}

export default ImagePreview;
