import React, { Component } from 'react'
import Comment from 'components/FrontEnd/Order/OrderForm/Comment';
import { s3StaticPath } from '../../../../helper/helperFunctions';

export default class OrderComments extends Component {
    constructor(props){
    super(props);
    this.state={
        submitComment: false
    }
}
componentDidUpdate(prevProps, prevState) {
    if (this.props.formSubmitted && (this.props.formSubmitted !== prevProps.formSubmitted)) {
        this.setState({
            submitComment: true
        }, () => {
            this.setState({
                submitComment: false
            })
        });
    }
}
    render() {
        return (
            <div className="form_tile">
                <div className="form_tile_heading">
                    <img src={s3StaticPath('img/documents.png')} alt="Comments" />
                    <span>Comments</span>
                </div>

                <div className="form_tile_content">
                {
                    this.props.notEditable === undefined &&

                    <React.Fragment>
                        <div className="flexElem respFlex spaceBetween form_tile_row">
                            <div className="col-md-12">
                                <span className="text_14">Note: This is also visible to the Group Leader.</span>
                            </div>
                        </div>

                        <div className="flexElem respFlex spaceBetween form_tile_row">
                            <div className="col-md-12">
                                <Comment createdOrderBarcode={this.props.createdOrderBarcode} order_id={this.props.id} formType={this.props.formType} submitComment={this.state.submitComment} />
                            </div>
                        </div>
                    </React.Fragment>
                }
            </div>
        </div>
        )
    }
}

