import React, { Component } from "react";
import {
	OverlayTrigger,
	Popover,
} from "react-bootstrap";
import { confirmAlert } from 'react-confirm-alert';
import ConfirmEditTracking from "./ConfirmEditTracking";

class openModalButton extends Component {

    editConfirmTracking = () => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <ConfirmEditTracking typeName="Shipping Detail" notChangeable={this.props.notChangeable} name={this.props.name} description="Are you sure you want to edit this " onClosePro={onClose} editModel={this.props.openModal} />
                )
            }
        })
    }
	render() {
		const { classButton, tooltipText, buttonName, classIcon, tracking_number, notChangeable } = this.props;
		return (
			<OverlayTrigger placement="bottom" trigger={['hover']} overlay={<Popover id="tooltip"> {tooltipText} </Popover>}>
                {
                  tracking_number != null || notChangeable ?
                        <button disabled={this.props.disabled} type="button" className={classButton.join(' ')} onClick={() => this.editConfirmTracking()}>
                            {
                                classIcon.length > 0 &&
                                <i className={classIcon.join(' ')} />
                            }
                            {buttonName}
                        </button>
                    :
                    <button disabled={this.props.disabled} type="button" className={classButton.join(' ')} onClick={this.props.openModal}>
                            {
                                classIcon.length > 0 &&
                                <i className={classIcon.join(' ')} />
                            }
                            {buttonName}
                        </button>
                }
			</OverlayTrigger>
		);
	}
}

export default openModalButton;
