import React, { Component } from "react";
import { alertActions } from 'redux/actions/alert-actions';
import {
  toast
} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { connect } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';

import { history } from 'helper/history';
var isEqual = require('lodash.isequal');
toast.configure({
    autoClose: 10000,
    draggable: true,
    position: "bottom-right",
});
class MessagePage extends Component {
	constructor(props) {
	    super(props);
	    this.errorMessage = this.errorMessage.bind(this);
	}

	ClearAfterSomeTime(){
    const { dispatch } = this.props;
    dispatch(alertActions.clear());
   }
    serverFormValidation(data) {
        var j = 0;
        var exceptions = [];
        const valItems  = Object.keys(data).map(x=> {
            if (data && data[x]) {
              if(typeof data[x] === 'object'){
                  return (data[x].map((y,i)=> {
                    return (<li key={i}>{y}</li>)
                  }));
              }
              else if (typeof data[x] === 'string' || typeof data[x] === 'number'){
                let response = (<li key={j}>{data[x]}</li>)
                exceptions.push(response);
                j++;
                return '';
              }
            }
        });
        if(exceptions.length > 0) {
          return exceptions;
        }
        return valItems;
    }
  changeRoute = (url) => {
    if (url === history.location.pathname) {
      history.go(0);
    } else {
        history.push(url);
    }
  }
  errorMessage() {
      const alert=this.props.alert;
      if (typeof alert.message === 'string'){
          const valDiv = <span>{ ReactHtmlParser(alert.message) } </span>;
          return valDiv;
      }

      if (typeof alert.message === 'object' && alert.notiType === 'notification'){
          return (
             <table>
              <tbody>
              <tr>
                <td colSpan={alert.message.url?1:2}>{ alert.message.title }</td>
                {
                  alert.message.url &&
                  <td><button onClick={ ()=>this.changeRoute(alert.message.url) } className="blue_link">Go to</button></td>
                }
              </tr>
              <tr>
                <td className="notification-title" colSpan="2">{alert.message.message}</td>
              </tr>
              </tbody>
            </table>
          );
      }
      if (typeof alert.message === 'object' && alert.notiType === 'default'){
        // console.log('alert.message', alert.message);
          const valDiv = <ul style={{margin:"0"}}>{this.serverFormValidation(alert.message)}</ul>;
          return valDiv;
      }
  }
  generateToast(type, message, autoClose){
    if (type === 'alert-danger') {
        if (autoClose === 'off') {
            return (
              toast.error(this.errorMessage(message), {
                  onClose: () => this.ClearAfterSomeTime(),
                  autoClose:false,
              })
            )
        } else {
            return (
                toast.error(this.errorMessage(message), {
                    onClose: () => this.ClearAfterSomeTime()
                })
              )
        }
    } else if (type === 'alert-success') {

      if (autoClose === 'off') {
        return (
          toast.success(this.errorMessage(message), {
              onClose: () => this.ClearAfterSomeTime(),
              autoClose:false,
            })
        )
      } else {
        return (
          toast.success(this.errorMessage(message), {
              onClose: () => this.ClearAfterSomeTime()
            })
        )
      }

    } else if (type === 'alert-info') {
      return (
        toast.info(this.errorMessage(message), {
            onClose: () => this.ClearAfterSomeTime()
          })
      )
    }  else {
      return (
        toast(this.errorMessage(message), {
            onClose: () => this.ClearAfterSomeTime()
          })
      )
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!isEqual(prevProps.alert, this.props.alert)) {
      if (this.props.alert.message){
        this.generateToast(this.props.alert.type, ReactHtmlParser(this.props.alert.message), this.props.alert.autoClose);
      }
    }
  }

  render(){
  	return(
      <React.Fragment></React.Fragment>
  	);
  }
}


function mapStateToProps(state) {
    const { alert } = state;
    const typeOfAlert = typeof alert.message;
    return {
        alert,
        typeOfAlert
    };
}

const MessageCom = connect(mapStateToProps)(MessagePage);

export default MessageCom;
