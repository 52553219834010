import React from 'react'
import { s3StaticPath } from '../../helper/helperFunctions'
import $ from 'jquery';

function handleHover() {
    // setState(prevState => ({
    //     isHovered: !prevState.isHovered
    // }));

    $("#clients div.client").hover(function () {
        $("#clients div.client").removeClass("pink_gradient")
        $(this).addClass("pink_gradient")
    }, function () {
        $("#clients div.client").removeClass("pink_gradient")
    })
}
const MainClients = (props) => {
    return (
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="clients">
                        <div className="client" onMouseEnter={handleHover} onMouseLeave={handleHover}>
                            <img src={s3StaticPath('img/syta_logo.png')} alt="Client name" />
                        </div>
                        <div className="client" onMouseEnter={handleHover} onMouseLeave={handleHover}>
                            <img src={s3StaticPath('img/aba_logo.jpg')} alt="Client name" />
                        </div>
                        <div className="client" onMouseEnter={handleHover} onMouseLeave={handleHover}>
                            <img src={s3StaticPath('img/NTA_logo.jpg')} alt="Client name" />
                        </div>
                        <div className="client" onMouseEnter={handleHover} onMouseLeave={handleHover}>
                            <img src={s3StaticPath('img/tap_logo.png')} alt="Client name" />
                        </div>
                        <div className="client" onMouseEnter={handleHover} onMouseLeave={handleHover}>
                            <img src={s3StaticPath('img/vc_logo.jpg')} alt="Client name" />
                        </div>
                        <div className="client" onMouseEnter={handleHover} onMouseLeave={handleHover}>
                            <img src={s3StaticPath('img/minthillchamberofcommerce.png')} alt="Client name" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MainClients