import { adminConstants } from "../constant/admin-constant";

const initialState = {
    itemCategories: []
}

export const itemCategoryReducer = (state = initialState, action) => {
    switch (action.type) {

        case adminConstants.GET_ALL_ITEM_CATEGORIES_SUCCESS:
            return {
                ...state,
                itemCategories: action.payload.data
            }
        case adminConstants.GET_ALL_ITEM_CATEGORIES_FAILURE:
            return {
                ...state,
                error: action.payload.error
            }

        default:
            return state
    }
}
