import React from "react";
import ReactCalendar from "react-calendar";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { connect } from "react-redux";
var Holidays = require('date-holidays');
var dateFormat = require('dateformat');
var cloneDeep = require('lodash.clonedeep');
const hd = new Holidays('US');
class Calendar extends React.Component {
    constructor(props) {
        super(props);
        
        var cd = new Date();
        this.state = {
            holidays : hd.getHolidays(),
            years : [cd.getFullYear()]
            
        }
        this.getHolidays = this.getHolidays.bind(this);
    }
    getHolidays(date, func) {
        var year =  parseInt(dateFormat(date, "yyyy"), 10);
        if (!this.state.years.includes(year)) {
            var yearsTemp  = cloneDeep(this.state.years);
            yearsTemp.push(year);
            this.setState({years:yearsTemp}, ()=>{
                var holidaysTemp = cloneDeep(this.state.holidays);
                holidaysTemp = [...this.state.holidays, ...hd.getHolidays(year)]
                this.setState({holidays:holidaysTemp});
            });
        }
        const holidays = [...this.state.holidays];
        // console.log('holidays', holidays);
        let status;
        holidays.forEach(holiday => {
            
            if (dateFormat(date, "yyyy-mm-dd") === dateFormat(holiday.date, "yyyy-mm-dd")) {
                
                if (func === 'disabled') {
                    status = true;
                }
                if (func === 'content') {
                    status = (<OverlayTrigger placement="bottom" overlay={<Popover id='tooltip' >{holiday.name} <br/>{'Type: '+holiday.type}</Popover>}>
                        <p style={{ color: 'red', fontSize: '10px', marginBottom: '0px' }} >Holiday</p>
                    </OverlayTrigger>);
                }
                this.count = this.count + 1;
            }
        });
        return status;
    }

    render() {
        return (
            <ReactCalendar
                calendarType='US'
                view='month'
                showNeighboringMonth={false}
                tileDisabled={(this.state.holidays.length >= 1) ? ({ date }) => this.getHolidays(date, 'disabled') : null}
                tileContent={(this.state.holidays.length >= 1) ? ({ date }) => this.getHolidays(date, 'content') : null}
                className='ml-auto'
                value={new Date()} 
                onClick={this.onClick}
            />
        );
    }
}

const mapStateToProps = state => {
    return {}
}

const mapDispatchToProps = dispatch => {
    return {
        // getHolidaysCall: (id) => { dispatch(holidayActions.getHolidays(id)) }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Calendar);
