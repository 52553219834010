import { SC } from "../helper/ServerCall";

export const programTypeService = {
    storeProgramType,
    getProgramType,
    updateProgramType,
    getProgramTypesPagination,
    deleteProgramType
}

function storeProgramType(data) {
    return SC.postCall(`programType`, data);
}

function getProgramType(id) {
    return SC.getCall(`programType/${id}`);
}

function updateProgramType(data, id) {
    return SC.putCall(`programType/${id}`, data);
}

function getProgramTypesPagination(page, filter, sort, pageSize, orderType) {
    return SC.postCall(`getProgramTypesPagination?page=${page}&filter=${filter}&sort=${sort}&pageSize=${pageSize}&orderType=${orderType}`);
}

function deleteProgramType(id) {
    return SC.deleteCall(`programType/${id}`);
}