import React, { Component } from 'react'
import { connect } from 'react-redux';
import $ from 'jquery';
import { Roles } from "constants/Roles";
import User from "helper/User";
import { Dashboard } from 'layouts/Frontend/Dashboard/Dashboard';
import { s3StaticPath } from 'helper/helperFunctions';
import { Link } from "react-router-dom";
import { Row, Col } from 'react-bootstrap';

// import MyImage from 'components/Upload/MyImage';

class UserHome extends Component {
    componentDidMount() {
        $(".faqList > .quest").click(function () {
            $(this).toggleClass("open");
            $(this).next(".ans").stop(true, true).slideToggle("fast");
        });

        $(window).resize(function () {
            setTimeout(function () {
                $("#how_it_works .banner").height($("#how_it_works .banner img").height());
            }, 300);
        });

        // var count = 10;
        $(window).scroll(function () {
            let a = $(this).scrollTop();
            a = a - (a / 5);
            $("#how_it_works .banner img").css({ position: 'relative', top: a + 'px' });
        });
    }
    render() {
        return (
            <main id="how_it_works">
                <section className="banner">
                    <img alt="Banner header" className='bgImage' src={s3StaticPath('img/home/slicing/gm/background_img.png')} />
                </section>

                <section >
                    <div className="container mt-md">
                        <div className="row">
                            <div className="col-md-9">
                                {
                                    (
                                        User.hasRole([Roles.PHOTO_ADMIN]) && !User.getProperty('cameraShipped') && User.getProperty('upload_approved') && Number(User.orderSettingValue('finalize_by_qa')) !== 1
                                    ) &&
                                    <h5 className="home_heading userLogin_heading twenty22">Your gallery will be available after your Pre-Trip Materials are sent.</h5>
                                }
                                <h2 className="home_heading">Features &amp; Benefits</h2>
                            </div>
                            {
                                User.hasRole([Roles.AGENCY_USER, Roles.AGENCY_USER_SC, Roles.AGENCY_USER_U]) &&
                                <div className="col-md-3 text-right">
                                    <Link className="home_new_order_btn pt-sm pb-sm  hover_dark" to={'/new-order'} ><i style={{ verticalAlign: 'bottom' }} className="noLmargin flaticon-shopping-cart"></i>&nbsp; New Order</Link>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="container mt-md">
                        <Row className="mb25 tabsContentUpdated">
                            <Col xs={12} sm={4} className="mt10">
                                <div className="border1 whiteBG" style={{ minHeight: '440px' }}>
                                    <img
                                        src={s3StaticPath('img/home/slicing/gm/1.jpg')}
                                        alt="App Features 1"
                                        className="mw100Force"
                                    />
                                    <div className="roundedOverlay_home">
                                        <img src={s3StaticPath('img/home/slicing/gm/free_mobile_app_icon.png')} alt="New-Free-Mobile-App" />
                                    </div>
                                    <div className="px1-25 py25 text-center" >
                                        <h6 className="secondary font-weight-bold lineH mb10F">
                                            Free Mobile App
                                        </h6>
                                        <span className="mt10">
                                            {/* Allows connection amongst the travelers and the family &amp; friends back home with real time photo uploads while on tour. */}
                                            Enables travelers to stay connected with their loved ones back home by providing the ability to upload real-time photos during their journey.
                                        </span>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={12} sm={4} className="mt10">
                                <div className="border1 whiteBG" style={{ minHeight: '440px' }}>
                                    <img
                                        src={s3StaticPath('img/home/slicing/gm/2.jpg')}
                                        alt="App Features 1"
                                        className="mw100Force"
                                    />
                                    <div className="roundedOverlay_home">
                                        <img src={s3StaticPath('img/home/slicing/gm/video_download_icon.png')} alt="Video-Downloads" />
                                    </div>
                                    <div className="px1-25 py25 text-center">
                                        <h6 className="secondary font-weight-bold lineH mb10F">
                                            Video Downloads
                                        </h6>
                                        <span>
                                            {/* Keepsake Video can be downloaded from the app or GroupMemories.co.uk gallery for offline viewing. */}
                                            You can download the Keepsake Video from the app or the GroupMemories.co.uk gallery to watch it offline.
                                        </span>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={12} sm={4} className="mt10">
                                <div className="border1 whiteBG" style={{ minHeight: '440px' }}>
                                    <img
                                        src={s3StaticPath('img/home/slicing/gm/3.png')}
                                        alt="App Features 1"
                                        className="mw100Force"
                                    />
                                    <div className="roundedOverlay_home">
                                        <img src={s3StaticPath('img/home/slicing/gm/video_clips_icon.png')} alt="Video-Clips" />
                                    </div>
                                    <div className="px1-25 py25 text-center">
                                        <h6 className="secondary font-weight-bold lineH mb10F">
                                            Video Clips and<br />Performance Music
                                        </h6>
                                        <span>
                                            {/* Travelers can include these in their videos for FREE */}
                                            Travelers have the option to incorporate these elements into their videos without incurring any additional charges.
                                        </span>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row className="mb25 tabsContentUpdated">
                            {/* <Col xs={12} sm={4} className="mt10">
                                <div className="border1 whiteBG" style={{ minHeight: '440px' }}>
                                    <img
                                        src={s3StaticPath('img/home/slicing/gm/4.jpg')}
                                        alt="App Features 1"
                                        className="mw100Force"
                                    />
                                    <div className="roundedOverlay_home">
                                        <img src={s3StaticPath('img/home/slicing/gm/usb_icon.png')} alt="USB-Flash" />
                                    </div>
                                    <div className="px1-25 py25 text-center" >
                                        <h6 className="secondary font-weight-bold lineH mb10F">
                                            USB Flash Drive for Group Leaders
                                        </h6>
                                        <span>
                                           Group leaders will conveniently have access to the photos and videos on a USB drive, which can be shipped within the US. This is an ideal resource for showcasing during parent meetings to attract potential future trip participants. Additionally, travelers also have the option to choose to order these materials on DVDs if they prefer.
                                        </span>
                                    </div>
                                </div>
                            </Col> */}
                            <Col xs={12} sm={4} className="mt10">
                                <div className="border1 whiteBG" style={{ minHeight: '455px' }}>
                                    <img
                                        src={s3StaticPath('img/home/slicing/gm/5.jpg')}
                                        alt="App Features 1"
                                        className="mw100Force"
                                    />
                                    <div className="roundedOverlay_home">
                                        <img src={s3StaticPath('img/home/slicing/gm/fb_sharing_icon.png')} alt="Facebook-Sharing" />
                                    </div>
                                    <div className="px1-25 py25 text-center">
                                        <h6 className="secondary font-weight-bold lineH mb10F">
                                            Facebook Sharing
                                        </h6>
                                        <span>
                                            {/* Travelers can easily share photos &amp; their highlight video on Facebook from the app or GroupMemories.co.uk photo gallery. */}
                                            Travelers can effortlessly post their photos and highlight videos to Facebook directly from the app or the GroupMemories.co.uk photo gallery.
                                        </span>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={12} sm={4} className="mt10">
                                <div className="border1 whiteBG" style={{ minHeight: '455px' }}>
                                    <img
                                        src={s3StaticPath('img/home/slicing/gm/6.png')}
                                        alt="App Features 1"
                                        className="mw100Force"
                                    />
                                    <div className="roundedOverlay_home">
                                        <img src={s3StaticPath('img/home/slicing/gm/photo_editing_icon.png')} alt="Photo-Editing" />
                                    </div>
                                    <div className="px1-25 py25 text-center">
                                        <h6 className="secondary font-weight-bold lineH mb10F">
                                            Photo Editing
                                        </h6>
                                        <span>
                                            {/* Our editors go through each photo to remove not needed or duplicate photos, do color correction, &amp; cropping. */}
                                            Our editors meticulously review each photo, eliminating unnecessary or duplicate images, performing color correction, and making necessary crops to ensure quality.
                                        </span>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={12} sm={4} className="mt10">
                                <div className="border1 whiteBG" style={{ minHeight: '440px' }}>
                                    <img
                                        src={s3StaticPath('img/home/slicing/gm/7.jpg')}
                                        alt="App Features 1"
                                        className="mw100Force"
                                    />
                                    <div className="roundedOverlay_home">
                                        <img src={s3StaticPath('img/home/slicing/gm/advertising_markiting_icon.png')} alt="Advertising-&-Marketing" />
                                    </div>
                                    <div className="px1-25 py25 text-center" >
                                        <h6 className="secondary font-weight-bold lineH mb10F">
                                            Advertising &amp; Marketing
                                        </h6>
                                        <span>
                                            {/* It's a commercial for your tours funded by your travelers. Each time a traveler logs in to the app or website they will see your company's logo as well as at the beginning &amp; end of each video. It serves as a great recruiting tool for Group Leaders, teachers &amp; band directors for next year's trip. They show it at the pre-trip meetings which excites everyone about traveling. */}
                                            This promotional video for our tours is financed by our travelers. Every time a traveler accesses the app or website, they will encounter our company's logo, both at the video's outset and conclusion. This video serves as an effective recruitment tool for Group Leaders, teachers, and band directors who are planning next year's trip. It is showcased during pre-trip meetings, generating excitement and anticipation among all prospective travelers.
                                        </span>
                                    </div>
                                </div>
                            </Col>
                        </Row>

                        {/* <Row className="mb25 tabsContentUpdated mb-md">
                            
                        </Row> */}
                    </div>
                </section>
            </main>
        )
    }
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {

}
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard(UserHome));
