import { SC } from '../helper/ServerCall';

export const itineraryService = {
    getBrandsPagination,
    storeItinerary,
    getItineraries,
    editItinerary,
    deleteItinerary,
    updateBrandStatus,
    deleteItineraryDay,
    swapItineraryDays,
    itineraryImageStatus,
    replaceItineraryImage
}

function getBrandsPagination(page, filter, sort, pageSize, status) {
    return SC.getCall('brands?page=' + page + '&filter=' + filter + '&sort=' + sort + '&pageSize=' + pageSize + '&status=' + status);
}
function storeItinerary(data) {
    return SC.postCall('itineraries', data);
}
function getItineraries(brandId, agencyId, orderId) {
    let order_id = '';
    if (!agencyId)
        return SC.getCall('itineraries/' + brandId);
    if(orderId) order_id = `/${orderId}`;
    return SC.getCall('itineraries/' + brandId + '/' + agencyId + order_id);
}
function editItinerary(data, id) {
    return SC.putCall(`itineraries/${id}`, data);
}
function deleteItinerary(data) {
    return SC.postCall('deleteItinerary', data);
}
function deleteItineraryDay(data) {
    return SC.postCall(`deleteItineraryDay`, data);
}
function updateBrandStatus(brandId, brandData) {
    return SC.putCall(`brandStatusChange/${brandId}`, brandData);
}

function swapItineraryDays(data) {
    return SC.postCall('swapItineraryDays', data);
}

function itineraryImageStatus(data) {
    return SC.postCall('itineraryImageStatus', data);
}

function replaceItineraryImage(data) {
    return SC.postCall('replaceItineraryImage', data);
}
