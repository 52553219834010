import React from "react";
import { connect } from 'react-redux';
import Dropzone from "react-dropzone";
import Confirmalert from 'components/ThemeComponents/confirmAlert';
import { confirmAlert } from 'react-confirm-alert';
import { s3StaticPath } from 'helper/helperFunctions';
import { showLoader } from 'redux/actions/settings-actions';

// var findOrientation = require('exif-orientation');
// import EXIF from "exif-js";
// window.EXIF = EXIF;    
// var fixRotation = require('fix-image-rotation')

class FileUploadNewPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      warningMsg: "",
      showAlert: true,
      loading: false,
    };
    this.replaceImageAlert = this.replaceImageAlert.bind(this);
    this.onDrop = this.onDrop.bind(this);
    this.dropzoneRef = React.createRef();
  };
  toggle = () => {
    this.setState({ showAlert: !this.state.showAlert });
  }
  extraHtml = () => {
    return (
      <span><i>Dont Show this pop up again</i><input type="checkbox" onClick={() => this.toggle()} /></span>
    );
  }
  replaceImageAlert(index, fileObject) {
    var self = this;
    return new Promise(function (resolve, reject) {
      const options = {
        customUI: ({ onClose }) => <Confirmalert
          header="Replace image with same name"
          description={"Are you sure to replace image " + fileObject.name + "?"}
          onClosePro={onClose}
          extraHtml={self.extraHtml}
          onNo={() => { resolve({ replace: false }) }}
          onYes={() => { resolve({ replace: true }) }}
          yesLabel='Yes'
          noLabel='No'
        />,
        closeOnEscape: false,
        closeOnClickOutside: false,
      };
      confirmAlert(options);
    });
  }
  async onDrop(accepted, rejected) {
    if (Object.keys(rejected).length !== 0) {
      const message = "Please submit valid file type";
      this.setState({ warningMsg: message });
    }
    else {
      this.setState({ warningMsg: "" });

      const generateFile = (count, fileObject) => {
        console.log('generate files fileObject', fileObject);
        return new Promise((resolve, reject) => {
          const reader = new window.FileReader();
          reader.readAsDataURL(fileObject);
          reader.onloadend = () => {
            const base64data = reader.result;
            fileObject['file'] = base64data;
            resolve({ index: count, fileObject: fileObject });
          };
        });
      };
      var i = 0;
      var length = accepted.length;
      if (this.props.multiple) {
        this.setState({ loading: true });
        for (const file of accepted) {
          var temp = file;
          await generateFile(i, temp).then((value) => {
            this.props.setResourceFile(this.props.type ? this.props.type : null, value.fileObject); //add file data to file aray
          });  
          i++;
          if (i === length) {
            this.setState({ loading: false });
          }
        }
      } else {
        this.setState({loading:true});
        generateFile(0, accepted[0]).then((value) => {
          this.setState({loading:false});
          this.props.setResourceFile(this.props.type ? this.props.type : null, value.fileObject); //add file data to file aray
        });
      }
    }
  };

  open() {
    if (this.dropzoneRef.current) {
      this.dropzoneRef.current.open();
    }
  }

  render() {
    const { allowedFormatsStringMimePro, allowedFormatsStringExtPro, multiple, title } = this.props;
    const render =
      <p className="hello">Please drop {title ? title : 'files'} here with format {allowedFormatsStringExtPro}</p>;

    return (
      <div style={{ position: "relative" }}>
        {
          this.state.loading &&
          <span className="myLoader">
            <span> Building Preview...  </span> <img className="ml-md" src={s3StaticPath('img/recycle.svg')} alt="Loading..." />
          </span>
        }
        <p>{this.state.warningMsg}</p>
        <Dropzone
          ref={this.dropzoneRef}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "10vh",
            border: " 2px dashed"
          }}
          multiple={multiple}
          accept={allowedFormatsStringMimePro}
          onDrop={
            (accepted, rejected) =>
              this.onDrop(accepted, rejected)
          }
        >
          {({ isDragAccept, isDragReject, acceptedFiles, rejectedFiles }) => {
            if (isDragReject) return "Please submit a valid file";
            return render;
          }}
        </Dropzone>
      </div>
    );
  }
}

function mapStateToProps(state) {
  
  return {
    
  };
}

const mapDispatchToProps = dispatch => {
  return ({
    showLoaderCall: (status) => { dispatch(showLoader(status)) },
  });
};

const FileUploadNew = connect(mapStateToProps, mapDispatchToProps)(FileUploadNewPage);

export default FileUploadNew;
