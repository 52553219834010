import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getAllMusic } from '../../../redux/actions/musicActions';
import { Link } from 'react-router-dom';

class SampleMusic extends Component {
    constructor(props) {
        super(props)
        this.audioPlayer = React.createRef();
        this.state = {
            currentMusic: {
                id: '',
                src: ''
            }
        }
        this.props.getAllMusic();
    }

    playMusicHandler = (e, item) => {
        this.setState({
            currentMusic: {
                id: item.id,
                src: item.file_path
            }
        }, () => {
            this.audioPlayer.current.play();
        })
    }

    render() {
        const { musicLibrary } = this.props;
        const { REACT_APP_GM_URL } = process.env;
        return (
            <div className='row'>
                <div className='col-md-6 pull-right'>
                    <div className="sampleMusicContainer">
                        <audio className='w-100' controls ref={this.audioPlayer} src={this.state.currentMusic.src}>
                            <source src={this.state.currentMusic.src} type='audio/mpeg' />
                        </audio>
                        <ul className='list-group mt-md playMusic'>
                            {
                                musicLibrary.map(item => {
                                    return (
                                        <li onClick={(e) => { this.playMusicHandler(e, item) }} style={{ cursor: 'pointer' }} className={"list-group-item" + (this.state.currentMusic.id === item.id ? ' active' : '')} key={item.id}>{item.title}</li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                </div>

                <div className='col-md-6'>
                    <p className='text_14 light'>Here is some of our background music for our Group Memories. You may pick between <strong className="semibold">Age Based</strong> or <strong className="semibold">Destination Based</strong>.</p>
                    <p className='text_14 light'>The first 2 songs are stock Age Based music tracks. Next, are our most commonly used Destination Based tracks starting with Orlando.
                    If you do not see your destination here, please <a className="blue_link" href={REACT_APP_GM_URL+'contact'}>Contact Us</a>. If we have your destination music in stock, we will add it to this page. If we do not have your destination music in stock, music will be chosen at the discretion of the video producer.</p>
                    <p className='text_14 light'><strong className="semibold">Note:</strong> These tracks are only 3 minutes of music. The actual tracks are much longer and contain multiple songs. However, these 3 minute samples should give you a good idea of the type of music used in the actual multi-song tracks.</p>
                </div>
                
                <div className="col-md-12">
                    <h4 className="text_18 mb-xs"><strong className="semibold">Performance Groups</strong></h4>
                    <p className='text_14 light'>If you are part of a performance group, you have the option to send us a recording of the music your group performed.
                    <strong className="semibold">The music MUST be sent to us prior to video production!</strong> You can mail the music to us on a flash drive, CD or <a className="blue_link" href={REACT_APP_GM_URL+'contact'}>Contact Us</a> for information on sending it to us over the web. If you decide to mail your music, be sure to send it ASAP that way we get before we produce your DVD videos and make sure to include you group information (group name, tour operator, and destination) so we can pair it with your video order.</p>
                    <p className="text_14 light">If you have any questions about sending performance music, please <a className="blue_link" href={REACT_APP_GM_URL+'contact'}>Contact Us</a>.</p>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { musicLibrary } = state.musicLibraryReducer;
    return {
        musicLibrary
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getAllMusic: () => { dispatch(getAllMusic()) }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SampleMusic)
