import React, { Component } from 'react';
import $ from 'jquery';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class Terms extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isHovered: false,
    };
    this.handleHover = this.handleHover.bind(this);
  }
  handleHover() {
    // this.setState(prevState => ({
    //     isHovered: !prevState.isHovered
    // }));

    $("#clients div.client").hover(function () {
      $("#clients div.client").removeClass("pink_gradient")
      $(this).addClass("pink_gradient")
    }, function () {
      $("#clients div.client").removeClass("pink_gradient")
    })
  }
  render() {
    // const hoverClass = this.state.isHovered ? "client pink_gradient" : "client";
    // let sliderSettings = {
    //   dots: true,
    //   autoplay: true,
    //   infinite: true,
    //   speed: 500,
    //   slidesToShow: 1,
    //   slidesToScroll: 1,
    //   accessibility: false,
    //   className: 'benefits',
    //   prevArrow: <div><MdArrowBack /></div>,
    //   nextArrow: <div><MdArrowForward /></div>,
    // };

    return (
        <section id="terms" className="section">
            <div className="container mt-xlg">
                <div className="row mb-xlg">
                    <div className="col-md-12 mb-xlg">
                        <h2 className="panel_heading">Terms &amp; Conditions</h2>

                        <p className="text_22 mt-xxlg text-primary">
                            <strong className="semibold">Copyright/Trademark Information</strong>
                        </p>

                        <p className="text_16 mt-sm">
                            Group Memories&trade; is a trademark of PhotoVision, Inc. This entire website, copy, images, logos, indicia, text, content, and unique method of showcasing products is copyright { new Date().getFullYear()} PhotoVision, Inc. All trademarks appearing herein are acknowledged. Permission to use documents delivered from this World Wide Web site and/or server and related graphics is restricted. The name of PhotoVision, Inc. or any of the above trademarks or logos may not be used in advertising or publicity pertaining to distribution of this information without specific, written prior permission. The information contained in this website is subject to change without notice. We are not responsible for typographical, technical, or descriptive errors of products herein. You must be <strong className="semibold">13 years</strong> of age to use this app or otherwise have the permission from a parent or legal guardian to do so.
                        </p>

                        <p className="text_22 mt-xxlg text-primary">
                            <strong className="semibold">Use of our App</strong>
                        </p>

                        <p className="text_16 mt-sm">
                            We provide a free app for users to easily - and privately - share their photos with each other in their group. This is available for Apple and Android devices. Your group has a unique username and password, and only requires you to enter your name once when you login for the first time. The purpose for that is to give you credit when you share a photo with the group, and to use in our geo-tracking and messaging feature we have built into the app. We do not require, nor need, your email address, contact information, or your contacts in your device. We do require you to allow the app to have your location services on, if and when your group leaders want us to use the geo-tracking feature in the app. This feature turns on at 5am the day of your trip's departure, and turns off at midnight the day of your return. Any location coordinates or messages stored in our servers are deleted at this time. We are passionate about your privacy, and it is one of the key benefits of using our services because of the privacy features. Should you wish not to be part of the geo-tracking feature, let your group leader know. Before you login, check the box 'Following from Home' on the login screen. You will still be able to share your photos with your fellow travelers and to have access to the photos they share. The app does have a social media sharing feature to it, allowing you to share photos on Facebook, Instagram, Twitter, and Email. You will be required to login to your social media account to allow the app access to share your image to your account. We do not capture or keep your login information to your social media accounts. You also will have the ability to download any images shared into the device you are using.
                        </p>

                        <p className="text_22 mt-xxlg text-primary">
                            <strong className="semibold">Order Acceptance Policy</strong>
                        </p>

                        <p className="text_16 mt-sm">
                            Your receipt of an electronic or other form of order confirmation does not signify our acceptance of your order, nor does it constitute confirmation of our offer to sell. PhotoVision, Inc. reserves the right at any time after receipt of your order to accept or decline your order for any reason. PhotoVision, Inc. reserves the right at any time after receipt of your order, without prior notice to you, to supply less than the quantity you ordered of any item. All orders placed over <strong className="semibold">$2000.00</strong> (USD ) must obtain pre-approval with an acceptable method of payment, as established by our credit and fraud avoidance department. We may require additional verifications or information before accepting any order. PhotoVision, Inc. is a reseller to travel industry providers and to end user consumers.
                        </p>

                        <p className="text_22 mt-xxlg text-primary">
                            <strong className="semibold">Typographical Errors</strong>
                        </p>

                        <p className="text_16 mt-sm">
                            In the event a product is listed at an incorrect price or with incorrect information, PhotoVision, Inc. shall have the right to refuse or cancel any orders placed for product listed at the incorrect price. PhotoVision, Inc. shall have the right to refuse or cancel any such orders whether or not the order has been confirmed.
                        </p>

                        <p className="text_22 mt-xxlg text-primary">
                            <strong className="semibold">Return Policy and Defective Product</strong>
                        </p>

                        <p className="text_16 mt-sm">
                            PhotoVision Inc has a <strong className="semibold">100% guarantee</strong> policy against defects for <strong className="semibold">60 days</strong> after delivery that provides for either repair or replacement (at our sole discretion) of product that has been confirmed as defective by PhotoVision, Inc. If for any reason you are not completely satisfied with the production of your final video montage, we will work with you to provide any corrections or errors that we have made, at our expense. For assistance or to exchange a defective product please call <strong className="semibold">888-533-7637 Monday-Friday, 10am-5pm</strong>. We may or may not require you to return the defective product before a credit or replacement is issued. This is determined solely at our discretion. We will do everything we can to expedite a credit or replacement product for you.
                        </p>

                        <p className="text_22 mt-xxlg text-primary">
                            <strong className="semibold">Limitation of Liability</strong>
                        </p>

                        <p className="text_16 mt-sm">
                            In no event shall PhotoVision, Inc. or any of its subsidiaries or affiliates be liable for any indirect, special, incidental, copyright infringements, or consequential damages including but not limited to loss of use, loss of data, loss of business or profits. Some localities do not allow the exclusion or limitation of incidental or consequential damages, so the above limitation or exclusion may not apply to you.
                        </p>

                        <p className="text_22 mt-xxlg text-primary">
                            <strong className="semibold">Transit Time</strong>
                        </p>

                        <p className="text_16 mt-sm">US Priority Mail: <strong className="semibold">2-3</strong> Business Days US Express Mail: <strong className="semibold">1-2</strong> Business Days</p>
                        <p className="text_16"><strong className="semibold">Note:</strong> Sunday deliveries are not available.</p>

                        <p className="text_22 mt-xxlg text-primary">
                            <strong className="semibold">Total Time For Delivery</strong>
                        </p>

                        <p className="text_16 mt-sm">
                            Total time is based on the amount of time it takes to get payment authorization, order processing, and the transit time from the carrier. This can range from <strong className="semibold">24</strong> hours to <strong className="semibold">5-10</strong> business days.
                        </p>

                        <p className="text_22 mt-xxlg text-primary">
                            <strong className="semibold">Shipping And Handling Rates</strong>
                        </p>

                        <p className="text_16 mt-sm">
                            Our prices include all shipping to a common address. This includes both shipping of the equipment to and from the group and also the final shipment of produced DVD’s. For orders where you request express shipping, the fee for this is <strong className="semibold">$25-$50.</strong> These rates are for domestic shipping to all 50 states in the USA. If you request shipping of DVD’s individually to home addresses, the S&amp;H fee is <strong className="semibold">$2.95</strong> per DVD, and <strong className="semibold">$8.95</strong> for photomosaics.
                        </p>

                        <p className="text_22 mt-xxlg text-primary">
                            <strong className="semibold">Invoicing</strong>
                        </p>

                        <p className="text_16 mt-sm">
                            PhotoVision Inc., invoices for all active orders <strong className="semibold">30 days</strong> prior to departure with 30 day terms. Orders can be adjusted electronically thru our website or by calling up to the day of departure. In the event the group has already departed but the order has not been produced, orders can be modified by calling our customer service department. If the order has been produced and shipped, we cannot adjust down any order in the number of DVD’s invoiced. We can, however, add to orders already produced. In the event that a traveler orders an additional DVD, the cost would be <strong className="semibold">$15</strong> including <strong className="semibold">S&amp;H</strong>. The customer would make payments to PhotoVision, Inc.
                        </p>

                        <p className="text_22 mt-xxlg text-primary">
                            <strong className="semibold">Cancelled Orders</strong>
                        </p>

                        <p className="text_16 mt-sm">
                            If an order is cancelled prior to PhotoVision shipping materials to the group and before departure, there will be no charges assessed and a full refund will be made if payment has already been made.
                        </p>
                        
                        <p className="text_16 mt-md">
                            If we have already shipped materials and the order is cancelled prior to departure, a refund will be made if already paid, less <strong className="semibold">$50</strong> for an administrative fee. If not paid, an administrative fee of <strong className="semibold">$50</strong> will be invoiced.
                        </p>
                        
                        <p className="text_16 mt-md">
                            Once an order is placed, we provide the resources necessary and do our best to see that the app we provide is utilized, including the mapping, messaging, document features and photo sharing. In order to make the group’s video, we need pictures from the group. We make numerous contacts via email and telephone to ensure the group leader is aware of the program’s features and video. On rare occasion, we are unsuccessful in communicating with the group leader. In this case, there is a chance the video may not get produced due to not enough photos but the use of the app’s other features was utilized. There are no refunds or credits in these situations; however, we will honor the production of a video for that group at any time in the future, including photos that were not taken on the trip. We are in the memory saving business, and it is our fervent desire to produce a video for every group when an order was placed. Special situations and requests will be considered.
                        </p>

                        <p className="text_22 mt-xxlg text-primary">
                            <strong className="semibold">Contact Us</strong>
                        </p>

                        <p className="text_16 mt-sm">
                            You may reach us <strong className="semibold">Monday-Friday 9am to 5:30pm</strong> Eastern Time at <strong className="semibold">1-888-533-7637, ext 213</strong>. PhotoVision, Inc. 8300 Fairview Road, Charlotte, NC 28227.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
  }
}

export default Terms;