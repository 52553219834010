import { adminConstants } from '../constant/admin-constant';

const initialState = {loadingPermissions: false};

export function permissionsReducer(state = initialState, action){

	switch(action.type){
		case adminConstants.GET_ALL_PERMISSION_REQUEST:
			return { ...state, loadingPermissions: true};
		case adminConstants.GET_ALL_PERMISSION_SUCCESS:
			return { ...state, loadingPermissions:false, permissions:action.payload.permissions };
		case adminConstants.GET_ALL_PERMISSION_FAILURE:
			return { ...state, loadingPermissions:false, error:action.payload };

		case adminConstants.PAGINATION_PERMISSIONS_REQUEST:
			return { ...state, loadingPermission:true, permissions: {}, editPermission:'', storedPermission:'', updatedPermission:'', deletePermission:false };
		case adminConstants.PAGINATION_PERMISSIONS_SUCCESS:
			return { ...state, permissionsData:action.payload.data.pagination.data, pages:action.payload.data.pagination.last_page, loadingPermission: false, current_page:action.payload.data.pagination.current_page };
		case adminConstants.PAGINATION_PERMISSIONS_FAILURE:
			return { ...state, loadingPermission:false, error : action.payload };

		case adminConstants.STORE_PERMISSION_REQUEST:
			return { ...state, loadingPermission:true, editPermission:'', storedPermission: '' }
		case adminConstants.STORE_PERMISSION_SUCCESS:
			return { ...state, loadingPermission:false, storedPermission: action.payload.permission};
		case adminConstants.STORE_PERMISSION_FAILURE:
			return { ...state, loadingPermission:false, error:action.payload };

		case adminConstants.GET_PERMISSION_REQUEST:
			return { ...state, loadingPermission:true };
		case adminConstants.GET_PERMISSION_SUCCESS:
			return { ...state, loadingPermission:false, editPermission : action.payload.data };
		case adminConstants.GET_PERMISSION_FAILURE:
			return { ...state, loadingPermission:false, error : action.payload };

		case adminConstants.EDIT_PERMISSION_REQUEST:
			return { ...state, loadingPermission:true, updatedPermission:'' };
		case adminConstants.EDIT_PERMISSION_SUCCESS:
			return { ...state, loadingPermission:false , updatedPermission:action.payload.permission};
		case adminConstants.EDIT_PERMISSION_FAILURE:
			return { ...state, loadingPermission:false, error : action.payload };

		case adminConstants.DELETE_PERMISSION_REQUEST:
			return { ...state, loadingPermission:true, deletePermission: false }
		case adminConstants.DELETE_PERMISSION_SUCCESS:
			return { ...state, loadingPermission:false, deletePermission: true};
		case adminConstants.DELETE_PERMISSION_FAILURE:
			return { ...state, loadingPermission:false, error:action.payload };

		case adminConstants.GET_ALL_PARENT_LABEL_REQUEST:
			return { ...state, loadingPermissions: true};
		case adminConstants.GET_ALL_PARENT_LABEL_SUCCESS:
			return { ...state, loadingPermissions:false, parent_labels:action.payload.parentLabels };
		case adminConstants.GET_ALL_PARENT_LABEL_FAILURE:
			return { ...state, loadingPermissions:false, error:action.payload };

		case adminConstants.GET_ALREADY_EXISTING_ORDER_REQUEST:
			return { ...state, loadingPermissions: true};
		case adminConstants.GET_ALREADY_EXISTING_ORDER_SUCCESS:
			return { ...state, loadingPermissions:false, existingOrder:action.payload.orders };
		case adminConstants.GET_ALREADY_EXISTING_ORDER_FAILURE:
			return { ...state, loadingPermissions:false, error:action.payload };
			
		default:
			return { ...state };
	}
}