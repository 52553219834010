import React, { Component } from "react";
import { connect } from 'react-redux';
import { getPrintInvoices } from "redux/actions/invoicing-actions";
import $ from 'jquery';
import { dateFormatMy } from 'helper/helperFunctions';
import 'react-table/react-table.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


// const moment = window.moment;

class PrintInvoicesPage extends Component {
    constructor(props) {
        super(props);
        this.props.getReportsCall();
        this.state = {
            fromDate: '',
            to: '',
            pageSize: 10,
            page: 0,
            sorted: '',
            isHovered: false,
            printData: []
        };
        this.handleHover = this.handleHover.bind(this);

    }

    handleHover() {
        // this.setState(prevState => ({
        //     isHovered: !prevState.isHovered
        // }));

        $("#clients div.client").hover(function () {
            $("#clients div.client").removeClass("pink_gradient")
            $(this).addClass("pink_gradient")
        }, function () {
            $("#clients div.client").removeClass("pink_gradient")
        })
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.props.data !== prevProps.data && this.props.data.length > 0){
            let newdataTemp = [...this.props.printData];
            let data = [...this.props.data]
            let newArray = [];
            newdataTemp.forEach(x => {
                for ( var i = 0; i < data.length; i++ ) {
                    if ( data[i] === x.id ) {
                        newArray.push(x);
                    }
                }
            });
            this.setState({
                printData: newArray
            })
        }
        if (this.props.data !== prevProps.data && Object.keys(this.props.data).length === 0) {
            this.setState({
                printData: []
            })
        }
    }

    render() {
        const { printData } = this.state;
        console.log("llengthhh////", this.props.data.length);
        return (
            <div id="ReportPage">
                {
                    // printData&&
                    printData.map((report, index) => {
                        console.log("reportt", report);
                        let calculatedTax = 0;
                        if (Number(report.taxable) === 1 ){
                            calculatedTax = parseFloat(Number(
                                (report.sub_total ? Number(report.sub_total) : 0) +
                                (report.additional_fees ? Number(report.additional_fees) : 0) -
                                (report.discount_value ? Number(report.discount_value) : 0)
                            ) * (parseFloat(report.tax ? Number(report.tax) : 0) / 100)).toFixed(2)
                            console.log("calcullatedd", calculatedTax);
                        }
                        return (<table className="mainReportTable">
                            <tbody>
                                <tr>
                                    <td style={{ width: '45%' }}>
                                        <table style={{ width: '100%' }} className="blankStyle">
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        {report.agency_name}
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td>
                                                        {report.agency.primary_address ? report.agency.primary_address.street_address_1 : ''}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        {report.agency.primary_address ? report.agency.primary_address.city : ''}
                                                             , {report.agency.primary_address ? report.agency.primary_address.state.title : ''} {report.agency.primary_address ? report.agency.primary_address.zipcode : ''}
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td>
                                                        Sales Rep -
                                                       </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        Tour Number - {report.client_tour_number}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        Tour Code - {report.client_tour_code}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>

                                    <td style={{ width: '30%', verticalAlign: 'top' }}>
                                        <table style={{ width: '100%' }} className="blankStyle">
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        Departure Date - {dateFormatMy(report.departuredate)}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>

                                    <td style={{ width: '25%', verticalAlign: 'top' }}>
                                        <table className="blankStyle" style={{ textAlign: 'right', width: '100%' }}>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        {report.barcode}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        {report.program_type.item_category.title}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>

                                <tr>
                                    <td colSpan="3">
                                        <table style={{ width: '100%' }} className="styledReport">
                                            <thead>
                                                <tr>
                                                    <th>QTY</th>
                                                    <th>DESCRIPTION</th>
                                                    <th>PRICE</th>
                                                    <th>TOTAL</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {report.order_items.map((product, index) => {
                                                    return (
                                                        <tr>
                                                            <td>
                                                                {product.quantity}
                                                            </td>
                                                            <td>
                                                                {product.item.title}
                                                            </td>
                                                            <td>
                                                                {product.price}
                                                            </td>
                                                            <td>
                                                                {product.total}
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                                }
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <td colSpan="2">
                                                        &nbsp;
                                                       </td>
                                                    <td>
                                                        Subtotal
                                                       </td>
                                                    <td>
                                                        {report.sub_total}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="2">
                                                        &nbsp;
                                                       </td>
                                                    <td>
                                                        Discount
                                                       </td>
                                                    <td>
                                                        {report.discount_value}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="2">
                                                        &nbsp;
                                                       </td>
                                                    <td>
                                                        Additional Fees
                                                       </td>
                                                    <td>
                                                        {report.additional_fees}
                                                    </td>
                                                </tr>
                                                {
                                                    Number(report.taxable) === 1 ?
                                                    <tr>
                                                        <td colSpan="2">
                                                            &nbsp;
                                                            </td>
                                                        <td>
                                                            Tax
                                                            </td>
                                                        <td>
                                                            {report.tax + '%' + " " + "(" + calculatedTax + ")"}
                                                        </td>
                                                    </tr>
                                                    : null
                                                }
                                                <tr>
                                                    <td colSpan="2">
                                                        &nbsp;
                                                       </td>
                                                    <td>
                                                        Shipping
                                                       </td>
                                                    <td>
                                                        {report.shipping}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="2">
                                                        ORIGINAL INVOICE
                                                       </td>
                                                    <td>
                                                        TOTAL
                                                       </td>
                                                    <td>
                                                        {report.grand_total}
                                                    </td>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </td>
                                </tr>
                            </tbody>
                        </table>);
                    })
                }
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return ({
        getReportsCall: (data) => { return dispatch(getPrintInvoices(data)) },//example function

    });
};

function mapStateToProps(state) {
    const { printData, REPORTSpages, REPORTScurrent_page, REPORTStotal_orders, loadingOrder } = state.invoicingReducer;
    return {
        printData, REPORTSpages, REPORTScurrent_page, REPORTStotal_orders, loadingOrder
    };
}


const PrintInvoices = connect(mapStateToProps, mapDispatchToProps)(PrintInvoicesPage);
export default PrintInvoices;
