import $, { param } from 'jquery';
import React from "react";
import {
    Button,
} from "react-bootstrap";
import clonedeep from 'lodash.clonedeep';
import { isEqual } from 'lodash';

var dateFormat = require('dateformat');
var moment = require("moment");

export const helperFunctions = {
    getRole,
    focusInput,
    returnLabelWithValue,
    returnLabelsWithValues,
    returnLabelsWithValueOtherThenLabel,
    isEmpty,
    returnOptionWithDisble,
    returnFormats,
    returnExtFromImage,
    returnKeyWithColumnMatch,
    returnColumnValueWithColumnMatch,
    returnDollars,
    dateFormatMy,
    dateTimeMy,
    leaf,
    updateStateVariableDynamic,
    hasChildren,
    TriggerHeader,
    returnKeys,
    intersectionOfObjectsWithNewValue,
    omitKeys,
    sortObjectWithDefineKeys,
    openModal,
    closeModal,
    closeModalAfterApi,
    showDate,
    overRideDuplicateImage,
    removeDuplicateImage,
    returnTimeStamp,
    hasRole,
    optionsHasRole,
    checkLoggedInUserById,
    toggleModal,
    inputChangeHandler,
    isLoggedIn,
    getInitials,
    colorForBardcodeWithDate,
    returnLabelWithValueGroup,
    clean,
    dateChangeHandler,
    updateState,
    contactChangeHandler,
    selectChangeHandler,
    appendCount,
    returnBoleanWithColumnMatch,
    appendValues,
    appendValuesWithMatchKeys,
    returnArrayOfMatchingKeyFromArray,
    returnColumnValueWithColumnMatchSA,
    changeText,
    returnLabelsWithValuesWithValueMAndLabelM,
    selectArrayChangeHandler,
    getFormattedDate,
    BytesToMb,
    createFormData,
    compareDates,
    isset,
    checkObjHasKeys,
    checkNested,
    lengthOfMultiArray,
    flatten,
    matchWithFlatten,
    getRandomInt,
    dateWithDayMy,
    returnDynamicURL,
    getSum,
    dateTimeWithAMPM,
    remove_duplicates,
    isEmptyCheck,
    boldStatment,
    dateContainInObject,
    dateContainInObjectStart,
    columnFilters,
    createSortString,
    createSortStringReOrder,
    convertMomentIntoDateObj,
    s3StaticPath,
    s3VideoPath,
    dateFormatMyReturnNone,
    scrollLogin,
    makeTrackingUrl,
    UploadImagesToS3,
    uuid,
    dateFormatMomentCheckOut,
    getFormattedDateMoment,
    dateFormatMyDocuments,
    generateUrl,
    returnAgencyQuickURL,
    returnTimeZoneNames,
    returnOriginalTZ,
    returnChecksForPhotoSubmit, 
    returnChangedAddress
};

export function returnOriginalTZ (tz) {
    console.log("tz",tz);
    let timeZone = '';
    if (tz.includes('CST')) {
        timeZone = 'CST6CDT'
    } else if (tz.includes('MST')) {
        timeZone = 'MST'
    } else if (tz.includes('PST')){
        timeZone = 'PST8PDT'
    } else {
        timeZone = 'EST'
    }
    return timeZone;
}

export function returnTimeZoneNames (tz) {
    let timeZone = '';
    if (tz.includes('CST6CDT')) {
        timeZone = 'CST (Central Standard Time)'
    } else if (tz.includes('MST')) {
        timeZone = 'MST (Mountain Standard Time)'
    } else if (tz.includes('PST8PDT')){
        timeZone = 'PST (Pacific Standard Time)'
    } else {
        timeZone = 'EST (Eastern Standard Time)'
    }
    return timeZone;
}

export function UploadImagesToS3 (path = '') {
    let tempPath = '';
    if (path) {
        tempPath = path
    }
    const { REACT_APP_AWS_ACCESS_KEY_ID, REACT_APP_AWS_BUCKET, REACT_APP_AWS_DEFAULT_REGION, REACT_APP_AWS_SECRET_ACCESS_KEY  } = process.env;

    const S3_BUCKET = REACT_APP_AWS_BUCKET;
    const REGION = REACT_APP_AWS_DEFAULT_REGION;
    const ACCESS_KEY = REACT_APP_AWS_ACCESS_KEY_ID;
    const SECRET_ACCESS_KEY = REACT_APP_AWS_SECRET_ACCESS_KEY;

    const config = {
        bucketName: S3_BUCKET,
        dirName: tempPath, /* optional */
        region: REGION,
        accessKeyId: ACCESS_KEY,
        secretAccessKey: SECRET_ACCESS_KEY,
    }
    return config;

}

export function TriggerHeader(heading, status) {
    return (
        <h4 className="headerTitle">{heading}
            <Button className="arrow_toggle" aria-controls="example-collapse-text">
                {status ? <img src={this.s3StaticPath('img/arrow_down.png')} alt="Arrow" className="arrow_up"/> :
                    <img src={this.s3StaticPath('img/arrow_down.png')} alt="Arrow" className="arrow_down"/>}
            </Button>
        </h4>
    );
}

export function hasChildren(obj, parent) {
    for (var k in obj) {
        if (typeof obj[k] === 'object' && obj[k] !== null) {
            parent.push(k);
            this.hasChildren(obj[k], parent);
        }
    }
    return parent;
}

//Path is the array of dynamic keys to update state
export function updateStateVariableDynamic(stateClone, value, path) {
    const nestedObject = path.slice(0, -1)
        .reduce(
            (object, part) => (
                object === undefined ? undefined : object[part]
            ),
            stateClone
        );

    if (nestedObject !== undefined) {
        /* Obtain last key in path */
        const [pathTail] = path.slice(-1);

        /* Update value of last key on target object to new value */
        nestedObject[pathTail] = value;
    }

    return stateClone;
}

export function leaf(obj, path) {
    path = path.split('.');
    var res = obj;
    for (var i = 0; i < path.length; i++) {
        res = res[path[i]];
    }
    ;
    return res;
}

export function usDateFormat(date) {
    date = date || date === 0 ? date : new Date();


    if (!(date instanceof Date)) {
        date.replace(/ /g,"T").replace(/-/g, '/');
        date = date = new Date(date);
        // date = new Date(date.replace(/ /g,"T").replace(/-/g, '/'));
    }
    console.log('date testing', date);
    if(date) {
        const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        let month = months[date.getMonth()];
        console.log('date testing ', date, month + ' ' + date.getDate() + ', ' + date.getFullYear());
        if(isNaN(date)) {
            return '--';
        }
        return month + ' ' + date.getDate() + ', ' + date.getFullYear();
    }
    return '--';

    // console.log('dateFormat Date ', date);
    // date = Date.parse(date);
    // var formattedDate = date ? date.toLocaleDateString("en-US") : '';
    // console.log('formattedDate ', formattedDate);
    // return formattedDate;

    // return date ? dateFormat(date, "mmm dd yyyy") : '--';
}

export function dateFormatMy(date) {
    return date ? dateFormat(date, "mmm dd yyyy") : '--';
}
export function dateFormatMyDocuments(date) {
    return date ? moment(date).format("MMM DD YYYY") : '--';
}
export function dateFormatMomentCheckOut(date) {
    return date ? moment(date).format("MMMM D, YYYY") : '--';
}

export function dateFormatMyReturnNone(date) {
    return date ? dateFormat(date, "mmm dd yyyy") : 'None';
}

export function dateTimeMy(date) {
    //var time = dateFormat(now, "hammerTime");
    return date ? dateFormat(date, "mmm dd yyyy - h:MM:ss") : '--';
}

export function dateTimeWithAMPM(date) {
    //var time = dateFormat(now, "hammerTime");
    return date ? dateFormat(date, "mmm dd yyyy h:MM TT") : '--';
}

export function dateWithDayMy(date) {
    //var time = dateFormat(now, "hammerTime");
    return date ? dateFormat(date, "ddd") : '--';
}

export function returnDollars(num) {
    return num > 0 ? '$' + num : num;
}

export function dateFormatWithDashes(date) {
    return date ? dateFormat(date, "mm-dd-yyyy") : '--';
}

export function getRole() {
    var userAdmin = JSON.parse(localStorage.getItem('user'));
    if (userAdmin) {
        return userAdmin.user.roles;
    }
}

export function focusInput(objectTemp) {
    let properties = Object.keys(objectTemp).reverse();
    // console.log('properties', properties);
    for (var key in properties) {
        if (objectTemp[properties[key]] !== null) {


            var myEle = $("#" + properties[key]);
            // console.log('myEle', properties[key]);
            // console.log('myEle', myEle);
            if (myEle) {
                var scrollPos = $(myEle).offset().top;
                $(window).scrollTop(scrollPos);
            }


            // $("#"+properties[key]).focus();
        }
    }
}

export function returnExtFromImage(imageName = '') {
    var ext = imageName.substr(imageName.lastIndexOf('.') + 1);
    return ext;
}

export function returnOptionWithDisble(value, ObjectArray) {
    var newObject = [];
    for (var key in ObjectArray) {
        if (ObjectArray[key]['value'] === value) {
            newObject.push({
                value: ObjectArray[key]['value'],
                label: ObjectArray[key]['label'],
                disabled: true
            });
        } else {
            newObject.push({
                value: ObjectArray[key]['value'],
                label: ObjectArray[key]['label'],
            });
        }
    }
    return newObject;
}


export function returnFormats(ObjectArray, columnName) {
    var newObject = [];
    for (var key in ObjectArray) {
        newObject.push(
            ObjectArray[key][columnName]
        );
    }
    return newObject;
}


export function returnLabelWithValue(value, ObjectArray, defaultLabel = null) {
    // console.log('ObjectArray 456', ObjectArray)
    if (value) {
        for (var key in ObjectArray) {
            if (ObjectArray[key]['value'] === value) {
                return {
                    value: ObjectArray[key]['value'], label: ObjectArray[key]['label']
                };
            }
        }
    } else if (defaultLabel) {
        for (var keyN in ObjectArray) {
            if (ObjectArray[keyN]['label'] === defaultLabel) {
                return {
                    value: ObjectArray[keyN]['value'], label: ObjectArray[keyN]['label']
                };
            }
        }
    }
    return null;
}

export function returnLabelWithValueGroup(value, ObjectArray) {
    if (value) {
        for (var key in ObjectArray) {
            for (var k in ObjectArray[key]['options']) {
                if (ObjectArray[key]['options'][k]['value'] === value) {
                    return {
                        value: ObjectArray[key]['options'][k]['value'], label: ObjectArray[key]['options'][k]['label']
                    };
                }
            }
        }
    }
    return null;
}

export function returnLabelsWithValues(values, ObjectArray) {
    var temp = [];

    if (values.length > 0) {
        for (var key in ObjectArray) {
            if (values.includes(ObjectArray[key]['value'])) {
                temp.push({value: ObjectArray[key]['value'], label: ObjectArray[key]['label']});
            }
        }
    } else {
        return null;
    }
    return temp;
}

/*return selected values with match value and label*/
export function returnLabelsWithValuesWithValueMAndLabelM(values, ObjectArray, valueMatch, labelMatch) {
    var temp = [];
    if (values.length > 0) {
        for (var key in ObjectArray) {
            if (values.includes(ObjectArray[key][valueMatch])) {
                temp.push({[valueMatch]: ObjectArray[key][valueMatch], [labelMatch]: ObjectArray[key][labelMatch]});
            }
        }
    } else {
        return null;
    }
    return temp;
}

export function returnLabelsWithValueOtherThenLabel(value, ObjectArray, keyValue, keyLabel) {
    if (value) {
        for (var key in ObjectArray) {
            if (ObjectArray[key][keyValue] === value) {

                var data = {
                    value: ObjectArray[key][keyValue], label: ObjectArray[key][keyLabel]
                };

                return data;
            }
        }
    }

    return null;
}

/*Return array key if key index match to define column otherwise null*/
export function returnKeyWithColumnMatch(columnName, ObjectArray, value) {
    for (var key in ObjectArray) {
        if (ObjectArray[key][columnName] === value) {
            return key;
        }
    }
    return null;
}

export function isEmpty(obj) {
    for (var key in obj) {
        if (obj.hasOwnProperty(key))
            return false;
    }
    return true;
}

/*return value for nested array after key match with value */
export function returnColumnValueWithColumnMatch(ObjectArray, columnNameReturn, columnMatch, columnMatchName) {
    // console.log('ObjectArray', ObjectArray);
    // console.log('columnNameReturn',columnNameReturn);
    // console.log('columnMatch', columnMatch);
    // console.log('columnMatchName', columnMatchName);
    if (ObjectArray && ObjectArray.length > 0) {
        for (var key in ObjectArray) {
            if (columnMatch) {
                // console.log('test column', ObjectArray[key])
                if (columnMatch.includes(ObjectArray[key][columnMatchName])) {
                    return ObjectArray[key][columnNameReturn];
                }
            }
        }
    }
    return null;
}

/*return value for single array after key match */
export function returnColumnValueWithColumnMatchSA(ObjectArray, columnNameReturn) {
    for (var key in ObjectArray) {
        if (columnNameReturn) {
            if (key === columnNameReturn) {
                return ObjectArray[columnNameReturn];
            }
        }
    }
    return null;
}


export function returnKeys(Obj) {
    var keys = [];
    for (var key in Obj) {
        if (typeof Obj[key] === 'object') {
            this.returnKeys(Obj[key]);
        }
    }
    return keys;
}

//b is the object from where to be copied and a is the object to be updated
export function intersectionOfObjectsWithNewValue(a, b) {
    var newObj = {};
    for (var key in a) {
        if (Array.isArray(a[key])) {
            newObj[key] = b[key];
        } else if (typeof a[key] === 'object') {
            if (b && typeof b[key] !== 'undefined') {
                var obj = intersectionOfObjectsWithNewValue(a[key], b[key]);
                newObj[key] = obj;
            } else {
                newObj[key] = a[key];
            }

        } else if (b && b.hasOwnProperty(key)) {
            newObj[key] = b[key] !== null ? b[key] : '';
        } else if (!b || !b.hasOwnProperty(key)) {
            newObj[key] = a[key] ? a[key] : '';
        }
    }
    return newObj;
}

export function omitKeys(obj, not) {
    var result = Object.assign({}, obj);
    for (let n of not) delete result[n];
    return result;
}

export function sortObjectWithDefineKeys(object, keysObject) {
    let tempObject = {}
    for (var key in keysObject) {

        if (object.hasOwnProperty(keysObject[key])) {

            tempObject[keysObject[key]] = object[keysObject[key]];
        }
    }
    return tempObject;
}


export function openModal(name) {

    this.setState({
        [name]: true
    });
}

export function closeModal(modalName) {
    this.setState({
        [modalName]: false
    })
}

export function closeModalAfterApi(modalName, afterApi) {
    this.setState({
        [modalName]: false,
        [afterApi]: true
    })
}

export function showDate(data) {
    const months = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
    const date = new Date(data);
    const d = date.getDate();
    let prefix;
    if (d > 3 && d < 21) {
        prefix = 'th'
    } else {
        switch (d % 10) {
            case 1:
                prefix = 'st';
                return prefix;
            case 2:
                prefix = 'nd';
                return prefix;
            case 3:
                prefix = 'rd'
                return prefix;
            default:
                break;
        }
    }
    return `${months[date.getMonth()]} ${date.getDate()}${prefix} ,${date.getFullYear()}`;
}

export function overRideDuplicateImage(currentImgs, AllImgs) {
    // for(var i in currentImgs ) {
    // 	for(var j in AllImgs) {

    // 	}
    // }
}

export function removeDuplicateImage(val, AllImgs) {
    for (var i in AllImgs) {
        if ((AllImgs[i].name === val.name)) {
            if ((AllImgs[i].file === val.file))
                return {
                    val: val,
                    duplicate: true,
                    name: val.name,
                    index: i,
                    message: val.name + ' Image name and file is same'
                }
            else
                return {
                    val: val,
                    duplicate: true,
                    name: val.name,
                    index: i,
                    message: val.name + ' Image file name is same'
                }
        }
    }
    return {duplicate: false, val: val, name: val.name};
}

export function returnTimeStamp(dataString) {
    const dateTime = new Date(dataString).getTime();
    const timestamp = Math.floor(dateTime / 1000);
    return timestamp;
}

export function hasRole(role) {
    let AdminUser = JSON.parse(localStorage.getItem('user'));
    if (AdminUser && AdminUser.token) {
        for (var i in AdminUser.user.roles) {
            var temp = AdminUser.user.roles[i];
            if (role.includes(temp.name)) {
                return true;
            }
        }
        return false;
    } else {
        return false;
    }
}

export function optionsHasRole(options, roleName) {
    var temp = [];
    for (var i in options) {
        for (var j in options[i].roles) {
            if (options[i].roles[j].name === roleName) {
                temp.push(options[i]);
            }
        }
    }
    return temp;
}

export function checkLoggedInUserById(id) {
    let AdminUser = JSON.parse(localStorage.getItem('user'));
    if (AdminUser && AdminUser.token) {
        return AdminUser.user.id === id ? true : false;
    } else {
        return false;
    }
}

export function toggleModal(name) {
    console.log('call toggle function 999')
    this.setState({
        [name]: !this.state[name]
    });
}


export function isLoggedIn() {
    let AdminUser = JSON.parse(localStorage.getItem('user'));
    return (AdminUser && AdminUser.token) ? true : false;
}

export function getInitials(name, delimeter) {

    if (name) {
        var array = name.split(delimeter);
        switch (array.length) {
            case 1:
                return array[0].charAt(0).toUpperCase();
            //break;
            default:
                return array[0].charAt(0).toUpperCase() + array[array.length - 1].charAt(0).toUpperCase();
        }
    }

    return false;

}

export function colorForBardcodeWithDate(date, dateName) {
    var color = "";
    if (date && dateName === 'videoSpecialNeedDate') {
        color = 'barcode-color-red'
    } else if (date && dateName === 'lastDayOfSchool') {
        var diff = moment().diff(date, 'days');
        if (diff > 0 && diff <= 5) {
            color = 'barcode-color-yellow';
        }
    }
    return color;
}

//This function checks if the value is not null
export function clean(prop, value = null, date = null) {
    if (prop) {
        if (date && prop) {
            return new Date(prop)
        } else if (date && value !== null) {
            return value
        }
        return prop
    } else {
        if (value !== null) {
            return value
        } else {
            return ''
        }
    }
}

export function inputChangeHandler(e, pattern = null) {
    const checked = e.target.checked;
    if (pattern) {
        if (e.target.value.match(pattern) === null) {
            return
        }
    }
    const container = e.target.name;
    let value = e.target.value;
    if (value === 'true') {
        value = true
    } else if (value === 'false') {
        value = false;
    }
    const newState = updateState.bind(this);
    newState(container, value, checked);
}

export function dateChangeHandler(value, dateType, defaultValue) {
    if (defaultValue !== undefined && value === '') {
        value = defaultValue
    }
    const newState = updateState.bind(this);
    newState(dateType, value);
}

export function contactChangeHandler(e) {
    const {value} = e.target;
    if (value.match(/^\d*$/) === null) {
        return;
    }
    ;
    const container = e.target.name.split('.');
    let phone = ''.split('-');
    if (this.state[container[0]][container[1]][container[2]] !== null) {
        phone = this.state[container[0]][container[1]][container[2]].split('-')
    }
    phone[container[3]] = value;
    const newContainer = container[0] + "." + container[1] + "." + container[2];
    const newState = updateState.bind(this);
    const phoneLength = phone.reduce((total, current) => {
        return total = parseFloat(current.length) + parseFloat(total);
    }, 0);
    if (phoneLength > 0) {
        newState(newContainer, phone.join('-'))
    } else {
        newState(newContainer, '');
    }
}

export function selectChangeHandler(selected, action, type) {
    const newState = updateState.bind(this);
    newState(action.name, selected.value)
}

export function selectArrayChangeHandler(selected, action) {
    const container = action.name.split('.');
    let targetArray = [];
    if (container.length > 1) {
        targetArray = this.state[container[0]][container[1]];
    } else {
        targetArray = this.state[action.name]
    }
    if (action.action === 'select-option') {
        selected = [...targetArray, action.option.value];
    }
    if (action.action === 'remove-value') {
        selected = targetArray.filter(item => {
            return item.value === action.removedValue.value
        });
    }
    const newState = updateState.bind(this);
    newState(action.name, selected);
}

export function updateState(containerState, value, checked = null) {
    const container = containerState.split('.');
    let currentState;
    currentState = clonedeep(this.state[container[0]])
    switch (container.length) {
        case 1:
            currentState = value
            break;
        case 2:
            const isArray = container[1].match(/(\[\])/g);
            if (isArray) {
                container[1] = container[1].replace('[]', '');
                if (currentState[container[1]]) {
                    if (checked) {
                        currentState[container[1]] = [...currentState[container[1]], parseInt(value, 10)]
                    } else {
                        currentState[container[1]] = currentState[container[1]].filter(item => {
                            return item !== parseInt(value, 10)
                        });
                    }
                } else {
                    currentState[container[1]] = [value]
                }
            } else {
                currentState[container[1]] = value
            }
            break;
        case 3:
            currentState[container[1]][container[2]] = value
            break;
        case 4:
            currentState[container[1]][container[2]][container[3]] = value
            break;
        default:
            break;
    }
    this.setState({
        [container[0]]: currentState
    });
}

/*Append count in an array*/
export function appendCount(options) {
    for (var i in options) {
        options[i]['count'] = parseInt(i, 10);
    }
    return options;
}

/*Append values in an array*/
export function appendValues(options, values) {
    // console.log('values', values);
    for (var i in options) {
        for (var j in values) {
            options[i][values[j]['column']] = values[j]['value'];
        }
    }
    return options;
}

/*Return true if key index match to define column otherwise false*/
export function returnBoleanWithColumnMatch(options, index, value) {
    for (var i in options) {
        if (index in options[i]) {
            if (options[i][index] === value) {
                return true;
            }
        }
    }
    return false;
}

/*return array after adding new key (setcolumn) in object, if keysArray Array have matching key (matchColumn) value */
export function appendValuesWithMatchKeys(options, keysArray, matchColumn, setColumn, setValue) {
    for (var i in options) {
        if (keysArray.includes(options[i][matchColumn])) {
            options[i][setColumn] = setValue;
        }
    }

    return options;
}

/*Return an array of matching key from array(options)*/
export function returnArrayOfMatchingKeyFromArray(options, matchColumn) {
    // console.log('optiosn', options);
    // console.log('matchColumn', matchColumn);
    var temp = [];
    for (var i in options) {
        if (matchColumn in options[i]) {
            if (options[i][matchColumn]) {
                temp.push(options[i][matchColumn]);
            }
        }
    }
    return temp;
}

export function changeText(attribute) {
    if (attribute.includes('item'))
        return 'item';
    else if (attribute.includes('inventory'))
        return 'inventory';
    // return 'hahaha';
}
export function getDemoVideoLink(locationObj) {
    let params = Object.fromEntries(new URLSearchParams(locationObj));

    let demoVideo = {
        'gtv': "https://grouptravelvideos.com/videos/GTV_App_Demo.mp4",
        '965': "https://grouptravelvideos.com/videos/GTV_App_Demo.mp4",
        '19': "https://grouptravelvideos.com/videos/BRT_App_Demo_2021.mp4",
        'brt': "https://grouptravelvideos.com/videos/BRT_App_Demo_2021.mp4",
        '15': "https://grouptravelvideos.com/videos/ETI_App_Demo_2020.mp4",
        'eti': "https://grouptravelvideos.com/videos/ETI_App_Demo_2020.mp4",
        '519': "https://grouptravelvideos.com/videos/GTV_App_Demo.mp4",
        'act': "https://grouptravelvideos.com/videos/GTV_App_Demo.mp4",
        'mci': 'https://grouptravelvideos.com/videos/MCI_App_Demo.mp4',
        '1233': "https://grouptravelvideos.com/videos/ITK_App_Demo.mp4",
        'itk': "https://grouptravelvideos.com/videos/ITK_App_Demo.mp4"
    };
    // '18': 'https://grouptravelvideos.com/videos/MCI_App_Demo.mp4',
    // '957': 'https://grouptravelvideos.com/videos/MCI_App_Demo.mp4',

    if(params && params.customerID){
        if (demoVideo[params.customerID] !== undefined){
            return demoVideo[params.customerID];
        }

    }
    const brands = ['gtv', 'brt', 'mci', 'eti', 'itk', 'act', 'ttk'];
    const brand = brands.includes(document.domain.split('.')[0]) ? document.domain.split('.')[0] : 'gtv';
    return demoVideo[brand];

}


export function getFormattedDate(date) {
    if (date !== '' && date !== null && date !== undefined) {
        const formattedDate = new Date(date);
        return (formattedDate.getMonth() + 1) + '/' + formattedDate.getDate() + '/' + formattedDate.getFullYear()
    } else {
        return ''
    }
}
export function getFormattedDateMoment(date) {
    if (date !== '' && date !== null && date !== undefined) {
        const formattedDate = moment(date).format('DD/MM/YYYY');
        return formattedDate
    } else {
        return ''
    }
}

export function compareDates(date1, date2, operator) {
    date1 = new Date(getFormattedDate(date1));
    date2 = new Date(getFormattedDate(date2));
    if (operator === '>') {
        if (date1 > date2) {
            return true
        } else {
            return false
        }
    } else if (operator === '<') {
        if (date1 < date2) {
            return true
        } else {
            return false
        }
    } else if (operator === '>=') {
        if (date1 >= date2) {
            return true
        } else {
            return false
        }
    } else if (operator === '<=') {
        if (date1 <= date2) {
            return true
        } else {
            return false
        }
    }
}

export function BytesToMb(bytes) {
    var kb = 1024;
    // var ndx = Math.floor(Math.log(bytes) / Math.log(kb));

    return +(bytes / kb / kb).toFixed(2);
}

export function createFormData(dataArray) {
    const fd = new FormData();
    for (var i in dataArray) {
        if (Array.isArray(dataArray[i])) {
            console.log('length of array', dataArray[i].length)
            if (dataArray[i].length > 0) {
                for (var j in dataArray[i]) {
                    fd.append(i, dataArray[i][j]);
                }
            } else {
                fd.append(i, dataArray[i]);
            }
        } else {
            fd.append(i, dataArray[i]);
        }
    }
    return fd;
}

export function isset(object, props) {
    var dump;
    try {
        console.log('x props', props);
        for (var x in props) {
            console.log('x props 1', props);
            if (x === 0) {
                console.log('x props test');
                dump = object[props[x]];
                return;
            }
            console.log('x data', x);
            dump = dump[props[x]];
        }
    } catch (e) {
        return false;
    }

    return true;
}

export function checkObjHasKeys(obj, keys) {
    var success = true;
    keys.forEach(function (key) {
        if (!obj.hasOwnProperty(key)) {
            success = false;
        }
        obj = obj[key];
    })
    console.log(success ? 'yes 321' : 'no 321')
    return success;
}

export function checkNested(obj, keys) {
    var args = Array.prototype.slice.call(keys);
    obj = args.shift();
    for (var i = 0; i < args.length; i++) {
        if (obj == null || !obj.hasOwnProperty(args[i])) {
            return false;
        }
        obj = obj[args[i]];
    }
    return true;
}

export function lengthOfMultiArray(array) {
    var total = 0;
    for (var i in array) {
        if (Array.isArray(array[i])) {
            total += lengthOfMultiArray(array[i]);
        } else {
            total++;
        }
    }

    return total;
}


export function flatten(obj, prefix = '', res = {}) {
    return Object.entries(obj).reduce((r, [key, val]) => {
        const k = `${prefix}${key}`
        if (typeof val === 'object') {
            flatten(val, `${k}.`, r)
        } else {
            res[k] = val
        }
        return r
    }, res)
}

// function flatten(data){
//   return data.reduce(function(result,next){
//     result.push(next);
//     if(next.items){
//       result = result.concat(flatten(next.items));
//       next.items = [];
//     }
//     return result;
//   },[]);
// }

export function matchWithFlatten(match, data) {
    for (var i in data) {
        if (match === data) {
            return i;
        }
    }

    return null;
}

export function getRandomInt(max) {
    return Math.floor(Math.random() * Math.floor(max));
}

export function uuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }
export function generateUrl(process, is_ttk) {
    const { REACT_APP_FRONTEND_URL_GTV, REACT_APP_FRONTEND_URL_TTK} = process;
    // console.log("frontend", REACT_APP_FRONTEND_URL_GTV);
    let url = '';
    if (is_ttk) {
        url = REACT_APP_FRONTEND_URL_TTK;
    } else {
        url = REACT_APP_FRONTEND_URL_GTV
    }
    return url;
  }


// export function returnDynamicURL(domainSlug, email, password) {
//     // const host = window.location.hostname.replace(/www./).split('.');
//     // let match, result;
//     // match = document.domain.match(/^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\/\n\?\=]+)/im);
//     // if (match) {
//     //     result = match[1]
//     //     console.log('match[1]', result);



// 		const { REACT_APP_GTV_HOST, REACT_APP_TTK_HOST  } = process.env;
// 		let link = REACT_APP_GTV_HOST;
// 		if(domainSlug == 'ttk') {
// 			link = REACT_APP_TTK_HOST;
// 		}

//         if (process.env.NODE_ENV === 'development') {
//             return `http://${link}/login/${email}/${password}`;
//         }
//         return `https://${domainSlug}.${link}/login/${email}/${password}`;

//         // let domainSlugs = ['gtv', 'brt', 'mci', 'eti', 'itk', 'act', 'ttk'];
//         // if (domainSlugs.includes(result.split('.')[0])) {
//         //     result = result.split('.').slice(1).join('.');
//         // }

//     // }
//     // if (process.env.NODE_ENV === 'development') {

//     //     domainSlug = window.location.host;
//     //     return `http://${domainSlug}/login/${email}/${password}`;
//     // } else {
//     //     return `http://${domainSlug}.${result}/login/${email}/${password}`;
//     // }

// }

export function returnDynamicURL(domainSlug, email, password) {
    // const host = window.location.hostname.replace(/www./).split('.');
    let match, result;
    match = document.domain.match(/^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\/\n\?\=]+)/im);
    if (match) {
        result = match[1]
        let domainSlugs = ['gtv', 'brt', 'mci', 'eti', 'itk', 'act', 'ttk', 'tech', 'aom', 'gm'];
        if (domainSlugs.includes(result.split('.')[0])) {
            result = result.split('.').slice(1).join('.');
        }
    }
    if(domainSlug === 'ttk') {
        const { REACT_APP_TTK_HOST  } = process.env;
        result = REACT_APP_TTK_HOST;
    }
    if(domainSlug === 'itk') {
        const { REACT_APP_ITK_HOST  } = process.env;
        result = REACT_APP_ITK_HOST;
    }
    // if(domainSlug === 'gm') {
    //     const { REACT_APP_GM_HOST  } = process.env;
    //     result = REACT_APP_GM_HOST;
    // }
    if (process.env.NODE_ENV === 'development') {
        domainSlug = window.location.host;
        return `http://${domainSlug}/login/${email}/${password}`;
    } else {
        return `http://${domainSlug}.${result}/login/${email}/${password}`;
    }
}
export function returnAgencyQuickURL( email, password ) {
    let domainSlug = window.location.host;
    let link = `http://${domainSlug}/login/${email}/${password}`;
    return <a className="orderDetailsAction small_icon blue_link btn" style={{cursor: "pointer"}} href={`microsoft-edge:${link}`}>Quick Link</a>
}

export function getSum(val1, val2) {
    return parseInt(val1, 10) + parseInt(val2, 10);
}

export function remove_duplicates(arr) {
    let s = new Set(arr);
    let it = s.values();
    return Array.from(it);
}

export function isEmptyCheck(data) {
    if (typeof (data) === 'object') {
        if (JSON.stringify(data) === '{}' || JSON.stringify(data) === '[]') {
            return true;
        } else if (!data) {
            return true;
        }
        return false;
    } else if (typeof (data) === 'string') {
        if (!data.trim()) {
            return true;
        }
        return false;
    } else if (typeof (data) === 'undefined') {
        return true;
    } else {
        return false;
    }
}

export function boldStatment(text, regexStatement) {
    var temp = text;
    var regex = new RegExp(/^[^:-]*[:-]\s*/g, 'g');
    var result = temp.replace(regex, '<b>' + text + '</b>');
    return result;
}

export function dateContainInObject(object, slot) {
    // console.log('event 123', object);
    //    console.log('info', slot);

    for (var i in object) {
        if ((+object[i]['start'] === +slot['start']) && (+object[i]['end'] === +slot['end'])) {
            return true;
        }
    }

    return false;
}

export function dateContainInObjectStart(object, date) {
    // console.log('event 123', object);
    //    console.log('info', slot);

    for (var i in object) {
        if ((+object[i]['start'] === +date)) {
            return true;
        }
    }

    return false;
}


export function columnFilters(columns) {
    let matchFilterWith = [];
    columns.forEach(column => {
        if ((column.id !== undefined || column.accessor !== undefined) && column.filterable !== false) {
            if (column.id) {
                matchFilterWith.push(column.id)
            } else {
                matchFilterWith.push(column.accessor)
            }
        }
    })
    return matchFilterWith;
}

export function createSortString(sorted) {
    let sort = '';
    let orderType = 'asc'
    if (sorted.length > 0 && sorted[0] !== '') {
        sort = sorted[0];
        let tempOrder = sorted[1].split(':');
        if (tempOrder[1] === "true") {
            orderType = 'desc'
        }
    }
    return {
        sort,
        orderType
    }
}

export function createSortStringReOrder(sorted,sort = '',orderType = 'asc') {
    // let sort = '';
    // let orderType = 'asc'
    if (sorted.length > 0 && sorted[0] !== '') {
        sort = sorted[0];
        let tempOrder = sorted[1].split(':');
        if (tempOrder[1] === "true") {
            orderType = 'desc'
        }
    }
    return {
        sort,
        orderType
    }
}

export function convertMomentIntoDateObj(value) {
    return moment.isMoment(value) ? value.toDate() : value
}

export function s3StaticPath(url) {
    return `https://gtvoriginalimages.s3.amazonaws.com/static/${url}`;
}
export function s3VideoPath(url) {
    return `https://gtv.grouptravelvideos.com/videos/${url}`;
}

export function scrollLogin() {
    // $(document).ready(function () {
    // 	$("html, body").animate({scrollTop: '0'}, 500, function (e) {
    // 		console.log($(".loginIcon > a").length)
    // 		$(".loginIcon > a")[0].click();
    // 	});
    // })
    $("html, body").animate({scrollTop: '0'}, 500, function (e) {
        !$(".loginIcon").hasClass('open') &&
        $(".loginIcon").addClass('open');
    });
}

export function isEmptyStr(str) {
    console.log('strrrr', str);
    return (!str || 0 === str.length);
}

export function makeTrackingUrl(url, number, country_code = '') {
    let resultUrl = url
    const tokens = {
        "{track_no}": number,
        "{country_code}": country_code
    }
    Object.keys(tokens).forEach(token => {
        resultUrl = resultUrl.replace(`${token}`, `${tokens[token]}`);
    })
    return resultUrl
}

export function returnChecksForPhotoSubmit(orderUploadInfo) {
    let error = '';
    if (new Date() <= new Date(orderUploadInfo.return_date) ) {
        error = "You can submit your order for video production once the return date has passed, as it has not yet arrived.";
        
    } else if (orderUploadInfo.total_approved_photos < 1) {
        error = "Before submitting photos for video production, it is necessary to have at least one photo approved."
        
    }
    return error;
}
function returnAddressObjectForComparison (obj) {
    const newObj = Object.assign({},
        {
            street_address_2: obj.street_address_2,
            city: obj.city,
            state_id: obj.state_id,
            country_id: obj.country_id,
            zipcode: obj.zipcode,
            country: obj.country,
            state: obj.state,
            street_address_1: obj.street_address_1,
        })
    return newObj;
}

function capitalizeWord (str) {
    return str.toLowerCase().replace(/\b\w/g, (match) => match.toUpperCase());
};


export function returnChangeAddressNote (shippingDetails, type, prevPtmAddressObject) {
    
    // console.log("type: " , type);
    // console.log("prevPtmAddressObject: " , prevPtmAddressObject);
    let html = '';

    let prevPtm = returnAddressObjectForComparison(prevPtmAddressObject);
    let ptmAddressObject = returnAddressObjectForComparison(shippingDetails.order.pre_trip_material_address);
    let videoAddressObject = returnAddressObjectForComparison(shippingDetails.order.video_shipping_address);

    if ( shippingDetails.shipping_type == 'ptm' && type === 'ptm') {

        html = "This will change the PTM Address."

    } else if ( shippingDetails.shipping_type == 'ptm' && type === 'video' && !isEqual(prevPtm, videoAddressObject)) {

        html = "PTM address does not match Video address, can not update."

    }  else if ( shippingDetails.shipping_type == 'ptm' && type === 'video' && isEqual(prevPtm, videoAddressObject)) {

        html = "This will change the Video Address."
    } else {

        html = "This will change the Video Address."
    }

    return html;
};

export function returnChangedAddress(shippingDetails, shipping_address, btn_type) {
    console.log("shippingDetails", shippingDetails);
    let ptm_address = {...shippingDetails.pre_trip_material_address};
    let video_address = {...shippingDetails.video_shipping_address};

    let ptmAddressObject = returnAddressObjectForComparison(ptm_address);
    let videoAddressObject = returnAddressObjectForComparison(video_address);

    let shipping_address_street_1 = capitalizeWord(shipping_address.street_address_1);
    let shipping_address_city = capitalizeWord(shipping_address.city);

    // if ( isEqual(ptmAddressObject, videoAddressObject) ) {

    //     console.log("if isEqual(ptmAddressObject, videoAddressObject)");
    //     ptm_address['street_address_1'] = shipping_address_street_1;
    //     ptm_address['city'] = shipping_address_city;
    //     ptm_address['zipcode'] = shipping_address.zipcode;

    //     video_address['street_address_1'] = shipping_address_street_1;
    //     video_address['city'] = shipping_address_city;
    //     video_address['zipcode'] = shipping_address.zipcode;

    // } else 
    if ( shippingDetails.shipping_type == 'ptm' && btn_type == 'ptm') {

        console.log("else if shippingDetails.shipping_type == 'ptm'");
        ptm_address['street_address_1'] = shipping_address_street_1;
        ptm_address['city'] = shipping_address_city;
        ptm_address['zipcode'] = shipping_address.zipcode;
        ptm_address['state'] = shipping_address.state;

    } else if ( shippingDetails.shipping_type = 'video' || btn_type == 'video') {

        console.log("else if shippingDetails.shipping_type = 'video'");
        video_address['street_address_1'] = shipping_address_street_1;
        video_address['city'] = shipping_address_city;
        video_address['zipcode'] = shipping_address.zipcode;
        video_address['state'] = shipping_address.state;

    } else if ( shippingDetails.shipping_type = 'mosaic' ) {

        console.log("else if shippingDetails.shipping_type = 'mosaic'");
        video_address['street_address_1'] = shipping_address_street_1;
        video_address['city'] = shipping_address_city;
        video_address['zipcode'] = shipping_address.zipcode;
        video_address['state'] = shipping_address.state;
    }
    
    return [ptm_address, video_address];
    
}

export function getPhoneFormat(format) {
    let mask = '';
    let validator = '';
    let placeHolder = '';
    if (format === 'uk') {
        validator = [
                {
                'regex': [/^[\d]{4}[-]?[\d]{3}[-]?[\d]{4,4}$/]
            },
        ]
        mask = '9999-999-9999'
        placeHolder = "1234-567-8910";

    }
   return [validator, mask, placeHolder]; 
}
