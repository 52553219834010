import { SC } from '../helper/ServerCall';

export const agencyResourceService = {
    storeAgencyResource,
    getAgencyResource,
    editAgencyResource,
    deleteAgencyResource,
    getCurrentAgencyResource,
    updatePermission,
    getAgencyResourcesPagination,
    getAgencyResourcesTypes,
    getResourcesByRole,
    getOrdersResourcesByRole,
    getAgencyResourceByType,
    getAgencyDocumentsPagination,
    storeAgencyDocument,
    deleteAgencyDocument
}


function storeAgencyResource(agencyId, agencyResourceData) {
    return SC.postCall('updateAgencyResource/' + agencyId, agencyResourceData);
}

function storeAgencyDocument(agencyId, agencyDocumentData) {
    return SC.postCall('uploadAgencyDocument/' + agencyId, agencyDocumentData);
}

function getAgencyResource(agency_id, agencyResourceId) {
    return SC.getCall('agencyResources/' + agency_id + '/' + agencyResourceId + '/show');
}

function editAgencyResource(agencyId, agencyResourceData) {
    return SC.postCall('updateAgencyResource/' + agencyId, agencyResourceData);
}

function deleteAgencyResource(agency_id, agencyResourceId) {
    return SC.deleteCall('deleteAgencyResource/' + agency_id + '/' + agencyResourceId);
}

function deleteAgencyDocument(agency_id, id) {
    return SC.deleteCall('deleteAgencyDocument/' + agency_id + '/' + id);
}

function getAgencyResourcesPagination(agency_id) {
    return SC.getCall('getAgencyResources/' + agency_id);
}

function getAgencyDocumentsPagination(agency_id) {
    return SC.getCall('getAgencyDocuments/' + agency_id);
}

function getAgencyResourcesTypes(agency_id) {
    return SC.getCall('getAgencyResourceTypes/' + agency_id);
}

function getCurrentAgencyResource() {
    return SC.getCall('getUsersData');
}

function updatePermission(agencyResourceId) {
    return SC.getCall('changePrimaryContact/' + agencyResourceId);
}

function getResourcesByRole(agency_id, order_id) {
    if (order_id)
        return SC.getCall('getResourcesByRole/' + agency_id+'/'+order_id);
    else
        return SC.getCall('getResourcesByRole/' + agency_id);
}

function getOrdersResourcesByRole(order_id) {
    return SC.getCall('getResourcesByRoleForOrder/' + order_id);
}

function getAgencyResourceByType(type, agency_id) {
    return SC.getCall(`getAgencyResourceByType/${agency_id}/${type}`);
}
