import React, { Component } from "react";
import { connect } from 'react-redux';
import { getInventoryLocationStats } from 'redux/actions/agency-actions';
import { Grid, Row, Col } from "react-bootstrap";
import { Card } from "components/Card/Card.jsx";
import SearchInput, { createFilter } from 'react-search-input';
const KEYS_TO_FILTERS = ["title"];

class InventoryLocationStatsListPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
            searchTerm: '',
        }
		this.props.getInvLocStats();
	}
	searchUpdated = (term) =>{
        this.setState({ searchTerm: term });
    }
	render() {
		const { invLocationStats } = this.props;
		const { searchTerm } = this.state;
		// if ( invLocationStats.items.length > 0 ) {
		const filteredResources = invLocationStats.items.filter(createFilter(searchTerm, KEYS_TO_FILTERS));
		let moduleContent;
		if (filteredResources.length > 0) {
            moduleContent = filteredResources.map((item, key) => {
                return (
                    <tr key={key}>
						<td>{ item.title }</td>
						<td>{ item.inventoryLocation['warehouse'].inventoryLocationStats.count }</td>
						<td>{ item.inventoryLocation['in field'].inventoryLocationStats.count }</td>
						<td>{ item.inventoryLocation['proccessed'].inventoryLocationStats.count }</td>
						<td>{ item.inventoryLocation['received'].inventoryLocationStats.count }</td>
					</tr>
                )
            });
        }
		// }



		return(
			<div className="content">
		        <Grid fluid>
		          <Row>
		            <Col md={12}>
		              <Card
		                ctTableResponsive
		                content={
		                	<div>
		                		<div className="flexElem mt-md mb-sm flexResponsive" style={{ "alignItems": "center", "justifyContent": "space-between" }}>

		                			<div></div>
	                                <div className="custom-search-input">
	                                    <SearchInput fuzzy={true} className="search-input form-control" placeholder="Search Item" onChange={this.searchUpdated} />
	                                    {/*<input type="text" className="form-control" placeholder="Search Permission" /> */}
	                                </div>
	                            </div>
		                		<table className="table table-responsive table-striped mt-md">
		                			<thead>
		                				<tr>
		                					<th>Item</th>
		                					<th>Warehouse</th>
		                					<th>In field</th>
		                					<th>Proccessed</th>
		                					<th>Received</th>
		                				</tr>
		                			</thead>
		                			<tbody>
		                				{
		                					moduleContent
		                				}

		                			</tbody>
		                			{
	                					(searchTerm==='' && Object.keys(invLocationStats.totalInvLoc).length > 0) &&
		                				<tfoot>
			                				<tr>
			                					<td>Total</td>
			                					<td>{ invLocationStats.totalInvLoc['warehouse'].totalCount }</td>
			                					<td>{ invLocationStats.totalInvLoc['in field'].totalCount }</td>
			                					<td>{ invLocationStats.totalInvLoc['proccessed'].totalCount }</td>
			                					<td>{ invLocationStats.totalInvLoc['received'].totalCount }</td>
			                				</tr>
		                				</tfoot>
	                				}
		                		</table>

		                	</div>
		                }
		               />
		              </Col>
		          </Row>
		        </Grid>
      		</div>
		);
	}
}

const mapDispatchToProps = dispatch => {
	return ({
		getInvLocStats: () => { dispatch(getInventoryLocationStats()) },
	});
};

function mapStateToProps(state) {
	const { loadingAgency, invLocationStats } = state.agenciesReducer;
	return {
		loadingAgency, invLocationStats
	};
}


const InventoryLocationStatsList = connect(mapStateToProps, mapDispatchToProps)(InventoryLocationStatsListPage);
export default InventoryLocationStatsList;
