import { SC } from "../helper/ServerCall";

export const moreButtonService = {

    storeMoreButton,
    getMoreButton,
    updateMoreButton,
    getMoreButtonsPagination,
    deleteMoreButton,
    updateMoreButtonStatus,
    updateMoreButtonForOrder,
    storeAgencyResource,
    getAgencyResources,
    deleteAgencyResource,
    getMoreButtonsForOrder,
    getAccountMoreButtonsPagination,
    updateAccountMoreButton
}

function storeMoreButton(data) {
    return SC.postCall(`storeMoreButton`, data);
}
function getMoreButton(id) {
    return SC.getCall(`editMoreButton/${id}`);
}
function updateMoreButtonStatus(moreButtonId, data) {
    return SC.putCall('updateMoreButtonStatus/' + moreButtonId, data);
}
function storeAgencyResource(resource, id) {
    return SC.postCall("storeAgencyResource/" + id, resource);
}

function getAgencyResources(agencyId, type) {
return SC.getCall(`getAgencyResources/${agencyId}/${type}`);
}

function deleteAgencyResource(id) {
return SC.deleteCall("AgencyResourceDelete/" + id);
}

function updateMoreButton(data, id) {
    return SC.postCall(`updateMoreButton/${id}`, data);
}
function updateAccountMoreButton(data, id) {
    return SC.postCall(`updateMoreButton/${id}`, data);
}
function updateMoreButtonForOrder(data, id) {
    return SC.postCall(`updateMoreButton/${id}`, data);
}
function getMoreButtonsPagination(page, filter, sort, pageSize, orderType, id) {
    return SC.postCall(`agencyMoreButtonsList/${id}?page=${page}&filter=${filter}&sort=${sort}&pageSize=${pageSize}&orderType=${orderType}`);
    // return SC.postCall(`agencyMoreButtonsListTest/${id}?page=${page}&filter=${filter}&sort=${sort}&pageSize=${pageSize}&orderType=${orderType}`);
}
function getAccountMoreButtonsPagination(page, filter, sort, pageSize, orderType, id) {
    return SC.postCall(`accountMoreButtonsList/${id}?page=${page}&filter=${filter}&sort=${sort}&pageSize=${pageSize}&orderType=${orderType}`);
}
function getMoreButtonsForOrder(page, filter, sort, pageSize, orderType, id, agency_id = null, uuid = null) {
    return SC.getCall(`getMoreButtonsForOrder/${id}?page=${page}&filter=${filter}&sort=${sort}&pageSize=${pageSize}&orderType=${orderType}&agency_id=${agency_id}&uuid=${uuid}`);
}

function deleteMoreButton(id) {
    return SC.deleteCall(`deleteMoreButton/${id}`);
}
