import React, { Component } from 'react';
import {
    Col,
    OverlayTrigger,
    ButtonToolbar,
    Popover
} from "react-bootstrap";
import ThemeModal from '../../ThemeComponents/ThemeModal';
import { SC } from 'helper/ServerCall';
import ToggleButton from 'react-toggle-button';
import { themeCSSConstants } from 'redux/constant/themeCSS';
import MoreButtonForm from "../MoreButton/MoreButtonForm";
import { connect } from 'react-redux'
import { getMoreButtonsPagination, deleteMoreButton, updateMoreButtonStatus } from '../../../redux/actions/more-buttons-action';
import Confirmalertfordelete from 'components/ThemeComponents/confirmAlertForDelete';
import { createSortString, closeModal, dateFormatMy } from '../../../helper/helperFunctions'

import 'react-table/react-table.css';
import { confirmAlert } from 'react-confirm-alert';
import MoreButtonsPagination from '../../ThemeComponents/MoreButtonsPagination';
import {objectsConstants as OC} from 'redux/constant/objects-constant';
import { dragFaq } from '../../../assets/media/icons';
class MoreButtonsList extends Component {

    constructor(props) {
        super(props);
        this.table = React.createRef();
        this.state = {
            pageSize: 100,
            filtered: [],
            moreButtons: null,
            moreButtonEditModal: false,
            moreButtonCreateModal: false,
            moreButtonEditData: '',
            is_active: '1',
            // childData: null
        }
        this.showModal = this.showModal.bind(this);
    }

    showModal(data = '', e) {
        let newData = { ...data };
        newData.file_path = {
            preview: data.file_path,
            name: data.file_name,
            type: data.mime_type,
            id: data.id
        }

        this.setState({
            [e.target.name]: true,
            moreButtonEditData: newData,
        });

    }
    deleteConfirmMoreButton = (id) => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <Confirmalertfordelete typeName="Button" description="Are you sure you want to delete this button" onClosePro={onClose} deleteType={() => this.deleteMoreButtonHandler(id)} />
                )
            }
        })
    }

    // updateToggles(id) {
    //     // if(this.state.moreButtons.link !== this.state.oldState.link){
    //         return SC.postCall('updateToggles/' + id );
    //     //   }
    // }

    updateMoreButton = (id, data) => {
            return SC.postCall('updateMoreButton/' + id, data );
    }

    updateMoreButtonsSortOrders = (data) => {
        return SC.postCall('updateMoreButtonsSortOrders' , data );
    }

    handleOptionAtOrderPlacement = (name, value, id,data) => {
        // let newData = { ...data };
        // newData.file_path = {
        //     preview: data.file_path,
        //     name: data.file_name,
        //     type: data.mime_type,
        //     id: data.id
        // }
        let tempValue = !value;
        tempValue = tempValue ? 1 : 0;

        if (name === 'show_on_all_orders' || name === 'option_at_order_placement') {
            			var WtOrder = '';
            			if (name === 'show_on_all_orders'){
            				WtOrder = 'option_at_order_placement';
                        }
            			else{
            				WtOrder = 'show_on_all_orders';
                        }
                        let newData = {
                            ...data,
                            [name] : tempValue,
                            [WtOrder] : 0,
                        }
                        this.updateMoreButton(id, newData).then(res=>{
                            this.pagination.dataCall();
                        })
            		}

		// let tempValue = !value;
        // tempValue = tempValue ? 1 : 0;
		// this.setState(prevState => ({
		// 			moreButtons: {
        //                 ...prevState.moreButtons,
        //                 [name]: tempValue
        //             }
		// })
        // 	,
		// 	() => {
		// 		if (name === 'show_on_all_orders' || name === 'option_at_order_placement') {
		// 			var WtOrder = '';
		// 			if (name === 'show_on_all_orders')
		// 				WtOrder = 'option_at_order_placement';
		// 			else
		// 				WtOrder = 'show_on_all_orders';
		// 			this.setState(prevState => ({
		// 				moreButtons: {
        //                     ...prevState.moreButtons,
        //                     [WtOrder]:0
        //                 }
		// 			}),() => {
        //                 // console.log(this.state.moreButtons, "asdasd");
        //                 // this.updateMoreButton(id,data);
        //             });
		// 		}
        //     });
	}

    deleteMoreButtonHandler(id) {
        this.props.deleteMoreButtonCall(id).then(res => {
            this.pagination.dataCall();
        });
    }

    paginationCall = (data) => {
        let sorted = data.sort.split(',');
        let { sort, orderType } = createSortString(sorted);
        return this.props.getMoreButtonsPaginationCall(data.page, data.filter, sort, data.pageSize, orderType, this.props.agency_id);

    }
    handleInactiveStatusUpdate = (moreButtonId, e) => {
        this.props.updateMoreButtonStatusCall(moreButtonId, { is_active: e.target.value ? true : false }).then(
            response => {
                this.pagination.dataCall();
            }
        );
    }
    handleActiveStatusUpdate = (moreButtonId, e) => {
        this.props.updateMoreButtonStatusCall(moreButtonId, { is_active: e.target.value ? false : true }).then(
            response => {
                this.pagination.dataCall();
            }
        );
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.state.is_active !== prevState.is_active) {
            this.pagination.dataCall();
        }

        if (this.props.agency_id !== prevProps.agency_id && this.props.agency_id) {
            // console.log('this.props.agency_id',this.props.agency_id, prevProps.agency_id);
            this.pagination.dataCall();
        }
    }
    // componentDidMount() {
    //     let response = this.getAgencyWeTravelButton().then( (response) =>  {
    //         // console.log(response, 'response#21');
    //         this.setState({
    //             weTravelButton: response.data.weTravel
    //         })
    //     })
    // }

    render() {
        const { moreButtons, is_active } = this.state;
        const columns = [
            {
                Header: 'Title',
                accessor: 'title',
                className: "action-center",
                width: 120,
                height: 100,

            },
            {
                Header: 'Description',
                accessor: 'description',
                className: "action-center",
            },
            // {
            //     Header: 'File Name',
            //     accessor: 'file_name',
            //     className: "action-center",
            //     // Cell: ({ original }) => {
            //     //     console.log(original, "data");
            //     //     return <span>{original.file_name}</span>
            //     // }
            // },
            {
                Header: 'Show on all orders',
                accessor: 'show_on_all_orders',
                className: "action-center",
                Cell: ({ original,row }) => {
                    return (

                        <div className="togglePadding">
                            <ToggleButton
                                inactiveLabel={''}
                                activeLabel={''}
                                colors={themeCSSConstants.THUMBICONSTYLE}
                                trackStyle={{ height: '10px', width: '30px' }}
                                thumbStyle={themeCSSConstants.THUMBSTYLE}
                                name={'moreButtonEditModal'}
                                thumbAnimateRange={[0, 15]}
                                value={original.show_on_all_orders ? original.show_on_all_orders : ''}
                                disabled={false}
                                readOnly={true}
                                onToggle={() => this.handleOptionAtOrderPlacement("show_on_all_orders", original.show_on_all_orders, original.id,original)}
                            />
                        </div>
                    )
                }
            },
            {
                Header: 'Option at order placement',
                accessor: 'option_at_order_placement',
                className: "action-center",
                Cell: ({ original, row }) => {
                    // console.log(value,'option cell value')
                    return (
                        <div className="togglePadding">
                            <ToggleButton
                                inactiveLabel={''}
                                activeLabel={''}
                                colors={themeCSSConstants.THUMBICONSTYLE}
                                trackStyle={{ height: '10px', width: '30px' }}
                                thumbStyle={themeCSSConstants.THUMBSTYLE}
                                name={'moreButtonEditModal'}
                                thumbAnimateRange={[0, 15]}
                                // value={moreButtons && moreButtons[row._index].option_at_order_placement}
                                value={original.option_at_order_placement ? original.option_at_order_placement : ''}
                                disabled={false}
                                readOnly={true}
                                // onToggle={() => this.handleOptionAtOrderPlacement("option_at_order_placement", moreButtons && moreButtons[row._index].option_at_order_placement, original.id, original)}
                                onToggle={() => this.handleOptionAtOrderPlacement("option_at_order_placement", original.option_at_order_placement, original.id, original)}
                            />
                        </div>
                    )
                },
            },
            {
                Header: 'Expiration Date',
                accessor: 'expiration_date',
                className: "action-center",
                Cell: ({ value }) => {
                    return (
                        value ? dateFormatMy(value) : 'None'
                    )
                },
            },
            {
                Header: 'Status',
                accessor: 'is_active',
                className: "action-center",
                Cell: ({ original, value }) => {
                    return (
                        parseInt(value, 10) == 1 ? 'Active' : 'In-Active'
                    )
                },
            },
            {
                Header: "Actions",
                accessor: "id",
                className: "action-center",
                Cell: ({ original, value }) => {
                    return (

                        <div>

                            <ButtonToolbar>
                                <span className='flexElem flexGap10'>
                                    <OverlayTrigger trigger="focus" placement="bottom" overlay={
                                        <Popover id="tooltip">
                                            <table className="table text-left actions_table">
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <button name='moreButtonEditModal' onClick={(e) => { this.showModal(original, e) }} type='button' className="orderDetailsAction editIcon" >Edit</button>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            <button onClick={() => this.deleteConfirmMoreButton(value)} className="btn_link text-danger"><i className="fa fa-trash text-danger " /> Delete</button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <button onClick={(e) => this.handleActiveStatusUpdate(value, e)} className="btn_link"><i className="fa fa-check-square-o" /> Active</button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <button onClick={(e) => { this.handleInactiveStatusUpdate(value, e) }} className="btn_link text-danger" ><i className="fa fa-ban text-danger " />Inactive</button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                        </Popover>
                                    }>
                                        <span>
                                            <button type='button' className="action-button-size" ><i className="fa fa-bars" /></button>
                                        </span>
                                    </OverlayTrigger>
                                    <OverlayTrigger placement="bottom" overlay={<Popover id="tooltip">Drag & Sort FAQs</Popover>}>
                                        <span>
                                            <img src={dragFaq} width={20} alt="" />
                                        </span>
                                    </OverlayTrigger>
                                </span>
                            </ButtonToolbar>

                        </div>
                    )
                },

                className: "action-center",
                sortable: false,
                excExp: false,
            }
        ]
        return (
            <div>
                {/* <Col md={12} className="mt-md"> */}
                    <React.Fragment>
                        <div className='row'>
                            <MoreButtonsPagination
                                noCols={true}
                                addNewBtn={
                                    <>
                                        <button type="button" onClick={(e) => this.showModal('', e)} name='moreButtonCreateModal' className='backButton mr pt-sm no_radius pb-sm primary text-xs btn btn-lg btn-info btn btn-lg btn-info  btn btn-default' ><i className="fa fa-plus" />Create New ‘More’ Button</button>
                                    </>
                                }
                                ref={(p) => this.pagination = p}
                                showPagination={false}
                                columns={columns}
                                pageSize={100}
                                noDataText='No Item found'
                                getDataCall={this.paginationCall}
                                updateSort={this.updateMoreButtonsSortOrders}
                                // filterView={true}
                                // filterPlaceHolder={'Button'}
                                getRowProps={this.getRowProps}
                            // showAllToggle={true}
                            />
                        </div>
                    </React.Fragment>
                {/* </Col> */}
                <ThemeModal size='lg' show={this.state.moreButtonEditModal} hide={closeModal.bind(this, 'moreButtonEditModal')} title='Edit More Button'>
                    <MoreButtonForm getPagination={this.pagination ? this.pagination.dataCall : null} formType='edit' hide={closeModal.bind(this, 'moreButtonEditModal')} data={this.state.moreButtonEditData} agency_id={this.props.agency_id} />
                </ThemeModal>

                <ThemeModal size='lg' show={this.state.moreButtonCreateModal} hide={closeModal.bind(this, 'moreButtonCreateModal')} title='Create More Button'>
                    <MoreButtonForm getPagination={this.pagination ? this.pagination.dataCall : null} hide={closeModal.bind(this, 'moreButtonCreateModal')} formType='add' agency_id={this.props.agency_id} />
                </ThemeModal>
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    const { moreButtonsPagination } = state.moreButtonReducer;
    return { moreButtonsPagination }
}

const mapDispatchToProps = dispatch => {
    return {
        getMoreButtonsPaginationCall: (page, filter, sort, pageSize, orderType, agency_id) => { return dispatch(getMoreButtonsPagination(page, filter, sort, pageSize, orderType, agency_id)) },
        updateMoreButtonStatusCall: (moreButtonId, data) => { return dispatch(updateMoreButtonStatus(moreButtonId, data)) },
        deleteMoreButtonCall: (id) => { return dispatch(deleteMoreButton(id)) }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(MoreButtonsList)
