import React from 'react';
import { Button } from "react-bootstrap";
import SimpleReactValidator from "simple-react-validator";
import { connect } from "react-redux";
import { storeModule } from '../../../redux/actions/module-actions';
// import Message from "./../../../layouts/Dashboard/Message";
class ModuleForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            module: {
                name: ''
            }
        }
        this.validator = new SimpleReactValidator({autoForceUpdate: this, locale: 'en'});
    }

    handleModuleChange = (e) => {
        const module = { ...this.state.module };
        module['name'] = e.target.value;
        this.setState({
            module: module
        })
    }

    handleModuleSubmit = (e) => {
        e.preventDefault();
        if (this.validator.allValid()) {
            this.props.onModuleSubmit(this.state.module).then(
                response => {
                    this.props.closed();
                }
            );
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    render() {
        return (
            <div>
                <form id='module-create-form' onSubmit={this.handleModuleSubmit} >
                    <div className='form-group' >
                        <label htmlFor="name">Module Name<span className="requiredClass">*</span></label>
                        <input type="text" name="name" className="form-control" value={this.state.module.name} onChange={this.handleModuleChange} />
                        {this.validator.message('module_name', this.state.module.name, 'required|string')}
                    </div>
                    <div className='btn-toolbar'>
                        <Button form='module-create-form' type='submit' bsSize="large" bsStyle="info" className="outOfTableButton" >Save</Button>
                        <Button bsSize="large" bsStyle="info" onClick={this.props.closed} className="outOfTableButton" >Cancel</Button>
                    </div>
                </form>
            </div>
        );
    }
}
const mapStateToProps = state => {
    const { loading, modules } = state.moduleReducer;
    return {
        loading, modules
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        onModuleSubmit: (moduleData) => dispatch(storeModule(moduleData))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModuleForm);