import { SC } from '../helper/ServerCall'
export const agencyTypeService = {
    getAgencyTypePagination,
    getAgencyType,
    storeAgencyType,
    updateAgencyType,
    deleteAgencyType
}

function getAgencyTypePagination(page, filter, sort, pageSize) {
    return SC.getCall(`agencyType?page=${page}&filter=${filter}&sort=${sort}&pageSize=${pageSize}`);
}

function getAgencyType(id) {
    return SC.getCall(`agencyType/${id}`);
}

function storeAgencyType(data) {
    return SC.postCall(`agencyType`, data);
}

function updateAgencyType(id, data) {
    return SC.putCall(`agencyType/${id}`, data);
}

function deleteAgencyType(id) {
    return SC.deleteCall(`agencyType/${id}`);
}