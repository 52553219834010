import React from "react";
import { connect } from 'react-redux';
import DownloadFile from 'components/ThemeComponents/downloadFile';

class GenerateAppCard extends React.Component {
    render() {
        const { orderUploadInfo } = this.props;
        return (
            <React.Fragment >
                {
                    <a target="_blank" href={`${this.props.base_url}/generateAppCard1Up/${this.props.orders}`} className={this.props.className}>
                        <i className={this.props.navIcon}></i>
                        {this.props.text}
                    </a>
                    // orderUploadInfo.order_resources
                    //     ?
                    //     'app_card' in orderUploadInfo.order_resources
                    //         ?
                    //         <DownloadFile multiple={false} extraClass={`${this.props.className} showNoDownlaod`} title={
                    //             <React.Fragment>
                    //                 <i className={this.props.navIcon}></i>
                    //                 &nbsp;
                    //                 {this.props.text}
                    //             </React.Fragment>
                    //         } files={{
                    //             'file_title': orderUploadInfo.order_resources.app_card.file_title,
                    //             'file_path': orderUploadInfo.order_resources.app_card.file_path
                    //         }}
                    //         />
                    //         :
                    //         null
                    //     :
                    //     null
                }
            </React.Fragment>
        );
    }
}
const mapStateToProps = (state) => {
    const { orderUploadInfo } = state.frontEndUserReducer;
    return {
        orderUploadInfo
    }
}

export default connect(mapStateToProps)(GenerateAppCard)
