import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { avatar, trashIcon } from "../../../assets/media/chat/ChatIcons";
import { orderService } from "../../../services/order";
import { withRouter } from 'react-router-dom';
import ConfirmAlertForDelete from 'components/ThemeComponents/confirmAlertForDelete';
import { confirmAlert } from 'react-confirm-alert';
import { alertActions } from '../../../redux/actions/alert-actions';
import './ChatModule.css';

function TravelersConversation({ sender, receiver, summary_chats, groupChat, order_id, successAlert, errorAlert }) {
    const [chat, setChat] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [disableDelete, setDeleteToggle] = useState(true);

    async function getChat() {
        try {
            let data = {
                order_id: order_id,
                sender_id: sender ? sender.id : '',
                receiver_id: receiver ? receiver.id : '',
                group_chat_id: groupChat ? groupChat.id : ''
            }
            let res = await orderService.getOrderChat(data);
            let chat = [...res.result.data.map(item => { return { ...item, selected: false } })];
            chat = chat.sort((a, b) => {
                return new Date(a.timestamp) - new Date(b.timestamp);
            })
            setChat(chat);
        } catch (e) {
            console.log('Error in listing', e);
        }
    }

    useEffect(() => {
        if (receiver)
            getChat();
    }, [receiver]);

    useEffect(() => {
        if (groupChat)
            getChat();
    }, [groupChat]);

    useEffect(() => {
        if (chat.length > 0) {
            let none_seletected = true;
            chat.forEach(item => {
                if (item.selected) none_seletected = false;
            });
            setDeleteToggle(none_seletected);
        }
        setSelectAll(false);
    }, [chat.length]);

    useEffect(() => {
        if (chat.length > 0) {
            let none_seletected = true;
            chat.forEach(item => {
                if (item.selected) none_seletected = false;
            });
            setDeleteToggle(none_seletected);
        }
    }, [chat]);

    function markMessage(e, index) {
        let chatTemp = [...chat];
        chatTemp[index]['selected'] = e.target.checked;
        setChat(chatTemp);
    }

    function confrimDeleteMessages() {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <ConfirmAlertForDelete typeName="Message" description="Are you sure to delete these messages?" onClosePro={onClose} deleteType={() => { deleteMessages() }} />
                )
            }
        })
    }

    async function deleteMessages() {
        try {
            let messages = chat.filter(msg => msg.selected).map(msg => msg.path);
            const data = {
                messages,
                order_id,
                sender_id: sender ? sender.id : '',
                receiver_id: receiver ? receiver.id : '',
                group_chat_id: groupChat ? groupChat.id : ''
            };
            if (messages.length > 0) {
                const res = await orderService.deleteMessages({ ...data });
                successAlert(res.message);
                setSelectAll(false);
                getChat();
            }
        } catch (e) {
            console.log("🚀 ~ file: TravelerConversation.jsx:65 ~ deleteMessages ~ e:", e)
            errorAlert(e);
        }
    }

    async function markAllMessages(e) {
        let chatTemp = [...chat.map(item => {
            item.selected = e.target.checked;
            return item;
        })];
        setSelectAll(e.target.checked);
        setChat(chatTemp);
    }

    function formatAMPM(date) {
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0' + minutes : minutes;
        var strTime = hours + ':' + minutes + ' ' + ampm;
        return strTime;
    }

    function renderDateTimeString(t) {
        if (t) {
            let dateObj = new Date(t);
            let dateStr = dateObj.toDateString();
            let dateArr = dateStr.split(' ');
            dateArr[2] = `${dateArr[2]}, `;
            let convertedStr = dateArr.join(' ');
            let timeStr = formatAMPM(dateObj);
            return convertedStr + ' ' + timeStr;
        }
    }

    return (
        <div className="groupTravelers">
            {
                ((sender && receiver) || groupChat) &&
                <div id="chat-box-container">
                    <div style={{ display: "flex", justifyContent: 'space-between', alignItems: 'center' }}>
                        <div className="chat-user-info">
                            <div>
                                <img src={avatar} alt="avatar" />
                            </div>
                            {
                                groupChat ?
                                    <div>
                                        <div>
                                            {groupChat.group_name}
                                        </div>
                                        <div>
                                            <strong>All Messages</strong>
                                        </div>
                                    </div>
                                    :
                                    <div>
                                        <div>
                                            {sender.firstName} {sender.lastName} --- {receiver.firstName} {receiver.lastName}
                                        </div>
                                        <div>
                                            <strong>All Messages</strong>
                                        </div>
                                    </div>
                            }
                        </div>
                        <div style={{ display: 'flex', gap: '20px', paddingRight: '20px' }}>
                            <div>
                                <span onClick={confrimDeleteMessages} style={{ cursor: 'pointer', pointerEvents: `${disableDelete ? 'none' : 'auto'}` }}>
                                    <img src={trashIcon} alt="Delete Chat" style={{ filter: `opacity(${disableDelete ? 0.5 : 1})` }} />
                                </span>
                            </div>
                            <div>
                                <label className="container-check">
                                    <input type="checkbox" name={'deleteText'}
                                        className="checkbox"
                                        onChange={markAllMessages} checked={selectAll}
                                    />
                                    <span className="checkmark"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className="conversation-container">
                        {
                            chat && chat.length > 0 ? chat.map((data, index) => {
                                return <div key={data.timestamp} style={{ paddingBottom: '10px' }}>
                                    {
                                        groupChat ?
                                            <div className={`${groupChat ? 'chatPurple' : data.sent_by === sender.id ? 'chatPurple' : 'chatGrey'}`}>
                                                {data.sender_first_name + ' ' + data.sender_last_name}
                                            </div>
                                            :
                                            <div className={`${groupChat ? 'chatPurple' : data.sent_by === sender.id ? 'chatPurple' : 'chatGrey'}`}>
                                                {data.sent_by === sender.id ? sender.firstName + ' ' + sender.lastName : receiver.firstName + ' ' + receiver.lastName}
                                            </div>

                                    }
                                    <div style={{ display: 'flex' }}>
                                        <div
                                            className={`${groupChat ? 'messageSenderPurple' : data.sent_by === sender.id ? 'messageSenderPurple' : 'messageSender'}`}
                                            style={{
                                                background: `${groupChat ? '#8A288F' : data.sent_by === sender.id ? '#8A288F' : '#F2F2F2'}`,
                                                color: `${groupChat ? 'white' : data.sent_by === sender.id ? 'white' : '#212121'}`,
                                            }}
                                        >
                                            <div>
                                                {data.message}
                                            </div>
                                        </div>
                                        <div style={{ width: '5%', paddingTop: '5px' }}>
                                            <label className="container-check">
                                                <input type="checkbox" name={'deleteText'} className="checkbox" onChange={(e) => { markMessage(e, index) }}
                                                    checked={data.selected}
                                                />
                                                <span className="checkmark"></span>
                                            </label>
                                        </div>
                                    </div>
                                    <small className={`${groupChat ? 'chatPurple' : data.sent_by === sender.id ? 'chatPurple' : 'chatGrey'}`}>
                                        {renderDateTimeString(data.timestamp)}
                                    </small>

                                </div>
                            })
                                : 'No Messages Found!'
                        }
                    </div>
                </div>
            }
        </div >
    );
}

const mapDispatchToProps = dispatch => {
    return ({
        errorAlert: (e) => {
            dispatch(alertActions.error(e))
        },
        successAlert: (msg) => {
            dispatch(alertActions.success(msg))
        },
    });
};

export default withRouter(connect(null, mapDispatchToProps)(TravelersConversation));
