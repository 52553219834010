import { adminConstants } from '../constant/admin-constant';

const initialState = {
	loadingBrand: false,
	faqsCategories: [],
	transmittalTemplatesTypes: [],
	transmittalTemplatesCategories: [],
	brandAppSettingFields: [],
};

export function brandsReducer(state = initialState, action) {

	switch (action.type) {

		case adminConstants.PAGINATION_BRANDS_REQUEST:
			return { ...state, loadingBrand: true, brands: {} };
		case adminConstants.PAGINATION_BRANDS_SUCCESS:
			return {
				...state,
				brandsData: action.payload.data.pagination.data,
				pages: action.payload.data.pagination.last_page,
				loadingBrand: false,
				current_page: action.payload.data.pagination.current_page
			};
		case adminConstants.PAGINATION_BRANDS_FAILURE:
			return { ...state, loadingBrand: false, error: action.payload };

		case adminConstants.STORE_BRAND_REQUEST:
			return { ...state, loadingBrand: true }
		case adminConstants.STORE_BRAND_SUCCESS:
			return { ...state, loadingBrand: false, storedBrand: action.payload.brand };
		case adminConstants.STORE_BRAND_FAILURE:
			return { ...state, loadingBrand: false, error: action.payload };

		case adminConstants.GET_BRAND_REQUEST:
			return { ...state, loadingBrand: true };
		case adminConstants.GET_BRAND_SUCCESS:
			return { ...state, loadingBrand: false, editBrand: action.payload.brand };
		case adminConstants.GET_BRAND_FAILURE:
			return { ...state, loadingBrand: false, error: action.payload };

		case adminConstants.EDIT_BRAND_REQUEST:
			return { ...state, loading: true };
		case adminConstants.EDIT_BRAND_SUCCESS:
			return { ...state, loading: false, updatedBrand: action.payload.brand };
		case adminConstants.EDIT_BRAND_FAILURE:
			return { ...state, loading: false, error: action.payload };

		case adminConstants.DELETE_BRAND_REQUEST:
			return { ...state, loading: true }
		case adminConstants.DELETE_BRAND_SUCCESS:
			return { ...state, loading: false };
		case adminConstants.DELETE_BRAND_FAILURE:
			return { ...state, loading: false, error: action.payload };

		case adminConstants.UPDATE_BRAND_STATUS_REQUEST:
			return { ...state, loading: true };
		case adminConstants.UPDATE_BRAND_STATUS_SUCCESS:
			return { ...state, loading: false, updatedBrandStatus: action.payload.brand };
		case adminConstants.UPDATE_BRAND_STATUS_FAILURE:
			return { ...state, loading: false, error: action.payload };


		case adminConstants.PAGINATION_FAQS_REQUEST:
			return { ...state, loadingBrand: true, faqs: {} };
		case adminConstants.PAGINATION_FAQS_SUCCESS:
			return { ...state, faqsData: action.payload.data.pagination.data, faqsPages: action.payload.data.pagination.last_page, loadingBrand: false, faqsCurrent_page: action.payload.data.pagination.current_page };
		case adminConstants.PAGINATION_FAQS_FAILURE:
			return { ...state, loadingBrand: false, error: action.payload };

		case adminConstants.GET_FAQS_CATEGORIES_REQUEST:
			return { ...state, loadingBrand: true };
		case adminConstants.GET_FAQS_CATEGORIES_SUCCESS:
			return { ...state, loadingBrand: false, faqsCategories: action.payload.faqsCategories };
		case adminConstants.GET_FAQS_CATEGORIES_FAILURE:
			return { ...state, loadingBrand: false, error: action.payload };

		case adminConstants.STORE_FAQ_REQUEST:
			return { ...state, loadingBrand: true }
		case adminConstants.STORE_FAQ_SUCCESS:
			return { ...state, loadingBrand: false, storedFaq: action.payload.faq };
		case adminConstants.STORE_FAQ_FAILURE:
			return { ...state, loadingBrand: false, error: action.payload };

		case adminConstants.GET_FAQ_REQUEST:
			return { ...state, loadingBrand: true };
		case adminConstants.GET_FAQ_SUCCESS:
			return { ...state, loadingBrand: false, editFaq: action.payload.faq };
		case adminConstants.GET_FAQ_FAILURE:
			return { ...state, loadingBrand: false, error: action.payload };

		case adminConstants.EDIT_FAQ_REQUEST:
			return { ...state, loading: true };
		case adminConstants.EDIT_FAQ_SUCCESS:
			return { ...state, loading: false, updatedFaq: action.payload.faq };
		case adminConstants.EDIT_FAQ_FAILURE:
			return { ...state, loading: false, error: action.payload };

		case adminConstants.DELETE_FAQ_REQUEST:
			return { ...state, loading: true }
		case adminConstants.DELETE_FAQ_SUCCESS:
			return { ...state, loading: false };
		case adminConstants.DELETE_FAQ_FAILURE:
			return { ...state, loading: false, error: action.payload };

		case adminConstants.UPDATE_FAQ_STATUS_REQUEST:
			return { ...state, loading: true };
		case adminConstants.UPDATE_FAQ_STATUS_SUCCESS:
			return { ...state, loading: false, updatedFaqStatus: action.payload.faq };
		case adminConstants.UPDATE_FAQ_STATUS_FAILURE:
			return { ...state, loading: false, error: action.payload };

		case adminConstants.PAGINATION_TRANSMITTAL_TEMPLATES_REQUEST:
			return { ...state, loadingBrand: true, transmittalTemplates: {} };
		case adminConstants.PAGINATION_TRANSMITTAL_TEMPLATES_SUCCESS:
			return { ...state, transmittalTemplatesData: action.payload.data.pagination.data, transmittalTemplatesPages: action.payload.data.pagination.last_page, loadingBrand: false, transmittalTemplatesCurrent_page: action.payload.data.pagination.current_page };
		case adminConstants.PAGINATION_TRANSMITTAL_TEMPLATES_FAILURE:
			return { ...state, loadingBrand: false, error: action.payload };

		case adminConstants.STORE_TRANSMITTAL_TEMPLATE_REQUEST:
			return { ...state, loadingBrand: true }
		case adminConstants.STORE_TRANSMITTAL_TEMPLATE_SUCCESS:
			return { ...state, loadingBrand: false, storedTransmittalTemplate: action.payload.transmittalTemplate };
		case adminConstants.STORE_TRANSMITTAL_TEMPLATE_FAILURE:
			return { ...state, loadingBrand: false, error: action.payload };

		case adminConstants.GET_TRANSMITTAL_TEMPLATE_REQUEST:
			return { ...state, loadingBrand: true };
		case adminConstants.GET_TRANSMITTAL_TEMPLATE_SUCCESS:
			return { ...state, loadingBrand: false, editTransmittalTemplate: action.payload.transmittalTemplate };
		case adminConstants.GET_TRANSMITTAL_TEMPLATE_FAILURE:
			return { ...state, loadingBrand: false, error: action.payload };

		case adminConstants.EDIT_TRANSMITTAL_TEMPLATE_REQUEST:
			return { ...state, loading: true };
		case adminConstants.EDIT_TRANSMITTAL_TEMPLATE_SUCCESS:
			return { ...state, loading: false, updatedTransmittalTemplate: action.payload.transmittalTemplate };
		case adminConstants.EDIT_TRANSMITTAL_TEMPLATE_FAILURE:
			return { ...state, loading: false, error: action.payload };

		case adminConstants.DELETE_TRANSMITTAL_TEMPLATE_REQUEST:
			return { ...state, loading: true }
		case adminConstants.DELETE_TRANSMITTAL_TEMPLATE_SUCCESS:
			return { ...state, loading: false };
		case adminConstants.DELETE_TRANSMITTAL_TEMPLATE_FAILURE:
			return { ...state, loading: false, error: action.payload };

		case adminConstants.GET_TRANSMITTAL_TEMPLATES_TYPES_REQUEST:
			return { ...state, loadingBrand: true };
		case adminConstants.GET_TRANSMITTAL_TEMPLATES_TYPES_SUCCESS:
			return { ...state, loadingBrand: false, transmittalTemplatesTypes: action.payload.transmittalTemplatesTypes };
		case adminConstants.GET_TRANSMITTAL_TEMPLATES_TYPES_FAILURE:
			return { ...state, loadingBrand: false, error: action.payload };

		case adminConstants.GET_TRANSMITTAL_TEMPLATES_CATEGORIES_REQUEST:
			return { ...state, loadingBrand: true };
		case adminConstants.GET_TRANSMITTAL_TEMPLATES_CATEGORIES_SUCCESS:
			return { ...state, loadingBrand: false, transmittalTemplatesCategories: action.payload.transmittalTemplatesCategories };
		case adminConstants.GET_TRANSMITTAL_TEMPLATES_CATEGORIES_FAILURE:
			return { ...state, loadingBrand: false, error: action.payload };

		case adminConstants.GET_BRAND_APP_SETTING_REQUEST:
			return { ...state, loadingBrand: true };
		case adminConstants.GET_BRAND_APP_SETTING_SUCCESS:
			return { ...state, loadingBrand: false, brandAppSettingFields: action.payload.brandAppSettingFields };
		case adminConstants.GET_BRAND_APP_SETTING_FAILURE:
			return { ...state, loadingBrand: false, error: action.payload };

		default:
			return { ...state };
	}
}