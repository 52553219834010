import { adminConstants } from '../constant/admin-constant';
import { alertActions } from './alert-actions';
import { reportService } from '../../services/report';

export function getVideoShipments(value, fromDate, toDate, page, pageSize, sorted) {
    return dispatch => {
        dispatch(request())
        return reportService.getVideoShipments(value, fromDate, toDate, page, pageSize, sorted).then(
            response => {
                dispatch(success(response))
                return response;
            }).catch(error => {
                dispatch(failure(error))
                Promise.reject(error)
            })
    }
    function request() { return { type: adminConstants.GET_ALL_VIDEO_SHIPMENT_REQUEST } }
    function success(response) { return { type: adminConstants.GET_ALL_VIDEO_SHIPMENT_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.GET_ALL_VIDEO_SHIPMENT_FAILURE, payload: error } }
}

export function getTodaysShipments(value, fromDate, toDate, page, pageSize, sorted) {
    return dispatch => {
        dispatch(request())
        return reportService.getTodaysShipments(value, fromDate, toDate, page, pageSize, sorted).then(
            response => {
                dispatch(success(response))
                return response;
                // console.log('response', response);
            }).catch(error => {
                dispatch(failure(error))
                return Promise.reject(error);
            })
    }
    function request() { return { type: adminConstants.GET_ALL_TODAYS_SHIPMENT_REQUEST } }
    function success(response) { return { type: adminConstants.GET_ALL_TODAYS_SHIPMENT_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.GET_ALL_TODAYS_SHIPMENT_FAILURE, payload: error } }
}

export function getWebLog(value, fromDate, toDate, page, pageSize, sorted) {
    return dispatch => {
        dispatch(request())
        return reportService.getWebLog(value, fromDate, toDate, page, pageSize, sorted).then(
            response => {
                dispatch(success(response))
                return response;
            }).catch(error => {
                dispatch(failure(error))
                return Promise.reject(error)
            })
    }
    function request() { return { type: adminConstants.GET_ALL_WEB_LOG_REQUEST } }
    function success(response) { return { type: adminConstants.GET_ALL_WEB_LOG_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.GET_ALL_WEB_LOG_FAILURE, payload: error } }
}

export function getOrderLog(value, fromDate, toDate, page, pageSize, sorted) {
    return dispatch => {
        dispatch(request())
        return reportService.getOrderLog(value, fromDate, toDate, page, pageSize, sorted).then(
            response => {
                dispatch(success(response))
                return response;
            },
            error => {
                dispatch(failure(error))
            })
    }
    function request() { return { type: adminConstants.GET_ALL_ORDER_LOG_REQUEST } }
    function success(response) { return { type: adminConstants.GET_ALL_ORDER_LOG_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.GET_ALL_WEB_ORDER_FAILURE, payload: error } }
}

export function getContactLog(fromDate, toDate, page, pageSize, sorted) {
    return dispatch => {
        dispatch(request())
        return reportService.getContactLog(fromDate, toDate, page, pageSize, sorted).then(
            response => {
                dispatch(success(response))
                return response;
            },
            error => {
                dispatch(failure(error))
            })
    }
    function request() { return { type: adminConstants.GET_ALL_CONTACT_LOG_REQUEST } }
    function success(response) { return { type: adminConstants.GET_ALL_CONTACT_LOG_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.GET_ALL_CONTACT_LOG_FAILURE, payload: error } }
}

export function getAccessLog(value, fromDate, toDate, page, pageSize, sorted) {
    return dispatch => {
        dispatch(request())
        return reportService.getAccessLog(value, fromDate, toDate, page, pageSize, sorted).then(
            response => {
                dispatch(success(response))
                return response;
            },
            error => {
                dispatch(failure(error))
            })
    }
    function request() { return { type: adminConstants.GET_ALL_ACCESS_LOG_REQUEST } }
    function success(response) { return { type: adminConstants.GET_ALL_ACCESS_LOG_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.GET_ALL_ACCESS_LOG_FAILURE, payload: error } }
}

export function getOrderBooking(value, fromDate, toDate, page, pageSize, sorted) {
    return dispatch => {
        dispatch(request())
        return reportService.getOrderBooking(value, fromDate, toDate, page, pageSize, sorted).then(
            response => {
                dispatch(success(response))
                return response;
            }, error => {
                dispatch(failure(error))
            })
    }
    function request() { return { type: adminConstants.GET_ALL_ORDER_BOOKING_REQUEST } }
    function success(response) { return { type: adminConstants.GET_ALL_ORDER_BOOKING_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.GET_ALL_ORDER_BOOKING_FAILURE, payload: error } }
}


export function getOrdersWithStatus(status, value, fromDate, toDate, page, pageSize, sorted) {
    return dispatch => {
        dispatch(request())
        return reportService.getOrdersWithStatus(status, value, fromDate, toDate, page, pageSize, sorted).then(
            response => {
                dispatch(success(response))
                return response;
            },
            error => {
                dispatch(failure(error))
            })
    }
    function request() { return { type: adminConstants.GET_ALL_ORDERS_WITH_STATUS_REQUEST } }
    function success(response) { return { type: adminConstants.GET_ALL_ORDERS_WITH_STATUS_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.GET_ALL_ORDERS_WITH_STATUS_FAILURE, payload: error } }
}

export function getForcastReport(value, fromDate, toDate, page, pageSize, sorted) {
    return dispatch => {
        dispatch(request())
        return reportService.getForcastReport(value, fromDate, toDate, page, pageSize, sorted).then(
            response => {
                dispatch(success(response))
                return response;
            },
            error => {
                dispatch(failure(error))
            })
    }
    function request() { return { type: adminConstants.GET_ALL_SALES_FRECAST_REQUEST } }
    function success(response) { return { type: adminConstants.GET_ALL_SALES_FRECAST_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.GET_ALL_SALES_FRECAST_FAILURE, payload: error } }
}

//new action
export function getReceivingReports(data) {
    return dispatch => {
        // dispatch(request())
        return reportService.getReceivingReports(data).then(
            response => {
                // dispatch(success(response))
                return response
            }).catch(error => {
                return Promise.reject(error);
                // dispatch(failure(error))
            })
    }
    // function request() { return { type: adminConstants.GET_RECEIVING_REPORTS_REQUEST } }
    // function success(response) { return { type: adminConstants.GET_RECEIVING_REPORTS_SUCCESS, payload: response } }
    // function failure(error) { return { type: adminConstants.GET_RECEIVING_REPORTS_FAILURE, payload: error } }
}

export function getUCDReached(value, fromDate, toDate, page, pageSize, sorted) {
    return dispatch => {
        dispatch(request())
        return reportService.getUCDReached(value, fromDate, toDate, page, pageSize, sorted).then(
            response => {
                dispatch(success(response))
                return response;
            }).catch(error => {
                dispatch(failure(error))
                return Promise.reject(error)
            })
    }
    function request() { return { type: adminConstants.GET_ALL_UCD_REACHED_REQUEST } }
    function success(response) { return { type: adminConstants.GET_ALL_UCD_REACHED_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.GET_ALL_UCD_REACHED_FAILURE, payload: error } }
}
export function checkOrderStatusWithBarcode(data) {
    return dispatch => {
        dispatch(request())
        return reportService.checkOrderStatusWithBarcode(data).then(
            response => {
                dispatch(success(response))
                // dispatch(alertActions.success(response.message));
                return response;
            }).catch(error => {
                dispatch(failure(error))
            })
    }
    function request() { return { type: adminConstants.CHECK_BARCODE_STATUS_REQUEST } }
    function success(response) { return { type: adminConstants.CHECK_BARCODE_STATUS_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.CHECK_BARCODE_STATUS_FAILURE, payload: error } }
}

export function createLabelWithBarcode(data) {
    return dispatch => {
        return reportService.createLabelWithBarcode(data).then(
            response => {
                dispatch(alertActions.success(response.message));
                return response;
            }).catch(error => {
                return error;
            })
    }
}

