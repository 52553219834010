import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Grid,
  Row,
  Col,
  // Tooltip,
  OverlayTrigger,
  // Button,
  Popover
} from "react-bootstrap";
import Select from 'react-select';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Card } from "components/Card/Card.jsx";
import { getAllAgenciesUsersPagination, getAgencyUser, storeAgencyUser, editAgencyUser, deleteAgencyUser } from 'redux/actions/agency-user-actions';
import { getAgencyRoles } from 'redux/actions/role-actions';
import AgencyUserForm from "components/Admin/AgencyUsers/AgencyUserForm";
import { hasPermission } from 'helper/hasPermission';
import Confirmalertfordelete from 'components/ThemeComponents/confirmAlertForDelete';
import { adminLabels } from 'redux/constant/admin-label-constant';
import OpenModalButton from 'components/ThemeComponents/openModelButton';
import { objectsConstants as OC } from 'redux/constant/objects-constant';
import { selectChangeHandler, clean, hasRole, openModal, closeModal } from 'helper/helperFunctions';
import Pagination from 'components/ThemeComponents/Pagination';
import { updateUserStatus, showPassword } from 'redux/actions/user-actions';
import ShowPassword from 'components/ThemeComponents/showPassword';
import { Link } from 'react-router-dom';
import { AiOutlineEye } from 'react-icons/ai';
var Modal = require('react-bootstrap-modal');

class AllAgenciesUsersListPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: OC.AGENCYUSER,
      addModalAgencyUser: false,
      editModalAgencyUser: false,
      agency_id: '',
      agencyName: '',
      is_active: '1',
    };

    this.openModalAgencyUserEdit = this.openModalAgencyUserEdit.bind(this);
    this.deleteAgencyUser = this.deleteAgencyUser.bind(this);
    this.deleteConfirmAgencyUser = this.deleteConfirmAgencyUser.bind(this);
    this.selectChangeHandler = selectChangeHandler.bind(this);
    this.handleAgencyUserSubmit = this.handleAgencyUserSubmit.bind(this);
    this.clean = clean.bind(this);
    this.props.getAllRolesCall();
  };

  handleAgencyUserSubmit(userData, formType) {
    if (formType === 'add')
      this.props.storeAgencyUserCall(this.state.agency_id, userData).then(response => {
        this.setState({ addModalAgencyUser: false }, function () {
          this.pagination.dataCall();
        });
      });
    else if (formType === 'edit')
      this.props.editAgencyUserCall(userData, this.props.editAgencyUser.id, this.state.agency_id).then(response => {
        this.setState({ editModalAgencyUser: false, agency_id: '' }, function () {
          this.pagination.dataCall();
        });
      });
  }

  openModalAgencyUserEdit(original) {
    this.setState({
      agency_id: original.agency[0].pivot.agency_id
    })
    this.props.getAgencyUserCall(original.agency[0].pivot.agency_id, original.id);
  }
  deleteAgencyUser(original) {
    this.props.deleteAgencyUserCall(original.agency[0].pivot.agency_id, original.id).then(response => {
      this.pagination.dataCall();
    });
  }
  deleteConfirmAgencyUser(userId) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <Confirmalertfordelete typeName="Agency User" description="Are you sure to delete the admin agency user?" onClosePro={onClose} deleteType={() => this.deleteAgencyUser(userId)} />
        )
      }
    })
  }
  paginationCall = (data) => {
    return this.props.getAllAgenciesUsersCall(data.page, data.filter, data.sort, data.pageSize, this.state.is_active).then(response => {
      this.setState({ agencyName: response.data.agency });
      return response;
    });
  }
    componentDidUpdate(prevProps, prevState) {
        if ((prevProps.editAgencyUser !== this.props.editAgencyUser) && !this.props.loadingAgencyUser) {
        this.setState({
            user: {
            name: this.props.editAgencyUser.name,
            username: this.props.editAgencyUser.username,
            fax: this.props.editAgencyUser.contact ? this.clean(this.props.editAgencyUser.contact.fax) : "",
            phone1: this.props.editAgencyUser.contact ? this.props.editAgencyUser.contact.phone1 : this.props.editAgencyUser.phone1,
            phone2: this.props.editAgencyUser.contact ? this.props.editAgencyUser.contact.phone2 : "",
            email: this.props.editAgencyUser.email,
            password: '',
            confirm_password: '',
            agencyRoles: this.props.editAgencyUser.agencyRoles,
            is_active: this.props.editAgencyUser.is_active,
            }
        }, function () {
            this.setState({ editModalAgencyUser: true });
            });
        }
        if (this.state.is_active !== prevState.is_active) {
            this.pagination.dataCall();
            }
    }

  handleUpdateUser = userId => (e) => {
    this.props.updateUserStatusCall(userId, { is_active: e.target.checked ? true : false }).then(
      response => {
        this.pagination.dataCall();
        // this.props.usersPaginationData();
      }
    );
  }

  showPasswordModal = (original) => {
    this.props.showPasswordCall(original.id).then(response => {

      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <ShowPassword description={`Password for ${response.user.username} : `} user={response.user} onClosePro={onClose} />
          )
        }
      })
    })
  }

  render() {
    const { user, agencyName } = this.state;
    const { editAgencyUser, agencyRoles } = this.props;
    const columns = [
      {
        Header: "Agency Users Listing",
        headerClassName: 'headerPagination',
        columns: [
          {
            Header: "Name",
            accessor: "name",
            className: "action-center",
            // sortable: false,
            excExp: true
          },
          {
            Header: "Agency Name",
            accessor: "agency[0].name",
            Cell: ({ original }) => (
              <div>
                {hasPermission('agency-edit') ? <Link target="_blank" to={'/Admin/' + original.agency[0].id + '/agency-edit'} className="blue_link">{original.agency[0].name}</Link> : original.agency[0].name}
              </div>
            ),
            className: "action-center",
            sortable: false,
            excExp: true
          },
          {
            Header: "Brand",
            accessor: "agency[0].accounts[0].domain_slug",
            className: "action-center",
            sortable: false,
            excExp: true
          },
          {
            Header: "Program Type",
            accessor: "agency[0].program_type.programTypeWithPrice",
            className: "action-center",
            sortable: false,
            excExp: true
          },
          {
            Header: "Group Type",
            accessor: "agency[0].agency_type.title",
            className: "action-center",
            sortable: false,
            excExp: true
          },
          {
            Header: "Email",
            accessor: "email",
            className: "action-center",
            // sortable: false,
            excExp: true
          },
          {
            Header: "Roles",
            accessor: "role_csv_value",
            Cell: ({ original }) => (
              <div>
                {
                  original.roles.map((prop, key) => {
                    return (<span className="badge badge-success" key={key}>{prop.label}</span>)
                  })
                }
              </div>
            ),
            className: "action-center",
            sortable: false,
            excExp: true
          },
          {
            Header: "Status",
            accessor: "is_active",
            Cell: ({ row }) => (
              <label className="container-check">
                &nbsp;
                <input
                  type="checkbox" name="is_active" className="checkbox" value={row.is_active ? 1 : 0} onChange={this.handleUpdateUser(row.id)} checked={row.is_active ? 1 : 0}
                /><span className="checkmark"></span>
              </label>
            ),
            className: "action-center",
            sortable: false
          },
          {
            Header: "Actions",
            accessor: "id",
            Cell: ({ original, value }) => (
              <div>
                <OverlayTrigger placement="bottom" overlay={<Popover id="tooltip">{hasPermission('agency-user-edit') ? 'Edit Agency User' : adminLabels.DONT_ALLOW}</Popover>}>
                  <span>
                    {
                      hasPermission('agency-user-edit') &&
                      <button type='button' className="editIcon orderDetailsAction" onClick={() => this.openModalAgencyUserEdit(original)}>&nbsp;</button>
                    }
                    {
                      !hasPermission('agency-user-edit') &&
                      <button type='button' className="editIcon orderDetailsAction button_disabled" >&nbsp;</button>
                    }
                  </span>
                </OverlayTrigger>
                <OverlayTrigger placement="bottom" overlay={<Popover id="tooltip">{hasPermission('agency-user-delete') ? 'Delete Agency User' : adminLabels.DONT_ALLOW}</Popover>}>
                  <React.Fragment>
                    <span>
                      {
                        hasPermission('agency-user-delete') &&
                        <button type='button' className="deleteIcon orderDetailsAction " onClick={() => this.deleteConfirmAgencyUser(original)}>&nbsp;</button>
                      }
                    </span>
                    <span>
                      {
                        !hasPermission('agency-user-delete') &&
                        <button type='button' className="deleteIcon orderDetailsAction button_disabled" >&nbsp;</button>
                      }
                    </span>
                    <span>
                      {
                        hasRole(['admin']) &&
                        <button onClick={() => this.showPasswordModal(original)} className={'linkBtn'} ><AiOutlineEye /></button>
                      }
                    </span>
                  </React.Fragment>
                </OverlayTrigger>
              </div>
            ),
            className: "action-center",
            sortable: false
          }
        ]
      }
    ];
    const options = [
      {
        value: '',
        label: 'All'
      },
      {
        value: '1',
        label: 'Active'
      },
      {
        value: '0',
        label: 'In-Active'
      }
    ]
    return (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                ctTableResponsive
                content={
                  <div>
                    <Grid fluid>
                      <Row>
                        {/* <Col md={12}>
                          <div className='clearfix' >
                            <div className="secHeading pull-left">Agency Name : <strong>{agencyName}</strong></div>
                          </div>
                        </Col> */}
                        <Pagination
                            ref={(p) => this.pagination = p}
                            columns={columns}
                            getDataCall={this.paginationCall}
                            filterView={true}
                            filterPlaceHolder={'Agency User'}
                            pageSize={100}
                            downloadFileName={'AgencyAllUsers'}
                            downloadData={true}
                            showAllToggle={true}
                            upperContent={
                            <React.Fragment>
                                <Col md={12}>
                                    <div className='clearfix' >
                                        <div className="secHeading pull-left"><strong>All Agencies Users List</strong></div>
                                    </div>
                                </Col>
                                <Col md={3} sm={6} xs={12} className="col-md-offset-0">
                                  <div className="form-group select_height_adjust">
                                    <Select
                                      isSearchable={false}
                                      isMulti={false}
                                      styles={
                                        {
                                          container: (provided) => {
                                          return {
                                            ...provided,
                                            width: '100%',
                                            minWidth: '200px'
                                          }
                                          },
                                          menu: (provided) => {
                                            return {
                                              ...provided,
                                              width: '200px'
                                            }
                                          }
                                        }
                                      }
                                      value={
                                        options.map(item => {
                                          if (item.value === this.state.is_active) {
                                            return item
                                          }
                                        })
                                      }
                                      className='mt-md'
                                      options={options}
                                      onChange={this.selectChangeHandler}
                                      name='is_active'
                                    />
                                  </div>
                                </Col>
                            </React.Fragment>
                          }
                        //getRowProps = { this.getRowProps }
                        />
                      </Row>
                    </Grid>

                    {/*Edit AgencyUser Modal start*/}
                    {editAgencyUser && agencyRoles !== null &&
                      <Modal backdrop={'static'} show={this.state.editModalAgencyUser} onHide={closeModal.bind(this, "editModalAgencyUser")} aria-labelledby="ModalHeader" >
                        <Modal.Header closeButton>
                          <Modal.Title id='ModalHeader' className="headerTitle">Edit Agency User </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <div className="row">
                            <div className="col-md-12">
                              <Card bsClass={['innerCard mb-none']} content={
                                <AgencyUserForm
                                  closeModel={closeModal.bind(this, "editModalAgencyUser")}
                                  formTypePro="edit"
                                  userPro={user}
                                  handleAgencyUserSubmit={this.handleAgencyUserSubmit}
                                  componentPro="adminAgencyUser"
                                  agencyRoles={agencyRoles}
                                />
                              }
                              />
                            </div>
                          </div>
                        </Modal.Body>
                      </Modal>
                    }
                    {/*Edit AgencyUser Modal End*/}
                  </div>
                } />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  };
}

const mapDispatchToProps = dispatch => {
  return ({
    getAllAgenciesUsersCall: (page, filter, sort, pageSize, is_active) => { return dispatch(getAllAgenciesUsersPagination(page, filter, sort, pageSize, is_active)) },
    getAgencyUserCall: (agency_id, id) => { dispatch(getAgencyUser(agency_id, id)) },
    storeAgencyUserCall: (agencyId, userData) => { return dispatch(storeAgencyUser(agencyId, userData)) },
    editAgencyUserCall: (userData, id, agency_id) => { return dispatch(editAgencyUser(userData, id, agency_id)) },
    deleteAgencyUserCall: (agency_id, id) => { return dispatch(deleteAgencyUser(agency_id, id)) },
    getAllRolesCall: () => { dispatch(getAgencyRoles()) },
    updateUserStatusCall: (id, data) => { return dispatch(updateUserStatus(id, data)) },
    showPasswordCall: (id) => { return dispatch(showPassword(id)) }

  });
};

function mapStateToProps(state) {
  const { editAgencyUser, loadingAgencyUser } = state.agencyUserReducer;
  const { agencyRoles } = state.rolesReducer;
  return {
    loadingAgencyUser, editAgencyUser, agencyRoles
  };
}
const AllAgenciesUsers = connect(mapStateToProps, mapDispatchToProps)(AllAgenciesUsersListPage);
export default AllAgenciesUsers;