import React, { Component } from "react";
import { connect } from 'react-redux';
import { getInvLocations } from 'redux/actions/settings-actions';
import Select from 'react-select';
import { returnColumnValueWithColumnMatch } from 'helper/helperFunctions';

class ChangeInvLocPage extends Component {
	constructor(props) {
		super(props);
		// console.log('inventory_location_stats', this.props.inventory_location_stats_object);
		if (this.props.invLocations === undefined && !this.props.loadingInvLocations) {
			this.props.getInvLocationsCall();
		}
	}

	render() {
		const { inventory_location_stats_object, invLocations } = this.props;
		return (
			<React.Fragment>
				{
					invLocations !== undefined &&
					<Select
						value={
							inventory_location_stats_object ? {
								id: returnColumnValueWithColumnMatch(invLocations, 'id', [inventory_location_stats_object.inventory_location_id], 'id'),
								title: returnColumnValueWithColumnMatch(invLocations, 'title', [inventory_location_stats_object.inventory_location_id], 'id')
							} : null
						}
						options={invLocations}
						onChange={this.props.handleChangeLocation}
						getOptionLabel={(option) => option.title}
						getOptionValue={(option) => option.id}
						className="selectUser"
						placeholder="Select Location"
						isMulti={false}
					/>
				}
			</React.Fragment>
		);
	}
}

const mapDispatchToProps = dispatch => {
	return ({
		getInvLocationsCall: () => { dispatch(getInvLocations()) },
	});
};

function mapStateToProps(state) {
	const { loadingSettings, invLocations, loadingInvLocations } = state.settingsReducer;
	return {
		loadingSettings, invLocations, loadingInvLocations,
	};
}


const ChangeInvLoc = connect(mapStateToProps, mapDispatchToProps)(ChangeInvLocPage);
export default ChangeInvLoc;