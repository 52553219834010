import { adminConstants } from "../constant/admin-constant";

const initialState = {
    loadingItem : false,
    items : []
}

export const itemReducer = (state = initialState, action) => {
    switch (action.type) {

        case adminConstants.GET_ITEMS_REQUEST:
            return { ...state, loadingItem: true};
        case adminConstants.GET_ITEMS_SUCCESS:
            return { ...state, loadingItem:false, items:action.payload.items };
        case adminConstants.GET_ITEMS_FAILURE:
            return { ...state, loadingItem:false, error:action.payload };


        case adminConstants.GET_ITEM_LIST_REQUEST:
            return { ...state, loadingItem: true};
        case adminConstants.GET_ITEM_LIST_SUCCESS:
            console.log(action.payload)
            return { ...state, itemsPagination: action.payload.data.pagination.data, lastPageItem: action.payload.data.pagination.last_page, loadingItem: false, currentPageItem: action.payload.data.pagination.current_page };
        case adminConstants.GET_ITEM_LIST_FAILURE:
            return { ...state, loadingItem: false, error: action.payload };

        case adminConstants.STORE_ITEM_REQUEST:
            return { ...state, loadingItem: true};
        case adminConstants.STORE_ITEM_SUCCESS:
            return { ...state, item: action.payload.item, loadingItem: false };
        case adminConstants.STORE_ITEM_FAILURE:
            return { ...state, loadingItem: false, error: action.payload };

        case adminConstants.GET_ITEM_REQUEST:
            return { ...state, loadingItem: true};
        case adminConstants.GET_ITEM_SUCCESS:
            return { ...state, editItem: action.payload.editItem, loadingItem: false };
        case adminConstants.GET_ITEM_FAILURE:
            return { ...state, loadingItem: false, error: action.payload };

        case adminConstants.UPDATE_ITEM_REQUEST:
            return { ...state, loadingItem: true};
        case adminConstants.UPDATE_ITEM_SUCCESS:
            return { ...state, updatedItem: action.payload.updatedItem, loadingItem: false };
        case adminConstants.UPDATE_ITEM_FAILURE:
            return { ...state, loadingItem: false, error: action.payload };

        case adminConstants.DELETE_ITEM_REQUEST:
            return { ...state, loadingItem: true};
        case adminConstants.DELETE_ITEM_SUCCESS:
            return { ...state, deleteItem: action.payload.deleteItem, loadingItem: false };
        case adminConstants.DELETE_ITEM_FAILURE:
            return { ...state, loadingItem: false, error: action.payload };
        default:
            return state
    }
}
