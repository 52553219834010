import React, { Fragment, useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux';
import { alertActions } from '../../../redux/actions/alert-actions';
import { Modal, OverlayTrigger, Popover, Tooltip } from 'react-bootstrap';
import { ItineraryForm } from './ItineraryForm';
import ConfirmAlertForDelete from 'components/ThemeComponents/confirmAlertForDelete';
import { confirmAlert } from 'react-confirm-alert';
import { SortableContainer, SortableHandle, arrayMove, SortableElement } from 'react-sortable-hoc';
import moment from 'moment';
import { itineraryService } from '../../../services/itinerary';
import { withRouter } from 'react-router-dom';
import { flight, hotel, breakfast, edit, file, trash, drag } from "../../../assets/media/icons"
import "./itinerary.css"
import { dateFormatMy } from '../../../helper/helperFunctions';
import User from "helper/User";
import FileUpload from '../../Upload/FileUpload';
import ImagePreview from '../../Upload/ImagePreview';

// const format = 'HH:mm';
const format = 'h:mm a';
const now = moment().hour(0).minute(0);

const itinerary_obj = {
    destination_name: '',
    country: '',
    start_date: '',
    start_time: null,
    start_time_string: null,
    end_date: '',
    end_time: null,
    end_time_string: null,
    description: '',
    is_visited: false,
    google_place_id: '',
    lat: '',
    long: '',
    photos: [],
};

const DragHandle = SortableHandle(() => <OverlayTrigger placement="bottom"
    overlay={<Popover id="tooltip">Click and Hold to
        Drag</Popover>}><span
            className="moveIcon orderDetailsAction "
        ><img src={drag} width={20} /></span></OverlayTrigger>);

const SortableItem = SortableElement(({ day, setCurrentDay, current_day, setAddModal, itineraries }) => {
    return (
        <div className={`w-100 ${Number(day) <= Number(current_day) && 'currentDay'} `}>
            <div className='w-100 flexElem' style={{ flexDirection: 'column' }}>
                <div className='mb-1 h6' style={{ display: 'inline-flex', flexDirection: 'column', alignItems: 'start', justifyContent: 'center' }}>
                    <DragHandle />
                    <small className='font-weight-bold text-dark'>Day {day}</small>
                </div>
                <a className="daysBullet" key={day} onClick={(e) => {
                    e.preventDefault();
                    setCurrentDay(Number(day));
                    setAddModal(false);
                }}
                >
                </a>
            </div>
        </div>
    )
}
);

const DaysList = SortableContainer(({ daysArr, onSortEnd, setCurrentDay, current_day, setAddModal, itineraries }) => {
    return (
        <div className='flexElem w-100'>
            {
                daysArr.map((day, i) => {
                    return (
                        <SortableItem
                            index={i}
                            key={i}
                            item_index={i}
                            day={day}
                            onSortEnd={onSortEnd}
                            setCurrentDay={setCurrentDay}
                            current_day={current_day}
                            setAddModal={setAddModal}
                            itineraries={itineraries}
                            disabled={itineraries[day] && Object.values(itineraries[day]).length > 0 ? false : true}
                        />
                    );
                })
            }
        </div>
    )
});

const AddItinerary = (props) => {
    const [addModal, setAddModal] = useState(false);
    const [editing, setEditing] = useState(false);
    const [view_mode, setViewMode] = useState(false);
    const [add_day_toggle, setAddDayToggle] = useState(false);
    const [current_day, setCurrentDay] = useState(1);
    const [selected_itinerary, setItinerary] = useState({ ...itinerary_obj });
    const [order_id, setOrderId] = useState('');
    const [brand_id, setBrandId] = useState('');
    const [agency_id, setAgencyId] = useState('');
    const [brand_name, setBrandName] = useState('');
    const [orderBarcode, setOrderBarcode] = useState('');
    const [changeImageModal, setChangeImageModal] = useState(false);
    const [itin_image, setItinImage] = useState(null);
    const [image_id, setImageId] = useState(null);

    const [steps, setSteps] = useState({});
    const [days_arr, setDaysArr] = useState([]);

    function mapPlacesData(data) {
        const dataObj = { ...data };
        for (let key in dataObj) {
            dataObj[key].forEach(place => {
                let start_time = moment(place['start_time']);
                let end_time = moment(place['end_time']);

                place['start_time_string'] = start_time.format(format);
                place['end_time_string'] = end_time.format(format);

                place['start_time'] = moment(start_time.format(format), format);
                place['end_time'] = moment(end_time.format(format), format);
            })
        }
        setDaysArr(Object.keys(dataObj));
        return dataObj;
    }

    async function getItineraries(brandId, agencyId, orderId) {
        try {
            let ord_id = orderId ? orderId : '';
            const res = await itineraryService.getItineraries(brandId, agencyId, ord_id);
            const places_data = mapPlacesData(res.data.itineraries);
            setSteps(places_data);
            return res.data;
        } catch (e) {
            console.log('Error in listing', e);
        }
    }

    useEffect(() => {
        async function callGetItines() {
            try {
                let brandId = '';
                let orderId = '';
                let agencyId = '';
                if (window.location.href.indexOf("manage-itineraries") > -1) {
                    agencyId = User.agency('id');
                }

                if (!props.in_order) {
                    orderId = props.match.params.order_id || '';
                    brandId = props.match.params.brand_id || User.brand('id');
                    agencyId = User.agency('id') || '';
                } else {
                    orderId = props.order_id || '';
                    brandId = props.brand_id || '';
                    agencyId = props.agency_id || '';
                }
                setOrderId(orderId);
                setBrandId(brandId);
                setAgencyId(agencyId);
                const response = await getItineraries(brandId, agencyId, orderId);
                let brandName = 'Brand';
                let orderBarcode = '';
                brandName = response.brand ? response.brand.title : 'Brand';
                orderBarcode = response.order ? response.order.barcode : '';
                setBrandName(brandName)
                setOrderBarcode(orderBarcode)
            } catch (e) {
                console.log("🚀 ~ file: AddItinerary.jsx:109 ~ callGetItines ~ e:", e)
            }
        };
        callGetItines();
    }, [])

    async function onFormSubmit(place, edit = false) {
        try {
            const format = 'HH:mm';
            const data = {
                ...place,
                start_time: place.start_time.toDate(),
                start_time_string: place.start_time.format(format),
                end_time: place.end_time.toDate(),
                end_time_string: place.end_time.format(format),
                day: current_day,
                brand_id: brand_id,
                order_id: order_id ? order_id : '',
                agency_id: agency_id ? agency_id : ''
            }
            let place_added;
            if (!edit) {
                place_added = await itineraryService.storeItinerary(data);
                setAddDayToggle(false);
            } else {
                place_added = await itineraryService.editItinerary(data, data.id);
            }
            const places_data = mapPlacesData(place_added.data);
            setSteps(places_data)
            setAddModal(!addModal)
            props.successAlert(place_added.message);
        } catch (e) {
            props.errorAlert(e);
            console.log("🚀 ~ file: AddItinerary.jsx:66 ~ onFormSubmit ~ e", e)
        }
    }

    function addDay(steps) {
        const t_days = Object.keys(steps);
        setDaysArr([...days_arr, t_days.length + 1 + '']);
        const temp_steps = { ...steps };
        temp_steps[t_days.length + 1] = [];
        setCurrentDay(t_days.length + 1);
        setSteps(temp_steps);
        setAddDayToggle(true);
        setAddModal(false);
    }

    async function removeDay(curr_day) {
        try {
            const data = { day: curr_day, brand_id, order_id, agency_id }
            setAddModal(false);
            if (steps[curr_day].length < 1) {
                delete steps[curr_day];
                if (current_day == 1 || current_day == 2) {
                    setCurrentDay(1);
                } else {
                    setCurrentDay(current_day - 1);
                }
                setAddDayToggle(false);
                let days = [...Object.keys(steps)];
                setDaysArr(days);
                return;
            }
            const res = await itineraryService.deleteItineraryDay(data);
            if (res.data.length < 1) {
                setSteps({});
                setCurrentDay(1);
                setDaysArr([]);
                setAddDayToggle(false);
                return;
            }
            const places_data = mapPlacesData(res.data);
            if (!Object.keys(places_data).includes(current_day)) {
                if (current_day == 1 || current_day == 2) {
                    setCurrentDay(1);
                } else {
                    setCurrentDay(current_day - 1);
                }
                setAddDayToggle(false);
            }
            props.successAlert(res.message);
            let days = [...Object.keys(places_data)];
            setDaysArr(days);
            setSteps(places_data);
        } catch (error) {
            props.errorAlert(error);
        }
        return;
    }

    function removeDayConfirmation(day) {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <ConfirmAlertForDelete typeName="Day" description="Are you sure to remove this day?" onClosePro={onClose} deleteType={() => { removeDay(day) }} />
                )
            }
        })
    }

    function removePlaceConfirmation(id) {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <ConfirmAlertForDelete typeName="Place" description="Are you sure to remove this place?" onClosePro={onClose} deleteType={() => { removePlace(id) }} />
                )
            }
        })
    }

    function imageStatusConfirmation(imgData) {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <ConfirmAlertForDelete typeName="Itinerary Image" description={`Are you sure you want to ${imgData.is_active == 0 ? 'Activate' : 'In-Activate'} this Itinerary Image?`} onClosePro={onClose} deleteType={() => { itineraryImageStatus(imgData); setImageId(imgData.id) }} />
                )
            }
        })
    }

    function editImageConfirmation(imgData) {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <ConfirmAlertForDelete typeName="Itinerary Image" description="Are you sure to change this Itinerary Image?" onClosePro={onClose} deleteType={() => { setChangeImageModal(true); setImageId(imgData.id) }} />
                )
            }
        })
    }

    async function removePlace(id) {
        try {
            setAddModal(false);
            let last_item = false;
            if (steps[current_day].length === 1) {
                last_item = true;
            }
            const data = { id, last_item, agency_id, brand_id, order_id }
            const itineraries = await itineraryService.deleteItinerary(data);
            if (itineraries.data.length < 1) {
                setSteps({});
                setCurrentDay(1);
                setDaysArr([]);
                setAddDayToggle(false);
                return;
            }
            const places_data = mapPlacesData(itineraries.data);
            if (!Object.keys(itineraries.data).includes('' + current_day)) {
                setCurrentDay(current_day - 1);
            }
            setAddDayToggle(false);
            setDaysArr([...Object.keys(places_data)]);
            setSteps(places_data);
        } catch (e) {
            props.errorAlert(e);
        }
    }
    // render the progress bar
    const icons = [
        flight, hotel, breakfast
    ]

    function renderDate(place, day) {
        if ((place && place.order && place.order.order_dates)) {
            let date = new Date(place.order.order_dates[0].value);
            if (day > 1) {
                date.setDate(date.getDate() + (day - 1));
            }
            // return date.toDateString();
            return dateFormatMy(date);
        }
        return '';
    }

    let x = Object.keys(steps).length > 0 ? (100 / Object.keys(steps).length) * current_day : 0;

    const executeScroll = () => {
        // myRef.current.scrollIntoView();
        const section = document.getElementById('itineraryForm');
        setTimeout(() => {

            section.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }, 300);
    }

    const onSortEnd = async ({ oldIndex, newIndex }) => {
        if (oldIndex == newIndex) return;
        const oldDay = days_arr[oldIndex];
        const newDay = days_arr[newIndex];

        // need to replace the day numbers in the list
        const tempSteps = { ...steps };
        if (Object.values(tempSteps[oldDay]).length < 1 || Object.values(tempSteps[newDay]).length < 1) {
            return;
        }
        tempSteps[oldDay].forEach(itin => {
            itin.day = newDay;
        })

        tempSteps[newDay].forEach(itin => {
            itin.day = oldDay;
        })
        const tempItins = [...Object.values(tempSteps).flat(1)];
        const tempObj = {};
        tempItins.forEach(itin => {
            if (!(itin.day in tempObj)) {
                tempObj[itin.day] = [];
            }
            itin.agency_id = agency_id;
            tempObj[itin.day].push(itin);
        })

        try {
            const res = await itineraryService.swapItineraryDays({ itineraries: tempItins });
            props.successAlert(res.message);
        } catch (error) {
            props.errorAlert(error);
        }

        setTimeout(() => {
            setCurrentDay(newDay);
            setSteps({ ...tempObj });
            setDaysArr(Object.keys(tempObj));
        }, 100);
    };

    var urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
    const linkify = (text) => {
        if (text)
            return text.replace(urlRegex, function (url) {
                return '<a class="itineraryDescriptionLink" href="' + url + '"  target="_blank">' + url + '</a>';
            });
        return ''
    }

    const itineraryImageStatus = async (imgData) => {
        const data = {
            is_active : imgData.is_active == 1 ? 0 : 1,
            itinerary_id: imgData.itinerary_id,
            image_id: imgData.id,
            order_id: order_id,
            agency_id: agency_id,
            brand_id: brand_id
        }
        try {
            const res = await itineraryService.itineraryImageStatus(data);
            props.successAlert(res.message);
            const places_data = mapPlacesData(res.data);
            setSteps(places_data);
        } catch (error) {
            props.errorAlert(error);
        }
    }

    function setResourceFile (key, value) {
        setItinImage(value);
    }

    function removeFile (key, value) {
        setItinImage(null);
    }

    const replaceItineraryImage = async () => {
        const fd = new FormData();
        fd.append('agency_id', agency_id);
        fd.append('brand_id', brand_id);
        fd.append('order_id', order_id);
        fd.append('itin_image', itin_image);
        fd.append('image_id', image_id);
      
        try {
            const res = await itineraryService.replaceItineraryImage(fd);
            if (res) {
                props.successAlert(res.message);
                setItinImage(null);
                setChangeImageModal(false);
                const places_data = mapPlacesData(res.data);
                setSteps(places_data);
            }
        } catch (error) {
            props.errorAlert(error);
        }
    }

    return (
        <div className='container-fluid p-lg'>
            <div id="heading" style={{ padding: '30px' }}>
                <div style={{ fontSize: '2em', fontWeight: '300px', display: 'flex', justifyContent: 'center' }}>
                    {
                        order_id ?
                            <div>
                                Order <span style={{ color: '#1684E5' }}>{orderBarcode}</span> Itinerary
                            </div>
                            :
                            agency_id ?
                                `${User.agency('name')} (TO) Itinerary`
                                :
                                `${brand_name} Itinerary`
                    }
                </div>
            </div>
            <div className='flexCenter'>
                <div className='daysSelection'>
                    <div className='progressBar' style={{ width: `${x}%` }} />
                    <DaysList
                        // helperClass='moving'
                        transitionDuration={400}
                        lockToContainerEdges={true}
                        axis='xy'
                        daysArr={days_arr}
                        itineraries={{ ...steps }}
                        useDragHandle
                        onSortEnd={onSortEnd}
                        current_day={current_day}
                        setCurrentDay={setCurrentDay}
                        setAddModal={setAddModal}
                    />
                </div>
                <button className='addDayBtn' onClick={() => { addDay(steps) }} disabled={add_day_toggle}>Add Day</button>
            </div>
            <section className='daysWrapper'>
                <div className='cardInformation'>
                    {
                        Object.keys(steps).length > 0 &&
                        <div className='daysCounterWrap'>
                            <div className='daysCount'>
                                {current_day}
                            </div>
                            <div className='dates'>
                                <span className='font-weight-bold' style={{ whiteSpace: 'nowrap' }}>
                                    {renderDate(steps[current_day][0], current_day)}
                                </span>
                                {
                                    Object.keys(steps).length > 0 &&
                                    <span className="deleteDay" onClick={() => { removeDayConfirmation(current_day) }}
                                    // disabled={(steps[current_day] && steps[current_day].length < 0)}
                                    >
                                        <img src={trash} height={24} />
                                    </span>
                                }
                            </div>
                        </div>
                    }
                    <div className='cardTimeline'>
                        {
                            steps[current_day] ? steps[current_day].map((place, idx) => {
                                return (
                                    <div className='flexCenter flexWrap' key={idx}>
                                        <div className='cardTimelineItem' key={idx + Math.random()}>
                                            <img src={place.icon_url ? `${place.icon_url}.svg` : icons[1]} height={20} className="mt-sm" />
                                            <div>
                                                <h5 className='font-weight-bold mt-sm'>{place.destination_name}</h5>
                                                <p>{place.start_time_string} &nbsp; ---- &nbsp; {place.end_time_string}</p>
                                                <p dangerouslySetInnerHTML={{ __html: linkify(place.description) }} />
                                            </div>
                                        </div>
                                        <div className='msAuto cardActions'>
                                            <div className='places'>
                                                {
                                                    place.photos.map((img, index) => {
                                                        return <React.Fragment key={index}>
                                                            <div className={`placeOverlayWrap`}>
                                                                <div className='placeOverlay'>
                                                                    <OverlayTrigger placement="top" overlay={
                                                                        <Tooltip id="tooltip">{'Change Image'} </Tooltip>
                                                                    }>
                                                                        <span className="itin_edit_icon" onClick={(e) => { editImageConfirmation(img) }}>
                                                                            <img src={edit} className='filter-white'/>
                                                                        </span>
                                                                    </OverlayTrigger>
                                                                </div>
                                                                <img src={img.thumb_path} className="placeAvatar" />
                                                                <div
                                                                    className={"approvalChk hover_dark gray_gradient"}>
                                                                    <div className="customChk">
                                                                        <button onClick={(e) => { imageStatusConfirmation(img) }} 
                                                                                className={img.is_active ? "checkedBtn checkBtn text_12" : "checkBtn text_12"}
                                                                                htmlFor={'image_' + img.id}>{img.is_active ? 'In-Activate' : 'Activate'}
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </React.Fragment>
                                                    })
                                                }
                                            </div>
                                            <div className='actionIcons'>
                                                <OverlayTrigger placement="bottom" overlay={<Popover id="tooltip">Delete Place</Popover>}>
                                                    <span className="action-button-size" onClick={(e) => { e.preventDefault(); removePlaceConfirmation(place.id) }}>
                                                        <img src={trash} />
                                                    </span>
                                                </OverlayTrigger>
                                                <OverlayTrigger placement="bottom" overlay={<Popover id="tooltip">View Place</Popover>}>
                                                    <span className="action-button-size" onClick={(e) => { e.preventDefault(); setItinerary(place); setAddModal(true); setEditing(false); setViewMode(true); executeScroll() }}>
                                                        <img src={file} />
                                                    </span>
                                                </OverlayTrigger>
                                                <OverlayTrigger placement="bottom" overlay={<Popover id="tooltip">Edit Place</Popover>}>
                                                    <span className="action-button-size" onClick={(e) => { e.preventDefault(); setItinerary(place); setAddModal(true); setEditing(true); setViewMode(false); executeScroll() }}>
                                                        <img src={edit} />
                                                    </span>
                                                </OverlayTrigger>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                                :
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    No Itineraries Found!
                                </div>
                        }
                    </div>
                </div>
                {
                    Object.keys(steps).length > 0 &&
                    <button className='addPlaceBtn mt-lg' onClick={() => { setEditing(false); setViewMode(false); setAddModal(true); setItinerary({ ...itinerary_obj }); setEditing(false); executeScroll() }}>Add Place</button>
                }
            </section>
            <section className='formWrapper' id="itineraryForm">
                {
                    addModal &&
                    <div>
                        <ItineraryForm
                            closeModal={() => { setAddModal(!addModal); setEditing(false) }}
                            selected_itinerary={selected_itinerary}
                            current_day={current_day}
                            editing={editing}
                            view_mode={view_mode}
                            return_submitted_data={onFormSubmit}
                        />
                    </div>
                }
            </section>
            <Modal show={changeImageModal}
                onHide={() => { setChangeImageModal(!changeImageModal); setItinImage(null) }}
                aria-labelledby="ModalHeader"
                backdrop={false}>
                <Modal.Header closeButton>
                    <Modal.Title id='ModalHeader' className="headerTitle">
                        Change Itinerary Image
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="text-center">
                        <div className="col-md-12">
                            {
                                !itin_image ?
                                    <div className='col-md-6 form-group dropzone_profileImage'>
                                        <FileUpload
                                            files={itin_image}
                                            setResourceFile={setResourceFile}
                                            allowedFormatsStringMimePro={'image/jpeg, image/png'}
                                            allowedFormatsStringExtPro={'jpg, png'}
                                            multiple={false}
                                        />
                                    </div>
                                :
                                    <div className="text-center">
                                        <ImagePreview 
                                            filesToPreview={itin_image} 
                                            removeFile={removeFile}
                                            resetImage={true}
                                        />
                                    </div>
                            }
                        </div>
                        <button onClick={replaceItineraryImage} className="backButton pt-sm no_radius pb-sm primary btn btn-md btn-info ml-sm mt-sm"> Update</button>
                        <button onClick={() => { setChangeImageModal(!changeImageModal); setItinImage(null) }} className="backButton pt-sm no_radius pb-sm primary btn btn-md btn-info ml-sm mt-sm"> Close</button>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

const mapDispatchToProps = dispatch => {
    return ({
        errorAlert: (e) => {
            dispatch(alertActions.error(e))
        },
        successAlert: (msg) => {
            dispatch(alertActions.success(msg))
        },
    });
};

export default withRouter(connect(null, mapDispatchToProps)(AddItinerary));
