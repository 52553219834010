import React from 'react';
import AgencyForm from './AgencyForm';
import { getAgency } from '../../../redux/actions/agency-actions';
// import { settingsActions } from '../../../redux/actions/settings-actions';
import { connect } from 'react-redux';
import {
	Grid,
	Row,
	Col
} from "react-bootstrap";
import { Card } from "components/Card/Card.jsx";
import { hasPermission } from 'helper/hasPermission';
import { objectsConstants as OC } from 'redux/constant/objects-constant';
import { intersectionOfObjectsWithNewValue } from '../../../helper/helperFunctions';

class EditAgencyPage extends React.Component {
	constructor(props) {
		super(props);
		const { id } = this.props.match.params;
		this.state = {
			agency: this.props.editAgency,
			agency_id: id,
			formType: 'edit',
			selected: {}
		};
		this.props.getAgencyCall(id);

	};
	render() {
		const { agency_id } = this.state;
		const { editAgency } = this.props;
		return (
			<div className="content">
				<Grid fluid>
					<Row>
						<Col md={12}>
							<Card
								title={hasPermission('agency-edit') ? "Edit Agency" : "View Agency"}
								content={
									<div>
										<Grid fluid>
											<Row>
												<Col md={12}>
													{
														editAgency.id &&
														<AgencyForm
															agencyData={intersectionOfObjectsWithNewValue(OC.AGENCY, editAgency)}
															formType={this.state.formType}
															agency_id={agency_id}
                                                            primary_address={editAgency.primary_address}
                                                            secondary_address={editAgency.secondary_address}
														/>
													}
												</Col>
											</Row>
										</Grid>
									</div>
								}
							/>
						</Col>
					</Row>
				</Grid>
			</div>
		);
	};
}

const mapDispatchToProps = dispatch => {
	return ({
		getAgencyCall: (id) => { dispatch(getAgency(id)) },
	});
};

function mapStateToProps(state) {
	const { editAgency } = state.agenciesReducer;
	return {
		editAgency,
	};
}

const EditAgency = connect(mapStateToProps, mapDispatchToProps)(EditAgencyPage);
export default EditAgency;
