import { createStore, applyMiddleware } from "redux";
import thunkMiddleware from 'redux-thunk';
//import { createLogger } from 'redux-logger';
import rootReducer from "../reducers/index";
import  { int }  from '../../helper/interceptor';
import { history } from '../../helper/history';

//const loggerMiddleware = createLogger();

const store = createStore(
		rootReducer, 
		applyMiddleware(
        	thunkMiddleware
    	)
    );
int.setupInterceptors(store, history);
export default store;