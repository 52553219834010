import React, { Component } from 'react'
import { getAgencyLogo } from 'redux/actions/agency-actions';
import { connect } from 'react-redux'
import User from 'helper/User';
import { s3StaticPath } from 'helper/helperFunctions';

class AgencyLogo extends Component {
    constructor(props) {
        super(props);
        this.props.getAgencyLogo(User.agency('id'));
    }
    render() {
        return (
            <React.Fragment>
                {
                    this.props.agencyLogo ?
                        <img className={this.props.className} src={this.props.agencyLogo.file_path} style={{maxHeight: this.props.fixHeight ? this.props.fixHeight : ''}} alt="Company Name" />
                        :
                        <img className={this.props.className} src={s3StaticPath('img/placeholder.png')} style={{maxHeight: this.props.fixHeight ? this.props.fixHeight : ''}} alt="Company Name" />
                }
            </React.Fragment>
        )
    }
}
const mapStateToProps = (state) => {
    const { agencyLogo } = state.frontEndUserReducer;
    return {
        agencyLogo
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getAgencyLogo: (agencyId) => { dispatch(getAgencyLogo(agencyId)) }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AgencyLogo);
