import React, { Component } from "react";
import { connect } from 'react-redux';
import { getCustomerServicesOrders } from "redux/actions/order-actions";
import { Row, Col, Grid } from "react-bootstrap";
import { Card } from "components/Card/Card.jsx";
import { Link } from 'react-router-dom';
import { helperFunctions as HF } from 'helper/helperFunctions';
import Pagination from 'components/ThemeComponents/Pagination';
var moment = require("moment");
// const moment = window.moment;

class CustomerServicesPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
		}
	}

  	getCsOrdersData = (data) =>{
    	return this.props.getCsOrdersCall({...data });
 	}

	render() {
		const columns = [
	      	{
		        Header: "Completed Orders Listing",
		        headerClassName: 'headerPagination',
		        columns: [
		        	{
			            Header: "Barcode",
						accessor: "barcode",
						width: 200,
                        Cell: ({ value, original }) => (
                                <Link to={'/Admin/' + original.id + '/order-edit'} className="blue_link barcode">{value}</Link>
                        ),
			            className: "action-center"
		          	},
		          	{
			            Header: "Days difference from departure to today",
			            accessor: "depDatDiff",
			            className: "action-center",
			            sortable:false,
			        },
			        {
			            Header: "Days difference from creation to departure",
			            accessor: "depDatDiff",
			            className: "action-center",
			            Cell: ({ value, original }) => (
                                moment(original.order_date_single.value).diff(original.created_at, 'days')
                        ),
			            sortable:false,
			        },
			        {
			            Header: "Step to be done",
			            accessor: "step",
						Cell: ({ value }) => (
						  <div>
							{ value.title }
						  </div>
						),
			            className: "action-center",
			            sortable:false,
			        },
			        {
			            Header: "Departure Date",
			            accessor: "order_date_single",
						Cell: ({ value }) => (
						  <div>
							{ HF.dateFormatMy(value.value) }


						  </div>
						),
			            className: "action-center",
			            sortable:false,
			        }
		        ]
	      	}
    	];
		return(
			<div className="content">
        		<Grid fluid>
          			<Row>
            			<Col md={12}>
              				<Card
                				ctTableResponsive
                				content={
              					<div>
                					<Grid fluid>
                  						<div className="row">

							                <Col md={12} className="mt-md">
					                        	<Pagination
				                                    ref={(p) => this.pagination = p}
				                                    columns = { columns }
				                                    getDataCall= { this.getCsOrdersData }
				                                    filterView={false}

				                                  />
					                        </Col>
                  						</div>
                					</Grid>
              					</div>
                			} />
            			</Col>
          			</Row>
        		</Grid>
        	</div>
		);
	}
}

const mapDispatchToProps = dispatch => {
	return ({
		getCsOrdersCall: (data) => { return dispatch(getCustomerServicesOrders(data)) },//example function
	});
};

function mapStateToProps(state) {
	const { csOrderData, OWSpages, OWScurrent_page, OWStotal_orders, loadingOrder, ordersCountWithModerator } = state.ordersReducer;
	return {
		csOrderData, OWSpages, OWScurrent_page, OWStotal_orders, loadingOrder, ordersCountWithModerator
	};
}


const CustomerServices = connect(mapStateToProps, mapDispatchToProps)(CustomerServicesPage);
export default CustomerServices;
