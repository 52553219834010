import React, { Component } from 'react'
import { SC } from 'helper/ServerCall';
import ToggleButton from 'react-toggle-button';
import { themeCSSConstants } from 'redux/constant/themeCSS';
import { objectsConstants as OC } from 'redux/constant/objects-constant';

export default class MoreButtonsWeTravel extends Component {


    constructor(props) {
        super(props);
        this.state = {
            weTravelButton: OC.MOREBUTTONS,
            oldState: false
        }
    }

    getAgencyWeTravelButton() {
        return SC.getCall('getAgencyWeTravelButton/' + this.props.agency_id);
    }
    componentDidMount() {
        let response = this.getAgencyWeTravelButton().then((response) => {
            // console.log(response, 'response#21');
            this.setState({
                weTravelButton: response.data.weTravel
            })
        }
        )

    }


    updateMoreButton() {
        // if(this.state.weTravelButton.link !== this.state.oldState.link){
        return SC.postCall('updateMoreButton/' + this.state.weTravelButton.id, this.state.weTravelButton);
        //   }
    }

    handleOptions = (name, value) => {
        // console.log(name, value, 'value#36');
        let tempValue = !+value;
        tempValue = tempValue ? 1 : 0;
        // console.log(name, tempValue, 'value#39');

        // console.log(this.state.weTravelButton, 'this.state.weTravelButton#42');
        this.setState(prevState => ({
            weTravelButton: {
                ...prevState.weTravelButton,
                [name]: tempValue
            }
        })
            ,
            () => {
                if (name === 'show_on_all_orders' || name === 'option_at_order_placement') {
                    var WtOrder = 'show_on_all_orders';
                    if (name === WtOrder)
                        WtOrder = 'option_at_order_placement';
                    this.setState(prevState => ({
                        weTravelButton: {
                            ...prevState.weTravelButton,
                            [WtOrder]: 0
                        }
                    }), () => {
                        this.updateMoreButton();
                    });
                }
                else if (name === 'is_active' && tempValue == 0) {
                    this.setState(prevState => ({
                        weTravelButton: {
                            ...prevState.weTravelButton,
                            ['show_on_all_orders']: 0,
                            ['option_at_order_placement']: 0
                        }
                    }), () => {
                        this.updateMoreButton();
                    });
                }
                else {
                    this.setState(prevState => ({
                        weTravelButton: {
                            ...prevState.weTravelButton,
                            ['show_on_all_orders']: 1,
                            ['option_at_order_placement']: 0
                        }
                    }), () => {
                        this.updateMoreButton();
                    });
                    // this.updateMoreButton();
                }
            });

        // console.log(this.state.weTravelButton, 'this.state.weTravelButton#74');
        ;
    }



    handleAgencyLink = (name, value) => {
        this.setState(prevState => ({
            oldState: prevState,
            weTravelButton: {
                ...prevState.weTravelButton,
                [name]: value
            }
        }))
    }

    render() {
        const { weTravelButton } = this.state;

        // console.log(weTravelButton, '+weTravelButton.is_active#83')
        return (
            <div>
                <div className="clearfix">
                    <div className="row">
                        <div className="col-md-3 mt-md">
                            <label className="container-check flexElem alignCenter" style={{ paddingLeft: '5px' }}>
                                <ToggleButton
                                    inactiveLabel={''}
                                    activeLabel={''}
                                    colors={themeCSSConstants.THUMBICONSTYLE}
                                    trackStyle={{ height: '10px', width: '30px' }}
                                    thumbStyle={themeCSSConstants.THUMBSTYLE}
                                    thumbAnimateRange={[0, 15]}
                                    value={(weTravelButton) ? weTravelButton.is_active : 0}
                                    onToggle={() => this.handleOptions("is_active", (weTravelButton) ? +weTravelButton.is_active : 0)} />
                                <div style={{ marginLeft: '-10px' }}>{OC.WETRAVELTITLE}</div>
                            </label>
                        </div>
                        <div className="col-md-9">
                            {Number(weTravelButton && +weTravelButton.is_active) ?
                                <div className="row">
                                    {/* <div className="col-md-4">
                                        <input type="text" placeholder="Button Content" onFocus={() => this.setState(prevState => ({oldState: prevState.weTravelButton}))} onKeyDown={(e) => e.which == 13 && e.preventDefault()} onKeyPress={(e) => e.which == 13 && e.preventDefault()}
                                        onKeyUp={(e) => e.which == 13 && (e.preventDefault(),
                                        this.updateMoreButton())}
                                        value={weTravelButton.link} className="form-control"
                                        onBlur={(e) => this.updateMoreButton()}
                                        onChange={(e) => this.handleAgencyLink('link', e.target.value)} />

                                    </div> */}
                                    <div className="col-md-12 pt-none flexElem alignCenter">
                                        <div className="mt-md">
                                            <label className="container-check flexElem alignCenter" style={{ paddingLeft: '5px' }}>
                                                <ToggleButton
                                                    inactiveLabel={''}
                                                    activeLabel={''}
                                                    colors={themeCSSConstants.THUMBICONSTYLE}
                                                    trackStyle={{ height: '10px', width: '30px' }}
                                                    thumbStyle={themeCSSConstants.THUMBSTYLE}
                                                    thumbAnimateRange={[0, 15]}
                                                    value={(weTravelButton) ? weTravelButton.show_on_all_orders : 0}
                                                    onToggle={() => this.handleOptions("show_on_all_orders", (weTravelButton) ? +weTravelButton.show_on_all_orders : 0)} />
                                                <div style={{ marginLeft: '-10px' }}>Show on all orders</div>
                                            </label>
                                        </div>

                                        <div className="mt-md ml-md">
                                            <label className="container-check flexElem alignCenter" style={{ paddingLeft: '5px' }}>
                                                <ToggleButton
                                                    inactiveLabel={''}
                                                    activeLabel={''}
                                                    colors={themeCSSConstants.THUMBICONSTYLE}
                                                    trackStyle={{ height: '10px', width: '30px' }}
                                                    thumbStyle={themeCSSConstants.THUMBSTYLE}
                                                    thumbAnimateRange={[0, 15]}
                                                    value={(weTravelButton) ? weTravelButton.option_at_order_placement : 0}
                                                    onToggle={() => this.handleOptions("option_at_order_placement", (weTravelButton) ? +weTravelButton.option_at_order_placement : 0)}
                                                />
                                                <div style={{ marginLeft: '-10px' }}>Option at order placement</div>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                : ""}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
