import React, { Component } from "react";
import Select from 'react-select';
import { Card } from 'components/Card/Card.jsx';
import { returnLabelWithValue } from 'helper/helperFunctions';
class AgencyAdvanceSearchForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inActive: false,
        };
        if (this.props.setStateOfParent) {
            this.props.setStateOfParent('1');
        }
    }
	enterKey = (event) => {
		if (event.keyCode === 13) {
			this.props.performSearch();
		}
	}

    handleAgencyInactiveCheckbox = (e) => {
        const value = !this.state.inActive;
        console.log("value", value);
        if (value) {
            this.setState({ inActive: value})
            if (this.props.setStateOfParent) {
                this.props.setStateOfParent('0');
            }
        } else {
            this.setState({ inActive: value})
            if (this.props.setStateOfParent) {
                this.props.setStateOfParent('1');
            }
        }
    }

	render() {
		const { isOpen, advanceSearch, options } = this.props;
		const { inActive } = this.state;
		return (
			<Card bsClass={["innerCard relativeCard"]} collapsHandler={() => this.props.agencySearchCollapseHandle('isOpenAgencyAdvanceSearch')} collapseTrigger={isOpen} collapsable={true} title="Additional Search" content={
				<React.Fragment>
					<div className="clearfix mt-md fullCalendar">
						<div className="col-md-3">
							<div className='form-group'>
								{/* <label htmlFor="gname">Agency Name</label>
								<input onKeyDown={(e) => this.enterKey(e)} type="email" name="agency_name" className="form-control" onChange={this.props.handleAdvanceSearch} value={advanceSearch.agency_name} /> */}
                                <label htmlFor="glemail">Search Agency</label>
                                    <Select
                                        value={returnLabelWithValue(advanceSearch.agency_name, options.agencies)}
                                        onChange={this.props.handleChangeAdvanceSearchDropDown}
                                        options={options.agencies}
                                        isSearchable={true}
                                        isMulti={false}
                                        name='agency_name'
                                        placeholder={'Search Agency'}
                                        isClearable={true}
                                    />
                            </div>
							<div className='form-group'>
								<label htmlFor="gname">Agency website</label>
								<input onKeyDown={(e) => this.enterKey(e)} type="text" name="agency_website" className="form-control" onChange={this.props.handleAdvanceSearch} value={advanceSearch.agency_website} />
							</div>
                            <div className="form-group">
								<label htmlFor="glemail">Select Brand</label>
								<Select
									value={advanceSearch.account_id.length > 0 ? advanceSearch.account_id.map(item => item) : ''}
									onChange={this.props.handleChangeBrand}
									options={options.brands}
									isSearchable={true}
									isMulti={true}
									name='account_id'
									placeholder={'Select Brand'}
									isClearable={true}
								/>
							</div>
						</div>
						<div className="col-md-3">
							<div className='form-group'>
								<label htmlFor="gname">Primary Contact Name</label>
								<input onKeyDown={(e) => this.enterKey(e)} type="text" name="primary_contact_name" className="form-control" onChange={this.props.handleAdvanceSearch} value={advanceSearch.primary_contact_name} />
							</div>
							<div className='form-group'>
								<label htmlFor="gname">Primary Contact Number</label>
								<input onKeyDown={(e) => this.enterKey(e)} type="email" name="primary_contact_number" className="form-control" onChange={this.props.handleAdvanceSearch} value={advanceSearch.primary_contact_number} />
							</div>
							<div className='form-group'>
                                <label className="container-check mt-xxlg">
                                    Only Inactive Agencies&nbsp;
                                    <input type="checkbox" name="is_active" className="checkbox"
                                        value={inActive}
                                        onChange={(e) => this.handleAgencyInactiveCheckbox(e)}
                                        checked={inActive}
                                        // readOnly={true}
                                    />
                                    <span className="checkmark"></span>
                                </label>
							</div>
						</div>
						<div className="col-md-3">
                            <div className="form-group">
                                <label htmlFor="glemail">Program Type</label>
                                <Select
                                    value={returnLabelWithValue(advanceSearch.program_type, options.mainProgramType)}
                                    onChange={this.props.handleChangeAdvanceSearchDropDown}
                                    options={options.mainProgramType}
                                    isSearchable={true}
                                    isMulti={false}
                                    name='program_type'
                                    placeholder={'Select Program Type'}
                                    isClearable={true}
                                />
                            </div>

							<div className="form-group" id="primary_contact_email">
                                <div className='form-group'>
                                    <label htmlFor="gname">Primary Contact Email</label>
                                    <input onKeyDown={(e) => this.enterKey(e)} type="text" name="primary_contact_email" className="form-control" onChange={this.props.handleAdvanceSearch} value={advanceSearch.primary_contact_email} />
                                </div>
							</div>
						</div>

						<div className="col-md-3">
							<div className="form-group" id="agency_type">
                                <div className="form-group">
                                    <label htmlFor="glemail">Agency Type</label>
                                    <Select
                                        value={returnLabelWithValue(advanceSearch.agency_type, options.agencyTypes)}
                                        onChange={this.props.handleChangeAdvanceSearchDropDown}
                                        options={options.agencyTypes}
                                        isSearchable={true}
                                        isMulti={false}
                                        name='agency_type'
                                        placeholder={'Select Agency Type'}
                                        isClearable={true}
                                    />
                                </div>
							</div>

							<div className="form-group" id="gtv_sales_rep">
                                <div className="form-group">
                                    <label htmlFor="glemail">GTV Sales Rep</label>
                                    <Select
                                        value={returnLabelWithValue(advanceSearch.gtv_sales_rep, options.salesRep)}
                                        onChange={this.props.handleChangeAdvanceSearchDropDown}
                                        options={options.salesRep}
                                        isSearchable={true}
                                        isMulti={false}
                                        name='gtv_sales_rep'
                                        placeholder={'GTV Sales Rep'}
                                        isClearable={true}
                                    />
                                </div>
							</div>
						</div>
						<div className="col-md-12 text-right">
							<button onClick={this.props.clearAdvanceSearchForm} className="backButton pt-sm no_radius pb-sm success btn btn-info ml-sm mt-sm"> Clear All </button>

							<button onClick={() => this.props.performSearch()} className="backButton pt-sm no_radius pb-sm primary btn btn-md btn-info ml-sm mt-sm">Perform Search</button>
						</div>
					</div>
				</React.Fragment>
			}
			/>

		);
	};
}

export default AgencyAdvanceSearchForm;
