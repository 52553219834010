import { adminConstants } from '../constant/admin-constant';
import { alertActions } from './alert-actions';
import { destinationService } from '../../services/destination';

export function getDestinationsPagination(page, filter, sort, pageSize, is_active) {
    return dispatch => {
        dispatch(request())
        return destinationService.getDestinationsPagination(page, filter, sort, pageSize, is_active)
            .then(
                response => {
                    dispatch(success(response));
                    return response
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request() { return { type: adminConstants.PAGINATION_DESTINATIONS_REQUEST } }
    function success(response) { return { type: adminConstants.PAGINATION_DESTINATIONS_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.PAGINATION_DESTINATIONS_FAILURE, payload: error } }
}
export function storeDestination(DestinationData) {
    return dispatch => {
        dispatch(request());
        return destinationService.storeDestination(DestinationData)
            .then(
                response => {
                    dispatch(success(response));
                    dispatch(alertActions.success(response.message));
                    return response;
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                    return Promise.reject(error);
                }
            );
    }

    function request() { return { type: adminConstants.STORE_DESTINATION_REQUEST } }
    function success(response) { return { type: adminConstants.STORE_DESTINATION_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.STORE_DESTINATION_FAILURE, payload: error } }
}
export function getDestination(id) {
    return dispatch => {
        dispatch(request());
        destinationService.getDestination(id)
            .then(
                Destination => {
                    dispatch(success(Destination));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    }

    function request() { return { type: adminConstants.GET_DESTINATION_REQUEST } }
    function success(Destination) { return { type: adminConstants.GET_DESTINATION_SUCCESS, payload: Destination } }
    function failure(error) { return { type: adminConstants.GET_DESTINATION_FAILURE, payload: error } }
}
export function editAllDestination(DestinationData) {

    return dispatch => {
        dispatch(request());
        return destinationService.editAllDestination(DestinationData)
            .then(
                response => {
                    dispatch(success(response));
                    dispatch(alertActions.success(response.message));
                    return response;
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                    return Promise.reject(error);
                }
            );
    }

    function request() { return { type: adminConstants.EDIT_ALL_DESTINATIONS_REQUEST } }
    function success(response) { return { type: adminConstants.EDIT_ALL_DESTINATIONS_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.EDIT_ALL_DESTINATIONS_FAILURE, payload: error } }
}
export function editDestination(DestinationData, destinationId) {

    return dispatch => {
        dispatch(request());
        return destinationService.editDestination(DestinationData, destinationId)
            .then(
                response => {
                    dispatch(success(response));
                    dispatch(alertActions.success(response.message));
                    return response;
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                    return Promise.reject(error);
                }
            );
    }

    function request() { return { type: adminConstants.EDIT_DESTINATION_REQUEST } }
    function success(response) { return { type: adminConstants.EDIT_DESTINATION_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.EDIT_DESTINATION_FAILURE, payload: error } }
}
export function deleteDestination(destinationId) {
    return dispatch => {
        dispatch(request());
        return destinationService.deleteDestination(destinationId)
            .then(
                response => {
                    dispatch(success(response));
                    dispatch(alertActions.success(response.message));
                    return response;
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error.message));
                }
            );
    }

    function request() { return { type: adminConstants.DELETE_DESTINATION_REQUEST } }
    function success(response) { return { type: adminConstants.DELETE_DESTINATION_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.DELETE_DESTINATION_FAILURE, payload: error } }
}
