import React from "react";
class PrintOrderLabel extends React.Component {

    render() {
        return (
            <a
            rel="noopener noreferrer" 
            target='_blank' 
            href={`${this.props.base_url}/printOrderLabel/${this.props.orders}?order_page=${this.props.order_page}`} 
            className={this.props.className} >
                {this.props.btnIcon}
                <span>{this.props.text}</span>
            </a>
        );
    }
}

export default PrintOrderLabel;