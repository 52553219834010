import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Grid, Row, Col } from 'react-bootstrap'
import Card from '../../Card/Card'
import { getOrderbyType } from '../../../redux/actions/order-actions'
import { columnFilters, createSortString, getFormattedDate } from '../../../helper/helperFunctions'
import { Link } from 'react-router-dom'
import { adminConstants } from '../../../redux/constant/admin-constant'
import Pagination from '../../ThemeComponents/Pagination'

class FlaggedOrders extends Component {
    constructor(props) {
        super(props)
        this.state = {
            filtered: '',
            pageSize: 100,
        }
        this.type = 'flag_follow_up';
    }

    inputChangeHandler = (e) => {
        this.setState({
            [e.target.name]: [e.target.value]
        })
    }

    paginationCall = (data) => {
        let matchFilterWith;
        if (this.pagination) {
            if (this.pagination.reactTable) {
                matchFilterWith = columnFilters(this.pagination.reactTable.state.columns[0].columns)
            }
        }
        let sorted = data.sort.split(',');
        let { sort, orderType } = createSortString(sorted)
        // return this.props.getFlaggedOrders(data.page, data.filter, data.sort, data.pageSize);
        return this.props.getFlaggedOrders(this.type, adminConstants.GET_FLAGGED_ORDERS_SUCCESS, data.pageSize, sort, orderType, data.filter, matchFilterWith);
    }

    render() {
        const columns = [
            {
                Header: 'Program Types',
                headerClassName: 'headerPagination',
                columns: [
                    {
                        Header: 'Barcode',
                        accessor: 'barcode',
                        width: 200,
                        className: 'action-center',
                        Cell: ({ value, original }) => {
                            return <Link target='_blank' to={`/Admin/${original.id}/order-edit`} className='blue_link'>{value}</Link>
                        }
                    },
                    {
                        Header: 'Group Name',
                        accessor: 'group_name',
                        className: 'action-center'
                    },
                    {
                        Header: 'Group Leader',
                        accessor: 'group_leader_contact.first_name',
                        className: 'action-center',
                        id: 'groupLeaderContact.first_name'
                    },
                    {
                        Header: 'Departure Date',
                        accessor: 'order_dates.departure_date.value',
                        className: 'action-center',
                        id: 'orderDates.departure_date.value',
                        Cell: ({ value }) => {
                            return getFormattedDate(value)
                        }
                    },
                    {
                        Header: 'Contact Log',
                        className: 'action-center',
                        Cell: ({ original }) => {
                            return <Link target='_blank' to={{ pathname: `/Admin/${original.id}/order-edit?commentCategoryCode=ContactLog#commentTabs`, state: { contactLog: true } }} className='blue_link'>Contact Log</Link>
                        },
                        sortable: false
                    },
                    {
                        Header: 'Date Flagged',
                        accessor: 'order_settings.flag_follow_up.updated_at',
                        className: 'action-center',
                        id: 'settings.flag_follow_up.updated_at',
                        Cell: ({ value }) => {
                            return getFormattedDate(value)
                        },
                    }
                ]
            }
        ]
        return (
            <div className='content'>
                <Grid fluid>
                    <Row>
                        <Col md={12}>
                            <Card content={
                                <React.Fragment>
                                    <div className="secHeading">Flagged Orders</div>
                                    {/* <div className='flexElem' style={{ justifyContent: 'space-between' }}>
                                        <div className='my-20 col-md-4'>
                                            <input onChange={this.inputChangeHandler} type='text' name='filtered' className='form-control' placeholder='Search...' />
                                        </div>
                                    </div> */}
                                    {/* <ReactTable
                                        noDataText='No Orders Found'
                                        columns={columns}
                                        data={this.props.flagged_orders.data}
                                        defaultPageSize={100}
                                        pages={this.props.flagged_orders.last_page}
                                        filtered={this.state.filtered}
                                        onFetchData={
                                            (state) => {
                                                let matchFilterWith = columnFilters(state.columns[0].columns);
                                                let filter = state.filtered;
                                                let { sort, orderType } = createSortString(state.sorted);
                                                this.props.getFlaggedOrders(this.type, adminConstants.GET_FLAGGED_ORDERS_SUCCESS, state.pageSize, sort, orderType, filter, matchFilterWith);
                                            }
                                        }
                                    /> */}
                                    <div className='row'>
                                        <Pagination
                                            ref={(p) => this.pagination = p}
                                            showPagination={false}
                                            columns={columns}
                                            pageSize={100}
                                            noDataText='No Orders Found'
                                            getDataCall={this.paginationCall}
                                            filterView={true}
                                            // downloadData={true}
                                            // downloadFileName={'Orders'}
                                            // lowerContent = {null}
                                            filterPlaceHolder={'Orders'}
                                            getRowProps={this.getRowProps}
                                        // showAllToggle={true}
                                        />
                                    </div>
                                </React.Fragment>
                            } />
                        </Col>
                    </Row>
                </Grid>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { flagged_orders } = state.ordersReducer;
    return {
        flagged_orders
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getFlaggedOrders: (type, constant, pageSize, sort, orderType, filter, matchFilterWith) => { return dispatch(getOrderbyType(type, constant, pageSize, sort, orderType, filter, matchFilterWith)) }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FlaggedOrders)
