import { adminConstants } from "../constant/admin-constant";
import { frontEndUserConstants } from "../constant/front-end-constant";

const initialState = {
    brands: [],
    brandingInfo: {
        account_resources: {
            logo_file: {
                file_path: '',
                file_title: ''
            }
        }
    }
}

export const accountReducer = (state = initialState, action) => {
    switch (action.type) {
        case adminConstants.GET_ALL_BRANDS_SUCCESS:
            return {
                ...state,
                brands: action.payload.data
            }
        case adminConstants.GET_ALL_BRANDS_FAILURE:
            return {
                ...state,
                error: action.payload
            }
        case frontEndUserConstants.GET_BRANDING_INFO_SUCCESS:
            return {
                ...state,
                // brandingInfo: action.payload.data
                brandingInfo: action.payload && action.payload.data ? action.payload.data : initialState.brandingInfo
            }

        default:
            return state;
    }
}
