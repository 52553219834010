import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getCountries, getStates } from 'redux/actions/settings-actions';
import Select from 'react-select';
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import { inputChangeHandler, clean, contactChangeHandler, s3StaticPath, returnLabelWithValue, getSum } from 'helper/helperFunctions';
import { cloneDeep } from 'lodash'
import $ from 'jquery'
import Comment from 'components/FrontEnd/Order/OrderForm/Comment';
import User from 'helper/User';
import PhoneNumber from "components/ThemeComponents/phoneNumber";
import CustomPhoneNumber from '../../../ThemeComponents/CustomPhoneNumber';
import CustomCellPhoneNumber from '../../../ThemeComponents/CustomCellPhoneNumber';

class ShippingDetails extends Component {
    constructor(props) {
        super(props)

        this.state = {
            shipping_details: this.props.shipping_details,
            options: {
                statespre_trip_material_address: [],
                statesvideo_shipping_address: [],
            },
            order_details: this.props.order_details,
            is_france: false,
            vsa_is_france: false,
            ptma_is_france: false,
        }
        this.inputChangeHandler = inputChangeHandler.bind(this);
        this.clean = clean.bind(this);
        this.contactUpdateHandler = contactChangeHandler.bind(this);
        this.handleChangeGroupLeader = this.handleChangeGroupLeader.bind(this);
        this.props.getCountriesCall();
    }

    setAddressStates = (type, statesData) => {
        this.setState(prevState => ({
            ...prevState, options: {
                ...prevState.options, ['states' + type]: statesData
            }
        }));
    }

    // contactChangeHandler = (e) => {
    //     const { value, maxLength } = e.target;
    //     if (maxLength === value.length) {
    //         $(e.target).parent('div').next('div').find('input').focus();
    //     }
    //     this.handleChangeGroupLeader(e, 0);
    //     this.contactUpdateHandler(e);

    // }

    countryChangeHandler = (selected, action ) => {
    console.log("actionnn...", action.name);
    console.log("selected", selected.label);
                // ---------Frontend Order Edit Pre Trip Materials Address France Check ------------- //

        if (selected.label === 'France' && action.name === 'pre_trip_material_address'){
            this.setState({
                is_france: true,
                ptma_is_france: true
            },()=>{
                // console.log('is_france', this.state.is_france);
                if(this.props.childrenStatePtma)
                {
                    this.props.childrenStatePtma(this.state.ptma_is_france)
                    // console.log(this.state.is_france, 'is_france');
                }
                if (this.props.setStateOfParentPtma){
                    this.props.setStateOfParentPtma(true)
                }
            })
        }
        if (selected.label !== 'France' && action.name === 'pre_trip_material_address'){
            // console.log('called French');
            this.setState({
                is_france: false,
                ptma_is_france: false
            },()=>{
                // console.log('is_france', this.state.is_france);
                if(this.props.childrenStatePtma)
                {
                    this.props.childrenStatePtma(this.state.ptma_is_france)
                    // console.log(this.state.is_france, 'is_france');
                }
                if (this.props.setStateOfParentPtma){
                    this.props.setStateOfParentPtma(false)
                }
            })
        }

                         // ---------Frontend Order Edit Video Shipping Address France Check ------------- //

        if (selected.label === 'France' && action.name === 'video_shipping_address'){
            this.setState({
                vsa_is_france: true
            },()=>{
                // console.log('is_france', this.state.is_france);
                if(this.props.childrenStateVsa)
                    {
                        this.props.childrenStateVsa(this.state.vsa_is_france)
                        // console.log(this.state.is_france, 'is_france');
                    }
                if (this.props.setStateOfParentVsa){
                    this.props.setStateOfParentVsa(true)
                }
            })
        }
        if (selected.label !== 'France' && action.name === 'video_shipping_address'){
            // console.log('called French');
            this.setState({
                vsa_is_france: false
            },()=>{
                // console.log('is_france', this.state.is_france);
                if(this.props.childrenStateVsa)
                {
                    this.props.childrenStateVsa(this.state.vsa_is_france)
                    // console.log(this.state.is_france, 'is_france');
                }
                if (this.props.setStateOfParentVsa){
                    this.props.setStateOfParentVsa(false)
                }
            })
        }
        if (selected.label !== this.state.shipping_details.pre_trip_material_address.country.title && action.name === 'pre_trip_material_address' )
            {
                console.log("condition true");
                let shipping_details = {...this.state.shipping_details};
                shipping_details['pre_trip_material_address']['state_id'] = null;
                if(shipping_details.pre_trip_material_address.state)
                    {
                        shipping_details['pre_trip_material_address']['state']['code'] = null;
                        shipping_details['pre_trip_material_address']['state']['id'] = null;
                        shipping_details['pre_trip_material_address']['state']['title'] = null;
                    }
                console.log("state upadted with null value");
            }

        if (selected.label !== this.state.shipping_details.video_shipping_address.country.title && action.name === 'video_shipping_address' )
            {
                console.log("condition true");
                let shipping_details = {...this.state.shipping_details};
                shipping_details['video_shipping_address']['state_id'] = null;
                if(shipping_details.video_shipping_address.state)
                    {
                        shipping_details['video_shipping_address']['state']['code'] = null;
                        shipping_details['video_shipping_address']['state']['id'] = null;
                        shipping_details['video_shipping_address']['state']['title'] = null;
                    }
                console.log("state upadted with null value");
            }
        if (action.action === 'select-option') {
            this.setState(prevState => ({
                shipping_details: {
                    ...prevState.shipping_details,
                    [action.name]: {
                        ...prevState.shipping_details[action.name],
                        country_id: selected.value,
                        country: { 'title': selected.label, id: selected.value }
                    }
                }
            }))
        }
    }

    phoneFormatChangeHandler = () => {
        var Val1 = $( "body" ).find("#france_1");
        setTimeout(() => {
            Val1.trigger("click");
        }, 500);
        var Val2 = $( "body" ).find("#france_2");
        setTimeout(() => {
            Val2.trigger("click");
        }, 500);
    }

    selectChangeHandler = (selected, action) => {
        const container = action.name.split('.');
        // console.log('container', container);
        this.setState(prevState => ({
            shipping_details: {
                ...prevState.shipping_details,
                [container[0]]: {
                    ...prevState.shipping_details[container[0]],
                    state: { 'title': selected.label, id: selected.value },
                    [container[1]]: selected.value
                }
            }
        }))
    }

    copyAddress = (e, destination, source) => {
        const checked = e.target.checked;
        let data = {};
        if (checked) {
            const options = cloneDeep(this.state.options);
            if (source === 'agency') {
                data = {
                    ...this.state.shipping_details.pre_trip_material_address,
                    ...this.props.agencyAddress
                };
            } else if (source === 'camera') {
                data = this.state.shipping_details.pre_trip_material_address
                if (data.country_id === '') {
                    options.statesvideo_shipping_address = []
                }
            }
            this.setState(prevState => ({
                shipping_details: {
                    ...prevState.shipping_details,
                    [destination]: { ...prevState.shipping_details[destination], ...data }
                },
                options
            }), () => {
                if (this.state.shipping_details.video_shipping_address.country.title === "France") {
                    this.setState({
                        vsa_is_france: true,
                    },()=>{
                            if(this.props.childrenStateVsa)
                                {
                                    this.props.childrenStateVsa(this.state.vsa_is_france)
                                }
                            if (this.props.setStateOfParentVsa){
                                this.props.setStateOfParentVsa(true)
                            }
                        })
                } else{
                    this.setState({
                        vsa_is_france: false,
                    },()=>{
                        if(this.props.childrenStateVsa)
                            {
                                this.props.childrenStateVsa(this.state.vsa_is_france)
                            }
                            if (this.props.setStateOfParentVsa){
                                this.props.setStateOfParentVsa(false)
                            }
                    })
                }
                if (this.state.shipping_details.pre_trip_material_address.country.title === "France") {
                    this.setState({
                        vsa_is_france: true,
                    },()=>{
                            if(this.props.childrenStatePtma)
                                {
                                    this.props.childrenStatePtma(this.state.ptma_is_france)
                                }
                            if (this.props.setStateOfParentPtma){
                                this.props.setStateOfParentPtma(true)
                            }
                        })
                } else{
                    this.setState({
                        ptma_is_france: false,
                    },()=>{
                        if(this.props.childrenStatePtma)
                            {
                                this.props.childrenStatePtma(this.state.ptma_is_france)
                            }
                            if (this.props.setStateOfParentPtma){
                                this.props.setStateOfParentPtma(false)
                            }
                    })
                }
            })
        } else {
            const data = {
                ...this.state.shipping_details[destination],
                street_address_1: '',
                city: '',
                zipcode: '',
                country_id: '',
                state_id: ''
            }
            this.setState(prevState => ({
                shipping_details: {
                    ...prevState.shipping_details,
                    [destination]: data
                },
                options: {
                    ...prevState.options,
                    ['states' + destination]: []
                }
            }))
        }
    }

    componentDidUpdate(prevProps, prevState) {
        // if (this.state.shipping_details && this.state.shipping_details.pre_trip_material_address && this.state.shipping_details.pre_trip_material_address.country && this.state.shipping_details.pre_trip_material_address.country.title && this.state.shipping_details.pre_trip_material_address.country.title === 'France'){
        //     this.setState({
        //         initial_country_France: true
        //     })
        // }
        if ((this.props.states !== this.state.options['statespre_trip_material_address']) && this.props.statesType === 'pre_trip_material_address' && this.state.shipping_details.pre_trip_material_address.country_id !== '') {
            this.setAddressStates('pre_trip_material_address', this.props.states);
        }
        if (this.props.states !== this.state.options['statesvideo_shipping_address'] && this.props.statesType === 'video_shipping_address' && this.state.shipping_details.video_shipping_address.country_id !== '') {
            this.setAddressStates('video_shipping_address', this.props.states);
        }
        if (this.props.shipping_details !== prevProps.shipping_details) {
            this.setState({
                shipping_details: this.props.shipping_details
            },() => {
                this.phoneFormatChangeHandler();
            });
        }
        if (this.state.shipping_details.pre_trip_material_address.country_id !== prevState.shipping_details.pre_trip_material_address.country_id && this.state.shipping_details.pre_trip_material_address.country_id !== '') {
            this.props.getStateCall(this.state.shipping_details.pre_trip_material_address.country_id, 'pre_trip_material_address');
        }
        if (this.state.shipping_details.video_shipping_address.country_id !== prevState.shipping_details.video_shipping_address.country_id) {
            this.props.getStateCall(this.state.shipping_details.video_shipping_address.country_id, 'video_shipping_address');
        }
        if (this.state.shipping_details !== prevState.shipping_details) {
            this.props.returnState(this.state.shipping_details, 'shipping_details');
        }
        if (this.props.showErrorMessages && this.props.showErrorMessages !== prevProps.showErrorMessages) {
            this.props.validatorInstance.showMessages();
        }
        if (this.props.order_details !== prevProps.order_details) {
            this.setState({
                order_details: this.props.order_details
            })
        }
        if (this.state.order_details) {
            if (this.state.order_details.group_leader_contact !== prevState.order_details.group_leader_contact) {
                this.props.returnState(this.state.order_details, 'order_details');
            }

            if (this.state.order_details.settings !== prevState.order_details.settings) {
                this.props.returnState(this.state.order_details, 'order_details');
            }
        }
        if (this.props.countries !== prevProps.countries) {
            if (this.props.formType === 'add') {
                const us = this.props.countries.find(item => item.label === 'United Kingdom');
                const shipping_details = cloneDeep(this.state.shipping_details);
                shipping_details.pre_trip_material_address.country_id = us.value
                shipping_details.video_shipping_address.country_id = us.value
                this.setState({
                    shipping_details
                })
            }

        }


        // if (this.state.shipping_details.pre_trip_material_address.country_id !== prevState.shipping_details.pre_trip_material_address.country_id)
        //     {
        //         console.log("condition true");
        //         let shipping_details = {...this.state.shipping_details};
        //         shipping_details['pre_trip_material_address']['state_id'] = null;
        //         console.log("state upadted with null value");
        //     }
        // if (this.state.shipping_details.video_shipping_address.country_id !== prevState.shipping_details.video_shipping_address.country_id)
        //     {
        //         console.log("condition true");
        //         let shipping_details = {...this.state.shipping_details};
        //         shipping_details['video_shipping_address']['state_id'] = null;
        //         console.log("state upadted with null value");
        //     }


    }

    handleOrderDetailSettings = (e) => {
        // console.log('check box 789');
        const { name, checked, type, value } = e.target;
        // console.log('type', type);
        // console.log('name', name);
        // console.log('name', value);
        // const tempValue = type === 'checkbox' || 'radio' ? checked ? 1 : 0 : value;
        // console.log('tempValue', tempValue);
        this.setState(prevState => ({
            ...prevState, order_details: {
                ...prevState.order_details, settings: {
                    ...prevState.order_details.settings, [name]: {
                        value: value
                    }
                }
            }
        }));
    }


    handleChangeGroupLeader (e, key) {
        // console.log('data', key);
        const { name, value } = e.target;
        const order_detailsTemp = cloneDeep(this.state.order_details);
        order_detailsTemp['group_leader_contacts'][key][name] = value;
        order_detailsTemp['group_leader_contact'][name] = value;
        this.setState(
            {
                order_details: order_detailsTemp
            }
        );
    }


    render() {
        const { countries, notEditable, ptma_initial_country_France, vsa_initial_country_France } = this.props;
        const { shipping_details, options, order_details, is_france, ptma_is_france, vsa_is_france } = this.state;
        const { pre_trip_material_address, video_shipping_address } = shipping_details;
        let groupLeaderPhone1, groupLeaderPhone2, group_leader_contact;
        groupLeaderPhone1 = ''.split('-');
        if (this.props.reorder) {
            group_leader_contact = order_details.group_leader_contact;
            groupLeaderPhone1 = group_leader_contact.phone1.split('-');
        }
        if (this.props.contactInfo) {
            group_leader_contact = order_details.group_leader_contact;
            groupLeaderPhone1 = group_leader_contact.phone1.split('-');
            //  groupLeaderPhone2 = groupLeader.phone2 ? groupLeader.phone2.split('-') : "--".split('-');
            groupLeaderPhone2 = this.clean(group_leader_contact.phone2).split('-');
        }
        // this.props.validatorInstance.purgeFields();
        return (
            <React.Fragment>
                <div className="form_tile">
                    <div className="form_tile_heading">
                        <img src={s3StaticPath('img/shipping_information.png')} alt={this.props.panelTitle ? this.props.panelTitle : "Shipping Information"} />
                        <span>
                            {
                                this.props.panelTitle ? this.props.panelTitle : "Shipping Information"
                            }
                        </span>
                    </div>
                    <div className="form_tile_content">
                        {
                            !this.props.reorder &&
                            <React.Fragment>
                                <div className="row mb-lg">
                                    {
                                        'agencyAddress' in this.props ?
                                            <React.Fragment>
                                                <div className="col-md-4">
                                                    <div className="text_16">Pre Trip Materials Address
                                                        {/*<OverlayTrigger
                                                            overlay={
                                                                <Tooltip id='pre_trip_address'>Please provide only a street address. All correspondence contains the Group Leader and Group Name provided above.</Tooltip>
                                                            }>
                                                            <span className="tipIcon">?</span>
                                                        </OverlayTrigger>*/}
                                                    </div>
                                                </div>

                                                <div className="col-md-8">
                                                    {
                                                        (!this.props.notShipToAgency && notEditable === undefined) &&
                                                        <div className="customChk">
                                                            <input onChange={(e) => { this.copyAddress(e, 'pre_trip_material_address', 'agency') }} name='agency' type="checkbox" id="shipAgency" />
                                                            <label htmlFor="shipAgency">
                                                                Ship to Agency
                                                            </label>
                                                        </div>
                                                    }
                                                </div>
                                            </React.Fragment>
                                            :
                                            <React.Fragment>
                                                <div className="col-md-12">
                                                    <div className="text_16">Pre Trip Materials Address</div>
                                                </div>
                                            </React.Fragment>
                                    }

                                </div>
                                <div className="flexElem respFlex alignCenter spaceBetween form_tile_row">
                                    <div className="col-md-4">
                                        Street Address*
                                    </div>
                                    <div className="col-md-8 text-primary">
                                        {
                                            notEditable ?
                                                pre_trip_material_address.street_address_1
                                                :
                                                <input onChange={this.inputChangeHandler} name='shipping_details.pre_trip_material_address.street_address_1' value={this.clean(pre_trip_material_address.street_address_1)} type="text" autoComplete="off" placeholder="Street Address" className="form_tile_input block" />
                                        }

                                        {this.props.validatorInstance.message('street address ', pre_trip_material_address.street_address_1, 'required')}
                                    </div>
                                </div>
                                <div className="flexElem respFlex alignCenter spaceBetween form_tile_row">
                                    <div className="col-md-4">
                                        City*
                                    </div>
                                    <div className="col-md-8 text-primary">
                                        {
                                            notEditable ?
                                                pre_trip_material_address.city
                                                :
                                                <input onChange={this.inputChangeHandler} name='shipping_details.pre_trip_material_address.city' value={pre_trip_material_address.city} type="text" autoComplete="off" placeholder="City" className="form_tile_input block" />
                                        }
                                        {this.props.validatorInstance.message('city', pre_trip_material_address.city, 'required')}
                                    </div>
                                </div>



                                <div className="flexElem respFlex alignCenter spaceBetween form_tile_row">
                                        {
                                            ptma_is_france || ptma_initial_country_France ?
                                                <div className="col-md-4">
                                                    County
                                                </div>
                                             :
                                                <div className="col-md-4">
                                                    County *
                                                </div>
                                        }
                                    <div className="col-md-8 text-primary" id={"state"}>
                                        {
                                            notEditable ?
                                                pre_trip_material_address.state && pre_trip_material_address.state.code ? pre_trip_material_address.state.code : ''
                                                :
                                                <Select
                                                    value={returnLabelWithValue(pre_trip_material_address.state_id, options.statespre_trip_material_address) || null}
                                                    onChange={this.selectChangeHandler}
                                                    options={options.statespre_trip_material_address}
                                                    isSearchable={true}
                                                    isMulti={false}
                                                    name='pre_trip_material_address.state_id'
                                                    placeholder={'Select State'}
                                                />
                                        }

                                        {ptma_is_france || ptma_initial_country_France ? ' ' : this.props.validatorInstance.message('state', pre_trip_material_address.state_id, 'required')}
                                    </div>
                                </div>

                                <div className="flexElem respFlex alignCenter spaceBetween form_tile_row">
                                    <div className="col-md-4">
                                        Postcode
                                    </div>
                                    <div className="col-md-8 text-primary">
                                        {
                                            notEditable ?
                                                pre_trip_material_address.zipcode ? pre_trip_material_address.zipcode : ''
                                                :
                                                <input onChange={(e) => { this.inputChangeHandler(e) }} name='shipping_details.pre_trip_material_address.zipcode' value={pre_trip_material_address.zipcode} type="text" autoComplete="off" placeholder="AB01 2CD" className="form_tile_input block" />
                                                // <input onChange={(e) => { this.inputChangeHandler(e, /^\d{0,5}$/) }} name='shipping_details.pre_trip_material_address.zipcode' value={pre_trip_material_address.zipcode} type="text" autoComplete="off" placeholder="00000" className="form_tile_input block" />
                                        }

                                        {/* {this.props.validatorInstance.message('zipcode', pre_trip_material_address.zipcode, 'required')} */}
                                    </div>
                                </div>

                                <div className="flexElem respFlex alignCenter spaceBetween form_tile_row">
                                    <div className="col-md-4">
                                        Country *
                                    </div>
                                    <div className="col-md-8 text-primary">
                                        {
                                            notEditable ?
                                                pre_trip_material_address.country.title
                                                :
                                                <Select
                                                    id="pre_trip_material_address"
                                                    value={returnLabelWithValue(pre_trip_material_address.country_id, countries) || null}
                                                    onChange={this.countryChangeHandler}
                                                    options={countries}
                                                    onInputChange={this.phoneFormatChangeHandler}
                                                    isSearchable={true}
                                                    isMulti={false}

                                                    name='pre_trip_material_address'
                                                    placeholder={'Select Country'}
                                                />
                                        }

                                        {this.props.validatorInstance.message('camera address country', pre_trip_material_address.country_id, 'required')}
                                    </div>
                                </div>
                            </React.Fragment>
                        }
                        {
                            !this.props.notVideoShipAddressShow &&
                                <React.Fragment>
                                    <div className="row mb-lg mt-xlg">
                                    {
                                        'agencyAddress' in this.props ?
                                            <React.Fragment>
                                                <div className="col-md-4">
                                                    <div className="text_16">Video Address
                                                        {/*<OverlayTrigger
                                                            overlay={
                                                                <Tooltip id='video_address'>Please provide only a street address. All corresponence contains the Group Leader and Group Name provided above.</Tooltip>
                                                            }>
                                                            <span className="tipIcon">?</span>
                                                        </OverlayTrigger>
                                                        */}
                                                    </div>
                                                </div>

                                                <div className="col-md-3">
                                                    {
                                                        (!this.props.notVideoAddressAgencyPreTripButton && !this.props.reorder && notEditable === undefined) &&
                                                        <div className="customChk">
                                                            <input onChange={(e) => { this.copyAddress(e, 'video_shipping_address', 'agency') }} name='copyAddress' type="radio" id="agencyAddress" />
                                                            <label htmlFor="agencyAddress">
                                                                Agency
                                                    </label>
                                                        </div>
                                                    }
                                                </div>
                                                <div className="col-md-5">
                                                    {
                                                        (!this.props.notVideoAddressAgencyPreTripButton && !this.props.reorder  && notEditable === undefined) &&
                                                        <div className="customChk">
                                                            <input onChange={(e) => { this.copyAddress(e, 'video_shipping_address', 'camera') }} name='copyAddress' type="radio" id="sameAddress" />
                                                            <label htmlFor="sameAddress">
                                                                Same as Pre Trip Materials
                                                    </label>
                                                        </div>
                                                    }
                                                </div>
                                            </React.Fragment>
                                            :
                                            <React.Fragment>
                                                <div className="col-md-6">
                                                    <div className="text_16">Video Address</div>
                                                </div>

                                                <div className="col-md-6 text-right">
                                                    <div className="customChk">
                                                        <input onChange={(e) => { this.copyAddress(e, 'video_shipping_address', 'camera') }} type="checkbox" id="sameAddress" />
                                                        <label htmlFor="sameAddress">
                                                            Same as Pre Trip Materials
                                                    </label>
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                    }
                                    </div>
                                    {
                                        this.props.reorder &&
                                        <React.Fragment>
                                            <div className="flexElem respFlex alignCenter spaceBetween form_tile_row">
                                                <div className="col-md-4">
                                                    Name *
                                                        </div>
                                                <div className="col-md-8 text-primary">
                                                    <input name='order_details.group_leader_contact.name' onChange={this.inputChangeHandler} type="text" autoComplete="off" placeholder="Name" className="form_tile_input block" />
                                                    {this.props.validatorInstance.message('name', group_leader_contact.name, 'required')}
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    }
                                    <div className="flexElem respFlex alignCenter spaceBetween form_tile_row">
                                        <div className="col-md-4">
                                            Street Address*
                                                </div>
                                        <div className="col-md-8 text-primary">
                                            {
                                                notEditable ?
                                                    this.clean(video_shipping_address.street_address_1)
                                                    :
                                                    <input
                                                        onChange={this.inputChangeHandler}
                                                        name='shipping_details.video_shipping_address.street_address_1'
                                                        value={this.clean(video_shipping_address.street_address_1)}
                                                        type="text" autoComplete="off"
                                                        placeholder="Street Address"
                                                        className="form_tile_input block" />
                                            }
                                            {this.props.validatorInstance.message('video address street 1', video_shipping_address.street_address_1, 'required')}
                                        </div>
                                    </div>
                                    <div className="flexElem respFlex alignCenter spaceBetween form_tile_row">
                                        <div className="col-md-4">
                                            City*
                                                </div>
                                        <div className="col-md-8 text-primary">
                                            {
                                                notEditable ?
                                                    video_shipping_address.city
                                                    :
                                                    <input onChange={this.inputChangeHandler} name='shipping_details.video_shipping_address.city' value={video_shipping_address.city} type="text" autoComplete="off" placeholder="City" className="form_tile_input block" />
                                            }

                                            {this.props.validatorInstance.message('video address city', video_shipping_address.city, 'required')}
                                        </div>
                                    </div>
                                    <div className="flexElem respFlex alignCenter spaceBetween form_tile_row">
                                        {
                                            vsa_is_france || vsa_initial_country_France ?
                                                <div className="col-md-4">
                                                    County
                                                </div>
                                             :
                                                <div className="col-md-4">
                                                    County *
                                                </div>
                                        }
                                        <div className="col-md-8 text-primary" id={"video address state"}>
                                            {
                                                notEditable ?
                                                    video_shipping_address.state && video_shipping_address.state.code && video_shipping_address.state.code
                                                    :
                                                    <Select
                                                        value={returnLabelWithValue(video_shipping_address.state_id, options.statesvideo_shipping_address) || null}
                                                        onChange={this.selectChangeHandler}
                                                        options={options.statesvideo_shipping_address}
                                                        isSearchable={true}
                                                        isMulti={false}
                                                        name='video_shipping_address.state_id'
                                                        placeholder={'Select County'}
                                                    />
                                            }

                                            {vsa_is_france || vsa_initial_country_France ? '' : this.props.validatorInstance.message('video address state', video_shipping_address.state_id, 'required')}
                                        </div>
                                    </div>
                                    <div className="flexElem respFlex alignCenter spaceBetween form_tile_row">
                                        <div className="col-md-4">
                                            Postcode
                                        </div>
                                        <div className="col-md-8 text-primary">
                                            {
                                                notEditable ?
                                                    video_shipping_address.zipcode ? video_shipping_address.zipcode : ''
                                                    :
                                                    <input onChange={(e) => { this.inputChangeHandler(e) }} value={video_shipping_address.zipcode} name='shipping_details.video_shipping_address.zipcode' type="text" autoComplete="off" placeholder="AB01 2CD" className="form_tile_input block" />
                                                    // <input onChange={(e) => { this.inputChangeHandler(e, /^\d{0,5}$/) }} value={video_shipping_address.zipcode} name='shipping_details.video_shipping_address.zipcode' type="text" autoComplete="off" placeholder="00000" className="form_tile_input block" />
                                            }

                                            {/* {this.props.validatorInstance.message('video address zipcode', video_shipping_address.zipcode, 'required')} */}
                                        </div>
                                    </div>
                                    <div className="flexElem respFlex alignCenter spaceBetween form_tile_row">
                                        <div className="col-md-4">
                                            Country *
                                        </div>
                                        <div className="col-md-8 text-primary">
                                            {
                                                notEditable ?
                                                    video_shipping_address.country.title
                                                    :
                                                    <Select
                                                        value={returnLabelWithValue(video_shipping_address.country_id, countries) || null}
                                                        onChange={this.countryChangeHandler}
                                                        options={countries}
                                                        isSearchable={true}
                                                        isDisabled={this.props.reorder}
                                                        isMulti={false}
                                                        name='video_shipping_address'
                                                        placeholder={'Select Country'}
                                                    />
                                            }

                                            {this.props.validatorInstance.message('video address country', video_shipping_address.country_id, 'required')}
                                        </div>
                                    </div>
                                </React.Fragment>
                        }

                        {
                            this.props.reorder &&
                            <React.Fragment>

                                <div className="flexElem respFlex alignCenter spaceBetween form_tile_row">
                                    <div className="col-md-4">
                                        Email *
                                            </div>
                                    <div className="col-md-8 text-primary">
                                        <input name='order_details.group_leader_contact.email' onChange={this.inputChangeHandler} type="text" autoComplete="off" placeholder="Email" className="form_tile_input block" />
                                        {this.props.validatorInstance.message('email', group_leader_contact.email, 'required')}
                                    </div>
                                </div>
                                <div className="flexElem respFlex alignCenter spaceBetween form_tile_row">
                                    <div className="col-md-4">
                                        Phone *
                                            </div>
                                    <div className="col-md-8 text-primary">
                                        <div className="flexElem flexThreeCols spaceBetween">
                                                <PhoneNumber
                                                    value={order_details.group_leader_contact.phone1}
                                                    handleParent={(e) => this.handleChangeGroupLeader(e, 0)}
                                                    validationName={'GroupLeaderPhone_' + getSum(0, 1)}
                                                    validator={this.props.validatorInstance}
                                                    required={true}
                                                    name={'phone1'}
                                                />
                                        </div>
                                    </div>
                                </div>
                            </React.Fragment>
                        }

                        {
                            this.props.contactInfo &&
                            <React.Fragment>
                                <div className="row mb-lg mt-xlg">
                                    <div className="col-md-12">
                                        <div className="text_16">Contact Information</div>
                                    </div>
                                </div>

                                <div className="flexElem respFlex alignCenter spaceBetween form_tile_row">
                                    <div className="col-md-4">
                                        Phone *
                                            </div>
                                    <div className="col-md-8 text-primary">
                                        <div className="flexElem flexThreeCols spaceBetween">
                                            {/* <PhoneNumber
                                                value={order_details.group_leader_contact.phone1}
                                                handleParent={(e) => this.handleChangeGroupLeader(e, 0)}
                                                validationName={'GroupLeaderPhone_' + getSum(0, 1)}
                                                validator={this.props.validatorInstance}
                                                required={true}
                                                name={'phone1'}
                                            /> */}
                                            <CustomPhoneNumber
                                                value={order_details.group_leader_contact.phone1}
                                                handleParent={(e) => this.handleChangeGroupLeader(e, 0)}
                                                validationName={'GroupLeaderPhone_' + getSum(0, 1)}
                                                validator={this.props.validatorInstance}
                                                required={true}
                                                elemId={'france_1'}
                                                name={'phone1'}
                                                is_france={is_france}
                                                initial_country_France={ptma_initial_country_France}
                                            />

                                        </div>
                                    </div>
                                </div>
                                <div className="flexElem respFlex alignCenter spaceBetween form_tile_row">
                                    <div className="col-md-4">
                                        Cell Phone
                                            </div>
                                    <div className="col-md-8 text-primary">
                                        <div className="flexElem flexThreeCols spaceBetween">
                                            {/* <PhoneNumber
                                                value={order_details.group_leader_contact.phone2}
                                                handleParent={(e) => this.handleChangeGroupLeader(e, 0)}
                                                validationName={'GroupLeaderPhone_' + getSum(0, 1)}
                                                validator={this.props.validatorInstance}
                                                required={false}
                                                name={'phone2'}
                                            /> */}
                                            <CustomCellPhoneNumber
                                                value={order_details.group_leader_contact.phone2}
                                                handleParent={(e) => this.handleChangeGroupLeader(e, 0)}
                                                validationName={'GroupLeader Cell Phone_' + getSum(0, 2)}
                                                validator={this.props.validatorInstance}
                                                required={false}
                                                elemId={'france_2'}
                                                name={'phone2'}
                                                is_france={is_france}
                                                initial_country_France={ptma_initial_country_France}
                                            />
                                        </div>
                                    </div>
                                </div>

                                {/* <div className="flexElem respFlex alignCenter spaceBetween form_tile_row">
                                    <div className="col-md-4">
                                        Cell Provider
                                            </div>
                                    <div className="col-md-8 text-primary">
                                        <input name='' type="text" autoComplete="off" placeholder="Cell Provider" className="form_tile_input block" />
                                    </div>
                                </div> */}

                                <div className="flexElem respFlex alignCenter spaceBetween form_tile_row">
                                    <div className="col-md-4">
                                        Best Time to Call (EST)
                                    </div>
                                    <div className="col-md-8 text-primary">
                                        <input
                                            name='best_time_to_call'
                                            value={'best_time_to_call' in order_details.settings ? order_details.settings.best_time_to_call.value : ''}
                                            type="text"
                                            autoComplete="off"
                                            placeholder="Best Time to Call (EST)"
                                            className="form_tile_input block"
                                            onChange={this.handleOrderDetailSettings}
                                        />
                                    </div>
                                </div>

                                <div className="flexElem respFlex alignCenter spaceBetween form_tile_row">
                                    <div className="col-md-4">
                                        Email *
                                            </div>
                                    <div className="col-md-8 text-primary">
                                        <input value={this.clean(group_leader_contact.email)} name='email' onChange={(e) => this.handleChangeGroupLeader(e, 0)} type="text" autoComplete="off" placeholder="Email" className="form_tile_input block" />
                                        {this.props.validatorInstance.message('emial', group_leader_contact.email, 'required')}
                                    </div>
                                </div>

                                <div className="flexElem respFlex spaceBetween form_tile_row">
                                    <div className="col-md-4">
                                        Special Instructions *
                                    </div>
                                    <div className="col-md-8 text-primary">
                                        <Comment orderOptions order_id={User.getProperty('order.id')} formType={this.props.formType} submitComment={this.props.submitComment} />
                                    </div>
                                </div>
                            </React.Fragment>
                        }
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    const { countries, states, statesType } = state.settingsReducer;
    return {
        countries,
        states,
        statesType
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getCountriesCall: () => { dispatch(getCountries()) },
        getStateCall: (countryId, type) => { dispatch(getStates(countryId, type)) },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ShippingDetails)
