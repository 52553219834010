import { adminConstants } from "../constant/admin-constant";

const initialState = {
    moreButton: {},
    moreButtonsPagination: {},
    accountMoreButtonsPagination: {},
    moreButtonsForOrder: {}


}

export const moreButtonReducer = (state = initialState, action) => {
    switch (action.type) {

        case adminConstants.STORE_PROGRAM_TYPE_SUCCESS:
            return {
                ...state,
            }
        case adminConstants.STORE_MORE_BUTTON_FAILURE:
            return {
                ...state,
                error: action.payload
            }

        case adminConstants.GET_MORE_BUTTONS_PAGINATION_SUCCESS:
            return {
                ...state,
                moreButtonsPagination: action.payload.data
            }

        case adminConstants.GET_ACCOUNT_MORE_BUTTONS_PAGINATION_SUCCESS:
            return {
                ...state,
                accountMoreButtonsPagination: action.payload.data
            }   

            case adminConstants.GET_MORE_BUTTON_FOR_ORDER_SUCCESS:
            return {
                ...state,
                moreButtonsForOrder: action.payload.data
            }
            case adminConstants.GET_SINGLE_MORE_BUTTON_SUCCESS:
                return {
                    ...state,
                    moreButton: action.payload.data
                }
            case adminConstants.GET_SINGLE_MORE_BUTTON_FAILURE:
                return {
                    ...state,
                    error: action.payload.error
                }
            case adminConstants.DELETE_MORE_BUTTON_SUCCESS:
                return {
                    ...state,
                    moreButtonsPagination: {
                        ...state.moreButtonsPagination,
                        data: action.payload
                    }
                }
            case adminConstants.UPDATE_MORE_BUTTON_STATUS_REQUEST:
                return {
                     ...state
                    };
            case adminConstants.UPDATE_MORE_BUTTON_STATUS_SUCCESS:
                return {
                     ...state,  updateMoreButtonStatus:action.payload.data
                    };
            case adminConstants.UPDATE_MORE_BUTTON_STATUS_FAILURE:
                return {
                    ...state, error : action.payload
                };

            case adminConstants.UPDATE_MORE_BUTTON_SUCCESS:
                const data = [...state.moreButtonsPagination.pagination.data];
                const index = data.findIndex(function (item) {
                    return item.id === action.payload.id;
                });
                data[index] = { ...data[index],...action.payload.data }
                return {
                    ...state,
                    moreButtonsPagination: {
                        ...state.moreButtonsPagination,
                        data: [...data]
                    }
                };

            case adminConstants.UPDATE_ACCOUNT_MORE_BUTTON_SUCCESS:
            const data1 = [...state.accountMoreButtonsPagination.pagination.data];
            const index1 = data1.findIndex(function (item) {
                return item.id === action.payload.id;
            });
            data1[index1] = { ...data1[index1],...action.payload.data }
            return {
                ...state,
                accountMoreButtonsPagination: {
                    ...state.accountMoreButtonsPagination,
                    data: [...data1]
                }
            };

            case adminConstants.UPDATE_MORE_BUTTON_FOR_ORDER_SUCCESS:
                const data2 = [...state.moreButtonsForOrder.pagination.data];
                const index2 = data2.findIndex(function (item) {
                    return item.id === action.payload.id;
                });
                data2[index2] = { ...data2[index2],...action.payload.data }
                return {
                    ...state,
                    moreButtonsForOrder: {
                        ...state.moreButtonsForOrder,
                        data: [...data2]
                    }
                };
                case adminConstants.UPDATE_MORE_BUTTON_FAILURE:
                    return {
                        ...state,
                        error : action.payload
                     };
                     
                case adminConstants.UPDATE_ACCOUNT_MORE_BUTTON_FAILURE:
                return {
                    ...state,
                    error : action.payload
                };

        default:
            return state
    }
}
