import React, { Component } from "react";
import { connect } from 'react-redux';
import {

  Table,
} from "react-bootstrap";

class OrderProducedSummaryPage extends Component {
	render() {
		const { ordersCountWithModerator } = this.props;
		return(
			<div>
				<Table striped hover responsive className="customTable tableLayoutFix">
					<thead>
						<tr>
							<th>Produced By</th>
							<th>Total Orders Produced</th>
						</tr>
							{
								ordersCountWithModerator.map((prop,key)=>{
									return(
										prop.producer_id ? 
											<tr key={key}>
												<td>{prop.producer.name}</td>
												<td>{prop.total}</td>
											</tr>
										:
											null
									);
								})
							}
					</thead>
				</Table>
			</div>
		);
	}
}

// const mapDispatchToProps = dispatch => {
// 	return ({
// 		exampleFunctionCall: () => { dispatch(exampleAction.exampleFunction()) },//example function
// 	});
// };

function mapStateToProps(state) {
	const { ordersCountWithModerator } = state.ordersReducer;
	return {
		ordersCountWithModerator
	};
}


const OrderProducedSummary = connect(mapStateToProps, null)(OrderProducedSummaryPage);
export default OrderProducedSummary;