import React, { Component } from "react";
import { connect } from 'react-redux';
import {
    getPendingOrders,
    submitPendingOrders
} from "redux/actions/order-actions";
import ReactValidator from "simple-react-validator";
import DatePicker from "react-datepicker";
import {
    Grid,
    Row,
    Col,
} from "react-bootstrap";
import { Card } from "components/Card/Card.jsx";
import 'react-table/react-table.css';
import { Link } from 'react-router-dom';
import { appendValues, dateFormatMy } from 'helper/helperFunctions';
import Pagination from "../../ThemeComponents/Pagination";

const moment = window.moment;

class PendingOrdersPage extends Component {
    constructor(props) {
        super(props);
        this.dateValidator = new ReactValidator();
        this.state = {
            fromDate: '',
            toDate: '',
            pageSize: 10,
            page: 0,
            sorted: '',
            selected: {},
            selectAll: 0,
            newdata: [],
        };
        this.toggleRow = this.toggleRow.bind(this);
        this.table = React.createRef();

    }

    componentDidUpdate(prevProps, prevState) {
        console.log(this.props.pendingOrdersData);
        if (prevProps.pendingOrdersData !== this.props.pendingOrdersData) {
            const temp = appendValues(this.props.pendingOrdersData, [{ column: 'checklist', value: false }]);
            this.setState({
                newdata: temp
            })
            console.log('updated');
        }
    }

    toggleRow(id) {
        let newdataTemp = [...this.state.newdata];
        newdataTemp[id]['checklist'] = !newdataTemp[id]['checklist']
        this.setState({
            newdata: newdataTemp
        });

    }
    toggleSelectAll() {
        // let newSelected = {};
        let newdataTemp = [...this.state.newdata];
        if (this.state.selectAll === 0) {

            newdataTemp.forEach(x => {
                x['checklist'] = true;
            });
        } else {
            newdataTemp.forEach(x => {
                x['checklist'] = false;
            });
        }

        this.setState({
            newdata: newdataTemp,
            selectAll: this.state.selectAll === 0 ? 1 : 0
        });
    }

    DateChangeHandler = (value, field) => {
        this.setState({ [field]: value === null ? '' : moment(value).format("YYYY-MM-DD") }, () => {
            if (this.dateValidator.allValid()) {
                this.pagination.dataCall();
            } else {
                this.dateValidator.showMessages();
                this.forceUpdate();
            }
        })
    }
    dateElement(label, name, value, rules) {
        var selectedDate = this.state[name] !== '' ? new Date(this.state[name]) : '';
        const { fromDate, toDate } = this.state;
        return (
            <div className='form-group fullCalendar' id={name}>
                <label>{label}</label>
                <DatePicker className="form-control calender_icon" selected={selectedDate} name={name} onChange={(event) => this.DateChangeHandler(event, name)} />
                {
                    (fromDate !== '' && toDate !== '') &&
                    this.dateValidator.message(name, value, rules)
                }
            </div>
        );
    }
    ordersPaginationData = (page = this.state.page, sorted = this.state.sorted, pageSize = this.state.pageSize) => {
        const { fromDate, toDate } = this.state;
        this.props.getOrdersCall(1, fromDate, toDate, page, pageSize, sorted);
    }

    sendOrdersTOInvoice = (page = this.state.page, sorted = this.state.sorted, pageSize = this.state.pageSize) => {
        let newdataTemp = [...this.state.newdata];
        let newArray = {};
        newdataTemp.forEach(x => {
            if (x.checklist === true)
                newArray[x.id] = { "order_id": x.id };
        });
        console.log("invoice", newArray);
        if (Object.keys(newArray).length === 0) {
            alert("Please select the orders to process")
            return
        }
        this.props.submitPendingOrdersCall(newArray).then(
            response => {
                this.pagination.dataCall();
            },
            error => {

            }
        );

    }

    paginationCall = (data) => {
        const { fromDate, toDate } = this.state;
        return this.props.getOrdersCall(1, fromDate, toDate, data.page, data.pageSize, data.sort);
    }


    render() {
        const { /*newdata, toDate, fromDate*/ } = this.state;
        const { /*pendingOrderspages, loadingOrder*/ } = this.props;
        const columns = [
            {
                Header: "Pending Orders",
                headerClassName: 'headerPagination',
                columns: [

                    {
                        Header: "Barcode",
                        accessor: "barcode",
                        width: 200,
                        Cell: ({ value, original }) => {
                            return <Link target="_blank" to={'/Admin/' + original.id + '/order-edit'} className="blue_link">{value}</Link>
                        },
                        className: "action-center",
                        sortable: false,
                    },
                    {
                        Header: "Agency",
                        accessor: "agency",
                        Cell: ({ value }) => (
                            <div>
                                {value.name}
                            </div>
                        ),
                        className: "action-center",
                        sortable: true,
                    },
                    {
                        Header: "Group Name",
                        accessor: "group_name",
                        className: "action-center",
                        sortable: false,
                    },
                    {
                        Header: "Departure Date",
                        accessor: "departure_date",
                        Cell: ({ value }) => (
                            <div>
                                {dateFormatMy(value)}
                            </div>
                        ),
                        className: "action-center",
                        sortable: true,
                    },
                    {
                        Header: "Videos",
                        accessor: "numberOfVideos",
                        className: "action-center",
                        sortable: false,
                    },
                    // {
                    //     accessor: "checklist",
                    //     Cell: ({ original, row }) => {
                    //         return (
                    //             <input
                    //                 type="checkbox"
                    //                 className="checkbox"
                    //                 onChange={() => this.toggleRow(row._index)}
                    //                 checked={original.checklist}
                    //             />
                    //         );
                    //     },
                    //     Header: x => {
                    //         return (
                    //             <input
                    //                 type="checkbox"
                    //                 className="checkbox"
                    //                 checked={this.state.selectAll === 1}
                    //                 ref={input => {
                    //                     if (input) {
                    //                         input.indeterminate = this.state.selectAll === 2;
                    //                     }
                    //                 }}
                    //                 onChange={() => this.toggleSelectAll()}
                    //             />
                    //         );
                    //     },
                    //     sortable: false,
                    //     width: 45
                    // },
                ]
            }
        ];
        return (
            <div className="content">


                <Grid fluid>
                    <Row>
                        <Col md={12}>
                            <Card
                                ctTableResponsive
                                content={
                                    <div>
                                        <Grid fluid>
                                            <div className="row">
                                                <Col md={12}>
                                                    <div className="secHeading">Approve Orders</div>
                                                </Col>

                                                {/* <Col md={4} >
                                                    <div className='row flexElem flexResponsive align-items-center' >
                                                        <div className="col-md-6" >
                                                            {
                                                                this.dateElement('Date From', 'fromDate', fromDate !== '' && moment(fromDate, 'YYYY-MM-DD'), [{ before_or_equal: moment(this.state.toDate) }])
                                                            }
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col md={4} >
                                                    <div className='row flexElem flexResponsive align-items-center' >
                                                        <div className="col-md-6" >
                                                            {
                                                                this.dateElement('Date From', 'fromDate', fromDate !== '' && moment(fromDate, 'YYYY-MM-DD'), [{ before_or_equal: moment(this.state.toDate) }])
                                                            }
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col md={4} >
                                                    <div className='row flexElem flexResponsive align-items-center' >
                                                        <div className="col-md-6" >
                                                            {
                                                                this.dateElement('Date To', 'toDate', toDate !== '' && moment(toDate, 'YYYY-MM-DD'), [{ after_or_equal: moment(this.state.fromDate) }])
                                                            }
                                                        </div>

                                                    </div>
                                                </Col>

                                                <Col md={4} >
                                                    <div className='row flexElem flexResponsive align-items-center' >
                                                        <div className="col-md-6" >&nbsp;</div>
                                                        <div className="col-md-6" >
                                                            <div >
                                                                <OverlayTrigger placement="bottom" trigger={['hover']} overlay={<Popover id="tooltip">Send Orders to Approve</Popover>}>
                                                                    <Button type='button' className="backButton pt-sm no_radius btn-block pb-sm primary mt-none btn btn btn-info " onClick={this.sendOrdersTOInvoice} ><i className="fa fa-plus" />Approve Orders</Button>
                                                                </OverlayTrigger>

                                                            </div>
                                                        </div>

                                                    </div>
                                                </Col> */}
                                                {/* <Col md={12} className="mt-md">
                                                    <ReactTable
                                                        ref={this.table}
                                                        noDataText='No order found'
                                                        data={newdata}
                                                        showPagination={true}
                                                        pages={pendingOrderspages}
                                                        columns={columns}
                                                        defaultPageSize={50}
                                                        loading={loadingOrder}
                                                        className="-striped listing"
                                                        loadingText={'Loading...'}
                                                        pageData={this.ordersPaginationData}
                                                        manual
                                                        onFetchData={(state, instance) => {
                                                            var sort = state.sorted.length === 0 ? '' : state.sorted[0].id + ',desc:' + state.sorted[0].desc;
                                                            state.pageData(state.page + 1, sort, state.pageSize);
                                                        }}
                                                    />
                                                </Col> */}
                                                <Pagination
                                                    ref={(p) => this.pagination = p}
                                                    showPagination={false}
                                                    noDataText='No order found'
                                                    columns={columns}
                                                    pageSize={50}
                                                    getDataCall={this.paginationCall}
                                                    filterView={false}
                                                    defaultSorted={[
                                                        { id: 'departure_date', desc: false }
                                                    ]}
                                                    // downloadData={true}
                                                    // downloadFileName={'Orders'}
                                                    // lowerContent = {null}
                                                    // filterPlaceHolder = {'Orders'}
                                                    getRowProps={this.getRowProps}
                                                // showAllToggle={true}
                                                />
                                            </div>
                                        </Grid>
                                    </div>
                                } />
                        </Col>
                    </Row>
                </Grid>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return ({
        getOrdersCall: (value, fromDate, toDate, page, pageSize, sorted) => { return dispatch(getPendingOrders(value, fromDate, toDate, page, pageSize, sorted)) },
        submitPendingOrdersCall: (ordersData) => { return dispatch(submitPendingOrders(ordersData)) },

    });
};

function mapStateToProps(state) {
    const { pendingOrdersData, pendingOrderspages, pendingOrderscurrent_page, pendingOrderstotal_orders, loadingOrder } = state.ordersReducer;
    return {
        pendingOrdersData, pendingOrderspages, pendingOrderscurrent_page, pendingOrderstotal_orders, loadingOrder
    };
}


const PendingOrders = connect(mapStateToProps, mapDispatchToProps)(PendingOrdersPage);
export default PendingOrders;
